import React, { Dispatch, SetStateAction } from 'react';
import { ModalBody, ModalWrapper, TransactModal } from './styles';

import leftArrow from '../../assets/left-arrow.svg';

import Modal from '../Modal';

import { formatToMoney } from '../../utils/format';
import { convertPaymentName } from '../../utils/paymentName';
import { ITransaction } from '../../models/IPayment';

interface IModalBackPaymentProps {
  open: boolean;
  loading: boolean;
  discount: number;
  service: number;
  valueService: number;
  valueDiscount: number;
  transactionsNoSaved: ITransaction[];
  onConfirm: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const ModalBackPayment: React.FC<IModalBackPaymentProps> = ({
  open,
  loading,
  service,
  discount,
  valueDiscount,
  valueService,
  transactionsNoSaved,
  onConfirm,
  setOpen,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      loading={loading}
    >
      <ModalWrapper>
        <h3>Deseja perder todas alterações?</h3>
        <ModalBody>
          <h5>Você perderá as seguintes alterações:</h5>
          {discount !== 0 && (
            <TransactModal>
              <p>{`Desconto de ${discount}%`}</p>
              <p>{formatToMoney(valueDiscount)}</p>
            </TransactModal>
          )}
          {service !== 10 && (
            <TransactModal>
              <p>{`Taxa de serviço ${service}%`}</p>
              <p>{formatToMoney(valueService)}</p>
            </TransactModal>
          )}
          <img src={leftArrow} alt="" />
          {transactionsNoSaved.length > 0 && <h5>Pagamentos não salvos</h5>}
          {transactionsNoSaved.length > 0 &&
            transactionsNoSaved.map((transact: ITransaction) => (
              <TransactModal key={transact.id}>
                <p>{convertPaymentName(transact?.paymentType)}</p>
                <p>{formatToMoney(transact?.amount)}</p>
              </TransactModal>
            ))}
        </ModalBody>
      </ModalWrapper>
    </Modal>
  );
};

export default ModalBackPayment;
