import React, { useEffect, useRef, useState } from 'react';
import { DropdownButton, DropdownContainer, DropdownContent } from './styles';

type IOptionsProps = {
  title: string;
  onClick: () => void;
};

interface IDropdownProps {
  button: React.ReactNode;
  options: IOptionsProps[];
}
const Dropdown: React.FC<IDropdownProps> = ({ button, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [dropdownPosition, setDropdownPosition] = React.useState({
    top: 0,
    left: 0,
  });
  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    // Adiciona o listener quando o componente é montado
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove o listener quando o componente é desmontado
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleOpenDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    const rect = event.currentTarget.getBoundingClientRect();
    setDropdownPosition({
      top: window.innerWidth > 700 ? rect.bottom : rect.bottom - 105,
      left: rect.left,
    });
  };

  const toggleDropdown = (event: React.MouseEvent<HTMLDivElement>) => {
    handleOpenDropdown(event);
    setIsOpen(!isOpen);
  };
  return (
    <DropdownContainer ref={dropdownRef} onClick={toggleDropdown}>
      <DropdownButton onClick={toggleDropdown}>{button}</DropdownButton>
      {isOpen && (
        <DropdownContent
          style={{ top: dropdownPosition.top, left: dropdownPosition.left }}
          ref={contentRef}
          itemCount={options?.length || 0}
        >
          {options?.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <button key={index} type="button" onClick={option.onClick}>
              {option.title}
            </button>
          ))}
        </DropdownContent>
      )}
    </DropdownContainer>
  );
};

export default Dropdown;
