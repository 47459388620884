/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable consistent-return */
/* eslint-disable no-nested-ternary */
import React, { useMemo, useState, useEffect, useCallback } from 'react';

import { useHistory, useLocation } from 'react-router-dom';

import api from '../../services/api';
import tourData from '../../tour/settings';
import { useCompany } from '../../hooks/company';
import { useBsTicket } from '../../hooks/bsticket';
import { AuthRole, useAuth } from '../../hooks/auth';
import * as chatbotService from '../../services/chatbot';

import {
  Main,
  Header,
  Content,
  SubPage,
  PageName,
  SubPages,
  Container,
  GroupTitle,
  CompanyCode,
  GroupContainer,
  ChatbotContainer,
  CompanyCodeContainer,
} from './styles';

import { PageNames } from '../../enums/pages';
import { EPlanModule } from '../../models/IPlan';

import Tour from '../../components/Tour';
import HelpButton from '../../components/HelpButton';
import { IGroupProps } from '../../components/OrderGroupModal';
import UserSettings from '../../components/SettingsPageComponents/UserSettings';
import BsFoodSettings from '../../components/SettingsPageComponents/BsFoodSettings';
import CompanySettings from '../../components/SettingsPageComponents/CompanySettings';
import OptionsSettings from '../../components/SettingsPageComponents/OptionsSettings';
import BsTicketSettings from '../../components/SettingsPageComponents/BsTicketSettings';
import IntegrationSettings from '../../components/SettingsPageComponents/IntegrationSettings';

const SettingsPage: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const { user, updateUser } = useAuth();

  const {
    company,
    isSavingCompany,
    saveCompany,
    toggleOption,
    togglePaymentCard,
    saveBusinessHours,
    saveCustomPizzaCharges,
    saveFacebookPixelIntegration,
  } = useCompany();

  const {
    productsToOrder,
    saveProductsOrder,
    loadProductsToOrder,
    changeBsTicketCodeType,
  } = useBsTicket();

  const [apiKey, setApiKey] = useState('');
  const [clientId, setClientId] = useState('');
  const [chatbotUrl, setChatbotUrl] = useState('');
  const [isTourVisible, setIsTourVisible] = useState(false);
  const [page, setPage] = useState<1 | 2 | 3 | 4 | 5 | 6 | 7>(1); // console.log() voltar para número 1

  useEffect(() => {
    if (history.location.hash === '#tables') {
      const tableAmountInput = document.getElementById(
        'tableAmount',
      ) as HTMLInputElement;

      tableAmountInput?.scrollIntoView({ behavior: 'smooth' });
      tableAmountInput?.focus();
    }
  }, [history]);

  useEffect(() => {
    if (company?.enablesWhatsAppBot) {
      const search = new URLSearchParams(location.search);
      if (search.get('fromBot')) {
        setPage(5);
      }
    }
  }, [location, company]);

  const handleOnSubPageChanged = async (subPage: typeof page) => {
    setPage(subPage);

    if (subPage === 4) {
      loadIntegrationData();
    }

    if (subPage === 5) {
      const arch = window?.navigator?.userAgent.includes('Win64')
        ? 'x64'
        : 'ia32';

      const url = await chatbotService.getReleaseUrl(arch);
      setChatbotUrl(url);
    }
  };

  const handleHelpButtonClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  const loadIntegrationData = useCallback(async () => {
    const { data } = await api.get(
      `/restricted/companies/${company?.id}/api-key`,
    );

    setClientId(data.clientId);

    return setApiKey(data.apiKey);
  }, [company]);

  const handleOpenChatbot = useCallback(() => {
    if (user && company) {
      const url = `${process.env.REACT_APP_CHATBOT_PROTOCOL}config`;
      const query = `accessToken=${user.accessToken}&companyId=${company.id}&subdomain=${company.subdomain}`;

      window.open(`${url}?${query}`, '_parent');
    }
  }, [company, user]);

  const bsTickectProductsToOrder = useMemo<IGroupProps[]>(() => {
    return productsToOrder;
  }, [productsToOrder]);

  return (
    <Container>
      <Content>
        <Header>
          <PageName>
            {PageNames.SETTINGS}
            {page !== 6 && <HelpButton onClick={handleHelpButtonClick} />}
          </PageName>
        </Header>
        <SubPages>
          <SubPage
            onClick={() => handleOnSubPageChanged(1)}
            selected={page === 1}
          >
            EMPRESA
          </SubPage>
          <SubPage
            onClick={() => handleOnSubPageChanged(2)}
            selected={page === 2}
          >
            OPÇÕES
          </SubPage>
          <SubPage
            onClick={() => handleOnSubPageChanged(3)}
            selected={page === 3}
          >
            MEUS DADOS
          </SubPage>
          <AuthRole>
            <SubPage
              onClick={() => handleOnSubPageChanged(4)}
              selected={page === 4}
            >
              INTEGRAÇÃO
            </SubPage>
          </AuthRole>
          <AuthRole blackList={['Marketing']}>
            {company?.enablesWhatsAppBot && (
              <SubPage
                onClick={() => handleOnSubPageChanged(5)}
                selected={page === 5}
              >
                WHATSAPP
              </SubPage>
            )}
          </AuthRole>

          <AuthRole>
            {company?.subscription?.plan.modules?.includes(
              EPlanModule.BSTICKET,
            ) && (
              <SubPage
                onClick={() => handleOnSubPageChanged(6)}
                selected={page === 6}
              >
                BS TICKET
              </SubPage>
            )}
          </AuthRole>
          {/* <AuthRole>
            <SubPage
              onClick={() => handleOnSubPageChanged(7)}
              selected={page === 7}
            >
              BSFOOD
            </SubPage>
          </AuthRole> */}
        </SubPages>
        <Main className="has-custom-scroll-bar-2">
          {page === 1 ? (
            <CompanySettings
              company={company}
              saveCompany={saveCompany}
              saveBusinessHours={saveBusinessHours}
            />
          ) : page === 2 ? (
            <OptionsSettings
              company={company}
              toggleOption={toggleOption}
              saveCustomPizzaCharges={saveCustomPizzaCharges}
              togglePaymentCard={togglePaymentCard}
            />
          ) : page === 3 ? (
            <UserSettings user={user} updateUser={updateUser} />
          ) : page === 4 ? (
            <AuthRole>
              <IntegrationSettings
                apiKey={apiKey}
                company={company}
                clientId={clientId}
                isSavingCompany={isSavingCompany}
                setApiKey={setApiKey}
                setClientId={setClientId}
                toggleOption={toggleOption}
                saveFacebookPixelIntegration={saveFacebookPixelIntegration}
              />
            </AuthRole>
          ) : page === 5 ? (
            <ChatbotContainer>
              <GroupContainer>
                <GroupTitle>Bot do Whatsapp</GroupTitle>
                <CompanyCodeContainer>
                  {chatbotUrl && (
                    <>
                      <span>Instalar aplicação</span>
                      <CompanyCode>
                        <a
                          id="download-chatbot"
                          href={chatbotUrl}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          <span>Fazer o Download</span>
                        </a>
                      </CompanyCode>
                    </>
                  )}
                  <span>Configurar bot</span>
                  <CompanyCode>
                    <button
                      id="open-whatsapp-bot"
                      type="button"
                      onClick={handleOpenChatbot}
                    >
                      <span>Abrir/configurar bot</span>
                    </button>
                  </CompanyCode>
                </CompanyCodeContainer>
              </GroupContainer>
            </ChatbotContainer>
          ) : page === 6 ? (
            <BsTicketSettings
              company={company}
              bsTickectProductsToOrder={bsTickectProductsToOrder}
              toggleOption={toggleOption}
              saveProductsOrder={saveProductsOrder}
              loadProductsToOrder={loadProductsToOrder}
              changeBsTicketCodeType={changeBsTicketCodeType}
            />
          ) : (
            <BsFoodSettings />
          )}
        </Main>
      </Content>
      {isTourVisible && (
        <Tour
          steps={
            page === 1
              ? tourData.company
              : page === 2
              ? tourData.options
              : page === 3
              ? tourData.user
              : tourData.integration
          }
          onFinish={handleTourFinish}
        />
      )}
    </Container>
  );
};

export default SettingsPage;
