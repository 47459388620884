import { IStep } from '../components/Tour';
import { chooseBsFoodOrVarejo } from '../utils/subdomain';

const tour: IStep[] = [
  {
    text: 'Esta é a página de Destaques.',
  },
  {
    text: `Os itens adicionados aqui irão aparecer no topo de seu ${chooseBsFoodOrVarejo(
      'cardápio',
      'catálogo',
    )}!`,
  },

  {
    id: 'highlights-container',
    position: 'bottom',
    text: 'Aqui ficarão listados os destaques.',
  },
  {
    id: 'highlights-add',
    position: 'right',
    text: 'Basta clicar aqui para adicionar um destaque.',
  },
  {
    id: 'highlights-edit',
    position: 'left',
    text: 'Clicando aqui você pode editar o destaque...',
  },
  {
    id: 'highlights-delete',
    position: 'left',
    text: 'e aqui deletá-lo.',
  },
  {
    id: 'highlights-drag',
    position: 'right',
    text: 'Para mudar a ordem de exibição dos produtos basta clicar e arrastar.',
  },
];

export default tour;
