import styled from 'styled-components';

interface IDisplayProps {
  table: boolean;
  card: boolean;
}

export const Wrapper = styled.div<IDisplayProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 10px 32px;
  gap: 10px;
  padding-bottom: 25px;
  overflow-y: hidden;
`;

export const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  place-content: center;
`;
