import styled from 'styled-components';
import { statusCardTableType } from '../../enums/cardAndTableType';

export const Container = styled.div`
  display: flex;
  width: 100%;
`;

export const Filters = styled.div`
  display: flex;
  width: 100%;
  gap: 10px;
  align-items: center;
`;

interface IBadgeProps {
  type: statusCardTableType;
  status: statusCardTableType | undefined;
}
export const Badge = styled.div<IBadgeProps>`
  display: flex;
  padding: 5px;
  gap: 5px;
  border-radius: 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.background_white};
  background-color: ${({ type }) =>
    type === 'FREE'
      ? '#85D1A5'
      : type === 'CLOSING'
      ? '#fdd400'
      : type === 'OCCUPIED' && '#DA123A'};

  color: ${({ type, status }) =>
    status && type !== status && type === 'FREE'
      ? '#85D1A5'
      : status && type !== status && type === 'CLOSING'
      ? '#fdd400'
      : status && type !== status && type === 'OCCUPIED' && '#DA123A'};

  background-color: ${({ type, status, theme }) =>
    status && type !== status && theme.palette.background_white};

  border: 1px solid
    ${({ type }) =>
      type === 'FREE'
        ? '#85D1A5'
        : type === 'CLOSING'
        ? '#fdd400'
        : type === 'OCCUPIED' && '#DA123A'};

  @media (max-width: 870px) {
    width: 40px;
    height: 30px;
    border: 2px solid
      ${({ type }) =>
        type === 'FREE'
          ? '#85D1A5'
          : type === 'CLOSING'
          ? '#fdd400'
          : type === 'OCCUPIED' && '#DA123A'};

    background-color: ${({ type, status }) =>
      status && type !== status && type === 'FREE'
        ? 'rgba(133, 209, 165, 0.1)'
        : status && type !== status && type === 'CLOSING'
        ? 'rgba(253, 212, 0, 0.1)'
        : status &&
          type !== status &&
          type === 'OCCUPIED' &&
          'rgba(218, 18, 58,0.1)'};
    p {
      display: none;
    }
  }
`;
