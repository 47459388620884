import styled from 'styled-components';
import LogoAsset from '../../assets/logo.png';

export const Container = styled.div`
  height: 121px;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);
  overflow: hidden;
  transition: box-shadow 0.4s;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1280px) {
    width: 100%;
    flex: 0 100%;
  }
`;
export const Infos = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 32px;

  .name {
    font-weight: 500;
    font-size: 18px;
  }
  .phone {
    color: ${({ theme }) => theme.palette.text_light};
  }
`;

export const Actions = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const WrapperBadge = styled.div`
  display: flex;
  height: 100%;
  min-width: 68px;
`;

export const Badge = styled.div`
  width: calc(38px - 1vw);
  height: calc(38px - 1vw);
  background-image: url(${LogoAsset});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin: 10px 20px 0px 0px;

  @media (max-width: 550px) {
    width: calc(28px - 1vw);
    height: calc(28px - 1vw);
  }
`;
