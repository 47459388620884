import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiCheck, FiTrash, FiX } from 'react-icons/fi';

import {
  Img,
  Price,
  Close,
  Header,
  Wrapper,
  HalfPrice,
  Container,
  Complements,
  GridAdditional,
  ItemAdditional,
  ContainerButtons,
  ContainerAdditionals,
} from './styles';
import theme from '../../../../styles/themes/light';

import { useToast } from '../../../../hooks/toast';
import { useProducts } from '../../../../hooks/products';
import { formatToMoney } from '../../../../utils/format';
import { useConfirmDialog } from '../../../../hooks/confim_dialog';

import IComplement from '../../../../models/IComplement';
import { IProductSell } from '../../../../models/IFastSell';

import Additionals from '../Additionals';
import Button from '../../../../components/Button';
import ComplementsCartSell from '../ComplementsCartSell';
import IconButton from '../../../../components/IconButton';
import ProductIcon from '../../../../components/ProductIcon';
import AmountInput from '../../../../components/AmountInput';
import TextAreaObservation from '../../../../components/TextAreaObservation';

interface IDetailProductGridProps {
  product: IProductSell;
  setOpenDrawer: (value: React.SetStateAction<boolean>) => void;
  setProductsToGrid: (value: React.SetStateAction<IProductSell[]>) => void;
}
const DetailProductToGrid: React.FC<IDetailProductGridProps> = ({
  product,
  setOpenDrawer,
  setProductsToGrid,
}) => {
  const { calcProductPrice } = useProducts();
  const { addToast } = useToast();
  const { showConfirmDialog } = useConfirmDialog();

  const productRef = useRef<HTMLDivElement | null>(null);
  const containerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const [openAdditional, setOpenAdditional] = useState(false);
  const [nextComplement, setNextComplement] = useState(0);
  const [editProduct, setEditProduct] = useState<IProductSell>({
    ...product,
    productId: Number(product.id),
  });

  const hasComplements =
    editProduct && (editProduct?.complementsGroups?.length ?? 0) > 0;

  useEffect(() => {
    if (
      nextComplement > 0 &&
      nextComplement !== null &&
      containerRefs.current[nextComplement]
    ) {
      const element = containerRefs.current[nextComplement];
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [nextComplement]);

  useEffect(() => {
    const handleScroll = () => {
      if (productRef.current) {
        setNextComplement(-1);
      }
    };

    const productElement = productRef.current;
    if (productElement) {
      productElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (productElement) {
        productElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  const handleAddProductToGrid = () => {
    const mandatoryComplements = editProduct?.complementsGroups?.filter(
      complGroup => complGroup.minAmount >= 1,
    );
    // Verifica se todos os complementos obrigatórios têm o número mínimo de itens marcados
    const verify = mandatoryComplements?.map(complGroup => {
      // Soma o amount de todos os complementos marcados (checked: true)
      const checkedAmountSum = complGroup.complements
        .filter((compl: any) => compl.checked)
        .reduce((total, compl: any) => total + (compl?.amount ?? 1), 0);
      // Verifica se a soma do amount é pelo menos o valor de minAmount
      return checkedAmountSum >= complGroup.minAmount;
    });

    // Verifica se algum grupo não satisfaz a condição (ou seja, se tem algum false)
    const hasFalse = verify?.some(cond => cond === false);

    if (hasFalse) {
      showConfirmDialog({
        title: 'Há complementos obrigatórios',
        message: `Preencha os para continuar!`,
        onConfirm: () => null,
      });
      // Se algum grupo não tem o mínimo de complementos obrigatórios marcados, interrompe o processo
      return;
    }

    setProductsToGrid(prev => {
      const cleanedEditProduct = {
        ...editProduct,
        complementsGroups:
          editProduct.complementsGroups
            ?.map(group => ({
              ...group,
              complements: group.complements.filter(
                complement => complement.checked,
              ),
            }))
            .filter(group => group.checked) ?? [],
      };
      const exists = prev.some(
        product => product.hash === cleanedEditProduct.hash,
      );

      if (exists) {
        // Substitui o produto existente
        return prev.map(product =>
          product.hash === cleanedEditProduct.hash
            ? {
                ...cleanedEditProduct,
                total: calcProductPrice(cleanedEditProduct),
              }
            : product,
        );
      }
      // Adiciona o novo produto
      return [
        ...prev,
        { ...cleanedEditProduct, total: calcProductPrice(cleanedEditProduct) },
      ];
    });
    setOpenDrawer(false);
    addToast({
      type: 'success',
      description: 'Produto adicionado ao grid com sucesso!',
    });
  };

  const toggleHalfPrice = useCallback(
    (value: boolean) => {
      if (editProduct) {
        setEditProduct(prevProduct => {
          // Alternar o valor de halfPrice
          const updatedProduct: IProductSell = {
            ...prevProduct,
            isHalfPrice: value,
          };

          // Recalcular o preço após a atualização
          const newTotalPrice = calcProductPrice(updatedProduct);

          return {
            ...updatedProduct,
            total: newTotalPrice,
          };
        });
      }
    },
    [calcProductPrice, editProduct],
  );

  const handleDeleteAdditional = (compl: IComplement) => {
    setEditProduct(prev => {
      const existingGroupIndex = editProduct.complementsGroups?.findIndex(
        group => group.id === undefined,
      );
      if (existingGroupIndex === -1 || existingGroupIndex === undefined)
        return prev;
      const updatedProduct = { ...prev };
      const existingGroup = (updatedProduct.complementsGroups ?? [])[
        existingGroupIndex
      ];
      const updatedComplements = existingGroup.complements.filter(
        item => item.title !== compl.title,
      );
      // Atualizar o grupo com o novo array de complements
      (updatedProduct.complementsGroups ?? [])[existingGroupIndex] = {
        ...existingGroup,
        complements: updatedComplements,
      };
      return updatedProduct;
    });
  };

  return (
    <Wrapper>
      <Header>
        <Close>
          <IconButton
            onClick={() => setOpenDrawer(false)}
            icon={<FiX size={22} />}
          />
        </Close>
      </Header>

      <Container
        ref={productRef}
        hasComplements={hasComplements}
        className="has-custom-scroll-bar-2"
      >
        <Img>
          <ProductIcon url={product.imageUrl} alt={product.title} />
        </Img>
        {Number(editProduct?.halfPrice) > 0 && (
          <HalfPrice>
            <h3>Selecione o Tamanho</h3>
            <div className="prices">
              <Price
                selected={!editProduct?.isHalfPrice}
                onClick={() => toggleHalfPrice(false)}
              >
                <p>Inteiro</p>
                R$
                {editProduct?.unitPrice.toFixed(2).replace('.', ',')}
              </Price>
              <Price
                selected={!!editProduct?.isHalfPrice}
                onClick={() => toggleHalfPrice(true)}
              >
                <p>Meio</p>
                R$
                {(editProduct?.halfPrice ?? 0).toFixed(2).replace('.', ',')}
              </Price>
            </div>
          </HalfPrice>
        )}
        <Complements>
          {hasComplements &&
            editProduct?.complementsGroups?.map((complementGroup, index) => {
              if (complementGroup.id) {
                return (
                  <ComplementsCartSell
                    ref={el => {
                      containerRefs.current[index] = el;
                    }}
                    index={index}
                    key={complementGroup.id}
                    complementGroup={complementGroup}
                    setEditProduct={setEditProduct}
                    setNextComplement={setNextComplement}
                  />
                );
              }
              // eslint-disable-next-line react/no-array-index-key
              return <div key={index} />;
            })}
        </Complements>
        <ContainerAdditionals>
          <Additionals
            open={openAdditional}
            setOpen={setOpenAdditional}
            setEditProduct={setEditProduct}
          />

          {editProduct.complementsGroups?.map(compGroup => {
            if (compGroup.id === undefined) {
              return (
                <GridAdditional key={1}>
                  {compGroup.complements.map(compl => (
                    <ItemAdditional key={compl.title}>
                      <p>{compl.title}</p>
                      <div>
                        <p>{formatToMoney(compl.unitPrice)}</p>
                        <FiTrash
                          onClick={() => handleDeleteAdditional(compl)}
                          size={22}
                          className="delete"
                          color={theme.palette.primary}
                        />
                      </div>
                    </ItemAdditional>
                  ))}
                </GridAdditional>
              );
            }
            return <div className="none" key={compGroup.id} />;
          })}
        </ContainerAdditionals>

        <TextAreaObservation
          placeholder="Escreva as observações do produto aqui..."
          title="Observações"
          value={product.comments}
          handleCommentsChange={value =>
            setEditProduct(prev => ({ ...prev, comments: value }))
          }
        />
      </Container>

      <ContainerButtons>
        <AmountInput
          value={editProduct.qty}
          setValue={e => setEditProduct(prev => ({ ...prev, qty: e }))}
          onIncreaseAmount={() =>
            setEditProduct(prev => ({ ...prev, qty: prev.qty + 1 }))
          }
          onDecreaseAmount={() =>
            setEditProduct(prev => ({ ...prev, qty: prev.qty - 1 }))
          }
        />
        <h3>{formatToMoney(calcProductPrice(editProduct))}</h3>
        <Button
          title="Adicionar"
          onClick={handleAddProductToGrid}
          icon={<FiCheck size={22} color="#fff" />}
        />
      </ContainerButtons>
    </Wrapper>
  );
};

export default DetailProductToGrid;
