import styled from 'styled-components';

export const ProductsSellWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 30px;
  gap: 20px;
  overflow-y: auto;
`;

export const GridProducts = styled.div`
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
`;
