import React, { useContext, createContext, useState, useCallback } from 'react';
import ICashierReport, { IShift } from '../models/ICashierReport';
import api from '../services/api';
import { useCompany } from './company';
import IDevice from '../models/IDevice';

interface IFilters {
  device: {
    get: IDevice['deviceId'];
    set: (param: IDevice['deviceId']) => void;
  };
}

interface CashierReportContextData {
  cashiersReports: IShift[];
  isCashierReportLoading: boolean;
  filtersStates: IFilters;
  loadCashiersReports: (deviceId: IDevice['deviceId']) => Promise<void>;
  loadCashierReport: (
    shiftId: IShift['id'],
    deviceId: IDevice['deviceId'],
  ) => Promise<ICashierReport | null>;
}

const CashierReportContext = createContext<CashierReportContextData>(
  {} as CashierReportContextData,
);

export const CashierReportProvider: React.FC = ({ children }) => {
  const [isCashierReportLoading, setIsCashierReportLoading] = useState(false);
  const [cashiersReports, setCashiersReports] = useState<IShift[]>([]);

  const [selectedDeviceFilter, setSelectedDeviceFilter] =
    useState<IDevice['deviceId']>('');

  const filtersStates: IFilters = {
    device: {
      get: selectedDeviceFilter,
      set: setSelectedDeviceFilter,
    },
  };

  const { company } = useCompany();

  const loadCashiersReports = useCallback(
    async (deviceId: IDevice['deviceId']) => {
      try {
        if (company) {
          setIsCashierReportLoading(true);

          const response = await api.get<IShift[]>(
            `/restricted/shifts/${deviceId}/company/${company.id}/all`,
          );

          setCashiersReports(response.data);

          setIsCashierReportLoading(false);
        }
      } catch (error) {
        setIsCashierReportLoading(false);
      }
    },
    [company],
  );

  const loadCashierReport = useCallback(
    async (shiftId: IShift['id'], deviceId: IDevice['deviceId']) => {
      try {
        setIsCashierReportLoading(true);

        const response = await api.get<ICashierReport>(
          `/restricted/shifts/${shiftId}/device/${deviceId}/summary`,
        );

        setIsCashierReportLoading(false);

        return response.data;
      } catch (error) {
        setIsCashierReportLoading(false);

        return null;
      }
    },
    [],
  );

  return (
    <CashierReportContext.Provider
      value={{
        cashiersReports,
        isCashierReportLoading,
        filtersStates,
        loadCashiersReports,
        loadCashierReport,
      }}
    >
      {children}
    </CashierReportContext.Provider>
  );
};

export function useCashierReport(): CashierReportContextData {
  const context = useContext(CashierReportContext);

  if (!context) {
    throw new Error(
      'useCashierReport must be used within CashierReportProvider',
    );
  }

  return context;
}
