import styled from 'styled-components';

export const DropdownContainer = styled.div`
  position: relative;
  display: inline-block;
`;

export const DropdownButton = styled.div`
  width: 100%;
`;

export const DropdownContent = styled.div<{
  itemCount: number;
  positionUpwards: boolean;
}>`
  display: flex;
  flex-direction: column;
  position: absolute;
  background-color: ${({ theme }) => theme.palette.background_white};
  min-width: 170px;
  border-radius: 8px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  gap: 2px;
  bottom: 100%;
  transform: translateY(-5px);
  transform: ${({ positionUpwards }) =>
    positionUpwards && 'translateX(-170px)'};
  transition: transform 0.3s ease;

  button {
    text-align: start;
    background-color: ${({ theme }) => theme.palette.background_white};
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text};
    text-decoration: none;
    display: block;
    border: 0;
    cursor: pointer;
    padding: 5px 13px;

    :hover {
      background-color: ${({ theme }) => theme.palette.background};
    }
  }
`;
