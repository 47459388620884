import { Form } from '@unform/web';
import styled from 'styled-components';
import Radio from '../../Radio';

export const Wrapper = styled(Form)`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }

  > button {
    display: flex;
    align-items: center;
    justify-content: center;

    margin-top: 64px;
    width: 200px;

    transition: box-shadow 0.4s;

    box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.1);

    &:hover {
      box-shadow: 3px 3px 8px 0 rgba(0, 0, 0, 0.3);
    }

    border: 0;
    color: #fff;
    background: #db1622;

    padding: 16px 48px;

    span {
      font-size: 18px;
    }
  }
`;

interface IHorizontalWrapperProps {
  asymmetrical?: boolean;
}
export const HorizontalWrapper = styled.div<IHorizontalWrapperProps>`
  display: flex;

  > div + div {
    margin-left: 32px;
  }

  @media (max-width: 730px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    div + div {
      width: 100%;
      margin-left: 0;
    }
  }

  #clientIdContainer {
    margin-right: 24px;
  }

  #apiCompanyKeyContainer {
    width: auto;
  }
`;

export const DisplayName = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};
  padding: 16px 32px;

  input {
    width: 87%;
  }

  @media (max-width: 1800px) {
    width: 100%;
  }
`;
export const Span = styled.div`
  display: flex;
  flex-direction: column;
`;

interface IHorizontalWrapperProps {
  asymmetrical?: boolean;
}

export const DivPadding = styled.div`
  width: 100%;
  height: 8px;
`;

export const HorizontalWrapper2 = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 16px;
`;

export const Input = styled.input``;

export const Title = styled.span`
  font-size: 14px;
`;

export const CompanyName = styled.span`
  font-size: 32px;
`;

export const GroupTitle = styled.div`
  border-bottom: 4px solid #db1622;

  font-weight: 500;
  font-size: 24px;

  button {
    border: 0;
    color: #fff;
    background: #db1622;
    width: 35px;
    height: 35px;
    margin-bottom: 16px;
  }
`;

export const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 16px;
  }

  :first-child {
    margin-top: unset;
  }

  #contentContainer {
    margin-top: 0;
  }

  #settings-bsticket-products {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    #bs-ticket-order-products-button {
      margin-left: unset;
    }
  }
`;

export const AddressContainer = styled.div`
  display: flex;
  width: 100%;

  div {
    width: 20%;
  }

  div + div {
    width: 10%;
    margin-left: 32px;
  }

  div + div + div {
    width: 70%;
  }

  @media (max-width: 730px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    div + div {
      width: 100%;
      margin-left: 0;
      margin-top: 32px;
    }

    div + div + div {
      width: 100%;
      margin-top: 32px;
    }
  }
`;

export const PricesContaier = styled.div`
  display: flex;
  flex-direction: column;

  div {
    div {
      margin-bottom: 32px;
    }
  }
`;

export const GroupSubTitles = styled.span`
  width: fit-content;
  border-bottom: 4px solid #db1622;
  margin-top: 32px;
  font-size: 18px;
`;

export const PixContainer = styled.div`
  margin-top: 16px;

  > div {
    margin-top: 16px;
  }
`;

export const RadioContainer = styled(Radio.Group)`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: -4px;

  input {
    display: none;
  }

  label {
    margin-top: 8px;
    user-select: none;
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 1.2rem;
    padding-bottom: 8px;
    transition: border-color 200ms ease;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text};

    svg {
      margin-left: 0.4rem;
    }

    .checked {
      display: none;
    }
  }

  input:checked + label {
    border-color: ${({ theme }) => theme.palette.primary};
    .unchecked {
      display: none;
    }
    .checked {
      display: block;
    }
  }

  @media (max-width: 800px) {
    label {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    label {
      margin-bottom: 0.4rem;
    }
  }
`;

export const TypeContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 1rem;

  h2 {
    font-size: 1.4rem;
    margin-right: 0.4rem;
  }

  h4 {
    width: 100%;
  }
`;

export const BusinessHoursGroupTitle = styled(GroupTitle)`
  display: flex;
  justify-content: space-between;
`;

export const BusinessHoursGroupContainer = styled.div`
  display: flex;

  max-width: 800px;
  flex-wrap: wrap;

  margin-top: 32px;
`;
