import React from 'react';
import ReactDOM from 'react-dom';
import { FirstDiv, SecondDiv, SellWrapper } from './styles';

import ProductsSellPage from '../../components/ProductsSellPage';
import CartSell from '../../components/CartSell';

import { useCartProducts } from '../../hooks/products_sell';

import { ICard } from '../../models/ICard';
import { ITable2 } from '../../models/ITable2';
import IOrder from '../../models/IOrder';

interface ISellPageProps {
  open: boolean;
  card?: ICard;
  table?: ITable2;
  order?: IOrder;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const SellPage: React.FC<ISellPageProps> = ({
  card,
  table,
  order,
  open,
  setOpen,
}) => {
  // usado para controlar a animação de entrade e saida da tela
  const { openCart } = useCartProducts();
  return ReactDOM.createPortal(
    <>
      <SellWrapper close={!open}>
        <FirstDiv openCartSell={openCart}>
          <ProductsSellPage setOpen={setOpen} />
        </FirstDiv>
        <SecondDiv openCartSell={openCart}>
          <CartSell order={order} card={card} table={table} />
        </SecondDiv>
      </SellWrapper>
    </>,
    document.body,
  );
};

export default SellPage;
