/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FiCheck, FiPlus, FiTrash } from 'react-icons/fi';
import Cropper from 'react-cropper';
import Toggle from 'react-toggle';
import { ConfigContainer } from '../EditDeviceModal/styles';
import ButtonSm from '../ButtonSm';
import {
  Row,
  Image,
  Option,
  Images,
  ImageInput,
  ImageLoader,
  RemoveButton,
  GroupSubTitles,
  CropperContent,
  ToggleContainer,
  OptionsContainer,
} from './styles';
import {
  Modal,
  CancelButton,
  CropImageAction,
  CropImageActions,
} from '../../pages/EditProductPage/styles';
import { useToast } from '../../hooks/toast';
import {
  ITotemImagePosition,
  ITotemSettings,
  TotemAppMode,
} from '../../models/IDevice';
import TotemPreview from '../TotemPreview';
import api from '../../services/api';
import Loading from '../Loading';
import { urlToFile } from '../../utils/url';
import { DraggableGrid, DraggableRender } from '../DraggableGrid';

const ASPECT_RATIO = 9 / 16;

interface ITotemDeviceSettingsProps {
  deviceId: string;
}

interface IImage {
  id?: number;
  file: File;
  isLocal?: boolean;
  fileName?: string;
}

interface IImageComponentProps {
  file?: File;
  fileName?: string;
  url: string;
  index: number;
  isLocal?: boolean;
  isDragging?: boolean;
  isDragTarget?: boolean;
  deleteImage: (fileName: string) => Promise<void>;
  upload: (file: File, callback: (progress: number) => void) => Promise<void>;
  onRemove: () => void;
}

const ImageComponent: React.FC<IImageComponentProps> = ({
  index,
  file,
  fileName,
  url,
  upload,
  deleteImage,
  onRemove,
  isLocal,
  isDragging,
  isDragTarget,
}) => {
  const [progress, setProgress] = useState(0);

  const uploadFile = useCallback(async () => {
    if (!isLocal || !file) return;

    try {
      await upload(file, setProgress);
    } catch {
      onRemove();
    }
  }, [isLocal, file, onRemove, upload]);

  const handleDelete = useCallback(async () => {
    if (!fileName) return;
    await deleteImage(fileName);
    onRemove();
  }, [fileName, deleteImage, onRemove]);

  useEffect(() => {
    uploadFile();
  }, [uploadFile]);

  return (
    <Image
      key={url}
      className={`${isDragging ? 'dragging' : ''} ${
        isDragTarget ? ' drag-target' : ''
      }`}
    >
      <img src={url} alt={`Imagem totem ${index}`} />

      {fileName && (
        <div className="delete">
          <RemoveButton onClick={handleDelete}>
            <FiTrash size={22} />
          </RemoveButton>
        </div>
      )}

      {isLocal && <ImageLoader progress={progress} />}
    </Image>
  );
};

const TotemDeviceSettings: React.FC<ITotemDeviceSettingsProps> = ({
  deviceId,
}) => {
  const { addToast } = useToast();

  const cropperRef = useRef<HTMLImageElement>(null);
  const imageInputRef = useRef<HTMLInputElement>(null);

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  const [settings, setSettings] = useState<ITotemSettings>({
    appMode: TotemAppMode.DEFAULT,
    enableDemoMode: false,
    enableDriveThru: false,
    enableNfce: false,
    enablesCustomerNameOnOrder: false,
    enableSkipPayment: false,
    enableSubcategoriesLayout: false,
    orderTypeConfig: { checkout: true, onTable: true },
    images: [],
  });

  const [currentImageIndex, setCurrentImageIndex] = useState<number | null>(
    null,
  );
  const [images, setImages] = useState<IImage[]>([]);

  const previews = useMemo(
    () =>
      images.map(({ file, isLocal, fileName }) => ({
        url: URL.createObjectURL(file),
        file,
        isLocal,
        fileName,
      })),
    [images],
  );

  const uploadImage = useCallback(
    async (file: File, uploadCallback: (progress: number) => void) => {
      const formData = new FormData();

      formData.append('image', file);
      const { data } = await api.post<ITotemSettings['images']>(
        `restricted/devices/${deviceId}/kiosk/images`,
        formData,
        {
          onUploadProgress: event => {
            if (event?.lengthComputable) {
              uploadCallback(Math.round((event.loaded / event.total) * 100));
            }
          },
        },
      );

      if (data) {
        const newImages = await Promise.all(
          data.map<Promise<IImage>>(async ({ id, url, fileName }) => ({
            id,
            file: await urlToFile(url),
            fileName,
            isLocal: false,
          })),
        );

        setImages(newImages);
      }
    },
    [deviceId],
  );

  const deleteImage = useCallback(
    async (fileName: string) => {
      await api.delete(
        `restricted/devices/${deviceId}/kiosk/images/${fileName}`,
      );
    },
    [deviceId],
  );

  const save = async (settingsToSave: typeof settings) => {
    try {
      await api.post(`restricted/devices/${deviceId}/config`, settingsToSave);
      addToast({
        type: 'success',
        description: 'Configurações salvas com sucesso!',
      });
    } catch {
      addToast({
        type: 'error',
        description: 'Ocorreu um erro ao salvar as configurações.',
      });
    }
  };

  const handleOnToggleSetting = (
    key: keyof Omit<ITotemSettings, 'orderTypeConfig'>,
  ) => {
    return setSettings(old => {
      const newValue = {
        ...old,
        ...(key === 'appMode'
          ? {
              appMode:
                old.appMode === TotemAppMode.DEFAULT
                  ? TotemAppMode.EVENT
                  : TotemAppMode.DEFAULT,
            }
          : {
              [key]: !old[key],
            }),
      };

      save(newValue);
      return newValue;
    });
  };

  const handleOrderTypePress = (
    key: keyof ITotemSettings['orderTypeConfig'],
  ) => {
    setSettings(old => {
      const newOrderTypeConfig = {
        ...old.orderTypeConfig,
        [key]: !old.orderTypeConfig[key],
      };

      if (Object.values(newOrderTypeConfig).every(value => !value)) return old;

      const newValue = { ...old, orderTypeConfig: newOrderTypeConfig };
      save(newValue);
      return newValue;
    });
  };

  const handleCancelCrop = () => {
    setCurrentImageIndex(null);
    setImages(old => old.slice(0, -1));
  };

  const handleOnInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target?.files ? event.target?.files[0] : null;

    if (file) {
      setImages(old => [...old, { file, isLocal: true }]);
      setCurrentImageIndex(images.length);
    }

    if (event?.target) {
      event.target.value = '';
    }
  };

  const handleImageCrop = async () => {
    const cropper = (cropperRef?.current as any)?.cropper as any;

    const canvas = cropper.getCroppedCanvas({
      width: 1080,
      height: 1920,
      fillColor: '#000',
    });
    const currentImage =
      currentImageIndex !== null ? images[currentImageIndex]?.file : null;

    canvas.toBlob((blob: any) => {
      const file = new File([blob], currentImage?.name || '', {
        type: currentImage?.type,
      });
      setImages(old =>
        old.map((oldFile, index) => ({
          ...oldFile,
          ...(index === currentImageIndex && { file }),
        })),
      );
      setCurrentImageIndex(null);
    }, currentImage?.type);
  };

  const handleAddImage = () => {
    if (images.length === 5) {
      return addToast({
        type: 'error',
        description: 'É permitido no máximo 5 imagens.',
      });
    }

    return imageInputRef?.current?.click();
  };

  const handleRemoveImage = (index: number) => {
    setImages(old => old.filter((_, imageIndex) => imageIndex !== index));
  };

  const handleOnPreviewClick = () => {
    setIsPreviewVisible(true);
  };

  const handleOnPreviewClose = () => {
    setIsPreviewVisible(false);
  };

  const loadConfig = useCallback(
    async (id: string) => {
      setIsLoading(true);

      try {
        const { data } = await api.get<ITotemSettings>(
          `restricted/devices/${id}/config`,
        );
        setSettings(data);

        const files = await Promise.all(
          data?.images?.map<Promise<IImage>>(async ({ id, url, fileName }) => ({
            id,
            file: await urlToFile(url),
            isLocal: false,
            fileName,
          })),
        );

        setImages(files);

        setIsLoading(false);
      } catch (e) {
        addToast({
          type: 'error',
          description: `Ocorreu um erro ao carregar configurações de ${id}.`,
        });
      }
    },
    [addToast],
  );

  const handleDragEnd = async (newPreviews: typeof previews) => {
    const imagePositions: ITotemImagePosition[] = [];
    let oldImages: IImage[] = [];

    setImages(old => {
      oldImages = old;

      return newPreviews.reduce((acc, preview, index) => {
        const image = old.find(image => image.file === preview.file);

        if (!image) return acc;

        imagePositions.push({ id: image?.id || 0, position: index + 1 });
        return [...acc, image];
      }, [] as typeof images);
    });

    try {
      if (
        oldImages.some((image, index) => image.id !== imagePositions[index].id)
      ) {
        await api.patch(
          `restricted/devices/${deviceId}/kiosk/images/position`,
          imagePositions,
        );
      }
    } catch (e) {
      addToast({ type: 'error', description: 'Erro ao ordenar imagens.' });
      setImages(oldImages);
      console.log(e);
    }
  };

  const renderImage: DraggableRender<(typeof previews)[number]> = useCallback(
    (
      { url, file, fileName, isLocal },
      index,
      { isDragging, isDragTarget, isTemp },
    ) =>
      index !== currentImageIndex && (
        <ImageComponent
          file={isTemp ? undefined : file}
          isLocal={isLocal}
          fileName={fileName}
          upload={uploadImage}
          key={url}
          index={index}
          url={url}
          deleteImage={deleteImage}
          onRemove={() => handleRemoveImage(index)}
          isDragging={isDragging}
          isDragTarget={isDragTarget}
        />
      ),
    [currentImageIndex, deleteImage, uploadImage],
  );

  useEffect(() => {
    loadConfig(deviceId);
  }, [deviceId, loadConfig]);

  const currentPreview =
    currentImageIndex !== null ? previews[currentImageIndex] : '';

  if (isLoading) {
    return <Loading />;
  }

  return (
    <>
      <ConfigContainer>
        <GroupSubTitles style={{ marginTop: 8 }}>
          Aceitar pedidos:
        </GroupSubTitles>

        <OptionsContainer>
          <Option
            active={settings?.orderTypeConfig?.onTable}
            onClick={() => handleOrderTypePress('onTable')}
          >
            Na mesa
          </Option>
          <Option
            active={settings?.orderTypeConfig?.checkout}
            onClick={() => handleOrderTypePress('checkout')}
          >
            Para viagem
          </Option>
        </OptionsContainer>
        <ToggleContainer>
          <Toggle
            icons={false}
            checked={settings?.enableDemoMode}
            onChange={() => handleOnToggleSetting('enableDemoMode')}
          />
          <span>Ativar modo demo</span>
        </ToggleContainer>

        <ToggleContainer>
          <Toggle
            icons={false}
            checked={settings?.enableSkipPayment}
            onChange={() => handleOnToggleSetting('enableSkipPayment')}
          />
          <span>Ativar teste de pagamento.</span>
        </ToggleContainer>

        <ToggleContainer>
          <Toggle
            icons={false}
            checked={settings?.appMode === TotemAppMode.EVENT}
            onChange={() => handleOnToggleSetting('appMode')}
          />
          <span>Ativar modo evento.</span>
        </ToggleContainer>

        <ToggleContainer>
          <Toggle
            icons={false}
            checked={settings?.enableDriveThru}
            onChange={() => handleOnToggleSetting('enableDriveThru')}
          />
          <span>Ativar modo Drive thru</span>
        </ToggleContainer>

        <ToggleContainer>
          <Toggle
            icons={false}
            checked={settings?.enableNfce}
            onChange={() => handleOnToggleSetting('enableNfce')}
          />
          <span>Ativar emissão de nota fiscal</span>
        </ToggleContainer>

        <ToggleContainer>
          <Toggle
            icons={false}
            checked={settings?.enablesCustomerNameOnOrder}
            onChange={() => handleOnToggleSetting('enablesCustomerNameOnOrder')}
          />
          <span>Solicitar nome do cliente:</span>
        </ToggleContainer>

        <ToggleContainer>
          <Toggle
            icons={false}
            checked={settings?.enableSubcategoriesLayout}
            onChange={() => handleOnToggleSetting('enableSubcategoriesLayout')}
          />
          <span>Ativar layout de subcategorias:</span>
        </ToggleContainer>

        <Modal
          isOpen={!!currentPreview || isPreviewVisible}
          shouldCloseOnOverlayClick={isPreviewVisible}
          onRequestClose={handleOnPreviewClose}
          style={{
            content: { margin: '4rem' },
            overlay: {
              background: 'rgba(0, 0, 0, .8)',
              height: '100%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            },
          }}
        >
          {currentPreview ? (
            <CropperContent>
              <Cropper
                ref={cropperRef}
                src={currentPreview.url}
                aspectRatio={ASPECT_RATIO}
                style={{ height: '50vh' }}
                autoCropArea={1}
              />

              <CropImageActions>
                <div className="cropImageButtonsContainer">
                  <CancelButton type="button" onClick={handleCancelCrop}>
                    Cancelar
                  </CancelButton>
                  <CropImageAction onClick={handleImageCrop}>
                    Salvar
                    <FiCheck size={24} />
                  </CropImageAction>
                </div>
              </CropImageActions>
            </CropperContent>
          ) : (
            <TotemPreview images={previews} onClose={handleOnPreviewClose} />
          )}
        </Modal>

        <Row>
          <GroupSubTitles style={{ marginTop: 8 }}>Imagens</GroupSubTitles>
          {previews.length > 0 && (
            <ButtonSm buttonStyle="outline" onClick={handleOnPreviewClick}>
              Preview
            </ButtonSm>
          )}
        </Row>

        <Images>
          <DraggableGrid
            items={previews}
            onDragEnd={handleDragEnd}
            disableDrag={previews?.some(({ isLocal }) => isLocal)}
            render={renderImage}
          />

          {images.length < 5 && (
            <>
              <ImageInput
                ref={imageInputRef}
                id="add-image"
                accept="image/x-png,image/jpeg"
                onChange={handleOnInputChange}
              />
              <label
                onClick={handleAddImage}
                style={{
                  justifySelf: 'start',
                }}
              >
                <FiPlus size={28} />
              </label>
            </>
          )}
        </Images>
      </ConfigContainer>
    </>
  );
};

export default TotemDeviceSettings;
