import React, { forwardRef, useImperativeHandle, useRef } from 'react';
import { DateTime } from 'luxon';
import { useReactToPrint } from 'react-to-print';
import {
  Value,
  Footer,
  Header,
  Product,
  Wrapper,
  Payments,
  Complement,
  ClientInfo,
  GridProducts,
  FinishValues,
  HeaderProducts,
  GridComplement,
} from './styles';

import { useCompany } from '../../../../hooks/company';
import { ISendOrderProps } from '../../../../hooks/cashier_orders';

import { getPaymentTypeName } from '../../../../utils/string';
import { formatToCash, formatToMoney } from '../../../../utils/format';

interface ISalesCouponProps {
  order: ISendOrderProps;
}

export interface ISalesCouponPrintRef {
  print: () => void;
}
const SalesCoupon: React.ForwardRefRenderFunction<
  ISalesCouponPrintRef,
  ISalesCouponProps
> = ({ order }, ref) => {
  const containerRef = useRef<HTMLDivElement>(null);

  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
  });

  useImperativeHandle(
    ref,
    () => ({
      print: () => handlePrint && handlePrint(),
    }),
    [handlePrint],
  );
  const { company } = useCompany();

  return (
    <Wrapper ref={containerRef}>
      <Header>
        <img src={company?.logoPrinterUrl} alt="logo" />
        <div className="company">
          <strong>{company?.companyName}</strong>
          <p>{`${company?.streetName}, ${company?.streetNumber}`}</p>
          <p>{company?.neighborhood}</p>
          <p>
            {`${company?.city.name}-${(
              company?.city.state ?? ''
            ).toUpperCase()}`}
          </p>
        </div>
      </Header>

      <ClientInfo>
        <h3>{order?.customerName}</h3>
      </ClientInfo>

      <HeaderProducts>
        <div className="description">
          <p>qtd</p>
          <p>descrição</p>
        </div>
        <p>unidade</p>
        <p>total</p>
      </HeaderProducts>

      <GridProducts>
        {order?.items.map(product => {
          const additionals = product.complementsGroups.filter(cp => !cp?.id);

          const complements = product.complementsGroups.filter(
            cp => (cp?.id ?? 0) > 0,
          );
          const isPizza = product.complementsGroups.some(
            cp => (cp?.id ?? 0) < 0,
          );

          const flavours = product.complementsGroups.filter(
            cp => (cp?.id ?? 0) === -8,
          );
          const crusts = product.complementsGroups.filter(
            cp => (cp?.id ?? 0) === -9,
          );

          const edges = product.complementsGroups.filter(
            cp => (cp?.id ?? 0) === -10,
          );

          return (
            <Product key={product.hash}>
              <div className="description">
                <p>{`${product.qty} X`}</p>
                <p>{product.title}</p>
              </div>
              <div className="price">
                <p>{formatToCash(product.unitPrice)}</p>
                <p>{formatToCash(product.total ?? 0)}</p>
              </div>
              <div className="complements">
                {isPizza && (
                  <div className="complements">
                    {flavours?.length > 0 && (
                      <GridComplement>
                        <h4>Sabores</h4>
                        {flavours.map(group =>
                          group.complements.map(flav => (
                            <Complement key={flav.id}>
                              <p>{`${flav.amount}/${group.complements.length}`}</p>
                              <p>{flav.title}</p>
                            </Complement>
                          )),
                        )}
                      </GridComplement>
                    )}
                    {crusts?.length > 0 && (
                      <GridComplement>
                        <h4>Massa</h4>
                        {crusts.map(group =>
                          group.complements.map(crust => (
                            <Complement key={crust.id}>
                              <p>{`${crust.amount} X`}</p>
                              <p>{crust.title}</p>
                            </Complement>
                          )),
                        )}
                      </GridComplement>
                    )}
                    {edges?.length > 0 && (
                      <GridComplement>
                        <h4>Borda</h4>
                        {edges.map(group =>
                          group.complements.map(edge => (
                            <Complement key={edge.id}>
                              <p>{`${edge.amount} X`}</p>
                              <p>{edge.title}</p>
                            </Complement>
                          )),
                        )}
                      </GridComplement>
                    )}
                  </div>
                )}

                {complements?.length > 0 && (
                  <GridComplement>
                    <h4>Complementos</h4>
                    {complements.map(group =>
                      group.complements.map(compl => (
                        <Complement key={compl.id}>
                          <p>{`${compl.amount} X`}</p>
                          <p>{compl.title}</p>
                        </Complement>
                      )),
                    )}
                  </GridComplement>
                )}
                {additionals?.length > 0 && (
                  <GridComplement>
                    <h4>Adicionais</h4>
                    {additionals.map(group =>
                      group.complements.map(add => (
                        <Complement key={add.id}>
                          <p>{`${add.amount} X`}</p>
                          <p>{add.title}</p>
                        </Complement>
                      )),
                    )}
                  </GridComplement>
                )}
              </div>
            </Product>
          );
        })}
      </GridProducts>
      <div className="values">
        <FinishValues>
          {order.subtotal ? (
            <Value>
              <p>Subtotal</p>
              <p>{formatToMoney(order.subtotal)}</p>
            </Value>
          ) : null}
          {order.serviceFee ? (
            <Value>
              <p>Taxa de Serviço</p>
              <p>{formatToMoney(order.serviceFee)}</p>
            </Value>
          ) : null}
          {order.discount ? (
            <Value>
              <p>Desconto</p>
              <p>{`- ${formatToMoney(order.discount)}`}</p>
            </Value>
          ) : null}
          {order.total ? (
            <Value className="last">
              <h3>Total</h3>
              <h3>{formatToMoney(order.total)}</h3>
            </Value>
          ) : null}
          <Payments>
            <h4>Pagamento</h4>
            {order.paymentsTransactions.map(pay => (
              <Value>
                <p>{getPaymentTypeName(pay.paymentType)}</p>
                <p>{formatToMoney(pay.amount)}</p>
              </Value>
            ))}
            {order.change ? (
              <Value>
                <p>Troco</p>
                <p>{`${formatToMoney(order?.change)}`}</p>
              </Value>
            ) : null}
          </Payments>
        </FinishValues>
      </div>
      <Footer>
        <p>
          {`Data da impressão: ${DateTime.utc()
            .minus({ hours: 3 })
            .toFormat("dd/MM/yyyy 'às' HH:mm:ss")}`}
        </p>
        <p>Feito com ❤ por Build Solutions.</p>
      </Footer>
    </Wrapper>
  );
};

export default forwardRef(SalesCoupon);
