import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { MaskedCurrency } from 'react-easy-mask';
import Modal from '../Modal';
import { useCashDrawer } from '../../hooks/cash_drawers';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { WrapperModal } from './styles';

interface IModalOpenCashierProps {
  open: boolean;
  lastShiftCloseAmount: number;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setKey: Dispatch<SetStateAction<number>>;
}
const ModalOpenCashier: React.FC<IModalOpenCashierProps> = ({
  open,
  lastShiftCloseAmount,
  setOpen,
  setKey,
}) => {
  const { openCashier } = useCashDrawer();
  const { ThrowError } = useConfirmDialog();
  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState(lastShiftCloseAmount);
  const [errorValue, setErrorValue] = useState(false);

  useEffect(() => {
    setValueInput(lastShiftCloseAmount);
  }, [lastShiftCloseAmount]);

  const onConfirm = async () => {
    try {
      setLoading(true);

      if (valueInput < 0) {
        setLoading(false);
        setErrorValue(true);
        return;
      }
      setErrorValue(false);

      await openCashier(valueInput);
      setKey(prev => prev + 1);
    } catch (error) {
      ThrowError(error, 'Erro ao fazer a operação');
    } finally {
      setLoading(false);
    }

    setLoading(false);
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      loading={loading}
    >
      <WrapperModal error={false}>
        <div className="title">
          <h2>Abrir Caixa</h2>
        </div>

        <div>
          <p>Valor</p>
          <MaskedCurrency
            locale="pt-BR"
            currency="BRL"
            defaultValue={valueInput}
            onChangeValue={value => setValueInput(value)}
          />
          <p className="error">
            {errorValue && 'O Valor não pode ser menor que zero!'}
          </p>
        </div>
      </WrapperModal>
    </Modal>
  );
};

export default ModalOpenCashier;
