import styled from 'styled-components';

export const Wrapper = styled.div`
  display: none;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  gap: 2px;
  width: 100%;
  @media (max-width: 700px) {
    display: grid;
  }
`;

export const ValueCard = styled.div<{ totalPay?: boolean }>`
  display: flex;
  padding: 5px;
  width: 100%;
  background-color: ${({ theme, totalPay }) =>
    totalPay ? theme.palette.success : theme.palette.control_light};
  color: ${({ theme, totalPay }) =>
    totalPay ? theme.palette.text_white : theme.palette.text_light};
  justify-content: space-between;

  .edit {
    display: flex;
    gap: 10px;

    svg {
      transition: 300ms;

      &:hover {
        cursor: pointer;
        transform: scale(1.2);
        /* border-radius: 10px;
        background-color: ${({ theme }) => theme.palette.control_light}; */
      }
    }
  }
`;
