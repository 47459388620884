import React, { useCallback, useEffect, useState } from 'react';

import { FiX } from 'react-icons/fi';

import ICategory from '../../models/ICategory';

import { useToast } from '../../hooks/toast';
import { useCategories } from '../../hooks/categories';

import OrderableCategory from '../OrderableCategory';
import OrderSubcategoriesModal from '../OrderSubcategoriesModal';

import {
  Main,
  Title,
  Header,
  Message,
  PageInfo,
  Container,
  BackButton,
  Categories,
  StyledDraggable,
  StyledDroppable,
  StyledDragDropContext,
  DraggableContainer,
} from './styles';
import SaveButton from '../SaveButton';
import { AuthRole } from '../../hooks/auth';

interface IOrderCategoriesModalProps {
  visible: boolean;
  onClose: () => void;
  onConfirm: (order: IOrderable[]) => void;
}

export interface IOrderable {
  position: number;
  categoryId: number;
}

const OrderCategoriesModal: React.FC<IOrderCategoriesModalProps> = ({
  visible,
  onClose,
  onConfirm,
}) => {
  const { addToast } = useToast();
  const { categories, orderSubcategories } = useCategories();

  const [orderedCategories, setOrderedCategories] = useState<ICategory[]>([]);

  const [isOrderSubcategoriesModalOpen, setIsOrderSubcategoriesModalOpen] =
    useState(false);

  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null,
  );

  useEffect(() => {
    if (categories.length > 0) {
      setOrderedCategories(
        categories
          .filter(c => c.isCategory)
          .sort((current, next) => {
            if (current.position < next.position) {
              return -1;
            }

            if (current.position === next.position) {
              return 0;
            }

            return 1;
          }),
      );
    }
  }, [categories]);

  const handleOnConfirm = useCallback(() => {
    const orderedArray: IOrderable[] = orderedCategories.map((c, index) => {
      return {
        position: index,
        categoryId: c.id,
      };
    }, []);

    onConfirm(orderedArray);
  }, [orderedCategories, onConfirm]);

  const reorderCategories = useCallback(
    (list, startIndex: number, endIndex) => {
      const result = Array.from(list);
      const [removed] = result.splice(startIndex, 1);
      result.splice(endIndex, 0, removed);

      return result;
    },
    [],
  );

  const handleOnDragEnd = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (result: any) => {
      if (!result.destination) {
        return;
      }

      if (result.destination.index === result.source.index) {
        return;
      }

      const newCategories = reorderCategories(
        orderedCategories,
        result.source.index,
        result.destination.index,
      );

      setOrderedCategories(newCategories as ICategory[]);
    },
    [reorderCategories, orderedCategories],
  );

  const handleSelectCategory = useCallback((category: ICategory) => {
    setSelectedCategory(category);
    setIsOrderSubcategoriesModalOpen(true);
  }, []);

  const handleOnOrderModalClosed = useCallback(() => {
    setIsOrderSubcategoriesModalOpen(false);
  }, []);

  const handleOnOrderSubcategories = useCallback(
    async (order: IOrderable[]) => {
      try {
        await orderSubcategories(selectedCategory?.id || 0, order);

        addToast({
          type: 'success',
          description: 'Subcategorias ordenadas!',
        });
        setIsOrderSubcategoriesModalOpen(false);
      } catch {
        addToast({
          type: 'error',
          description: 'Verifique sua conexão e tente novamente.',
        });
      }
    },
    [selectedCategory, orderSubcategories, addToast],
  );

  return (
    <Container visible={visible}>
      <Header>
        <PageInfo>
          <BackButton onClick={onClose}>
            <FiX size={32} />
          </BackButton>
          <Title>Ordenação de categorias</Title>
        </PageInfo>
        <AuthRole blackList={['Employee']}>
          <SaveButton onClick={handleOnConfirm} />
        </AuthRole>
      </Header>
      <Main>
        <Message>
          Clique, segure e arraste os itens para alterar a order de exibição.
        </Message>
        <Categories className="has-custom-scroll-bar-2">
          <StyledDragDropContext onDragEnd={handleOnDragEnd}>
            <StyledDroppable droppableId="order-categories">
              {(providedDroppable, snapshot) => (
                <div
                  style={snapshot.isDraggingOver ? { background: '#ccc3' } : {}}
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                >
                  {orderedCategories.map((category, index) => (
                    <StyledDraggable
                      index={index}
                      key={category.id}
                      draggableId={category.id.toString()}
                    >
                      {providedDraggable => (
                        <DraggableContainer
                          ref={providedDraggable.innerRef}
                          {...providedDraggable.draggableProps}
                          {...providedDraggable.dragHandleProps}
                          role="button"
                          tabIndex={-1}
                        >
                          <OrderableCategory
                            category={category}
                            position={index + 1}
                            onClick={handleSelectCategory}
                          />
                        </DraggableContainer>
                      )}
                    </StyledDraggable>
                  ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </StyledDroppable>
          </StyledDragDropContext>
        </Categories>
      </Main>
      <OrderSubcategoriesModal
        selectedCategory={selectedCategory}
        visible={isOrderSubcategoriesModalOpen}
        onClose={handleOnOrderModalClosed}
        onConfirm={handleOnOrderSubcategories}
      />
    </Container>
  );
};

export default OrderCategoriesModal;
