import styled from 'styled-components';
import { statusCardTableType } from '../../enums/cardAndTableType';

interface IContainerCardProps {
  status: statusCardTableType;
}

export const ContainerCard = styled.div<IContainerCardProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme, status }) =>
    status === 'FREE'
      ? '#85D1A5'
      : status === 'CLOSING'
      ? '#FDD400'
      : theme.palette.primary_light};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 5px;
  font-size: 12px;
  color: ${({ theme }) => theme.palette.text_white};
  cursor: pointer;
  .cardNumber {
    font-size: 21px;
    p {
      width: auto;
    }
  }

  .description {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
