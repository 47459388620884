import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Loading from '../../components/Loading';
import Header from '../../components/Header';
import { PageNames } from '../../enums/pages';
import UpModal from '../../components/UpModal';
import { useWaiter } from '../../hooks/waiter';
import { defaultWaiter, IWaiter } from '../../models/IWaiter';
import { CardWaiters } from '../../components/CardWaiters';
import ModalEditWaiter from '../../components/ModalEditWaiter';
import { NotFound, WaitersWrapper, WaitersPadding, Grid } from './styles';

const WaitersPage: React.FC = () => {
  const [waiters, setWaiters] = useState<IWaiter[]>([]);
  const [openModal, setOpenModal] = useState({
    active: false,
    waiter: waiters[0],
  });
  const [searchCriteria, setSearchCriteria] = useState('');
  const { getWaiters, isLoading } = useWaiter();

  useEffect(() => {
    const fetchWaiters = async () => {
      const waitersData = await getWaiters();
      if (waitersData.length > 0) {
        setWaiters(waitersData);
      }
    };

    fetchWaiters();
  }, [getWaiters]);

  const searchedWaiters = useMemo(() => {
    return waiters.filter(waiter => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      return waiter.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [searchCriteria, waiters]);

  const handleOpenModal = (waiter?: IWaiter) => {
    if (waiter) {
      setOpenModal({ active: !openModal.active, waiter });
    } else {
      setOpenModal({ active: !openModal.active, waiter: defaultWaiter });
    }
  };
  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);
  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <WaitersWrapper>
          <WaitersPadding>
            <Header
              selectedPage={PageNames.WAITER}
              searchCriteria={searchCriteria}
              handleOpenModal={handleOpenModal}
              handleOnSearchCriteriaChanged={handleOnSearchCriteriaChanged}
            />
            <Grid className="has-custom-scroll-bar-2">
              {searchedWaiters.length > 0 ? (
                (searchedWaiters ?? []).map((waiter: IWaiter) => (
                  <CardWaiters
                    key={waiter?.id}
                    waiter={waiter}
                    onClick={() => handleOpenModal(waiter)}
                    setWaiters={setWaiters}
                  />
                ))
              ) : (
                <NotFound>Nenhum Atendente encontrado!</NotFound>
              )}
            </Grid>
          </WaitersPadding>

          <UpModal visible={openModal?.active} onClose={handleOpenModal}>
            {openModal && (
              <ModalEditWaiter
                onClose={() => handleOpenModal()}
                waiter={openModal.waiter}
                setWaiters={setWaiters}
              />
            )}
          </UpModal>
        </WaitersWrapper>
      )}
    </>
  );
};

export default WaitersPage;
