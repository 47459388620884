import React, { Dispatch, SetStateAction, useState } from 'react';
import { MaskedCurrency } from 'react-easy-mask';
import Modal from '../Modal';
import { WrapperModal } from './styles';
import BasicInput from '../BasicInput';
import { useCashDrawer } from '../../hooks/cash_drawers';
import { useConfirmDialog } from '../../hooks/confim_dialog';

interface IModalSupplyCashierWithdrawalProps {
  open: boolean;
  type: 'supply' | 'withdrawal';
  setKey: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const ModalSupplyCashierWithdrawal: React.FC<
  IModalSupplyCashierWithdrawalProps
> = ({ open, type, setOpen, setKey }) => {
  const { Supply, CashWithdrawal } = useCashDrawer();
  const { ThrowError } = useConfirmDialog();

  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState(0);
  const [description, setDescription] = useState('');
  const [errorValue, setErrorValue] = useState(false);
  const [errorDescription, setErrorDescription] = useState(false);

  const onConfirm = async () => {
    try {
      setLoading(true);
      if (description.length === 0) {
        setLoading(false);
        setErrorDescription(true);
        setErrorValue(false);
        return;
      }
      setErrorDescription(false);
      if (valueInput <= 0) {
        setLoading(false);
        setErrorValue(true);
        return;
      }
      setErrorValue(false);

      const body = {
        description,
        amount: valueInput,
      };

      type === 'supply' && (await Supply(body));
      type === 'withdrawal' && CashWithdrawal(body);
      // Estado alterado somente para forçar a requisição de summary, na mesma requisição o status será consertado
      setKey(prev => prev + 1);
    } catch (error) {
      ThrowError(error, 'Erro ao fazer a operação');
    } finally {
      setLoading(false);
    }

    // zerando valores
    setValueInput(0);
    setDescription('');
    setLoading(false);
    setOpen(false);
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      loading={loading}
    >
      <WrapperModal error={false}>
        <div className="title">
          {type === 'supply' && <h2>Suprimento</h2>}
          {type === 'withdrawal' && <h2>Sangria</h2>}
        </div>

        <div className="inputs">
          <BasicInput
            title="Descrição"
            value={description}
            onChange={value => setDescription(value.target.value)}
            placeholder={
              type === 'supply' ? 'Falta de troco...' : 'Pagamento da água...'
            }
            error={errorDescription ? 'O campo descrição é obrigatório' : ''}
          />
          <div>
            <p>Valor</p>
            <MaskedCurrency
              locale="pt-BR"
              currency="BRL"
              defaultValue={valueInput}
              onChangeValue={value => setValueInput(value)}
            />
            <p className="error">
              {errorValue && 'O Valor não pode ser zero!'}
            </p>
          </div>
        </div>
      </WrapperModal>
    </Modal>
  );
};

export default ModalSupplyCashierWithdrawal;
