import React, { useCallback, useMemo, useRef, useState } from 'react';

import {
  Card,
  Text,
  Footer,
  Container,
  Indicator,
  Background,
  StepButtons,
  ElementPlaceholder,
  IndicatorsContainer,
} from './styles';

type Position = 'left' | 'right' | 'top' | 'bottom' | 'top-left';

export interface IStep {
  id?: string;
  text: string;
  position?: Position;
}

interface TourProps {
  steps: IStep[];
  onFinish?: () => void;
}

const Tour: React.FC<TourProps> = ({ steps, onFinish }) => {
  const [visible, setVisible] = useState(true);
  const [currentStep, setCurrentStep] = useState(0);
  const highlightSpotRef = useRef<HTMLDivElement | null>(null);

  const goToElement = useMemo(
    () => (id?: string) => {
      if (id) {
        const element = document.getElementById(id);

        if (element && highlightSpotRef.current) {
          element.scrollIntoView();
          const { offsetWidth, offsetHeight } = element;

          const { left, top } = element.getBoundingClientRect();

          highlightSpotRef.current.style.width = `${offsetWidth}px`;
          highlightSpotRef.current.style.height = `${offsetHeight}px`;
          highlightSpotRef.current.style.transform = `translate(${left}px, ${top}px)`;
        }
      } else if (highlightSpotRef.current) {
        highlightSpotRef.current.style.width = '0';
        highlightSpotRef.current.style.height = '0';
        highlightSpotRef.current.style.transform = 'translate(50vw, 50vh)';
      }
    },
    [],
  );

  const currentStepObject = useMemo(() => {
    const element = steps[currentStep];
    setTimeout(() => goToElement(element?.id), 100);
    return element;
  }, [steps, currentStep, goToElement]);

  const handleNext = useCallback(() => {
    setCurrentStep(old =>
      old + 1 >= steps.length ? steps.length - 1 : old + 1,
    );
  }, [steps]);

  const handlePrevious = useCallback(() => {
    setCurrentStep(old => (old - 1 < 0 ? 0 : old - 1));
  }, []);

  const handleIndicatorClick = useCallback((index: number) => {
    setCurrentStep(index);
  }, []);

  const handleFinishOrSkipClick = useCallback(() => {
    setVisible(false);
    onFinish && setTimeout(onFinish, 340);
  }, [onFinish]);

  return (
    <Container visible={visible}>
      {currentStepObject && (
        <Background>
          <ElementPlaceholder ref={highlightSpotRef}>
            <Card position={currentStepObject?.position || 'center'}>
              <Text>
                <span>{currentStepObject.text}</span>
              </Text>
              <IndicatorsContainer>
                {steps.map((_, index) => (
                  <Indicator
                    active={index === currentStep}
                    onClick={() => handleIndicatorClick(index)}
                  />
                ))}
              </IndicatorsContainer>
              <Footer>
                <button
                  type="button"
                  className="ignore"
                  onClick={handleFinishOrSkipClick}
                >
                  Sair
                </button>

                <StepButtons>
                  <button type="button" onClick={handlePrevious}>
                    Anterior
                  </button>

                  {currentStep !== steps.length - 1 ? (
                    <button type="button" onClick={handleNext} className="next">
                      Próximo
                    </button>
                  ) : (
                    <button
                      type="button"
                      onClick={handleFinishOrSkipClick}
                      className="next"
                    >
                      Finalizar
                    </button>
                  )}
                </StepButtons>
              </Footer>
            </Card>
          </ElementPlaceholder>
        </Background>
      )}
    </Container>
  );
};

export default Tour;
