import styled, { keyframes, css } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: transparent;
  width: 100%;
  position: sticky;
  bottom: 0;
  border-radius: 16px 16px 0px 0px;
  align-items: center;
  overflow: visible;
  z-index: 10;
`;
export const FinishResponsive = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background_white};
  width: 100%;
  position: sticky;
  bottom: 0;
  border-radius: 16px 16px 0px 0px;
  align-items: center;
  overflow: visible;
  z-index: 10;
  padding: 8px 32px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  gap: 10px;

  @media (max-width: 600px) {
    padding: 8px 16px;
  }
`;

export const Value = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end;
  color: ${({ theme }) => theme.palette.text};
  gap: 0.5rem;

  strong {
    font-size: 22px;
    margin-bottom: -2px;
    @media (max-width: 1024px) {
      font-size: 18px;
    }
  }
`;
export const Buttons = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  place-content: space-between;
`;

export const OptionButton = styled.div`
  display: flex;
  padding: 12px;
  gap: 15px;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  transition: all 0.3s ease; /* Transição suave para todas as mudanças */

  /* Efeito de sombra e elevação no hover */
  &:hover {
    transform: translateX(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  @media (max-width: 600px) {
    padding: 6px;
  }
`;

export const FinishButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};
  transition: transform 0.3s;
  border-radius: 16px 0 0 16px;
  transform: translateX(10%);
  margin-right: 8px;
  &:hover {
    transform: translateX(5%);
  }
  .button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px;
    * {
      color: ${({ theme }) => theme.palette.text_white};
    }
    span {
      font-size: 14px;
    }
    svg {
      width: 24px;
      height: 24px;
      z-index: 10;
      display: block;
    }

    @media (max-width: 1024px) {
      padding: 12px;
    }
  }
`;

export const History = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 100%;
  margin-bottom: ${({ isVisible }) => (isVisible ? 0 : '-0.5rem')};

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.text_white};
    gap: 5px;
    padding: 0 5px;
    border-radius: 8px 8px 0 0;
    background-color: ${({ theme, isVisible }) =>
      isVisible ? theme.palette.primary : theme.palette.primary_light};
    transition: 400ms;
    border: none;

    :hover {
      background-color: ${({ theme }) => theme.palette.primary};
      transform: translateY(-10%);
    }

    ${({ isVisible }) =>
      isVisible
        ? css`
            animation: ${slideIn} 0.5s forwards;
          `
        : css`
            animation: ${slideOut} 0.5s forwards;
          `}
  }
`;

const slideIn = keyframes`
  from {
    transform: translateY(100%);
  }
  to {
    transform: translateY(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(110%);
  }
`;
export const Log = styled.div<{ isVisible: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: ${({ isVisible }) => (isVisible ? '20vh' : '0px')};
  background-color: ${({ theme }) => theme.palette.background};
  overflow: auto;
  border-radius: 8px 8px 0 0;
  padding: 16px;

  ${({ isVisible }) =>
    isVisible
      ? css`
          animation: ${slideIn} 0.5s forwards;
        `
      : css`
          animation: ${slideOut} 0.5s forwards;
        `}
`;

export const LineLog = styled.div`
  display: flex;
  gap: 1rem;
`;
