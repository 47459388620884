import React from 'react';
import { Input, Label, Wrapper } from './styles';

interface IInputSellProps {
  label: string;
  value: string;
  onClick: () => void;
  onChange: (e: any) => void;
  onKeyPress?: React.KeyboardEventHandler<HTMLInputElement>;
}

const InputSell: React.FC<IInputSellProps> = ({
  label,
  value,
  onClick,
  onChange,
  onKeyPress,
}) => {
  return (
    <Wrapper>
      <Label onClick={onClick}>
        <p>{label}</p>
      </Label>
      <Input
        value={value}
        onChange={e => onChange(e.target.value)}
        onKeyPress={onKeyPress}
      />
    </Wrapper>
  );
};

export default InputSell;
