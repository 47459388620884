import React, { useState } from 'react';
import { FiArrowLeft } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';
import { Close, Container, Header, Wrapper } from './styles';
import GridCardsAndTables from '../../components/GridCardsAndTables';
import IconButton from '../../components/IconButton';
import { useSidebar } from '../../hooks/sidebar';
import { PageNames } from '../../enums/pages';
import { PaymentDrawerProvider } from '../../hooks/payment_drawer';
import PaymentDrawer from '../../components/PaymentDrawer';

const CardsAndTablesPage: React.FC = () => {
  const { setSelectedPage } = useSidebar();
  const [close, setClose] = useState<boolean>(
    window.location.pathname !== '/sell',
  );

  const history = useHistory();
  return (
    <Wrapper close={close}>
      <Container>
        <Header>
          <Close>
            <IconButton
              icon={<FiArrowLeft size={22} />}
              onClick={() => {
                setClose(true);
                setTimeout(() => {
                  history.push('/');
                  setSelectedPage(PageNames.DASHBOARD);
                }, 500);
              }}
            />
          </Close>
          <h3>Mesas & Comandas</h3>
        </Header>
        <PaymentDrawerProvider>
          <GridCardsAndTables />
          <PaymentDrawer />
        </PaymentDrawerProvider>
      </Container>
    </Wrapper>
  );
};

export default CardsAndTablesPage;
