import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    font-family: 'Poppins';
    -webkit-tap-highlight-color: transparent;
  }
  html, body, #root {
    width: 100%;
    height: 100vh;
    height: -webkit-fill-available;
  }

  button{
   outline: 0;
   color: ${({ theme }) => theme.palette.text}
  }
  body {
    -webkit-font-smoothing: antialiased;
    overflow: hidden;
  }
  body, input, button, textarea {
    font-size: 16px;
  }
  h1, h2, h3, h4, h5, h6, strong {
    font-weight: 500;
  }
  button {
    cursor: pointer;
  }

  input::ms-reveal{
    display: none !important;
  }
  input{
    ::placeholder {
    color: #dedede;
  }
  }


  .has-custom-scroll-bar {
    @media(min-width:  600px) {

      &::-webkit-scrollbar-thumb {
        border: 12px solid rgba(0, 0, 0, 0);
        background-clip: padding-box;
        background-color: rgba(0, 0, 0, 0.1);
        box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
          inset 1px 1px 0px rgba(0, 0, 0, 0.05);
        border-radius: 24px;
      }

      &::-webkit-scrollbar {
        width: 28px;
      }


      &::-webkit-scrollbar-button {
        width: 0;
        height: 0;
        display: none;
      }

      &::-webkit-scrollbar-corner {
        background-color: transparent;
      }
    }
  }

  .has-custom-scroll-bar-2 {
    @media(min-width: 600px) {
      ::-webkit-scrollbar {
        width: 6px;
        height: 8px;
      }

      ::-webkit-scrollbar-track {
        background: #f1f1f1;
      }

      ::-webkit-scrollbar-thumb {
        background: #636e72;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #2d3436;
      }
    }
  }
  .has-custom-scroll-bar-3 {
    @media(min-width:  600px) {
      ::-webkit-scrollbar {
        width: 6px;
      }

      ::-webkit-scrollbar-track {
        background: ${({ theme }) => theme.palette.primary}30;
      }

      ::-webkit-scrollbar-thumb {
        background: #636e72;
      }

      ::-webkit-scrollbar-thumb:hover {
        background: #2d3436;
      }
    }
  }

  ::-moz-selection {
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.text_white};
  }

  ::selection {
    background: ${({ theme }) => theme.palette.primary};
    color: ${({ theme }) => theme.palette.text_white};
  }

  .react-calendar__tile--now {
    color: ${({ theme }) => theme.palette.text_white};
    background: ${({ theme }) => theme.palette.primary};
  }

  .react-calendar__tile--now:enabled:hover {
    color: ${({ theme }) => theme.palette.text_white};
    background: ${({ theme }) => theme.palette.primary}90;
  }

  .react-date-picker__clear-button {
    display: none;
  }
  .react-toggle--focus .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .react-toggle:active:not(.react-toggle--disabled) .react-toggle-thumb {
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .react-toggle .react-toggle-track {
    background-color: #c0392b;
  }

  .react-toggle--checked .react-toggle-track {
    background-color: #27ae60;
  }

  .react-toggle:hover:not(.react-toggle--disabled) .react-toggle-track {
    background-color: #992d22;
  }

  .react-toggle--checked:hover:not(.react-toggle--disabled)
    .react-toggle-track {
    background-color: #1f8b4c;
  }

  .react-toggle-thumb {
    border: 0 !important;
  }

  .disabledElement {
    *, & {
      user-select: none !important;
      pointer-events: none !important;
      filter: opacity(0.65) !important;
    }
  }

  .focus-animation {
    animation: focusAnimation 1.5s cubic-bezier(.73,.11,.35,1) 3 500ms;
  }

  @keyframes focusAnimation {
    0% {
      border: inherit;
    }
    50% {
      box-shadow: 0 0 4px 1px ${({ theme }) => theme.palette.primary};
    }
    100% {
      border: inherit;
    }
  }

  .block-interactions {
    pointer-events: none !important;
    user-select: none !important;
  }
    .block-interactions {
    pointer-events: none !important;
    user-select: none !important;
  }
`;
