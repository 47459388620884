import React, {
  Dispatch,
  useState,
  useEffect,
  useCallback,
  SetStateAction,
} from 'react';
import { MaskedCurrency } from 'react-easy-mask';

import {
  Btn,
  Title,
  Column,
  Wrapper,
  StyledInput,
  StyledInputDiscount,
} from './styles';

import Modal from '../Modal';
import Button from '../Button';
import BasicInput from '../BasicInput';

interface IModalServiceDiscountProps {
  open: boolean;
  value: number;
  totalValue: number;
  type: 'discount' | 'service' | 'addition';
  setOpen: Dispatch<SetStateAction<boolean>>;
  setValue: React.Dispatch<React.SetStateAction<number>>;
}
const ModalServiceDiscount: React.FC<IModalServiceDiscountProps> = ({
  open,
  type,
  value,
  totalValue,
  setOpen,
  setValue,
}) => {
  const calculedValue = (value / totalValue) * 100;
  const [percent, setPercent] = useState(
    calculedValue ? `${calculedValue}%` : '0%',
  );
  const [valueNumber, setValueNumber] = useState(value);

  const handleInputPercent = (e: any) => {
    const inputValue = e.target.value.replace(/[^0-9]/g, ''); // Remove tudo que não é número

    if (inputValue === '') {
      setPercent('');
      setValueNumber(0);
      return;
    }

    const numericValue = Number(inputValue);

    if (numericValue <= 100) {
      setPercent(`${numericValue}%`); // Mantém a string com '%'
      setValueNumber((totalValue * numericValue) / 100);
    } else {
      setPercent('100%'); // Limita a 100%
      setValueNumber(totalValue);
    }
  };

  const handleKeyDown = (e: any) => {
    if (e.key === 'Backspace') {
      e.preventDefault();
      const currentValue = percent.replace('%', '');
      const newValue = currentValue.slice(0, -1);

      setPercent(newValue ? `${newValue}%` : '');
      setValueNumber((totalValue * Number(newValue)) / 100);
    }
  };

  const handleRemoveRate = () => {
    if (value === 0) {
      const calculedValue = (totalValue * 10) / 100;
      setPercent('10%');
      setValueNumber(calculedValue);
      setValue(calculedValue);
    } else {
      setPercent('0%');
      setValueNumber(0);
      setValue(0);
    }
    setOpen(false);
  };

  const handleConfirm = useCallback(() => {
    setValue(valueNumber);
    setOpen(false);
  }, [setOpen, setValue, valueNumber]);

  const handleChangeValue = (e: number) => {
    if (e <= totalValue || type === 'addition') {
      setValueNumber(Number(e.toFixed(2)));
      const calculedPercent = (e / totalValue) * 100;
      setPercent(`${String(calculedPercent.toFixed(2))}%`);
    } else {
      setValueNumber(totalValue);
      setPercent('100%');
    }
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleConfirm();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleConfirm, open, valueNumber]);

  return (
    <Modal open={open} setOpen={setOpen} onConfirm={handleConfirm}>
      <Title>
        <h3>
          {type === 'discount'
            ? 'Desconto'
            : type === 'service'
            ? 'Taxa de Serviço'
            : 'Acréscimo'}
        </h3>
      </Title>
      <Wrapper>
        <Column>
          {type !== 'addition' && (
            <BasicInput
              title="Porcentagem"
              placeholder="10%"
              value={percent}
              onChange={handleInputPercent}
              onKeyDown={handleKeyDown}
              scale="lg"
            />
          )}

          {type === 'service' ? (
            <StyledInput>
              <p>Valor</p>
              <span>
                {new Intl.NumberFormat('pt-BR', {
                  style: 'currency',
                  currency: 'BRL',
                }).format(valueNumber)}
              </span>
            </StyledInput>
          ) : (
            <StyledInputDiscount>
              <p>Valor</p>
              <MaskedCurrency
                locale="pt-BR"
                currency="BRL"
                defaultValue={Number(valueNumber.toFixed(2)) ?? 0}
                onChangeValue={handleChangeValue}
                max={type === 'addition' ? 999999 : totalValue}
              />
            </StyledInputDiscount>
          )}
        </Column>
        <Btn>
          {type !== 'addition' &&
            (value === 0 ? (
              <Button
                side="B"
                title={type === 'service' ? 'Aplicar Taxa' : 'Aplicar Desconto'}
                onClick={handleRemoveRate}
              />
            ) : (
              <Button
                side="B"
                title={type === 'service' ? 'Remover Taxa' : 'Remover Desconto'}
                onClick={handleRemoveRate}
              />
            ))}
        </Btn>
      </Wrapper>
    </Modal>
  );
};

export default ModalServiceDiscount;
