import React, { useCallback, useRef, useState } from 'react';

import * as Yup from 'yup';
import Toggle from 'react-toggle';
import { FormHandles } from '@unform/core';
import { MdContentCopy } from 'react-icons/md';
import { writeText } from 'clipboard-polyfill/text';

import {
  Wrapper,
  GroupTitle,
  CompanyCode,
  GroupContainer,
  ToggleContainer,
  ContentContainer,
  HorizontalWrapper,
  CompanyCodeContainer,
} from './styles';

import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { getValidationErrors } from '../../../utils/errors';

import ICompany, { CompanyToggleOptions } from '../../../models/ICompany';
import { IFacebookPixelIntegrationDTO } from '../../../dtos/IIntegrationDTO';

import Input from '../../Input';
import SaveButton from '../../SaveButton';

interface IIntegrationSettingsProps {
  apiKey: string;
  clientId: string;
  company: ICompany | null;
  isSavingCompany: boolean | undefined;
  toggleOption: (
    name: CompanyToggleOptions,
    companyField?: keyof ICompany,
  ) => Promise<void>;
  saveFacebookPixelIntegration: (
    integration: IFacebookPixelIntegrationDTO,
  ) => Promise<void>;
  setApiKey: React.Dispatch<React.SetStateAction<string>>;
  setClientId: (value: React.SetStateAction<string>) => void;
}
const IntegrationSettings: React.FC<IIntegrationSettingsProps> = ({
  apiKey,
  company,
  clientId,
  isSavingCompany,
  setApiKey,
  setClientId,
  toggleOption,
  saveFacebookPixelIntegration,
}) => {
  const integrationFormRef = useRef<FormHandles | null>(null);
  const { addToast } = useToast();

  const [showApiKey, setShowApiKey] = useState(false);
  const handleOnIntegrationSaved = useCallback(
    async (data: IFacebookPixelIntegrationDTO) => {
      const schema = Yup.object().shape({
        fbPixelId: Yup.string().test(
          'length',
          'ID do Pixel precisa ter 15 caracteres.',
          value => (value ? value?.length === 15 : true),
        ),
        fbMetaContent: Yup.string().test(
          'length',
          'Código de verificação precisa ter entre 15 e 30 caracteres.',
          value => (value ? value?.length >= 15 && value?.length <= 30 : true),
        ),
      });

      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        await saveFacebookPixelIntegration(data);
        addToast({
          type: 'success',
          description: 'As alterações foram salvas com sucesso!',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          integrationFormRef.current?.setErrors(getValidationErrors(err));

          addToast({
            type: 'error',
            description: 'Verifique os campos em vermelho.',
          });
        } else {
          addToast({
            type: 'error',
            description: 'Ocorreu um erro inesperado.',
          });
        }
      }
    },
    [addToast, saveFacebookPixelIntegration],
  );
  const handleOnToggleIntegration = useCallback(
    async (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
        const { data } = await api.post(
          `/restricted/companies/${company?.id}/api-key`,
        );

        setClientId(data.clientId);
        setApiKey(data.apiKey);
      }

      await toggleOption('pdvIntegration');
    },
    [company, setApiKey, setClientId, toggleOption],
  );
  const handleCopy = useCallback(
    async value => {
      await writeText(value);

      addToast({
        type: 'info',
        description: 'Copiado para àrea de transferência.',
      });
    },
    [addToast],
  );
  const handleToggleShowApiKey = useCallback(() => {
    if (apiKey) {
      setShowApiKey(old => !old);
    }
  }, [apiKey]);
  const handleFbMetaContentInput = useCallback(
    (event: React.FormEvent<HTMLInputElement>) => {
      const value = event.currentTarget.value || '';

      if (/name="facebook-domain-verification"/s.test(value)) {
        const content = (/(content=".*?")/.exec(value)?.[0] ?? '').replace(
          /content|"|=/g,
          '',
        );
        integrationFormRef?.current?.setFieldValue('fbMetaContent', content);
        return null;
      }

      if (/facebook-domain-verification/.test(value)) {
        try {
          const content = value.replace(/facebook-domain-verification=/g, '');
          integrationFormRef?.current?.setFieldValue('fbMetaContent', content);
        } catch {
          integrationFormRef?.current?.setFieldValue('fbMetaContent', '');
        }
        return null;
      }

      return null;
    },
    [],
  );
  return (
    <Wrapper ref={integrationFormRef} onSubmit={handleOnIntegrationSaved}>
      <GroupContainer>
        <GroupTitle>PDV</GroupTitle>
        <ContentContainer id="contentContainer">
          <ToggleContainer id="settings-integration-toggle">
            <Toggle
              icons={false}
              checked={company?.pdvIntegration}
              onChange={handleOnToggleIntegration}
              onClick={() => setShowApiKey(false)}
            />
            <span>Integrar com o PDV</span>
          </ToggleContainer>
          {company?.pdvIntegration && (
            <HorizontalWrapper>
              <CompanyCodeContainer id="clientIdContainer">
                <span>Código da Empresa</span>
                <CompanyCode>
                  <span id="clientId">{clientId}</span>
                  <button
                    className="copyButton"
                    type="button"
                    onClick={() => handleCopy(clientId)}
                  >
                    <MdContentCopy size={20} title="Copiar" />
                  </button>
                </CompanyCode>
              </CompanyCodeContainer>
              <CompanyCodeContainer id="apiCompanyKeyContainer">
                <span>Código da API</span>
                <CompanyCode>
                  <button
                    id="showCode"
                    type="button"
                    onClick={handleToggleShowApiKey}
                  >
                    <span>
                      {!showApiKey
                        ? '[ clique aqui para ver o código ]'
                        : apiKey}
                    </span>
                  </button>
                  {showApiKey && (
                    <button
                      className="copyButton"
                      type="button"
                      onClick={() => handleCopy(apiKey)}
                    >
                      <MdContentCopy size={20} title="Copiar" />
                    </button>
                  )}
                </CompanyCode>
              </CompanyCodeContainer>
            </HorizontalWrapper>
          )}
        </ContentContainer>
      </GroupContainer>
      <GroupContainer>
        <GroupTitle>Facebook Pixel</GroupTitle>
        <ContentContainer id="fbPixelContainer">
          <Input
            type="text"
            name="fbPixelId"
            title="ID do Pixel"
            defaultValue={company?.fbPixelId}
          />
          <Input
            type="text"
            name="fbMetaContent"
            title="Código de verificação (meta tag)"
            defaultValue={company?.fbMetaContent}
            onInput={handleFbMetaContentInput}
          />
          <SaveButton loading={isSavingCompany} />
        </ContentContainer>
      </GroupContainer>
    </Wrapper>
  );
};

export default IntegrationSettings;
