import { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import * as signalR from '@microsoft/signalr';
import api from '../services/api';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useSignalR = (
  companyID: string,
  accessToken: string,
  setAttTable: Dispatch<SetStateAction<number>>,
  setAttCard: Dispatch<SetStateAction<number>>,
) => {
  const handleUserConnected = useCallback((id: string) => {
    api.defaults.headers.common['X-TableCard-Connection-Id'] = id;
  }, []);
  useEffect(() => {
    const newConnection = new signalR.HubConnectionBuilder()
      .withUrl(`${process.env.REACT_APP_API_URL}card-table-changed`, {
        skipNegotiation: true,
        transport: signalR.HttpTransportType.WebSockets,
        accessTokenFactory: () => accessToken,
      })
      .configureLogging(signalR.LogLevel.Information)
      .withAutomaticReconnect([1000, 5000, 10000, 20000, 20000, 20000])
      .build();

    const startConnection = async () => {
      try {
        await newConnection.start();

        newConnection.on('UserConnected', handleUserConnected);
        newConnection.on('TableChanged', () => setAttTable(prev => prev + 1));
        newConnection.on('CardChanged', () => setAttCard(prev => prev + 1));

        await newConnection.invoke('JoinGroup', companyID.toString());

        newConnection.onreconnected(async () => {
          await newConnection.invoke('JoinGroup', companyID.toString());
          setAttTable(prev => prev + 1);
          setAttCard(prev => prev + 1);
        });
      } catch (error) {
        console.error('Erro ao iniciar conexão SignalR', error);
      }
    };

    startConnection();

    return () => {
      newConnection.off('UserConnected');
      newConnection.off('TableChanged');
      newConnection.off('CardChanged');
      newConnection
        .stop()
        .catch(e => console.error('Erro ao parar a conexão SignalR', e));
    };
  }, [accessToken, companyID, handleUserConnected, setAttCard, setAttTable]);
};
