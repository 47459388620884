import React, { useCallback, useEffect } from 'react';

import { Container, Main } from './styles';

interface IUpModal {
  visible: boolean;
  onClose: () => void;
  children: React.ReactNode;
}

const UpModal: React.FC<IUpModal> = ({ visible, children, onClose }) => {
  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  return (
    <Container visible={visible}>
      <Main>{children}</Main>
    </Container>
  );
};

export default UpModal;
