import React, { useState } from 'react';
import { FiEdit } from 'react-icons/fi';
import { ValueCard, Wrapper } from './styles';
import { formatToMoney } from '../../../../utils/format';
import ModalServiceDiscount from '../../../../components/ModalServiceDiscount';

interface ITotalizerMobileProps {
  service: number;
  discount: number;
  addition: number;
  totalPaid: number;
  totalValue: number;
  totalProductsValue: number;
  setService: React.Dispatch<React.SetStateAction<number>>;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  setAddition: React.Dispatch<React.SetStateAction<number>>;
}
const TotalizerMobile: React.FC<ITotalizerMobileProps> = ({
  service,
  discount,
  addition,
  totalPaid,
  totalValue,
  totalProductsValue,
  setService,
  setDiscount,
  setAddition,
}) => {
  const [openDiscount, setOpenDiscount] = useState(false);
  const [openAddition, setOpenAddition] = useState(false);
  const [openService, setOpenService] = useState(false);

  return (
    <Wrapper>
      <ValueCard>
        <p>Total</p>
        <p>{formatToMoney(totalValue)}</p>
      </ValueCard>
      <ValueCard>
        <p>Serviço</p>
        <div className="edit">
          <p>{formatToMoney(service)}</p>
          <FiEdit onClick={() => setOpenService(true)} />
        </div>
      </ValueCard>
      <ValueCard>
        <p>SubTotal</p>
        <p>{formatToMoney(totalProductsValue)}</p>
      </ValueCard>
      <ValueCard onClick={() => setOpenDiscount(true)}>
        <p>Desconto</p>
        <div className="edit">
          <p>{formatToMoney(discount)}</p>
          <FiEdit />
        </div>
      </ValueCard>
      <ValueCard totalPay>
        <p>Total Pago</p>
        <p>{formatToMoney(totalPaid)}</p>
      </ValueCard>
      <ValueCard>
        <p>Acréscimo</p>
        <div className="edit">
          <p>{formatToMoney(addition)}</p>
          <FiEdit onClick={() => setOpenAddition(true)} />
        </div>
      </ValueCard>
      {openDiscount && (
        <ModalServiceDiscount
          open={openDiscount}
          value={discount}
          totalValue={totalProductsValue}
          setOpen={setOpenDiscount}
          setValue={setDiscount}
          type="discount"
        />
      )}
      {openService && (
        <ModalServiceDiscount
          open={openService}
          value={service}
          totalValue={totalProductsValue}
          setOpen={setOpenService}
          setValue={setService}
          type="service"
        />
      )}
      {openAddition && (
        <ModalServiceDiscount
          open={openAddition}
          value={addition}
          totalValue={totalProductsValue}
          setOpen={setOpenAddition}
          setValue={setAddition}
          type="addition"
        />
      )}
    </Wrapper>
  );
};

export default TotalizerMobile;
