import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;
interface IWrapperProps {
  close: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999;
  background-color: ${({ theme }) => theme.palette.background_secondary};

  ${({ close }) =>
    close
      ? css`
          animation: ${slideOut} 0.5s ease forwards;
        `
      : css`
          animation: ${slideIn} 0.5s ease forwards;
        `}

  @media(max-width: 700px) {
    height: 100%;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.palette.background_secondary};
  overflow-x: hidden;
`;

export const Close = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  background-color: ${({ theme }) => theme.palette.background_white};
  align-items: center;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 20px 32px 5px;
  gap: 30px;
`;
