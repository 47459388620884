import styled from 'styled-components';

export const CartWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  background-color: ${({ theme }) => theme.palette.background_white};
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  width: 100%;
  height: 10%;
  background-color: ${({ theme }) => theme.palette.background_white};
  z-index: 999;
  padding: 0 32px;
  padding-top: 20px;
  align-items: flex-start;

  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1024px) {
      padding: 0 16px;
    }
    @media (max-width: 1129px) {
      h2 {
        font-size: 20px;
      }
    }
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 0 32px;
  padding-bottom: 32px;
  gap: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
  overflow-x: hidden;
`;

export const Close = styled.div`
  display: flex;
  width: auto;
  height: auto;
`;

export const NotFound = styled.div`
  width: 100%;
  height: 100%;
  text-align: center;
  place-content: center;

  .link {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary};
    background: none;
    border: none;
    padding: 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
`;

export const ContinueShopping = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  background-color: ${({ theme }) => theme.palette.background_white};
  align-items: center;
  .link {
    color: ${({ theme }) => theme.palette.primary};
    background: none;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.palette.primary};
    padding: 14px;
    margin: 0;
    font: inherit;
    cursor: pointer;
    transition: 500ms;

    :hover {
      color: ${({ theme }) => theme.palette.text_white};
      background-color: ${({ theme }) => theme.palette.primary_light};
      border: 0;
    }
  }
`;
