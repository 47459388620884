import styled from 'styled-components';

export const Icon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
`;
export const Time = styled.div`
  display: flex;
`;
