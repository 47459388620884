import React, { useRef, useCallback, useState, useEffect } from 'react';

import { useTheme } from 'styled-components';
import { FiX, FiSave, FiCheck } from 'react-icons/fi';

import Toggle from 'react-toggle';
import { useToast } from '../../hooks/toast';
import { useOperators } from '../../hooks/operators';
import { AuthRole } from '../../hooks/auth';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import IOperator from '../../models/IOperator';

import FormlessInput from '../FormlessInput';

import {
  Main,
  Header,
  Container,
  OperatorInfo,
  GroupSubTitles,
  ToggleContainer,
  SaveOperatorButton,
  HeaderButtonsContainer,
  SaveOperatorFloatingButton,
} from './styles';

import {} from '../../pages/SettingsPage/styles';

import { Button } from '../OptionsDialog/styles';

interface IEditOperatorModal {
  visible: boolean;
  operator: IOperator | null;
  onClose: () => void;
}

const EditOperatorModal: React.FC<IEditOperatorModal> = ({
  visible,
  operator,
  onClose,
}) => {
  const theme = useTheme();
  const inputRef = useRef<HTMLInputElement | null>(null);

  const { addToast } = useToast();
  const { saveOperator, resetPassword } = useOperators();
  const { showConfirmDialog, ThrowError } = useConfirmDialog();

  const [operatorName, setOperatorName] = useState('');
  const [operatorLogin, setOperatorLogin] = useState('');
  const [operatorIsAdmin, setOperatorIsAdmin] = useState(false);

  const [operatorNameError, setOperatorNameError] = useState('');
  const [operatorLoginError, setOperatorLoginError] = useState('');

  useEffect(() => {
    if (visible && operator) {
      setOperatorName(operator.name);
      setOperatorLogin(operator.login);
      setOperatorIsAdmin(operator.isAdmin);
    } else {
      setOperatorName('');
      setOperatorLogin('');
      setOperatorIsAdmin(false);
    }
  }, [visible, operator]);

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  useEffect(() => {
    if (visible) {
      if (inputRef && inputRef.current) {
        inputRef.current.focus();

        if (operator) {
          inputRef.current.setSelectionRange(0, operator?.name.length || 0);
        }
      }
    } else {
      setOperatorLogin('');
      setOperatorName('');
    }
  }, [operator, visible]);

  const handleOnOperatorNameChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      setOperatorNameError('');
      setOperatorName(e.target.value);
    },
    [],
  );

  const handleOnOperatorLoginChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;

      const format = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]+/;

      if (!format.test(value) || !value) {
        setOperatorLoginError('');
        setOperatorLogin(value);
      }
    },
    [],
  );

  const handleOnToggleOperatorIsAdmin = useCallback(() => {
    setOperatorIsAdmin(old => !old);
  }, []);

  const handleOnOperatorSaved = useCallback(async () => {
    if (operatorLogin.length < 3 || operatorName.length < 3) {
      setOperatorNameError(
        operatorName.length < 3
          ? 'Nome precisa ter no mínimo 3 caractéres.'
          : '',
      );
      setOperatorLoginError(
        operatorLogin.length < 3
          ? 'Login precisa ter no mínimo 3 caractéres.'
          : '',
      );
      return;
    }

    try {
      await saveOperator({
        id: operator?.id,
        name: operatorName,
        login: operatorLogin,
        isAdmin: operatorIsAdmin,
      });

      addToast({
        type: 'success',
        description: `Você criou ${operatorName}.`,
      });

      onClose();
    } catch (err) {
      ThrowError(err, 'Ocorreu um erro inesperado');
    }
  }, [
    operatorLogin,
    operatorName,
    saveOperator,
    operator,
    operatorIsAdmin,
    addToast,
    onClose,
    ThrowError,
  ]);

  const handleOnResetPasswordDialogConfirm = useCallback(async () => {
    if (operator) {
      try {
        await resetPassword(operator.id);

        addToast({
          type: 'success',
          description: `Senha do operador ${operatorName} resetada.`,
        });

        onClose();
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    }
  }, [addToast, onClose, operator, operatorName, resetPassword]);

  const handleOnResetPassword = useCallback(
    () =>
      showConfirmDialog({
        title: 'Resetar Senha',
        message: `Deseja realmente resetar a senha do operador ${operator?.name}?`,
        onCancel: () => null,
        onConfirm: handleOnResetPasswordDialogConfirm,
      }),
    [handleOnResetPasswordDialogConfirm, operator, showConfirmDialog],
  );

  return (
    <Container visible={visible}>
      <Header>
        <OperatorInfo>
          <FiX onClick={onClose} size={32} />
          <span>
            {operator ? 'Alteração de operador' : 'Cadastro de operador'}
          </span>
        </OperatorInfo>
        <HeaderButtonsContainer>
          {operator && (
            <AuthRole whiteList={['Company', 'Manager']} disableOnly>
              <Button className="on-hover" onClick={handleOnResetPassword}>
                Resetar senha
              </Button>
            </AuthRole>
          )}
          <SaveOperatorButton onClick={handleOnOperatorSaved}>
            <FiCheck />
            <span>Salvar</span>
          </SaveOperatorButton>
        </HeaderButtonsContainer>
      </Header>
      <Main>
        <FormlessInput
          name="Name"
          title="Nome"
          value={operatorName}
          error={operatorNameError}
          onChange={handleOnOperatorNameChanged}
        />
        <FormlessInput
          name="Login"
          title="Login"
          value={operatorLogin}
          error={operatorLoginError}
          onChange={handleOnOperatorLoginChanged}
        />
        <GroupSubTitles>Permissões</GroupSubTitles>
        <ToggleContainer>
          <AuthRole whiteList={['Company', 'Manager']} disableOnly>
            <Toggle
              icons={false}
              checked={operatorIsAdmin}
              onChange={handleOnToggleOperatorIsAdmin}
            />
          </AuthRole>
          <span>Habilita operador como administrador.</span>
        </ToggleContainer>
      </Main>
      <SaveOperatorFloatingButton onClick={handleOnOperatorSaved}>
        <FiSave color={theme.palette.text_white} size={24} />
      </SaveOperatorFloatingButton>
    </Container>
  );
};

export default EditOperatorModal;
