import styled from 'styled-components';

export const Container = styled.div`
  position: relative;
  height: 121px;
  display: flex;
  background-color: #fff;
  justify-content: space-between;
  align-items: center;
  width: calc(1 / 2 * 100% - (1 - 1 / 3) * 16px);
  overflow: hidden;
  transition: box-shadow 0.4s;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:hover {
    box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  }

  @media (max-width: 1280px) {
    width: 100%;
    flex: 0 100%;
  }
`;

export const Info = styled.div`
  width: 100%;
  padding: 32px;
  p {
    font-size: 18px;
    font-weight: 500;
  }
`;

interface IActiveButtonProps {
  active: 'true' | 'false';
}
export const ActiveButton = styled.div<IActiveButtonProps>`
  display: flex;
  flex-direction: column;
  width: 90px;
  height: 100%;
  gap: 16px;
  align-items: center;
  place-content: center;
  background-color: ${({ active }) =>
    active === 'true' ? '#27ae6090' : '#e74c3c90'};

  div {
    display: none;
  }

  @media (max-width: 384px) {
    position: absolute;
    display: flex;
    flex-direction: column;
    bottom: 0;
    right: 0;
    border-left: ${({ theme }) =>
      `100px solid ${theme.palette.background_white}`};
    border-bottom: 100px solid transparent;

    p {
      display: none;
    }

    div {
      display: block;
      color: ${({ theme }) => theme.palette.background_white};
      margin-top: 155px;
      margin-right: 55px;
    }
  }
`;

export const Delete = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  background: ${({ theme }) => theme.palette.background_white};
  border: none;
  padding: 8px;
  border-radius: 100%;
  transition: filter 2ms ease;

  &:hover {
    filter: brightness(0.9);
  }
`;
