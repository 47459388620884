import React, { useState } from 'react';
import { MdAddShoppingCart } from 'react-icons/md';
import { FiSettings } from 'react-icons/fi';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import {
  Log,
  Value,
  Buttons,
  LineLog,
  Wrapper,
  History,
  OptionButton,
  FinishResponsive,
} from './styles';

import { ICard } from '../../models/ICard';
import { ITable2 } from '../../models/ITable2';
import IOrder, { ILog } from '../../models/IOrder';

import { formatToFullLocalDate, formatToMoney } from '../../utils/format';

import Button from '../Button';
import DeleteOrder from '../DeleteOrder';
import SellPage from '../../pages/SellPage';
import Dropdown from '../../pages/Dropdown';
import TransferOrder from '../TransferOrder';

import { useConfirmDialog } from '../../hooks/confim_dialog';
import { usePaymentDrawer } from '../../hooks/payment_drawer';
import { useInternalOrders } from '../../hooks/internal_orders';
import { statusCardTableType } from '../../enums/cardAndTableType';

interface IButtonCartLaunch {
  card?: ICard;
  table?: ITable2;
  order: IOrder;
  openSell: boolean;
  transfer: boolean;
  handleOpenTransfer: () => void;
  handleTransferItems?: () => void;
  setOpenSell: React.Dispatch<React.SetStateAction<boolean>>;
  setCardState?: React.Dispatch<React.SetStateAction<ICard>>;
  setTransfer: React.Dispatch<React.SetStateAction<boolean>>;
  setTableState?: React.Dispatch<React.SetStateAction<ITable2>>;
}
const ButtonCartLaunch: React.FC<IButtonCartLaunch> = ({
  card,
  table,
  order,
  transfer,
  openSell,
  setTransfer,
  setOpenSell,
  handleOpenTransfer,
}) => {
  const { showConfirmDialog } = useConfirmDialog();
  const { openDrawer, setOrder } = usePaymentDrawer();
  const { closingOrder, occupiedOrder } = useInternalOrders();

  const [isLogOpen, setIsLogOpen] = useState(false);
  const [openDeleteOrder, setOpenDeleteOrder] = useState(false);
  const [openTransferOrder, setOpenTransferOrder] = useState(false);

  const toggleLog = () => {
    setIsLogOpen(!isLogOpen);
  };

  const handleFinish = () => {
    setOrder(order);
    openDrawer();

    table &&
      table.status !== statusCardTableType.CLOSING &&
      closingOrder(order.id);
    card &&
      card.status !== statusCardTableType.CLOSING &&
      closingOrder(order.id);
  };
  const dropdownButton = (
    <OptionButton>
      <FiSettings size={22} color="#DA123A" />
    </OptionButton>
  );

  const dropdownOptions = [
    {
      title: `Reabrir ${card ? 'comanda' : 'mesa'}`,
      onClick: () => occupiedOrder(order.id),
    },
    {
      title: 'Deletar pedido',
      onClick: () => setOpenDeleteOrder(true),
    },
    {
      title: 'Transferir pedido',
      onClick: () => setOpenTransferOrder(true),
    },
    {
      title: 'Transferir produto',
      onClick: () => setTransfer(true),
    },
  ];

  const handleAddProduct = () => {
    const status = card ? card.status : table?.status;
    const name = card ? 'Comanda' : 'Mesa';
    if (status === 'CLOSING') {
      showConfirmDialog({
        title: `${name} em fechamento!`,
        message: `Não é possível fazer o lançamento em um ${name.toLocaleLowerCase()} em fechamento!`,
        onCancel: () => null,
        onConfirm: () => null,
      });
    } else {
      setOpenSell(true);
    }
  };
  return (
    <>
      {!transfer ? (
        <Wrapper>
          <History isVisible={isLogOpen}>
            <button type="button" onClick={toggleLog}>
              {isLogOpen ? <IoIosArrowDown /> : <IoIosArrowUp />}
              <p>Histórico</p>
            </button>
          </History>

          <Log isVisible={isLogOpen} className="has-custom-scroll-bar-2">
            {order?.logs?.map((log: ILog) => (
              <LineLog>
                <p>{formatToFullLocalDate(log.createdAt)}</p>
                <p>{log.reason}</p>
              </LineLog>
            ))}
          </Log>

          <FinishResponsive>
            {order && order.total > 0 && (
              <Value>
                <strong>Total:</strong>
                <strong>{formatToMoney(order.total)}</strong>
              </Value>
            )}
            <Buttons>
              <Dropdown button={dropdownButton} options={dropdownOptions} />
              <Button
                outline
                title="Produto"
                onClick={handleAddProduct}
                icon={<MdAddShoppingCart size={22} color="#DA123A" />}
              />

              {order && order.total > 0 && (
                <Button title="Fechar conta" onClick={handleFinish} />
              )}
            </Buttons>
          </FinishResponsive>
        </Wrapper>
      ) : (
        <FinishResponsive>
          <Buttons>
            <Button
              className="cancel"
              side="L"
              bg="#aaa"
              color="#fff"
              onClick={() => setTransfer(false)}
              title="Cancelar"
            />

            <Button
              title="Confirmar"
              side="R"
              onClick={
                handleOpenTransfer ? () => handleOpenTransfer() : undefined
              }
            />
          </Buttons>
        </FinishResponsive>
      )}

      {openSell && (
        <SellPage
          order={order}
          open={openSell}
          setOpen={setOpenSell}
          card={card}
          table={table}
        />
      )}

      <DeleteOrder
        order={order}
        setOpen={setOpenDeleteOrder}
        open={openDeleteOrder}
      />

      {openTransferOrder && (
        <TransferOrder
          order={order}
          card={card}
          table={table}
          open={openTransferOrder}
          setOpen={setOpenTransferOrder}
        />
      )}
    </>
  );
};

export default ButtonCartLaunch;
