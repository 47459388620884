import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ClientPadding, ClientWrapper, Grid, NotFound } from './styles';
import Header from '../../components/Header';
import ClientCard from '../../components/ClientCard';
import UpModal from '../../components/UpModal';
import IClient, { defaultClient } from '../../models/IClient';
import { useClient } from '../../hooks/clients';
import ModalEditClient from '../../components/ModalEditClient';
import Loading from '../../components/Loading';
import { PageNames } from '../../enums/pages';

const ClientPage: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [clientEdit, setClientEdit] = useState<IClient>(defaultClient);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [clients, setClients] = useState<IClient[]>([]);
  const { getClients, isLoading } = useClient();

  const searchedClients = useMemo(() => {
    return clients.filter(client => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      return client.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [searchCriteria, clients]);

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleOpenModal = () => {
    setOpenModal(!openModal);
  };
  const handleCloseModal = () => {
    setOpenModal(!openModal);
    setClientEdit(defaultClient);
  };

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const clientData = getClients ? await getClients() : [];
        if (clientData.length > 0) {
          setClients(clientData);
        }
      } catch (error) {
        throw error;
      }
    };

    fetchClients();
  }, [getClients]);

  return (
    <>
      {isLoading ? (
        <Loading />
      ) : (
        <ClientWrapper>
          <ClientPadding>
            <Header
              selectedPage={PageNames.CLIENTS}
              searchCriteria={searchCriteria}
              handleOpenModal={handleOpenModal}
              handleOnSearchCriteriaChanged={handleOnSearchCriteriaChanged}
            />
            <Grid className="has-custom-scroll-bar-2">
              {searchedClients?.length > 0 ? (
                (searchedClients ?? []).map((client: IClient) => (
                  <ClientCard
                    key={client.id}
                    client={client}
                    onClick={() => {
                      setClientEdit(client);
                      setOpenModal(!openModal);
                    }}
                  />
                ))
              ) : (
                <NotFound>Nenhum Cliente encontrado!</NotFound>
              )}
            </Grid>
          </ClientPadding>

          <UpModal visible={openModal} onClose={handleOpenModal}>
            {openModal && (
              <ModalEditClient
                setClients={setClients}
                client={clientEdit}
                onClose={handleCloseModal}
              />
            )}
          </UpModal>
        </ClientWrapper>
      )}
    </>
  );
};

export default ClientPage;
