import styled from 'styled-components';
import { mergeColors } from '../../utils/mergeColors';

export const Container = styled.div<{ readonly: boolean; inputWidth: string }>`
  display: flex;
  align-items: center;
  place-content: space-between;
  text-align: center;
  height: 40px;
  width: ${({ readonly }) => (readonly ? '63px' : 'auto')};
  border-radius: 8px;
  background: ${({ theme }) => theme.palette.background_white};
  border: 1px solid ${({ theme }) => theme.palette.background_dark};

  input[type='number'] {
    -moz-appearance: textfield; /* Remove as setas no Firefox */
  }
  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    display: none;
    margin: 0;
  }
  input {
    width: ${({ readonly, inputWidth }) => (readonly ? '100%' : inputWidth)};
    display: flex;
    text-align: center;
    border: none;
  }

  user-select: none;

  div.ripple-1 {
    border-radius: 8px 0 0 8px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    cursor: pointer;
    transition: 100ms;

    * {
      color: ${({ theme }) => theme.palette.text_light};
    }

    &:hover {
      background: ${({ theme }) => theme.palette.primary};
      * {
        color: ${({ theme }) => theme.palette.primary_light};
      }
    }
  }

  div.ripple-2 {
    border-radius: 0 8px 8px 0;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 6px;
    cursor: pointer;

    * {
      color: ${({ theme }) => theme.palette.text_light};
    }

    &:hover {
      background: ${({ theme }) => theme.palette.primary};

      * {
        color: ${({ theme }) => theme.palette.primary_light};
      }
    }
  }

  div.ripple-2.disabled {
    * {
      color: ${({ theme }) =>
        mergeColors(theme.palette.black, theme.palette.background_white, 0.8)};
    }
    &:hover {
      background: #ccc4;
    }
  }
`;
