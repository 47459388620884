import React, { Dispatch, SetStateAction, useState } from 'react';
import { MaskedCurrency } from 'react-easy-mask';
import { useCashDrawer } from '../../hooks/cash_drawers';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { WrapperModal } from './styles';
import Modal from '../Modal';

interface IModalCloseCashierProps {
  open: boolean;
  cashierId: number;
  setKey: Dispatch<SetStateAction<number>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const ModalCloseCashier: React.FC<IModalCloseCashierProps> = ({
  open,
  cashierId,
  setKey,
  setOpen,
}) => {
  const { closeCashier } = useCashDrawer();
  const { ThrowError } = useConfirmDialog();

  const [loading, setLoading] = useState(false);
  const [valueInput, setValueInput] = useState(0);
  const [errorValue, setErrorValue] = useState(false);
  const [valueInputAfterCashier, setValueInputAfterCashier] = useState(0);

  const onConfirm = async () => {
    try {
      setLoading(true);

      if (valueInput < valueInputAfterCashier) {
        setLoading(false);
        setErrorValue(true);
        return;
      }
      setErrorValue(false);

      const currentDate = new Date().toISOString();

      const body = {
        id: cashierId,
        amount: valueInput,
        date: currentDate,
        cashWithdrawAmount: valueInputAfterCashier,
      };
      await closeCashier(body, cashierId);
      setOpen(false);
      setKey(prev => prev + 1);
    } catch (error) {
      ThrowError(error, 'Erro ao fazer a operação');
    } finally {
      setLoading(false);
      setValueInput(0);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      loading={loading}
    >
      <WrapperModal error={false}>
        <div className="title">
          <h2>Fechar Caixa</h2>
        </div>

        <div className="inputs">
          <div>
            <p>Conferência de valor</p>
            <MaskedCurrency
              locale="pt-BR"
              currency="BRL"
              defaultValue={valueInput}
              onChangeValue={value => setValueInput(value)}
            />
            <p className="error">
              {errorValue &&
                'O campo Conferência de valor precisa ser menor ou igual ao campo Valor para o próximo caixa!'}
            </p>
          </div>
          <div>
            <p>Valor para o próximo caixa</p>
            <MaskedCurrency
              locale="pt-BR"
              currency="BRL"
              defaultValue={valueInputAfterCashier}
              onChangeValue={value => setValueInputAfterCashier(value)}
            />
            <p className="error">
              {errorValue && 'O Valor não pode ser zero!'}
            </p>
          </div>
        </div>
      </WrapperModal>
    </Modal>
  );
};

export default ModalCloseCashier;
