import styled from 'styled-components';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  visibility: hidden;
  z-index: -1;
  display: flex;
  flex-direction: column;
  justify-content: end;
  padding: 8px;
  margin-top: 24px;
  background-color: rgb(243, 237, 191);
  gap: 16px;
  color: #000;

  .company {
    p {
      font-size: 12px;
    }
  }

  .values {
    display: flex;
    justify-content: end;
  }

  @media print {
    visibility: visible;
  }
`;
export const Header = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  gap: 1rem;

  img,
  svg {
    width: 70px;
    height: 70px;
  }
`;
export const ClientInfo = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-bottom: 1px solid #000;
  padding-bottom: 6pt;
`;

export const HeaderProducts = styled.div`
  display: flex;
  margin-top: 1rem;
  border-bottom: 1px solid #000;
  padding-bottom: 6pt;
  align-items: flex-end;
  justify-content: space-between;

  font-size: 14px;

  .description {
    display: flex;
    gap: 15px;
  }
`;
export const GridProducts = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  font-size: 14px;
`;

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #000;

  .description {
    display: flex;
    width: 100%;
    gap: 15px;
  }
  .price {
    justify-content: space-between;
    display: flex;
    margin-left: 56%;
  }
  .complements {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const GridComplement = styled.div`
  margin-top: -5px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const Complement = styled.div`
  display: flex;
  width: 100%;
  gap: 15px;
`;

export const FinishValues = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  p {
    font-size: 13px;
  }

  .last {
    padding-top: 8px;
    border-top: 1px solid #000;
  }
`;
export const Value = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  h3 {
    font-size: 16px;
  }
`;

export const Payments = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 2rem;

  h4 {
    font-size: 14px;
    align-items: center;
    text-align: center;
    border-bottom: 1px solid #000;
  }
`;

export const Footer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 1rem;
  width: auto;
  justify-content: center;
  align-items: center;
  font-size: 12px;
`;
