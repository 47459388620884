import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const Head = styled.div`
  display: flex;
  width: 100%;
  gap: 0.5rem;
  max-height: 50px;
  font-weight: 600;
  padding-left: 10px;
`;
export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 55vh;
  max-width: 1020px;
  min-width: 300px;
  overflow: auto;
  padding: 0.5rem 0;
`;

export const Client = styled.div`
  display: flex;
  width: 100%;
  max-height: 50px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.control};
  padding: 10px;
  gap: 0.5rem;
  cursor: pointer;
  :hover {
    background-color: ${({ theme }) => theme.palette.background};
  }

  .name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
