import React, { useCallback, useRef, useState } from 'react';
import Toggle from 'react-toggle';

import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

import { FormHandles } from '@unform/core';
import { useToast } from '../../../hooks/toast';
import {
  Wrapper,
  Container,
  GroupTitle,
  RadioContainer,
  GroupSubTitles,
  GroupContainer,
  ToggleContainer,
  GroupDescription,
} from './styles';

import { BsTicketPrintCodeType } from '../../../enums/bsticket';
import ICompany, { CompanyToggleOptions } from '../../../models/ICompany';

import Radio from '../../Radio';
import OrderButton from '../../OrderButton';
import OrderGroupModal, { IGroupProps } from '../../OrderGroupModal';

interface IBsTicketSettingsProps {
  company: ICompany | null;
  bsTickectProductsToOrder: IGroupProps[];
  toggleOption: (
    name: CompanyToggleOptions,
    companyField?: keyof ICompany,
  ) => Promise<void>;
  loadProductsToOrder: () => Promise<void>;
  saveProductsOrder: (newOrderedProducts: IGroupProps[]) => Promise<void>;
  changeBsTicketCodeType: (newCodeType: BsTicketPrintCodeType) => Promise<void>;
}
const BsTicketSettings: React.FC<IBsTicketSettingsProps> = ({
  company,
  bsTickectProductsToOrder,
  toggleOption,
  saveProductsOrder,
  loadProductsToOrder,
  changeBsTicketCodeType,
}) => {
  const { addToast } = useToast();
  const bsTicketFormRef = useRef<FormHandles | null>(null);

  const [
    isBsTicketProductsOrderModalOpen,
    setIsBsTicketProductsOrderModalOpen,
  ] = useState(false);

  const handleOnChangeBsTicketCodeType = useCallback(
    async (newCodeType: BsTicketPrintCodeType) => {
      try {
        await changeBsTicketCodeType(newCodeType);

        addToast({
          type: 'success',
          description: 'Código de impressão alterado com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao alterar o código de impressão!',
        });
      }
    },
    [addToast, changeBsTicketCodeType],
  );

  const handleOnOrderButtonBsTicketClicked = useCallback(() => {
    loadProductsToOrder();

    setIsBsTicketProductsOrderModalOpen(true);
  }, [loadProductsToOrder]);

  const handleOnBsTicketProductsOrderModalConfirm = useCallback(
    async (newOrderedProducts: IGroupProps[]) => {
      try {
        await saveProductsOrder(newOrderedProducts);

        addToast({
          type: 'success',
          description: 'Produtos salvos com sucesso!',
        });

        setIsBsTicketProductsOrderModalOpen(false);
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar os produtos!',
        });

        setIsBsTicketProductsOrderModalOpen(false);
      }
    },
    [addToast, saveProductsOrder],
  );

  return (
    <>
      <Wrapper
        ref={bsTicketFormRef}
        onSubmit={() => null}
        initialData={{
          ...company,
        }}
      >
        <Container>
          <GroupContainer>
            <GroupTitle>Pedidos</GroupTitle>
            <div id="settings-bsticket-orders">
              <GroupSubTitles>Impressão</GroupSubTitles>
              <ToggleContainer>
                <Toggle
                  icons={false}
                  checked={company?.bsTicketEnablesOrderPrint}
                  onChange={() => toggleOption('bsTicketEnablesOrderPrint')}
                />
                <span>Imprimir pedido.</span>
              </ToggleContainer>
              <ToggleContainer>
                <Toggle
                  icons={false}
                  checked={company?.bsTicketEnablesTicketPrint}
                  onChange={() => toggleOption('bsTicketEnablesTicketPrint')}
                />
                <span>Imprimir ficha.</span>
              </ToggleContainer>
              <div>
                <GroupDescription>Impressão de código</GroupDescription>
                <RadioContainer
                  name="bsTicketPrintCodeType"
                  onChange={newCodeType =>
                    handleOnChangeBsTicketCodeType(
                      newCodeType as BsTicketPrintCodeType,
                    )
                  }
                >
                  <Radio.Option value={BsTicketPrintCodeType.BARCODE}>
                    Código de barras
                    <MdRadioButtonUnchecked size={20} className="unchecked" />
                    <MdRadioButtonChecked size={20} className="checked" />
                  </Radio.Option>
                  <Radio.Option value={BsTicketPrintCodeType.QRCODE}>
                    QR Code
                    <MdRadioButtonUnchecked size={20} className="unchecked" />
                    <MdRadioButtonChecked size={20} className="checked" />
                  </Radio.Option>
                  <Radio.Option value={BsTicketPrintCodeType.NONE}>
                    Nenhum
                    <MdRadioButtonUnchecked size={20} className="unchecked" />
                    <MdRadioButtonChecked size={20} className="checked" />
                  </Radio.Option>
                </RadioContainer>
              </div>
            </div>
          </GroupContainer>
          <GroupContainer>
            <GroupTitle>Produtos</GroupTitle>
            <div id="settings-bsticket-products">
              <GroupSubTitles>Ordenação</GroupSubTitles>
              <OrderButton
                id="bs-ticket-order-products-button"
                onClick={handleOnOrderButtonBsTicketClicked}
              />
            </div>
            <div id="settings-bsticket-stock">
              <GroupSubTitles>Estoque</GroupSubTitles>
              <ToggleContainer>
                <Toggle
                  icons={false}
                  checked={company?.bsTicketEnablesStockControl}
                  onChange={() => toggleOption('bsTicketEnablesStockControl')}
                />
                <span>Habilita controle de estoque.</span>
              </ToggleContainer>
            </div>
          </GroupContainer>
        </Container>
      </Wrapper>
      <OrderGroupModal
        id="bsTicketProductsOrderModal"
        isOpen={isBsTicketProductsOrderModalOpen}
        groups={bsTickectProductsToOrder}
        onClose={() => setIsBsTicketProductsOrderModalOpen(false)}
        onConfirm={handleOnBsTicketProductsOrderModalConfirm}
      />
    </>
  );
};

export default BsTicketSettings;
