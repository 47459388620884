import React, { useEffect, useState } from 'react';

import { BiTransfer } from 'react-icons/bi';
import { FiArrowUp, FiEdit, FiTrash } from 'react-icons/fi';

import { useCompany } from '../../hooks/company';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { usePaymentDrawer } from '../../hooks/payment_drawer';
import { IPayment, useInternalOrders } from '../../hooks/internal_orders';

import { formatToMoney } from '../../utils/format';
import { convertPaymentName } from '../../utils/paymentName';

import {
  Grid,
  Body,
  Header,
  Bottom,
  Wrapper,
  Buttons,
  Transact,
  TotalPay,
  TotalCard,
  GridCards,
  ValueCard,
  GridTransact,
} from './styles';

import Button from '../Button';
import Drawer from '../Drawer';
import Loading from '../Loading';
import IconButton from '../IconButton';
import PaymentCard from '../PaymentCard';
import ModalBackPayment from '../ModalBackPayment';
import DivisionPerPerson from '../DivisionPerPerson';
import ModalFinishPayment from '../ModalFinishPayment';
import ModalServiceDiscount from '../ModalServiceDiscount';
import { ITransaction } from '../../models/IPayment';

const PaymentDrawer: React.FC = () => {
  const { sendPayment, completeOrder, deletePaymentOrder } =
    useInternalOrders();
  const { company } = useCompany();
  const { ThrowError } = useConfirmDialog();
  const { isDrawerOpen, closeDrawer, order } = usePaymentDrawer();

  const [service, setService] = useState(10); //  porcentagem
  const [discount, setDiscount] = useState(0); // porcentagem
  const [loading, setLoading] = useState(false);
  const [openBack, setOpenBack] = useState(false);
  const [openFinish, setOpenFinish] = useState(false);
  const [openService, setOpenService] = useState(false);
  const [openDiscount, setOpenDiscount] = useState(false);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);

  const valueDiscount = Number(((order.total * discount) / 100).toFixed(2));
  const valueService = Number(
    (((order.total - valueDiscount) * service) / 100).toFixed(2),
  );
  const totalValue = Number(
    (order.total + valueService - valueDiscount).toFixed(2),
  );
  const { subTotal } = order;
  const totalPayment = Number(
    transactions
      ?.reduce((accumulator, transaction) => {
        return accumulator + transaction.amount;
      }, 0)
      .toFixed(2),
  );
  const needToPay = totalValue - totalPayment;
  const smallCash = totalPayment - totalValue;
  const title =
    order?.cardNo > 0 ? `Comanda ${order?.cardNo}` : `Mesa ${order?.tableNo}`;

  useEffect(() => {
    if (order?.paymentsTransactions) {
      setTransactions(order?.paymentsTransactions);
    }
  }, [order]);

  const handleRemoveTransact = async (id: string | number) => {
    setLoading(true);
    try {
      if (typeof id === 'number') {
        await deletePaymentOrder(order.id, id);
      }
      setTransactions(prev => prev.filter(transact => transact.id !== id));
    } catch (error) {
      ThrowError(error, 'Erro ao deletar transação!');
    } finally {
      setLoading(false);
    }
  };

  const handleFinishPayment = async () => {
    setLoading(true);
    try {
      const body: IPayment[] = transactions
        .filter(transact => typeof transact.id === 'string')
        .map(transact => ({
          orderId: order.id,
          amount: transact.amount,
          paymentType: transact.paymentType,
        }));
      // Caso tenha troco informa junto das transactions
      if (smallCash > 0) {
        const newBody = [
          ...body,
          {
            orderId: order.id,
            amount: Number(smallCash.toFixed(2)),
            paymentType: 'CHANGE',
          },
        ];
        await sendPayment(order.id, newBody);
      } else {
        await sendPayment(order.id, body);
      }
      try {
        if (totalPayment >= totalValue) {
          await completeOrder(order.id, {
            orderId: order.id,
            discount: Number(valueDiscount.toFixed(2)),
            serviceFee: Number(valueService.toFixed(2)),
          });
        }
      } catch (error) {
        ThrowError(error, 'Erro ao liberar mesa');
      }

      setOpenFinish(false);
      closeDrawer();
    } catch (error) {
      ThrowError(error, 'Erro ao finalizar pagamento');
    } finally {
      setDiscount(0);
      setService(10);
      setLoading(false);
    }
  };

  const handleBack = () => {
    setDiscount(0);
    setService(10);
    closeDrawer();
    setOpenBack(false);
    setTransactions(prev =>
      prev.filter(transact => typeof transact.id === 'number'),
    );
  };

  const transactionsNoSaved = transactions.filter(
    transact => typeof transact.id === 'string',
  );
  const modalBack =
    discount !== 0 || service !== 10 || transactionsNoSaved.length > 0;
  return (
    <>
      <Drawer open={isDrawerOpen} setOpen={closeDrawer} zindex={2}>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Wrapper>
              <Header>
                <h3>{title}</h3>
              </Header>
              <Body>
                <div className="first">
                  <h4 className="title">
                    Informe uma ou mais formas de pagamento
                  </h4>
                  <Grid className="has-custom-scroll-bar-2">
                    {company?.orderPaymentTypes.map(payment => (
                      <PaymentCard
                        hasProducts={order?.items?.length > 0}
                        key={payment}
                        payment={payment}
                        needToPay={needToPay}
                        setTransactions={setTransactions}
                      />
                    ))}
                  </Grid>
                </div>
                <div className="second">
                  {transactions?.length > 0 && (
                    <h4 className="title">
                      <BiTransfer size={22} />
                      Transações
                    </h4>
                  )}
                  <GridTransact className="has-custom-scroll-bar-2">
                    {(transactions &&
                      transactions?.map((transact, index) => (
                        <Transact key={index ?? 0}>
                          <div className="line">
                            <FiArrowUp size={22} color="#85D1A5" />
                            <p>{convertPaymentName(transact?.paymentType)}</p>
                          </div>
                          <div className="line">
                            <p>{formatToMoney(transact?.amount)}</p>
                            <IconButton
                              icon={<FiTrash size={18} color="#DA123A" />}
                              onClick={
                                () => handleRemoveTransact(transact?.id) // Aqui terei de mexer
                              }
                            />
                          </div>
                        </Transact>
                      ))) ||
                      null}
                  </GridTransact>
                  {transactions.length > 0 && (
                    <TotalPay>
                      <p>Total Pago</p>
                      <p>{formatToMoney(totalPayment)}</p>
                    </TotalPay>
                  )}
                </div>
              </Body>
            </Wrapper>
            <Bottom>
              <DivisionPerPerson totalValue={totalValue} />

              <GridCards>
                <ValueCard cursor="true" onClick={() => setOpenService(true)}>
                  <div className="edit">
                    <p>{`Serviço ${service}%`}</p>
                    <FiEdit />
                  </div>
                  <p className="value">{formatToMoney(valueService)}</p>
                </ValueCard>
                <ValueCard cursor="true" onClick={() => setOpenDiscount(true)}>
                  <div className="edit">
                    <p>Desconto</p>
                    <FiEdit />
                  </div>
                  <p className="value">{formatToMoney(valueDiscount)}</p>
                </ValueCard>
                <ValueCard>
                  <p>SubTotal</p>
                  <p className="value">{formatToMoney(subTotal)}</p>
                </ValueCard>
                <ValueCard>
                  <p>Total</p>
                  <p className="value">{formatToMoney(totalValue)}</p>
                </ValueCard>
              </GridCards>

              {smallCash < 0 ? (
                <TotalCard smallCash={false}>
                  <p>Falta pagar</p>
                  <p>{needToPay > 0 ? formatToMoney(needToPay) : '0,00'}</p>
                </TotalCard>
              ) : (
                <TotalCard smallCash>
                  <p>Troco</p>
                  <p>{formatToMoney(smallCash)}</p>
                </TotalCard>
              )}
            </Bottom>
            <Buttons>
              <Button
                title="Voltar"
                side="L"
                outline
                onClick={() => (modalBack ? setOpenBack(true) : handleBack())}
              />
              <Button
                title="Confirmar"
                side="R"
                onClick={() => setOpenFinish(true)}
              />
            </Buttons>
          </>
        )}
      </Drawer>

      {openService && (
        <ModalServiceDiscount
          value={service}
          open={openService}
          totalValue={order.total - valueDiscount}
          setValue={setService}
          setOpen={setOpenService}
          type="service"
        />
      )}

      {openDiscount && (
        <ModalServiceDiscount
          value={discount}
          open={openDiscount}
          totalValue={order.total}
          setValue={setDiscount}
          setOpen={setOpenDiscount}
          type="discount"
        />
      )}

      {openFinish && (
        <ModalFinishPayment
          open={openFinish}
          title={title}
          loading={loading}
          needToPay={needToPay}
          smallCash={smallCash}
          totalValue={totalValue}
          totalPayment={totalPayment}
          onConfirm={handleFinishPayment}
          setOpen={setOpenFinish}
        />
      )}

      {openBack && (
        <ModalBackPayment
          open={openBack}
          loading={loading}
          discount={0}
          service={0}
          valueService={0}
          valueDiscount={0}
          transactionsNoSaved={transactionsNoSaved}
          onConfirm={handleBack}
          setOpen={setOpenBack}
        />
      )}
    </>
  );
};

export default PaymentDrawer;
