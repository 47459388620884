import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BiTransfer } from 'react-icons/bi';
import Select, { ValueType } from 'react-select';
import { selectStyles } from '../../styles/select';
import { ContainerCancelationOrder, SelectContainer, Tittle } from './styles';

import { ICard } from '../../models/ICard';
import { ITable2 } from '../../models/ITable2';
import { statusCardTableType } from '../../enums/cardAndTableType';

import { useTable } from '../../hooks/table';
import { useCards } from '../../hooks/card';

import Modal from '../Modal';
import { ITransferOrder, useInternalOrders } from '../../hooks/internal_orders';
import { ISelect } from '../ModalEditInfosCardTable';
import { useConfirmDialog } from '../../hooks/confim_dialog';

interface ITransferProductProps {
  open: boolean;
  body: ITransferOrder;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const TransferProduct: React.FC<ITransferProductProps> = ({
  open,
  body,
  setOpen,
}) => {
  const { transferProduct } = useInternalOrders();
  const { getTables } = useTable();
  const { getCards } = useCards();
  const { ThrowError } = useConfirmDialog();
  const [optionSelected, setOptionSelected] = useState<ISelect | null>(null);
  const [cards, setCards] = useState<ICard[]>([]);
  const [tables, setTables] = useState<ITable2[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCards = async () => {
      setLoading(true);
      try {
        const response = await getCards();
        setCards(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };

    fetchCards();
  }, [getCards]);

  useEffect(() => {
    const fetchTable = async () => {
      setLoading(true);
      try {
        const response = await getTables();
        setTables(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };

    fetchTable();
  }, [getTables]);

  const tablesOptions = useMemo(() => {
    return tables
      .filter(tb => tb.status === statusCardTableType.OCCUPIED)
      ?.map(tb => {
        return {
          label: `Mesa ${tb.tableNumber}`,
          value: tb.tableNumber,
        };
      });
  }, [tables]);

  const cardsOptions = useMemo(() => {
    return cards
      .filter(cd => cd.status === statusCardTableType.OCCUPIED)
      ?.map(cd => {
        return {
          label: `Comanda ${cd.cardNumber}`,
          value: -cd.cardNumber,
        };
      });
  }, [cards]);

  const handleOnGroupChanged = useCallback(
    (param: ValueType<ISelect, false>) => {
      setError(false);
      setOptionSelected(param as ISelect);
    },
    [],
  );

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const put = {
        ...body,
        destinationNumber:
          Number(optionSelected?.value) < 0
            ? -Number(optionSelected?.value)
            : Number(optionSelected?.value),
        eOrderTransferTypeDestination:
          Number(optionSelected?.value) < 0 ? 'CARD' : 'TABLE',
      };
      transferProduct(put.orderId, put);
      setOpen(false);
      setLoading(false);
    } catch (error) {
      ThrowError(error, 'Erro ao transferir');
      setLoading(false);
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={handleConfirm}
      loading={loading}
    >
      <ContainerCancelationOrder>
        <Tittle>
          <p>Transferir Produto</p>
          <BiTransfer size={22} />
          <p>enviar para</p>
          <SelectContainer>
            <Select
              className="select"
              error={error}
              isClearable
              styles={selectStyles}
              menuPortalTarget={document.body}
              value={{
                label: optionSelected?.label ?? '',
                value: Number(optionSelected?.value),
              }}
              options={[...tablesOptions, ...cardsOptions]}
              placeholder="Selecione a mesa ou comanda de envio"
              onChange={handleOnGroupChanged}
            />

            {error && <p>Obrigatório selecionar um destino!</p>}
          </SelectContainer>
        </Tittle>
      </ContainerCancelationOrder>
    </Modal>
  );
};

export default TransferProduct;
