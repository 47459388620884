import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FiMenu } from 'react-icons/fi';
import { MdClose } from 'react-icons/md';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';

import { useHistory } from 'react-router-dom';
import { v4 as uuid } from 'uuid';
import { useSidebar } from '../../hooks/sidebar';

import {
  Grid,
  Close,
  Header,
  Wrapper,
  Display,
  Payments,
  Container,
  Controller,
  ButtonClose,
  ButtonMobile,
  GridPayments,
  DisplayPayments,
  ContainerInputs,
  ContainerTotalizer,
  ContainerController,
} from './styles';

import { useProducts } from '../../hooks/products';
import { useCategories } from '../../hooks/categories';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import IClient from '../../models/IClient';
import { PageNames } from '../../enums/pages';
import ICategory from '../../models/ICategory';
import { IProductSell } from '../../models/IFastSell';
import IPayment, { ITransaction } from '../../models/IPayment';

import Button from '../../components/Button';
import Totalizer from './components/Totalizer';
import FinishButton from './components/FinishButton';
import InputClients from './components/InputClients';
import GridProducts from './components/GridProducts';
import IconButton from '../../components/IconButton';
import PaymentCard from '../../components/PaymentCard';
import InputProducts from './components/InputProducts';
import TotalizerMobile from './components/TotalizerMobile';
import GridTransactions from './components/GridTransactions';
import DivisionPerPerson from '../../components/DivisionPerPerson';

const FastSellPage: React.FC = () => {
  const history = useHistory();
  const { loadPayments, loadOnlyProducts } = useProducts();
  const { setSelectedPage } = useSidebar();
  const { ThrowError, showConfirmDialog } = useConfirmDialog();
  const { loadOnlyCategories } = useCategories();

  const [close, setClose] = useState<boolean>(
    window.location.pathname !== '/fast',
  );

  const [service, setService] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [addition, setAddition] = useState(0);
  const [cartId, setCartId] = useState(uuid());
  const [products, setProducts] = useState<IProductSell[]>([]);
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [customer, setCustomer] = useState<IClient>({} as IClient);
  const [paymentTypes, setPaymentTypes] = useState<IPayment[]>([]);
  const [closePayments, setClosePayments] = useState<boolean>(true);
  const [transactions, setTransactions] = useState<ITransaction[]>([]);
  const [closeController, setCloseController] = useState<boolean>(false);
  const [productsToGrid, setProductsToGrid] = useState<IProductSell[]>([]);

  useEffect(() => {
    const fetchPayments = async () => {
      try {
        const response = await loadPayments();
        setPaymentTypes(response);
      } catch (error) {
        ThrowError(error, 'Erro ao buscar formas de pagamento!');
      }
    };
    fetchPayments();
  }, [ThrowError, loadPayments]);

  const toglgleController = useCallback(() => {
    setCloseController(prev => !prev);
  }, []);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const response = await loadOnlyProducts();
        setProducts(response);
      } catch (error) {
        ThrowError(error, 'Erro ao listar produtos!');
      }
    };
    fetchProducts();
  }, [ThrowError, loadOnlyProducts]);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await loadOnlyCategories();
        setCategories(response);
      } catch (error) {
        ThrowError(error, 'Erro ao listar produtos!');
      }
    };
    fetchCategories();
  }, [ThrowError, loadOnlyCategories]);

  const totalAmount = useMemo(() => {
    return transactions.reduce(
      (acc, transaction) => acc + transaction.amount,
      0,
    );
  }, [transactions]);
  const totalProductsValue = useMemo(() => {
    return productsToGrid.reduce(
      (acc, products) =>
        acc + (products?.total ? products?.total : products?.unitPrice),
      0,
    );
  }, [productsToGrid]);

  const needToPay = useMemo(() => {
    return totalProductsValue + addition + service - discount - totalAmount;
  }, [addition, discount, service, totalAmount, totalProductsValue]);

  const totalValue = useMemo(() => {
    return totalProductsValue + addition + service - discount;
  }, [addition, discount, service, totalProductsValue]);

  const handleClear = () => {
    showConfirmDialog({
      title: 'Deseja limpar o grid?',
      message:
        'Todos dados como produtos, pagamentos, cliente, etc serão perdidos!',
      onConfirm: () => {
        setService(0);
        setDiscount(0);
        setAddition(0);
        setTransactions([]);
        setProductsToGrid([]);
        setCustomer({} as IClient);
      },
      onCancel: () => null,
    });
  };

  return (
    <Wrapper close={close}>
      <Header>
        <Close>
          <IconButton
            icon={<MdClose size={22} />}
            onClick={() => {
              setClose(true);
              setTimeout(() => {
                history.push('/');
                setSelectedPage(PageNames.DASHBOARD);
              }, 500);
            }}
          />
        </Close>
        <h3>Lançamento Rápido</h3>
      </Header>
      <ButtonMobile>
        <Button
          onClick={() => setClosePayments(prev => !prev)}
          title={closePayments ? 'Pagar' : 'Produtos'}
          width="auto"
          icon={<FiMenu size={22} />}
        />
      </ButtonMobile>
      <Container>
        <Display close={closePayments}>
          <Grid close={closePayments}>
            <GridProducts
              allProducts={products}
              categories={categories}
              products={productsToGrid}
              handleClear={handleClear}
              setProducts={setProductsToGrid}
            />
          </Grid>
          <Payments close={closePayments}>
            <DisplayPayments>
              <GridPayments className="has-custom-scroll-bar-2">
                {paymentTypes?.map(payment => (
                  <PaymentCard
                    hasProducts={productsToGrid.length > 0}
                    key={payment.id}
                    payment={payment.tag}
                    needToPay={needToPay}
                    setTransactions={setTransactions}
                  />
                ))}
              </GridPayments>
            </DisplayPayments>
            <GridTransactions
              transactions={transactions}
              setTransactions={setTransactions}
            />

            <DivisionPerPerson totalValue={totalValue} />
          </Payments>

          <TotalizerMobile
            service={service}
            discount={discount}
            addition={addition}
            totalValue={totalValue}
            totalPaid={totalAmount}
            totalProductsValue={totalProductsValue}
            setService={setService}
            setDiscount={setDiscount}
            setAddition={setAddition}
          />
        </Display>

        <Controller close={closeController}>
          <ButtonClose isVisible={closeController}>
            <button type="button" onClick={toglgleController}>
              {closeController ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </button>
          </ButtonClose>

          <ContainerController>
            <div className="first">
              <ContainerInputs>
                <InputProducts
                  products={products}
                  categories={categories}
                  setProductsToGrid={setProductsToGrid}
                />
                {/* <InputCardsTables /> */}
                <InputClients setCustomer={setCustomer} customer={customer} />
              </ContainerInputs>
              <ContainerTotalizer>
                <Totalizer
                  service={service}
                  discount={discount}
                  addition={addition}
                  needToPay={needToPay}
                  totalPaid={totalAmount}
                  totalValue={totalValue}
                  totalProductsValue={totalProductsValue}
                  setService={setService}
                  setDiscount={setDiscount}
                  setAddition={setAddition}
                />
              </ContainerTotalizer>
            </div>
            <FinishButton
              cartId={cartId}
              client={customer}
              service={service}
              discount={discount}
              needToPay={needToPay}
              totalValue={totalValue}
              products={productsToGrid}
              totalPayment={totalAmount}
              transactions={transactions}
              subTotal={totalProductsValue}
              setCartId={setCartId}
              setService={setService}
              setAddition={setAddition}
              setCustomer={setCustomer}
              setDiscount={setDiscount}
              setTransactions={setTransactions}
              setProductsToGrid={setProductsToGrid}
            />
          </ContainerController>
        </Controller>
      </Container>
    </Wrapper>
  );
};

export default FastSellPage;
