import React, { forwardRef } from 'react';
import IComplementsGroup from '../../models/IComplementsGroup';
import {
  Complement,
  ComplementContainer,
  ComplementsWrapper,
  InputContainer,
  Name,
  Price,
} from './styles';

import CheckboxComplement from '../CheckboxComplement';
import AmountInput from '../AmountInput';
import { useCartProducts } from '../../hooks/products_sell';

interface IComplementsCartSell {
  complementGroup: IComplementsGroup;
  setNextComplement: React.Dispatch<React.SetStateAction<number>>;
  index: number;
}

const ComplementsCartSell = forwardRef<HTMLDivElement, IComplementsCartSell>(
  ({ complementGroup, index, setNextComplement }, ref) => {
    const { handleSelectComplement } = useCartProducts();

    const isRadioButton =
      complementGroup?.minAmount === 1 && complementGroup?.maxAmount === 1;

    const numberButton = complementGroup.complements.some(
      complementGroup => (complementGroup?.maxAmount || 0) > 1,
    );
    const isCheckbox = !isRadioButton && !numberButton;

    return (
      <ComplementsWrapper ref={ref}>
        <Name>
          <h4>
            {complementGroup?.displayName
              ? complementGroup?.displayName
              : complementGroup?.title}
          </h4>
          {complementGroup?.maxAmount > 1 && (
            <p>{`(até ${complementGroup?.maxAmount} itens)`}</p>
          )}
          {complementGroup?.minAmount > 0 && (
            <p className="obg">
              {/* eslint-disable-next-line */}
              Obrigatório ({complementGroup?.minAmount})
            </p>
          )}
          {complementGroup?.minAmount === 0 && <p className="opc">Opcional</p>}
        </Name>

        {/* caso seja radiobox */}
        {isRadioButton && (
          <ComplementContainer>
            {complementGroup?.complements.map(comp => {
              const isDisabled = complementGroup.complements.some(
                otherComp => otherComp.checked && otherComp.id !== comp.id,
              );
              return (
                <Complement key={comp?.id} disabled={isDisabled}>
                  <p>{comp?.title ? comp?.title : comp?.description}</p>
                  {comp?.unitPrice > 0 && (
                    <Price>
                      <p>
                        R$
                        {comp?.unitPrice.toFixed(2).replace('.', ',')}
                      </p>
                    </Price>
                  )}

                  <CheckboxComplement
                    id={`${complementGroup.id}-${comp.id}`}
                    checked={comp?.checked ? comp?.checked : false}
                    onChange={() => {
                      setNextComplement(index + 1);
                      handleSelectComplement(complementGroup, comp, 'radio');
                    }}
                    roundedStyle={isRadioButton}
                  />
                </Complement>
              );
            })}
          </ComplementContainer>
        )}
        {/* caso seja Checkbox */}
        {isCheckbox && (
          <ComplementContainer>
            {complementGroup?.complements.map(comp => {
              const isDisabled =
                complementGroup.maxAmount ===
                  complementGroup.complements.filter(comp => comp.checked)
                    .length && !comp.checked;

              const beforeDisabled =
                complementGroup.maxAmount ===
                  complementGroup.complements.filter(comp => comp.checked)
                    .length +
                    1 && !comp.checked;
              // O -1 ou +1 utilizado na verificação é para simular a ultima entrada do usuário,
              // pois se não a condição só será verdadeira no momento em que deveria scrollar a página
              const allSelected =
                complementGroup?.complements.length - 1 ===
                complementGroup.complements.filter(comp => comp.checked).length;
              return (
                <Complement key={comp?.id} disabled={isDisabled}>
                  <p>{comp?.title ? comp?.title : comp?.description}</p>
                  {comp?.unitPrice > 0 && (
                    <Price>
                      <p>
                        R$
                        {comp?.unitPrice.toFixed(2).replace('.', ',')}
                      </p>
                    </Price>
                  )}

                  <CheckboxComplement
                    id={`${complementGroup.id}-${comp.id}`}
                    checked={comp?.checked ? comp?.checked : false}
                    onChange={() => {
                      if (!isDisabled) {
                        handleSelectComplement(
                          complementGroup,
                          comp,
                          'checkbox',
                        );
                        if (beforeDisabled) {
                          setNextComplement(index + 1);
                        }
                        // Se todos checkbox forém selecionados
                        if (allSelected && !comp?.checked) {
                          setNextComplement(index + 1);
                        }
                      }
                    }}
                    roundedStyle={isRadioButton}
                  />
                </Complement>
              );
            })}
          </ComplementContainer>
        )}

        {/* caso seja numberButton */}
        {numberButton && (
          <ComplementContainer>
            {complementGroup?.complements.map(comp => {
              const groupMaxAmount = complementGroup.maxAmount;
              const totalAmount = complementGroup?.complements.reduce(
                (accumulator, item) => {
                  return accumulator + (item.amount || 0); // Adiciona o valor de `amount` ao acumulador
                },
                0,
              );

              const disabledAll = totalAmount >= groupMaxAmount;
              const beforeDisabledAll = totalAmount + 1 === groupMaxAmount;
              const maxAmount = (comp?.amount ?? 0) >= (comp?.maxAmount || 0);
              return (
                <Complement key={comp?.id} disabled={maxAmount || disabledAll}>
                  <p>{comp?.title ? comp?.title : comp?.description}</p>
                  {comp?.unitPrice > 0 && (
                    <Price>
                      <p>
                        R$
                        {comp?.unitPrice.toFixed(2).replace('.', ',')}
                      </p>
                    </Price>
                  )}
                  <InputContainer>
                    <AmountInput
                      allowsZero
                      disabled={maxAmount || disabledAll}
                      value={comp?.amount ?? 0}
                      onIncreaseAmount={() => {
                        handleSelectComplement(
                          complementGroup,
                          comp,
                          'increase',
                        );
                        beforeDisabledAll && setNextComplement(index + 1);
                      }}
                      onDecreaseAmount={() =>
                        handleSelectComplement(
                          complementGroup,
                          comp,
                          'decrease',
                        )
                      }
                    />
                  </InputContainer>
                </Complement>
              );
            })}
          </ComplementContainer>
        )}
      </ComplementsWrapper>
    );
  },
);

export default ComplementsCartSell;
