import React from 'react';

import IComplement from '../../../../models/IComplement';
import IComplementsGroup from '../../../../models/IComplementsGroup';

import ComplementsGroupsPizza from '../ComplementsGroupsPizza';

interface IComplementsPizza {
  complementsGroups: IComplementsGroup[];
  setComplementsGroups: React.Dispatch<
    React.SetStateAction<IComplementsGroup[]>
  >;
}

const ComplementsPizza: React.FC<IComplementsPizza> = ({
  complementsGroups,
  setComplementsGroups,
}) => {
  const handleSelectComplements = (
    comple: IComplement,
    type: 'increase' | 'decrease' | 'checkbox' | 'radio',
  ) => {
    if (type === 'increase') {
      setComplementsGroups(prevGroups =>
        prevGroups.map(group => {
          if (group.complements.some(c => c.id === comple.id)) {
            return {
              ...group,
              complements: group.complements.map(c =>
                c.id === comple.id
                  ? { ...c, checked: true, amount: (c.amount ?? 0) + 1 }
                  : c,
              ),
            };
          }

          return group;
        }),
      );
    } else if (type === 'decrease') {
      setComplementsGroups(prevGroups =>
        prevGroups.map(group => {
          if (group.complements.some(c => c.id === comple.id)) {
            return {
              ...group,
              complements: group.complements.map(c =>
                c.id === comple.id
                  ? {
                      ...c,
                      amount: (c.amount ?? 0) - 1,
                      checked: c.amount === 1 ? false : c.checked,
                    }
                  : c,
              ),
            };
          }

          return group;
        }),
      );
    } else if (type === 'checkbox') {
      setComplementsGroups(prevGroups =>
        prevGroups.map(group => {
          if (group.complements.some(c => c.id === comple.id)) {
            return {
              ...group,
              complements: group.complements.map(c =>
                c.id === comple.id
                  ? { ...c, amount: !c.checked ? 1 : 0, checked: !c.checked }
                  : c,
              ),
            };
          }

          return group;
        }),
      );
    } else {
      setComplementsGroups(prevGroups =>
        prevGroups.map(group => {
          if (group.complements.some(c => c.id === comple.id)) {
            return {
              ...group,
              complements: group.complements.map(
                c =>
                  c.id === comple.id
                    ? { ...c, checked: true, amount: 1 } // O complemento selecionado tem checked: true
                    : { ...c, checked: false, amount: 0 }, // Todos os outros complementos têm checked: false
              ),
            };
          }

          return group;
        }),
      );
    }
  };

  return (
    <>
      {complementsGroups.map((compG, index) => (
        <ComplementsGroupsPizza
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          index={compG.id ?? 0}
          complementGroup={compG}
          handleSelect={handleSelectComplements}
        />
      ))}
    </>
  );
};

export default ComplementsPizza;
