import styled, { css } from 'styled-components';

interface ISubPageProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  height: 100%;

  input {
    scroll-margin: 60px;
  }
`;

export const Content = styled.main`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;

  background: #f4f4f6;
  position: relative;
`;

export const Header = styled.header`
  transform-origin: left top;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px;
  padding-top: 24px;
  margin-bottom: 16px;
`;

export const PageName = styled.strong`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-size: 32px;

  @media (max-width: 1027px) {
    display: none;
  }
`;

export const SubPages = styled.div`
  width: 100%;
  min-height: 64px;
  overflow: auto;

  padding: 0 32px 8px 32px;
  margin-bottom: 8px;

  display: flex;
  justify-content: space-between;
  font-size: 20px;

  @media (max-width: 1280px) {
    margin-bottom: 8px;
    font-size: 100%;
    div + div {
      margin-left: 16px;
    }
  }

  @media (max-width: 700px) {
    width: 100vw;
  }
`;

export const SubPage = styled.div<ISubPageProps>`
  white-space: nowrap;
  cursor: pointer;

  ${({ selected }) =>
    selected
      ? css`
          padding-bottom: 8px;
          border-bottom: 4px solid #db1622;
          font-weight: 500;
        `
      : css``}
`;

export const Main = styled.main`
  display: flex;
  justify-content: stretch;
  flex-direction: column;
  align-content: center;
  width: 100%;
  flex: 1 1 auto;
  overflow-y: auto;

  flex-wrap: wrap;

  overflow-y: auto;

  input {
    font-size: 24px;
  }

  @media (max-width: 1024px) {
    max-width: 100vw;
    input {
      font-size: 20px;
    }
  }

  /* @media (max-width: 480px) {
    padding-bottom: 80px;
  } */
`;

export const GroupTitle = styled.div`
  border-bottom: 4px solid #db1622;

  font-weight: 500;
  font-size: 24px;

  button {
    border: 0;
    color: #fff;
    background: #db1622;
    width: 35px;
    height: 35px;
    margin-bottom: 16px;
  }
`;

export const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 16px;
  }

  :first-child {
    margin-top: unset;
  }

  #contentContainer {
    margin-top: 0;
  }

  #settings-bsticket-products {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    #bs-ticket-order-products-button {
      margin-left: unset;
    }
  }
`;

const integrationStyles = css`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

export const ChatbotContainer = styled.div`
  ${integrationStyles}
`;

export const CompanyCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  > span {
    font-weight: 500;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
  }
`;

export const CompanyCode = styled.div`
  width: 100%;
  height: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ theme }) => theme.palette.background_secondary};
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_secondary};
    border-radius: 4px;

    padding: 2px 12px;
  }

  #clientId {
    display: flex;
    flex: 1;
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }

  .copyButton {
    background: none;
    border: none;
    padding: 0;
  }
`;
