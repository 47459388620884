import styled from 'styled-components';

export const NavbarWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 0 0 0;
`;

export const Container = styled.div`
  display: flex;
  width: 100%;
  padding: 0 30px;
  justify-content: space-between;
  @media (max-width: 1024px) {
    gap: 10px;
  }
`;

export const Close = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
`;
export const Cart = styled.div`
  display: flex;
  width: 40px;
  height: 40px;
  @media (min-width: 940px) {
    display: none;
  }
`;

export const SubcategoriesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  padding: 0 30px 15px 30px;
`;

export const SubcategoriesMap = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  gap: 10px;
`;

interface ISubcategorieContainer {
  selected: boolean;
}

export const SubcategorieContainer = styled.div<ISubcategorieContainer>`
  display: flex;
  place-content: center;
  width: auto;
  height: auto;
  border: 1px solid ${({ theme }) => theme.palette.black_transparency};
  color: ${({ theme, selected }) =>
    selected ? theme.palette.text : theme.palette.text_white};
  background-color: ${({ theme, selected }) =>
    selected
      ? theme.palette.background_white
      : theme.palette.black_transparency};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
  padding: 4px;
  font-size: 14px;
  border-radius: 8px;
  transition: 500ms;

  :hover {
    transform: scale(1.1);
  }
`;
