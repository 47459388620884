import React, { FormEvent, useCallback, useRef } from 'react';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import { useToast } from '../../../hooks/toast';

import { DivPadding, GroupContainer, GroupTitle, Wrapper } from './styles';

import Input from '../../Input';
import SaveButton from '../../SaveButton';
import { getValidationErrors } from '../../../utils/errors';
import ISaveUserDTO from '../../../dtos/ISaveUserDTO';
import ICustomer from '../../../models/IUser';

interface IUpdateUserFormData {
  password: string;
  confirmPassword: string;
  currentPassword?: string;
}

interface IUserSettingsProps {
  user: ICustomer | null;
  updateUser: (data: ISaveUserDTO) => Promise<void>;
}
const UserSettings: React.FC<IUserSettingsProps> = ({ user, updateUser }) => {
  const { addToast } = useToast();

  const userFormRef = useRef<FormHandles | null>(null);

  const handleOnUserSaved = useCallback(
    async (
      data: IUpdateUserFormData,
      event: FormEvent<Element> | undefined,
    ) => {
      event?.stopPropagation();

      const schema = Yup.object().shape({
        name: Yup.string()
          .min(3, 'Mínimo de 3 caracteres.')
          .required('O nome é obrigatório.'),
        currentPassword: Yup.string().test(
          'password-length',
          'Se presente, mínimo de 4 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 4) {
              return false;
            }
            return true;
          },
        ),
        password: Yup.string()
          .when('currentPassword', {
            is: password => password.length >= 4,
            then: Yup.string().required('Nova senha é obrigatória.'),
          })
          .test(
            'password-length',
            'Se presente, mínimo de 4 caracteres.',
            async (value: string | null | undefined) => {
              if (value && value.length < 4) {
                return false;
              }
              return true;
            },
          ),
        confirmPassword: Yup.string()
          .when('currentPassword', {
            is: password => password >= 4,
            then: Yup.string().required('Confirmação de senha é obrigatória.'),
          })
          .when('password', {
            is: val => !!val.length,
            then: Yup.string().required(
              'A confirmação de senha é obrigatória.',
            ),
            otherwise: Yup.string(),
          })
          .oneOf([Yup.ref('password'), undefined], 'As senhas não conferem.'),
      });

      userFormRef.current?.setErrors({});

      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        await updateUser({
          ...data,
          email: user?.email || '',
        });

        addToast({
          type: 'success',
          description: 'Você alterou as sua informações.',
        });

        userFormRef.current?.setFieldValue('password', '');
        userFormRef.current?.setFieldValue('confirmPassword', '');
        userFormRef.current?.setFieldValue('currentPassword', '');
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          userFormRef.current?.setErrors(getValidationErrors(err));

          if (err instanceof Yup.ValidationError) {
            document
              .getElementById(err.inner[0].path)
              ?.scrollIntoView({ behavior: 'smooth' });
          }

          addToast({
            type: 'error',
            description: 'Verifique os campos em vermelho.',
          });
        } else {
          addToast({
            type: 'error',
            description: 'Verifique a conexão e tente novamente.',
          });
        }
      }
    },
    [addToast, updateUser, user],
  );

  return (
    <Wrapper
      ref={userFormRef}
      initialData={user || {}}
      onSubmit={(data, _, event) => handleOnUserSaved(data, event)}
    >
      <GroupContainer id="settings-user">
        <Input title="Nome" name="name" />
        <Input title="Email" name="email" disabled />
      </GroupContainer>
      <GroupContainer id="settings-user-password">
        <GroupTitle>Alterar senha</GroupTitle>
        <Input
          type="password"
          title="Senha atual"
          name="currentPassword"
          hasPasswordEye
        />
        <Input
          title="Nova senha"
          name="password"
          type="password"
          hasPasswordEye
        />
        <Input
          type="password"
          name="confirmPassword"
          title="Confirmar senha"
          hasPasswordEye
        />
      </GroupContainer>
      <SaveButton type="submit" id="settings-user-save" />
      <DivPadding id="div-padding" />
    </Wrapper>
  );
};

export default UserSettings;
