import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;

  p {
    color: ${({ theme }) => theme.palette.text_light};
  }
`;

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  max-height: 30px;
  background-color: ${({ theme }) => theme.palette.background_white};
  border-radius: 4px;
  padding-right: 10px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
`;

export const Control = styled.div`
  display: flex;
  gap: 1rem;
`;

export const BtnAdd = styled.div`
  display: flex;
  padding: 5px;
  place-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: 30px;
  max-height: 30px;
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.palette.primary_light};
  }
`;
