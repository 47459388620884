import React from 'react';
import ReactDOM from 'react-dom';
import { FiAlertCircle } from 'react-icons/fi';

import {
  Dialog,
  Buttons,
  Container,
  CancelButton,
  ConfirmButton,
} from './styles';

interface IProps {
  title: string;
  open?: boolean;
  message?: string;
  onCancel?: () => void;
  onConfirm?: () => void;
}

const ConfirmDialog: React.FC<IProps> = ({
  open,
  title,
  message,
  onCancel,
  onConfirm,
}) =>
  ReactDOM.createPortal(
    <Container open={open} onClick={onCancel}>
      <Dialog>
        <span>
          <FiAlertCircle size={32} />
          <h2>{title}</h2>
        </span>
        {message && <span className="confirm-message">{message}</span>}
        <Buttons>
          {onCancel && <CancelButton onClick={onCancel}>Cancelar</CancelButton>}
          {onConfirm && <ConfirmButton onClick={onConfirm}>Ok</ConfirmButton>}
        </Buttons>
      </Dialog>
    </Container>,
    document.body,
  );

export default ConfirmDialog;
