import React, {
  useMemo,
  useState,
  useContext,
  useCallback,
  createContext,
} from 'react';

import ICategory from '../models/ICategory';
import ISaveCategoryDTO from '../dtos/ISaveCategoryDTO';
import { IOrderable } from '../components/OrderCategoriesModal';

import api from '../services/api';
import { useCompany } from './company';

interface CategoriesContextData {
  categories: ICategory[];
  subcategories: ICategory[];
  isCategoriesLoading: boolean;
  orderableSubcategories: ICategory[];
  loadCategories(): Promise<void>;
  loadOnlyCategories: () => Promise<ICategory[]>;
  orderCategories: (order: IOrderable[]) => Promise<void>;
  changeStatus(id: number, resource: string): Promise<void>;
  deleteCategory(id: number): Promise<void>;
  orderSubcategories: (
    categoryId: number,
    order: IOrderable[],
  ) => Promise<void>;
  resetOrderableCategories: () => void;
  getOrderableCategories: (categoryId: number) => Promise<void>;
  saveCategory(data: ISaveCategoryDTO, categoryId: number): Promise<void>;
}

const CategoriesContext = createContext<CategoriesContextData>(
  {} as CategoriesContextData,
);

export const CategoriesProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [isCategoriesLoading, setIsCategoriesLoading] = useState(true);

  const [orderableSubcategories, setOrderableSubcategories] = useState<
    ICategory[]
  >([]);

  const subcategories = useMemo(() => {
    return categories.filter(category => category.isSubcategory);
  }, [categories]);

  const loadCategories = useCallback(async () => {
    const response = await api.get<ICategory[]>('/restricted/categories');
    setCategories(response.data);
    setIsCategoriesLoading(false);
  }, []);

  const loadOnlyCategories = useCallback(async () => {
    try {
      if (!company) return [];
      const response = await api.get<ICategory[]>('/categories');
      return response.data;
    } catch (error) {
      throw error;
    }
  }, [company]);

  const saveCategory = useCallback(
    async (saveCategoryDTO: ISaveCategoryDTO, categoryId: number) => {
      if (categoryId > 0) {
        const response = await api.put<ICategory>(
          `/restricted/categories/${categoryId}/${company?.id}`,
          { ...saveCategoryDTO, companyId: company?.id },
        );

        setCategories([
          response.data,
          ...categories.filter(product => product.id !== categoryId),
        ]);
      } else {
        const response = await api.post<ICategory>(
          `/restricted/categories/${company?.id}`,
          {
            ...saveCategoryDTO,
            companyId: company?.id,
          },
        );
        setCategories([response.data, ...categories]);
      }
    },
    [categories, company],
  );

  const deleteCategory = useCallback(
    async (id: number) => {
      await api.delete(`restricted/categories/${id}/${company?.id}`);
      setCategories(oldState =>
        oldState.filter(category => category.id !== id),
      );
    },
    [company],
  );

  const changeStatus = useCallback(
    async (id: number, resource: string) => {
      await api.patch(`/restricted/categories/${company?.id}/${resource}`, {
        categoryId: id,
        companyId: company?.id,
      });

      const newCategories = [...categories];

      const updatedCategoryIndex = categories.findIndex(
        category => category.id === id,
      );

      if (updatedCategoryIndex > -1) {
        newCategories[updatedCategoryIndex].active =
          !newCategories[updatedCategoryIndex].active;

        setCategories(newCategories);
      }
    },
    [company, categories],
  );

  const orderCategories = useCallback(
    async (order: IOrderable[]) => {
      await api.patch<ICategory>(
        `/restricted/categories/${company?.id}/order`,
        order,
      );

      loadCategories();
    },
    [company, loadCategories],
  );

  const orderSubcategories = useCallback(
    async (categoryId: number, order: IOrderable[]) => {
      await api.patch<ICategory>(
        `/restricted/categories/${categoryId}/company/${company?.id}/subcategory-order`,
        order,
      );
    },
    [company],
  );

  const getOrderableCategories = useCallback(
    async (categoryId: number) => {
      const response = await api.get<ICategory[]>(
        `/restricted/categories/${company?.id}/${categoryId}/subcategories`,
      );

      setOrderableSubcategories(response.data);
    },
    [company],
  );

  const resetOrderableCategories = useCallback(() => {
    setOrderableSubcategories([]);
  }, []);

  return (
    <CategoriesContext.Provider
      value={{
        categories,
        subcategories,
        isCategoriesLoading,
        orderableSubcategories,
        saveCategory,
        changeStatus,
        deleteCategory,
        loadCategories,
        loadOnlyCategories,
        orderCategories,
        orderSubcategories,
        getOrderableCategories,
        resetOrderableCategories,
      }}
    >
      {children}
    </CategoriesContext.Provider>
  );
};

export function useCategories(): CategoriesContextData {
  const context = useContext(CategoriesContext);

  if (!context) {
    throw new Error('useCategories must be used within CategoriesProvider');
  }

  return context;
}
