import React, { useCallback, useEffect, useState } from 'react';
import ContainerForm from '../ContainerForm';
import FormlessInput from '../FormlessInput';
import IClient from '../../models/IClient';
import { useClient } from '../../hooks/clients';
import { useToast } from '../../hooks/toast';
import {
  CpfRgMask,
  maskPhoneNumber,
  unmaskCpfRg,
  unmaskPhoneNumber,
} from '../../utils/masks';
import { Main } from './styles';

interface IModalEditClientProps {
  onClose: () => void;
  client: IClient | undefined;
  setClients: React.Dispatch<React.SetStateAction<IClient[]>>;
}

const ModalEditClient: React.FC<IModalEditClientProps> = ({
  onClose,
  setClients,
  client,
}) => {
  const [clientNameError, setClientNameError] = useState('');
  const [clientPhoneError, setClientPhoneError] = useState('');
  const [clientDocumentError, setClientDocumentError] = useState('');
  const [clientName, setClientName] = useState('');
  const [clientPhone, setClientPhone] = useState('');
  const [clientDocument, setClientDocument] = useState('');
  const { addToast } = useToast();
  const { saveClient, getClients } = useClient();

  useEffect(() => {
    setClientName(client?.name || '');
    setClientPhone(client?.phoneNumber || '');
    setClientDocument(client?.document || '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [client || '']);

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  const handleOnSave = () => {
    if (!client?.isManualRegister) {
      addToast({
        type: 'info',
        description: 'Cliente registrados pela plataforma, impossível editar!',
      });
      return;
    }

    if (!clientName) {
      addToast({
        type: 'error',
        description: 'Nome do cliente obrigatório!',
      });
      setClientNameError('Nome do cliente obrigatório!');
      return;
    }
    if (!clientDocument) {
      addToast({
        type: 'error',
        description: 'Documento CPF ou RG é obrigatório!',
      });
      setClientDocumentError('Documento CPF ou RG é obrigatório!');
      return;
    }
    if (!clientPhone) {
      addToast({
        type: 'error',
        description: 'Telefone é obrigatório!',
      });
      setClientPhoneError('Telefone é obrigatório!');
      return;
    }

    const post: IClient = {
      document: unmaskCpfRg(clientDocument),
      isManualRegister: client?.isManualRegister || true,
      name: clientName,

      phoneNumber: unmaskPhoneNumber(clientPhone),
    };
    const put: IClient = {
      id: client?.id,
      document: unmaskCpfRg(clientDocument),
      isManualRegister: client?.isManualRegister || true,
      name: clientName,
      phoneNumber: unmaskPhoneNumber(clientPhone),
    };

    client?.id ? saveClient(put) : saveClient(post);

    const fetchClients = async () => {
      const clientData = await getClients();
      if (clientData.length > 0) {
        setClients(clientData);
      }
    };

    fetchClients();
    onClose();
  };

  const handleEditDocument = (e: string) => {
    if (e.length <= 12) {
      setClientDocument(CpfRgMask(e));
    }
  };
  const handleEditPhone = (e: string) => {
    if (e.length <= 12) {
      setClientPhone(maskPhoneNumber(e));
    }
  };
  return (
    <ContainerForm
      onSave={handleOnSave}
      onClose={onClose}
      type={client?.id ? 'Alteração de Cliente' : 'Cadastro de Cliente'}
    >
      <Main>
        <FormlessInput
          disabled={!client?.isManualRegister}
          name="Name"
          title="Nome"
          value={clientName}
          error={clientNameError}
          onChange={e => setClientName(e.target.value)}
        />
        <FormlessInput
          disabled={!client?.isManualRegister}
          name="Document"
          title="Documento"
          value={CpfRgMask(clientDocument)}
          error={clientDocumentError}
          onChange={e => handleEditDocument(e.target.value)}
        />
        <FormlessInput
          disabled={!client?.isManualRegister}
          name="phoneNumber"
          title="Telefone"
          value={maskPhoneNumber(clientPhone)}
          error={clientPhoneError}
          onChange={e => handleEditPhone(e.target.value)}
        />
      </Main>
    </ContainerForm>
  );
};

export default ModalEditClient;
