export interface IWaiter {
  id?: number;
  name: string;
  phoneNumber?: string;
  active: boolean;
}
export const defaultWaiter = {
  name: '',
  active: true,
};
