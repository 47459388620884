import styled, { keyframes } from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 1rem;
`;

export const WrapperContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 0.5rem;
`;
export const SearchContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  margin-bottom: 1rem;
`;
export const GridBox = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(80px, 1fr));
  gap: 10px;
  max-height: 330px;
  overflow-y: auto;
  padding: 10px;
  /* box-shadow: rgba(0, 0, 0, 0.35) 0px -35px 16px -40px inset,
    rgba(0, 0, 0, 0.35) 0px 35px 16px -40px inset; */
  border-radius: 4px;
  width: 100%;

  @media (max-width: 768px) {
    grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  }
`;

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(-5px); }
  40% { transform: translateX(5px); }
  60% { transform: translateX(-5px); }
  80% { transform: translateX(5px); }
  100% { transform: translateX(0); }
`;
interface IBoxProps {
  animate: boolean;
  checked: boolean;
}
export const Box = styled.div<IBoxProps>`
  display: flex;
  position: relative;
  height: auto;
  width: auto;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.control};
  place-content: center;
  align-items: center;
  cursor: pointer;
  transition: ${({ checked }) => !checked && '600ms'};
  animation: ${({ animate, checked }) =>
      animate || checked ? shakeAnimation : 'none'}
    0.5s ease-out;

  border: ${({ checked, theme }) =>
    checked ? `3px solid ${theme.palette.primary};` : 'none'};

  .identification {
    color: #fff;
    font-size: 18px;
    align-items: center;
    p {
      display: flex;
      text-align: center;
      place-content: center;
    }
    svg {
      margin-top: -10px;
    }
  }

  :hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  @media (max-width: 768px) {
    flex: 1 1 50px;
  }
`;

export const GroupSubTitles = styled.span`
  width: 100%;
  border-bottom: 1px solid #db1622;
  font-size: 18px;
`;
export const ContainerBtn = styled.span<{ type?: string }>`
  display: flex;
  width: 100%;
  justify-content: ${({ type }) =>
    type === 'table' ? 'space-between' : 'end'};
  button {
    width: 100px;
  }
  padding-left: 10px;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const SelectAll = styled.div`
  display: flex;
  width: 100%;
  justify-content: start;
  gap: 2rem;
  align-items: center;

  input[type='checkbox'] {
    appearance: none; /* Remove o estilo padrão do checkbox */
    width: 30px;
    height: 30px;
    border: 2px solid ${({ theme }) => theme.palette.primary};
    border-radius: 4px;
    text-align: center;
    position: relative;
    cursor: pointer;

    &:checked {
      background-color: ${({ theme }) => theme.palette.primary};

      &::after {
        content: '✔'; /* Adiciona o checkmark */
        color: white;
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export const DonwloadQrCode = styled.a`
  display: flex;
  font-size: 20px;
  text-decoration: underline;
  align-items: center;
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  gap: 0.5rem;
  transition: 300ms;

  :hover {
    color: ${({ theme }) => theme.palette.primary_light};
  }
  @media (max-width: 700px) {
    font-size: 18px;
  }
`;
