import React, { useMemo, useState } from 'react';
import { HiDotsVertical } from 'react-icons/hi';

import theme from '../../../../styles/themes/light';
import { Cell, OptionButton, Row, Image } from './styles';

import { ReactComponent as ProductPlaceholderSVG } from '../../../../assets/icons/product_placeholder.svg';

import { randomInt } from '../../../../utils/numbers';
import { formatToMoney } from '../../../../utils/format';

import { IProductSell } from '../../../../models/IFastSell';

import Drawer from '../../../../components/Drawer';
import Dropdown from '../../../../components/Dropdown';
import DetailProductToGrid from '../DetailProductToGrid';
import MountYourPizza from '../MountYourPizza';
import ICategory from '../../../../models/ICategory';

interface IProductProps {
  categories: ICategory[];
  allProducts: IProductSell[];
  product: IProductSell;
  setProductsToGrid: (value: React.SetStateAction<IProductSell[]>) => void;
}
const Product: React.FC<IProductProps> = ({
  product,
  setProductsToGrid,
  categories,
  allProducts,
}) => {
  const [open, setOpen] = useState(false);
  const dropdownButton = (
    <OptionButton>
      <HiDotsVertical size={22} color={theme.palette.control_light} />
    </OptionButton>
  );

  const dropdownOptions = [
    {
      title: 'Editar',
      onClick: () => setOpen(true),
    },
    {
      title: 'Remover',
      onClick: () =>
        setProductsToGrid(prev => {
          return prev.filter(prevProduct => prevProduct.hash !== product.hash);
        }),
    },
  ];

  const productIcon = useMemo(() => {
    if (product.imageUrl) {
      return <img src={product.imageUrl} alt={product.title} />;
    }

    const classes = ['blue', 'red', 'orange', 'green', 'yellow'];

    const randomClass = classes[randomInt(0, classes.length - 1)];
    return <ProductPlaceholderSVG className={randomClass} />;
  }, [product]);

  const categoryPizza = categories.filter(
    category => category.id === product?.pizzaCategoryId,
  );

  return (
    <>
      <Row>
        <Cell>
          <Image>{productIcon}</Image>
        </Cell>
        <Cell>{product.productId}</Cell>
        <Cell>{product.title}</Cell>
        <Cell>{product.qty}</Cell>
        <Cell>{formatToMoney(product?.total ?? 0)}</Cell>
        <Cell>
          <Dropdown button={dropdownButton} options={dropdownOptions} />
        </Cell>
      </Row>
      {open && (
        <>
          {product.productId !== 0 ? (
            <Drawer setOpen={setOpen} open={open} zindex={9}>
              <DetailProductToGrid
                product={product}
                setOpenDrawer={setOpen}
                setProductsToGrid={setProductsToGrid}
              />
            </Drawer>
          ) : (
            <MountYourPizza
              open={open}
              categories={categoryPizza}
              allProducts={allProducts}
              setOpen={setOpen}
              editPizza={product}
              setProductsToGrid={setProductsToGrid}
            />
          )}
        </>
      )}
    </>
  );
};

export default Product;
