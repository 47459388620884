import styled from 'styled-components';
import { Form } from '@unform/web';
import Radio from '../../Radio';

export const Wrapper = styled(Form)`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

export const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 16px;
  }

  :first-child {
    margin-top: unset;
  }

  #contentContainer {
    margin-top: 0;
  }

  #settings-bsticket-products {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    #bs-ticket-order-products-button {
      margin-left: unset;
    }
  }
`;

export const GroupTitle = styled.div`
  border-bottom: 4px solid #db1622;

  font-weight: 500;
  font-size: 24px;

  button {
    border: 0;
    color: #fff;
    background: #db1622;
    width: 35px;
    height: 35px;
    margin-bottom: 16px;
  }
`;
export const GroupSubTitles = styled.span`
  width: fit-content;
  border-bottom: 4px solid #db1622;
  margin-top: 32px;
  font-size: 18px;
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 32px;

  span {
    margin-left: 16px;
  }
`;
export const GroupDescription = styled.p`
  margin-top: 16px;
`;
export const RadioContainer = styled(Radio.Group)`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: -4px;

  input {
    display: none;
  }

  label {
    margin-top: 8px;
    user-select: none;
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 1.2rem;
    padding-bottom: 8px;
    transition: border-color 200ms ease;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text};

    .checked {
      display: none;
    }
  }

  input:checked + label {
    border-color: ${({ theme }) => theme.palette.primary};
    .unchecked {
      display: none;
    }
    .checked {
      display: block;
    }
  }

  @media (max-width: 800px) {
    label {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    label {
      margin-bottom: 0.4rem;
    }
  }
`;

export const ContainerDescription = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;
export const Generators = styled.div`
  display: flex;
  width: 100%;
  flex-wrap: wrap;
  gap: 2rem;
`;
export const MarginIcon = styled.div`
  display: flex;
  place-content: center;
  margin-top: 10px;
`;

export const ContainerDemo = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 600px) {
    flex-direction: column;
  }
`;

export const DemoOptions = styled.div`
  display: flex;
  width: 200px;
  flex-direction: column;
  height: 100%;
  text-align: center;
  gap: 0.75rem;

  @media (max-height: 840px) {
    gap: 1rem;
  }
`;

export const Image = styled.div.attrs({ className: 'warning-image' })`
  justify-self: center;
  align-self: center;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
  }

  .custom-svg .background {
    fill: ${({ theme }) => theme.palette.primary};
  }
  .custom-svg .shirt {
    fill: ${({ theme }) => theme.palette.primary};
  }

  width: 75px;
  height: 75px;
`;

export const GridButtons = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const SvgWrapper = styled.div`
  flex: 1;
  width: 25px;
  height: 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 25%;

  svg {
    width: 100%;
    height: 100%;
    max-width: 50px;
    max-height: 50px;
  }
`;

export const Button = styled.button`
  display: flex;
  border: none;
  padding: 4px;
  background: ${({ theme }) => theme.palette.background};
  border-radius: 6px;
  box-shadow: rgba(17, 17, 26, 0.05) 0px 1px 0px,
    rgba(17, 17, 26, 0.1) 0px 0px 8px;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;

  transition: 400ms;

  p {
    font-size: 12px;
    font-weight: 500;
    flex: 3;
    text-align: left;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const PhoneFrame = styled.div`
  display: flex;
  max-width: 240px;
  flex-direction: column;
  height: 400px;
  padding: 8px;
  justify-content: center;
  border: 12px solid #333;
  border-radius: 30px;
  background: #f0f0f0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
`;

export const ButtonFrame = styled.div`
  margin-top: -12px;
  display: flex;
  width: 100%;
  justify-content: center;

  div {
    width: 80px;
    background-color: #333;
    border-radius: 30px;
    height: 10px;
  }
`;
