import React, { ReactElement } from 'react';
import { Button } from './styles';

interface IIconProps extends Partial<HTMLDivElement> {
  onClick?: React.MouseEventHandler<HTMLDivElement>;
  icon: ReactElement<any, any>;
  hover?: boolean;
}

const IconButton: React.FC<IIconProps> = ({ onClick, icon, hover = true }) => {
  return (
    <Button hover={hover} onClick={onClick}>
      {icon}
    </Button>
  );
};

export default IconButton;
