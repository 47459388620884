import React, { useState } from 'react';
import { ContainerForm, Tittle, Wrapper } from './styles';

import { ICard } from '../../models/ICard';
import { ITable2 } from '../../models/ITable2';

import { useCards } from '../../hooks/card';
import { useTable } from '../../hooks/table';

import Modal from '../Modal';
import BasicInput from '../BasicInput';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import AmountInput from '../AmountInput';

interface IModalOpenCardTableProps {
  card?: ICard;
  table?: ITable2;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setCardState?: React.Dispatch<React.SetStateAction<ICard>>;
  setTableState?: React.Dispatch<React.SetStateAction<ITable2>>;
}
const ModalOpenCardTable: React.FC<IModalOpenCardTableProps> = ({
  card,
  table,
  open,
  setOpen,
  setOpenDrawer,
  setCardState,
  setTableState,
}) => {
  const { putOccupy } = useCards();
  const { ThrowError } = useConfirmDialog();
  const { putOccupy: putOccupyTable } = useTable();

  const [location, setLocation] = useState('');
  const [personQtd, setPersonQtd] = useState(1);
  const [loading, setLoading] = useState(false);
  const [description, setDescription] = useState('');

  const handleConfirm = async () => {
    setLoading(true);
    try {
      const responseCard =
        card &&
        (await putOccupy({
          ...card,
          description,
          location,
        }));
      responseCard && setCardState && setCardState(responseCard?.data);

      const responseTable =
        table &&
        (await putOccupyTable({
          ...table,
          description,
          personQty: personQtd,
        }));
      responseTable && setTableState && setTableState(responseTable?.data);
      setOpenDrawer(true);
    } catch (error) {
      ThrowError(error, `Erro ao abrir ${card ? 'comanda' : 'mesa'}`);
    } finally {
      setOpen(false);
    }
  };
  return (
    <Modal
      open={open}
      loading={loading}
      setOpen={setOpen}
      onConfirm={handleConfirm}
      titleConfirm={`Abrir ${card ? 'comanda' : 'mesa'}`}
    >
      <Wrapper>
        <Tittle>
          <p>
            Abrir
            {table && ` Mesa ${table.tableNumber}`}
            {card && ` Comanda ${card.cardNumber}`}
          </p>
        </Tittle>
        <ContainerForm>
          <div className="separament">
            {table && (
              <div className="first-child">
                <p>QtdPessoas</p>
                <div className="input">
                  <AmountInput
                    value={personQtd}
                    setValue={setPersonQtd}
                    onIncreaseAmount={setPersonQtd}
                    onDecreaseAmount={setPersonQtd}
                  />
                </div>
              </div>
            )}
            <BasicInput
              scale="lg"
              value={description}
              title="Identificação"
              onChange={e => setDescription(e.target.value)}
            />
          </div>
          {card && (
            <BasicInput
              scale="lg"
              value={location}
              title="Localização"
              onChange={e => setLocation(e.target.value)}
            />
          )}
        </ContainerForm>
      </Wrapper>
    </Modal>
  );
};

export default ModalOpenCardTable;
