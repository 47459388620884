import React, {
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react';
import { Container, Pages, Page } from './styles';
import { clamp } from '../../utils/numbers';

export interface PagerRef {
  setPage: (pageIndex: number) => void;
}

interface PagerProps {
  children: React.ReactNode[];
}

const Pager: React.ForwardRefRenderFunction<PagerRef, PagerProps> = (
  { children },
  ref,
) => {
  const pagesRef = useRef<HTMLDivElement>(null);

  const childrenCount = useMemo(
    () => React.Children.count(children),
    [children],
  );

  const translateToPage = useCallback((pageIndex: number) => {
    if (pagesRef?.current) {
      pagesRef.current.style.transform = `translateX(${pageIndex * -100}%)`;
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      setPage: (pageIndex: number) => {
        translateToPage(clamp(pageIndex, 0, childrenCount));
      },
    }),
    [translateToPage, childrenCount],
  );

  return (
    <Container>
      <Pages ref={pagesRef}>
        {React.Children.map(children, (child: React.ReactNode, index) => {
          if (React.isValidElement(child)) {
            return React.cloneElement(child, {
              ...child.props,
              index,
              // eslint-disable-next-line react/no-array-index-key
              key: index.toString(),
            });
          }

          return child;
        })}
      </Pages>
    </Container>
  );
};

export default {
  Container: React.forwardRef(Pager),
  Page,
};
