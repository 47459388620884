import React, { useEffect, useMemo, useRef, useState } from 'react';

import { FiArrowDown, FiArrowUp } from 'react-icons/fi';

import { useCashDrawer } from '../../hooks/cash_drawers';

import {
  Grid2,
  Buttons,
  Movment,
  Receipt,
  Wrapper,
  GridInfos,
  Totalizer,
  ReceiptItem,
  DoubleReceipt,
  NotCashier,
  GroupSeller,
  ProductSeller,
  CanceledOrder,
  Grid,
} from './styles';

import { useConfirmDialog } from '../../hooks/confim_dialog';

import { ICashier } from '../../models/ICashier';
import { PaymentTypes } from '../../enums/paymentType';
import ICashierReport from '../../models/ICashierReport';

import { formatToFullLocalDate, formatToMoney } from '../../utils/format';
import { getPaymentTypeName } from '../../utils/string';
import { chooseColorPaymentTypeIcon } from '../../utils/icons';

import Button from '../../components/Button';
import ModalOpenCashier from '../../components/ModalOpenCashier';
import ModalCloseCashier from '../../components/ModalCloseCashier';
import CashierReportItem from '../../components/CashierReportItem';
import ModalSupplyCashierWithdrawal from '../../components/ModalSupplyCashierWithdrawal';
import CashierReportPrint, {
  ICashierReportPrintRef,
} from '../../components/CashierReportPrint';

const CashierManagement: React.FC = () => {
  const { ThrowError } = useConfirmDialog();
  const { getCashier, getSummary } = useCashDrawer();
  const reportPrintRef = useRef<ICashierReportPrintRef>(null);

  const [key, setKey] = useState(0);
  const [status, setStatus] = useState(true);
  const [openSupply, setOpenSupply] = useState(false);
  const [openCashier, setOpenCashier] = useState(false);
  // const [closeTheDark, setCloseTheDark] = useState(false);
  const closeTheDark = false; // substituir por um botão que permite e não permite mostrar os valores
  const [openCloseCashier, setOpenCloseCashier] = useState(false);
  const [cashier, setCashier] = useState<ICashier>({} as ICashier);
  const [openCashWithdrawal, setOpenCashWithdrawal] = useState(false); // Sangria
  const [summary, setSummary] = useState<ICashierReport>({} as ICashierReport);

  useEffect(() => {
    const fetchCashierSummary = async () => {
      try {
        const response = await getCashier();
        setCashier(response.data);
        if (response.data.id !== 0) {
          const responseSummary = await getSummary(response.data.id);
          setSummary(responseSummary.data);
          setStatus(true);
        } else {
          setStatus(false);
        }
      } catch (error) {
        ThrowError(error, 'Erro ao buscar dados!');
      }
    };
    fetchCashierSummary();
  }, [getCashier, getSummary, key, ThrowError]);

  // const resumeSeller = [
  //   {
  //     id: 0,
  //     name: 'Sherek Albino',
  //     amount: 2255.35,
  //   },
  //   {
  //     id: 1,
  //     name: 'Fiona Verde',
  //     amount: 1340.5,
  //   },
  //   {
  //     id: 2,
  //     name: 'Burro Falante',
  //     amount: 980.75,
  //   },
  //   {
  //     id: 3,
  //     name: 'Gato de Botas',
  //     amount: 1575.9,
  //   },
  //   {
  //     id: 4,
  //     name: 'Lord Farquaad',
  //     amount: 210.15,
  //   },
  //   {
  //     id: 5,
  //     name: 'Dragão Rosa',
  //     amount: 3200.0,
  //   },
  // ];
  const totalMovments = summary?.cashMovements?.reduce((acc, movment) => {
    const amount = movment.type === 0 ? -movment.amount : movment.amount;
    return acc + amount;
  }, 0);

  // const totalResumeSeller = resumeSeller.reduce((acc, seller) => {
  //   return acc + seller.amount;
  // }, 0);

  const totalHistory = summary?.shiftHistory?.reduce((acc, history) => {
    return acc + history.amount;
  }, 0);

  const resume = [
    {
      id: 1,
      name: 'Suprimentos/Sangrias',
      amount: totalMovments,
    },
    // {
    //   id: 2,
    //   name: 'Vendas',
    //   amount: summary?.cashSummary?.actualCashAmount ?? 0,
    // },
  ];

  const productsSalesTotal = useMemo(() => {
    return summary?.items?.reduce(
      (acc, current) =>
        acc +
        current.items.reduce(
          (itemAcc, currentItem) => itemAcc + currentItem.total,
          0,
        ),
      0,
    );
  }, [summary]);

  const totalResume = resume.reduce((acc, res) => {
    return acc + res.amount;
  }, 0);

  return (
    <>
      <Wrapper>
        <Buttons>
          <h1>Meu caixa</h1>
          <div className="btns">
            {status && (
              <Button
                border={false}
                title="Suprimento"
                bg="#D6F0E1"
                color="#27AE60"
                onClick={() => setOpenSupply(true)}
              />
            )}
            {status && (
              <Button
                border={false}
                title="Sangria"
                bg="#FADDDA"
                color="#DA123A"
                onClick={() => setOpenCashWithdrawal(true)}
              />
            )}
            {status ? (
              <Button
                title="Fechar caixa"
                outline
                border
                onClick={() => setOpenCloseCashier(true)}
              />
            ) : (
              <Button
                title="Abrir caixa"
                onClick={() => setOpenCashier(true)}
              />
            )}
          </div>
        </Buttons>
        {status ? (
          <GridInfos className="has-custom-scroll-bar-2">
            <CashierReportItem
              onClickCashierReport={() => null}
              onPrintClicked={() => reportPrintRef?.current?.print()}
              id={cashier?.id}
              end=""
              start={cashier?.start}
              openOperatorName={cashier?.openUserName}
            />
            {summary?.cashMovements?.length > 0 && (
              <Receipt>
                <p className="title">Suprimentos/Sangrias</p>
                <Grid>
                  {summary?.cashMovements?.length > 0 ? (
                    summary?.cashMovements?.map(movment => {
                      const label = movment.description;
                      const value = formatToMoney(movment.amount);
                      return (
                        <ReceiptItem key={movment.id} doubleLine>
                          <div>
                            {movment.type === 0 ? (
                              <FiArrowDown size={22} color="#DA123A" />
                            ) : (
                              <FiArrowUp size={22} color="#27AE60" />
                            )}
                            <p>{label}</p>
                            {/* <div className="operator-date">
                          <p>(</p>
                          <p>{`${movment.operatorName} -`}</p>
                          <p>{formatToFullLocalDate(movment.date)}</p>
                          <p>)</p>
                        </div> */}
                          </div>
                          <Movment type={movment.type}>
                            {movment.type === 0 && '-'}
                            {closeTheDark ? 'R$ ****' : value}
                          </Movment>
                        </ReceiptItem>
                      );
                    })
                  ) : (
                    <p>Nenhuma operação realizada!</p>
                  )}
                </Grid>
                <Totalizer negative={totalMovments < 0}>
                  {closeTheDark
                    ? 'R$ ****'
                    : `Total:  ${formatToMoney(totalMovments)}`}
                </Totalizer>
              </Receipt>
            )}

            <Grid2>
              <Receipt>
                <p className="title">Recebimentos</p>
                {summary?.salesPaymentMethods?.length > 0 ? (
                  summary?.salesPaymentMethods?.map((payment, index) => {
                    const icon = chooseColorPaymentTypeIcon(
                      payment.paymentType as PaymentTypes,
                    );
                    const label = getPaymentTypeName(payment.paymentType)
                      ? getPaymentTypeName(payment.paymentType)
                      : payment.paymentType;
                    const value = formatToMoney(payment.paymentAmount);
                    return (
                      // eslint-disable-next-line react/no-array-index-key
                      <ReceiptItem key={index}>
                        <div>
                          <img src={icon} alt={label} />
                          <p>{label}</p>
                        </div>
                        {closeTheDark ? 'R$ ****' : value}
                      </ReceiptItem>
                    );
                  })
                ) : (
                  <p>Nenhum recebimento encontrado!</p>
                )}
              </Receipt>

              <Receipt>
                <p className="title">Resumo do caixa</p>
                {resume?.length > 0 ? (
                  resume.map(operation => {
                    const label = operation.name;
                    const value = formatToMoney(operation.amount);
                    return (
                      <ReceiptItem key={operation.id}>
                        <div>
                          <p>{label}</p>
                        </div>
                        <Movment type={operation.amount < 0 ? 0 : 1}>
                          {closeTheDark ? 'R$ ****' : value}
                        </Movment>
                      </ReceiptItem>
                    );
                  })
                ) : (
                  <p>Nenhuma operação registrada!</p>
                )}
                <Totalizer negative={totalResume < 0}>
                  {closeTheDark
                    ? 'R$ ****'
                    : `Total: ${formatToMoney(totalResume)}`}
                </Totalizer>
              </Receipt>
            </Grid2>
            {/* Resumo do caixa pedidos cancelados produtos vendidos */}
            <Grid2>
              <Receipt>
                <p className="title">Produtos vendidos</p>
                <GroupSeller>
                  {summary?.items?.length > 0 ? (
                    summary.items.map((category, index) => {
                      return (
                        // eslint-disable-next-line react/no-array-index-key
                        <div className="category" key={index}>
                          <h4 key={category.category}>{category.category}</h4>
                          {category.items
                            .sort((a, b) => b.qty - a.qty)
                            .map((product, index) => {
                              const value = formatToMoney(product.total);
                              const unitPrice = formatToMoney(
                                product.unitPrice,
                              );
                              return (
                                // eslint-disable-next-line react/no-array-index-key
                                <ProductSeller key={index}>
                                  <p className="label">{product.title}</p>
                                  <div>
                                    <span>Qtde</span>
                                    <p>{product.qty}</p>
                                  </div>
                                  <div className="center">
                                    <span>Preço</span>
                                    <p>
                                      {closeTheDark ? 'R$ ****' : unitPrice}
                                    </p>
                                  </div>
                                  <div className="end">
                                    <span>Total</span>
                                    <p>{closeTheDark ? 'R$ ****' : value}</p>
                                  </div>
                                </ProductSeller>
                              );
                            })}
                        </div>
                      );
                    })
                  ) : (
                    <p>Nenhum produto vendido!</p>
                  )}
                </GroupSeller>
                <Totalizer negative={productsSalesTotal < 0} border={false}>
                  {closeTheDark
                    ? 'R$ ****'
                    : `Total: ${formatToMoney(productsSalesTotal)}`}
                </Totalizer>
              </Receipt>
              <DoubleReceipt>
                {/* <Receipt>
                  <p className="title">Resumo por Vendedor</p>
                  {resumeSeller?.length > 0 ? (
                    resumeSeller
                      .sort((a, b) => b.amount - a.amount)
                      .map((seller, index) => {
                        const { name } = seller;
                        const value = formatToMoney(seller.amount);
                        return (
                          <ReceiptItem key={seller.id}>
                            <p>{`${index + 1}° ${name}`}</p>
                            <p>{value}</p>
                          </ReceiptItem>
                        );
                      })
                  ) : (
                    <p>Nenhuma venda registrada!</p>
                  )}
                  <Totalizer negative={false}>
                    {`Total: ${formatToMoney(totalResumeSeller)}`}
                  </Totalizer>
                </Receipt> */}
                <Receipt>
                  <p className="title">Histórico</p>
                  {summary.shiftHistory?.length > 0 ? (
                    summary?.shiftHistory?.map(history => {
                      const label = history.description;
                      const value = formatToMoney(history.amount);
                      return (
                        <CanceledOrder key={history.id}>
                          <div>
                            <span>{history.type}</span>
                            <p>{`${label}`}</p>
                            <p>{`${formatToFullLocalDate(history.date)}`}</p>
                          </div>
                          <div>
                            <span>Total</span>
                            <p>{closeTheDark ? 'R$ ****' : value}</p>
                            <p>{history.userName && history.userName}</p>
                          </div>
                        </CanceledOrder>
                      );
                    })
                  ) : (
                    <p>Nenhuma venda registrada!</p>
                  )}
                  <Totalizer negative={false} border={false}>
                    {closeTheDark
                      ? 'R$ ****'
                      : `Total: ${formatToMoney(totalHistory)}`}
                  </Totalizer>
                </Receipt>
                {summary?.canceledOrders && (
                  <Receipt>
                    <p className="title">Pedidos Cancelados</p>
                    {summary?.canceledOrders?.orders?.length > 0 ? (
                      summary?.canceledOrders?.orders?.map(order => {
                        const label = order.number;
                        const value = formatToMoney(order.total);
                        return (
                          <CanceledOrder key={order.id}>
                            <div>
                              <span>Pedido</span>
                              <p>{`#${label}`}</p>
                            </div>
                            <div>
                              <span>Total</span>
                              <p>{closeTheDark ? 'R$ ****' : value}</p>
                            </div>
                          </CanceledOrder>
                        );
                      })
                    ) : (
                      <p>Nenhum pedido cancelado!</p>
                    )}
                    <Totalizer
                      border={false}
                      negative={
                        (summary?.canceledOrders?.canceledTotal ?? 0) < 0
                      }
                    >
                      {closeTheDark
                        ? 'R$ ****'
                        : `Total: ${formatToMoney(
                            summary?.canceledOrders.canceledTotal,
                          )}`}
                    </Totalizer>
                  </Receipt>
                )}
              </DoubleReceipt>
            </Grid2>
          </GridInfos>
        ) : (
          <NotCashier>
            <h2>Nenhum caixa aberto!</h2>
            <button type="button" onClick={() => setOpenCashier(true)}>
              Clique para abrir o caixa
            </button>
          </NotCashier>
        )}
      </Wrapper>
      {openCloseCashier && (
        <ModalCloseCashier
          cashierId={cashier.id}
          open={openCloseCashier}
          setKey={setKey}
          setOpen={setOpenCloseCashier}
        />
      )}

      {openCashier && (
        <ModalOpenCashier
          open={openCashier}
          lastShiftCloseAmount={cashier.lastShiftCloseAmount}
          setKey={setKey}
          setOpen={setOpenCashier}
        />
      )}
      {openSupply && (
        <ModalSupplyCashierWithdrawal
          open={openSupply}
          type="supply"
          setKey={setKey}
          setOpen={setOpenSupply}
        />
      )}

      {openCashWithdrawal && (
        <ModalSupplyCashierWithdrawal
          open={openCashWithdrawal}
          type="withdrawal"
          setKey={setKey}
          setOpen={setOpenCashWithdrawal}
        />
      )}
      {summary?.shift && (
        <CashierReportPrint ref={reportPrintRef} cashierReport={summary} />
      )}
    </>
  );
};

export default CashierManagement;
