import React from 'react';
import { FiCheck } from 'react-icons/fi';
import { MdAddShoppingCart } from 'react-icons/md';
import { formatToMoney } from '../../utils/format';
import { ContainerInput, FinishResponsive } from './styles';
import AmountInput from '../AmountInput';
import Button from '../Button';

interface IButtonFinishDetailProductProps {
  subtotal: number;
  qtd?: number;
  title: string;
  loading?: boolean;
  onIncrease?: () => void;
  onDecrease?: () => void;
  handleFinish?: () => void;
}

const ButtonFinishDetailProduct: React.FC<IButtonFinishDetailProductProps> = ({
  subtotal,
  qtd,
  title,
  handleFinish,
  onIncrease,
  onDecrease,
}) => {
  return (
    <FinishResponsive>
      <div className="subtotal">
        {onIncrease && onDecrease && (
          <ContainerInput>
            <AmountInput
              value={qtd ?? 0}
              onIncreaseAmount={onIncrease}
              onDecreaseAmount={onDecrease}
            />
          </ContainerInput>
        )}
        <strong>
          {title === 'Finalizar!' && 'Total:'}
          {` `}
          {formatToMoney(subtotal)}
        </strong>
      </div>
      <Button
        title={title}
        onClick={handleFinish}
        icon={
          title === 'Adicionar' ? (
            <MdAddShoppingCart size={22} color="#fff" />
          ) : (
            <FiCheck size={22} color="#fff" />
          )
        }
      />

      {/* <button type="button" onClick={handleFinish}>
        {loading ? (
          <Lottie
            options={{
              animationData: animation,
            }}
            width={80}
            height={80}
            style={{ overflow: 'visible' }}
          />
        ) : (
          <div className="button">
            <span>{title}</span>
            <FiCheck className="finish-icon" />
          </div>
        )}
      </button> */}
    </FinishResponsive>
  );
};

export default ButtonFinishDetailProduct;
