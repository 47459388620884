import React, { Dispatch, SetStateAction } from 'react';
import { ModalBody, ModalWrapper } from './styles';

import money from '../../assets/money.svg';
import nomoney from '../../assets/no-money.svg';

import { formatToMoney } from '../../utils/format';

import Modal from '../Modal';

interface IModalFinishPaymentProps {
  open: boolean;
  title: string;
  loading: boolean;
  needToPay: number;
  smallCash: number;
  totalValue: number;
  totalPayment: number;
  onConfirm: () => void;
  setOpen: Dispatch<SetStateAction<boolean>>;
}

const ModalFinishPayment: React.FC<IModalFinishPaymentProps> = ({
  open,
  title,
  loading,
  needToPay,
  smallCash,
  totalValue,
  totalPayment,
  setOpen,
  onConfirm,
}) => {
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={onConfirm}
      loading={loading}
    >
      <ModalWrapper>
        <h3>Finalizar pagamento</h3>
        <ModalBody>
          {totalPayment > totalValue && (
            <h4>{`Troco no valor de ${formatToMoney(smallCash)}`}</h4>
          )}
          {totalPayment < totalValue && (
            <h4>
              {`Pagamento parcial, restando á pagar ${formatToMoney(
                needToPay,
              )}`}
            </h4>
          )}

          <img src={totalPayment >= totalValue ? money : nomoney} alt="" />
          {totalPayment >= totalValue ? (
            <p>Deseja finalizar a etapa de pagamento?</p>
          ) : (
            <p>{`Deseja finalizar mantendo a ${title} em aberto?`}</p>
          )}
        </ModalBody>
      </ModalWrapper>
    </Modal>
  );
};

export default ModalFinishPayment;
