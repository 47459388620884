import styled, { css } from 'styled-components';
import Tooltip from '../Tooltip';

interface IContainerProps {
  visible: boolean;
}

interface IOperatorNameProps {
  hasError: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  position: absolute;
  z-index: 20;
  bottom: 0;
  background: #f4f4f6;

  transition: height 0.4s;

  overflow: auto;

  ${({ visible }) =>
    visible
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
        `}
`;

export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  max-height: 100px;
  padding: 32px;

  span {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    max-height: 150px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const OperatorInfo = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    margin-right: 16px;

    @media (max-width: 460px) {
      margin-right: 0;
    }
  }

  > div {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 460px) {
      margin-top: 32px;
    }
  }

  > span {
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
`;

export const OperatorNameContainer = styled.div`
  display: flex;
  position: relative;
`;

export const OperatorName = styled.input<IOperatorNameProps>`
  font-size: 32px;
  margin-left: 8px;

  background: none;
  border: 0;

  padding-bottom: 8px;

  ${({ hasError }) =>
    hasError
      ? css`
          border-bottom: 2px solid #c53030;
        `
      : css`
          border-bottom: 2px solid #db1622;
        `}

  margin-right: 32px;

  @media (max-width: 800px) {
    font-size: 24px;
  }

  @media (max-width: 380px) {
    font-size: 21px;
  }
`;

export const Error = styled(Tooltip)`
  height: 20px;
  position: absolute;
  top: 16px;
  right: 36px;

  svg {
    margin: 0;
  }

  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;

  .on-hover {
    transition: border-bottom 0.2s;

    &:hover {
      border-bottom: 8px solid #db1622;
    }
  }
`;

export const SaveOperatorButton = styled.button`
  display: flex;
  align-items: center;

  font-size: 32px;

  background: none;
  border: 0;

  padding-bottom: 8px;

  border-bottom: 2px solid #db1622;

  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 8px solid #db1622;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;

export const SaveOperatorFloatingButton = styled.button`
  display: none;
  align-items: center;
  justify-content: center;

  width: 60px;
  height: 60px;
  border-radius: 50%;

  background: #db1622;
  border: 0;

  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);

  position: absolute;
  right: 16px;
  bottom: 32px;

  @media (max-width: 650px) {
    display: flex;
  }

  @supports (-webkit-touch-callout: none) {
    bottom: 96px;
  }
`;

export const Main = styled.div`
  padding: 32px;
  display: flex;
  flex-direction: column;

  > div + div {
    margin-top: 32px;
  }
`;
export const GroupSubTitles = styled.span`
  width: fit-content;
  border-bottom: 4px solid #db1622;
  margin-top: 32px;
  font-size: 18px;
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 32px;

  span {
    margin-left: 16px;
  }
`;
