import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(+100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;

interface ISellWrapperProps {
  close: boolean;
}

export const SellWrapper = styled.div<ISellWrapperProps>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999;
  background-color: ${({ theme }) => theme.palette.background_secondary};

  @media (max-width: 700px) {
    height: 100%;
  }

  ${({ close }) =>
    close
      ? css`
          animation: ${slideOut} 0.5s ease forwards;
        `
      : css`
          animation: ${slideIn} 0.5s ease forwards;
        `}
`;
interface IOverlayProps {
  openCartSell: boolean;
}

export const FirstDiv = styled.div<IOverlayProps>`
  flex: 2;
  @media (max-width: 1280px) {
    flex: 1;
  }
  @media (max-width: 700px) {
    flex: none;
    width: 100%; /* Ocupa 100% da largura da tela */
    display: ${({ openCartSell }) => (!openCartSell ? 'block' : 'none')};
  }
  background-color: ${({ theme }) => theme.palette.background_secondary};
  overflow-x: hidden;
`;

export const SecondDiv = styled.div<IOverlayProps>`
  flex: 1;
  @media (max-width: 700px) {
    width: 100%;
    display: none;
    flex: none;
    display: ${({ openCartSell }) => (!openCartSell ? 'none' : 'block')};
  }

  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: -2px 2px 7px -1px rgba(0, 0, 0, 0.15);
  border-radius: 16px 0 0 16px;
  height: 100vh;
  overflow: hidden;

  @media (max-width: 700px) {
    height: 100%;
  }
`;
