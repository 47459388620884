import 'react-toggle/style.css';

import { Link, useHistory } from 'react-router-dom';
import * as clipboard from 'clipboard-polyfill/text';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import {
  FiMenu,
  FiLogOut,
  FiShare2,
  FiArrowLeft,
  FiChevronDown,
} from 'react-icons/fi';

import Toggle from 'react-toggle';
import { choosePageIcon } from '../../utils/icons';
import { chooseBsFoodOrVarejo } from '../../utils/subdomain';

import { useToast } from '../../hooks/toast';
import { useOrders } from '../../hooks/orders';
import { useCompany } from '../../hooks/company';
import { useSidebar } from '../../hooks/sidebar';
import { AuthRole, PagesAccessProfiles, useAuth } from '../../hooks/auth';

import {
  Logo,
  Badge,
  Image,
  Pages,
  Header,
  Welcome,
  Content,
  PageName,
  Container,
  Indicator,
  InnerContent,
  HeaderBottom,
  IconContainer,
  SidebarHeader,
  ProductsGroup,
  SettingsGroup,
  ChildrenContainer,
  ToggleContainer,
} from './styles';

import { SidebarTab } from '../SidebarTab';

import avatar from '../../assets/avatar.png';
import { PageNames } from '../../enums/pages';
import { useStatus } from '../../hooks/status';

export interface ISidebarRouteProps {
  isProductsActive: boolean;
  isSettingsActive: boolean;
  isRegistersActive: boolean;
}

const Sidebar: React.FC = ({ children }) => {
  const history = useHistory();

  const { addToast } = useToast();
  const { user, signOut } = useAuth();

  const { company } = useCompany();
  const { toggleIsOpen, toggleSendWhatsapp } = useStatus();

  const { visible, selectedPage, setVisible, toggleVisible, setSelectedPage } =
    useSidebar();

  const {
    recentOrders,
    pending: pendingOrders,
    connectToWebSocket,
  } = useOrders();

  const [isSellActive, setIsSellActive] = useState(false);
  const [isProductsActive, setIsProductsActive] = useState(true);
  const [isSettingsActive, setIsSettingsActive] = useState(false);
  const [isRegistersActive, setIsRegistersActive] = useState(false);
  const [wppActive, setWppActive] = useState(company?.sendWhatsapp || false);
  const [empriseActive, setEmpriseActive] = useState(company?.isOpen || false);

  useEffect(() => {
    setWppActive(company?.sendWhatsapp || false);
    setEmpriseActive(company?.isOpen || false);
  }, [company?.sendWhatsapp, company?.isOpen]);

  useEffect(() => {
    if (company) {
      connectToWebSocket(company);
    }
  }, [connectToWebSocket, company]);

  useEffect(() => {
    const name = chooseBsFoodOrVarejo('Food', 'Varejo');
    if (recentOrders.length > 0) {
      document.title = `(${recentOrders.length}) BS ${name} Dashboard`;
    } else {
      document.title = `BS ${name} Dashboard`;
    }
  }, [recentOrders]);

  useEffect(() => {
    if ('Notification' in window) {
      if (Notification.permission !== 'granted') {
        Notification.requestPermission();
      }
    }
  }, []);

  const handleNavigateTo = useCallback(
    (route: string, pageName: PageNames) => {
      setVisible(false);
      history.push(route);
      setSelectedPage(pageName);
    },
    [setVisible, history, setSelectedPage],
  );

  const handleOnSignOutClick = useCallback(() => {
    signOut();
    const name = chooseBsFoodOrVarejo('Food', 'Varejo');
    document.title = `BS ${name} Dashboard`;
  }, [signOut]);

  const handleToggleCompanyOpen = useCallback(() => {
    setEmpriseActive(prev => {
      toggleIsOpen(prev); // Envia o novo valor para a API
      return !prev;
    });
  }, [toggleIsOpen]);

  const handleToggleSendWhatsapp = useCallback(() => {
    setWppActive(prev => {
      toggleSendWhatsapp(prev); // Envia o novo valor para a API
      return !prev;
    });
  }, [toggleSendWhatsapp]);

  const handleToggle = useCallback(() => {
    toggleVisible();
  }, [toggleVisible]);

  const handleClose = useCallback(() => {
    setVisible(false);
  }, [setVisible]);

  const handleShare = useCallback(async () => {
    try {
      if (navigator.share) {
        const name = chooseBsFoodOrVarejo('Food', 'Varejo');
        await navigator.share({
          title: `${company?.tradingName} no BS ${name}`,
          text: 'Faça já o seu pedido!',
          url: `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
            'bsfood',
            'bsvarejo',
          )}.com.br/`,
        });
      } else {
        await clipboard.writeText(
          `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
            'bsfood',
            'bsvarejo',
          )}.com.br/`,
        );

        addToast({
          type: 'info',
          description: 'Copiado para àrea de transferência.',
        });
      }
    } catch {
      try {
        await clipboard.writeText(
          `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
            'bsfood',
            'bsvarejo',
          )}.com.br/`,
        );

        addToast({
          type: 'info',
          description: 'Copiado para àrea de transferência.',
        });
      } catch {
        // eslint-disable-next-line no-console
        console.log('Sharing not available!');
      }
    }
  }, [addToast, company]);

  const handleOnGroupClicked = useCallback(
    (
      group:
        | PageNames.PRODUCTS
        | PageNames.SETTINGS
        | PageNames.REGISTER
        | PageNames.CASHIER,
    ) => {
      if (group === PageNames.PRODUCTS) {
        setIsProductsActive(prevState => !prevState);
      } else if (group === PageNames.SETTINGS) {
        setIsSettingsActive(prevState => !prevState);
      } else if (group === PageNames.REGISTER) {
        setIsRegistersActive(prevState => !prevState);
      } else {
        setIsSellActive(prevState => !prevState);
      }
    },
    [],
  );

  // const subPageCashierIndicator = useMemo(() => {
  //   const pages = [PageNames.CASHIER, PageNames.CASHIER_REPORT];

  //   return {
  //     index: pages.indexOf(selectedPage),
  //     length: pages.length,
  //   };
  // }, [selectedPage]);

  const subPageProductIndicator = useMemo(() => {
    const pages = [
      PageNames.PRODUCTS,
      PageNames.STOCK,
      PageNames.HIGHLIGHTS,
      PageNames.COMPLEMENTS,
      PageNames.DISCOUNTS,
      PageNames.UPDATE_SKU,
    ];

    return {
      index: pages.indexOf(selectedPage),
      length: pages.length,
    };
  }, [selectedPage]);

  const subPageRegisterIndicator = useMemo(() => {
    const pages = [
      PageNames.TEAM,
      PageNames.OPERATORS,
      PageNames.CLIENTS,
      PageNames.WAITER,
    ];

    return {
      index: pages.indexOf(selectedPage),
      length: pages.length,
    };
  }, [selectedPage]);

  const subPageSellIndicator = useMemo(() => {
    const pages = [PageNames.SELL, PageNames.FAST, PageNames.CASHIER];

    return {
      index: pages.indexOf(selectedPage),
      length: pages.length,
    };
  }, [selectedPage]);

  const subPagePreferencesIndicator = useMemo(() => {
    const pages = [
      PageNames.ADDRESSES,
      PageNames.SCHEDULED_PRODUCTS,
      PageNames.MESSAGES,

      PageNames.DEVICES,
      PageNames.SOCIAL,
      PageNames.HISTORY,
      PageNames.THEME,
      PageNames.SETTINGS,
    ];

    return {
      index: pages.indexOf(selectedPage),
      length: pages.length,
    };
  }, [selectedPage]);

  console.log('empresa', company?.isOpen, empriseActive);
  console.log('Whatsapp', company?.sendWhatsapp, wppActive);
  return (
    <>
      <Container visible={visible}>
        <Content className="content">
          <Header>
            <FiArrowLeft size={22} onClick={handleClose} />

            <ToggleContainer className="toggle">
              <Toggle
                icons={false}
                className="custom-toggle"
                onChange={handleToggleCompanyOpen}
                checked={empriseActive}
              />
              <span>Empresa</span>
            </ToggleContainer>
            <ToggleContainer className="toggle">
              <Toggle
                icons={false}
                className="custom-toggle"
                onChange={handleToggleSendWhatsapp}
                checked={wppActive}
              />
              <span>Whatsapp</span>
            </ToggleContainer>
          </Header>
          <Welcome className="welcome">
            <div>
              <Image>
                <img src={avatar} alt="" />
              </Image>
              <div>
                <span>seja bem-vindo,</span>
                <strong>{user?.name}</strong>
              </div>
            </div>
          </Welcome>

          <InnerContent className="has-custom-scroll-bar-2">
            <Pages>
              <SidebarTab
                {...PagesAccessProfiles.DASHBOARD}
                selected={selectedPage === PageNames.DASHBOARD}
                onClick={() => handleNavigateTo('/', PageNames.DASHBOARD)}
              >
                <div>
                  <img
                    src={choosePageIcon(PageNames.DASHBOARD)}
                    alt="Dashboard"
                  />
                  <span>Dashboard</span>
                </div>
              </SidebarTab>
              {company?.subscription?.plan?.order && (
                <>
                  <SidebarTab
                    {...PagesAccessProfiles.RECENT_ORDERS}
                    selected={selectedPage === PageNames.RECENT_ORDERS}
                    onClick={() =>
                      handleNavigateTo(
                        '/recent-orders',
                        PageNames.RECENT_ORDERS,
                      )
                    }
                  >
                    <div>
                      <img
                        src={choosePageIcon(PageNames.RECENT_ORDERS)}
                        alt="Pedidos novos"
                      />
                      <span>Pedidos novos</span>
                    </div>
                    {recentOrders.length > 0 && (
                      <Badge
                        selected={selectedPage === PageNames.RECENT_ORDERS}
                      >
                        {recentOrders.length}
                      </Badge>
                    )}
                  </SidebarTab>
                  <SidebarTab
                    {...PagesAccessProfiles.ORDERS}
                    selected={selectedPage === PageNames.ORDERS}
                    onClick={() =>
                      handleNavigateTo('/orders', PageNames.ORDERS)
                    }
                  >
                    <div>
                      <img
                        src={choosePageIcon(PageNames.ORDERS)}
                        alt="Pedidos"
                      />
                      <span>Pedidos</span>
                    </div>
                    {pendingOrders > 0 && (
                      <Badge selected={selectedPage === PageNames.ORDERS}>
                        {pendingOrders}
                      </Badge>
                    )}
                  </SidebarTab>
                </>
              )}

              {/* <SidebarTab
                {...PagesAccessProfiles.SELL}
                selected={
                  selectedPage === PageNames.SELL ||
                  selectedPage === PageNames.FAST ||
                  selectedPage === PageNames.CASHIER
                }
                onClick={() => setIsSellActive(prev => !prev)}
              >
                <div>
                  <img src={choosePageIcon(PageNames.SELL)} alt="Venda" />
                  <span>{chooseBsFoodOrVarejo('Venda', 'Venda')}</span>
                </div>
                <IconContainer selected={isSellActive}>
                  <FiChevronDown
                    size={24}
                    style={{
                      marginRight:
                        selectedPage === PageNames.SELL ||
                        selectedPage === PageNames.FAST ||
                        selectedPage === PageNames.CASHIER
                          ? 0
                          : -4,
                    }}
                  />
                </IconContainer>
              </SidebarTab> */}
              {/* <ProductsGroup visible={isSellActive} {...subPageSellIndicator}>
                <Indicator className="indicator" />
                <SidebarTab
                  isSubPageTab
                  onClick={() => handleNavigateTo('/sell', PageNames.SELL)}
                  {...PagesAccessProfiles.SELL}
                >
                  <span>Mesas/Comandas</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() => handleNavigateTo('/fast', PageNames.FAST)}
                  {...PagesAccessProfiles.FAST}
                >
                  <span>Lançamento Rápido</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/cashier', PageNames.CASHIER)
                  }
                  {...PagesAccessProfiles.CASHIER}
                >
                  <span>Caixa</span>
                </SidebarTab>
              </ProductsGroup> */}

              <SidebarTab
                {...PagesAccessProfiles.TABLES}
                selected={selectedPage === PageNames.TABLES}
                onClick={() => handleNavigateTo('/tables', PageNames.TABLES)}
              >
                <div>
                  <img src={choosePageIcon(PageNames.TABLES)} alt="Mesas" />
                  <span>Mesas</span>
                </div>
              </SidebarTab>
              <SidebarTab
                {...PagesAccessProfiles.CASHIER_REPORT}
                selected={selectedPage === PageNames.CASHIER_REPORT}
                onClick={() =>
                  handleNavigateTo('/cashier-report', PageNames.CASHIER_REPORT)
                }
              >
                <div>
                  <img
                    src={choosePageIcon(PageNames.CASHIER_REPORT)}
                    alt="Relatórios de caixa"
                  />
                  <span>Relatórios de caixa</span>
                </div>
              </SidebarTab>
              <SidebarTab
                {...PagesAccessProfiles.CATEGORIES}
                selected={selectedPage === PageNames.CATEGORIES}
                onClick={() =>
                  handleNavigateTo('/categories', PageNames.CATEGORIES)
                }
              >
                <div>
                  <img
                    src={choosePageIcon(PageNames.CATEGORIES)}
                    alt="Categorias"
                  />
                  <span>Categorias</span>
                </div>
              </SidebarTab>

              <SidebarTab
                {...PagesAccessProfiles.PRODUCTS}
                selected={
                  selectedPage === PageNames.PRODUCTS ||
                  selectedPage === PageNames.STOCK ||
                  selectedPage === PageNames.HIGHLIGHTS ||
                  selectedPage === PageNames.COMPLEMENTS ||
                  selectedPage === PageNames.UPDATE_SKU ||
                  selectedPage === PageNames.DISCOUNTS
                }
                onClick={() => handleOnGroupClicked(PageNames.PRODUCTS)}
              >
                <div>
                  <img
                    src={choosePageIcon(PageNames.PRODUCTS)}
                    alt="Products"
                  />
                  <span>{chooseBsFoodOrVarejo('Cardápio', 'Catálogo')}</span>
                </div>
                <IconContainer selected={isProductsActive}>
                  <FiChevronDown
                    size={24}
                    style={{
                      marginRight:
                        selectedPage === PageNames.PRODUCTS ||
                        selectedPage === PageNames.COMPLEMENTS
                          ? 0
                          : -4,
                    }}
                  />
                </IconContainer>
              </SidebarTab>
              <ProductsGroup
                visible={isProductsActive}
                {...subPageProductIndicator}
              >
                <Indicator className="indicator" />
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/products', PageNames.PRODUCTS)
                  }
                  {...PagesAccessProfiles.PRODUCTS}
                >
                  <span>Produtos</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() => handleNavigateTo('/stock', PageNames.STOCK)}
                  {...PagesAccessProfiles.STOCK}
                >
                  <span>Estoque</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/highlights', PageNames.HIGHLIGHTS)
                  }
                  {...PagesAccessProfiles.HIGHLIGHTS}
                >
                  <span>Destaques</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/complements', PageNames.COMPLEMENTS)
                  }
                  {...PagesAccessProfiles.COMPLEMENTS}
                >
                  <span>Complementos</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/discounts', PageNames.DISCOUNTS)
                  }
                  {...PagesAccessProfiles.DISCOUNTS}
                >
                  <span>Cupons de desconto</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/updatesku', PageNames.UPDATE_SKU)
                  }
                  {...PagesAccessProfiles.UPDATE_SKU}
                >
                  <span>Alterar código de integração</span>
                </SidebarTab>
              </ProductsGroup>

              <SidebarTab
                {...PagesAccessProfiles.REGISTER}
                selected={
                  selectedPage === PageNames.TEAM ||
                  selectedPage === PageNames.OPERATORS ||
                  selectedPage === PageNames.CLIENTS ||
                  selectedPage === PageNames.WAITER
                }
                onClick={() => setIsRegistersActive(prev => !prev)}
              >
                <div>
                  <img
                    src={choosePageIcon(PageNames.REGISTER)}
                    alt="Cadastros"
                  />
                  <span>{chooseBsFoodOrVarejo('Cadastros', 'Cadastros')}</span>
                </div>
                <IconContainer selected={isRegistersActive}>
                  <FiChevronDown
                    size={24}
                    style={{
                      marginRight:
                        selectedPage === PageNames.TEAM ||
                        selectedPage === PageNames.OPERATORS ||
                        selectedPage === PageNames.CLIENTS ||
                        selectedPage === PageNames.WAITER
                          ? 0
                          : -4,
                    }}
                  />
                </IconContainer>
              </SidebarTab>
              <ProductsGroup
                visible={isRegistersActive}
                {...subPageRegisterIndicator}
              >
                <Indicator className="indicator" />

                <SidebarTab
                  isSubPageTab
                  onClick={() => handleNavigateTo('/team', PageNames.TEAM)}
                  {...PagesAccessProfiles.TEAM}
                >
                  <span>Equipe</span>
                </SidebarTab>

                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/operators', PageNames.OPERATORS)
                  }
                  {...PagesAccessProfiles.OPERATORS}
                >
                  <span>Operadores</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/clients', PageNames.CLIENTS)
                  }
                  {...PagesAccessProfiles.CLIENTS}
                >
                  <span>Clientes</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() => handleNavigateTo('/waiters', PageNames.WAITER)}
                  {...PagesAccessProfiles.WAITER}
                >
                  <span>Garçons</span>
                </SidebarTab>
              </ProductsGroup>

              <SidebarTab
                {...PagesAccessProfiles.SETTINGS}
                selected={
                  selectedPage === PageNames.ADDRESSES ||
                  selectedPage === PageNames.SCHEDULED_PRODUCTS ||
                  selectedPage === PageNames.MESSAGES ||
                  selectedPage === PageNames.DEVICES ||
                  selectedPage === PageNames.SETTINGS ||
                  selectedPage === PageNames.SOCIAL ||
                  selectedPage === PageNames.HISTORY ||
                  selectedPage === PageNames.THEME
                }
                onClick={() => handleOnGroupClicked(PageNames.SETTINGS)}
              >
                <div>
                  <img
                    src={choosePageIcon(PageNames.SETTINGS)}
                    alt="Configurações"
                  />
                  <span>Configurações</span>
                </div>
                <IconContainer selected={isSettingsActive}>
                  <FiChevronDown
                    size={24}
                    style={{
                      marginRight:
                        selectedPage === PageNames.ADDRESSES ||
                        selectedPage === PageNames.SCHEDULED_PRODUCTS ||
                        selectedPage === PageNames.MESSAGES ||
                        selectedPage === PageNames.SETTINGS ||
                        selectedPage === PageNames.DEVICES
                          ? 0
                          : -4,
                    }}
                  />
                </IconContainer>
              </SidebarTab>
              <SettingsGroup
                visible={isSettingsActive}
                {...subPagePreferencesIndicator}
              >
                <Indicator className="indicator" />
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/addresses', PageNames.ADDRESSES)
                  }
                  {...PagesAccessProfiles.ADDRESSES}
                >
                  <span>Taxas de Entrega</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo(
                      '/scheduled-products',
                      PageNames.SCHEDULED_PRODUCTS,
                    )
                  }
                  {...PagesAccessProfiles.SCHEDULED_PRODUCTS}
                >
                  <span>Produtos agendados</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/messages', PageNames.MESSAGES)
                  }
                  {...PagesAccessProfiles.MESSAGES}
                >
                  <span>Mensagens</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/devices', PageNames.DEVICES)
                  }
                  {...PagesAccessProfiles.DEVICES}
                >
                  <span>Dispositivos</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() => handleNavigateTo('/social', PageNames.SOCIAL)}
                  {...PagesAccessProfiles.SOCIAL}
                >
                  <span>Links</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/history', PageNames.HISTORY)
                  }
                  {...PagesAccessProfiles.HISTORY}
                >
                  <span>História</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() => handleNavigateTo('/theme', PageNames.THEME)}
                  {...PagesAccessProfiles.THEME}
                >
                  <span>Tema</span>
                </SidebarTab>
                <SidebarTab
                  isSubPageTab
                  onClick={() =>
                    handleNavigateTo('/settings', PageNames.SETTINGS)
                  }
                  {...PagesAccessProfiles.SETTINGS}
                >
                  <span>Preferências</span>
                </SidebarTab>
              </SettingsGroup>
            </Pages>
          </InnerContent>
          <HeaderBottom id="share-icon">
            <FiShare2 size={22} onClick={handleShare} />
            <FiLogOut size={22} onClick={handleOnSignOutClick} />
            <div className="privacy-policy">
              <Link to="/privacy-policy">Política de Privacidade</Link>
            </div>
          </HeaderBottom>
        </Content>

        <ChildrenContainer className="children">
          <SidebarHeader>
            <FiMenu size={28} onClick={handleToggle} />
            <PageName>{selectedPage}</PageName>
            <AuthRole whiteList={['Manager']} />
            <div className="toggles">
              <ToggleContainer className="toggle">
                <Toggle
                  icons={false}
                  className="custom-toggle"
                  onChange={handleToggleCompanyOpen}
                  checked={empriseActive}
                />
                {company?.isOpen ? (
                  <span>Empresa aberta</span>
                ) : (
                  <span>Empresa fechada</span>
                )}
              </ToggleContainer>
              <ToggleContainer className="toggle">
                <Toggle
                  icons={false}
                  className="custom-toggle"
                  onChange={handleToggleSendWhatsapp}
                  checked={wppActive}
                />
                <span>Enviar Whatsapp </span>
              </ToggleContainer>
            </div>
            <AuthRole />
            <Logo />
          </SidebarHeader>
          <div className="children-content">{children}</div>
        </ChildrenContainer>
      </Container>
    </>
  );
};

export default Sidebar;
