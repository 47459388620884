import styled from 'styled-components';

interface IStyledButtonProps {
  bg?: string;
  color?: string;
  width?: string;
  height?: string;
  border?: boolean;
  side?: 'L' | 'R' | 'T' | 'B';
  outline?: boolean;
}
export const StyledButton = styled.div<IStyledButtonProps>`
  display: flex;
  text-align: center;
  justify-content: center;
  width: ${({ width }) => width || '150px'};
  height: ${({ height }) => height || '51px'};
  padding: 12px;
  align-items: center;
  gap: 15px;
  border-radius: 8px;
  background-color: ${({ theme, bg, outline }) =>
    outline ? 'transparent' : bg || theme.palette.primary};
  transition: transform 0.3s;
  color: ${({ outline, theme, color }) =>
    outline ? theme.palette.primary : color};
  border: 1px solid
    ${({ theme, outline, border, color }) =>
      outline ? theme.palette.primary : border ? color : null};
  border: ${({ border }) => !border && 'none'};
  cursor: pointer;
  &:hover {
    transform: ${({ side }) =>
      side === 'L'
        ? 'translateX(2%)'
        : side === 'R'
        ? 'translateX(-2%)'
        : side === 'T'
        ? 'translateY(-2%)'
        : 'translateY(2%)'};
  }
  @media (max-width: 600px) {
    padding: 6px;
    font-size: 14px;
  }
  p {
    font-size: 16px;
    display: flex;
    align-items: center;
  }
`;
