import React, { useMemo } from 'react';
import IProduct from '../../models/IProduct';
import {
  Infos,
  Image,
  Wrapper,
  CategoryBadge,
  SubcategoryBadge,
  TypesContainer,
} from './styles';
import { randomInt } from '../../utils/numbers';
import { ReactComponent as ProductPlaceholderSVG } from '../../assets/icons/product_placeholder.svg';

interface IProductCardSellProps extends React.HTMLAttributes<HTMLDivElement> {
  product: IProduct;
}
export const ProductCardSell: React.FC<IProductCardSellProps> = ({
  product,
  ...divProps
}) => {
  const productIcon = useMemo(() => {
    if (product.imageUrl) {
      return <img src={product.imageUrl} alt={product.title} />;
    }

    const classes = ['blue', 'red', 'orange', 'green', 'yellow'];

    const randomClass = classes[randomInt(0, classes.length - 1)];
    return <ProductPlaceholderSVG className={randomClass} />;
  }, [product]);

  return (
    <Wrapper {...divProps}>
      <Image>{productIcon}</Image>
      <Infos>
        <p className="name">{product.title}</p>
        <TypesContainer>
          {product?.categories?.map(categorie => (
            <CategoryBadge key={categorie?.id}>{categorie.name}</CategoryBadge>
          ))}

          {product?.subcategory?.name && (
            <SubcategoryBadge key={product?.id}>
              {product?.subcategory?.name}
            </SubcategoryBadge>
          )}
        </TypesContainer>

        <div className="price">
          {/* <p>
            Qtd:
            {` `}
            {product.weight}
          </p> */}
          <p>
            R$
            {product.unitPrice.toFixed(2).replace('.', ',')}
          </p>
        </div>
      </Infos>
    </Wrapper>
  );
};

export default ProductCardSell;
