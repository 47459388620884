import React from 'react';

import { GridProducts, ProductsSellWrapper } from './styles';

import ProductCardSell from '../ProductCardSell';

import { NotFound } from '../../pages/ClientPage/styles';
import { useCartProducts } from '../../hooks/products_sell';
import { IProductSell } from '../../models/IFastSell';

interface IListProductsSellProps {
  filteredProducts: IProductSell[];
}
const ListProductsSell: React.FC<IListProductsSellProps> = ({
  filteredProducts,
}) => {
  const { handleSelectProduct } = useCartProducts();

  return (
    <ProductsSellWrapper className="has-custom-scroll-bar-2">
      {filteredProducts?.length > 0 ? (
        <GridProducts>
          {filteredProducts?.map((item: any, index) => (
            <ProductCardSell
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              onClick={() => handleSelectProduct(item)}
              product={item}
            />
          ))}
        </GridProducts>
      ) : (
        <NotFound> Nenhum produto encontrado!</NotFound>
      )}
    </ProductsSellWrapper>
  );
};

export default ListProductsSell;
