import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { v4 as uuid } from 'uuid';
import VMasker from 'vanilla-masker';
import * as clipboard from 'clipboard-polyfill/text';
import { FiCopy, FiEye, FiEyeOff, FiPlus, FiX } from 'react-icons/fi';

import ILinkModel from '../../models/ILinkModel';

import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';

import Tour from '../../components/Tour';
import HelpButton from '../../components/HelpButton';
import LoadingAnimation from '../../components/LoadingAnimation';

import tourData from '../../tour/social';
import { unmaskPhoneNumber } from '../../utils/masks';
import { chooseBsFoodOrVarejo } from '../../utils/subdomain';

import world from '../../assets/icons/world.svg';
import facebook from '../../assets/icons/social/facebook.svg';
import whatsapp from '../../assets/icons/social/whatsapp.svg';
import instagram from '../../assets/icons/social/instagram.svg';

import {
  Top,
  Main,
  Error,
  Bottom,
  Header,
  Content,
  LinkName,
  PageInfo,
  PageName,
  Container,
  GroupIcon,
  CustomLink,
  GroupTitle,
  GroupHeader,
  Placeholder,
  BottomHeader,
  CustomGroups,
  LinkPageLink,
  GroupContent,
  InnerContent,
  RemoveButton,
  ToggleButton,
  NoCustomLinks,
  GroupContainer,
} from './styles';

import SaveButton from '../../components/SaveButton';
import { AuthRole } from '../../hooks/auth';
import { PageNames } from '../../enums/pages';

const SocialPage: React.FC = () => {
  const facebookRef = useRef<HTMLInputElement>(null);
  const whatsAppRef = useRef<HTMLInputElement>(null);
  const instagramRef = useRef<HTMLInputElement>(null);

  const { addToast } = useToast();
  const { company, saveCompany } = useCompany();

  const [isTourVisible, setIsTourVisible] = useState(false);

  const [showsFacebook, setShowsFacebook] = useState(true);
  const [companyFacebook, setCompanyFacebook] = useState('');
  const [companyFacebookError, setCompanyFacebookError] = useState('');

  const [showsInstagram, setShowsInstagram] = useState(true);
  const [companyInstagram, setCompanyInstagram] = useState('');
  const [companyInstagramError, setCompanyInstagramError] = useState('');

  const [showsWhatsApp, setShowsWhatsApp] = useState(true);
  const [companyWhatsApp, setCompanyWhatsApp] = useState('');
  const [companyWhatsAppError, setCompanyWhatsAppError] = useState('');

  const [companyLinks, setCompanyLinks] = useState<ILinkModel[]>([]);

  const [erroredIndex, setErroredIndex] = useState(-1);

  useEffect(() => {
    if (company) {
      setShowsFacebook(company.showsFacebook);
      setShowsWhatsApp(company.showsWhatsApp);
      setShowsInstagram(company.showsInstagram);

      setCompanyFacebook(company.facebook || 'https://www.facebook.com/');
      setCompanyInstagram(company.instagram || 'https://www.instagram.com/');

      const whatsappNumber = company.whatsApp || company.cellPhoneNumber || '';

      if (whatsappNumber.length <= 10) {
        setCompanyWhatsApp(VMasker.toPattern(whatsappNumber, '(99) 9999 9999'));
      } else {
        setCompanyWhatsApp(
          VMasker.toPattern(whatsappNumber, '(99) 9 9999 9999'),
        );
      }

      setCompanyLinks(company.companyLinks);
    }
  }, [company]);

  useEffect(() => {
    setCompanyLinks(prevState => {
      if (isTourVisible && prevState.length === 0) {
        return [
          {
            id: 0,
            companyId: 0,
            localId: 'tour',
            name: chooseBsFoodOrVarejo('BSFOOD', 'BSVAREJO'),
            value: `https://${chooseBsFoodOrVarejo(
              'bsfood',
              'bsvarejo',
            )}.com.br`,
            visible: true,
          },
        ];
      }
      return prevState.filter(l => l.localId !== 'tour');
    });
  }, [isTourVisible]);

  const handleHelpClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  const handleOnWhatsAppChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.length <= 14) {
        setCompanyWhatsApp(VMasker.toPattern(e.target.value, '(99) 9999 9999'));
      } else {
        setCompanyWhatsApp(
          VMasker.toPattern(e.target.value, '(99) 9 9999 9999'),
        );
      }
    },
    [],
  );

  const handleOnFacebookChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.includes('https://www.facebook.com/')) {
        setCompanyFacebook(e.target.value);
      }
    },
    [],
  );

  const handleOnInstagramChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value.includes('https://www.instagram.com/')) {
        setCompanyInstagram(e.target.value);
      }
    },
    [],
  );

  const handleOnShowsWhatsAppToggle = useCallback(() => {
    setShowsWhatsApp(prevState => {
      if (!prevState && whatsAppRef.current) {
        whatsAppRef.current.focus();
      }
      return !prevState;
    });
  }, []);

  const handleOnShowsFacebookToggle = useCallback(() => {
    setShowsFacebook(prevState => {
      if (!prevState && facebookRef.current) {
        facebookRef.current.focus();
      }

      return !prevState;
    });
  }, []);

  const handleOnShowsInstagramToggle = useCallback(() => {
    setShowsInstagram(prevState => {
      if (!prevState && instagramRef.current) {
        instagramRef.current.focus();
      }

      return !prevState;
    });
  }, []);

  const handleNewCustomLinkClicked = useCallback(() => {
    setCompanyLinks(prevState => [
      {
        id: 0,
        name: '',
        value: '',
        visible: true,
        localId: uuid(),
        companyId: company?.id || 0,
      },
      ...prevState,
    ]);
  }, [company]);

  const handleOnCustomLinkChanged = useCallback(
    (text: string, field: 'name' | 'value', id: string | number) => {
      setCompanyLinks(prevState => {
        const newState = [...prevState];
        const index = prevState.findIndex(l =>
          typeof id === 'number' ? l.id === id : l.localId === id,
        );

        if (field === 'name') {
          newState[index].name = text;
          return newState;
        }

        newState[index].value = text;
        return newState;
      });
    },
    [],
  );

  const handleOnLinkRemoved = useCallback((id: number | string) => {
    setCompanyLinks(prevState => {
      return prevState.filter(l =>
        typeof id === 'number' ? l.id !== id : l.localId !== id,
      );
    });
  }, []);

  const handleOnCustomLinkVisibilityChanged = useCallback(
    (id: number | string) => {
      setCompanyLinks(prevState => {
        const newState = [...prevState];
        const index = prevState.findIndex(l =>
          typeof id === 'number' ? l.id === id : l.localId === id,
        );

        newState[index].visible = !newState[index].visible;
        return newState;
      });
    },
    [],
  );

  const handleOnCompanySaved = useCallback(async () => {
    const unsmaskedPhone = unmaskPhoneNumber(companyWhatsApp);

    if (showsWhatsApp && unsmaskedPhone.length < 10) {
      setCompanyWhatsAppError('Insira um telefone válido.');
      return;
    }

    if (showsFacebook && companyFacebook.length <= 25) {
      setCompanyFacebookError('Insira um endereço válido.');
      return;
    }

    if (showsInstagram && companyInstagram.length <= 26) {
      setCompanyInstagramError('Insira um endereço válido.');
      return;
    }

    const incorrectLinks = companyLinks.filter(l => !l.name || !l.value);

    if (incorrectLinks.length > 0) {
      setErroredIndex(companyLinks.indexOf(incorrectLinks[0]));
      return;
    }

    try {
      if (company) {
        await saveCompany({
          ...company,
          city: company.city.name,
          state: company.city.state,
          streetName: company.streetName,
          streetNumber: company.streetNumber,
          address2: company.address2,
          reference: company.reference,
          cellPhoneNumber: company.cellPhoneNumber,
          tableAmount: company.tableAmount,
          welcomeMessage: company.welcomeMessage,
          deliveryRanges: company.deliveryRanges,
          showAdditionalsInPrint: company.showAdditionalsInPrint,
          enablesSubcategories: company.enablesSubcategories,
          enablesInternalCode: company.enablesInternalCode,
          ordersAnotherCity: company.ordersAnotherCity,
          showsWhatsApp,
          whatsApp: unmaskPhoneNumber(companyWhatsApp),
          showsFacebook,
          facebook: companyFacebook,
          showsInstagram,
          instagram: companyInstagram,
          companyLinks,
        });

        addToast({
          type: 'success',
          description: 'Informações salvas com sucesso.',
        });
      }
    } catch {
      addToast({
        type: 'error',
        description: 'Erro ao salvar informações.',
      });
    }
  }, [
    company,
    companyLinks,
    showsFacebook,
    showsWhatsApp,
    showsInstagram,
    companyWhatsApp,
    companyFacebook,
    companyInstagram,
    addToast,
    saveCompany,
  ]);

  const linkMenuLink = useMemo(() => {
    return `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
      'bsfood',
      'bsvarejo',
    )}.com.br`;
  }, [company]);

  const facebookIntegrationLink = useMemo(() => {
    return `${process.env.REACT_APP_API_URL}products/${company?.hash}/merchants`;
  }, [company]);

  const handleOnCopyMenuLink = useCallback(async () => {
    await clipboard.writeText(linkMenuLink);

    addToast({
      type: 'info',
      description: 'Copiado para àrea de transferência.',
    });
  }, [linkMenuLink, addToast]);

  const handleOnCopyLink = useCallback(async () => {
    await clipboard.writeText(`${linkMenuLink}/links`);

    addToast({
      type: 'info',
      description: 'Copiado para àrea de transferência.',
    });
  }, [linkMenuLink, addToast]);

  const handleOnCopyFacebookLink = useCallback(async () => {
    await clipboard.writeText(facebookIntegrationLink);

    addToast({
      type: 'info',
      description: 'Copiado para àrea de transferência.',
    });
  }, [facebookIntegrationLink, addToast]);

  return (
    <Container>
      <Content>
        <InnerContent>
          <Header>
            <PageInfo>
              <PageName>
                {PageNames.SOCIAL}
                <HelpButton onClick={handleHelpClick} />
              </PageName>
            </PageInfo>
          </Header>
          {company?.id ? (
            <Main className="has-custom-scroll-bar-2">
              <LinkPageLink>
                <span>
                  {`Este é o endereço para seu ${chooseBsFoodOrVarejo(
                    'cardápio',
                    'catálogo',
                  )}:`}
                </span>
                <div>
                  <a href={linkMenuLink}>
                    <span>{linkMenuLink}</span>
                  </a>
                  <button type="button" onClick={handleOnCopyMenuLink}>
                    <FiCopy size={22} />
                  </button>
                </div>
              </LinkPageLink>
              <LinkPageLink>
                <span>Este é o endereço para sua página de links:</span>
                <div>
                  <a href={`${linkMenuLink}/links`}>
                    <span>{`${linkMenuLink}/links`}</span>
                  </a>
                  <button type="button" onClick={handleOnCopyLink}>
                    <FiCopy size={22} />
                  </button>
                </div>
              </LinkPageLink>
              <LinkPageLink>
                <span>
                  {`Este é o endereço para sua integração com o ${chooseBsFoodOrVarejo(
                    'cardápio',
                    'catálogo',
                  )} do
                  Facebook:`}
                </span>
                <div>
                  <a href={facebookIntegrationLink}>
                    <span>{facebookIntegrationLink}</span>
                  </a>
                  <button type="button" onClick={handleOnCopyFacebookLink}>
                    <FiCopy size={22} />
                  </button>
                </div>
              </LinkPageLink>
              <Top id="main-groups-container">
                <GroupContainer id="groups-whatsapp">
                  <GroupHeader>
                    <GroupIcon src={whatsapp} />
                    <GroupTitle>WhatsApp</GroupTitle>
                  </GroupHeader>
                  <GroupContent>
                    <span>telefone:</span>
                    <AuthRole disableOnly>
                      <input
                        type="text"
                        name="whatsapp"
                        ref={whatsAppRef}
                        value={companyWhatsApp}
                        onChange={handleOnWhatsAppChanged}
                      />
                    </AuthRole>
                    {companyWhatsAppError && (
                      <Error>{companyWhatsAppError}</Error>
                    )}
                  </GroupContent>
                  <AuthRole disableOnly>
                    <ToggleButton
                      selected={showsWhatsApp}
                      onClick={handleOnShowsWhatsAppToggle}
                    >
                      {showsWhatsApp ? (
                        <FiEye size={22} />
                      ) : (
                        <FiEyeOff size={22} />
                      )}
                    </ToggleButton>
                  </AuthRole>
                </GroupContainer>
                <GroupContainer>
                  <GroupHeader>
                    <GroupIcon src={facebook} />
                    <GroupTitle>Facebook</GroupTitle>
                  </GroupHeader>
                  <GroupContent>
                    <span>link do facebook: </span>
                    <AuthRole disableOnly>
                      <input
                        type="text"
                        id="groups-value"
                        ref={facebookRef}
                        value={companyFacebook}
                        onChange={handleOnFacebookChanged}
                      />
                    </AuthRole>
                    {companyFacebookError && (
                      <Error>{companyFacebookError}</Error>
                    )}
                  </GroupContent>
                  <AuthRole disableOnly>
                    <ToggleButton
                      id="groups-visiblity"
                      selected={showsFacebook}
                      onClick={handleOnShowsFacebookToggle}
                    >
                      {showsFacebook ? (
                        <FiEye size={22} />
                      ) : (
                        <FiEyeOff size={22} />
                      )}
                    </ToggleButton>
                  </AuthRole>
                </GroupContainer>
                <GroupContainer>
                  <GroupHeader>
                    <GroupIcon src={instagram} />
                    <GroupTitle>Instagram</GroupTitle>
                  </GroupHeader>
                  <GroupContent>
                    <span>link do instagram:</span>
                    <AuthRole disableOnly>
                      <input
                        type="text"
                        ref={instagramRef}
                        value={companyInstagram}
                        onChange={handleOnInstagramChanged}
                      />
                    </AuthRole>
                    {companyInstagramError && (
                      <Error>{companyInstagramError}</Error>
                    )}
                  </GroupContent>
                  <AuthRole blackList={['Employee']} disableOnly>
                    <ToggleButton
                      selected={showsInstagram}
                      onClick={handleOnShowsInstagramToggle}
                    >
                      {showsInstagram ? (
                        <FiEye size={22} />
                      ) : (
                        <FiEyeOff size={22} />
                      )}
                    </ToggleButton>
                  </AuthRole>
                </GroupContainer>
              </Top>
              <Bottom id="custom-groups-container">
                <AuthRole>
                  <BottomHeader>
                    <span>Adicione outros links para suas redes</span>
                    <button
                      type="button"
                      id="custom-groups-container-add"
                      onClick={handleNewCustomLinkClicked}
                    >
                      <FiPlus size={22} />
                      <span />
                    </button>
                  </BottomHeader>
                </AuthRole>
                {companyLinks.length > 0 ? (
                  <CustomGroups>
                    {companyLinks.map((link, index) => (
                      <CustomLink
                        key={link.id || link.localId}
                        error={index === erroredIndex}
                      >
                        <GroupHeader>
                          <GroupIcon src={world} />
                          <AuthRole disableOnly>
                            <LinkName
                              id="custom-groups-container-name"
                              placeholder="Nome"
                              value={link.name}
                              onChange={e =>
                                handleOnCustomLinkChanged(
                                  e.target.value,
                                  'name',
                                  link.id || link.localId,
                                )
                              }
                            />
                          </AuthRole>
                        </GroupHeader>
                        <GroupContent id="custom-groups-container-value">
                          <span>{`link: `}</span>
                          <AuthRole disableOnly>
                            <input
                              type="text"
                              placeholder="Link"
                              value={link.value}
                              onChange={e =>
                                handleOnCustomLinkChanged(
                                  e.target.value,
                                  'value',
                                  link.id || link.localId,
                                )
                              }
                            />
                          </AuthRole>
                          {index === erroredIndex && (
                            <Error>
                              Preencha todos os campos antes de continuar.
                            </Error>
                          )}
                          <AuthRole disableOnly>
                            <ToggleButton
                              selected={link.visible}
                              onClick={() => {
                                handleOnCustomLinkVisibilityChanged(
                                  link.id || link.localId,
                                );
                              }}
                            >
                              {link.visible ? (
                                <FiEye size={22} />
                              ) : (
                                <FiEyeOff size={22} />
                              )}
                            </ToggleButton>
                          </AuthRole>
                          <AuthRole>
                            <RemoveButton
                              id="custom-groups-container-delete"
                              onClick={() =>
                                handleOnLinkRemoved(link.id || link.localId)
                              }
                            >
                              <FiX size={22} />
                            </RemoveButton>
                          </AuthRole>
                        </GroupContent>
                      </CustomLink>
                    ))}
                    <Placeholder />
                    <Placeholder />
                    <Placeholder />
                  </CustomGroups>
                ) : (
                  <NoCustomLinks>
                    Nenhum link personalizado cadastrado.
                  </NoCustomLinks>
                )}
                <AuthRole>
                  <SaveButton
                    id="social-page-save"
                    onClick={handleOnCompanySaved}
                  />
                </AuthRole>
              </Bottom>
            </Main>
          ) : (
            <LoadingAnimation />
          )}
        </InnerContent>
      </Content>
      {isTourVisible && <Tour steps={tourData} onFinish={handleTourFinish} />}
    </Container>
  );
};

export default SocialPage;
