import { v4 as uuid } from 'uuid';
import { IMaskedCurrencyRef, MaskedCurrency } from 'react-easy-mask';
import React, {
  SetStateAction,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { ModalWrapper, Wrapper } from './styles';

import { choosePaymentTypeIcon } from '../../utils/icons';
import { convertPaymentName } from '../../utils/paymentName';

import Modal from '../Modal';
import { ITransaction } from '../../models/IPayment';

interface IPaymentCardProps {
  hasProducts: boolean;
  payment: string;
  needToPay: number;
  setTransactions: React.Dispatch<SetStateAction<ITransaction[]>>;
}
const PaymentCard: React.FC<IPaymentCardProps> = ({
  payment,
  needToPay,
  hasProducts,
  setTransactions,
}) => {
  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const inputRef = useRef<IMaskedCurrencyRef>(null);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
      setError(false);
    }
  }, [open, value]);

  useEffect(() => {
    setValue(Number(needToPay.toFixed(2)));
  }, [needToPay]);

  const handleConfirm = useCallback(() => {
    if (!hasProducts) {
      setError(true);
      return;
    }

    if (value <= 0) {
      setError(true);
      return;
    }

    const body: ITransaction = {
      id: uuid(),
      paymentType: payment,
      amount: value,
    };

    setTransactions(prev => (prev ? [...prev, body] : [body]));
    setOpen(false);
    setValue(0);
  }, [hasProducts, payment, setTransactions, value]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleConfirm();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleConfirm, open, value]);

  return (
    <>
      <Wrapper onClick={() => setOpen(true)}>
        <p>{convertPaymentName(payment)}</p>
        <img src={choosePaymentTypeIcon(`${payment}`, true)} alt={payment} />
      </Wrapper>
      {open && (
        <Modal open={open} setOpen={setOpen} onConfirm={handleConfirm}>
          <ModalWrapper error={error}>
            <p>Valor</p>
            <MaskedCurrency
              ref={inputRef}
              locale="pt-BR"
              currency="BRL"
              defaultValue={value}
              onChangeValue={value => setValue(value)}
            />
            {error && (
              <p style={{ color: 'red' }}>
                {hasProducts
                  ? 'O Campo deve ser maior que 0'
                  : 'Não é permitido lançar transações sem nenhuma venda'}
              </p>
            )}
          </ModalWrapper>
        </Modal>
      )}
    </>
  );
};

export default PaymentCard;
