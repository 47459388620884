import React, { useCallback, useEffect, useState } from 'react';
import { useToast } from '../../hooks/toast';
import { IWaiter } from '../../models/IWaiter';
import { useWaiter } from '../../hooks/waiter';
import ContainerForm from '../ContainerForm';
import { Main } from './styles';
import FormlessInput from '../FormlessInput';

interface IModalEditWaiterProps {
  onClose: () => void;
  waiter: IWaiter;
  setWaiters: React.Dispatch<React.SetStateAction<IWaiter[]>>;
}

const ModalEditWaiter: React.FC<IModalEditWaiterProps> = ({
  onClose,
  setWaiters,
  waiter,
}) => {
  const [waiterNameError, setWaiterNameError] = useState('');
  const [waiterName, setwaiterName] = useState('');
  const { addToast } = useToast();
  const { saveWaiter, getWaiters } = useWaiter();

  useEffect(() => {
    setwaiterName(waiter?.name || '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [waiter || '']);

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  const handleOnSave = async () => {
    if (!waiterName) {
      addToast({
        type: 'error',
        description: 'Nome do atendente obrigatório!',
      });
      setWaiterNameError('Nome do atendente é obrigatório!');
      return;
    }

    const post: IWaiter = {
      name: waiterName,
      active: true,
    };
    const put: IWaiter = {
      ...waiter,
      id: waiter?.id,
      name: waiterName,
    };

    waiter?.id ? await saveWaiter(put) : await saveWaiter(post);

    const fetchWaiters = async () => {
      const waiterData = await getWaiters();
      if (waiterData.length > 0) {
        setWaiters(waiterData);
      }
    };

    fetchWaiters();
    onClose();
  };

  return (
    <ContainerForm
      onSave={handleOnSave}
      onClose={onClose}
      type={waiter?.id ? 'Alteração de Atendente' : 'Cadastro de Atendente'}
    >
      <Main>
        <FormlessInput
          name="Name"
          title="Nome"
          value={waiterName}
          error={waiterNameError}
          onChange={e => setwaiterName(e.target.value)}
        />
      </Main>
    </ContainerForm>
  );
};

export default ModalEditWaiter;
