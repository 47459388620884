import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  gap: 0.5rem;
  place-content: end;
  @media (max-width: 1350px) {
    gap: 0.25rem;
  }
`;

export const Totals = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  @media (max-width: 1350px) {
    gap: 0.25rem;
  }
`;

export const CardValue = styled.div<{
  totalPay?: boolean;
  needToPay?: boolean;
}>`
  display: flex;
  border-radius: 8px;
  position: relative;
  gap: 5px;
  padding: 5px;
  color: ${({ theme, totalPay, needToPay }) =>
    !totalPay && !needToPay
      ? theme.palette.text_light
      : theme.palette.text_white};
  background-color: ${({ theme, totalPay, needToPay }) =>
    totalPay
      ? theme.palette.success_light
      : needToPay
      ? theme.palette.primary_light
      : theme.palette.background};
  justify-content: space-between;
  height: 100%;
  border: 2px solid
    ${({ theme, totalPay, needToPay }) =>
      !totalPay && !needToPay && theme.palette.text_light};

  :hover {
    box-shadow: ${({ needToPay, totalPay }) =>
      !needToPay && !totalPay && 'rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px'};
  }

  .name {
    font-size: 20px;
    font-weight: 500;
    white-space: nowrap;
  }

  .value {
    display: flex;
    align-items: end;
    font-size: 24px;
    font-weight: 700;
  }

  cursor: ${({ totalPay, needToPay }) => !totalPay && !needToPay && 'pointer'};
  @media (max-width: 1350px) {
    .value {
      font-size: 22px;
    }
  }

  @media (max-width: 1250px) {
    .name {
      font-size: 16px;
    }
    .value {
      font-size: 20px;
      font-weight: 600;
    }
  }
  @media (max-width: 1024px) {
    flex-direction: ${({ totalPay, needToPay }) =>
      (totalPay || needToPay) && 'column'};
    .name {
      font-size: 14px;
    }

    .value {
      font-size: 18px;
      font-weight: 600;
    }
  }
  @media (max-width: 800px) {
    .name {
      font-size: 14px;
    }

    .value {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .tooltip {
    visibility: hidden;
    width: 150px;
    background-color: ${({ theme }) => theme.palette.text_light};
    color: ${({ theme }) => theme.palette.text_white};
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    position: absolute;
    bottom: 125%; /* Exibe acima do CardValue */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-size: 12px;

    &::after {
      content: '';
      position: absolute;
      top: 100%; /* Triângulo apontando para o CardValue */
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.text_light} transparent
        transparent transparent;
    }
  }
  :hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

export const DiscountValues = styled.div`
  display: flex;
  border-radius: 8px;
  position: relative;
  gap: 5px;
  padding: 5px;
  color: ${({ theme }) => theme.palette.text_light};
  background-color: ${({ theme }) => theme.palette.background};
  justify-content: space-between;
  height: 100%;
  border: 2px solid ${({ theme }) => theme.palette.text_light};

  :hover {
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  }

  .name {
    font-size: 16px;
    font-weight: 300;
    white-space: nowrap;
  }

  .value {
    display: flex;
    align-items: end;
    font-size: 18px;
    font-weight: 500;
  }

  cursor: pointer;
  @media (max-height: 800px) {
    .value {
      font-size: 12px;
    }
    .value {
      font-size: 14px;
    }
  }
  @media (max-width: 1350px) {
    .value {
      font-size: 16px;
    }
  }

  @media (max-width: 1250px) {
    .name {
      font-size: 14px;
    }
    .value {
      font-weight: 400;
    }
  }
  @media (max-width: 1024px) {
    .name {
      font-size: 14px;
    }

    .value {
      font-size: 18px;
      font-weight: 400;
    }
  }
  @media (max-width: 800px) {
    .name {
      font-size: 14px;
    }

    .value {
      font-size: 16px;
      font-weight: 500;
    }
  }
  .tooltip {
    visibility: hidden;
    width: 150px;
    background-color: ${({ theme }) => theme.palette.text_light};
    color: ${({ theme }) => theme.palette.text_white};
    text-align: center;
    padding: 5px;
    border-radius: 4px;
    position: absolute;
    bottom: 125%; /* Exibe acima do CardValue */
    left: 50%;
    transform: translateX(-50%);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    font-size: 12px;

    &::after {
      content: '';
      position: absolute;
      top: 100%; /* Triângulo apontando para o CardValue */
      left: 50%;
      transform: translateX(-50%);
      border-width: 5px;
      border-style: solid;
      border-color: ${({ theme }) => theme.palette.text_light} transparent
        transparent transparent;
    }
  }
  :hover .tooltip {
    visibility: visible;
    opacity: 1;
  }
`;

export const TotalCard = styled.div<{
  totalPay?: boolean;
}>`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  gap: 5px;
  padding: 15px;
  color: ${({ theme }) => theme.palette.text_white};
  background-color: ${({ theme, totalPay }) =>
    totalPay ? theme.palette.success_light : theme.palette.control_light};
  /* border: 2px solid
    ${({ theme, totalPay }) => !totalPay && theme.palette.text_light}; */
  height: 100%;

  .total {
    font-size: 28px;
    font-weight: 600;
  }

  .valueTotal {
    height: 100%;
    display: flex;
    justify-content: end;
    align-items: end;
    font-size: 28px;
    font-weight: 700;
  }

  @media (max-width: 1350px) {
    .valueTotal {
      font-size: 26px;
    }
    .total {
      font-size: 26px;
    }
    .value {
      font-size: 22px;
    }
  }

  @media (max-width: 1250px) {
    .valueTotal {
      font-size: 24px;
    }
    .total {
      font-size: 24px;
    }
  }
  @media (max-width: 1024px) {
    .valueTotal {
      font-size: 20px;
    }
    .total {
      font-size: 20px;
    }

    padding: 10px;
  }
  @media (max-width: 800px) {
    .valueTotal {
      font-size: 20px;
    }
    .total {
      font-size: 18px;
    }

    padding: 5px;
  }
`;

export const Tooltip = styled.div``;
