import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { IProductCart } from '../models/IProduct';
import { defaultCart, ICart } from '../models/ICart';
import IComplement from '../models/IComplement';
import IComplementsGroup from '../models/IComplementsGroup';
import { useConfirmDialog } from './confim_dialog';

export type IInputType = 'checkbox' | 'radio' | 'increase' | 'decrease';
interface ProductsCartContextData {
  cart: ICart;
  products: IProductCart[];
  selectedProduct: IProductCart | null;
  openCart: boolean;
  setOpenCart: React.Dispatch<React.SetStateAction<boolean>>;
  setCart: React.Dispatch<React.SetStateAction<ICart>>;
  setSelectedProduct: React.Dispatch<React.SetStateAction<IProductCart | null>>;
  setProducts: React.Dispatch<React.SetStateAction<IProductCart[]>>;
  handleSetNewProduct: (product: IProductCart | null) => void;
  handleSelectComplement: (
    complementGroup: IComplementsGroup,
    complement: IComplement,
    type: IInputType,
  ) => void;
  incrementProduct: (product: IProductCart | null) => void;
  decrementProduct: (product: IProductCart | null) => void;
  toggleHalfPrice: (value: boolean) => void;
  handleComment: (value: string) => void;
  handleSelectProduct: (product: IProductCart) => void;
  deleteProductFromCart: (product: IProductCart) => void;
  editProduct: (product: IProductCart) => void;
  incrementProductOnCart: (product: IProductCart) => void;
  decrementProductOnCart: (product: IProductCart) => void;
  handleClearCart: () => void;
  calcCart: (productsArray: IProductCart[]) => void;
}

const ProductsCartContext = createContext<ProductsCartContextData>(
  {} as ProductsCartContextData,
);

export const ProductsCartProvider: React.FC = ({ children }) => {
  const { showConfirmDialog } = useConfirmDialog();
  const [openCart, setOpenCart] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<IProductCart | null>(
    null,
  );
  const [products, setProducts] = useState<IProductCart[]>([]);
  const [cart, setCart] = useState<ICart>(defaultCart);

  useEffect(() => {
    if (cart.products.length === 0) {
      setOpenCart(false);
    }
  }, [cart.products.length]);

  const calcCart = useCallback((productsArray: IProductCart[]) => {
    const totalPriceCart = productsArray?.reduce(
      (total, product) => total + (product?.total ?? 0),
      0,
    );

    setCart(prev => ({ ...prev, totalPriceCart }));
  }, []);
  const handleSelectProduct = useCallback((product: IProductCart) => {
    setSelectedProduct({
      ...product,
      id: uuid(),
      productId: Number(product.id),
      total: product.unitPrice,
      qty: 1,
    });
    setOpenCart(true);
  }, []);

  const deleteProductFromCart = useCallback(
    (product: IProductCart) => {
      const updatedProductsCart = cart?.products.filter(
        prod => prod.id !== product.id,
      );
      setCart(prevCart => ({
        ...prevCart,
        products: updatedProductsCart,
      }));
      calcCart(updatedProductsCart);
    },
    [calcCart, cart],
  );

  const editProduct = useCallback(
    (product: IProductCart) => {
      const editProduct = cart?.products.filter(
        prodCart => prodCart.id === product.id,
      );
      setSelectedProduct(editProduct[0]);
    },
    [cart],
  );

  const calcProductPrice = useCallback((selectedProduct: IProductCart) => {
    if (!selectedProduct) return 0;

    // Calcula o preço inicial do produto
    const initialPrice = selectedProduct.isHalfPrice
      ? (selectedProduct.halfPrice ?? 0) * selectedProduct.qty
      : selectedProduct.unitPrice * selectedProduct.qty;

    // Calcula o total dos complementos
    const totalComplementPrice = selectedProduct.complementsGroups
      .filter(group => group.checked) // Filtra os grupos com 'checked' como true
      .reduce((totalGroupPrice, group) => {
        // Calcula o total dos complementos no grupo
        const groupTotal = group.complements
          .filter(complement => complement.checked) // Filtra os complementos com 'checked' como true
          .reduce((totalComplementPrice, complement) => {
            return (
              totalComplementPrice +
              complement.unitPrice * (complement.amount || 1)
            );
          }, 0);
        // Acumula o total dos grupos
        return totalGroupPrice + groupTotal;
      }, 0);

    // Multiplica o total dos complementos pela quantidade do produto
    const finalComplementPrice = totalComplementPrice * selectedProduct.qty;

    // Retorna o preço total somando o preço inicial e o total dos complementos ajustado
    return initialPrice + finalComplementPrice;
  }, []);

  const handleSelectComplement = useCallback(
    (
      complementGroup: IComplementsGroup,
      complement: IComplement,
      type: IInputType,
    ) => {
      setSelectedProduct(prevProduct => {
        if (!prevProduct) return null;

        const updatedProduct: IProductCart = {
          ...prevProduct,
          complementsGroups: prevProduct.complementsGroups.map(group => {
            if (group.id === complementGroup.id) {
              const updatedComplements = group.complements.map(item => {
                switch (type) {
                  case 'checkbox':
                    return item.id === complement.id
                      ? {
                          ...item,
                          checked: !item.checked,
                          amount: item?.amount === 1 ? 0 : 1,
                        }
                      : item;
                  case 'increase':
                    return item.id === complement.id
                      ? {
                          ...item,
                          amount: (item.amount ?? 0) + 1,
                          checked: true,
                        }
                      : item;
                  case 'decrease':
                    return item.id === complement.id
                      ? {
                          ...item,
                          amount: (item.amount ?? 0) - 1,
                          checked: item.amount !== 1,
                        }
                      : item;
                  default:
                    // radio
                    return item.id === complement.id
                      ? { ...item, checked: true, amount: 1 }
                      : { ...item, checked: false, amount: 0 };
                }
              });
              const isGroupChecked = updatedComplements.some(
                comp => comp.checked,
              );

              return {
                ...group,
                complements: updatedComplements,
                checked: isGroupChecked, // Atualiza o checked do grupo
              };
            }
            return group;
          }),
        };

        // Calcula o novo preço do produto
        const newTotalPrice = calcProductPrice(updatedProduct);

        // Atualiza o selectedProduct com o novo preço
        return {
          ...updatedProduct,
          total: newTotalPrice, // Supondo que 'price' seja a propriedade que guarda o preço total do produto
        };
      });
    },
    [calcProductPrice],
  );

  const incrementProduct = useCallback((product: IProductCart | null) => {
    const unitTotalPrice = (product?.total ?? 0) / (product?.qty ?? 0);

    setSelectedProduct(prev =>
      prev
        ? {
            ...prev,
            qty: prev.qty + 1,
            total: (prev.total ?? 0) + unitTotalPrice,
          }
        : null,
    );
  }, []);

  const decrementProduct = useCallback((product: IProductCart | null) => {
    const unitTotalPrice = (product?.total ?? 0) / (product?.qty ?? 0);

    setSelectedProduct(prev =>
      prev
        ? {
            ...prev,
            qty: prev.qty - 1,
            total: (prev.total ?? 0) - unitTotalPrice,
          }
        : null,
    );
  }, []);

  const incrementProductOnCart = useCallback(
    (product: IProductCart) => {
      // Definindo o preço total por produto, pois o totalPrice é o valor total * qty de produtos
      const unitTotalPrice = (product?.total ?? 0) / (product?.qty ?? 0);
      const productIncremented = {
        ...product,
        qty: product.qty + 1,
        total: (product.total ?? 0) + unitTotalPrice,
      };

      // removendo o antigo produto ou seja, o mesmo produto com a qty + 1 e o novo valor calculado
      const updatedProductsCart = cart?.products.filter(
        prod => prod.id !== product.id,
      );
      setCart(prevCart => ({
        ...prevCart,
        products: [...updatedProductsCart, productIncremented],
      }));
      const arrayWithNewProduct = [
        ...cart?.products,
        { ...product, total: unitTotalPrice },
      ];

      calcCart(arrayWithNewProduct);
    },
    [calcCart, cart],
  );

  const decrementProductOnCart = useCallback(
    (product: IProductCart) => {
      // Verifica se a quantidade vai ser mudada para 0 e então deleta o produto
      if (product.qty === 1) {
        deleteProductFromCart(product);
        return;
      }
      // Definindo o preço total por produto, pois o totalPrice é o valor total * qty de produtos
      const unitTotalPrice = (product?.total ?? 0) / (product?.qty ?? 0);
      const productIncremented = {
        ...product,
        qty: product.qty - 1,
        total: (product.total ?? 0) - unitTotalPrice,
      };

      // removendo o antigo produto ou seja, o mesmo produto com a qty + 1 e o novo valor calculado
      const updatedProductsCart = cart?.products.filter(
        prod => prod.id !== product.id,
      );
      setCart(prevCart => ({
        ...prevCart,
        products: [...updatedProductsCart, productIncremented],
      }));
      const arrayWithNewProduct = [
        ...cart?.products,
        { ...product, total: -unitTotalPrice },
      ];
      calcCart(arrayWithNewProduct);
    },
    [calcCart, cart, deleteProductFromCart],
  );

  const toggleHalfPrice = useCallback(
    (value: boolean) => {
      if (selectedProduct) {
        setSelectedProduct(prevProduct => {
          if (!prevProduct) return null;

          // Alternar o valor de halfPrice
          const updatedProduct: IProductCart = {
            ...prevProduct,
            isHalfPrice: value,
          };

          // Recalcular o preço após a atualização
          const newTotalPrice = calcProductPrice(updatedProduct);

          return {
            ...updatedProduct,
            total: newTotalPrice,
          };
        });
      }
    },
    [calcProductPrice, selectedProduct],
  );

  const handleComment = useCallback((value: string) => {
    setSelectedProduct(prev => (prev ? { ...prev, comments: value } : null));
  }, []);

  const handleSetNewProduct = useCallback(
    (product: IProductCart | null) => {
      const oldProducts = cart.products;

      // Filtra os grupos de complementos obrigatórios
      const mandatoryComplements = product?.complementsGroups.filter(
        complGroup => complGroup.minAmount >= 1,
      );
      // Verifica se todos os complementos obrigatórios têm o número mínimo de itens marcados
      const verify = mandatoryComplements?.map(complGroup => {
        // Soma o amount de todos os complementos marcados (checked: true)
        const checkedAmountSum = complGroup.complements
          .filter(compl => compl.checked)
          .reduce((total, compl) => total + (compl?.amount ?? 1), 0);
        // Verifica se a soma do amount é pelo menos o valor de minAmount
        return checkedAmountSum >= complGroup.minAmount;
      });

      // Verifica se algum grupo não satisfaz a condição (ou seja, se tem algum false)
      const hasFalse = verify?.some(cond => cond === false);

      if (hasFalse) {
        showConfirmDialog({
          title: 'Há complementos obrigatórios',
          message: `Preencha os para continuar!`,
          onConfirm: () => null,
        });
        // Se algum grupo não tem o mínimo de complementos obrigatórios marcados, interrompe o processo
        return;
      }

      // Variavel criada para verificar se o produto está sendo adicionado da lista ou se está sendo editado
      const editedProduct = oldProducts.filter(
        oldProd => oldProd?.id === product?.id,
      );
      // Verifica se o produto vem da edição ou é um novo produto
      if (editedProduct.length > 0) {
        const arrayWithoutOldProdEdited = oldProducts.filter(
          oldProd => oldProd.id !== editedProduct[0].id,
        );
        product &&
          setCart(prev => ({
            ...prev,
            products: [...arrayWithoutOldProdEdited, product],
          }));
        product && calcCart([...arrayWithoutOldProdEdited, product]);
      } else {
        product &&
          setCart(prev => ({
            ...prev,
            products: [
              ...oldProducts,
              { ...product, releaseOrder: prev?.products?.length },
            ], // Espalhando os produtos anteriores e adicionando o novo
          }));
        product && calcCart([...oldProducts, product]);
      }

      setSelectedProduct(null);
    },
    [calcCart, cart.products, showConfirmDialog],
  );

  const handleClearCart = useCallback(() => {
    setCart(prev => ({
      ...prev, // Copia todas as propriedades do estado anterior
      products: [], // Substitui a propriedade products por um array vazio
    }));
  }, []);

  return (
    <ProductsCartContext.Provider
      value={{
        cart,
        products,
        selectedProduct,
        openCart,
        setOpenCart,
        incrementProduct,
        decrementProduct,
        setCart,
        setSelectedProduct,
        setProducts,
        handleSetNewProduct,
        handleSelectComplement,
        toggleHalfPrice,
        handleComment,
        handleSelectProduct,
        deleteProductFromCart,
        editProduct,
        incrementProductOnCart,
        decrementProductOnCart,
        handleClearCart,
        calcCart,
      }}
    >
      {children}
    </ProductsCartContext.Provider>
  );
};

export function useCartProducts(): ProductsCartContextData {
  const context = useContext(ProductsCartContext);

  if (!context) {
    throw new Error('useProducts must be used within ProductsCartProvider');
  }

  return context;
}
