import React, { useState } from 'react';
import Toggle from 'react-toggle';
import { GiTable } from 'react-icons/gi';
import { RiAccountBoxLine } from 'react-icons/ri';
import {
  Wrapper,
  Generators,
  GroupTitle,
  MarginIcon,
  GroupSubTitles,
  GroupContainer,
  ToggleContainer,
  ContainerDescription,
  ContainerDemo,
  DemoOptions,
  Image,
  GridButtons,
  SvgWrapper,
  Button,
  PhoneFrame,
  ButtonFrame,
} from './styles';
import GeneratorTableCard from './components/GeneratorTableCard';
import { ReactComponent as CallWaiterSvg } from '../../../assets/call-waiter.svg';
import { ReactComponent as IconFoodSvg } from '../../../assets/food.svg';
import { ReactComponent as IconCleanSvg } from '../../../assets/clean.svg';
import { ReactComponent as IconWaiterSvg } from '../../../assets/waiter.svg';
import { ReactComponent as IconCloseSvg } from '../../../assets/close-check.svg';
import { ReactComponent as IconQuestionSvg } from '../../../assets/question-mark.svg';

const BsFoodSettings: React.FC = () => {
  const [enableTables, setEnableTables] = useState(false);
  const [enableCards, setEnableCards] = useState(false);
  const [isCallWaiter, setIsCallWaiter] = useState(false);
  const [IsCleanTheTable, setIsCleanTheTable] = useState(false);
  const [isCheckBill, setICheckBill] = useState(false);
  const [isDetailedBill, setIsDetailedBill] = useState(false);
  const [enableDarkCashier, setEnableDarkCashier] = useState(false);
  return (
    <Wrapper onSubmit={() => null}>
      <GroupContainer>
        <GroupTitle>Lançamento de pedido</GroupTitle>
        <ToggleContainer>
          <Toggle
            icons={false}
            checked={enableTables}
            onChange={() => setEnableTables(prev => !prev)}
          />
          <span>Habilitar mesas</span>
        </ToggleContainer>
        <ToggleContainer>
          <Toggle
            icons={false}
            checked={enableCards}
            onChange={() => setEnableCards(prev => !prev)}
          />
          <span>Habilitar comandas</span>
        </ToggleContainer>
        <GroupSubTitles>Encerramento de caixa</GroupSubTitles>
        <ToggleContainer>
          <Toggle
            icons={false}
            checked={enableDarkCashier}
            onChange={() => setEnableDarkCashier(prev => !prev)}
          />
          <span>As escuras</span>
        </ToggleContainer>
        <ContainerDescription>
          <GroupSubTitles>Configuração de mesas e comandas</GroupSubTitles>
          <p>Informe as numerações das mesas/comandas que você utilizará</p>
        </ContainerDescription>
        <Generators>
          <GeneratorTableCard
            type="table"
            icon={<GiTable size={32} color="white" />}
          />
          <GeneratorTableCard
            type="card"
            icon={
              // eslint-disable-next-line react/jsx-wrap-multilines
              <MarginIcon>
                <RiAccountBoxLine size={28} color="white" />
              </MarginIcon>
            }
          />
        </Generators>
        <ContainerDescription>
          <GroupSubTitles>Pedido na mesa Menu</GroupSubTitles>
          <p>
            Permissões para o usuário solicitar no atendimento através do QRCode
            na Mesa.
          </p>
        </ContainerDescription>
        <ContainerDemo>
          <div>
            <ToggleContainer>
              <Toggle
                icons={false}
                checked={isCallWaiter}
                onChange={() => setIsCallWaiter(prev => !prev)}
              />
              <span>Chamar garçom</span>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                icons={false}
                checked={IsCleanTheTable}
                onChange={() => setIsCleanTheTable(prev => !prev)}
              />
              <span>Limpar mesa</span>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                icons={false}
                checked={isCheckBill}
                onChange={() => setICheckBill(prev => !prev)}
              />
              <span>Pedir conta </span>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                icons={false}
                checked={isDetailedBill}
                onChange={() => setIsDetailedBill(prev => !prev)}
              />
              <span>Pedir conta Detalhada</span>
            </ToggleContainer>
          </div>
          <PhoneFrame>
            <ButtonFrame>
              <div />
            </ButtonFrame>
            <DemoOptions>
              <p>Como posso ajudar?</p>
              <Image>
                <CallWaiterSvg className="custom-svg" />
              </Image>
              <GridButtons>
                <Button>
                  <SvgWrapper>
                    <IconFoodSvg />
                  </SvgWrapper>
                  <p>Fazer pedido</p>
                </Button>
                {isCallWaiter && (
                  <Button>
                    <SvgWrapper>
                      <IconWaiterSvg />
                    </SvgWrapper>
                    <p>Chamar garçom</p>
                  </Button>
                )}

                {IsCleanTheTable && (
                  <Button>
                    <SvgWrapper>
                      <IconCleanSvg />
                    </SvgWrapper>
                    <p>Limpar mesa</p>
                  </Button>
                )}

                {isCheckBill && (
                  <Button>
                    <SvgWrapper>
                      <IconCloseSvg />
                    </SvgWrapper>
                    <p>Fechar conta</p>
                  </Button>
                )}

                {isDetailedBill && (
                  <Button>
                    <SvgWrapper>
                      <IconQuestionSvg />
                    </SvgWrapper>
                    <p>O que eu consumi?</p>
                  </Button>
                )}

                {/* <GooglePayButton
                merchantId="1212"
                merchantName="Build Solutions"
                totalPrice="123"
              /> */}
              </GridButtons>
            </DemoOptions>
          </PhoneFrame>
        </ContainerDemo>
      </GroupContainer>
    </Wrapper>
  );
};

export default BsFoodSettings;
