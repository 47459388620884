import React from 'react';
import { RiCloseCircleFill } from 'react-icons/ri';
import { Cell, Clear, Labels, Wrapper, ScrollContainer } from './styles';

import ICategory from '../../../../models/ICategory';
import { IProductSell } from '../../../../models/IFastSell';

import Product from '../Product';

interface IGridProductsProps {
  categories: ICategory[];
  handleClear: () => void;
  products: IProductSell[];
  allProducts: IProductSell[];
  setProducts: React.Dispatch<React.SetStateAction<IProductSell[]>>;
}
const GridProducts: React.FC<IGridProductsProps> = ({
  products,
  allProducts,
  categories,
  handleClear,
  setProducts,
}) => {
  return (
    <Wrapper>
      <Clear>
        <button type="button" onClick={handleClear}>
          <p>Limpar grid</p>
          <RiCloseCircleFill size={32} color="#da123a" />
        </button>
      </Clear>
      {/* Cabeçalho das colunas */}
      <Labels>
        <Cell />
        <Cell>Cód.</Cell>
        <Cell>Nome</Cell>
        <Cell>Qtde</Cell>
        <Cell>Preço</Cell>
        <Cell />
      </Labels>
      {/* Exemplo de linhas com dados */}
      <ScrollContainer className="has-custom-scroll-bar-2">
        {products.map(product => (
          <Product
            categories={categories}
            allProducts={allProducts}
            key={product.hash}
            product={product}
            setProductsToGrid={setProducts}
          />
        ))}
      </ScrollContainer>
    </Wrapper>
  );
};
export default GridProducts;
