import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;

export const Overlay = styled.div<{ isVisible: boolean; zindex?: number }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${({ zindex }) => zindex || '9999'};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px) brightness(0.6);
  will-change: backdrop-filter;

  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};

  transition: opacity 100ms ease, visibility 150ms linear;
  will-change: opacity, visibility;
`;

export const DrawerWrapper = styled.div<{ isVisible: boolean }>`
  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: -2px 2px 7px -1px rgba(0, 0, 0, 0.15);
  border-radius: 16px 0 0 16px;
  width: 50%; /* Ocupa 1/2 da largura total da tela */
  height: 100vh; /* Ocupa toda a altura da tela */
  overflow: hidden;
  position: fixed; /* Fixa a div na tela, independentemente do grid onde está */
  right: 0; /* Alinha no lado direito da tela */
  top: 0; /* Inicia no topo da tela */
  z-index: 9999; /* Garante que fique sobreposta aos outros elementos */

  ${({ isVisible }) =>
    isVisible
      ? css`
          animation: ${slideIn} 0.5s forwards;
        `
      : css`
          animation: ${slideOut} 0.5s forwards;
        `}
  @media (max-width: 940px) {
    border-radius: 0;
    width: 100%; /* Ocupa toda a largura da tela em dispositivos menores */
  }
`;
