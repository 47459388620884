import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import Lottie from 'lottie-react-web';
import { FormHandles } from '@unform/core';
import Select, { ValueType } from 'react-select';
import { FiArrowLeft, FiCheck, FiPlus } from 'react-icons/fi';

import IComplement from '../../models/IComplement';
import IComplementsGroup from '../../models/IComplementsGroup';
import ISaveComplementsGroupDTO from '../../dtos/ISaveComplementsGroupDTO';

import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useComplements } from '../../hooks/complements';

import { selectStyles } from '../../styles/select';

import { formatToMoney } from '../../utils/format';
import { normalizePositions } from '../../utils/arrays';
import { getValidationErrors } from '../../utils/errors';
import animation from '../../assets/animations/loading.json';

import Input from '../Input';
import Complement from '../Complement';
import { IGroupCategory } from '../EditComplementsGroupModal';
import OrderButton from '../OrderButton';
import OrderGroupModal, { IGroupProps } from '../OrderGroupModal';

import {
  Title,
  Header,
  Button,
  Wrapper,
  Container,
  Complements,
  Information,
  SelectLabel,
  ButtonContainer,
  SelectContainer,
  LoadingContainer,
  ComplementsContainer,
  ComplementsButtons,
  DispositionContainer,
} from './styles';
import Disposition, { EDisposition } from '../Disposition';

interface IEditComplementsGroupModal2 {
  loading: boolean;
  visible: boolean;
  complementsGroup: IComplementsGroup | null;
  onSave: (data: ISaveComplementsGroupDTO) => Promise<void>;
  onClose: () => void;
}

interface IEditComplementsGroupFormData {
  title: string;
  minAmount: string;
  maxAmount: string;
  displayName: string;
}

export interface IEditComplementsGroup2Ref {
  complementsLength: () => number;
  getIsComplementsOrderModalOpen: () => boolean;
  closeComplementsOrderModal: () => void;
}

const EditComplementsGroupModal2: React.ForwardRefRenderFunction<
  IEditComplementsGroup2Ref,
  IEditComplementsGroupModal2
> = ({ loading, visible, complementsGroup, onSave, onClose }, ref) => {
  const { company } = useCompany();

  const { saveComplementsPosition } = useComplements();

  const { addToast } = useToast();

  const formRef = useRef<FormHandles | null>(null);

  const [complements, setComplements] = useState<IComplement[]>([]);

  const [category, setCategory] = useState<IGroupCategory>({
    label: 'Padrão',
    value: 'STANDARD',
  });

  const [isOrderGroupModalOpen, setIsOrderGroupModalOpen] = useState(false);

  const [dispositionTypes, setDispositionTypes] = useState<EDisposition[]>([
    'TABLE',
    'TICKET',
    'CHECKOUT',
    'DELIVERY',
    'SELFCHECKOUT',
  ]);

  const categories: IGroupCategory[] = useMemo(() => {
    return [
      {
        label: 'Padrão',
        value: 'STANDARD',
      },
      {
        label: 'Para pizza customizada',
        value: 'PIZZA',
      },
    ];
  }, []);

  useImperativeHandle(ref, () => ({
    complementsLength: () => complements.length,
    getIsComplementsOrderModalOpen: () => isOrderGroupModalOpen,
    closeComplementsOrderModal: () => setIsOrderGroupModalOpen(false),
  }));

  const handleClickOutside = useCallback(
    (event: MouseEvent) => {
      if (
        event.target &&
        event.target.id !== 'drawer' &&
        event.target.id !== 'complementsOrderModal' &&
        event.path &&
        !event.path.find(
          p =>
            (p.id === 'drawer' ||
              p.id === 'toast' ||
              p.id === 'complementsOrderModal') &&
            !loading,
        )
      ) {
        if (isOrderGroupModalOpen) {
          setIsOrderGroupModalOpen(false);
        } else {
          onClose();
        }
      }
    },
    [onClose, loading, isOrderGroupModalOpen],
  );

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [handleClickOutside]);

  const getLabel = useCallback(() => {
    return complementsGroup?.category === 'PIZZA'
      ? 'Para pizza customizada'
      : 'Padrão';
  }, [complementsGroup]);

  useEffect(() => {
    if (visible) {
      setCategory({
        label: getLabel(),
        value: complementsGroup?.category || 'STANDARD',
      });

      if (complementsGroup?.modules) {
        setDispositionTypes(
          complementsGroup.modules.split(', ') as EDisposition[],
        );
      }
    }
  }, [visible, complementsGroup, getLabel]);

  useEffect(() => {
    if (visible && complementsGroup && complementsGroup.complements) {
      const formattedComplements = complementsGroup.complements
        .sort((a, b) => ((a.position || 0) > (b.position || 0) ? 1 : -1))
        .map(complement => {
          return {
            ...complement,
            unitPriceAsString: formatToMoney(complement.unitPrice),
          };
        });

      setComplements(formattedComplements);

      if (formRef.current) {
        formRef.current.setData(complementsGroup);
      }
    } else {
      setComplements([]);

      if (formRef.current) {
        formRef.current.reset();
      }
    }
  }, [visible, complementsGroup]);

  const handleOnBackPressed = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAddNewComplement = useCallback(() => {
    const element = document.getElementById('complementsContainer');

    if (element) {
      element.scrollTo(0, 0);
    }

    setComplements([
      {
        id: 0,
        title: '',
        position: 0,
        unitPrice: 0,
        error: false,
        maxAmount: 0,
        deleted: false,
        isActive: true,
        localId: uuid(),
        description: '',
        unitPriceAsString: 'R$ 0,00',
      },
      ...complements.map(complement => ({
        ...complement,
        position: (complement.position || 0) + 1,
      })),
    ]);
  }, [complements]);

  const handleOnInputChange = useCallback(
    (value: string | number, name: string, id: string | number) => {
      const index = complements.findIndex(
        complement => complement.id === id || complement.localId === id,
      );

      const newComplements = [...complements];

      if (index > -1) {
        switch (name) {
          case 'description':
            newComplements[index].description = value.toString();
            break;
          case 'title':
            newComplements[index].title = value.toString();
            break;
          case 'maxAmount':
            newComplements[index].maxAmount = Number(value);
            break;
          default:
            newComplements[index].unitPrice = Number(value);
            newComplements[index].unitPriceAsString = value.toString();
        }
        setComplements(newComplements);
      }
    },
    [complements],
  );

  const handleOnRemoveComplementClick = useCallback(id => {
    if (typeof id === 'number' && id > 0) {
      setComplements(prevState => {
        return normalizePositions(
          prevState.map(c => {
            if (c.id === id) {
              return {
                ...c,
                deleted: true,
              };
            }

            return { ...c, position: (c.position || 0) - 1 };
          }),
        );
      });
    } else {
      setComplements(prevState => {
        return prevState.filter(c => c.localId !== id);
      });
    }
  }, []);

  const handleOnSubmit = useCallback(
    async (data: IEditComplementsGroupFormData) => {
      const newComplements = [...complements];
      const invalidComplementIndex = complements.findIndex(c => !c.title);

      if (invalidComplementIndex > -1) {
        newComplements[invalidComplementIndex].error = true;
        setComplements(newComplements);

        if (formRef && formRef.current) {
          const inputName = `complement_name_${invalidComplementIndex}`;

          const element = document.querySelector(`label[for="${inputName}"]`);
          element?.scrollIntoView({ behavior: 'smooth' });

          formRef.current.setFieldError(inputName, 'O nome é obrigatório');
        }

        return;
      }

      if (complements.length === 0) {
        addToast({
          type: 'error',
          description: 'Adicione, pelo menos, um complemento.',
        });

        return;
      }

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string()
            .min(3, 'Mínimo de 3 caracteres.')
            .required('O nome é obrigatório.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await onSave({
          complements,
          position: 0,
          isActive: true,
          deleted: false,
          localId: uuid(),
          title: data.title,
          id: complementsGroup?.id,
          displayName: data.displayName,
          maxAmount: Number(data.maxAmount || 0),
          minAmount: Number(data.minAmount || 0),
          category: category.value,
          modules: dispositionTypes.join(','),
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }

        const errors = (err as any)?.response?.data?.errors?.messages;

        addToast({
          type: 'error',
          description:
            (Array.isArray(errors) && errors[0]) ||
            'Ocorreu um erro inesperado.',
        });
      }
    },
    [
      complements,
      addToast,
      onSave,
      complementsGroup,
      category.value,
      dispositionTypes,
    ],
  );

  const filteredComplements = useMemo(() => {
    return complements.filter(c => !c.deleted);
  }, [complements]);

  const handleOnCategoryChanged = useCallback(
    (param: ValueType<IGroupCategory, false>) => {
      setCategory(param as IGroupCategory);
    },
    [],
  );

  const handleOnToggleActiveComplement = useCallback((id: number) => {
    setComplements(prevState => {
      const nextState = [...prevState];

      const index = nextState.findIndex(c => c.id === id);

      if (index > -1) {
        nextState[index].isActive = !nextState[index].isActive;
        return nextState;
      }

      return prevState;
    });
  }, []);

  const handleOnOrderButtonClicked = useCallback(() => {
    setIsOrderGroupModalOpen(true);
  }, []);

  const handleOnOrderGroupModalClose = useCallback(() => {
    setIsOrderGroupModalOpen(false);
  }, []);

  const handleOnOrderGroupModalConfirm = useCallback(
    async (newComplements: IGroupProps[]) => {
      try {
        if (company) {
          await setComplements(old =>
            old
              ? newComplements.map(complement => ({
                  ...(old.find(item =>
                    [item.id, item.localId].includes(complement.id),
                  ) as IComplement),
                  title: complement.label,
                  position: complement.position,
                }))
              : [],
          );

          const newComplementsGroup: IComplementsGroup = {
            ...(complementsGroup as IComplementsGroup),
            complements,
          };

          await saveComplementsPosition(newComplementsGroup);

          addToast({
            type: 'success',
            description: 'Complementos salvos com sucesso!',
          });

          setIsOrderGroupModalOpen(false);
        }
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar os complementos.',
        });
      }
    },
    [company, complementsGroup, complements, saveComplementsPosition, addToast],
  );

  const handleOnDispositionClicked = useCallback(
    (disposition: EDisposition) => {
      setDispositionTypes(prevState => {
        if (prevState.includes(disposition)) {
          if (prevState.length === 1) {
            return prevState;
          }

          return prevState.filter(d => d !== disposition);
        }

        return [...prevState, disposition];
      });
    },
    [],
  );

  const groupToOrder = useMemo(() => {
    if (!complementsGroup?.complements) return [];

    const formattedGroup: IGroupProps[] = complementsGroup?.complements.map(
      (complement, index) => ({
        id: complement?.id || complement?.localId || 0,
        label: complement?.title || '',
        position: complement?.position || index,
      }),
    );

    return formattedGroup;
  }, [complementsGroup]);

  return (
    <Container visible={visible} id="drawer">
      <Header>
        <FiArrowLeft size={24} onClick={handleOnBackPressed} />
        <span>
          {complementsGroup ? 'Alteração de Grupo' : 'Cadastro de Grupo'}
        </span>
      </Header>
      <Information
        ref={formRef}
        onSubmit={handleOnSubmit}
        className="has-custom-scroll-bar-2"
        initialData={complementsGroup || {}}
      >
        <Input name="title" title="Nome" />
        <div className="has-margin-top" />
        <Input name="displayName" title="Nome de exibição" />
        <SelectContainer>
          <SelectLabel>Categoria</SelectLabel>
          <Select
            value={category}
            options={categories}
            styles={selectStyles}
            onChange={handleOnCategoryChanged}
            placeholder="Selecione a categoria"
          />
        </SelectContainer>
        <DispositionContainer>
          <Disposition
            dispositionTypes={dispositionTypes}
            onDispositionClicked={handleOnDispositionClicked}
          />
        </DispositionContainer>
        <Wrapper>
          <Input
            min={0}
            type="number"
            name="minAmount"
            title="Quantidade mínima"
          />
          <Input
            min={0}
            type="number"
            name="maxAmount"
            title="Quantidade máxima"
          />
        </Wrapper>
        <Complements>
          <Title>
            <span>Complementos</span>
            <ComplementsButtons>
              {complementsGroup && (
                <OrderButton onClick={handleOnOrderButtonClicked} />
              )}
              <button
                id="newComplementButton"
                type="button"
                onClick={handleAddNewComplement}
              >
                <FiPlus />
                <span>Novo</span>
              </button>
            </ComplementsButtons>
          </Title>

          <ComplementsContainer
            className="has-custom-scroll-bar-2"
            id="complementsContainer"
          >
            {filteredComplements
              .sort((a, b) => ((a.position || 0) > (b.position || 0) ? 1 : -1))
              .map((complement, index) => (
                <Complement
                  index={index}
                  key={
                    complement.id && complement.id > 0
                      ? complement.id
                      : complement.localId
                  }
                  complement={complement}
                  onChange={handleOnInputChange}
                  onDelete={handleOnRemoveComplementClick}
                  onToggleActive={handleOnToggleActiveComplement}
                />
              ))}
          </ComplementsContainer>
        </Complements>
        <ButtonContainer>
          <Button type="submit">
            {loading ? (
              <LoadingContainer>
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  height={80}
                  width={80}
                />
              </LoadingContainer>
            ) : (
              <>
                <span>Salvar</span>
                <FiCheck size={24} />
              </>
            )}
          </Button>
        </ButtonContainer>
      </Information>
      <OrderGroupModal
        id="complementsOrderModal"
        isOpen={isOrderGroupModalOpen}
        groups={groupToOrder}
        onClose={handleOnOrderGroupModalClose}
        onConfirm={handleOnOrderGroupModalConfirm}
      />
    </Container>
  );
};

export default forwardRef(EditComplementsGroupModal2);
