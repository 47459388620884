import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(100%); /* Começa fora da tela à direita */
  }
  to {
    transform: translateX(0); /* Fica visível na tela */
  }
`;

const slideOut = keyframes`
  from {
    transform: translateX(0); /* Começa visível na tela */
  }
  to {
    transform: translateX(110%); /* Sai para fora da tela à direita */
  }
`;

interface ISellWrapperProps {
  close: boolean;
  displayNone: boolean;
}

export const DetailWrapper = styled.div<ISellWrapperProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  top: 0;
  left: 0;
  width: 100%;
  height: 90%;
  background-color: ${({ theme }) => theme.palette.background_white};
  box-sizing: border-box;
  ${({ close, displayNone }) =>
    close
      ? css`
          animation: ${slideOut} 0.5s ease forwards;
          display: ${displayNone ? 'none' : 'block'};
        `
      : css`
          animation: ${slideIn} 0.5s ease forwards;
        `};
`;
interface IProductProps {
  hasComplements: boolean;
}
export const Product = styled.div<IProductProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1; /* Faz com que o Product ocupe o espaço disponível */
  overflow-y: auto;
  align-items: center;
  text-align: center;
  padding: 0 32px;
`;

export const Image = styled.div`
  svg,
  img {
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 16px;
    object-fit: cover;

    .blue {
      .st0 {
        fill: #85c1e9 !important;
      }
    }

    .green {
      .st0 {
        fill: #94e7b7 !important;
      }
    }

    .orange {
      .st0 {
        fill: #f2be90 !important;
      }
    }

    .red {
      .st0 {
        fill: #f2a59d !important;
      }
    }

    .yellow {
      .st0 {
        fill: #f9e79f !important;
      }
    }
  }
`;

export const HalfPrice = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .prices {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
`;

type IPriceProps = {
  selected: boolean;
};
export const Price = styled.div<IPriceProps>`
  width: auto;
  height: auto;
  color: ${({ theme }) => theme.palette.text_white};
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary : theme.palette.background_dark};
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 16px;

  @media (max-width: 1024px) {
    padding: 8px 16px;
  }
`;

export const Complements = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
