import React, { useState } from 'react';
import { Center, Display, Grid } from './styles';

import { ICard } from '../../models/ICard';

import { FiltersCardsAndTables } from '../FiltersCardsAndTables';
import Card from '../Card';

interface IGridCardsProps {
  onlyCard: boolean;
  cards: ICard[];
}
const GridCards: React.FC<IGridCardsProps> = ({ onlyCard, cards }) => {
  const [filteredCards, setFilteredCards] = useState<ICard[]>(cards);
  return (
    <Display onlyCard={onlyCard}>
      <FiltersCardsAndTables
        cards={cards}
        setFilteredCards={setFilteredCards}
      />
      {filteredCards.length === 0 ? (
        <Center>Nenhuma Comanda encontrada!</Center>
      ) : (
        <Grid className="has-custom-scroll-bar-2">
          {filteredCards.map(card => (
            <Card card={card} key={card.hash} />
          ))}
        </Grid>
      )}
    </Display>
  );
};

export default GridCards;
