/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';

import { Container } from './styles';

interface ICheckboxComplementProps {
  id: string;
  checked: boolean;
  onChange: () => void;
  roundedStyle?: boolean;
}

const CheckboxComplement: React.FC<ICheckboxComplementProps> = ({
  id,
  checked,
  onChange,
  roundedStyle = false,
}) => {
  return (
    <Container rounded={roundedStyle} disabled>
      <input id={id} type="checkbox" onChange={onChange} checked={checked} />
      <label htmlFor={id} />
    </Container>
  );
};

export default CheckboxComplement;
