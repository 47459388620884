import React, { useState } from 'react';
import { Center, Display, Grid } from './styles';

import { ITable2 } from '../../models/ITable2';

import { FiltersCardsAndTables } from '../FiltersCardsAndTables';
import Table from '../Table';

interface IGridTablesProps {
  onlyTable: boolean;
  tables: ITable2[];
}
const GridTables: React.FC<IGridTablesProps> = ({ onlyTable, tables }) => {
  const [filteredTables, setFilteredTables] = useState<ITable2[]>(tables);

  return (
    <Display onlyTable={onlyTable}>
      <FiltersCardsAndTables
        setFilteredTables={setFilteredTables}
        tables={tables}
      />
      {filteredTables.length === 0 ? (
        <Center>Nenhuma Mesa encontrada!</Center>
      ) : (
        <Grid className="has-custom-scroll-bar-2">
          {filteredTables.map(table => (
            <Table table={table} key={table.hash} />
          ))}
        </Grid>
      )}
    </Display>
  );
};

export default GridTables;
