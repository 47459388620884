import { PixKeyType } from '../enums/pixKeyType';

export const unmaskPostalCode = (postalCode: string): string => {
  return postalCode.replace('.', '').replace('-', '');
};

export const unmaskPhoneNumber = (phoneNumber: string): string => {
  return phoneNumber
    .replace('(', '')
    .replace(')', '')
    .replace(' ', '')
    .replace(' ', '')
    .replace('-', '')
    .replace(' ', '');
};

export const unmaskMoney = (value: string): number => {
  return Number(value.replace(/\D/g, '')) / 100;
};

export const unmaskPixKey = (value: string, type: PixKeyType): string => {
  switch (type) {
    case PixKeyType.CNPJ: {
      return value
        .replace('.', '')
        .replace('.', '')
        .replace('/', '')
        .replace('-', '');
    }

    case PixKeyType.CPF: {
      return value
        .replace('.', '')
        .replace('.', '')
        .replace('.', '')
        .replace('-', '');
    }

    case PixKeyType.PHONE: {
      return value
        .replace('(', '')
        .replace(')', '')
        .replace(' ', '')
        .replace(' ', '')
        .replace(' ', '');
    }

    default: {
      return value;
    }
  }
};

export const unmaskCpfRg = (e: string): string => {
  const input = e.replace(/\D/g, ''); // Remove tudo que não é dígito

  return input;
};

export const maskPhoneNumber = (phoneNumber: string): string => {
  phoneNumber.replace(/\D/g, '');

  if (phoneNumber.length > 11) {
    // Formato para números com DDD e 9 dígitos (celular)
    return phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  if (phoneNumber.length === 11) {
    // Formato para números com DDD e 8 dígitos (fixo)
    return phoneNumber.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3');
  }
  // Retorna o número sem formatação se não tiver o comprimento esperado
  return phoneNumber;
};

export const CpfRgMask = (e: string): string => {
  let input = e.replace(/\D/g, ''); // Remove tudo que não é dígito

  if (input.length === 11) {
    // CPFe
    if (input.length > 9) {
      input = input.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    } else if (input.length > 6) {
      input = input.replace(/(\d{3})(\d{3})(\d{3})/, '$1.$2.$3');
    } else if (input.length > 3) {
      input = input.replace(/(\d{3})(\d{3})/, '$1.$2');
    }
  } else {
    input = e.replace(/\D/g, '');
  }

  return input;
};
