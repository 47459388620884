import styled, { css } from 'styled-components';
import ReactModal from 'react-modal';
import { ICouponArtElement, ICouponArtText } from '../../models/ICouponArt';

import { appearFromBottom } from '../../styles/animations';

interface IVariantButtonProps {
  active?: boolean;
}

export const StyledModal = styled(ReactModal).attrs({
  style: {
    content: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
      overflow: 'auto',
    },
  },
})`
  animation: ${appearFromBottom} 0.5s;

  height: -webkit-fill-available;
`;

export const Container = styled.div`
  min-width: min(500px, 80%);
  max-height: 100vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  background: ${({ theme }) => theme.palette.background_white};
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  padding: 24px;
  border-radius: 16px;
  gap: 16px;

  @media (max-width: 480px) {
    padding: 32px 24px;
  }
`;

export const Header = styled.div`
  display: flex;
  justify-content: space-between;

  h1 {
    font-size: 24px;
  }
`;

export const CouponArtTemplateList = styled.div`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const CloseButton = styled.button`
  margin-left: auto;
  border: none;
  background: none;
`;

export const CouponArtTemplateListItem = styled.button`
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: ${({ theme }) => theme.palette.background_white};
  padding: 16px;
  gap: 16px;
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.palette.outline};

  img {
    width: 48px;
    height: 48px;
    border-radius: 6px;
  }

  span {
    text-align: start;
    font-size: 1.2rem;
    margin-right: auto;
  }
`;

export const PreviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;

  width: 100%;
`;

export const ArtPreview = styled.div`
  position: relative;
  display: flex;
  user-select: none;
  pointer-events: none;
  width: 450px;
`;

export const BackgroundImage = styled.img`
  width: 100%;
  height: auto;
`;

export const ArtPreviewElementWrapper = styled.div<ICouponArtElement>`
  /* border: 1px dashed #fff; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  text-align: center;

  div {
    line-height: 110%;
  }

  ${props => css`
    width: ${props.width}%;
    height: ${props.height}%;
    left: ${props.x}%;
    top: ${props.y}%;
    transform: rotateZ(${props.rotation}deg);
  `}
`;

type FontWeightKeys = {
  [key in ICouponArtText['weight']]: string;
};

const fontWeights: FontWeightKeys = {
  normal: 'normal',
  bold: '800',
  medium: '600',
  light: '400',
};

export const ArtPreviewTextWrapper = styled(
  ArtPreviewElementWrapper,
)<ICouponArtText>`
  strong {
    font-weight: 700 !important;
  }

  ${props => css`
    font-weight: ${fontWeights[props.weight || 'normal']};
    text-decoration: ${props?.decoration || 'none'};
    text-transform: ${props?.transform || 'none'};
  `}

  ${props =>
    props.color.includes('linear-gradient')
      ? css`
          background-image: ${props.color};
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        `
      : css`
          color: ${props.color};
        `}
`;

export const ArtPreviewLogo = styled(ArtPreviewElementWrapper)`
  img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
  }
`;

export const ArtActionsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
`;

export const VariantContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 16px;

  span {
    font-size: 1.2rem;
    font-weight: 500;
  }
`;

export const Variants = styled(ArtActionsContainer)`
  width: auto;
  gap: 8px;
`;

export const SaveButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  padding: 8px 16px;
  border-radius: 4px;

  &:disabled {
    opacity: 0.5;
    cursor: normal;
  }

  @media (max-width: 480px) {
    font-size: 14px;
    padding: 8px;
  }
`;

export const PrevArtButton = styled(SaveButton)`
  margin: 0;
  margin-left: 0;
  gap: 8px;
  background: ${({ theme }) => theme.palette.secondary};
`;

export const NextArtButton = styled(PrevArtButton)``;

export const VariantButton = styled(SaveButton)<IVariantButtonProps>`
  background: none;
  border: 2px solid ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.primary};
  font-weight: 500;
  border-radius: 32px;
  padding: 4px 16px;

  ${({ active }) =>
    active &&
    css`
      background: ${({ theme }) => theme.palette.primary};
      color: ${({ theme }) => theme.palette.text_white};
    `}
`;
