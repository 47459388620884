import React, { InputHTMLAttributes, forwardRef } from 'react';
import { FiCopy, FiX } from 'react-icons/fi';
import { Label, Container, StyledInput, Copy, WrapperInput } from './styles';

interface IBasicInputProps extends InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  maxLength?: number;
  alignCenter?: boolean;
  variant?: 'COPIABLE' | 'STANDARD';
  scale?: 'sm' | 'md' | 'lg' | 'xl';
  onCopy?: () => void;
  clearLable?: () => void;
}

const BasicInput = forwardRef<HTMLInputElement, IBasicInputProps>(
  (
    {
      error,
      name,
      title,
      variant,
      maxLength,
      value = '',
      alignCenter,
      scale = 'md',
      onCopy,
      clearLable,
      ...rest
    },
    ref,
  ) => {
    return (
      <Container>
        <Label scale={scale} htmlFor={name}>
          {title}
        </Label>
        <WrapperInput>
          <StyledInput
            ref={ref}
            hasError={!!error}
            value={value}
            maxLength={maxLength}
            scale={scale}
            alignCenter={alignCenter}
            {...rest}
          />
          {variant === 'COPIABLE' && !error && (
            <Copy onClick={onCopy}>
              <FiCopy size={20} />
            </Copy>
          )}

          {clearLable && (
            <Copy onClick={clearLable}>
              <FiX size={20} />
            </Copy>
          )}
        </WrapperInput>
        {error && <p className="error">{error}</p>}
      </Container>
    );
  },
);

export default BasicInput;
