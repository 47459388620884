import { DateTime } from 'luxon';

export const weekDays = [
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
];

export const getWeekDayString = (day: number): string => {
  return weekDays[day];
};

export const normalizeHHmmToHHmmss = (hour: string): string => {
  const match = hour.split(':').length === 3;
  return match ? hour : `${hour}:00`;
};

export const convertHHmmssToNumber = (hour: string): number => {
  const normalizedHour = normalizeHHmmToHHmmss(hour)
    .substring(0, 5)
    .replace(/\D/g, '');

  return Number(normalizedHour);
};

export const isDatesSameDay = (date1: string, date2: string): boolean => {
  const date1Date = DateTime.fromISO(date1);
  const date2Date = DateTime.fromISO(date2);

  return date1Date.hasSame(date2Date, 'day');
};

export const calculateTimeDifference = (
  openedAt: string,
): { minutes: number; hours: number; days: number } => {
  const openedDate = new Date(openedAt);
  const currentDate = new Date();

  const differenceInMilliseconds = currentDate.getTime() - openedDate.getTime();

  const differenceInMinutes =
    Math.floor(differenceInMilliseconds / 1000 / 60) % 60;
  const differenceInHours =
    Math.floor(differenceInMilliseconds / 1000 / 60 / 60) % 24;
  const differenceInDays = Math.floor(
    differenceInMilliseconds / 1000 / 60 / 60 / 24,
  );
  return {
    days: differenceInDays,
    hours: differenceInHours,
    minutes: differenceInMinutes,
  };
};
