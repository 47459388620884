import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100px;
  border: 1px solid ${({ theme }) => theme.palette.background_white};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: ${({ theme }) => theme.palette.background_white};
  transition: 500ms;
  align-items: center;
  gap: 10px;
  padding: 5px;
  overflow-x: hidden;

  :hover {
    cursor: pointer;
    transform: scale(1.05);
  }
`;

export const Image = styled.div`
  svg,
  img {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    border-radius: 8px;
    object-fit: cover;
  }

  .blue {
    .st0 {
      fill: #85c1e9 !important;
    }
  }

  .green {
    .st0 {
      fill: #94e7b7 !important;
    }
  }

  .orange {
    .st0 {
      fill: #f2be90 !important;
    }
  }

  .red {
    .st0 {
      fill: #f2a59d !important;
    }
  }

  .yellow {
    .st0 {
      fill: #f9e79f !important;
    }
  }
`;

export const Infos = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;

  .name {
    font-weight: 500;
    display: -webkit-box;
    text-overflow: ellipsis;
    overflow: hidden;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Número de linhas a serem exibidas */
  }

  .price {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
  }

  p {
    font-size: 14px;
    font-weight: 500;
  }
`;

export const TypesContainer = styled.div`
  display: flex;
  font-size: 10px;
  gap: 2px;
  flex-wrap: wrap;
  overflow: hidden;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
`;

export const CategoryBadge = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 64px;
  background: #3498db40;
  padding: 2px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap; /* Garante que o texto longo em uma linha seja truncado */
  max-width: 100%;
`;

export const SubcategoryBadge = styled(CategoryBadge)`
  background: #1abc9c40;
`;
