import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  padding: 10px;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  max-width: 400px;
  max-height: 55px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text_white};
  background-color: ${({ theme }) => theme.palette.primary_light};
  background-image: url(https://menustaging.bsfood.com.br/svg/custom-pizza-background-light.svg);

  .separate {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .label {
    font-weight: 600;
  }
  .click {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary};
  }
`;
export const Header = styled.div`
  display: flex;
  width: 100%;
  max-height: 60px;
  background-color: ${({ theme }) => theme.palette.background_white};
  z-index: 999;
  padding: 0 32px;
  padding-top: 20px;
  align-items: flex-start;
  @media (max-width: 700px) {
    padding: 0 16px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1024px) {
      padding: 0 16px;
    }
    @media (max-width: 1129px) {
      h2 {
        font-size: 20px;
      }
    }
  }
`;
export const Close = styled.div`
  display: flex;
  width: auto;
  height: auto;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100% - 160px);
  overflow-y: auto;
  align-items: center;
  text-align: center;
  padding: 0 32px;
`;

export const Badge = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.text_light};
  cursor: pointer;
  transition: 300ms;

  box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
    rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
  &:hover {
    transform: scale(1.05);
  }

  span {
    font-size: 32px;
  }
`;

export const SelectCategory = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  margin-top: 3rem;
  gap: 1rem;
`;
