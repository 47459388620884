import styled from 'styled-components';

export const ModalWrapper = styled.div<{ error: boolean }>`
  margin: 0 0 32px 0;

  .title {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
  }

  .container {
    display: flex;
    gap: 0.5rem;
  }

  gap: 1rem;
  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.primary : theme.palette.outline};
    height: 40px;
  }
`;
