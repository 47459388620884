import React, { useState } from 'react';
import { GiTable } from 'react-icons/gi';
import { MdPeople } from 'react-icons/md';
import { ContainerTable, Icon, Infos } from './styles';

import { statusCardTableType } from '../../enums/cardAndTableType';
import { ITable2 } from '../../models/ITable2';

import DrawerCardTable from '../DrawerCardTable';
import ModalOpenCardTable from '../ModalOpenCardTable';
import DaysHoursMinutes from '../DaysHoursMinutes';

interface ITableProps extends React.HTMLAttributes<HTMLDivElement> {
  table: ITable2;
}
const Table: React.FC<ITableProps> = ({ table, ...divProps }) => {
  const [tableState, setTableState] = useState<ITable2>(table);
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <ContainerTable
        onClick={() =>
          tableState.status === statusCardTableType.FREE
            ? setOpen(true)
            : setOpenDrawer(true)
        }
        status={tableState.status}
        {...divProps}
      >
        <div className="name">
          {tableState.description ? <p>{tableState.description}</p> : <p />}
          <GiTable size={32} color="white" />
        </div>

        <Infos>
          <div className="subInfos">
            {tableState.personQty > 0 && (
              <Icon>
                <MdPeople size={14} />
                <p>{tableState.personQty}</p>
              </Icon>
            )}

            {tableState.openedAt && (
              <DaysHoursMinutes opened={tableState.openedAt} />
            )}
          </div>

          <div className="tableNumber">
            {tableState.tableNumber && <p>{tableState.tableNumber}</p>}
          </div>
        </Infos>
      </ContainerTable>

      {open && (
        <ModalOpenCardTable
          open={open}
          table={table}
          setOpen={setOpen}
          setOpenDrawer={setOpenDrawer}
          setTableState={setTableState}
        />
      )}

      {openDrawer && (
        <DrawerCardTable
          table={tableState}
          open={openDrawer}
          setOpen={setOpenDrawer}
          setTableState={setTableState}
        />
      )}
    </>
  );
};

export default Table;
