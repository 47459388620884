import React, { useEffect, useRef, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiPercent, FiStar } from 'react-icons/fi';
import {
  CarouselWrapper,
  GroupContainer,
  GroupsWrapper,
  NavButton,
} from './styles';

import ICategory from '../../models/ICategory';
import { IdsSelectedType } from '../ProductsSellPage';

import { chooseCategoryIcon } from '../../utils/icons';
import { NotFound } from '../../pages/ClientPage/styles';

interface ICarousellSellPageProps {
  setIdsSelected: React.Dispatch<React.SetStateAction<IdsSelectedType>>;
  idsSelected: IdsSelectedType;
  groups: ICategory[];
}
const CarousellSellPage: React.FC<ICarousellSellPageProps> = ({
  setIdsSelected,
  idsSelected,
  groups,
}) => {
  const [selectedGroupId, setSelectedGroupId] = useState<number>(
    idsSelected.IdGroupSelected,
  );
  useEffect(() => {
    if (
      selectedGroupId !== null &&
      wrapperRef.current &&
      groupRefs.current[selectedGroupId]
    ) {
      const selectedGroupElement = groupRefs.current[selectedGroupId];
      if (selectedGroupElement) {
        wrapperRef.current.scrollTo({
          left:
            selectedGroupElement.offsetLeft -
            wrapperRef.current.offsetLeft -
            50,
          behavior: 'smooth',
        });
      }
    }
  }, [selectedGroupId]);

  const onlyGroups = groups.filter(group => group.isCategory);

  const wrapperRef = useRef<HTMLDivElement>(null);
  const groupRefs = useRef<(HTMLDivElement | null)[]>([]);
  let isDown = false;
  let startX: number;
  let scrollLeft: number;

  const handleMouseDown = (e: React.MouseEvent) => {
    if (wrapperRef.current) {
      isDown = true;
      startX = e.pageX - wrapperRef.current.offsetLeft;
      scrollLeft = wrapperRef.current.scrollLeft;
      wrapperRef.current.classList.add('active');
    }
  };

  const handleMouseLeave = () => {
    if (wrapperRef.current) {
      isDown = false;
      wrapperRef.current.classList.remove('active');
    }
  };

  const handleMouseUp = () => {
    if (wrapperRef.current) {
      isDown = false;
      wrapperRef.current.classList.remove('active');
    }
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (!isDown || !wrapperRef.current) return;
    e.preventDefault();
    const x = e.pageX - wrapperRef.current.offsetLeft;
    const walk = (x - startX) * 2; // Ajuste a velocidade conforme necessário
    wrapperRef.current.scrollLeft = scrollLeft - walk;
  };

  const scrollToLeft = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollBy({
        left: -wrapperRef.current.offsetWidth + 100,
        behavior: 'smooth',
      });
    }
  };

  const scrollToRight = () => {
    if (wrapperRef.current) {
      wrapperRef.current.scrollBy({
        left: wrapperRef.current.offsetWidth - 100,
        behavior: 'smooth',
      });
    }
  };

  const handleGroupRef = (id: number) => (el: HTMLDivElement | null) => {
    groupRefs.current[id] = el;
  };

  const handleGroupClick = (groupId: number) => {
    if (groupId === selectedGroupId) {
      setIdsSelected({
        IdGroupSelected: 0,
        IdSubcategorieSelected: 0,
        IdSizeSelected: 0,
      });
      setSelectedGroupId(0);
    } else {
      setIdsSelected({
        IdGroupSelected: groupId,
        IdSubcategorieSelected: 0,
        IdSizeSelected: 0,
      });
      setSelectedGroupId(groupId);
    }
  };

  return (
    <CarouselWrapper>
      <NavButton left onClick={scrollToLeft}>
        <FiArrowLeft size={22} />
      </NavButton>
      {onlyGroups?.length === 0 ? (
        <NotFound>Nenhuma categoria encontrada!</NotFound>
      ) : (
        <GroupsWrapper
          ref={wrapperRef}
          onMouseDown={handleMouseDown}
          onMouseLeave={handleMouseLeave}
          onMouseUp={handleMouseUp}
          onMouseMove={handleMouseMove}
        >
          <GroupContainer
            selected={selectedGroupId === -1}
            favorite
            onClick={() => handleGroupClick(-1)}
          >
            <FiStar
              size={22} // Black pois o style inverte  a cor para o contrário (branco)
              color={selectedGroupId === -1 ? '#000' : ' #FDD400'}
            />
            Favoritos
          </GroupContainer>
          <GroupContainer
            selected={selectedGroupId === -2}
            favorite={false}
            onClick={() => handleGroupClick(-2)}
            promotion
          >
            <FiPercent
              size={22}
              color={selectedGroupId === -2 ? '#000' : ' #E20E2D'}
            />
            Promoções
          </GroupContainer>
          <GroupContainer
            selected={selectedGroupId === 0}
            favorite={false}
            onClick={() => handleGroupClick(0)}
          >
            <img src={chooseCategoryIcon('todos')} alt="Todos" />
            <p>Todos</p>
          </GroupContainer>
          {onlyGroups.map(group => (
            <GroupContainer
              selected={selectedGroupId === group.id}
              favorite={false}
              key={group.id}
              onClick={() => handleGroupClick(group.id)}
              ref={handleGroupRef(group.id)}
            >
              <img src={chooseCategoryIcon(group.name)} alt={group.name} />
              <p>{group.name}</p>
            </GroupContainer>
          ))}
        </GroupsWrapper>
      )}

      <NavButton onClick={scrollToRight}>
        <FiArrowRight size={22} />
      </NavButton>
    </CarouselWrapper>
  );
};

export default CarousellSellPage;
