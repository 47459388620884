import React, { useEffect, useRef } from 'react';
import { MaskedCurrency, IMaskedCurrencyRef } from 'react-easy-mask';

import { useField } from '@unform/core';
import { FiAlertCircle } from 'react-icons/fi';

import { Error, Label, Container } from './styles';

interface INumberInputProps {
  error?: string;
  defaultValue?: number;
  value?: number;
  title?: string;
  name?: string;
  max?: number;
  onChange: (value: number) => void;
}

const NumberInput: React.FC<INumberInputProps> = ({
  name,
  error,
  title,
  defaultValue,
  max,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<IMaskedCurrencyRef>(null);

  useEffect(() => {
    inputRef.current?.setValue(defaultValue || 0);
  }, [defaultValue]);

  return (
    <Container hasError={!!error} {...rest}>
      <Label htmlFor={name}>{title}</Label>
      <MaskedCurrency
        locale="pt-BR"
        currency="BRL"
        onChangeValue={onChange}
        ref={inputRef}
        max={max}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

interface INumberInputForm {
  title: string;
  name: string;
  hide?: boolean;
  max?: number;
  onChange?: (value: number) => void;
}

export const NumberInputForm: React.FC<INumberInputForm> = ({
  name,
  title,
  hide,
  max,
  onChange,
  ...rest
}) => {
  const inputRef = useRef<IMaskedCurrencyRef>(null);

  const { defaultValue, fieldName, registerField, error, clearError } =
    useField(name);

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRef.current,
      getValue(ref: typeof inputRef.current) {
        if (ref) {
          return ref?.getValue();
        }
        return null;
      },
      setValue(ref: typeof inputRef.current, value: number | null) {
        if (ref) {
          return ref.setValue(value || 0);
        }

        return null;
      },
      clearValue(ref: typeof inputRef.current) {
        if (ref) {
          return ref.setValue(0);
        }
        return null;
      },
    });
  }, [fieldName, registerField]);

  return (
    <Container hasError={!!error} hide={hide} {...rest}>
      <Label htmlFor={name}>{title}</Label>
      <MaskedCurrency
        ref={inputRef}
        locale="pt-BR"
        currency="BRL"
        defaultValue={defaultValue}
        onFocus={clearError}
        max={max}
        onChangeValue={onChange}
      />
      {error && (
        <Error title={error}>
          <FiAlertCircle color="#c53030" size={20} />
        </Error>
      )}
    </Container>
  );
};

export default NumberInput;
