import React, { createContext, useCallback, useContext } from 'react';

import { AxiosResponse } from 'axios';
import api from '../services/api';

import IOrder from '../models/IOrder';
import { ICard } from '../models/ICard';

interface CardsContextData {
  getCards: () => Promise<AxiosResponse<ICard[]>>;
  getOrderCart: (cardNumber: number) => Promise<AxiosResponse<IOrder>>;
  putOccupy: (card: ICard) => Promise<AxiosResponse<ICard>>;
  patchDescription: (
    cardNumber: number,
    description: string,
  ) => Promise<AxiosResponse<ICard>>;
  patchLocation: (
    cardNumber: number,
    location: string,
  ) => Promise<AxiosResponse<ICard>>;
  createCards: (
    initialNumber: number,
    finalNumber: number,
  ) => Promise<AxiosResponse<ICard[]>>;
  deactivateCards: (
    arrayCards: { cardNumber: number }[],
  ) => Promise<AxiosResponse<ICard[]>>;
}

const CardsContext = createContext<CardsContextData>({} as CardsContextData);

export const CardsProvider: React.FC = ({ children }) => {
  const createCards = useCallback(
    async (initialNumber: number, finalNumber: number) => {
      const response = await api.post<ICard[]>(`restricted/cards`, {
        initialNumber,
        finalNumber,
      });
      return response;
    },
    [],
  );
  const deactivateCards = useCallback(
    async (arrayCards: { cardNumber: number }[]) => {
      const response = await api.patch<ICard[]>(
        `restricted/cards/deactivate`,
        arrayCards,
      );
      return response;
    },
    [],
  );

  const getCards = useCallback(async () => {
    const response = await api.get<ICard[]>('restricted/cards/active');
    return response;
  }, []);

  const getOrderCart = useCallback(async (cardNumber: number) => {
    const response = await api.get<IOrder>(
      `restricted/internal-orders/cards/${cardNumber}`,
    );
    return response;
  }, []);

  const putOccupy = useCallback(async (card: ICard) => {
    const response = await api.put<ICard>(
      `restricted/cards/${card.cardNumber}/occupy`,
      {
        cardNumber: card.cardNumber,
        description: card.description,
        location: card.location,
      },
    );
    return response;
  }, []);

  const patchDescription = useCallback(
    async (cardNumber: number, description: string) => {
      const response = await api.patch<ICard>(
        `restricted/cards/${cardNumber}/description`,
        {
          cardNumber,
          description,
        },
      );
      return response;
    },
    [],
  );

  const patchLocation = useCallback(
    async (cardNumber: number, location: string) => {
      const response = await api.patch<ICard>(
        `restricted/cards/${cardNumber}/location`,
        {
          cardNumber,
          location,
        },
      );
      return response;
    },
    [],
  );

  return (
    <CardsContext.Provider
      value={{
        getCards,
        putOccupy,
        createCards,
        getOrderCart,
        patchLocation,
        deactivateCards,
        patchDescription,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};

export function useCards(): CardsContextData {
  const context = useContext(CardsContext);

  if (!context) {
    throw new Error('useCards must be used within CardsProvider');
  }

  return context;
}
