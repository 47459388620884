import React from 'react';
import { MdAccessTime } from 'react-icons/md';
import { calculateTimeDifference } from '../../utils/date';
import { Icon, Time } from './styles';

interface IDaysHoursMinutesProps {
  opened: string;
}
const DaysHoursMinutes: React.FC<IDaysHoursMinutesProps> = ({ opened }) => {
  const { days, hours, minutes } = calculateTimeDifference(opened);
  return (
    <Icon>
      <MdAccessTime size={14} />
      {days > 0 && (
        <Time>
          <p>{days}</p>
          <p>d</p>
        </Time>
      )}
      {hours > 0 && (
        <Time>
          <p>{hours}</p>
          <p>h</p>
        </Time>
      )}
      {minutes > 0 && (
        <Time>
          <p>{minutes}</p>
          <p>min</p>
        </Time>
      )}
    </Icon>
  );
};

export default DaysHoursMinutes;
