import React, { createContext, useCallback, useContext } from 'react';

import { AxiosResponse } from 'axios';
import api from '../services/api';

import IOrder from '../models/IOrder';
import { ITable2 } from '../models/ITable2';

interface TablesContextData {
  getTables: () => Promise<AxiosResponse<ITable2[]>>;
  getOrderTable: (cardNumber: number) => Promise<AxiosResponse<IOrder>>;
  putOccupy: (table: ITable2) => Promise<AxiosResponse<ITable2>>;
  putFee: (table: ITable2) => Promise<AxiosResponse<ITable2>>;
  patchDescription: (
    tableNumber: number,
    description: string,
  ) => Promise<AxiosResponse<ITable2>>;
  deleteItem: (
    orderId: number,
    itemId: number,
    reason: string,
  ) => Promise<AxiosResponse<IOrder>>;
  createTables: (
    initialNumber: number,
    finalNumber: number,
  ) => Promise<AxiosResponse<ITable2[]>>;
  deactivateTables: (
    arrayTables: { tableNumber: number }[],
  ) => Promise<AxiosResponse<ITable2[]>>;
}

const TablesContext = createContext<TablesContextData>({} as TablesContextData);

export const TableProvider: React.FC = ({ children }) => {
  const createTables = useCallback(
    async (initialNumber: number, finalNumber: number) => {
      const response = await api.post<ITable2[]>(`restricted/tables`, {
        initialNumber,
        finalNumber,
      });
      return response;
    },
    [],
  );

  const deactivateTables = useCallback(
    async (arrayTables: { tableNumber: number }[]) => {
      const response = await api.patch<ITable2[]>(
        `restricted/tables/deactivate`,
        arrayTables,
      );
      return response;
    },
    [],
  );

  const getTables = useCallback(async () => {
    const response = api.get<ITable2[]>('restricted/tables/active');
    return response;
  }, []);

  const getOrderTable = useCallback(async (tableNumber: number) => {
    const response = await api.get<IOrder>(
      `restricted/internal-orders/tables/${tableNumber}`,
    );
    return response;
  }, []);

  const putOccupy = useCallback(async (table: ITable2) => {
    const response = await api.put<ITable2>(
      `restricted/tables/${table.tableNumber}/occupy`,
      {
        tableNumber: table.tableNumber,
        description: table.description,
        personQty: table.personQty,
      },
    );
    return response;
  }, []);

  const putFee = useCallback(async (table: ITable2) => {
    const response = await api.put<ITable2>(
      `restricted/tables/${table.tableNumber}/complete`,
    );
    return response;
  }, []);

  const patchDescription = useCallback(
    async (tableNumber: number, description: string) => {
      const response = await api.patch<ITable2>(
        `restricted/tables/${tableNumber}/description`,
        {
          tableNumber,
          description,
        },
      );
      return response;
    },
    [],
  );
  const deleteItem = useCallback(
    (orderId: number, itemId: number, reason: string) => {
      const response = api.patch<IOrder>(
        `restricted/internal-orders/${orderId}/items/${itemId}`,
        { reason },
      );

      return response;
    },
    [],
  );

  return (
    <TablesContext.Provider
      value={{
        putFee,
        getTables,
        putOccupy,
        deleteItem,
        createTables,
        getOrderTable,
        deactivateTables,
        patchDescription,
      }}
    >
      {children}
    </TablesContext.Provider>
  );
};

export function useTable(): TablesContextData {
  const context = useContext(TablesContext);

  if (!context) {
    throw new Error('useTables must be used within TableProvider');
  }

  return context;
}
