import React, { useContext, createContext, useCallback } from 'react';

import api from '../services/api';
import { IProductSell } from '../models/IFastSell';
import { ITransaction } from '../models/IPayment';

export interface ISendOrderProps {
  // receiverDocument?: string;
  // phoneNumber: string;
  total: number;
  subtotal: number;
  comments?: string;
  discount?: number;
  customerId?: number;
  serviceFee?: number;
  customerName?: string;
  items: IProductSell[];
  change?: number;
  paymentsTransactions: ITransaction[];
}
interface CashierOrdersContextData {
  sendOrder: (body: ISendOrderProps, cartId: string) => Promise<void>;
}

const CashierOrdersContext = createContext<CashierOrdersContextData>(
  {} as CashierOrdersContextData,
);

export const CashierOrdersProvider: React.FC = ({ children }) => {
  const sendOrder = useCallback(
    async (body: ISendOrderProps, cartId: string) => {
      const itensWithoutId = body.items.map((item: any) => {
        const { id, productId, ...rest } = item; // Remove o id do item principal
        const cleanedItem =
          productId === 0 ? { ...rest } : { ...rest, productId };
        return cleanedItem;
      });

      try {
        await api.post(
          `/restricted/cashier-orders`,
          { ...body, items: itensWithoutId },
          {
            headers: { 'X-Cart-Id': cartId },
          },
        );
      } catch (error) {
        console.error('Erro na API:', error);
        throw error;
      }
    },
    [],
  );

  return (
    <CashierOrdersContext.Provider
      value={{
        sendOrder,
      }}
    >
      {children}
    </CashierOrdersContext.Provider>
  );
};

export function useCashierOrders(): CashierOrdersContextData {
  const context = useContext(CashierOrdersContext);

  if (!context) {
    throw new Error(
      'useCashierOrders must be used within CashierOrdersProvider',
    );
  }

  return context;
}
