/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { FiAlertTriangle, FiPlus, FiTrash } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';
import api from '../../services/api';
import { getImageAsync } from '../../utils/image';
import Loading from '../Loading';

import {
  Container,
  Content,
  Image as ImageComponent,
  Add,
  ImageButton,
  Title,
} from './styles';

interface IImage {
  id: string;
  url: string;
  file?: File;
}

interface ILocalImage extends IImage {
  warning: boolean;
  loading: boolean;
  local: boolean;
}

export interface IMultipleImageRef {
  getValue: () => IImage[];
  setValue: (value: IImage[]) => void;
  uploadImages: () => Promise<void[]>;
}

const MultipleHistoryImageInput: React.ForwardRefRenderFunction<
  IMultipleImageRef,
  React.HTMLAttributes<HTMLDivElement>
> = ({ ...rest }, ref) => {
  const [images, setImages] = useState<ILocalImage[]>([]);
  const inputRef = useRef<HTMLInputElement>(null);

  const transformFile = useCallback(
    async (file: File): Promise<ILocalImage> => {
      const id = uuid();
      const url = URL.createObjectURL(file);

      const image = await getImageAsync(url);

      return {
        id,
        url,
        file,
        warning: image.width < 600 || image.height < 600,
        loading: false,
        local: true,
      };
    },
    [],
  );

  const handleGetValue = useCallback(() => images, [images]);

  const handleSetValue = useCallback(
    (value: IImage[]) =>
      setImages(
        value.map(item => ({
          ...item,
          warning: false,
          loading: false,
          local: false,
        })),
      ),
    [],
  );

  const handleUpload = useCallback(async () => {
    const promises = images
      .filter(image => !!image?.file)
      .map(async image => {
        if (image?.file) {
          const data = new FormData();
          data.append('image', image.file);

          setImages(old =>
            old.map(item =>
              item.id === image.id
                ? {
                    ...item,
                    loading: true,
                  }
                : item,
            ),
          );
          await api.post('/restricted/companies/histories/image', data);
          setImages(old =>
            old.map(item =>
              item.id === image.id
                ? {
                    ...item,
                    loading: false,
                    local: true,
                    file: undefined,
                  }
                : item,
            ),
          );
        }
      });

    return Promise.all(promises);
  }, [images]);

  const handleAddChange = useCallback(async () => {
    if (
      images.length < 5 &&
      inputRef.current?.files &&
      inputRef.current?.files?.length > 0
    ) {
      const files = Array.from(inputRef.current?.files).slice(
        0,
        5 - images.length,
      );

      inputRef.current.value = '';

      if (files.length > 1) {
        const values = await Promise.all(files.map(transformFile));
        return setImages(images.concat(values));
      }

      const newImage = await transformFile(files[0]);
      return setImages(images.concat([newImage]));
    }

    return null;
  }, [transformFile, images]);

  const handleDelete = useCallback(async (image: ILocalImage) => {
    if (!image.local) {
      const urlHash = image.url.split('/').slice(-1)[0];

      setImages(old =>
        old.map(item =>
          item.id === image.id ? { ...item, loading: true } : item,
        ),
      );
      await api.delete(`/restricted/companies/histories/${urlHash}/image`);
    }
    setImages(old => old.filter(currentImage => currentImage.id !== image.id));
  }, []);

  useImperativeHandle(ref, () => ({
    getValue: handleGetValue,
    setValue: handleSetValue,
    uploadImages: handleUpload,
  }));

  return (
    <Container {...rest}>
      <Title>
        Imagens
        {images.some(item => item.warning) && (
          <span className="warning">
            <FiAlertTriangle size={18} />
            Recomendamos uma imagem do no mínimo 600x600 para melhor qualidade
          </span>
        )}
      </Title>
      <Content>
        {images.map(image => (
          <ImageComponent
            uri={image.url}
            key={image.url}
            className={image.warning ? 'warning' : ''}
          >
            {image.loading && (
              <div className="loading">
                <Loading color="primary" radius={32} stroke={3} />
              </div>
            )}
            <span>
              <ImageButton onClick={() => handleDelete(image)}>
                <FiTrash size={18} />
              </ImageButton>
            </span>
          </ImageComponent>
        ))}
        {images.length < 5 && (
          <>
            <Add
              ref={inputRef}
              id="add-image"
              accept="image/x-png,image/jpeg"
              onChange={handleAddChange}
              multiple
            />
            <label htmlFor="add-image">
              <FiPlus size={28} />
            </label>
          </>
        )}
      </Content>
    </Container>
  );
};

export default forwardRef(MultipleHistoryImageInput);
