import styled from 'styled-components';

export const Wrapper = styled.main`
  overflow-y: auto;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 24px 0px 0px 32px;
  background: #f4f4f6;
  gap: 30px;

  @media (max-width: 600px) {
    gap: 10px;
    padding: 10px 0px 5px 16px;
  }
`;

export const Buttons = styled.div`
  padding-right: 32px;
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media (max-width: 1024px) {
    h1 {
      display: none;
    }
    justify-content: end;
  }

  .btns {
    display: flex;
    gap: 10px;
    @media (max-width: 600px) {
      flex-wrap: wrap;
      justify-content: center;
      padding-right: 16px;
    }
  }
`;

export const GridInfos = styled.div`
  gap: 20px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  padding-bottom: 32px;
  padding-right: 32px;
  @media (max-width: 600px) {
    padding-right: 16px;
  }
`;

export const NotCashier = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  text-align: center;
  place-content: center;
  margin-bottom: 25rem;
  justify-content: center;
  align-items: center;
  gap: 2rem;
  h2 {
    place-content: center;
  }
  button {
    border: none;
    background-color: transparent;
    color: ${({ theme }) => theme.palette.primary};
    text-decoration: underline;
    font-size: 20px;
    cursor: pointer;
  }
`;

export const Receipt = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  padding: 24px;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
  p {
    color: ${({ theme }) => theme.palette.text};
  }
  .title {
    font-size: 0.8rem;
    margin-bottom: 1rem;
  }
`;

export const DoubleReceipt = styled.div`
  display: flex;
  width: 105%;
  flex-direction: column;
  gap: 20px;
  @media (max-width: 700px) {
    width: 100%;
  }
`;
export const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;

  @media (max-width: 500px) {
    grid-template-columns: 1fr;
    gap: 0.5rem;
  }
`;
export const ReceiptItem = styled.div<{ doubleLine?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* width: ${({ doubleLine = false }) => (doubleLine ? '49%' : '100%')}; */
  div {
    gap: 0.4rem;
    display: flex;
    align-items: center;
    @media (max-width: 1024px) {
      gap: 0.2px;
    }
  }

  img {
    width: 30px;
    height: 30px;
  }
  @media (max-width: 1024px) {
    p {
      font-size: 14px;
    }
  }
  .operator-date {
    gap: 2px;
    p {
      font-size: 0.8rem;
    }
    @media (max-width: 600px) {
      max-width: 130px;
      gap: 1px;
      p {
        font-size: 0.5rem;
        white-space: nowrap; /* Não permite quebrar linha */
        overflow: hidden; /* Esconde o texto que ultrapassa o limite do container */
        text-overflow: ellipsis;
      }
    }
  }
`;

export const Grid2 = styled.div`
  display: flex;
  width: 100%;
  gap: 20px;
  align-items: flex-start;
  @media (max-width: 700px) {
    flex-direction: column;
  }
`;

export const Movment = styled.p<{ type?: number }>`
  display: flex;
  color: ${({ type, theme }) =>
    type === 0 ? theme.palette.primary : theme.palette.success} !important;
`;

export const Totalizer = styled.p<{ negative: boolean; border?: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: end;
  color: ${({ negative, theme }) =>
    negative ? theme.palette.primary : theme.palette.text} !important;
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: ${({ theme, border = true }) =>
    border && `1px solid ${theme.palette.outline}`};
  font-weight: 500;
`;

export const GroupSeller = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  .category {
    border-bottom: 0.5px solid ${({ theme }) => theme.palette.outline};
  }
`;

export const ProductSeller = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 0.5rem;

  .label {
    grid-column: span 3;
    grid-row: span 1;
    font-size: 1rem;
    font-weight: 400;
  }

  .center {
    justify-self: center;
  }
  .end {
    justify-self: flex-end;
  }

  span {
    font-size: 14px;
    color: ${({ theme }) => theme.palette.text_light};
  }
`;

export const CanceledOrder = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0;
  border-bottom: 0.5px solid ${({ theme }) => theme.palette.outline};

  div {
    display: flex;
    flex-direction: column;
    span {
      font-size: 14px;
      color: ${({ theme }) => theme.palette.text_light};
    }
  }
`;
