import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  max-height: 50px;
  min-height: 0px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  border-radius: 8px;
`;
export const Label = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  max-height: 50px;
  min-height: 0px;
  width: 40%;
  background-color: ${({ theme }) => theme.palette.primary};
  border-radius: 8px 8px 0px 8px;
  color: ${({ theme }) => theme.palette.text_white};
  overflow-wrap: break-word;
  text-align: center;
  cursor: pointer;
  @media (max-width: 1280px) {
    font-size: 14px;
  }
`;
export const Input = styled.input`
  display: flex;
  place-content: center;
  height: auto;
  max-height: 50px;
  min-height: 0px;
  width: 60%;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background_white};
  border: none;
  padding-left: 5px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
