import React, { useCallback, useEffect, useState } from 'react';
import { FiCheck, FiTrash, FiX } from 'react-icons/fi';
import { ActiveButton, Container, Info } from './styles';
import { IWaiter } from '../../models/IWaiter';
import IconButton from '../IconButton';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { useWaiter } from '../../hooks/waiter';

interface ICardWaitersProps {
  waiter: IWaiter;
  onClick: () => void;
  setWaiters: React.Dispatch<React.SetStateAction<IWaiter[]>>;
}

export const CardWaiters: React.FC<ICardWaitersProps> = ({
  waiter,
  onClick,
  setWaiters,
}) => {
  const { ActiveDeactiveWaiter, deleteWaiter, getWaiters } = useWaiter();
  const [waiterState, setWaiterState] = useState<IWaiter>(waiter);
  const { showConfirmDialog } = useConfirmDialog();

  useEffect(() => {
    setWaiterState(waiter);
  }, [waiter]);

  const handleDelete = useCallback(() => {
    const fetchWaiters = async () => {
      const waitersData = await getWaiters();
      setWaiters(waitersData);
    };

    showConfirmDialog({
      title: 'Remover Atewndente',
      message: `Deseja realmente remover o atendente ${waiter?.name}?`,
      onCancel: () => null,
      onConfirm: async () => {
        await deleteWaiter(Number(waiter?.id));
        fetchWaiters();
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    deleteWaiter,
    getWaiters,
    setWaiters,
    showConfirmDialog,
    waiter.id,
    waiter.name,
  ]);

  return (
    <Container onClick={onClick}>
      <Info>
        <p>{waiterState.name}</p>
      </Info>
      <IconButton
        icon={<FiTrash size={22} />}
        onClick={e => {
          e.stopPropagation();
          handleDelete();
        }}
      />
      <ActiveButton
        active={waiterState.active ? 'true' : 'false'}
        onClick={e => {
          e.stopPropagation();
          setWaiterState(prev => ({ ...prev, active: !prev.active }));
          ActiveDeactiveWaiter(waiterState);
        }}
      >
        <p>{waiterState.active ? <FiCheck /> : <FiX />}</p>
        <p>{waiterState.active ? 'Ativo' : 'Inativo'}</p>
        <div>
          {waiterState.active ? <FiCheck size={36} /> : <FiX size={36} />}
        </div>
      </ActiveButton>
    </Container>
  );
};
