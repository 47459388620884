import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiArrowLeft, FiArrowRight, FiCheck, FiTrash } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';
import {
  Slice,
  Resume,
  Wrapper,
  Container,
  FlexColumn,
  BtnFlavours,
  PizzaPreview,
  GridFlavours,
  ModalWrapper,
  ItemAdditional,
  GridAdditional,
  PizzaPreviewLine,
  ContainerButtons,
  ContainerAdditionals,
} from './styles';

import { useCompany } from '../../../../hooks/company';
import { formatToMoney } from '../../../../utils/format';
import { calcPizzaValue } from '../../../../utils/calcPizzaValue';

import ICategory from '../../../../models/ICategory';
import { PizzaCharges } from '../../../../enums/pizza';
import IComplement from '../../../../models/IComplement';
import { IProductSell } from '../../../../models/IFastSell';
import IComplementsGroup from '../../../../models/IComplementsGroup';

import theme from '../../../../styles/themes/light';

import Edge from '../Edge';
import Crust from '../Crust';
import Flavours from '../Flavours';
import Additionals from '../Additionals';
import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import ComplementsPizza from '../ComplementsPizza';
import AmountInput from '../../../../components/AmountInput';
import TextAreaObservation from '../../../../components/TextAreaObservation';

interface IPizzaSizesProps {
  category: ICategory;
  editPizza?: IProductSell;
  pizzaToCart: IProductSell;
  allProducts: IProductSell[];
  handleOnClose: () => void;
  setPizzaToCart: React.Dispatch<React.SetStateAction<IProductSell>>;
  setProductsToGrid: React.Dispatch<React.SetStateAction<IProductSell[]>>;
}

const PizzaSizes: React.FC<IPizzaSizesProps> = ({
  category,
  editPizza,
  pizzaToCart,
  allProducts,
  handleOnClose,
  setPizzaToCart,
  setProductsToGrid,
}) => {
  const { company } = useCompany();
  const targetElementRef = useRef<HTMLDivElement>(null);

  const [modalError, setModalError] = useState(false);
  const [edges, setEdges] = useState<IComplement[]>([]);
  const [nextStep, setNextStep] = useState<0 | 1 | 2>(0);
  const [crusts, setCrusts] = useState<IComplement[]>([]);
  const [openAdditional, setOpenAdditional] = useState(false);
  const [flavours, setFlavours] = useState<IComplement[]>([]);
  const [complementsGroups, setComplementsGroups] = useState<
    IComplementsGroup[]
  >([]);

  const selectedSize = category?.pizzaSizes?.find(
    ps => ps.id === pizzaToCart?.pizzaSizeId,
  );

  const totalFlavEdit = useMemo(() => {
    return editPizza?.complementsGroups
      .filter(cp => cp.id === -8)[0]
      .complements.reduce((total, flavour) => {
        if (flavour.checked || (flavour.amount ?? 0) > 0) {
          return total + (flavour.amount ?? 0); // Se o sabor estiver selecionado ou tiver amount > 0, adiciona o amount
        }
        return total;
      }, 0);
  }, [editPizza]); // Recalcula somente se 'flavours' mudar

  const [flavoursQty, setFlavoursQty] = useState(
    editPizza ? totalFlavEdit ?? 2 : 2,
  );

  const totalComplement = flavours.reduce(
    (sum, comple) => sum + (comple.amount || 0),
    0,
  );

  const checkPizzaRule = () => {
    let rule: PizzaCharges = company?.pizzaCharge ?? PizzaCharges.AVERAGE;
    if (rule === 'CUSTOM') {
      const customRule = company?.pizzaChargeCustom?.rules.filter(
        r => r.minAmount <= flavoursQty,
      );
      if ((customRule ?? [])?.length > 0) {
        rule =
          (customRule ?? [])[(customRule?.length ?? 0) - 1].charge ===
          PizzaCharges.AVERAGE
            ? PizzaCharges.AVERAGE
            : PizzaCharges.GREATER;
      } else {
        rule =
          company?.pizzaChargeCustom?.defaultCharge === PizzaCharges.AVERAGE
            ? PizzaCharges.AVERAGE
            : PizzaCharges.GREATER;
      }
    }
    if (rule === 'GREATER') {
      const topUnitPrice = Math.max(
        ...flavours
          ?.filter(comp => (comp?.amount ?? 0) > 0)
          .map(compl => compl.unitPrice),
      );

      return flavours
        ?.filter(comp => (comp?.amount ?? 0) > 0)
        .map(c => ({
          ...c,
          unitPrice: topUnitPrice,
        }));
    }
    if (rule === 'AVERAGE') {
      return flavours?.filter(comp => (comp?.amount ?? 0) > 0);
    }

    return [] as IComplement[];
  };

  const complementEdge = edges.filter(edge => edge.checked);
  const complementCrust = crusts?.filter(crust => crust.checked);
  const complementFlavours = checkPizzaRule();

  const finishReleased =
    nextStep && !!complementCrust[0] && !!complementCrust[0].disableEdge
      ? true
      : !!complementEdge[0];

  const complementGroupFlavour: IComplementsGroup = {
    id: -8,
    totalAmount: flavoursQty,
    complements: complementFlavours,
    title: 'Sabores',
    isActive: true,
    minAmount: 1,
    maxAmount: flavoursQty,
    position: 0,
    deleted: false,
    category: 'PIZZA',
    modules: '',
  };
  const complementGroupEdge: IComplementsGroup = {
    id: -10,
    totalAmount: 1,
    complements: complementEdge,
    title: 'Borda',
    isActive: true,
    minAmount: 1,
    maxAmount: 1,
    position: 1,
    deleted: false,
    category: 'PIZZA',
    modules: '',
  };
  const complementGroupCrust: IComplementsGroup = {
    id: -9,
    totalAmount: 1,
    complements: complementCrust,
    title: 'Massa',
    isActive: true,
    minAmount: 1,
    maxAmount: 1,
    position: 0,
    deleted: false,
    category: 'PIZZA',
    modules: '',
  };
  const filteredGroups: IComplementsGroup[] = complementsGroups
    .filter(group =>
      group.complements.some(
        complement => (complement?.amount ?? 0) > 0 || complement.checked,
      ),
    )
    .map(group => ({
      id: group.id,
      title: group.title,
      minAmount: group.minAmount,
      maxAmount: group.maxAmount,
      isActive: true,
      position: group.position,
      deleted: false,
      category: group.category,
      modules: group.modules,
      totalAmount: group.maxAmount,
      complements: group.complements.filter(
        comp => (comp.amount ?? 0) > 0 || comp.checked,
      ),
    }));

  const additionals: IComplementsGroup = {
    ...pizzaToCart.complementsGroups[0],
    totalAmount: pizzaToCart.complementsGroups[0]?.maxAmount,
  };

  const groups: IComplementsGroup[] = [
    ...filteredGroups,
    complementGroupFlavour,
    complementGroupCrust,
    complementGroupEdge,
    ...(additionals?.complements?.length > 0 ? [additionals] : []),
  ];

  const pizzaBody = useMemo(() => {
    return {
      ...pizzaToCart,
      isCustomPizza: true,
      pizzaSizeId: selectedSize?.id,
      pizzaCategoryId: category.id,
      hash: uuid(),
      complementsGroups: groups,
      total: calcPizzaValue({
        ...pizzaToCart,
        complementsGroups: [
          ...filteredGroups,
          complementGroupFlavour,
          complementGroupCrust,
          additionals?.complements?.length > 0 && additionals,
          !complementCrust[0]?.disableEdge && complementGroupEdge,
        ].filter(Boolean),
      }),
    };
  }, [
    groups,
    additionals,
    category.id,
    pizzaToCart,
    selectedSize,
    filteredGroups,
    complementCrust,
    complementGroupEdge,
    complementGroupCrust,
    complementGroupFlavour,
  ]);

  // utilizado para verificar os complementsGroup já selecionados caso seja um editPizza
  useEffect(() => {
    const handleChangeComplementsToSelect = () => {
      const selectedFlavours = complementGroupFlavour.complements;

      setComplementsGroups(prevComplements => {
        // Complementos válidos com base nos sabores selecionados
        const validComplements = prevComplements.filter(existingGroup =>
          selectedFlavours.some(comple =>
            comple?.complementsPizza?.some(
              pizzaComplement => pizzaComplement.id === existingGroup.id,
            ),
          ),
        );

        // Adicionar novos complementos dos sabores selecionados
        const newComplements = selectedFlavours.flatMap(
          comple =>
            comple?.complementsPizza?.filter(
              newGroup =>
                !validComplements.some(
                  existingGroup => existingGroup.id === newGroup.id,
                ),
            ) ?? [],
        );

        // Atualizar complementos já existentes com base no editPizza
        const updatedComplements = [...validComplements, ...newComplements].map(
          group => {
            // Verificar se há correspondência no editPizza
            const matchingGroup = editPizza?.complementsGroups.find(
              g => g.id === group.id,
            );

            if (matchingGroup) {
              const updatedGroupComplements = group.complements.map(
                complement => {
                  const matchingComplement = matchingGroup.complements.find(
                    c => c.id === complement.id,
                  );

                  // Atualizar propriedades se houver correspondência
                  if (
                    matchingComplement &&
                    (matchingComplement.checked ||
                      (matchingComplement.amount ?? 0) > 0)
                  ) {
                    return {
                      ...complement,
                      amount: matchingComplement.amount,
                      checked: matchingComplement.checked,
                    };
                  }

                  return complement;
                },
              );

              return { ...group, complements: updatedGroupComplements };
            }

            // Se não houver correspondência no editPizza, retornar o grupo como está
            return group;
          },
        );

        return updatedComplements;
      });
    };

    handleChangeComplementsToSelect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [flavours, selectedSize, editPizza]);

  const handleBackStep = () => {
    setNextStep(prev => (prev === 1 ? 0 : 1));
  };

  const handleDeleteAdditional = (compl: IComplement) => {
    setPizzaToCart(prev => ({
      ...prev,
      complementsGroups: prev.complementsGroups.map(group => ({
        ...group,
        complements: group.complements.filter(c => c.title !== compl.title),
      })),
    }));
  };

  const deSelectComplements = (compareValue: number) => {
    const totalFlavSelected = flavours.reduce((total, flavour) => {
      if (flavour.checked || (flavour.amount ?? 0) > 0) {
        return total + (flavour.amount ?? 0); // Se o sabor estiver selecionado ou tiver amount > 0, adiciona o amount
      }
      return total;
    }, 0);

    if ((flavoursQty ?? 1) > compareValue && totalFlavSelected > compareValue) {
      const quantityToRemoveFlavour = totalFlavSelected - compareValue;
      setFlavours(prev => {
        let removedCount = 0; // Contador de itens removidos
        return prev.map(flavour => {
          if (
            (flavour.checked || (flavour.amount ?? 0) > 0) &&
            removedCount < quantityToRemoveFlavour
          ) {
            removedCount += 1;
            return {
              ...flavour,
              checked: false,
              amount: 0,
            };
          }
          return flavour;
        });
      });
    }
  };

  const verifyComplementsGroups = () => {
    const mensagensDeErro: string[] = [];

    complementsGroups.forEach(grupo => {
      const totalSelecionado = grupo.complements.reduce(
        (soma, complement) => soma + (complement?.amount ?? 0),
        0,
      );

      if (totalSelecionado < grupo.minAmount) {
        const restante = grupo.minAmount - totalSelecionado;
        mensagensDeErro.push(
          `O grupo "${grupo.title}" requer mais ${restante} complemento(s).`,
        );
      }
    });

    return mensagensDeErro.length > 0 ? mensagensDeErro[0] : true;
  };

  const handleNextStep = () => {
    if (nextStep === 0) {
      const nextComplements = totalComplement === flavoursQty;
      if (nextComplements) {
        setNextStep(1);
      } else {
        setModalError(true);
      }
    } else if (nextStep === 1) {
      if (finishReleased) {
        setNextStep(2);
      } else {
        setModalError(true);
      }
    } else {
      const verify = complementsGroups?.map(complGroup => {
        // Soma o amount de todos os complementos marcados (checked: true)
        const checkedAmountSum = complGroup.complements
          .filter((compl: any) => compl.checked)
          .reduce((total, compl: any) => total + (compl?.amount ?? 1), 0);
        // Verifica se a soma do amount é pelo menos o valor de minAmount
        return checkedAmountSum >= complGroup.minAmount;
      });

      const hasFalse = verify?.some(cond => cond === false);

      if (hasFalse) {
        setModalError(true);
        return;
      }

      if (complementCrust[0]?.disableEdge) {
        pizzaBody.complementsGroups = pizzaBody.complementsGroups.filter(
          group => group !== complementGroupEdge,
        );
      }

      setProductsToGrid(prev => [
        ...prev.filter(prodPrev => prodPrev.id !== pizzaBody.id),
        { ...pizzaBody, id: editPizza ? pizzaBody.id : uuid() },
      ]);

      handleOnClose();
    }
  };

  return (
    <Wrapper>
      {/* first step === nextStep = 0 */}
      <div
        className="step"
        style={{ display: nextStep === 0 ? 'flex' : 'none' }}
      >
        <h3>Selecione o tamanho:</h3>
        {category?.pizzaSizes?.map(ps => {
          const PreviewLines = () => {
            if (ps.slices === 1) {
              return null;
            }

            const angleFactor = 360 / ps.slices;

            return Array.from({ length: ps.slices }).map((_, index) => {
              const rotation = angleFactor * index;
              return (
                // eslint-disable-next-line react/no-array-index-key
                <PizzaPreviewLine key={index.toString()} rotation={rotation} />
              );
            });
          };

          return (
            <Container
              selected={ps.id === selectedSize?.id}
              key={ps.id}
              onClick={() => {
                setPizzaToCart(prev => ({
                  ...prev,
                  title: `Pizza ${ps.name}`,
                  pizzaSizeId: ps.id,
                }));

                deSelectComplements(ps.flavours);

                setFlavoursQty(prev =>
                  prev <= ps.flavours
                    ? prev
                    : ps.flavours > 1
                    ? 2
                    : ps.flavours,
                );

                // rola até os sabores
                targetElementRef.current &&
                  targetElementRef.current.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start',
                  });
              }}
            >
              <PizzaPreview
                applyBackground={ps.slices > 1}
                selected={ps.id === selectedSize?.id}
              >
                {PreviewLines() || <Slice />}
              </PizzaPreview>
              <div className="infos">
                <span>{ps.name}</span>
                <p>{`${ps.slices} pedaços`}</p>
                <p>
                  {`Até ${ps.flavours} ${
                    ps.flavours > 1 ? 'sabores' : 'sabor'
                  } `}
                </p>
              </div>
            </Container>
          );
        })}

        <div
          ref={targetElementRef}
          style={{ display: selectedSize ? 'block' : 'none' }}
        >
          <GridFlavours>
            <span>Quantos Sabores:</span>
            {Array.from({ length: selectedSize?.flavours ?? 1 }, (_, index) => (
              <BtnFlavours
                key={index}
                onClick={() => {
                  setFlavoursQty(index + 1);

                  deSelectComplements(index + 1);

                  targetElementRef.current && // rola até os sabores
                    targetElementRef.current.scrollIntoView({
                      behavior: 'smooth',
                      block: 'start',
                    });
                }}
                selected={index + 1 === flavoursQty}
              >
                {index + 1}
              </BtnFlavours>
            ))}
          </GridFlavours>
          <Flavours
            editPizza={editPizza}
            flavours={flavours}
            allProducts={allProducts}
            flavoursQty={flavoursQty}
            selectedSize={selectedSize}
            setFlavours={setFlavours}
          />
        </div>
      </div>

      {/* second step === nextStep = 1 */}
      <div
        className="step"
        style={{ display: nextStep === 1 ? 'flex' : 'none' }}
      >
        <FlexColumn>
          <h3>Resumo da pizza</h3>
          <Resume>
            <p className="title">Sabor:</p>
          </Resume>
          {flavours
            .filter(comp => comp.amount)
            .map(comp => (
              <p key={comp.id}>
                {`${comp.amount}/${flavoursQty} `}
                {comp.title}
              </p>
            ))}
        </FlexColumn>

        <Crust
          editPizza={editPizza}
          crusts={crusts}
          setCrusts={setCrusts}
          selectedSize={selectedSize}
        />
        {!complementCrust[0]?.disableEdge && (
          <Edge
            edges={edges}
            setEdges={setEdges}
            editPizza={editPizza}
            selectedSize={selectedSize}
          />
        )}
      </div>

      {/* third step === nextStep = 2 */}
      <div
        className="step"
        style={{ display: nextStep === 2 ? 'flex' : 'none' }}
      >
        <FlexColumn>
          <h3>Resumo da pizza:</h3>
          <Resume>
            <p className="title">Sabor:</p>
          </Resume>
          {flavours
            .filter(comp => comp.amount)
            .map(comp => (
              <p key={comp.id}>
                {`${comp.amount}/${flavoursQty} `}
                {comp.title}
              </p>
            ))}
          {complementCrust[0]?.title && (
            <Resume>
              <p className="title">Borda:</p>
              <p>{complementCrust[0]?.title}</p>
            </Resume>
          )}
          {complementEdge[0]?.title && (
            <Resume>
              <p className="title">Massa:</p>
              <p>{complementEdge[0]?.title}</p>
            </Resume>
          )}
        </FlexColumn>

        <ComplementsPizza
          complementsGroups={complementsGroups}
          setComplementsGroups={setComplementsGroups}
        />

        <ContainerAdditionals>
          <Additionals
            open={openAdditional}
            setOpen={setOpenAdditional}
            setEditProduct={setPizzaToCart}
          />

          {pizzaToCart.complementsGroups?.map(compGroup => {
            if (compGroup.id === undefined) {
              return (
                <GridAdditional key={1}>
                  {compGroup.complements.map(compl => (
                    <ItemAdditional key={compl.title}>
                      <p>{compl.title}</p>
                      <div>
                        <p>{formatToMoney(compl.unitPrice)}</p>
                        <FiTrash
                          onClick={() => handleDeleteAdditional(compl)}
                          size={22}
                          className="delete"
                          color={theme.palette.primary}
                        />
                      </div>
                    </ItemAdditional>
                  ))}
                </GridAdditional>
              );
            }
            return <div className="none" key={compGroup.id} />;
          })}
        </ContainerAdditionals>

        <TextAreaObservation
          placeholder="Escreva as observações do produto aqui..."
          title="Observações"
          value={pizzaToCart.comments}
          handleCommentsChange={value =>
            setPizzaToCart(prev => ({ ...prev, comments: value }))
          }
        />
      </div>

      <ContainerButtons>
        <AmountInput
          value={pizzaToCart.qty}
          setValue={e => setPizzaToCart(prev => ({ ...prev, qty: e }))}
          onIncreaseAmount={() =>
            setPizzaToCart(prev => ({ ...prev, qty: prev.qty + 1 }))
          }
          onDecreaseAmount={() =>
            setPizzaToCart(prev => ({ ...prev, qty: prev.qty - 1 }))
          }
        />
        <h3>{formatToMoney(pizzaBody?.total ?? 0)}</h3>
        {window.innerWidth < 500 ? (
          <div className="btns">
            {nextStep && (
              <Button
                width="auto"
                height="auto"
                outline
                title=""
                onClick={handleBackStep}
                icon={<FiArrowLeft size={22} color="#DA123A" />}
              />
            )}
            <Button
              width="auto"
              height="auto"
              title=""
              onClick={handleNextStep}
              icon={
                nextStep === 2 ? (
                  <FiCheck size={22} color="#fff" />
                ) : (
                  <FiArrowRight size={22} color="#fff" />
                )
              }
            />
          </div>
        ) : (
          <div className="btns">
            {nextStep !== 0 && (
              <Button
                width="100px"
                height="36px"
                outline
                title="Voltar"
                onClick={handleBackStep}
                icon={<FiArrowLeft size={22} color="#DA123A" />}
              />
            )}

            <Button
              title={nextStep === 2 ? 'Lançar' : 'Próximo'}
              onClick={handleNextStep}
              icon={
                nextStep === 2 ? (
                  <FiCheck size={22} color="#fff" />
                ) : (
                  <FiArrowRight size={22} color="#fff" />
                )
              }
            />
          </div>
        )}
      </ContainerButtons>

      {modalError && (
        <Modal
          open={modalError}
          setOpen={setModalError}
          onConfirm={() => setModalError(false)}
        >
          <ModalWrapper>
            <h3>
              {!nextStep
                ? `Selecione ${
                    flavoursQty > 1 ? 'os sabores' : 'o sabor'
                  } para continuar!`
                : complementCrust.length === 0
                ? 'Selecione ao menos uma borda!'
                : complementCrust[0].disableEdge
                ? 'Complemento(s) obrigatório'
                : complementEdge.length === 0
                ? 'Selecione ao menos uma massa!'
                : verifyComplementsGroups() && 'Complemento(s) obrigatório!'}
            </h3>
            <p>
              {!nextStep
                ? `Faltam ${flavoursQty - totalComplement} ${
                    flavoursQty - totalComplement > 1 ? 'sabores' : 'sabor'
                  } á selecionar`
                : complementCrust.length === 0
                ? 'A borda é um complemento obrigatório para a criação da Pizza!'
                : complementCrust[0].disableEdge
                ? verifyComplementsGroups()
                : complementEdge.length === 0
                ? 'A Massa é um complemento obrigatório para a criação da Pizza!'
                : verifyComplementsGroups()}
            </p>
          </ModalWrapper>
        </Modal>
      )}
    </Wrapper>
  );
};
export default PizzaSizes;
