import React, { useState } from 'react';
import {
  CardValue,
  DiscountValues,
  TotalCard,
  Totals,
  Wrapper,
} from './styles';

import { formatToMoney } from '../../../../utils/format';

import ModalServiceDiscount from '../../../../components/ModalServiceDiscount';

interface ITotalizerProps {
  service: number;
  discount: number;
  addition: number;
  needToPay: number;
  totalPaid: number;
  totalValue: number;
  totalProductsValue: number;
  setService: React.Dispatch<React.SetStateAction<number>>;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  setAddition: React.Dispatch<React.SetStateAction<number>>;
}
const Totalizer: React.FC<ITotalizerProps> = ({
  service,
  addition,
  discount,
  needToPay,
  totalPaid,
  totalValue,
  totalProductsValue,
  setService,
  setDiscount,
  setAddition,
}) => {
  const [openService, setOpenService] = useState(false);
  const [openDiscount, setOpenDiscount] = useState(false);
  const [openAddition, setOpenAddition] = useState(false);
  return (
    <Wrapper>
      <TotalCard>
        <p className="total">Subtotal</p>
        <p className="valueTotal">{formatToMoney(totalProductsValue)}</p>
      </TotalCard>

      <Totals>
        <DiscountValues onClick={() => setOpenDiscount(true)}>
          <p className="name">Desconto</p>
          <p className="value">{formatToMoney(discount)}</p>
          <div className="tooltip">Clique aqui para alterar o desconto!</div>
        </DiscountValues>
        <DiscountValues onClick={() => setOpenService(true)}>
          <p className="name">Serviço</p>
          <p className="value">{formatToMoney(service)}</p>
          <div className="tooltip">
            Clique aqui para alterar a taxa de serviço!
          </div>
        </DiscountValues>
        <DiscountValues onClick={() => setOpenAddition(true)}>
          <p className="name">Acréscimo </p>
          <p className="value">{formatToMoney(addition)}</p>
          <div className="tooltip">Clique aqui para adicionar um acréscimo</div>
        </DiscountValues>
      </Totals>
      <TotalCard totalPay>
        <p className="total">Total</p>
        <p className="valueTotal">{formatToMoney(totalValue)}</p>
      </TotalCard>
      <Totals>
        <CardValue totalPay>
          <p className="name">Total Pago</p>
          <p className="value">{formatToMoney(totalPaid)}</p>
        </CardValue>

        <CardValue needToPay={needToPay > 0}>
          <p className="name">{needToPay >= 0 ? 'Falta Pagar' : 'Troco'}</p>
          <p className="value">
            {formatToMoney(needToPay >= 0 ? needToPay : -needToPay)}
          </p>
        </CardValue>
      </Totals>

      {openDiscount && (
        <ModalServiceDiscount
          open={openDiscount}
          value={discount}
          totalValue={totalProductsValue}
          setOpen={setOpenDiscount}
          setValue={setDiscount}
          type="discount"
        />
      )}
      {openService && (
        <ModalServiceDiscount
          open={openService}
          value={service}
          totalValue={totalProductsValue}
          setOpen={setOpenService}
          setValue={setService}
          type="service"
        />
      )}
      {openAddition && (
        <ModalServiceDiscount
          open={openAddition}
          value={addition}
          totalValue={totalProductsValue}
          setOpen={setOpenAddition}
          setValue={setAddition}
          type="addition"
        />
      )}
    </Wrapper>
  );
};

export default Totalizer;
