import { IProductCart } from '../models/IProduct';

export function getProductTotal(item: IProductCart): number {
  const complementsTotal = item.complementsGroups.reduce(
    (sum2, groups) =>
      sum2 +
      groups.complements.reduce(
        (sum3, complement) =>
          sum3 + (complement?.amount ?? 1) * complement.unitPrice,
        0,
      ),
    0,
  );

  const pizzaCrustsTotal =
    item?.pizzaCrusts?.reduce((sum, crust) => sum + crust.total, 0) || 0;

  const pizzaEdgesTotal =
    item?.pizzaEdges?.reduce((sum, edge) => sum + edge.total, 0) || 0;

  return item.unitPrice + complementsTotal + pizzaCrustsTotal + pizzaEdgesTotal;
}
