import React, { useCallback, useState } from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

import Toggle from 'react-toggle';

import api from '../../../services/api';
import { AuthRole } from '../../../hooks/auth';
import { useToast } from '../../../hooks/toast';
import { chooseBsFoodOrVarejo } from '../../../utils/subdomain';

import { PizzaCharges } from '../../../enums/pizza';
import { ICustomPizzaCharge } from '../../../models/IPizza';
import ICompany, { CompanyToggleOptions } from '../../../models/ICompany';

import {
  Options,
  GroupTitle,
  GroupContainer,
  GroupSubTitles,
  RadioContainer,
  ToggleContainer,
  BrandsContainer,
  GroupDescription,
  InnerGroupContainer,
  PaymentTypesContainer,
  DeliveryTypesContainer,
} from './styles';

import Radio from '../../Radio';
import CardBrand from '../../CardBrand';
import TypeSelect from '../../TypeSelect';
import CustomPizzaChargeModal from '../../CustomPizzaChargeModal';

interface IOptionsSettingsProps {
  company: ICompany | null;
  toggleOption: (
    name: CompanyToggleOptions,
    companyField?: keyof ICompany,
  ) => Promise<void>;
  saveCustomPizzaCharges: (
    customPizzaCharge: ICustomPizzaCharge,
  ) => Promise<void>;
  togglePaymentCard: (id: number, type: 'LOCAL' | 'DELIVERY') => Promise<void>;
}
const OptionsSettings: React.FC<IOptionsSettingsProps> = ({
  company,
  toggleOption,
  togglePaymentCard,
  saveCustomPizzaCharges,
}) => {
  const { addToast } = useToast();
  const [isCustomPizzaChargeModalOpen, setIsCustomPizzaChargeModalOpen] =
    useState(false);

  const handleOnCustomPizzaChargeModalClose = useCallback(() => {
    setIsCustomPizzaChargeModalOpen(false);
  }, []);

  const handleOnPizzaChargeTypeChange = useCallback(
    async (value: PizzaCharges, force = false) => {
      if (!force && value === PizzaCharges.CUSTOM) {
        setIsCustomPizzaChargeModalOpen(true);
        return;
      }

      try {
        await api.patch('/restricted/companies/pizza-charges', {
          pizzaCharge: value,
        });

        addToast({
          type: 'success',
          description: 'Método de cobrança da pizza alterado com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    },
    [addToast],
  );

  const handleOnCustomPizzaChargeModalConfirm = useCallback(
    async (customPizzaCharge: ICustomPizzaCharge) => {
      await saveCustomPizzaCharges(customPizzaCharge);
      await handleOnPizzaChargeTypeChange(PizzaCharges.CUSTOM, true);
    },
    [handleOnPizzaChargeTypeChange, saveCustomPizzaCharges],
  );
  return (
    <Options
      onSubmit={() => null}
      initialData={{
        ...company,
        ...(company?.city && { city: company?.city.name }),
        ...(company?.city && { state: company?.city.state }),
      }}
    >
      <CustomPizzaChargeModal
        charge={company?.pizzaChargeCustom}
        isOpen={isCustomPizzaChargeModalOpen}
        onClose={handleOnCustomPizzaChargeModalClose}
        onConfirm={handleOnCustomPizzaChargeModalConfirm}
      />
      <GroupContainer>
        <GroupTitle>Pagamento e Entrega</GroupTitle>
        <div id="settings-payment">
          <GroupSubTitles>Tipos de Pagamento</GroupSubTitles>
          <AuthRole disableOnly>
            <PaymentTypesContainer id="settings-payment">
              <TypeSelect source="CASH" name="Dinheiro" type="payment" />
              <TypeSelect
                source="DEBIT_CARD"
                name="Cartão de Débito"
                type="payment"
              />
              <TypeSelect
                source="CREDIT_CARD"
                name="Cartão de Crédito"
                type="payment"
              />
              <TypeSelect source="PIX" name="Pix" type="payment" />
              <TypeSelect source="PIC_PAY" name="PicPay" type="payment" />
              <TypeSelect source="AME" name="Ame" type="payment" />
              <TypeSelect source="FB_PAYMENT" name="Whatsapp" type="payment" />
              <TypeSelect source="CHECK" name="Cheque" type="payment" />
              <TypeSelect
                source="VOUCHER"
                name="Vale Refeição"
                type="payment"
              />
              <TypeSelect
                source="MONTHLY_PAYER"
                name="Mensalista"
                type="payment"
              />
            </PaymentTypesContainer>
          </AuthRole>
        </div>
        <div id="settings-delivery">
          <GroupSubTitles>Tipos de Entrega</GroupSubTitles>
          <AuthRole disableOnly>
            <DeliveryTypesContainer>
              <TypeSelect source="DELIVERY" name="Delivery" type="delivery" />
              <TypeSelect source="CHECKOUT" name="Retira" type="delivery" />
              <TypeSelect
                source="DRIVE_THRU"
                name="Drive Thru"
                type="delivery"
              />
              <TypeSelect source="ON_TABLE" name="Mesa" type="delivery" />
            </DeliveryTypesContainer>
          </AuthRole>
        </div>
        <div id="settings-delivery-area">
          <AuthRole blackList={['Employee']} disableOnly>
            <GroupSubTitles>Entrega</GroupSubTitles>
            <ToggleContainer>
              <Toggle
                icons={false}
                checked={company?.ordersAnotherCity}
                className="print-additionals-toggle"
                onChange={() => toggleOption('ordersAnotherCity')}
              />
              <span>Aceitar pedidos de outra cidade.</span>
            </ToggleContainer>
            <ToggleContainer>
              <Toggle
                icons={false}
                checked={company?.singleZipCode}
                className="single-zip-toggle"
                onChange={() => toggleOption('singleZipCode')}
              />
              <span>CEP único.</span>
            </ToggleContainer>
          </AuthRole>
        </div>
        <div id="settings-print">
          <GroupSubTitles>Impressão</GroupSubTitles>
          <ToggleContainer>
            <AuthRole blackList={['Employee']} disableOnly>
              <Toggle
                icons={false}
                checked={company?.showAdditionalsInPrint}
                className="print-additionals-toggle"
                onChange={() => toggleOption('showAdditionalsInPrint')}
              />
            </AuthRole>
            <span>Mostrar adicionais na impressão do cliente.</span>
          </ToggleContainer>
          <ToggleContainer>
            <AuthRole blackList={['Employee']} disableOnly>
              <Toggle
                icons={false}
                checked={company?.isPrintSummedUp}
                className="print-additionals-toggle"
                onChange={() => toggleOption('isPrintSummedUp')}
              />
            </AuthRole>
            <span>Impressão resumida.</span>
          </ToggleContainer>
          <ToggleContainer>
            <AuthRole blackList={['Employee']} disableOnly>
              <Toggle
                icons={false}
                checked={company?.orderPrintInvertComplements}
                className="print-additionals-toggle"
                onChange={() => toggleOption('orderPrintInvertComplements')}
              />
            </AuthRole>
            <span>
              Inverter ordem de exibição de complementos na impressão.
            </span>
          </ToggleContainer>
          <ToggleContainer>
            <AuthRole blackList={['Employee']} disableOnly>
              <Toggle
                icons={false}
                checked={company?.showPrintComplementsTitle}
                className="print-kitchen-layout"
                onChange={() => toggleOption('showPrintComplementsTitle')}
              />
            </AuthRole>
            <span>Exibir título dos complementos na impressão.</span>
          </ToggleContainer>
          <ToggleContainer>
            <AuthRole blackList={['Employee']} disableOnly>
              <Toggle
                icons={false}
                checked={company?.printKitchenLayout}
                className="print-kitchen-layout"
                onChange={() => toggleOption('printKitchenLayout')}
              />
            </AuthRole>
            <span>Exibir impressão da cozinha por padrão.</span>
          </ToggleContainer>
        </div>
        <div id="settings-show-card-brands">
          <GroupSubTitles>Bandeiras</GroupSubTitles>
          <ToggleContainer>
            <AuthRole blackList={['Employee']} disableOnly>
              <Toggle
                icons={false}
                checked={company?.showCardBrands}
                className="show-card-brands-toggle"
                onChange={() => toggleOption('showCardBrands')}
              />
            </AuthRole>
            <span>Mostrar bandeiras de cartões aceitas.</span>
          </ToggleContainer>
        </div>
      </GroupContainer>
      <GroupContainer id="settings-menu">
        <GroupTitle>{chooseBsFoodOrVarejo('Cardápio', 'Catálogo')}</GroupTitle>
        <GroupSubTitles>Exibição</GroupSubTitles>
        <ToggleContainer>
          <AuthRole blackList={['Employee']} disableOnly>
            <Toggle
              icons={false}
              checked={company?.enablesSubcategories}
              className="enable-subcategories-toggle"
              onChange={() => toggleOption('enablesSubcategories')}
            />
          </AuthRole>
          <span>Habilita agrupamento de subcategorias.</span>
        </ToggleContainer>
        <ToggleContainer>
          <AuthRole blackList={['Employee']} disableOnly>
            <Toggle
              icons={false}
              checked={company?.enablesInternalCode}
              className="enable-internalcode-toggle"
              onChange={() => toggleOption('enablesInternalCode')}
            />
          </AuthRole>
          <span>Habilita o código interno no menu</span>
        </ToggleContainer>
        <ToggleContainer>
          <AuthRole blackList={['Employee']} disableOnly>
            <Toggle
              icons={false}
              checked={company?.showTopSellingProducts}
              className="show-top-selling-products"
              onChange={() => toggleOption('showTopSellingProducts')}
            />
          </AuthRole>
          <span>Mostrar produtos mais vendidos</span>
        </ToggleContainer>
        <GroupSubTitles>Pedidos sem Login</GroupSubTitles>
        <ToggleContainer>
          <AuthRole blackList={['Employee']} disableOnly>
            <Toggle
              icons={false}
              checked={company?.acceptsUnauthedOrders}
              className="accepts-unauthed-orders-toggle"
              onChange={() => toggleOption('acceptsUnauthedOrders')}
            />
          </AuthRole>
          <span>Habilita pedidos sem autenticação.</span>
        </ToggleContainer>
        <GroupSubTitles>Nota Fiscal</GroupSubTitles>
        <ToggleContainer>
          <AuthRole blackList={['Employee']} disableOnly>
            <Toggle
              icons={false}
              checked={company?.showMenuDocumentInput}
              className="show-document-input"
              onChange={() => toggleOption('showMenuDocumentInput')}
            />
          </AuthRole>
          <span>Habilita campo de CPF na nota</span>
        </ToggleContainer>
        {!company?.subscription?.plan?.showCase && (
          <InnerGroupContainer>
            <GroupSubTitles>Modo demonstração</GroupSubTitles>
            <GroupDescription>
              O modo demonstração apenas exibe os itens, não permitindo pedidos.
            </GroupDescription>
            <ToggleContainer>
              <AuthRole blackList={['Employee']} disableOnly>
                <Toggle
                  icons={false}
                  checked={company?.showcaseDelivery}
                  onChange={() => toggleOption('showcaseDelivery')}
                  className="enable-showcase-delivery-toggle"
                />
              </AuthRole>
              <span>Demonstração no delivery</span>
            </ToggleContainer>
            <ToggleContainer>
              <AuthRole blackList={['Employee']} disableOnly>
                <Toggle
                  icons={false}
                  checked={company?.showcaseTable}
                  onChange={() => toggleOption('showcaseTable')}
                  className="enable-showcase-table-toggle"
                />
              </AuthRole>
              <span>Demonstração na mesa</span>
            </ToggleContainer>
          </InnerGroupContainer>
        )}
        <InnerGroupContainer>
          <GroupSubTitles>Pizza</GroupSubTitles>
          <GroupDescription>
            Selecione o modo de cobrança referente as pizzas de múltiplos
            sabores.
          </GroupDescription>
          <AuthRole disableOnly>
            <RadioContainer
              name="pizzaChargeType"
              onChange={value =>
                handleOnPizzaChargeTypeChange(value as PizzaCharges)
              }
            >
              <Radio.Option
                value={PizzaCharges.AVERAGE}
                defaultChecked={company?.pizzaCharge === PizzaCharges.AVERAGE}
              >
                Valor médio
                <MdRadioButtonUnchecked size={20} className="unchecked" />
                <MdRadioButtonChecked size={20} className="checked" />
              </Radio.Option>
              <Radio.Option
                value={PizzaCharges.GREATER}
                defaultChecked={company?.pizzaCharge === PizzaCharges.GREATER}
              >
                Maior valor
                <MdRadioButtonUnchecked size={20} className="unchecked" />
                <MdRadioButtonChecked size={20} className="checked" />
              </Radio.Option>
              <Radio.Option
                value={PizzaCharges.CUSTOM}
                defaultChecked={company?.pizzaCharge === PizzaCharges.CUSTOM}
              >
                Customizado
                <MdRadioButtonUnchecked size={20} className="unchecked" />
                <MdRadioButtonChecked size={20} className="checked" />
              </Radio.Option>
            </RadioContainer>
          </AuthRole>
        </InnerGroupContainer>
      </GroupContainer>
      <GroupContainer id="settings-card-brands">
        <GroupTitle>Bandeiras aceitas</GroupTitle>
        <BrandsContainer>
          {company?.paymentCards.map(brand => (
            <CardBrand
              brand={brand}
              key={brand.paymentCardId}
              onChange={togglePaymentCard}
            />
          ))}
        </BrandsContainer>
      </GroupContainer>
    </Options>
  );
};

export default OptionsSettings;
