import React from 'react';
import { ThemeProvider } from 'styled-components';
import lightTheme from '../styles/themes/light';

import { TeamProvider } from './team';
import { AuthProvider } from './auth';
import { CardsProvider } from './card';
import { TableProvider } from './table';
import { StockProvider } from './stock';
import { AudioProvider } from './audio';
import { ToastProvider } from './toast';
import { WaiterProvider } from './waiter';
import { OrdersProvider } from './orders';
import { TablesProvider } from './tables';
import { StatusProvider } from './status';
import { ClientProvider } from './clients';
import { CompanyProvider } from './company';
import { DevicesProvider } from './devices';
import { SidebarProvider } from './sidebar';
import { MessageProvider } from './messages';
import { BsTicketProvider } from './bsticket';
import { OverviewProvider } from './overview';
import { ProductsProvider } from './products';
import { OperatorsProvider } from './operators';
import { CategoriesProvider } from './categories';
import { CashDrawerProvider } from './cash_drawers';
import { ComplementsProvider } from './complements';
import { ProductsCartProvider } from './products_sell';
import { ConfirmDialogProvider } from './confim_dialog';
import { ProductGroupsProvider } from './product_groups';
import { CashierReportProvider } from './cashier_report';
import { CashierOrdersProvider } from './cashier_orders';
import { InternalOrdersProvider } from './internal_orders';
import { DiscountsCouponsProvider } from './discounts_coupons';
import { ScheduledProductsProvider } from './scheduled_products';

const AppProvider: React.FC = ({ children }) => (
  <ThemeProvider theme={lightTheme}>
    <ToastProvider>
      <SidebarProvider>
        <ConfirmDialogProvider>
          <AudioProvider>
            <CompanyProvider>
              <MessageProvider>
                <OperatorsProvider>
                  <TeamProvider>
                    <DevicesProvider>
                      <AuthProvider>
                        <ScheduledProductsProvider>
                          <CategoriesProvider>
                            <ProductsProvider>
                              <ProductGroupsProvider>
                                <BsTicketProvider>
                                  <StockProvider>
                                    <ComplementsProvider>
                                      <DiscountsCouponsProvider>
                                        <TablesProvider>
                                          <OrdersProvider>
                                            <CashierReportProvider>
                                              <OverviewProvider>
                                                <StatusProvider>
                                                  <WaiterProvider>
                                                    <ProductsCartProvider>
                                                      <CardsProvider>
                                                        <TableProvider>
                                                          <InternalOrdersProvider>
                                                            <CashDrawerProvider>
                                                              <CashierOrdersProvider>
                                                                <ClientProvider>
                                                                  {children}
                                                                </ClientProvider>
                                                              </CashierOrdersProvider>
                                                            </CashDrawerProvider>
                                                          </InternalOrdersProvider>
                                                        </TableProvider>
                                                      </CardsProvider>
                                                    </ProductsCartProvider>
                                                  </WaiterProvider>
                                                </StatusProvider>
                                              </OverviewProvider>
                                            </CashierReportProvider>
                                          </OrdersProvider>
                                        </TablesProvider>
                                      </DiscountsCouponsProvider>
                                    </ComplementsProvider>
                                  </StockProvider>
                                </BsTicketProvider>
                              </ProductGroupsProvider>
                            </ProductsProvider>
                          </CategoriesProvider>
                        </ScheduledProductsProvider>
                      </AuthProvider>
                    </DevicesProvider>
                  </TeamProvider>
                </OperatorsProvider>
              </MessageProvider>
            </CompanyProvider>
          </AudioProvider>
        </ConfirmDialogProvider>
      </SidebarProvider>
    </ToastProvider>
  </ThemeProvider>
);

export default AppProvider;
