import React, { Dispatch, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';
import { RiExchangeDollarLine } from 'react-icons/ri';
import { ModalBody, ModalWrapper, Wrapper } from './styles';

import money from '../../../../assets/money.png';
import nomoney from '../../../../assets/no-money.png';
import scanner from '../../../../assets/scanner.png';
import { formatToMoney } from '../../../../utils/format';

import IClient from '../../../../models/IClient';
import { IProductSell } from '../../../../models/IFastSell';
import { ITransaction } from '../../../../models/IPayment';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import {
  ISendOrderProps,
  useCashierOrders,
} from '../../../../hooks/cashier_orders';
import { useConfirmDialog } from '../../../../hooks/confim_dialog';
import SalesCoupon, { ISalesCouponPrintRef } from '../SalesCoupon';

interface IFinishButtonProps {
  cartId: string;
  client: IClient;
  service: number;
  subTotal: number;
  discount: number;
  needToPay: number;
  totalValue: number;
  totalPayment: number;
  products: IProductSell[];
  transactions: ITransaction[];
  setCartId: Dispatch<React.SetStateAction<string>>;
  setService: React.Dispatch<React.SetStateAction<number>>;
  setDiscount: React.Dispatch<React.SetStateAction<number>>;
  setCustomer: React.Dispatch<React.SetStateAction<IClient>>;
  setAddition: React.Dispatch<React.SetStateAction<number>>;
  setProductsToGrid: React.Dispatch<React.SetStateAction<IProductSell[]>>;
  setTransactions: React.Dispatch<React.SetStateAction<ITransaction[]>>;
}
const FinishButton: React.FC<IFinishButtonProps> = ({
  cartId,
  client,
  service,
  products,
  discount,
  subTotal,
  needToPay,
  totalValue,
  transactions,
  totalPayment,
  setCartId,
  setService,
  setAddition,
  setCustomer,
  setDiscount,
  setTransactions,
  setProductsToGrid,
}) => {
  const { sendOrder } = useCashierOrders();
  const { ThrowError } = useConfirmDialog();
  const reportRef = useRef<ISalesCouponPrintRef>(null);

  const [open, setOpen] = useState(false);
  const [openCupom, setOpenCupom] = useState(false);

  const handleFinish = async () => {
    if (totalPayment >= totalValue && products?.length > 0) {
      try {
        const body: ISendOrderProps = {
          ...(client?.id && { customerId: Number(client.id) }),
          ...(client?.name && { customerName: client.name }),
          ...(discount && { discount }),
          ...(service && { serviceFee: service }),
          total: totalValue,
          subtotal: subTotal,
          items: products,
          paymentsTransactions: transactions,
        };
        await sendOrder(body, cartId);

        setOpenCupom(true);
        setOpen(false);
      } catch (error) {
        ThrowError(error, 'Erro ao enviar venda!');
      }
    } else {
      setOpen(false);
    }
  };

  const handlePrintCoupon = () => {
    reportRef?.current?.print();
    setOpenCupom(false);
    setService(0);
    setDiscount(0);
    setAddition(0);
    setCartId(uuid());
    setTransactions([]);
    setProductsToGrid([]);
    setCustomer({} as IClient);
  };
  const handleNotPrintCoupon = () => {
    setOpenCupom(false);
    setService(0);
    setDiscount(0);
    setAddition(0);
    setCartId(uuid());
    setTransactions([]);
    setProductsToGrid([]);
    setCustomer({} as IClient);
  };

  const order: ISendOrderProps = {
    items: products,
    customerName: client.name,
    total: totalValue,
    paymentsTransactions: transactions,
    subtotal: subTotal,
    discount,
    serviceFee: service,
    change: totalValue - totalPayment,
  };

  return (
    <Wrapper>
      <Button
        className="finalize"
        icon={<RiExchangeDollarLine size={30} />}
        title="Finalizar"
        onClick={() => setOpen(true)}
      />
      {open && (
        <Modal open={open} setOpen={setOpen} onConfirm={handleFinish}>
          <ModalWrapper>
            <h3>Finalizar pagamento</h3>
            <ModalBody>
              {products?.length === 0 && (
                <h4>Não há nenhum produto na venda!</h4>
              )}
              {totalPayment > totalValue && (
                <h4>{`Troco no valor de ${formatToMoney(-needToPay)}`}</h4>
              )}
              {totalPayment < totalValue && (
                <h4>
                  {`Pagamento imcompleto, restando á pagar ${formatToMoney(
                    needToPay,
                  )}`}
                </h4>
              )}

              <img
                src={
                  products?.length === 0
                    ? nomoney
                    : totalPayment >= totalValue
                    ? money
                    : nomoney
                }
                alt=""
              />
              {products?.length === 0 ? (
                <p>Não há produtos há pagar, lance-os e de continuidade!</p>
              ) : totalPayment >= totalValue ? (
                <p>Deseja finalizar a etapa de pagamento?</p>
              ) : (
                <p>Para finalizar faça o restante do pagamento!</p>
              )}
            </ModalBody>
          </ModalWrapper>
        </Modal>
      )}

      {openCupom && (
        <Modal
          open={openCupom}
          setOpen={setOpenCupom}
          onConfirm={handlePrintCoupon}
          onCancel={handleNotPrintCoupon}
        >
          <ModalWrapper>
            <h3>Imprimir Cupom</h3>
            <ModalBody>
              <img src={scanner} alt="" />

              <p>Deseja imprimir o cupom não fiscal?</p>
            </ModalBody>
          </ModalWrapper>
        </Modal>
      )}
      <SalesCoupon ref={reportRef} order={order} />
    </Wrapper>
  );
};

export default FinishButton;
