export default interface IClient {
  id?: string;
  name: string;
  phoneNumber?: string;
  document?: string;
  isManualRegister: boolean;
  // email: string;
  // addresses?: IAddress[];
}

export const defaultClient = {
  name: '',
  phoneNumber: '',
  document: '',
  isManualRegister: true,
  // email: '',
  // addresses: [],
};
