import styled from 'styled-components';

export const WrapperModal = styled.div<{ error: boolean }>`
  display: flex;
  flex-direction: column;
  padding-bottom: 32px;

  p {
    font-size: 14px;
  }

  .title {
    width: 100%;
    display: flex;
    text-align: center;
    place-content: center;
  }

  .error {
    color: ${({ theme }) => theme.palette.primary};
  }

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.primary : theme.palette.outline};
    height: 40px;
  }
`;
