import React, { ReactElement } from 'react';
import { StyledButton } from './styles';

interface IButtonProps extends React.HTMLAttributes<HTMLDivElement> {
  title?: string;
  bg?: string;
  color?: string;
  width?: string;
  height?: string;
  border?: boolean;
  side?: 'L' | 'R' | 'T' | 'B';
  outline?: boolean;
  icon?: ReactElement;
}

const Button: React.FC<IButtonProps> = ({
  bg,
  icon,
  title,
  side = 'R',
  color = 'white',
  width = '150px',
  border = true,
  height,
  outline = false,
  ...rest
}) => {
  return (
    <StyledButton
      bg={bg}
      side={side}
      color={color}
      width={width}
      height={height}
      border={border}
      outline={outline}
      {...rest}
    >
      {icon}
      {title && <p>{title}</p>}
    </StyledButton>
  );
};

export default Button;
