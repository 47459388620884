import React, { forwardRef, useCallback } from 'react';
import {
  Name,
  Price,
  Complement,
  InputContainer,
  ComplementsWrapper,
  ComplementContainer,
} from './styles';

import CheckboxComplement from '../../../../components/CheckboxComplement';
import { useProducts } from '../../../../hooks/products';
import IComplementsGroup from '../../../../models/IComplementsGroup';
import { IProductSell } from '../../../../models/IFastSell';
import IComplement from '../../../../models/IComplement';
import AmountInput from '../../../../components/AmountInput';

interface IComplementsCartSell {
  complementGroup: IComplementsGroup;
  setNextComplement: React.Dispatch<React.SetStateAction<number>>;
  index: number;
  setEditProduct: React.Dispatch<React.SetStateAction<IProductSell>>;
}
type IInputType = 'checkbox' | 'radio' | 'increase' | 'decrease';

const ComplementsCartSell = forwardRef<HTMLDivElement, IComplementsCartSell>(
  ({ complementGroup, index, setEditProduct, setNextComplement }, ref) => {
    const { calcProductPrice } = useProducts();
    const isRadioButton =
      complementGroup?.minAmount === 1 && complementGroup?.maxAmount === 1;

    const numberButton = complementGroup.complements.some(
      complementGroup => (complementGroup?.maxAmount || 0) > 1,
    );
    const isCheckbox = !isRadioButton && !numberButton;

    const handleSelectComplement = useCallback(
      (
        complementGroup: IComplementsGroup,
        complement: IComplement,
        type: IInputType,
      ) => {
        setEditProduct(prevProduct => {
          if (!prevProduct) return prevProduct;

          const updatedProduct: IProductSell = {
            ...prevProduct,
            complementsGroups: prevProduct?.complementsGroups?.map(group => {
              if (group.id === complementGroup.id) {
                const updatedComplements = group.complements.map(
                  (item: any) => {
                    switch (type) {
                      case 'checkbox':
                        return item.id === complement.id
                          ? {
                              ...item,
                              checked: !item.checked,
                              amount: item?.amount === 1 ? 0 : 1,
                            }
                          : item;
                      case 'increase':
                        return item.id === complement.id
                          ? {
                              ...item,
                              amount: (item.amount ?? 0) + 1,
                              checked: true,
                            }
                          : item;
                      case 'decrease':
                        return item.id === complement.id
                          ? {
                              ...item,
                              amount: (item.amount ?? 0) - 1,
                              checked: item.amount !== 1,
                            }
                          : item;
                      default:
                        // radio
                        return item.id === complement.id
                          ? { ...item, checked: true, amount: 1 }
                          : { ...item, checked: false, amount: 0 };
                    }
                  },
                );
                const isGroupChecked = updatedComplements.some(
                  comp => comp.checked,
                );

                return {
                  ...group,
                  complements: updatedComplements,
                  checked: isGroupChecked, // Atualiza o checked do grupo
                };
              }
              return group;
            }),
          };

          // Calcula o novo preço do produto
          const newTotalPrice = calcProductPrice(updatedProduct);

          // Atualiza o selectedProduct com o novo preço
          return {
            ...updatedProduct,
            total: newTotalPrice, // Supondo que 'price' seja a propriedade que guarda o preço total do produto
          };
        });
      },
      [calcProductPrice, setEditProduct],
    );
    return (
      <ComplementsWrapper ref={ref}>
        <Name>
          <h4>
            {complementGroup?.displayName
              ? complementGroup?.displayName
              : complementGroup?.title}
          </h4>
          {complementGroup?.maxAmount > 1 && (
            <p>{`(até ${complementGroup?.maxAmount} itens)`}</p>
          )}
          {complementGroup?.minAmount > 0 && (
            <p className="obg">
              {/* eslint-disable-next-line */}
              Obrigatório ({complementGroup?.minAmount})
            </p>
          )}
          {complementGroup?.minAmount === 0 && <p className="opc">Opcional</p>}
        </Name>

        {/* caso seja radiobox */}
        {isRadioButton && (
          <ComplementContainer>
            {complementGroup?.complements.map((comp: any) => {
              const isDisabled = complementGroup.complements.some(
                (otherComp: any) =>
                  otherComp.checked && otherComp.id !== comp.id,
              );
              return (
                <Complement key={comp?.id} disabled={isDisabled}>
                  <p>{comp?.title ? comp?.title : comp?.description}</p>
                  {comp?.unitPrice > 0 && (
                    <Price>
                      <p>
                        R$
                        {comp?.unitPrice.toFixed(2).replace('.', ',')}
                      </p>
                    </Price>
                  )}

                  <CheckboxComplement
                    id={`${complementGroup.id}-${comp.id}`}
                    checked={comp?.checked ? comp?.checked : false}
                    onChange={() => {
                      setNextComplement(index + 1);
                      handleSelectComplement(complementGroup, comp, 'radio');
                    }}
                    roundedStyle={isRadioButton}
                  />
                </Complement>
              );
            })}
          </ComplementContainer>
        )}
        {/* caso seja Checkbox */}
        {isCheckbox && (
          <ComplementContainer>
            {complementGroup?.complements.map((comp: any) => {
              const isDisabled =
                complementGroup.maxAmount ===
                  complementGroup.complements.filter(
                    (comp: any) => comp.checked,
                  ).length && !comp.checked;

              const beforeDisabled =
                complementGroup.maxAmount ===
                  complementGroup.complements.filter(
                    (comp: any) => comp.checked,
                  ).length +
                    1 && !comp.checked;
              // O -1 ou +1 utilizado na verificação é para simular a ultima entrada do usuário,
              // pois se não a condição só será verdadeira no momento em que deveria scrollar a página
              const allSelected =
                complementGroup?.complements.length - 1 ===
                complementGroup.complements.filter((comp: any) => comp.checked)
                  .length;
              return (
                <Complement key={comp?.id} disabled={isDisabled}>
                  <p>{comp?.title ? comp?.title : comp?.description}</p>
                  {comp?.unitPrice > 0 && (
                    <Price>
                      <p>
                        R$
                        {comp?.unitPrice.toFixed(2).replace('.', ',')}
                      </p>
                    </Price>
                  )}

                  <CheckboxComplement
                    id={`${complementGroup.id}-${comp.id}`}
                    checked={comp?.checked ? comp?.checked : false}
                    onChange={() => {
                      if (!isDisabled) {
                        handleSelectComplement(
                          complementGroup,
                          comp,
                          'checkbox',
                        );
                        if (beforeDisabled) {
                          setNextComplement(index + 1);
                        }
                        // Se todos checkbox forém selecionados
                        if (allSelected && !comp?.checked) {
                          setNextComplement(index + 1);
                        }
                      }
                    }}
                    roundedStyle={isRadioButton}
                  />
                </Complement>
              );
            })}
          </ComplementContainer>
        )}

        {/* caso seja numberButton */}
        {numberButton && (
          <ComplementContainer>
            {complementGroup?.complements.map((comp: any) => {
              const groupMaxAmount = complementGroup.maxAmount;
              const totalAmount = complementGroup?.complements.reduce(
                (accumulator, item: any) => {
                  return accumulator + (item.amount || 0); // Adiciona o valor de `amount` ao acumulador
                },
                0,
              );

              const disabledAll = totalAmount >= groupMaxAmount;
              const beforeDisabledAll = totalAmount + 1 === groupMaxAmount;
              const maxAmount = (comp?.amount ?? 0) >= (comp?.maxAmount || 0);
              return (
                <Complement key={comp?.id} disabled={maxAmount || disabledAll}>
                  <p>{comp?.title ? comp?.title : comp?.description}</p>
                  {comp?.unitPrice > 0 && (
                    <Price>
                      <p>
                        R$
                        {comp?.unitPrice.toFixed(2).replace('.', ',')}
                      </p>
                    </Price>
                  )}
                  <InputContainer>
                    <AmountInput
                      allowsZero
                      disabled={maxAmount || disabledAll}
                      value={comp?.amount ?? 0}
                      onIncreaseAmount={() => {
                        handleSelectComplement(
                          complementGroup,
                          comp,
                          'increase',
                        );
                        beforeDisabledAll && setNextComplement(index + 1);
                      }}
                      onDecreaseAmount={() =>
                        handleSelectComplement(
                          complementGroup,
                          comp,
                          'decrease',
                        )
                      }
                    />
                  </InputContainer>
                </Complement>
              );
            })}
          </ComplementContainer>
        )}
      </ComplementsWrapper>
    );
  },
);

export default ComplementsCartSell;
