import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';

import * as Yup from 'yup';
import { FormHandles } from '@unform/core';
import VMasker from 'vanilla-masker';
import { useToast } from '../../../hooks/toast';
import { AuthRole } from '../../../hooks/auth';
import { unmaskPixKey } from '../../../utils/masks';
import { getValidationErrors } from '../../../utils/errors';

import ICompany from '../../../models/ICompany';
import { PixKeyType } from '../../../enums/pixKeyType';
import IBusinessHours from '../../../models/IBusinessHours';
import ISaveCompanyDTO from '../../../dtos/ISaveCompanyDTO';

import {
  Span,
  Title,
  Wrapper,
  GroupTitle,
  DivPadding,
  DisplayName,
  CompanyName,
  PixContainer,
  TypeContainer,
  GroupContainer,
  GroupSubTitles,
  PricesContaier,
  RadioContainer,
  AddressContainer,
  HorizontalWrapper,
  HorizontalWrapper2,
  BusinessHoursGroupTitle,
  BusinessHoursGroupContainer,
} from './styles';

import Input from '../../Input';
import Radio from '../../Radio';
import TextArea from '../../Textarea';
import SaveButton from '../../SaveButton';
import NumberInput from '../../NumberInput';
import FormlessInput from '../../FormlessInput';
import BusinessHours from '../../BusinessHours';

interface IUpdateCompanyFormData {
  city: string;
  state: string;
  address2: string;
  reference: string;
  postalCode: string;
  streetName: string;
  tradingName: string;
  phoneNumber: string;
  tableAmount: string;
  streetNumber: string;
  timeToPickup: number;
  neighborhood: string;
  timeToDelivery: number;
  welcomeMessage: string;
  cellPhoneNumber: string;
}
interface ICompanySettingsProps {
  company: ICompany | null;
  saveCompany: (company: ISaveCompanyDTO) => Promise<void>;
  saveBusinessHours: (businessHours: IBusinessHours) => Promise<void>;
}
const CompanySettings: React.FC<ICompanySettingsProps> = ({
  company,
  saveCompany,
  saveBusinessHours,
}) => {
  const { addToast } = useToast();
  const companyFormRef = useRef<FormHandles | null>(null);

  const [pixKey, setPixKey] = useState('');
  const [deliveryFee, setDeliveryFee] = useState(0);
  const [minOrderPrice, setMinOrderPrice] = useState(0);
  const [pixType, setPixType] = useState<PixKeyType>(PixKeyType.OTHER);
  const [businessHours, setBusinessHours] = useState<IBusinessHours>([]);
  const [showMenuDocumentInput, setShowMenuDocumentInput] = useState(false);

  const formattedDocument = useMemo(() => {
    return VMasker.toPattern(company?.document || '', '99.999.999/9999-99');
  }, [company]);

  useEffect(() => {
    if (company) {
      setDeliveryFee(company.deliveryFee || 0);

      setMinOrderPrice(company.minOrderPrice || 0);
      setBusinessHours(company.businessHours || []);

      // setAutoApprovesOrder(company.autoApprovesOrder);
      setShowMenuDocumentInput(company?.showMenuDocumentInput || false);

      setBusinessHours(
        Array.from({ length: 7 }).map((_, index) => {
          const foundDay = company.businessHours.find(
            hour => hour.day === index,
          );

          if (foundDay) {
            return foundDay;
          }

          return {
            day: index,
            isOpen: false,
            shifts: [{ open: '00:00', close: '23:59' }],
          };
        }),
      );

      let pixMaskLocal = '';

      if (company.pixType === PixKeyType.CPF) {
        pixMaskLocal = '999.999.999-99';
      }

      if (company.pixType === PixKeyType.CNPJ) {
        pixMaskLocal = '99.999.999/9999-99';
      }
      if (company.pixType === PixKeyType.PHONE) {
        pixMaskLocal = '(99) 9 9999 9999';
      }

      if (
        company.pixType === PixKeyType.CPF ||
        company.pixType === PixKeyType.CNPJ ||
        company.pixType === PixKeyType.PHONE
      ) {
        setPixKey(VMasker.toPattern(company.pixKey, pixMaskLocal));
      } else {
        setPixKey(company.pixKey);
      }

      setPixType(company.pixType || PixKeyType.OTHER);

      companyFormRef.current?.setFieldValue(
        'pixType',
        company.pixType || PixKeyType.OTHER,
      );
    }
  }, [company, companyFormRef]);

  const pixMask = useMemo(() => {
    if (pixType === PixKeyType.CPF) {
      return '999.999.999-99';
    }

    if (pixType === PixKeyType.CNPJ) {
      return '99.999.999/9999-99';
    }

    return '(99) 9 9999 9999';
  }, [pixType]);

  const handleOnUpdateCompany = useCallback(
    async (data: IUpdateCompanyFormData) => {
      const schema = Yup.object().shape({
        tradingName: Yup.string()
          .min(3, 'Mínimo de 3 caracteres.')
          .required('O nome de exibição é obrigatório.'),
        phoneNumber: Yup.string()
          .min(10, 'Mínimo de 10 caracteres.')
          .required('O telefone é obrigatório.'),
        cellPhoneNumber: Yup.string()
          .min(10, 'Mínimo de 10 dígitos.')
          .max(11, 'Máximo de 11 dígitos')
          .required('O celular é obrigatório.'),
        postalCode: Yup.string()
          .matches(/^\d{8}$/, 'CEP inválido, deve conter exatamente 8 dígitos.')
          .typeError('CEP inválido')
          .min(8, 'Mínimo de 8 dígitos.')
          .max(8, 'Máximo de 8 dígitos.')
          .required('O CEP é obrigatório.'),
        state: Yup.string()
          .min(2, 'Mínimo de 2 caracteres.')
          .max(2, 'Máximo de 2 caracteres.'),
        city: Yup.string().min(3, 'Mínimo de 3 caracteres.'),
        streetName: Yup.string()
          .min(3, 'Mínimo de 3 caracteres.')
          .required('O logradouro é obrigatório.'),
        neighborhood: Yup.string().min(3, 'Mínimo de 3 caracteres.'),
        address2: Yup.string().test(
          'address2-length',
          'Se presente, mínimo de 3 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 3) {
              return false;
            }
            return true;
          },
        ),
        reference: Yup.string().test(
          'reference-length',
          'Se presente, mínimo de 3 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 3) {
              return false;
            }
            return true;
          },
        ),
        welcomeMessage: Yup.string().test(
          'welcome-message-length',
          'Se presente, mínimo de 10 caracteres.',
          async (value: string | null | undefined) => {
            if (value && value.length < 10) {
              return false;
            }
            return true;
          },
        ),
        timeToDelivery: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required('Não pode ser vazio'),
        timeToPickup: Yup.number()
          .transform(value => (Number.isNaN(value) ? undefined : value))
          .required('Não pode ser vazio'),
      });

      companyFormRef.current?.setErrors({});

      try {
        await schema.validate(data, {
          abortEarly: false,
        });

        await saveBusinessHours(businessHours);

        await saveCompany({
          city: data.city,
          tradingName: data.tradingName,
          companyName: company?.companyName || '',
          document: company?.document || '',
          phoneNumber: data.phoneNumber,
          deliveryFee,
          minOrderPrice,
          neighborhood: data.neighborhood,
          postalCode: data.postalCode,
          state: data.state,
          streetName: data.streetName,
          streetNumber: data.streetNumber,
          address2: data.address2,
          reference: data.reference,
          cellPhoneNumber: data.cellPhoneNumber,
          tableAmount: Number(data.tableAmount),
          welcomeMessage: data.welcomeMessage,
          timeToDelivery: Number(data.timeToDelivery),
          timeToPickup: Number(data.timeToPickup),
          deliveryRanges: company?.deliveryRanges || [],
          showsWhatsApp: company?.showsWhatsApp || false,
          whatsApp: company?.whatsApp || '',
          showsFacebook: company?.showsFacebook || false,
          facebook: company?.facebook || '',
          showsInstagram: company?.showsInstagram || false,
          instagram: company?.instagram || '',
          companyLinks: company?.companyLinks || [],
          pixKey: unmaskPixKey(pixKey, pixType),
          pixType,
          primaryColor: company?.primaryColor,
          isDarkTheme: company?.isDarkTheme,
          loadingAnimation: company?.loadingAnimation,
          showMenuDocumentInput,
          scheduledProducts: company?.scheduledProducts || false,
          fbPixelId: company?.fbPixelId || '',
          fbMetaContent: company?.fbMetaContent || '',
        });

        addToast({
          type: 'success',
          description: 'Você alterou as informações da sua empresa.',
        });
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          companyFormRef.current?.setErrors(getValidationErrors(err));

          if (err instanceof Yup.ValidationError) {
            document
              .getElementById(err.inner[0].path)
              ?.scrollIntoView({ behavior: 'smooth' });
          }
          addToast({
            type: 'error',
            description: 'Verifique os campos em vermelho.',
          });
        } else {
          addToast({
            type: 'error',
            description: 'Verifique a conexão e tente novamente.',
          });
        }
      }
    },
    [
      company,
      pixKey,
      pixType,
      deliveryFee,
      businessHours,
      minOrderPrice,
      companyFormRef,
      showMenuDocumentInput,
      addToast,
      saveCompany,
      saveBusinessHours,
    ],
  );

  const handleOnChangeBusinessHours = useCallback(
    (newBusinessHours: IBusinessHours) => {
      setBusinessHours(newBusinessHours);
    },
    [],
  );
  const handleOnMinOrderPriceChanged = useCallback((value: number) => {
    setMinOrderPrice(value);
  }, []);

  const handleOnPixChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (
        pixType === PixKeyType.CPF ||
        pixType === PixKeyType.CNPJ ||
        pixType === PixKeyType.PHONE
      ) {
        setPixKey(VMasker.toPattern(e.target.value, pixMask));
      } else {
        setPixKey(e.target.value);
      }
    },
    [pixType, pixMask],
  );

  const handleTypeChange = useCallback((value: string) => {
    setPixKey('');
    setPixType(value as PixKeyType);

    const element = document.getElementById('pix-key');
    if (element) {
      element.focus();
    }
  }, []);

  const radioOptions = [
    {
      name: 'E-mail',
      value: PixKeyType.EMAIL,
    },

    {
      name: 'CNPJ',
      value: PixKeyType.CNPJ,
    },
    {
      name: 'CPF',
      value: PixKeyType.CPF,
    },
    {
      name: 'Telefone',
      value: PixKeyType.PHONE,
    },
    {
      name: 'Outro',
      value: PixKeyType.OTHER,
    },
  ];

  return (
    <Wrapper
      ref={companyFormRef}
      onSubmit={handleOnUpdateCompany}
      initialData={{
        ...company,
        ...(company?.city && { city: company?.city.name }),
        ...(company?.city && { state: company?.city.state }),
      }}
    >
      <HorizontalWrapper id="settings-info">
        <DisplayName>
          <div>
            <Span>
              <Title>Razão social</Title>
              <CompanyName>{company?.companyName}</CompanyName>
            </Span>
            <Span>
              <Title>CNPJ</Title>
              <CompanyName>{formattedDocument}</CompanyName>
            </Span>
          </div>
        </DisplayName>
      </HorizontalWrapper>
      <GroupContainer id="settings-contact">
        <GroupTitle>Contato</GroupTitle>
        <AuthRole disableOnly>
          <Input id="tradingName" name="tradingName" title="Nome de exibição" />
        </AuthRole>
        <HorizontalWrapper>
          <AuthRole disableOnly>
            <Input id="phoneNumber" title="Telefone" name="phoneNumber" />
          </AuthRole>
          <AuthRole disableOnly>
            <Input
              title="Celular"
              id="cellPhoneNumber"
              name="cellPhoneNumber"
            />
          </AuthRole>
        </HorizontalWrapper>
      </GroupContainer>
      <GroupContainer id="settings-address">
        <GroupTitle>Endereço</GroupTitle>
        <AddressContainer>
          <AuthRole disableOnly>
            <Input
              title="CEP"
              name="postalCode"
              id="postalCode"
              type="number"
            />
          </AuthRole>
          <AuthRole disableOnly>
            <Input title="Estado" name="state" id="state" />
          </AuthRole>
          <AuthRole disableOnly>
            <Input title="Cidade" name="city" id="city" />
          </AuthRole>
        </AddressContainer>
        <HorizontalWrapper>
          <AuthRole disableOnly>
            <Input title="Rua" name="streetName" id="streetName" />
          </AuthRole>
          <AuthRole disableOnly>
            <Input title="Número" id="streetNumber" name="streetNumber" />
          </AuthRole>
        </HorizontalWrapper>
        <HorizontalWrapper>
          <AuthRole disableOnly>
            <Input
              id="address2"
              name="address2"
              title="Complemento (não obrigatório)"
            />
          </AuthRole>
          <AuthRole disableOnly>
            <Input title="Bairro" id="neighborhood" name="neighborhood" />
          </AuthRole>
        </HorizontalWrapper>
        <AuthRole disableOnly>
          <Input
            id="reference"
            name="reference"
            title="Referência (não obrigatório)"
          />
        </AuthRole>
      </GroupContainer>
      <GroupContainer id="settings-business-hours">
        <BusinessHoursGroupTitle>
          Horários de atendimento
        </BusinessHoursGroupTitle>
        <BusinessHoursGroupContainer>
          <AuthRole disableOnly>
            <BusinessHours
              businessHours={businessHours}
              onChange={handleOnChangeBusinessHours}
            />
          </AuthRole>
        </BusinessHoursGroupContainer>
      </GroupContainer>
      <GroupContainer id="settings-welcome">
        <GroupTitle>Mensagem de boas-vindas</GroupTitle>
        <AuthRole disableOnly>
          <TextArea name="welcomeMessage" title="Mensagem" />
        </AuthRole>
      </GroupContainer>
      <GroupContainer id="settings-prices">
        <GroupTitle>Atendimento e PIX</GroupTitle>
        <PricesContaier>
          <HorizontalWrapper2>
            <AuthRole disableOnly>
              <NumberInput
                value={minOrderPrice}
                onChange={handleOnMinOrderPriceChanged}
                title="Pedido mínimo"
                defaultValue={company?.minOrderPrice || 0}
              />
            </AuthRole>
            <AuthRole disableOnly>
              <Input
                type="number"
                id="tableAmount"
                name="tableAmount"
                title="Quantidade de mesas"
              />
            </AuthRole>
          </HorizontalWrapper2>
          <HorizontalWrapper2>
            <AuthRole disableOnly>
              <Input
                type="number"
                id="timeToDelivery"
                name="timeToDelivery"
                title="Tempo de entrega (em minutos)"
                defaultValue={company?.timeToDelivery}
              />
            </AuthRole>
            <AuthRole disableOnly>
              <Input
                type="number"
                id="timeToPickup"
                name="timeToPickup"
                title="Tempo de retirada (em minutos)"
                defaultValue={company?.timeToPickup}
              />
            </AuthRole>
          </HorizontalWrapper2>
        </PricesContaier>
        <GroupSubTitles>Chave pix</GroupSubTitles>
        <PixContainer>
          <AuthRole disableOnly>
            <FormlessInput
              id="pix-key"
              name="pixKey"
              value={pixKey}
              title="Chave PIX"
              onChange={handleOnPixChanged}
            />
          </AuthRole>
          <TypeContainer>
            <h4>Selecione o tipo de chave:</h4>
            <AuthRole disableOnly>
              <RadioContainer name="pixType" onChange={handleTypeChange}>
                {radioOptions.map((option, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <Radio.Option key={index} value={option.value}>
                    {option.name}
                    <MdRadioButtonUnchecked size={20} className="unchecked" />
                    <MdRadioButtonChecked size={20} className="checked" />
                  </Radio.Option>
                ))}
              </RadioContainer>
            </AuthRole>
          </TypeContainer>
        </PixContainer>
      </GroupContainer>
      <AuthRole>
        <SaveButton type="submit" id="settings-save" />
      </AuthRole>
      <DivPadding />
    </Wrapper>
  );
};

export default CompanySettings;
