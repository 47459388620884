import React, { useState } from 'react';
import { Badge, Container, Filters } from './styles';
import Search from '../Search';
import { ITable2 } from '../../models/ITable2';
import { ICard } from '../../models/ICard';
import { statusCardTableType } from '../../enums/cardAndTableType';

interface IFIltersCardsAndTablesProps {
  setFilteredTables?: React.Dispatch<React.SetStateAction<ITable2[]>>;
  setFilteredCards?: React.Dispatch<React.SetStateAction<ICard[]>>;
  tables?: ITable2[];
  cards?: ICard[];
}
export const FiltersCardsAndTables: React.FC<IFIltersCardsAndTablesProps> = ({
  setFilteredTables,
  setFilteredCards,
  tables,
  cards,
}) => {
  const [status, setStatus] = useState<statusCardTableType[]>([
    statusCardTableType.OCCUPIED,
    statusCardTableType.FREE,
    statusCardTableType.CLOSING,
  ]);
  const [searchCriteria, setSearchCriteria] = useState('');

  const handleFilter = (value: statusCardTableType) => {
    const already = status.some(st => st === value);
    let arrayComparison: statusCardTableType[];
    if (already) {
      setStatus(status.filter(st => st !== value));
      arrayComparison = status.filter(st => st !== value);
    } else {
      setStatus(prev => [...prev, value]);
      arrayComparison = [...status, value];
    }

    setFilteredTables &&
      tables &&
      setFilteredTables(
        tables.filter(table => arrayComparison.includes(table.status)),
      );
    setFilteredCards &&
      cards &&
      setFilteredCards(
        cards.filter(table => arrayComparison.includes(table.status)),
      );
  };

  const applyFilters = (criteria: string) => {
    setSearchCriteria(criteria);

    // Se searchCriteria for '' então ele pega os filtros do status e seta as mesas ou comandas para a os filtros ativos
    if (criteria.length === 0) {
      setFilteredTables &&
        tables &&
        setFilteredTables(
          tables.filter(table => status.includes(table.status)),
        );

      setFilteredCards &&
        cards &&
        setFilteredCards(cards.filter(card => status.includes(card.status)));
    } else {
      const filteredCards = cards?.filter(
        card =>
          card.description?.toLowerCase().includes(criteria.toLowerCase()) ||
          card.cardNumber.toString().includes(criteria),
      );

      const filteredTables = tables?.filter(
        table =>
          table.description?.toLowerCase().includes(criteria.toLowerCase()) ||
          table.tableNumber.toString().includes(criteria),
      );

      setFilteredCards && setFilteredCards(filteredCards || []);
      setFilteredTables && setFilteredTables(filteredTables || []);
    }
  };
  const qtdFree = tables
    ? tables?.filter(table => table.status === statusCardTableType.FREE).length
    : cards?.filter(card => card.status === statusCardTableType.FREE).length;
  const qtdOccupied = tables
    ? tables?.filter(table => table.status === statusCardTableType.OCCUPIED)
        .length
    : cards?.filter(card => card.status === statusCardTableType.OCCUPIED)
        .length;
  const qtdClosing = tables
    ? tables?.filter(table => table.status === statusCardTableType.CLOSING)
        .length
    : cards?.filter(card => card.status === statusCardTableType.CLOSING).length;

  return (
    <Container>
      <Filters>
        {setFilteredTables ? <h4>Mesas</h4> : <h4>Comandas</h4>}
        <Badge
          type={statusCardTableType.FREE}
          status={
            status.filter(st => st === statusCardTableType.FREE)[0]
              ? status.filter(st => st === statusCardTableType.FREE)[0]
              : statusCardTableType.OCCUPIED
          }
          onClick={() => handleFilter(statusCardTableType.FREE)}
        >
          <p>Disponíveis</p>
          <span>{qtdFree}</span>
        </Badge>
        <Badge
          type={statusCardTableType.OCCUPIED}
          status={
            status.filter(st => st === statusCardTableType.OCCUPIED)[0]
              ? status.filter(st => st === statusCardTableType.OCCUPIED)[0]
              : statusCardTableType.FREE
          }
          onClick={() => handleFilter(statusCardTableType.OCCUPIED)}
        >
          <p>Ocupadas</p>
          <span>{qtdOccupied}</span>
        </Badge>
        <Badge
          type={statusCardTableType.CLOSING}
          status={
            status.filter(st => st === statusCardTableType.CLOSING)[0]
              ? status.filter(st => st === statusCardTableType.CLOSING)[0]
              : statusCardTableType.FREE
          }
          onClick={() => handleFilter(statusCardTableType.CLOSING)}
        >
          <p>Fechamento</p>
          <span>{qtdClosing}</span>
        </Badge>
        <Search fullscreen value={searchCriteria} onChange={applyFilters} />
      </Filters>
    </Container>
  );
};
