import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { FiPlus } from 'react-icons/fi';

import IOperator from '../../models/IOperator';

import { useToast } from '../../hooks/toast';
import { useOperators } from '../../hooks/operators';

import Operator from '../../components/Operator';
import LoadingAnimation from '../../components/LoadingAnimation';
import EditOperatorModal from '../../components/EditOperatorModal';

import {
  Main,
  Header,
  Content,
  PageName,
  Container,
  AddOperatorButton,
  OperatorsPlaceholder,
  ButtonSearchContainer,
  EmptyMessageContainer,
} from './styles';
import { useCompany } from '../../hooks/company';
import Search from '../../components/Search';
import EmptyMessage from '../../components/EmptyMessage';
import { PageNames } from '../../enums/pages';

const OperatorsPage: React.FC = () => {
  const { addToast } = useToast();

  const { operators, isOperatorsLoading, getOperators, changeStatus } =
    useOperators();

  const { company } = useCompany();

  const [searchCriteria, setSearchCriteria] = useState('');

  const [isModalVisible, setIsModalVisible] = useState(false);

  const [selectedOperator, setSelectedOperator] = useState<IOperator | null>(
    null,
  );

  useEffect(() => {
    async function loadData() {
      await getOperators();
    }

    if (company?.id) {
      loadData();
    }
  }, [getOperators, company]);

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleAddNewOperatorClicked = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleOnModalClosed = useCallback(() => {
    setSelectedOperator(null);
    setIsModalVisible(false);
  }, []);

  const handleOnStatusClick = useCallback(
    async (id: number) => {
      try {
        await changeStatus(id);
      } catch {
        addToast({
          type: 'error',
          description: 'Verifique sua conexão e tente novamente.',
        });
      }
    },
    [addToast, changeStatus],
  );

  const handleSelectOperator = useCallback((operator: IOperator) => {
    setSelectedOperator(operator);
    setIsModalVisible(true);
  }, []);

  const searchedOperators = useMemo(() => {
    return operators.filter(operator => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return (
        operator.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(string_norm.toLowerCase()) ||
        operator.login
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .includes(string_norm.toLowerCase())
      );
    });
  }, [searchCriteria, operators]);

  return (
    <Container>
      <Content>
        <Header>
          <PageName>{PageNames.OPERATORS}</PageName>
          <ButtonSearchContainer>
            <Search
              value={searchCriteria}
              onChange={handleOnSearchCriteriaChanged}
            />
            <AddOperatorButton onClick={handleAddNewOperatorClicked}>
              <FiPlus />
              <span />
            </AddOperatorButton>
          </ButtonSearchContainer>
        </Header>
        {isOperatorsLoading ? (
          <LoadingAnimation />
        ) : (
          <Main className="has-custom-scroll-bar-2">
            {searchedOperators.length > 0 ? (
              <>
                {searchedOperators.map(operator => (
                  <Operator
                    key={operator.id}
                    operator={operator}
                    onStatusChange={handleOnStatusClick}
                    onOperatorClicked={handleSelectOperator}
                  />
                ))}
                <OperatorsPlaceholder />
                <OperatorsPlaceholder />
                <OperatorsPlaceholder />
              </>
            ) : (
              <EmptyMessageContainer>
                <EmptyMessage
                  text="operador"
                  plural="operadores"
                  searchCriteria={searchCriteria}
                  onClick={handleAddNewOperatorClicked}
                />
              </EmptyMessageContainer>
            )}
          </Main>
        )}
        <EditOperatorModal
          visible={isModalVisible}
          operator={selectedOperator}
          onClose={handleOnModalClosed}
        />
      </Content>
    </Container>
  );
};

export default OperatorsPage;
