import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FiArrowLeft, FiTrash, FiX } from 'react-icons/fi';
import {
  CartWrapper,
  Close,
  ContinueShopping,
  Grid,
  Header,
  NotFound,
} from './styles';
import { useCartProducts } from '../../hooks/products_sell';
import DetailProductCart from '../DetailProductCart';
import IconButton from '../IconButton';
import ProductCart from '../ProductCart';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import ButtonFinishDetailProduct from '../ButtonFinishDetailProduct';
import { ITable2 } from '../../models/ITable2';
import { ICard } from '../../models/ICard';
import { useOrders } from '../../hooks/orders';
import IOrder, {
  IProductsInternalOrder,
  ISendInternalOrder,
} from '../../models/IOrder';
import Loading from '../Loading';
import IComplementsGroup from '../../models/IComplementsGroup';
import { useToast } from '../../hooks/toast';

interface ICartSellProps {
  card?: ICard;
  table?: ITable2;
  order?: IOrder;
}
const CartSell: React.FC<ICartSellProps> = ({ card, table, order }) => {
  const { showConfirmDialog, ThrowError } = useConfirmDialog();
  const {
    selectedProduct,
    cart,
    openCart,
    setOpenCart,
    setSelectedProduct,
    handleClearCart,
    editProduct,
    deleteProductFromCart,
    incrementProductOnCart,
    decrementProductOnCart,
  } = useCartProducts();

  const { addToast } = useToast();
  const { sendInternalOrder } = useOrders();
  const [loading, setLoading] = useState(false);
  const cartWrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (selectedProduct && cartWrapperRef.current) {
      cartWrapperRef.current.scrollTo({ top: 0, behavior: 'smooth' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct?.id]);
  /* Ordena os produtos pelo realeaseOrder setado ao adicionar o produto no carrinho, para que quando editado o produto e inserido no carrinho novamente ele não fique trocando de posição, e ao setar a quantidade no carrinho também */
  const sortedProducts = useMemo(() => {
    return cart?.products?.slice().sort((a, b) => {
      if (a.releaseOrder === undefined && b.releaseOrder === undefined)
        return 0;
      if (a.releaseOrder === undefined) return 1;
      if (b.releaseOrder === undefined) return -1;

      return a.releaseOrder - b.releaseOrder;
    });
  }, [cart]);

  const handleClickClearCart = () => {
    showConfirmDialog({
      title: 'Limpar Carrinho',
      message: `Deseja limpar o carrinho?`,
      onCancel: () => null,
      onConfirm: () => handleClearCart(),
    });
  };

  const removeComplementsWithZeroAmount = (
    complementGroups: IComplementsGroup[],
  ) => {
    const complementsGroupFiltered = complementGroups.map(group => ({
      ...group,
      complements: group.complements.filter(
        complement => complement.amount !== 0,
      ),
    }));

    return complementsGroupFiltered.filter(
      complementG => complementG.complements.length > 0,
    );
  };
  const handleFinishOrder = async () => {
    setLoading(true);
    try {
      const items: IProductsInternalOrder[] = cart.products.map(product => {
        return {
          productId: product.productId,
          sellerSku: product.sellerSku,
          title: product.title,
          unitPrice: product.unitPrice,
          isHalfPrice: product.isHalfPrice,
          qty: product.qty,
          comments: product.comments,
          isCustomPizza: product?.isCustomPizza,
          pizzaCategoryId: product?.pizzaCategoryId,
          pizzaSizeId: product?.pizzaSizeId,
          total: product.total,
          complementsGroups: removeComplementsWithZeroAmount(
            product.complementsGroups,
          ),
        };
      });

      const internalOrder: ISendInternalOrder = {
        orderId: order?.id ?? 0,
        waiterId: 1,
        items,
      };
      await sendInternalOrder(internalOrder, internalOrder.orderId, cart.id);
      addToast({
        type: 'success',
        description: 'Produto adicionado com sucesso!',
      });
      window.location.reload();
    } catch (error) {
      ThrowError(error, 'Erro ao lançar produto!');
      setLoading(false);
      console.error(error);
    }
  };
  const isMobile = window?.innerWidth <= 940;
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <CartWrapper ref={cartWrapperRef} className="has-custom-scroll-bar-2">
          <Header>
            {window?.innerWidth <= 940 && (
              <Close>
                <IconButton
                  onClick={() => setOpenCart(false)}
                  icon={<FiArrowLeft size={22} />}
                />
              </Close>
            )}
            {selectedProduct !== null && !openCart && (
              <Close>
                <IconButton
                  onClick={() => setSelectedProduct(null)}
                  icon={<FiX size={22} />}
                />
              </Close>
            )}
            <div className="name">
              <h2>
                {card && `Comanda ${card.cardNumber}`}
                {table && `Mesa ${table.tableNumber}`}
              </h2>
              {cart?.products?.length > 0 && !selectedProduct && (
                <Close>
                  <IconButton
                    onClick={() => handleClickClearCart()}
                    icon={<FiTrash size={22} />}
                  />
                </Close>
              )}
            </div>
          </Header>
          <DetailProductCart />
          {sortedProducts.length > 0 && !selectedProduct ? (
            <Grid className="has-custom-scroll-bar-2">
              {sortedProducts.map((product, index) => (
                <ProductCart
                  // eslint-disable-next-line react/no-array-index-key
                  key={index}
                  product={product}
                  editProduct={editProduct}
                  onIncreaseAmount={() => incrementProductOnCart(product)}
                  onDecreaseAmount={() => decrementProductOnCart(product)}
                  deleteProduct={deleteProductFromCart}
                />
              ))}
            </Grid>
          ) : (
            !selectedProduct && (
              <NotFound>
                <p>Nenhum produto no carrinho! </p>
                <button
                  type="button"
                  className="link"
                  onClick={() => setOpenCart(false)}
                >
                  Adicionar produto
                </button>
              </NotFound>
            )
          )}
          {cart?.products?.length > 0 && !selectedProduct && (
            <ContinueShopping>
              {isMobile && (
                <button
                  onClick={() => setOpenCart(false)}
                  type="button"
                  className="link"
                >
                  Continuar comprando
                </button>
              )}

              <ButtonFinishDetailProduct
                loading={loading}
                subtotal={cart.totalPriceCart ?? 0}
                handleFinish={() => handleFinishOrder()}
                title="Finalizar!"
              />
            </ContinueShopping>
          )}
        </CartWrapper>
      )}
    </>
  );
};

export default CartSell;
