import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FiPlus } from 'react-icons/fi';

import { BoxAdd, ModalWrapper } from './styles';

import { useCards } from '../../../../../hooks/card';
import { useTable } from '../../../../../hooks/table';
import { useConfirmDialog } from '../../../../../hooks/confim_dialog';

import { ICard } from '../../../../../models/ICard';
import { ITable2 } from '../../../../../models/ITable2';

import Modal from '../../../../Modal';
import BasicInput from '../../../../BasicInput';

interface IModalAddTableCardProps {
  type: 'card' | 'table';
  setCards: React.Dispatch<React.SetStateAction<ICard[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTables: React.Dispatch<React.SetStateAction<ITable2[]>>;
}
const ModalAddTableCard: React.FC<IModalAddTableCardProps> = ({
  type,
  setCards,
  setTables,
  setLoading,
}) => {
  const { createCards } = useCards();
  const { createTables } = useTable();
  const { ThrowError } = useConfirmDialog();
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(0);
  const [open, setOpen] = useState(false);
  const [error, setError] = useState(false);

  const postTables = useCallback(async () => {
    try {
      setLoading(true);
      const response = await createTables(value, value);
      setTables(response.data);
    } catch (error) {
      ThrowError(error, 'Erro ao gerar mesas');
    } finally {
      setLoading(false);
    }
  }, [ThrowError, createTables, setLoading, setTables, value]);

  const postCards = useCallback(async () => {
    try {
      setLoading(true);
      const response = await createCards(value, value);
      setCards(response.data);
    } catch (error) {
      ThrowError(error, 'Erro ao gerar comandas');
    } finally {
      setLoading(false);
    }
  }, [ThrowError, createCards, setCards, setLoading, value]);

  const handleConfirm = useCallback(() => {
    if (value <= 0) {
      setError(true);
      return;
    }

    type === 'table' && postTables();
    type === 'card' && postCards();

    setValue(0);
    setOpen(false);
  }, [postCards, postTables, type, value]);

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
    }
  }, [open]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        handleConfirm();
      }
    };

    if (open) {
      window.addEventListener('keydown', handleKeyDown);
    }

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleConfirm, open, value]);

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e) {
      setValue(Number(e?.target?.value));
    }
  };
  return (
    <>
      <BoxAdd onClick={() => setOpen(true)}>
        <FiPlus color="#fff" size={32} />
      </BoxAdd>
      {open && (
        <Modal open={open} setOpen={setOpen} onConfirm={handleConfirm}>
          <ModalWrapper error={error}>
            <BasicInput
              ref={inputRef}
              scale="xl"
              type="number"
              title="Número da mesa/comanda"
              value={value}
              onChange={handleOnChange}
            />
            {error && (
              <p style={{ color: 'red' }}>O Campo deve ser maior que 0</p>
            )}
          </ModalWrapper>
        </Modal>
      )}
    </>
  );
};

export default ModalAddTableCard;
