import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  gap: 1rem;
  margin-bottom: 1rem;
  .title {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const WrapperGroups = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  max-width: 1020px;
  gap: 5px;
`;
export const Group = styled.div<{ selected?: boolean }>`
  display: flex;
  width: auto;
  padding: 2px 5px;
  color: ${({ theme, selected }) =>
    selected ? theme.palette.text_white : theme.palette.primary};
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary : 'transparent'};
  border: 1px solid ${({ theme }) => theme.palette.primary};
  border-radius: 4px;
  cursor: pointer;
  transition: 500ms;
`;
export const FavoriteGroup = styled.div<{ selected?: boolean }>`
  display: flex;
  width: auto;
  padding: 2px 5px;
  color: ${({ theme, selected }) =>
    selected ? theme.palette.text_white : '#FDD400'};
  background-color: ${({ selected }) => (selected ? '#FDD400' : 'transparent')};
  border: 1px solid #fdd400;
  border-radius: 4px;
  cursor: pointer;
  transition: 500ms;
`;

export const GridProducts = styled.div`
  display: grid;
  width: 100%;
  height: 55vh;
  max-width: 1020px;
  min-width: 200px;
  gap: 10px;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-auto-rows: 55px;
  overflow: auto;
  padding: 0.5rem 0;

  @media (max-width: 1024px) {
    height: 50vh;
  }
  @media (max-height: 700px) {
    height: 40vh;
  }
`;

export const NotFound = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 10vh;
  height: 55vh;
  max-width: 1020px;
  min-width: 200px;
`;

export const PizzaCard = styled.div`
  display: flex;
  padding: 10px;
  gap: 1rem;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  max-width: 400px;
  max-height: 55px;
  cursor: pointer;
  color: ${({ theme }) => theme.palette.text_white};
  background-color: ${({ theme }) => theme.palette.primary_light};
  background-image: url(https://menustaging.bsfood.com.br/svg/custom-pizza-background-light.svg);

  .separate {
    display: flex;
    justify-content: center;
    flex-direction: column;
  }

  .label {
    font-weight: 600;
  }
  .click {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary};
  }
`;
