import React, { useState } from 'react';
import { ContainerCancelationOrder, Tittle } from './styles';

import { useInternalOrders } from '../../hooks/internal_orders';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import IOrder from '../../models/IOrder';

import Modal from '../Modal';
import TextAreaObservation from '../TextAreaObservation';

interface IDeleteOrderProps {
  order?: IOrder;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const DeleteOrder: React.FC<IDeleteOrderProps> = ({ order, open, setOpen }) => {
  const { ThrowError } = useConfirmDialog();
  const { deleteOrder } = useInternalOrders();
  const [loading, setLoading] = useState(false);
  const [reasonDelete, setReasonDelete] = useState('');
  const [reasonError, setReasonError] = useState(false);

  const handleDeleteOrder = async () => {
    try {
      setLoading(true);
      if (reasonDelete.length <= 2) {
        setLoading(false);
        setReasonError(true);
        return;
      }
      await deleteOrder(order?.id ?? 0, reasonDelete);
      // Não é setado o loading para false, pois o SignalR me notifica após a requisição, desmontando meu componente
      // Causando vazemento de memória se algum estado estiver sendo setado com o componente desmontado
    } catch (error) {
      ThrowError(error, 'Erro ao cancelar!');
      setLoading(false);
      setOpen(false);
    }
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={handleDeleteOrder}
      loading={loading}
    >
      <ContainerCancelationOrder>
        <Tittle>
          <p>Cancelamento de pedido</p>
        </Tittle>
        <TextAreaObservation
          labelError="O campo deve ter no mínimo 3 caracteres"
          error={reasonError}
          handleCommentsChange={value => setReasonDelete(value)}
          title="Motivo do cancelamento"
          placeholder="Digite o motivo do cancelamento..."
        />
      </ContainerCancelationOrder>
    </Modal>
  );
};

export default DeleteOrder;
