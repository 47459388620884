import React from 'react';
import { FiArrowUp, FiTrash } from 'react-icons/fi';

import theme from '../../../../styles/themes/light';
import { Cell, Grid, Transaction, Wrapper } from './styles';

import { ITransaction } from '../../../../models/IPayment';

import { formatToMoney } from '../../../../utils/format';
import { choosePaymentTypeIcon } from '../../../../utils/icons';
import { convertPaymentName } from '../../../../utils/paymentName';

interface IGridTransactionsProps {
  transactions: ITransaction[];
  setTransactions: React.Dispatch<React.SetStateAction<ITransaction[]>>;
}
const GridTransactions: React.FC<IGridTransactionsProps> = ({
  transactions,
  setTransactions,
}) => {
  const handleDeleteTransaction = (trans: ITransaction) => {
    setTransactions(prev => {
      const removedTransactions = prev.filter(
        prevTransaction => prevTransaction.id !== trans.id,
      );
      return removedTransactions;
    });
  };

  return (
    <Wrapper>
      <h3>Transações</h3>
      <Grid className="has-custom-scroll-bar-2">
        {transactions.map(transact => (
          <Transaction key={transact.id}>
            <Cell>
              <FiArrowUp color={theme.palette.success} size={22} />
            </Cell>
            <Cell>
              <img
                src={choosePaymentTypeIcon(`${transact.paymentType}`, false)}
                alt={transact.paymentType}
              />
            </Cell>
            <Cell>{convertPaymentName(transact.paymentType)}</Cell>
            <Cell>{formatToMoney(transact.amount)}</Cell>
            <Cell deleteIcon onClick={() => handleDeleteTransaction(transact)}>
              <FiTrash
                className="delete"
                size={22}
                color={theme.palette.primary}
              />
            </Cell>
          </Transaction>
        ))}
      </Grid>
    </Wrapper>
  );
};

export default GridTransactions;
