import styled, { css } from 'styled-components';
import LogoAsset from '../../assets/logo.png';

interface IContainerProps {
  visible: boolean;
}

interface IPageProps {
  selected?: boolean;
  hideIcon?: boolean;
}

interface IBadgeProps {
  selected?: boolean;
}

interface ICompanyStatusButtonProps {
  open?: boolean;
}

interface IGroupProps {
  index: number;
  length: number;
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 3.5fr;
  grid-template-areas: 'sidebar children';

  overflow: hidden;

  @media (max-width: 1270px) {
    grid-template-rows: 1fr;
    grid-template-columns: 1fr;
    grid-template-areas: 'children';
  }

  @media (max-width: 1270px) {
    ${({ visible }) =>
      visible
        ? css`
            .content {
              opacity: 1;
              transform: none;
              visibility: visible;
            }

            .children {
              filter: brightness(0.4);
            }
          `
        : css`
            .content {
              opacity: 0;
              visibility: hidden;
              transform: translateX(-100vw);
            }

            .children {
              filter: none;
            }
          `}
  }
`;

export const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: stretch;
  flex-direction: column;
  justify-content: stretch;
  max-height: 100vh;

  transition: all 380ms cubic-bezier(0.24, 0.73, 0.23, 0.96);
  background: ${({ theme }) => theme.palette.background_white};

  overflow-y: auto;
  grid-area: sidebar;
  will-change: transform opacity visibility;

  .switches-container {
    display: none;
  }

  @media (max-width: 1270px) {
    width: 40%;
    height: 100%;
    padding-bottom: 0px;
    top: 0;
    left: 0;
    position: fixed;

    z-index: 999;

    .switches-container {
      display: flex;
    }
  }

  @media (max-width: 700px) {
    width: 100%;
  }
  @media (max-width: 600px) {
    padding-bottom: 0;
  }
`;

export const ChildrenContainer = styled.div`
  width: 100%;
  height: 100%;

  z-index: 0;
  grid-area: children;

  .children-content {
    width: 100%;
    height: 100%;
    max-height: calc(100vh);
    overflow: hidden;
    border-radius: 16px 0 0 0;
    overflow: auto;
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_sidebar};
    padding-bottom: 78px;
    @media (max-width: 1270px) {
      padding-bottom: 65px;
    }

    @media (max-width: 600px) {
      max-height: calc(100vh - 64px);
      padding-bottom: 0;
    }
  }
`;

export const SidebarHeader = styled.header`
  width: 100%;
  gap: 16px;
  height: auto;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;

  padding: 12px;

  svg {
    display: none;
  }

  @media (max-width: 1270px) {
    justify-content: space-between;

    padding: 8px;
    padding: 12px 24px;

    svg {
      display: block;
    }

    .switches-container {
      display: none;
    }
  }

  .toggles {
    display: flex;
    align-items: center;
    gap: 1rem;
  }

  @media (max-width: 700px) {
    .toggle {
      display: none;
    }
  }
`;

export const SwitchesContainerWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: flex-start;

  gap: 16px;

  @media (max-width: 1270px) {
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    gap: 8px;
    padding: 16px;
    position: relative;
  }
`;

export const InnerContent = styled.div`
  height: 100%;

  flex: 1;
  display: flex;
  flex-direction: column;

  margin-top: 8px;
  overflow-y: overlay;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const Header = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;

  padding: 16px 24px;

  svg {
    cursor: pointer;
    @media (min-width: 1271px) {
      display: none;
    }
  }

  @media (min-width: 700px) {
    .toggle {
      display: none;
    }
  }
`;

export const HeaderWrapper = styled.div`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  svg + svg {
    margin-left: 24px;
  }
`;

export const HeaderBottom = styled.div`
  width: 90%;

  display: flex;
  align-self: center;
  align-items: flex-end;
  justify-content: flex-start;

  padding: 16px;
  border-top: 1px solid ${({ theme }) => theme.palette.outline};

  svg {
    cursor: pointer;
  }

  svg + svg {
    margin-left: 24px;
  }

  .privacy-policy {
    margin-left: auto;
    a {
      color: ${({ theme }) => theme.palette.primary};
      text-decoration: underline;
    }
  }
`;

export const Welcome = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;

  padding: 0 32px;

  div {
    display: flex;
    align-items: center;

    div {
      display: flex;
      flex-direction: column;
    }
  }

  span {
    width: 100%;

    font-size: 80%;
    text-align: left;
  }

  strong {
    width: 100%;

    font-size: 100%;
    font-weight: 500;
    text-align: left;

    @media (max-width: 1270px) {
      font-size: 16px;
    }
  }

  svg {
    cursor: pointer;
    @media (max-width: 1270px) {
      display: none;
    }
  }

  @media (max-width: 1270px) {
    > div {
      display: flex;
      align-items: flex-start;
    }
  }

  @media (max-width: 600px) {
    display: none !important;
  }
`;
export const Pages = styled.div`
  button + button {
    margin-top: 16px;
  }
  padding-top: 3vh;
  height: 100%;

  @media (max-width: 1270px) {
    margin-top: 0;
    padding-top: 0;
  }
`;

export const CashierGroup = styled.div<IGroupProps>`
  position: relative;
  margin-left: 32px;
  padding-right: 8px;

  border-left: 2px solid ${({ theme }) => theme.palette.control};

  overflow: hidden;
  transition: all 0.4s;

  margin-bottom: 16px;

  .indicator {
    transform: ${({ index }) => `translateY(${index * 47}px)`};
  }

  ${({ visible, length }) =>
    visible
      ? css`
          height: ${length * 47}px;
        `
      : css`
          height: 0;
        `}
`;
export const ProductsGroup = styled.div<IGroupProps>`
  position: relative;
  margin-left: 32px;
  padding-right: 8px;

  border-left: 2px solid ${({ theme }) => theme.palette.control};

  overflow: hidden;
  transition: all 0.4s;

  margin-bottom: 16px;

  .indicator {
    transform: ${({ index }) => `translateY(${index * 47}px)`};
  }

  ${({ visible, length }) =>
    visible
      ? css`
          height: ${length * 47}px;
        `
      : css`
          height: 0;
        `}
`;

export const RegisterGroup = styled.div<IGroupProps>`
  position: relative;
  margin-left: 32px;
  padding-right: 8px;

  border-left: 2px solid ${({ theme }) => theme.palette.control};

  overflow: hidden;
  transition: all 0.4s;

  margin-bottom: 16px;

  .indicator {
    transform: ${({ index }) => `translateY(${index * 47}px)`};
  }

  ${({ visible, length }) =>
    visible
      ? css`
          height: ${length * 47}px;
        `
      : css`
          height: 0;
        `}
`;

export const SettingsGroup = styled.div<IGroupProps>`
  position: relative;
  margin-left: 32px;
  padding-right: 8px;

  border-left: 2px solid ${({ theme }) => theme.palette.control};

  overflow: hidden;
  transition: all 0.4s;

  .indicator {
    transform: ${({ index }) => `translateY(${index * 47}px)`};
  }

  ${({ visible, length }) =>
    visible
      ? css`
          height: ${length * 47}px;
        `
      : css`
          height: 0;
        `}
`;

export const Indicator = styled.div`
  height: 15px;

  margin: 20px 0;
  position: absolute;

  top: 0;
  right: 32px;
  width: 15px;

  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary};
  transition: transform 200ms cubic-bezier(0.62, 0.2, 0, 1.48);
`;

export const IconContainer = styled.div<IPageProps>`
  transition: all 0.4s;

  ${({ selected }) =>
    selected
      ? css`
          transform: rotateZ(-180deg);
        `
      : css`
          transform: rotateZ(0);
        `}
`;

export const Image = styled.div`
  width: 60px;
  height: 60px;
  min-width: 60px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  margin-right: 16px;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
  }
`;

export const HideButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 50%;

  z-index: 999;

  background: #fff;
  border: 1px solid #ccc;

  color: #c0392b;

  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.1);

  position: absolute;
  right: -20px;
  top: 15%;

  width: 40px;
  height: 40px;
`;

export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 16px;
  }

  padding: 8px 16px;
  border-radius: 16px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};

  @media (max-width: 1270px) {
    font-size: 14px;
  }

  @media (max-width: 600px) {
    padding: 0;
    box-shadow: none;
  }
`;

export const SendWhatsAppButtonContainer = styled.div`
  display: flex;
  align-items: center;

  span {
    margin-left: 16px;
  }

  @media (max-width: 1270px) {
    padding: 32px;
  }

  @media (max-width: 1270px) {
    font-size: 14px;
  }
`;

export const CompanyStatusButton = styled.button<ICompanyStatusButtonProps>`
  width: 100%;
  height: 40px;
  border: 0;
  color: #fff;

  ${({ open }) =>
    open
      ? css`
          background: #27ae60;
        `
      : css`
          background: #c0392b;
        `}
`;

export const Badge = styled.div<IBadgeProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  position: absolute;
  ${({ selected }) =>
    selected
      ? css`
          top: 8px;
          right: 32px;
        `
      : css`
          top: 8px;
          right: 36px;
        `}

  background: #c0392b;
  height: 30px;
  width: 30px;
  color: #fff;
  border-radius: 50%;

  font-size: 14px;

  @media (max-width: 1270px) {
    right: 16px;
    bottom: 0;
  }
`;

export const Logo = styled.div`
  width: calc(68px - 1vw);
  height: calc(68px - 1vw);
  background-image: url(${LogoAsset});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media (max-width: 1270px) {
    width: calc(48px - 1vw);
    height: calc(48px - 1vw);
  }
`;

export const PageName = styled.h1`
  font-size: 22px;
  align-self: center;
  display: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(100vw / 2 + 18px);
  @media (max-width: 1027px) {
    display: block;
    font-size: 1.2rem;
  }
`;
