import React, { createContext, useCallback, useState, useContext } from 'react';

import IPayment from '../models/IPayment';
import IProductSku from '../models/IProductSku';
import IProduct, { IProductMeasurement } from '../models/IProduct';

import api from '../services/api';
import { useCompany } from './company';
import { LocalStorage } from '../enums/localstorage';
import { IProductSell } from '../models/IFastSell';

interface ProductsContextData {
  products: IProduct[];
  isProductsLoading: boolean;
  selectedProduct: IProduct | null;
  lastEditedProductId: number;
  hasProducts: boolean;
  productMeasurements: IProductMeasurement[];
  unselectProduct: () => void;
  loadProducts(): Promise<void>;
  loadPayments: () => Promise<IPayment[]>;
  selectProduct: (product: IProduct) => void;
  deleteProduct: (id: number) => Promise<void>;
  removeImage: (id: number) => Promise<void>;
  saveSku(sku: IProductSku[]): Promise<void>;
  favoriteProduct: (id: number) => Promise<void>;
  loadOnlyProducts: () => Promise<IProductSell[]>;
  inactivateProduct: (id: number) => Promise<void>;
  loadOnlyFavoriteProducts: () => Promise<IProduct[]>;
  calcProductPrice: (editProduct: IProductSell) => number;
  saveProduct(data: FormData, productId: number): Promise<IProduct | null>;
}

const ProductsContext = createContext<ProductsContextData>(
  {} as ProductsContextData,
);

export const ProductsProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const [data, setData] = useState<IProduct[]>([]);
  const [hasProducts, setHasProducts] = useState(true);
  const [isProductsLoading, setIsProductsLoading] = useState(true);
  const [selectedProduct, setSelectedProduct] = useState<IProduct | null>(null);
  const [lastEditedProductId, setLastEditedProductId] = useState(0);
  const [productMeasurements, setProductMeasurements] = useState<
    IProductMeasurement[]
  >([]);

  const transformProductDTO = (products: any[]): IProductSell[] => {
    return products.map(product => ({
      ...product,
      qty: 1, // Inicia com a quantidade 0
    }));
  };

  const calcProductPrice = useCallback((editProduct: IProductSell) => {
    if (!editProduct) return 0;
    // Calcula o preço inicial do produto
    const initialPrice = editProduct.isHalfPrice
      ? (editProduct.halfPrice ?? 0) * editProduct.qty
      : editProduct.unitPrice * editProduct.qty;

    // Calcula o total dos complementos
    const totalComplementPrice = editProduct?.complementsGroups
      ?.filter((group: any) => group.checked) // Filtra os grupos com 'checked' como true
      .reduce((totalGroupPrice, group) => {
        // Calcula o total dos complementos no grupo
        const groupTotal = group.complements
          .filter((complement: any) => complement.checked) // Filtra os complementos com 'checked' como true
          .reduce((totalComplementPrice, complement: any) => {
            return (
              totalComplementPrice +
              complement.unitPrice * (complement.amount || 1)
            );
          }, 0);
        // Acumula o total dos grupos
        return totalGroupPrice + groupTotal;
      }, 0);

    // Multiplica o total dos complementos pela quantidade do produto
    const finalComplementPrice = (totalComplementPrice ?? 0) * editProduct.qty;

    // Retorna o preço total somando o preço inicial e o total dos complementos ajustado
    return initialPrice + finalComplementPrice;
  }, []);

  const loadProducts = useCallback(async () => {
    try {
      setIsProductsLoading(true);
      const response = await api.get<IProduct[]>('restricted/products');
      setData(response.data);
      setIsProductsLoading(false);

      setHasProducts(response.data?.length > 0);

      const measurementsResponse = await api.get<IProductMeasurement[]>(
        '/products/measurements',
      );

      setProductMeasurements(measurementsResponse.data || []);
    } catch (e) {
      throw e;
    }
  }, []);

  const loadOnlyProducts = useCallback(async () => {
    try {
      const customer = JSON.parse(
        localStorage.getItem(LocalStorage.CUSTOMER as string) ?? '',
      );
      const { subdomain } = customer.companies[0];
      const response = await api.get<IProductSell[]>(
        'products?Modules=DELIVERY',
        {
          headers: {
            'X-Tenant-Id': subdomain,
          },
        },
      );

      return transformProductDTO(response.data);
    } catch (e) {
      throw e;
    }
  }, []);

  const loadPayments = useCallback(async () => {
    try {
      const customer = JSON.parse(
        localStorage.getItem(LocalStorage.CUSTOMER as string) ?? '',
      );
      const { subdomain } = customer.companies[0];
      const response = await api.get<IPayment[]>('restricted/payment-types', {
        headers: {
          'X-Tenant-Id': subdomain,
        },
      });

      return response.data;
    } catch (e) {
      throw e;
    }
  }, []);
  const loadOnlyFavoriteProducts = useCallback(async () => {
    try {
      const response = await api.get<IProduct[]>(
        'restricted/products/favorite',
      );
      return response.data;
    } catch (e) {
      throw e;
    }
  }, []);

  const saveSku = useCallback(
    async (sku: IProductSku[]) => {
      await api.post<IProduct[]>(
        `restricted/products/${company?.id}/skus`,
        sku,
      );
    },
    [company],
  );

  const saveProduct = useCallback(
    async (formData: FormData, productId: number) => {
      if (productId > 0) {
        const response = await api.put<IProduct>(
          `restricted/products/${productId}`,
          formData,
        );

        const newProducts = [...data];

        const index = newProducts.findIndex(p => p.id === productId);

        if (index > -1) {
          newProducts[index] = response.data;
          setData(newProducts);
        } else {
          setData([
            response.data,
            ...data.filter(product => product.id !== productId),
          ]);
          return response.data;
        }

        setLastEditedProductId(productId);
      } else {
        const response = await api.post<IProduct>(
          'restricted/products',
          formData,
        );

        setData([response.data, ...data]);
        setLastEditedProductId(response.data?.id || 0);
        return response.data;
      }

      return null;
    },
    [data],
  );

  const inactivateProduct = useCallback(
    async (id: number) => {
      const productToBeAltered = data.find(product => product.id === id);

      await api.patch(
        `restricted/products/${id}/${company?.id}/${
          productToBeAltered?.active ? 'deactivate' : 'activate'
        }`,
      );

      const newProducts = [...data];

      const index = newProducts.findIndex(
        product => product.id === productToBeAltered?.id,
      );

      newProducts[index].active = !newProducts[index].active;

      setData(newProducts);
    },
    [data, company],
  );

  const deleteProduct = useCallback(
    async (id: number) => {
      await api.delete(`restricted/products/${id}/${company?.id}`);
      setData(oldState => oldState.filter(product => product.id !== id));
    },
    [company],
  );

  const favoriteProduct = useCallback(
    async (id: number) => {
      const productToBeAltered = data.find(product => product.id === id);
      await api.patch(
        `restricted/products/${id}/${company?.id}/toggle-favorite`,
      );
      const newProducts = [...data];

      const index = newProducts.findIndex(
        product => product.id === productToBeAltered?.id,
      );

      newProducts[index].isFavorite = !newProducts[index].isFavorite;

      setData(newProducts);
    },
    [company, data],
  );

  const removeImage = useCallback(
    async (id: number) => {
      await api.delete(`restricted/products/${id}/${company?.id}/image`);

      const newData = [...data];
      const index = newData.findIndex(p => p.id === id);

      if (index > -1) {
        newData[index].imageUrl = '';
        setData(newData);
      }
    },
    [company, data],
  );

  const selectProduct = useCallback((product: IProduct) => {
    setSelectedProduct(product);
  }, []);

  const unselectProduct = useCallback(() => {
    setSelectedProduct(null);
  }, []);

  return (
    <ProductsContext.Provider
      value={{
        products: data,
        selectedProduct,
        lastEditedProductId,
        isProductsLoading,
        hasProducts,
        productMeasurements,
        saveSku,
        removeImage,
        saveProduct,
        loadProducts,
        loadPayments,
        selectProduct,
        deleteProduct,
        unselectProduct,
        favoriteProduct,
        loadOnlyProducts,
        calcProductPrice,
        inactivateProduct,
        loadOnlyFavoriteProducts,
      }}
    >
      {children}
    </ProductsContext.Provider>
  );
};

export function useProducts(): ProductsContextData {
  const context = useContext(ProductsContext);

  if (!context) {
    throw new Error('useProducts must be used within ProductsProvider');
  }

  return context;
}
