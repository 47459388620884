import { Form } from '@unform/web';
import styled from 'styled-components';

export const Wrapper = styled(Form)`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;

  @keyframes smooth-appearance {
    0% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;

export const DivPadding = styled.div`
  width: 100%;
  height: 8px;
`;
export const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 16px;
  }

  :first-child {
    margin-top: unset;
  }

  #contentContainer {
    margin-top: 0;
  }

  #settings-bsticket-products {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    #bs-ticket-order-products-button {
      margin-left: unset;
    }
  }
`;
export const GroupTitle = styled.div`
  border-bottom: 4px solid #db1622;

  font-weight: 500;
  font-size: 24px;

  button {
    border: 0;
    color: #fff;
    background: #db1622;
    width: 35px;
    height: 35px;
    margin-bottom: 16px;
  }
`;
