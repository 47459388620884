import React, { useCallback, useRef } from 'react';

import Ripples from 'react-ripples';
import { FiMinus, FiPlus } from 'react-icons/fi';

import { Container } from './styles';

interface IAmountInputProps {
  value: number;
  active?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  maxAmount?: number;
  inputWidth?: string;
  allowsZero?: boolean;
  onIncreaseAmount: (amount: number) => void;
  onDecreaseAmount: (amount: number) => void;
  setValue?: (e: number) => void;
}

const AmountInput: React.FC<IAmountInputProps> = ({
  value,
  disabled,
  inputWidth = '28px',
  readonly = false,
  maxAmount = 999999,
  allowsZero = false,
  setValue,
  onIncreaseAmount,
  onDecreaseAmount,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnIncrease = useCallback(() => {
    if (!disabled && maxAmount > value) {
      onIncreaseAmount(value + 1);
    }
  }, [disabled, maxAmount, value, onIncreaseAmount]);

  const handleOnDecrease = useCallback(() => {
    if (!allowsZero && value === 1) {
      return;
    }

    if (value === 0) {
      return;
    }

    onDecreaseAmount(value - 1);
  }, [onDecreaseAmount, value, allowsZero]);

  const handleChangeValue = (e: any) => {
    setValue && setValue(e.target.value);
  };

  return (
    <Container readonly={readonly} inputWidth={inputWidth}>
      {!readonly && (
        <Ripples
          color="#f4f4f480"
          className="ripple-1"
          onClick={handleOnDecrease}
        >
          <FiMinus />
        </Ripples>
      )}
      <input
        type="number"
        value={value}
        ref={inputRef}
        onClick={e => {
          e.stopPropagation();
          e.preventDefault();
          inputRef?.current?.focus();
          inputRef?.current?.select();
        }}
        onChange={e => {
          setValue && setValue(Number(e.target.value));
          handleChangeValue(e);
        }}
      />
      {!readonly && (
        <Ripples
          color="#f4f4f480"
          onClick={handleOnIncrease}
          className={`ripple-2 ${disabled ? 'disabled' : ''}`}
        >
          <FiPlus />
        </Ripples>
      )}
    </Container>
  );
};

export default AmountInput;
