import React, { useEffect, useMemo, useState } from 'react';
import { FiEdit, FiTrash } from 'react-icons/fi';
import {
  Image,
  Actions,
  Infos,
  EditInput,
  TransferInput,
  ProductCartWrapper,
} from './styles';

import { ReactComponent as ProductPlaceholderSVG } from '../../assets/icons/product_placeholder.svg';

import { randomInt } from '../../utils/numbers';
import { formatToMoney } from '../../utils/format';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import IOrder from '../../models/IOrder';
import IComplement from '../../models/IComplement';
import { IProductCart } from '../../models/IProduct';
import IComplementsGroup from '../../models/IComplementsGroup';

import IconButton from '../IconButton';
import AmountInput from '../AmountInput';
import DeleteOrderProduct from '../DeleteProductOrder';

interface IProductCartProps {
  orderId?: number;
  selectAll?: boolean;
  product: IProductCart;
  transfer?: boolean;
  transferItems?: IProductCart[] | [];
  reason?: boolean;
  onIncreaseAmount?: (amount: number) => void;
  onDecreaseAmount?: (amount: number) => void;
  editProduct?: (product: IProductCart) => void;
  deleteProduct?: (product: IProductCart) => void;
  setOrder?: React.Dispatch<React.SetStateAction<IOrder>>;
  setTransferItems?: React.Dispatch<React.SetStateAction<IProductCart[] | []>>;
}
const ProductCart: React.FC<IProductCartProps> = ({
  reason = false,
  orderId,
  product,
  transfer,
  selectAll,
  transferItems,
  setOrder,
  editProduct,
  deleteProduct,
  onIncreaseAmount,
  onDecreaseAmount,
  setTransferItems,
}) => {
  const { showConfirmDialog } = useConfirmDialog();
  const [animate, setAnimate] = useState(!!transfer);
  const [qtyTransfer, setQtyTransfer] = useState(0);
  const [openModal, setOpenModal] = useState(false);

  const productIcon = useMemo(() => {
    if (product.imageUrl) {
      return <img src={product.imageUrl} alt={product.title} />;
    }

    const classes = ['blue', 'red', 'orange', 'green', 'yellow'];

    const randomClass = classes[randomInt(0, classes.length - 1)];
    return <ProductPlaceholderSVG className={randomClass} />;
  }, [product]);

  useEffect(() => {
    if (selectAll) {
      setQtyTransfer(product.qty);
    } else {
      setQtyTransfer(0);
    }
  }, [product, selectAll]);

  useEffect(() => {
    if (transfer) {
      setAnimate(true);
      // Reseta a animação após completá-la
      setTimeout(() => setAnimate(false), 500); // Duração da animação é de 0.5s
    }
  }, [transfer]);

  useEffect(() => {
    !transfer && setQtyTransfer(0);
  }, [transfer]);

  const handleSetTransferItems = (increase: boolean) => {
    if (increase && product.qty > qtyTransfer) {
      const changedProduct = { ...product, qty: qtyTransfer + 1 };
      if (transferItems && transferItems.some(prod => prod.id === product.id)) {
        setTransferItems &&
          setTransferItems(prev => {
            const filteredArray = prev.filter(prod => prod.id !== product.id);
            return [...filteredArray, changedProduct];
          });
      } else {
        setTransferItems &&
          setTransferItems(prev =>
            prev ? [...prev, changedProduct] : [changedProduct],
          );
      }
      setQtyTransfer(prev => prev + 1);
    } else if (!increase && qtyTransfer > 0) {
      const changedProduct = { ...product, qty: qtyTransfer - 1 };

      if (transferItems && transferItems.some(prod => prod.id === product.id)) {
        setTransferItems &&
          setTransferItems(prev => {
            const filteredArray = prev.filter(prod => prod.id !== product.id);
            return [...filteredArray, changedProduct];
          });
      }

      if (qtyTransfer === 1) {
        setTransferItems &&
          setTransferItems(prev => {
            const filteredArray = prev.filter(prod => prod.id !== product.id);
            return filteredArray;
          });
      }
      setQtyTransfer(prev => prev - 1);
    }
  };

  const handleClickDelete = () => {
    if (reason) {
      setOpenModal(true);
    } else {
      showConfirmDialog({
        title: 'Remover Produto',
        message: `Deseja remover o produto ${product.title}?`,
        onCancel: () => null,
        onConfirm: () => deleteProduct && deleteProduct(product),
      });
    }
  };
  return (
    <>
      <ProductCartWrapper
        animate={animate}
        transfer={qtyTransfer > 0}
        onClick={() => transfer && handleSetTransferItems(true)}
      >
        <Image>{productIcon}</Image>
        <Infos>
          <p>
            {product.qty}
            {` - `}
            {product.title}
          </p>
          <p className="complements">
            {product.complementsGroups.map((compG: IComplementsGroup) =>
              compG.complements.map((comp: IComplement) => `${comp.title}, `),
            )}
          </p>
          <div className="price">
            <p>{formatToMoney(product.total ?? 0)}</p>
            {onIncreaseAmount && onDecreaseAmount && (
              <EditInput>
                <AmountInput
                  {...(transfer && { readonly: true })}
                  allowsZero
                  value={product.qty}
                  onIncreaseAmount={() => onIncreaseAmount(product.qty)}
                  onDecreaseAmount={() => onDecreaseAmount(product.qty)}
                />
              </EditInput>
            )}
          </div>
        </Infos>
        <Actions>
          {editProduct && (
            <div className="edit common-background">
              <IconButton
                onClick={() => editProduct(product)}
                hover={false}
                icon={<FiEdit color="#fff" size={18} />}
              />
            </div>
          )}
          {(deleteProduct || reason) && (
            <div
              className={`${
                editProduct ? 'delete' : 'edit'
              }  common-background`}
            >
              <IconButton
                hover={false}
                onClick={handleClickDelete}
                icon={<FiTrash color="#fff" size={18} />}
              />
            </div>
          )}
          {transfer && (
            <TransferInput>
              <AmountInput
                allowsZero
                value={qtyTransfer}
                maxAmount={product.qty}
                onIncreaseAmount={() => handleSetTransferItems(true)}
                onDecreaseAmount={() => handleSetTransferItems(false)}
              />
            </TransferInput>
          )}
        </Actions>
      </ProductCartWrapper>
      {openModal && (
        <DeleteOrderProduct
          setOrder={setOrder}
          open={openModal}
          setOpen={setOpenModal}
          orderId={orderId ?? 0}
          productId={Number(product?.id) ?? 0}
        />
      )}
    </>
  );
};

export default ProductCart;
