/* eslint-disable */
export const selectStyles = {
  option: (provided: any, state: { isSelected: any }) => ({
    ...provided,
    color: '#000',
    padding: 20,
    backgroundColor: state.isSelected ? '#db1622' : '#fff',
    '&:hover': {
      backgroundColor: '#db162220',
    },
  }),
  control: (provided: any, state: any) => ({
    ...provided,
    border: state.selectProps.error ? '1px solid #db1622' : '1px solid #aaa',
    borderColor: state.selectProps.error ? '#db1622' : '#aaa',
    boxShadow: 0,
    '&:hover': {
      borderColor: '#db1622!important',
    },
  }),
  placeholder: (provided: any, state: any) => ({
    ...provided,
    color: state.selectProps.error ? '#db1622' : '#aaa',
  }),
  menuPortal: (base: any) => ({ ...base, zIndex: 9999 }),
  menu: (provided: any) => ({
    ...provided,
    zIndex: 9999,
  }),
};
