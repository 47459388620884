import styled from 'styled-components';

export const ComplementsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin-top: 30px;
`;
export const Name = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 8px;
  color: ${({ theme }) => theme.palette.background_white};
  background-color: ${({ theme }) => theme.palette.primary_light};

  p {
    padding: 5px;
    border-radius: 16px;
    font-size: 12px;
  }
  .obg {
    background-color: ${({ theme }) => theme.palette.primary};
  }
  .opc {
    background-color: #fdd400;
  }
`;
type IComplementProps = {
  disabled?: boolean;
};
export const Complement = styled.div<IComplementProps>`
  display: flex;
  width: 100%;
  align-items: center;
  border-radius: 16px;
  justify-content: space-between;
  padding-bottom: 5px;
  border-bottom: 1px solid ${({ theme }) => theme.palette.background};
  color: ${({ theme, disabled }) => disabled && theme.palette.unselected};
`;
export const ComplementContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 8px;
  gap: 10px;
  margin: 10px 0;
`;

export const InputContainer = styled.div`
  max-width: 80px;
  height: auto;
`;
export const Price = styled.div`
  font-size: 12px;
  padding: 5px;
  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.primary};
  color: ${({ theme }) => theme.palette.text_white};
`;
