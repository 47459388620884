import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 12px 0;
  background: transparent !important;
`;

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;

  transition: box-shadow 0.4s;

  box-shadow: ${({ theme }) => theme.shadow.box_shadow};

  &:hover {
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_hover};
  }

  border: 0;
  color: ${({ theme }) => theme.palette.text_white};
  background: ${({ theme }) => theme.palette.primary};

  padding: 16px 48px;

  position: relative;
`;

export const LoadingContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.palette.primary};
  display: flex;
  justify-content: center;
  align-items: center;
`;
