import styled from 'styled-components';
import { Form } from '@unform/web';
import Radio from '../../Radio';

export const Wrapper = styled(Form)`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

export const Container = styled.div`
  width: 100%;
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

export const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 16px;
  }

  :first-child {
    margin-top: unset;
  }

  #contentContainer {
    margin-top: 0;
  }

  #settings-bsticket-products {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    #bs-ticket-order-products-button {
      margin-left: unset;
    }
  }
`;

export const GroupTitle = styled.div`
  border-bottom: 4px solid #db1622;

  font-weight: 500;
  font-size: 24px;

  button {
    border: 0;
    color: #fff;
    background: #db1622;
    width: 35px;
    height: 35px;
    margin-bottom: 16px;
  }
`;
export const GroupSubTitles = styled.span`
  width: fit-content;
  border-bottom: 4px solid #db1622;
  margin-top: 32px;
  font-size: 18px;
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 32px;

  span {
    margin-left: 16px;
  }
`;
export const GroupDescription = styled.p`
  margin-top: 16px;
`;
export const RadioContainer = styled(Radio.Group)`
  display: flex;
  flex-wrap: nowrap;
  margin-bottom: -4px;

  input {
    display: none;
  }

  label {
    margin-top: 8px;
    user-select: none;
    cursor: pointer;
    margin-right: 1.5rem;
    font-size: 1.2rem;
    padding-bottom: 8px;
    transition: border-color 200ms ease;
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.palette.text};

    svg {
      margin-left: 0.4rem;
    }

    .checked {
      display: none;
    }
  }

  input:checked + label {
    border-color: ${({ theme }) => theme.palette.primary};
    .unchecked {
      display: none;
    }
    .checked {
      display: block;
    }
  }

  @media (max-width: 800px) {
    label {
      font-size: 1rem;
    }
  }

  @media (max-width: 600px) {
    flex-wrap: wrap;

    label {
      margin-bottom: 0.4rem;
    }
  }
`;
