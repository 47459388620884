import { useCallback, useRef } from 'react';

export const useDebounce = (
  callback: (...args: any[]) => void,
  delay: number,
): ((...args: any[]) => void) => {
  const timer = useRef<number>();

  return useCallback(
    (...args) => {
      if (timer.current) clearTimeout(timer.current);
      timer.current = window.setTimeout(() => callback(...args), delay);
    },
    [callback, delay],
  );
};
