import React, { useEffect, useState } from 'react';
import { FiArrowLeft, FiEdit } from 'react-icons/fi';
import {
  Close,
  FalseInput,
  Form,
  Line,
  Grid,
  Header,
  NotFound,
  SelectAll,
  Wrapper,
} from './styles';

import ProductCart from '../ProductCart';
import IconButton from '../IconButton';

import ButtonCartLaunch from '../ButtonCartLaunch';

import IOrder from '../../models/IOrder';
import { ICard } from '../../models/ICard';
import { ITable2 } from '../../models/ITable2';
import { statusCardTableType } from '../../enums/cardAndTableType';

import { useCards } from '../../hooks/card';
import { useTable } from '../../hooks/table';
import { ITransferOrder } from '../../hooks/internal_orders';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import Drawer from '../Drawer';
import TransferProduct from '../TransferProduct';
import { IProductCart } from '../../models/IProduct';
import { ModalEditInfosCardTable } from '../ModalEditInfosCardTable';
// import Loading from '../Loading';

interface ICartCardTabbleProps {
  card?: ICard;
  table?: ITable2;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setCardState?: React.Dispatch<React.SetStateAction<ICard>>;
  setTableState?: React.Dispatch<React.SetStateAction<ITable2>>;
}
const DrawerCardTable: React.FC<ICartCardTabbleProps> = ({
  card,
  table,
  open,
  setOpen,
  setCardState,
  setTableState,
}) => {
  const { getOrderCart } = useCards();
  const { getOrderTable } = useTable();
  const { showConfirmDialog, ThrowError } = useConfirmDialog();

  const [loading, setLoading] = useState(false);
  const [transfer, setTransfer] = useState(false);
  const [openSell, setOpenSell] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);
  const [openEditInfos, setOpenEditInfos] = useState(false);
  // const [loadingAmount, setLoadingAmount] = useState(false);
  const [openTransferProduct, setOpenTransferProduct] = useState(false);
  const [transferItems, setTransferItems] = useState<IProductCart[] | []>([]);

  useEffect(() => {
    if (open && card?.status !== statusCardTableType.FREE) {
      const fetchCardOrTable = async () => {
        setLoading(true);
        try {
          const responseCard = card && (await getOrderCart(card.cardNumber));
          const responseTable =
            table && (await getOrderTable(table.tableNumber));

          card && responseCard && setOrder(responseCard?.data);
          table && responseTable && setOrder(responseTable?.data);

          setCardState &&
            setCardState(prev => ({
              ...prev,
              customerName: responseCard?.data.customerName,
              customerId: responseCard?.data.customerId,
            }));

          setTableState &&
            setTableState(prev => ({
              ...prev,
              personQty: responseTable?.data.personQty,
              customerName: responseTable?.data.customerName,
              customerId: responseTable?.data.customerId,
            }));
        } catch (error) {
          ThrowError(error, 'Erro ao ver mesa!');
          setOpen(false);
        } finally {
          setLoading(false);
        }
      };

      fetchCardOrTable();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [open, getOrderCart, getOrderTable, setCardState, setTableState]);

  // const incrementProduct = async (
  //   product: IProductCart,
  //   increment: boolean,
  // ) => {
  //   try {
  //     setLoadingAmount(true);
  //     const productToAmount = order?.items.find(
  //       (item: any) => item.id === product.id,
  //     );
  //     const qtyAtt = increment ? product.qty + 1 : product.qty - 1;
  //     if (qtyAtt === 0) {
  //       showConfirmDialog({
  //         title: 'Remover Produto',
  //         message: `Deseja remover o produto ${product.title}?`,
  //         onCancel: () => null,
  //         onConfirm: async () => {
  //           setLoadingAmount(true);
  //           const response = await deleteItem(
  //             product.orderId,
  //             Number(product?.id),
  //           );
  //           setOrder(response.data);
  //           setLoadingAmount(false);
  //         },
  //       });
  //     } else {
  //       const bodyDTO = {
  //         ...productToAmount,
  //         qty: qtyAtt,
  //         id: String(productToAmount?.id),
  //         complementsGroups: productToAmount?.complementsGroups ?? [],
  //         orderId: productToAmount?.orderId ?? 0,
  //         productId: productToAmount?.productId ?? 0,
  //         title: productToAmount?.title ?? '',
  //         unitPrice: productToAmount?.unitPrice ?? 0,
  //         imageUrl: productToAmount?.imageUrl ?? '',
  //         description: productToAmount?.imageUrl ?? '',
  //       };
  //       const response = await amountProduct(Number(bodyDTO.id), bodyDTO);
  //       response && setOrder(response.data);
  //     }
  //   } catch (error) {
  //     ThrowError(error, 'Erro ao alterar quantidade');
  //   } finally {
  //     setLoadingAmount(false);
  //   }
  // };

  const bodyDTO: ITransferOrder = {
    orderId: order?.id ?? 0,
    EOrderTransferTypeOrigin: card ? 'CARD' : 'TABLE',
    originNumber: card ? card?.cardNumber : table ? table?.tableNumber : 0,
    EOrderTransferTypeDestination: 'TABLE',
    destinationNumber: 1,
    items: transferItems,
  };

  const handleOpen = () => {
    setOpen(prev => !prev);
    setTransfer(false);
    setTransferItems([]);
  };

  const handleOpenTransfer = () => {
    if (bodyDTO.items?.length === 0) {
      showConfirmDialog({
        title: 'Erro ao enviar!',
        message: 'Deve estar selecionado ao menos 1 produto',
        onConfirm: () => null,
      });
    } else {
      setOpenTransferProduct(true);
    }
  };

  const handleSelectAll = (checked: boolean) => {
    if (checked) {
      if (order && order.items) {
        setTransferItems(order.items);
      } else {
        setTransferItems([]);
      }
      setSelectAll(true);
    } else {
      setTransferItems([]);
      setSelectAll(false);
    }
  };

  return (
    <>
      <Drawer open={open} setOpen={handleOpen} loading={loading} zindex={1}>
        <Wrapper>
          <Header>
            <div className="name">
              <Close>
                <IconButton
                  onClick={() => {
                    setOpen(false);
                    setTransfer(false);
                  }}
                  icon={<FiArrowLeft size={22} />}
                />
              </Close>
              {table && <h2>{`Mesa ${table.tableNumber}`}</h2>}
              {card && <h2>{`Comanda ${card.cardNumber}`}</h2>}
            </div>
            <Form>
              {card && (
                <Line>
                  <FalseInput onClick={() => setOpenEditInfos(true)}>
                    <p className="first">Identificação</p>
                    <p className="second">
                      {card?.description ?? 'Informe uma identificação...'}
                    </p>
                  </FalseInput>
                  <FalseInput onClick={() => setOpenEditInfos(true)}>
                    <p className="first">Localização</p>
                    <p className="second">
                      {card?.location ?? 'Informe uma localização...'}
                    </p>
                  </FalseInput>
                </Line>
              )}
              {table && (
                <Line>
                  <FalseInput onClick={() => setOpenEditInfos(true)}>
                    <p className="first">Identificação</p>
                    <p className="second">
                      {table?.description ?? 'Informe uma identificação...'}
                    </p>
                  </FalseInput>
                  <FalseInput
                    onClick={() => setOpenEditInfos(true)}
                    className="people"
                  >
                    <p className="first">Pessoas</p>
                    <p className="number">{table.personQty ?? 1}</p>
                  </FalseInput>
                </Line>
              )}

              <Line>
                <FalseInput onClick={() => setOpenEditInfos(true)}>
                  <p className="first">Cliente</p>
                  <p className="second">
                    {card
                      ? card?.customerName ?? 'Selecione um Cliente...'
                      : table?.customerName ?? 'Selecione um Cliente...'}
                  </p>
                </FalseInput>
                <div className="icon">
                  <IconButton
                    onClick={() => setOpenEditInfos(true)}
                    icon={<FiEdit size={22} />}
                  />
                </div>
              </Line>
            </Form>
          </Header>
          {/* {loadingAmount ? (
            <Loading />
          ) : ( */}
          <Grid className="has-custom-scroll-bar-2">
            {transfer && (
              <SelectAll>
                <h4>Selecionar todos</h4>
                <input
                  type="checkbox"
                  onChange={e => handleSelectAll(e.currentTarget.checked)}
                />
              </SelectAll>
            )}

            {order.items && order?.items.length === 0 ? (
              <NotFound>
                <p>Ainda não há nenhum produto</p>
                <button
                  type="button"
                  className="link"
                  onClick={() => setOpenSell(true)}
                >
                  Adicionar produto
                </button>
              </NotFound>
            ) : (
              order?.items &&
              order?.items.map((product: any, index: number) => (
                <ProductCart
                  // eslint-disable-next-line react/no-array-index-key, react/jsx-indent
                  key={index}
                  reason
                  orderId={order?.id}
                  product={product}
                  transfer={transfer}
                  selectAll={selectAll}
                  transferItems={transferItems}
                  setOrder={setOrder}
                  setTransferItems={setTransferItems}
                  // onDecreaseAmount={() => incrementProduct(product, false)}
                  // onIncreaseAmount={() => incrementProduct(product, true)}
                />
              ))
            )}

            {order.item && order?.items.length !== 0 && (
              <button
                type="button"
                className="link"
                onClick={() => setOpenSell(true)}
              >
                Adicionar produto
              </button>
            )}
          </Grid>
          {/* )} */}
        </Wrapper>

        <ButtonCartLaunch
          order={order}
          card={card}
          table={table}
          transfer={transfer}
          openSell={openSell}
          setTransfer={setTransfer}
          setOpenSell={setOpenSell}
          setCardState={setCardState}
          setTableState={setTableState}
          handleOpenTransfer={handleOpenTransfer}
        />
      </Drawer>

      {openEditInfos && (
        <ModalEditInfosCardTable
          card={card}
          table={table}
          order={order}
          open={openEditInfos}
          setOpen={setOpenEditInfos}
          setCardState={setCardState}
          setTableState={setTableState}
        />
      )}

      {openTransferProduct && (
        <TransferProduct
          body={bodyDTO}
          open={openTransferProduct}
          setOpen={setOpenTransferProduct}
        />
      )}
    </>
  );
};

export default DrawerCardTable;
