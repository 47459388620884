import React, { useMemo, useRef } from 'react';

import { DateTime } from 'luxon';
import ReactToPrint from 'react-to-print';
import { FiPrinter } from 'react-icons/fi';

import IOrder from '../../models/IOrder';

import { getDeliveryTypeName } from '../../utils/string';

import {
  Main,
  Item,
  Items,
  Bottom,
  Footer,
  Header,
  ItemName,
  Container,
  OrderDate,
  OrderType,
  ApprovedBy,
  Complement,
  ItemsTitle,
  OrderNumber,
  PrintButton,
  ItemComments,
  CustomerName,
  ItemOptionals,
  ComplementName,
  ItemsContainer,
  ItemAdditionals,
  ComplementAmount,
  CustomerContainer,
  HorizontalWrapper,
  ItemOptionalsTitle,
  ItemAdditionalsTitle,
  ComplementsContainer,
} from './styles';
import { useCompany } from '../../hooks/company';
import { IProductCart } from '../../models/IProduct';
import IComplement from '../../models/IComplement';

interface KitchenPrintItemProps {
  order: IOrder | null;
  type?: string;
}

export interface IGroupedItem {
  item: IProductCart;
  pizzaSlices?: number;
  pizza: IComplement[];
  optionals: IComplement[];
  additionals: IComplement[];
}

const KitchenPrintItem: React.FC<KitchenPrintItemProps> = ({ order, type }) => {
  const printRef = useRef<HTMLDivElement | null>(null);
  const { company } = useCompany();

  const groupedItems: IGroupedItem[] = useMemo(() => {
    if (order) {
      return order.items.map(item => {
        const pizza: IComplement[] = [];
        const optionals: IComplement[] = [];
        const additionals: IComplement[] = [];

        item.complementsGroups.forEach(g => {
          g.complements.forEach(c => {
            if (g.category === 'PIZZA') {
              pizza.push(c);
            } else if (c.unitPrice > 0) {
              additionals.push(c);
            } else {
              optionals.push(c);
            }
          });
        });

        return {
          item,
          pizza,
          pizzaSlices: item?.pizzaSizes?.reduce(
            (acc, current) => acc + current?.amount,
            0,
          ),
          optionals,
          additionals,
        };
      });
    }

    return [];
  }, [order]);

  const getDivisor = useMemo(() => {
    return (groupAmount: number, complementAmount: number) => {
      if (groupAmount === complementAmount) {
        return '-';
      }

      switch (groupAmount) {
        case 2:
          return `${complementAmount}/2`;
        case 3:
          return `${complementAmount}/3`;
        case 4:
          return `${complementAmount}/4`;
        default:
          return '';
      }
    };
  }, []);

  const complementTitle = useMemo(() => {
    return (complement: IComplement, item: IGroupedItem) => {
      const group = item.item.complementsGroups.find(
        g => g.id === complement.complementGroupId,
      );

      if (group && group.category === 'PIZZA') {
        return `${getDivisor(group.minAmount, complement?.amount ?? 1)} ${
          complement.title
        }`;
      }

      return `${complement.amount}x ${complement.title}`;
    };
  }, [getDivisor]);

  const tableNumber = useMemo(() => {
    if (order?.cardNo) {
      return `Mesa ${order.tableNo} - Comanda ${order.cardNo}`;
    }

    return `Mesa ${order?.tableNo}`;
  }, [order]);

  const CorrectOrderName = useMemo(
    () => () => {
      if (order?.tableNo) {
        return (
          <CustomerContainer>
            <CustomerName>{tableNumber}</CustomerName>
          </CustomerContainer>
        );
      }

      if (order?.receiverName) {
        return (
          <CustomerContainer>
            <CustomerName>{order.receiverName}</CustomerName>
          </CustomerContainer>
        );
      }

      return (
        <CustomerContainer>
          <CustomerName>BS Ticket</CustomerName>
        </CustomerContainer>
      );
    },
    [order, tableNumber],
  );

  return (
    <>
      <PrintButton type={type}>
        <ReactToPrint
          trigger={() => (
            <button type="button" id="print_button">
              <FiPrinter size={22} />
              Imprimir
            </button>
          )}
          content={() => printRef.current}
        />
      </PrintButton>
      <Container className="has-custom-scroll-bar-2" ref={printRef}>
        <Header>
          <OrderNumber>
            <span>Pedido</span>
            <strong>{`#${order?.number}`}</strong>
          </OrderNumber>
          <OrderType>{getDeliveryTypeName(order?.orderType || '')}</OrderType>
        </Header>
        {order?.scheduledProduct && (
          <OrderType>
            <span className="value">
              {`Agendado para: ${order.scheduledProduct.slice(0, -3)}h`}
            </span>
          </OrderType>
        )}
        <Main>
          <CorrectOrderName />
          <ItemsContainer>
            <ItemsTitle>
              <span>produto</span>
            </ItemsTitle>
            <Items>
              {groupedItems?.map(g => (
                <Item key={g.item.id}>
                  <HorizontalWrapper>
                    <ItemName>
                      <strong>{`${g.item.qty}x`}</strong>
                      <span>{`${g.item.title}`}</span>
                    </ItemName>
                  </HorizontalWrapper>
                  <ItemComments>{g.item.comments}</ItemComments>
                  {g.pizza.length > 0 && (
                    <ItemAdditionals>
                      <ItemAdditionalsTitle>
                        Sabores da pizza
                      </ItemAdditionalsTitle>
                      {g.pizza.map(a => (
                        <Complement key={a.id}>
                          <div>
                            <ComplementName>
                              {complementTitle(a, g)}
                            </ComplementName>
                          </div>
                        </Complement>
                      ))}
                    </ItemAdditionals>
                  )}
                  {g?.item?.isCustomPizza && (
                    <>
                      {g?.item?.pizzaSizes && (
                        <ItemAdditionals>
                          <ItemAdditionalsTitle>Sabores</ItemAdditionalsTitle>
                          {g?.item?.pizzaSizes?.map(size => (
                            <Complement key={`pizzaSize-${size.id}`}>
                              <div>
                                <ComplementAmount>
                                  {`${size.amount}/${g?.pizzaSlices || 0}`}
                                </ComplementAmount>
                                <ComplementName>{size.title}</ComplementName>
                              </div>
                            </Complement>
                          ))}
                        </ItemAdditionals>
                      )}
                      {(g?.item?.pizzaCrusts?.length || 0) > 0 && (
                        <ItemAdditionals>
                          <ItemAdditionalsTitle>Massa</ItemAdditionalsTitle>
                          {g?.item?.pizzaCrusts?.map(crust => (
                            <Complement key={`pizzaCrust-${crust.id}`}>
                              <div>
                                <ComplementName>{crust.title}</ComplementName>
                              </div>
                            </Complement>
                          ))}
                        </ItemAdditionals>
                      )}
                      {(g?.item?.pizzaEdges?.length || 0) > 0 && (
                        <ItemAdditionals>
                          <ItemAdditionalsTitle>Borda</ItemAdditionalsTitle>
                          {g?.item?.pizzaEdges?.map(edge => (
                            <Complement key={`pizzaEdge-${edge.id}`}>
                              <div>
                                <ComplementName>{edge.title}</ComplementName>
                              </div>
                            </Complement>
                          ))}
                        </ItemAdditionals>
                      )}
                    </>
                  )}
                  <ComplementsContainer
                    inverse={company?.orderPrintInvertComplements}
                  >
                    {g.additionals.length > 0 && (
                      <ItemAdditionals>
                        {company?.showPrintComplementsTitle && (
                          <ItemAdditionalsTitle>
                            Adicionais
                          </ItemAdditionalsTitle>
                        )}
                        {g.additionals.map(a => (
                          <Complement key={a.id}>
                            <div>
                              <ComplementName>
                                {complementTitle(a, g)}
                              </ComplementName>
                            </div>
                          </Complement>
                        ))}
                      </ItemAdditionals>
                    )}
                    {g.optionals.length > 0 && (
                      <ItemOptionals>
                        {company?.showPrintComplementsTitle && (
                          <ItemOptionalsTitle>Opcionais</ItemOptionalsTitle>
                        )}
                        {g.optionals.map(o => (
                          <Complement key={o.id}>
                            <div>
                              <ComplementAmount>{`${o.amount}x`}</ComplementAmount>
                              <ComplementName>{o.title}</ComplementName>
                            </div>
                          </Complement>
                        ))}
                      </ItemOptionals>
                    )}
                  </ComplementsContainer>
                  <Bottom />
                </Item>
              ))}
            </Items>
          </ItemsContainer>
        </Main>
        <Footer>
          {order && order.approvedName && (
            <ApprovedBy>{`Aprovado por ${order.approvedName}`}</ApprovedBy>
          )}
          <OrderDate>
            {`Data do pedido: ${DateTime.fromISO(
              order?.createdAt || '',
            ).toFormat("dd/MM/yyyy 'às' HH:mm:ss")}`}
          </OrderDate>
        </Footer>
      </Container>
    </>
  );
};

export default KitchenPrintItem;
