import React, { useCallback, useEffect, useRef, useState } from 'react';

import * as Yup from 'yup';
import { Form } from '@unform/web';
import { FormHandles } from '@unform/core';
import { FiEye, FiEyeOff } from 'react-icons/fi';

import { useToast } from '../../hooks/toast';
import { PageNames } from '../../enums/pages';

import Input from '../../components/Input';
import Loading from '../../components/Loading';
import TextArea from '../../components/Textarea';

import MultipleImageInput, {
  IMultipleImageRef,
} from '../../components/MultipleHistoryImageInput';

import api from '../../services/api';
import { IHistory } from '../../models/IHistory';
import { getValidationErrors } from '../../utils/errors';

import { Header, Container, ActiveContainer, LoadingContainer } from './styles';
import SaveButton from '../../components/SaveButton';

const HistoryPage: React.FC = () => {
  const { addToast } = useToast();

  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isUpdate, setIsUpdate] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const formRef = useRef<FormHandles>(null);
  const imagesRef = useRef<IMultipleImageRef>(null);

  const loadImages = useCallback(async () => {
    setLoading(true);

    try {
      const { data } = await api.get<IHistory>(
        `/restricted/companies/histories`,
      );

      if (data) {
        setIsUpdate(true);
        setIsActive(data.active);
        formRef.current?.setData(data);
        imagesRef.current?.setValue(data.images);
      } else {
        setIsActive(true);
      }
    } catch (e) {
      addToast({
        type: 'error',
        description: 'Erro ao buscar informações, tente novamente.',
      });
    } finally {
      setLoading(false);
    }
  }, [addToast]);

  const handleSubmit = useCallback(async () => {
    formRef.current?.setErrors({});

    const response = formRef.current?.getData() as IHistory;

    const data = { ...response, active: isActive };

    const schema = Yup.object().shape({
      title: Yup.string()
        .min(3, 'Mínimo de 3 caracteres.')
        .max(60, 'Máximo de 60 caracteres.'),
      content: Yup.string()
        .min(3, 'Mínimo de 3 caracteres.')
        .max(5000, 'Máximo de 5000 caracteres.'),
    });

    try {
      await schema.validate(data, {
        abortEarly: false,
      });

      setSaving(true);

      await imagesRef.current?.uploadImages();

      if (isUpdate) {
        await api.put('/restricted/companies/histories', {
          ...data,
          images: undefined,
        });
      } else {
        await api.post('/restricted/companies/histories', {
          ...data,
          images: undefined,
        });
      }

      addToast({
        type: 'success',
        description: `História ${
          isUpdate ? 'atualizada' : 'salva'
        } com sucesso.`,
      });
    } catch (errors) {
      if (errors instanceof Yup.ValidationError) {
        return formRef.current?.setErrors(getValidationErrors(errors));
      }

      return addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }

    return setSaving(false);
  }, [addToast, isUpdate, isActive]);

  const handleActiveClick = useCallback(() => {
    setIsActive(old => !old);
  }, []);

  useEffect(() => {
    loadImages();
  }, [loadImages]);

  return (
    <Container>
      {loading && (
        <LoadingContainer>
          <Loading color="primary" radius={32} stroke={2} />
        </LoadingContainer>
      )}
      <Header>
        <h1>{PageNames.HISTORY}</h1>
        <ActiveContainer onClick={handleActiveClick}>
          <span>{isActive ? 'Ativado' : 'Desativado'}</span>
          {isActive ? <FiEye size={24} /> : <FiEyeOff size={24} />}
        </ActiveContainer>
      </Header>
      <MultipleImageInput
        ref={imagesRef}
        className={!isActive ? 'disabled' : ''}
      />
      <br />
      <Form
        onSubmit={() => null}
        ref={formRef}
        className={!isActive ? 'disabled' : ''}
      >
        <Input
          name="title"
          title="Título"
          minLength={3}
          maxLength={60}
          required
        />
        <br />
        <TextArea
          name="content"
          title="Conteúdo"
          minLength={3}
          maxLength={5000}
          required
        />
      </Form>
      <SaveButton
        onClick={handleSubmit}
        loading={saving}
        className="mobile-margin"
      />
    </Container>
  );
};

export default HistoryPage;
