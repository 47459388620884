import React, { forwardRef, useImperativeHandle, useMemo, useRef } from 'react';
import VMasker from 'vanilla-masker';
import { useReactToPrint } from 'react-to-print';
import ICashierReport from '../../models/ICashierReport';
import { useCompany } from '../../hooks/company';

import {
  Wrapper,
  Header,
  Section,
  SectionHeader,
  SectionTitle,
  SectionSubtitle,
  Item,
  Right,
  Clear,
  Line,
  Highlight,
  Footer,
  ItemTable,
  ItemTableRow,
  TableSectionTitle,
} from './styles';
import { formatToFullLocalDate, formatToMoney } from '../../utils/format';
import { getPaymentTypeName } from '../../utils/string';
import { CashMovement } from '../../enums/cashMovement';
import { useDevices } from '../../hooks/devices';
import { useCashierReport } from '../../hooks/cashier_report';

interface ICashierReportPrintProps {
  cashierReport: ICashierReport;
}

export interface ICashierReportPrintRef {
  print: () => void;
}

const CashierReportPrint: React.ForwardRefRenderFunction<
  ICashierReportPrintRef,
  ICashierReportPrintProps
> = ({ cashierReport }, ref) => {
  const { filtersStates } = useCashierReport();

  const { company } = useCompany();

  const { devices } = useDevices();

  const containerRef = useRef<HTMLDivElement>(null);

  const formattedDocument = useMemo(() => {
    return VMasker.toPattern(company?.document || '', '99.999.999/9999-99');
  }, [company]);

  const totalAmount = useMemo(() => {
    return cashierReport?.salesPaymentMethods?.reduce(
      (acc, current) => acc + current.paymentAmount,
      0,
    );
  }, [cashierReport]);

  const movementsTotalAmount = useMemo(() => {
    return cashierReport?.cashMovements?.reduce((acc, current) => {
      if (current.type === CashMovement.BLEED) {
        return acc - current.amount;
      }

      return acc + current.amount;
    }, 0);
  }, [cashierReport]);

  const productsSalesTotal = useMemo(() => {
    return cashierReport?.items?.reduce(
      (acc, current) =>
        acc +
        current.items.reduce(
          (itemAcc, currentItem) => itemAcc + currentItem.total,
          0,
        ),
      0,
    );
  }, [cashierReport]);

  // const foundDevice = devices.find(
  //   device => device.deviceId === cashierReport?.shift.deviceUuid,
  // );

  const foundDevice = devices.find(
    device => device.deviceId === filtersStates.device.get,
  );

  const handlePrint = useReactToPrint({
    content: () => containerRef.current,
  });

  useImperativeHandle(
    ref,
    () => ({
      print: () => handlePrint && handlePrint(),
    }),
    [handlePrint],
  );
  return (
    <Wrapper ref={containerRef}>
      <Header>
        <h2>Relatório de Caixa</h2>
        <Clear />
        <div>{formattedDocument}</div>
        <Clear />
        <div>{company?.companyName}</div>
        <Clear />
      </Header>
      <Section>
        <div>Número</div>
        <div>
          <Highlight className="bottom-space">
            {cashierReport?.shift?.id}
          </Highlight>
        </div>
        <Clear />
        <div>Data de abertura</div>
        <div>
          <Highlight className="bottom-space">
            {formatToFullLocalDate(cashierReport?.shift?.start)}
          </Highlight>
        </div>
        <Clear />
        <div>Data de fechamento</div>
        <div>
          <Highlight className="bottom-space">
            {cashierReport?.shift?.end
              ? formatToFullLocalDate(cashierReport?.shift?.end)
              : '-'}
          </Highlight>
        </div>
        <Clear />
        <div>Operador</div>
        <div>
          <Highlight className="bottom-space">
            {cashierReport?.shift?.openOperatorName}
          </Highlight>
        </div>
        <Clear />
      </Section>
      <Section>
        <SectionHeader>Recebimentos</SectionHeader>
        {cashierReport?.salesPaymentMethods?.map((payment, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index}>
            {getPaymentTypeName(payment.paymentType)}
            <Right>{formatToMoney(payment.paymentAmount, true)}</Right>
          </Item>
        ))}
        <Clear />
        <Line />
        <Item>
          Total de vendas
          <Right>{formatToMoney(totalAmount, true)}</Right>
        </Item>
        <Clear />
      </Section>
      <Section>
        <SectionHeader>Dinheiro</SectionHeader>
        <SectionTitle>
          <p>Suprimentos/Sangria</p>
        </SectionTitle>
        {cashierReport?.cashMovements?.map((movement, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Item key={index}>
            {movement.description}
            <Right>{formatToMoney(movement.amount, true)}</Right>
          </Item>
        ))}
        <Clear />
        <Line />
        <Item>
          Total
          <Right>{formatToMoney(movementsTotalAmount, true)}</Right>
        </Item>
        <Clear />
      </Section>
      <Section>
        <SectionTitle>Fechamento de Caixa</SectionTitle>
        <Item>
          Total realizado
          <Right>
            {formatToMoney(cashierReport?.cashSummary.actualCashAmount, true)}
          </Right>
        </Item>
        <Clear />
        <Item>
          Total informado
          <Right>
            {formatToMoney(cashierReport?.cashSummary.closeCashAmount, true)}
          </Right>
        </Item>
        <Clear />
        <Line />
        <SectionSubtitle>Diferença</SectionSubtitle>
        <SectionSubtitle>
          {formatToMoney(cashierReport?.cashSummary.cashBalanceAmount, true)}
        </SectionSubtitle>
        <Clear />
        <Line />
      </Section>
      <Section>
        <SectionHeader>Pedidos cancelados</SectionHeader>
        <Clear />
        <Line />
        <Item>
          Total de pedidos cancelados
          <Right>
            {formatToMoney(cashierReport?.canceledOrders?.canceledTotal, true)}
          </Right>
        </Item>
        <Clear />
        <Line />
      </Section>
      <Section>
        <SectionHeader>Produtos vendidos</SectionHeader>
        {cashierReport?.items?.map(item => (
          <React.Fragment key={item.category}>
            <TableSectionTitle>{item.category}</TableSectionTitle>
            <Clear />
            <ItemTable>
              <tbody>
                {item.items.map((product, index) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <React.Fragment key={index}>
                    <ItemTableRow>
                      <td colSpan={3} className="item-title">
                        {product.title}
                      </td>
                    </ItemTableRow>
                    <ItemTableRow>
                      <td className="qty">
                        {product.qty}
                        &times;
                      </td>
                      <td className="unit-price">
                        {formatToMoney(product.unitPrice, true)}
                      </td>
                      <td className="total">
                        {formatToMoney(product.total, true)}
                      </td>
                    </ItemTableRow>
                  </React.Fragment>
                ))}
              </tbody>
            </ItemTable>
          </React.Fragment>
        ))}
        <Clear />
        <Line />
        <Item>
          Total de vendas
          <Right>{formatToMoney(productsSalesTotal, true)}</Right>
        </Item>
        <Clear />
      </Section>
      <Footer>
        {foundDevice && <p>{`(${foundDevice?.name})`}</p>}
        <p>BS Ticket v1.11</p>
      </Footer>
    </Wrapper>
  );
};

export default forwardRef(CashierReportPrint);
