import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Center, Wrapper } from './styles';
import GridCards from '../GridCards';
import GridTables from '../GridTables';
import { useTable } from '../../hooks/table';
import { ITable2 } from '../../models/ITable2';
import { useCards } from '../../hooks/card';
import { ICard } from '../../models/ICard';
import Loading from '../Loading';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { useAuth } from '../../hooks/auth';
import { LocalStorage } from '../../enums/localstorage';
import { useSignalR } from '../../hooks/signalR';

const GridCardsAndTables: React.FC = () => {
  const { showConfirmDialog } = useConfirmDialog();
  const { getTables } = useTable();
  const { getCards } = useCards();
  const history = useHistory();
  const { user } = useAuth();

  const [loadingTables, setLoadingTables] = useState(false);
  const [loadingCards, setLoadingCards] = useState(false);
  const [tables, setTables] = useState<ITable2[]>([]);
  const [cards, setCards] = useState<ICard[]>([]);
  const [attTable, setAttTable] = useState(0);
  const [attCard, setAttCard] = useState(0);
  const customer = JSON.parse(
    localStorage.getItem(LocalStorage.CUSTOMER as string) ?? '',
  );
  const { id: companyID } = customer.companies[0];

  useSignalR(
    companyID.toString(),
    user?.accessToken ?? '',
    setAttTable,
    setAttCard,
  );

  // GetTables
  useEffect(() => {
    const fetchTable = async () => {
      setLoadingTables(true);
      try {
        const response = await getTables();
        setTables(response?.data);
      } catch (error) {
        showConfirmDialog({
          title: 'Erro ao buscar Mesas',
          message: 'Deseja buscar novamente?',
          onConfirm: () => window.location.reload(),
          onCancel: () => history.push('/'),
        });
        throw error;
      } finally {
        setLoadingTables(false);
      }
    };

    fetchTable();
  }, [getTables, attTable, showConfirmDialog, history]);
  // GetCards
  useEffect(() => {
    const fetchCards = async () => {
      setLoadingCards(true);
      try {
        const response = await getCards();
        setCards(response.data);
      } catch (error) {
        showConfirmDialog({
          title: 'Erro ao buscar Comandas',
          message: 'Deseja buscar novamente?',
          onConfirm: () => window.location.reload(),
          onCancel: () => history.push('/'),
        });
        throw error;
      } finally {
        setLoadingCards(false);
      }
    };

    fetchCards();
  }, [getCards, history, showConfirmDialog, attCard]);

  return (
    <Wrapper card={cards.length > 0} table={tables.length > 0}>
      {loadingTables ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        tables.length > 0 && (
          <GridTables
            tables={tables}
            onlyTable={tables.length > 0 && cards.length === 0} // OnlyTables e  OnlyCards são verificações para caso o usuário não utilize as mesas e comandas,
          />
        )
      )}

      {loadingCards ? (
        <Center>
          <Loading />
        </Center>
      ) : (
        cards.length > 0 && (
          <GridCards
            cards={cards}
            onlyCard={tables.length === 0 && cards.length > 0} // OnlyTables e  OnlyCards são verificações para caso o usuário não utilize as mesas e comandas,
          />
        )
      )}
    </Wrapper>
  );
};

export default GridCardsAndTables;
