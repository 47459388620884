/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import Lottie from 'lottie-react-web';
import { FaCheckCircle } from 'react-icons/fa';
import { FiEdit } from 'react-icons/fi';

import { ReactComponent as PreviewLightSvg } from '../../assets/theme/light.svg';
import { ReactComponent as PreviewDarkSvg } from '../../assets/theme/dark.svg';
import logo_placeholder from '../../assets/item-placeholder.png';

import {
  Container,
  Header,
  Content,
  PreviewContainer,
  Preview,
  LoadingIconsContainer,
  AnimationContainer,
  EditAnimationButton,
  CompanyLogoWrapper,
  CompanyLogo,
} from './styles';

import { useCompany } from '../../hooks/company';
import ISaveCompanyDTO from '../../dtos/ISaveCompanyDTO';
import Loading from '../../components/Loading';
import ChooseLoadingAnimationModal from '../../components/ChooseLoadingAnimationModal';

import { LoadingAnimations } from '../../enums/loadingAnimation';
import { getAnimation } from '../../utils/loadingAnimations';
import { useToast } from '../../hooks/toast';
import ColorPicker from '../../components/ColorPicker';
import SaveButton from '../../components/SaveButton';
import { AuthRole } from '../../hooks/auth';

const colors = [
  '#664123',
  '#c9861a',
  '#344a20',
  '#439c3d',
  '#168a76',
  '#0a678c',
  '#2249bf',
  '#663399',
  '#ba4caa',
  '#bf4358',
  '#db1622',
];

const ThemePage: React.FC = () => {
  const { addToast } = useToast();
  const [animationModalOpen, setAnimationModalOpen] = useState(false);
  const [selectedAnimation, setSelectedAnimation] =
    useState<LoadingAnimations | null>(LoadingAnimations.HAMBURGUER);
  const [selectedColor, setSelectedColor] = useState('');
  const [dark, setDark] = useState(false);

  const [companyLogo, setCompanyLogo] = useState<File | null>(null);

  const [companyLogoPrinter, setCompanyLogoPrinter] = useState<File | null>(
    null,
  );

  const [companyLogoChanged, setCompanyLogoChanged] = useState(false);

  const [companyLogoPrinterChanged, setCompanyLogoPrinterChanged] =
    useState(false);

  const {
    saveCompany,
    company,
    isSavingCompany,
    isLoadingCompany,
    changeLogo,
    changeLogoPrinter,
  } = useCompany();

  const handleModeClick = useCallback((value?: boolean) => {
    setDark(value || false);
  }, []);

  const handleSave = useCallback(async () => {
    if (company) {
      try {
        await saveCompany({
          ...company,
          state: company.city.state,
          city: company.city.name,
          primaryColor: selectedColor,
          isDarkTheme: dark,
          loadingAnimation: selectedAnimation,
        } as ISaveCompanyDTO);
        addToast({ type: 'success', description: 'Tema salvo com sucesso!' });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar o tema.',
        });
      }
    }
  }, [saveCompany, company, selectedColor, dark, addToast, selectedAnimation]);

  const handleOpenAnimationModal = useCallback(() => {
    setAnimationModalOpen(true);
  }, []);

  const handleCloseAnimationModal = useCallback(() => {
    setAnimationModalOpen(false);
  }, []);

  const handleAnimationSelect = useCallback((value: LoadingAnimations) => {
    setAnimationModalOpen(false);
    setSelectedAnimation(value);
  }, []);

  const handleColorChange = useCallback((color: string) => {
    setSelectedColor(color);
  }, []);

  const handleOnCompanyLogoChange = async (file: File | null) => {
    if (file && file.type !== 'image/png' && file.type !== 'image/jpeg') {
      // eslint-disable-next-line no-alert
      alert('Tipo de arquivo inválido!');
      return;
    }

    if (file && file.size > 4194304) {
      // eslint-disable-next-line no-alert
      alert('A imagem deve ser de até 4mb!');
      return;
    }

    if (file) {
      setCompanyLogoChanged(true);
      setCompanyLogo(file);
    }

    try {
      if (file) {
        await changeLogo(file);

        addToast({
          type: 'success',
          description: 'Você alterou o logo da empresa.',
        });
      }
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  };

  const handleOnCompanyLogoPrinterChange = async (file: File | null) => {
    if (file && file.type !== 'image/png' && file.type !== 'image/jpeg') {
      // eslint-disable-next-line no-alert
      alert('Tipo de arquivo inválido!');
      return;
    }

    if (file && file.size > 4194304) {
      // eslint-disable-next-line no-alert
      alert('A imagem deve ser de até 4mb!');
      return;
    }

    if (file) {
      setCompanyLogoPrinterChanged(true);
      setCompanyLogoPrinter(file);
    }

    try {
      if (file) {
        await changeLogoPrinter(file);

        addToast({
          type: 'success',
          description: 'Você alterou o logo da empresa.',
        });
      }
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  };

  const animationData = useMemo(
    () => selectedAnimation && getAnimation(selectedAnimation),
    [selectedAnimation],
  );

  const preview = useMemo(() => {
    return companyLogo ? URL.createObjectURL(companyLogo) : logo_placeholder;
  }, [companyLogo]);

  const previewPrinter = useMemo(() => {
    return companyLogoPrinter
      ? URL.createObjectURL(companyLogoPrinter)
      : logo_placeholder;
  }, [companyLogoPrinter]);

  useEffect(() => {
    if (company) {
      setSelectedColor(company?.primaryColor || '#db1622');
      setDark(company?.isDarkTheme || false);
      setSelectedAnimation(company?.loadingAnimation);
    }
  }, [company]);

  if (isLoadingCompany) {
    return (
      <Container>
        <Loading radius={32} stroke={3} color="primary" />
      </Container>
    );
  }

  return (
    <Container>
      <Header>
        <h1>Tema</h1>
      </Header>
      <Content>
        <CompanyLogoWrapper id="settings-logo">
          <div>
            <h4>Logo da Empresa</h4>
            {company?.logoUrl && !companyLogoChanged ? (
              <AuthRole whiteList={['Marketing']} disableOnly>
                <CompanyLogo
                  style={{
                    backgroundImage: `url(${company.logoUrl})`,
                  }}
                  hasLogo
                >
                  <input
                    id="thumbnail"
                    type="file"
                    onChange={event =>
                      handleOnCompanyLogoChange(
                        event.target?.files ? event.target?.files[0] : null,
                      )
                    }
                    accept="image/x-png,image/jpeg"
                  />
                  <span>Alterar imagem</span>
                </CompanyLogo>
              </AuthRole>
            ) : (
              <AuthRole whiteList={['Marketing']} disableOnly>
                <CompanyLogo
                  style={{ backgroundImage: `url(${preview})` }}
                  hasLogo={companyLogoChanged}
                >
                  <input
                    id="thumbnail"
                    type="file"
                    onChange={event =>
                      handleOnCompanyLogoChange(
                        event.target?.files ? event.target?.files[0] : null,
                      )
                    }
                    accept="image/x-png,image/jpeg"
                  />
                  <span>Alterar imagem</span>
                </CompanyLogo>
              </AuthRole>
            )}
          </div>
          <div>
            <h4>Logo de Impressão</h4>
            {company?.logoPrinterUrl && !companyLogoPrinterChanged ? (
              <AuthRole whiteList={['Marketing']} disableOnly>
                <CompanyLogo
                  style={{
                    backgroundImage: `url(${company.logoPrinterUrl})`,
                  }}
                  hasLogo
                >
                  <input
                    id="print-thumbnail"
                    type="file"
                    onChange={event =>
                      handleOnCompanyLogoPrinterChange(
                        event.target?.files ? event.target?.files[0] : null,
                      )
                    }
                    accept="image/x-png,image/jpeg"
                  />
                  <span>Alterar imagem</span>
                </CompanyLogo>
              </AuthRole>
            ) : (
              <AuthRole whiteList={['Marketing']} disableOnly>
                <CompanyLogo
                  style={{ backgroundImage: `url(${previewPrinter})` }}
                  hasLogo={companyLogoPrinterChanged}
                >
                  <input
                    id="print-thumbnail"
                    type="file"
                    onChange={event =>
                      handleOnCompanyLogoPrinterChange(
                        event.target?.files ? event.target?.files[0] : null,
                      )
                    }
                    accept="image/x-png,image/jpeg"
                  />
                  <span>Alterar imagem</span>
                </CompanyLogo>
              </AuthRole>
            )}
          </div>
        </CompanyLogoWrapper>
        <AuthRole whiteList={['Marketing']} disableOnly>
          <ColorPicker
            value={selectedColor}
            colors={colors}
            cssVarPrefix="theme-page-color-picker"
            onChange={handleColorChange}
          />
        </AuthRole>
        <AuthRole whiteList={['Marketing']} disableOnly>
          <PreviewContainer>
            <Preview selected={!dark} onClick={() => handleModeClick(false)}>
              <span>Modo Claro</span>
              <PreviewLightSvg className="svg-preview" />
              <FaCheckCircle className="svg-check" size={32} />
            </Preview>
            <Preview selected={dark} onClick={() => handleModeClick(true)}>
              <span>Modo Escuro</span>
              <PreviewDarkSvg className="svg-preview" />
              <FaCheckCircle className="svg-check" size={32} />
            </Preview>
          </PreviewContainer>
        </AuthRole>
        <LoadingIconsContainer>
          <h2>Animação de carregamento: </h2>
          <AnimationContainer>
            <Lottie
              options={{ animationData, autoplay: true, loop: true }}
              width={164}
              height={164}
            />
            <AuthRole whiteList={['Marketing']}>
              <EditAnimationButton onClick={handleOpenAnimationModal}>
                <FiEdit size={24} />
              </EditAnimationButton>
            </AuthRole>
          </AnimationContainer>
        </LoadingIconsContainer>
        <AuthRole whiteList={['Marketing']}>
          <SaveButton
            onClick={handleSave}
            disabled={isLoadingCompany || isSavingCompany}
            loading={isSavingCompany}
          />
        </AuthRole>
      </Content>
      <ChooseLoadingAnimationModal
        isOpen={animationModalOpen}
        onClose={handleCloseAnimationModal}
        onSelect={handleAnimationSelect}
      />
    </Container>
  );
};

export default ThemePage;
