import React, { useState, useContext, useCallback, createContext } from 'react';
import IClient from '../models/IClient';
import api from '../services/api';
import { LocalStorage } from '../enums/localstorage';

interface ClientContextData {
  isLoading: boolean;
  getClients: () => Promise<IClient[]>;
  saveClient(client: IClient): Promise<void>;
}

const ClientContext = createContext<ClientContextData>({} as ClientContextData);

export const ClientProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const getClients = useCallback(async () => {
    try {
      const customer = JSON.parse(
        localStorage.getItem(LocalStorage.CUSTOMER as string) ?? '',
      );
      const { subdomain } = customer.companies[0];
      setIsLoading(true);
      const response = await api.get<IClient[]>('/restricted/customers', {
        headers: {
          'X-Tenant-Id': subdomain,
        },
      });
      api.defaults.headers.common['X-Tenant-Id'] = subdomain;
      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return [];
    }
  }, []);

  const saveClient = useCallback(async (client: IClient) => {
    setIsLoading(true);
    try {
      if (client?.id) {
        await api.put<IClient>(
          `restricted/customers/${Number(client?.id)}`,
          client,
        );
      } else {
        await api.post<IClient>(`restricted/customers`, client);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, []);

  return (
    <ClientContext.Provider
      value={{
        isLoading,
        getClients,
        saveClient,
      }}
    >
      {children}
    </ClientContext.Provider>
  );
};

export function useClient(): ClientContextData {
  const context = useContext(ClientContext);

  if (!context) {
    throw new Error('useClient must be used within ClientProvider');
  }

  return context;
}
