import styled from 'styled-components';

export const WrapperGenerator = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 100%;

  .error {
    color: ${({ theme }) => theme.palette.primary};
  }
`;
export const Container = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  background-color: ${({ theme }) => theme.palette.background_secondary};
  border-radius: 4px;
  padding: 0.3rem;

  @media (max-width: 500px) {
    gap: 0.5rem;
    .btn {
      height: 100%;
    }
  }
`;
export const Inputs = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
  align-items: center;

  @media (max-width: 500px) {
    flex-direction: column;
    gap: 0.5rem;
  }
`;
export const Input = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;

  @media (max-width: 500px) {
    width: 100%;
    justify-content: space-between;
    gap: 0.5rem;
    div {
      max-width: 100px;
    }
  }

  p {
    white-space: nowrap;
  }
`;
