import React, { useCallback, useEffect } from 'react';

import { FiCheck, FiSave, FiX } from 'react-icons/fi';
import {
  Container,
  Header,
  HeaderButtonsContainer,
  Info,
  SaveOperatorButton,
  SaveOperatorFloatingButton,
} from './styles';
import theme from '../../styles/themes/light';

interface IContainerForm {
  onClose: () => void;
  onSave: () => void;
  children: React.ReactNode;
  type: string;
}

const ContainerForm: React.FC<IContainerForm> = ({
  onClose,
  onSave,
  children,
  type,
}) => {
  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  return (
    <Container>
      <Header>
        <Info>
          <FiX onClick={onClose} size={32} />
          <span>{type}</span>
        </Info>

        <HeaderButtonsContainer>
          <SaveOperatorButton onClick={onSave}>
            <FiCheck />
            <span>Salvar</span>
          </SaveOperatorButton>
        </HeaderButtonsContainer>
        <SaveOperatorFloatingButton onClick={onSave}>
          <FiSave color={theme.palette.text_white} size={24} />
        </SaveOperatorFloatingButton>
      </Header>

      {children}
    </Container>
  );
};

export default ContainerForm;
