import { Form } from '@unform/web';
import styled, { css } from 'styled-components';

const integrationStyles = css`
  width: calc(100% - 32px);
  max-width: 1fr;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  animation: smooth-appearance 0.5s ease;
  padding-bottom: 16px;
`;

export const Wrapper = styled(Form)`
  ${integrationStyles}

  #fbPixelContainer {
    margin-top: 24px;
  }
`;

export const GroupContainer = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  padding: 16px;
  border-radius: 8px;
  box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
  background: ${({ theme }) => theme.palette.background_white};

  margin-top: 32px;

  > div {
    width: 100%;
  }

  > div + div {
    margin-top: 24px;
    width: 100%;
  }

  @media (max-width: 800px) {
    margin-top: 16px;
  }

  :first-child {
    margin-top: unset;
  }

  #contentContainer {
    margin-top: 0;
  }

  #settings-bsticket-products {
    margin-top: unset;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;

    #bs-ticket-order-products-button {
      margin-left: unset;
    }
  }
`;

export const GroupTitle = styled.div`
  border-bottom: 4px solid #db1622;

  font-weight: 500;
  font-size: 24px;

  button {
    border: 0;
    color: #fff;
    background: #db1622;
    width: 35px;
    height: 35px;
    margin-bottom: 16px;
  }
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;
export const ToggleContainer = styled.div`
  display: flex;
  align-items: center;

  margin-top: 32px;

  span {
    margin-left: 16px;
  }
`;
interface IHorizontalWrapperProps {
  asymmetrical?: boolean;
}
export const HorizontalWrapper = styled.div<IHorizontalWrapperProps>`
  display: flex;

  > div + div {
    margin-left: 32px;
  }

  @media (max-width: 730px) {
    flex-direction: column;

    div {
      width: 100%;
    }

    div + div {
      width: 100%;
      margin-left: 0;
    }
  }

  #clientIdContainer {
    margin-right: 24px;
  }

  #apiCompanyKeyContainer {
    width: auto;
  }
`;
export const CompanyCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  gap: 8px;

  > span {
    font-weight: 500;
  }

  button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.2rem;
  }
`;
export const CompanyCode = styled.div`
  width: 100%;
  height: 2rem;

  display: flex;
  flex-direction: row;
  align-items: center;

  gap: 8px;

  span {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: ${({ theme }) => theme.palette.background_secondary};
    box-shadow: ${({ theme }) => theme.shadow.box_shadow_secondary};
    border-radius: 4px;

    padding: 2px 12px;
  }

  #clientId {
    display: flex;
    flex: 1;
  }

  button {
    background: none;
    border: none;
    padding: 0;
  }

  a {
    color: ${({ theme }) => theme.palette.primary};
  }

  .copyButton {
    background: none;
    border: none;
    padding: 0;
  }
`;
