/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useEffect, useCallback, useState, useMemo } from 'react';

import { FiPlus } from 'react-icons/fi';
import { writeText } from 'clipboard-polyfill/text';

import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import {
  FilterTypes,
  useDiscountsCoupons,
} from '../../hooks/discounts_coupons';

import Tour from '../../components/Tour';
import Search from '../../components/Search';
import HelpButton from '../../components/HelpButton';
import EmptyMessage from '../../components/EmptyMessage';
import AddCouponModal from '../../components/AddCouponModal';
import DiscountCoupon from '../../components/DiscountCoupon';
import LoadingAnimation from '../../components/LoadingAnimation';
import CouponMetricsModal from '../../components/CouponMetricsModal';

import tourData from '../../tour/discount';

import {
  Header,
  Filter,
  Content,
  Filters,
  PageInfo,
  PageName,
  Container,
  MainContent,
  AddCouponButton,
  CouponsContainer,
  LoadingContainer,
} from './styles';
import CouponArtModal from '../../components/CouponArtModal';
import IDiscountCoupon from '../../models/IDiscountCoupon';
import { chooseBsFoodOrVarejo } from '../../utils/subdomain';
import { PageNames } from '../../enums/pages';

interface IFilterOptions {
  title: string;
  ref: FilterTypes;
  selected: boolean;
}

const DiscountPage: React.FC = () => {
  const { addToast } = useToast();
  const { company } = useCompany();
  const { showConfirmDialog } = useConfirmDialog();

  const {
    filter,
    coupons,
    loadingController,
    setFilter,
    loadCoupons,
    deleteCoupon,
  } = useDiscountsCoupons();

  const [isTourVisible, setIsTourVisible] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState('');
  const [isAddCouponModalVisible, setIsAddCouponModalVisible] = useState(false);

  const [isCouponMetricsModalOpen, setIsCouponMetricsModalOpen] =
    useState(false);

  const [selectedCoupon, setSelectedCoupon] = useState<IDiscountCoupon | null>(
    null,
  );

  useEffect(() => {
    async function getData() {
      await loadCoupons();
    }

    if (company) {
      getData();
    }
  }, [company, loadCoupons]);

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  const handleAddNewCoupon = useCallback(() => {
    setIsAddCouponModalVisible(true);
  }, []);

  const handleOnHideCouponModal = useCallback(() => {
    setIsAddCouponModalVisible(false);
  }, []);

  const onDeleteCoupon = useCallback(
    (id: number) => {
      showConfirmDialog({
        title: 'Excluir o Cupom?',
        message: 'Esta ação é irreversível',
        onConfirm: () => {
          try {
            deleteCoupon(id);

            addToast({
              type: 'success',
              description: 'Cupom apagado com sucesso!',
            });
          } catch {
            addToast({
              type: 'error',
              description: 'Erro ao apagar cupom.',
            });
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-empty-function
        onCancel: () => {},
      });
    },
    [deleteCoupon, addToast, showConfirmDialog],
  );

  const handleCouponMetricsModalClosed = useCallback(() => {
    setIsCouponMetricsModalOpen(false);
  }, []);

  const handleOnCouponClicked = useCallback(() => {
    setIsCouponMetricsModalOpen(true);
  }, []);

  const handleOnFilterChanged = useCallback(
    (f: FilterTypes) => {
      setFilter(f);
    },
    [setFilter],
  );

  const handleOnCreateCourseArtClick = useCallback(
    (coupon: IDiscountCoupon) => {
      setSelectedCoupon(coupon);
    },
    [],
  );

  const handleOnCopyDiscountCouponLinkClick = useCallback(
    async (ref: string) => {
      try {
        const baseUrl = `https://${company?.subdomain}.${chooseBsFoodOrVarejo(
          'bsfood',
          'bsvarejo',
        )}.com.br/`;

        const url = `${baseUrl}?coupon=${ref}`;

        await writeText(url);

        addToast({
          type: 'info',
          description: 'O link foi copiado para àrea de transferência.',
        });
      } catch (e) {
        addToast({ type: 'error', description: 'Erro ao copiar link.' });
      }
    },
    [addToast, company],
  );

  const searchedCoupons = useMemo(() => {
    return coupons.filter(coupon => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return coupon.ref
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [coupons, searchCriteria]);

  const filterOptions: IFilterOptions[] = useMemo(() => {
    return [
      {
        ref: 'ACTIVE',
        title: 'Ativos',
        selected: filter === 'ACTIVE',
      },
      {
        ref: 'CANCELED',
        title: 'Cancelados',
        selected: filter === 'CANCELED',
      },
    ];
  }, [filter]);

  return (
    <Container>
      <Content overlay={isAddCouponModalVisible}>
        <Header>
          <PageInfo>
            <PageName>{PageNames.DISCOUNTS}</PageName>
            <HelpButton onClick={handleHelpButtonClick} />
          </PageInfo>
          <Search
            value={searchCriteria}
            onChange={handleOnSearchCriteriaChanged}
          />
          <AddCouponButton onClick={handleAddNewCoupon} id="discount-add">
            <FiPlus />
            <span />
          </AddCouponButton>
        </Header>
        <Filters id="orders-filters">
          <CouponArtModal
            selectedDiscountCoupon={selectedCoupon}
            isOpen={!!selectedCoupon}
            onClose={() => setSelectedCoupon(null)}
          />
          {filterOptions.map(option => (
            <Filter
              key={option.ref}
              selected={option.selected}
              onClick={() => handleOnFilterChanged(option.ref)}
            >
              {option.title}
            </Filter>
          ))}
        </Filters>
        <MainContent className="has-custom-scroll-bar-2">
          {isTourVisible && (
            <CouponsContainer>
              <DiscountCoupon
                coupon={{
                  id: 0,
                  amount: 10,
                  amountType: 'FIXED',
                  companyId: 0,
                  effectiveUses: 5,
                  createdAt: new Date().toISOString(),
                  deprecatedAt: new Date().toISOString(),
                  deletedAt: '',
                  description: 'TESTE',
                  finalDate: new Date().toISOString(),
                  initialDate: new Date().toISOString(),
                  effectiveAmount: 0,
                  maxAmount: 500,
                  maxUses: 10,
                  minAmount: 10,
                  reservedUses: 50,
                  operationType: 'TOTAL',
                  updatedAt: new Date().toISOString(),
                  ref: 'TESTE',
                  uniqueUse: true,
                }}
                onDelete={onDeleteCoupon}
                onClick={handleOnCouponClicked}
                onCreateArtClick={() => {}}
                onCopyLinkClick={() => {}}
              />
            </CouponsContainer>
          )}
          {loadingController.get ? (
            <LoadingContainer>
              <LoadingAnimation />
            </LoadingContainer>
          ) : (
            <>
              {searchedCoupons.length > 0 ? (
                <CouponsContainer>
                  {searchedCoupons.map(c => (
                    <DiscountCoupon
                      key={c.id}
                      coupon={c}
                      onDelete={onDeleteCoupon}
                      onClick={handleOnCouponClicked}
                      onCreateArtClick={() => handleOnCreateCourseArtClick(c)}
                      onCopyLinkClick={() =>
                        handleOnCopyDiscountCouponLinkClick(c.ref)
                      }
                    />
                  ))}
                </CouponsContainer>
              ) : (
                <EmptyMessage
                  searchCriteria={searchCriteria}
                  plural="cupons de desconto"
                  text="cupom de desconto"
                  onClick={handleAddNewCoupon}
                />
              )}
            </>
          )}
        </MainContent>
      </Content>
      <AddCouponModal
        visible={isAddCouponModalVisible}
        onBackPressed={handleOnHideCouponModal}
      />
      <CouponMetricsModal
        isOpen={isCouponMetricsModalOpen}
        onClose={handleCouponMetricsModalClosed}
      />
      {isTourVisible && <Tour steps={tourData} onFinish={handleTourFinish} />}
    </Container>
  );
};

export default DiscountPage;
