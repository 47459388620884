import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  height: 100%;
  max-height: 60px;
  align-items: center;
  padding: 5px;
  background-color: ${({ theme }) => theme.palette.primary_light};
  border-radius: 4px;
  color: ${({ theme }) => theme.palette.text_white};
  transition: color 0.6s, background-color 0.6s, transform 0.6s;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  justify-content: space-between;
  font-size: 14px;
  cursor: pointer;
  :hover {
    color: ${({ theme }) => theme.palette.background_white};
    background-color: ${({ theme }) => theme.palette.primary};
  }
  p {
    margin: 0 5px;
    font-size: 12px;
  }
  img {
    height: 22px;
    width: 22px;
  }

  @media (max-height: 900px) {
    height: 65px;
  }
`;

export const ModalWrapper = styled.div<{ error: boolean }>`
  margin: 32px 0;

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.primary : theme.palette.outline};
    height: 40px;
  }
`;
