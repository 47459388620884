import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
`;
export const ContainerForm = styled.div`
  display: flex;
  flex-direction: column;
  place-content: center;
  gap: 15px;
  padding: 32px 0;

  .separament {
    display: flex;
    align-items: center;
    width: 100%;
    gap: 15px;

    .first-child {
      width: 30%;

      .input {
        div {
          display: flex;
          max-height: 30px;
        }
      }
    }
  }
`;
export const Tittle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-size: 20px;
  }
`;
