export const convertPaymentName = (payment: string): string => {
  let paymentMethod;

  switch (payment) {
    case 'CASH':
      paymentMethod = 'Dinheiro';
      break;
    case 'DEBIT_CARD':
      paymentMethod = 'Débito';
      break;
    case 'PIX':
      paymentMethod = 'Pix';
      break;
    case 'PIC_PAY':
      paymentMethod = 'PicPay';
      break;
    case 'AME':
      paymentMethod = 'Ame';
      break;
    case 'MONTHLY_PAYER':
      paymentMethod = 'Mensalista';
      break;
    case 'VOUCHER':
      paymentMethod = 'Vale Refeição';
      break;
    case 'FB_PAYMENT':
      paymentMethod = 'WhatsApp';
      break;
    case 'CHECK':
      paymentMethod = 'Cheque';
      break;
    case 'CREDIT_CARD':
      paymentMethod = 'Crédito';
      break;
    default:
      paymentMethod = 'Nenhum'; // Caso o valor não corresponda a nenhum dos casos
  }
  return paymentMethod;
};
