import React, {
  useState,
  useContext,
  useCallback,
  createContext,
  useMemo,
  useEffect,
} from 'react';

import IUser, { CustomerRolesTypes } from '../models/IUser';
import IUserLoginDTO from '../dtos/ILoginDTO';
import ISaveUserDTO from '../dtos/ISaveUserDTO';
import ISaveAddressDTO from '../dtos/ISaveAddressDTO';
import IResetPasswordDTO from '../dtos/IResetPasswordDTO';

import { useCompany } from './company';

import api from '../services/api';
import { LocalStorage } from '../enums/localstorage';
import { PageNames } from '../enums/pages';
import { verifyUserRole } from '../utils/auth';
import { injectProps } from '../utils/react';

type PagesAccessProfilesType = {
  [key in keyof typeof PageNames]: IUseAuthRolesProps;
};

interface AuthContextData {
  user: IUser | null;
  preferableDisplay: 'GRID' | 'LIST';
  productPreferableDisplay: 'LIST' | 'GRID';
  signOut(): void;
  checkForUpdates(): void;
  refreshUser(): Promise<void>;
  refreshToken(response: IUser): void;
  signIn(data: IUserLoginDTO): Promise<void>;
  forgotPassword(email: string): Promise<void>;
  updatePhoneNumber(phoneNumber: string): void;
  updateUser(data: ISaveUserDTO): Promise<void>;
  saveAddress(address: ISaveAddressDTO): Promise<void>;
  resetPassword(data: IResetPasswordDTO): Promise<void>;
  changePreferableDisplay(display: 'GRID' | 'LIST'): void;
  changeProductPreferableDisplay(display: 'LIST' | 'GRID'): void;
}

interface ILoginResponse {
  userToken: IUser;
  expiresIn: number;
  accessToken: string;
  refreshToken: string;
}

interface IUseAuthRolesProps {
  whiteList?: CustomerRolesTypes[];
  blackList?: CustomerRolesTypes[];
  disableOnly?: boolean;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

export const AuthProvider: React.FC = ({ children }) => {
  const { resetCompany } = useCompany();

  const [data, setData] = useState<IUser | null>(() => {
    const customer = localStorage.getItem(LocalStorage.CUSTOMER as string);

    if (customer) {
      const parsedCustomer = JSON.parse(customer) as IUser;
      return parsedCustomer;
    }

    return null;
  });

  const [preferableDisplay, setPreferableDisplay] = useState<'GRID' | 'LIST'>(
    () => {
      const display = localStorage.getItem(LocalStorage.PREFERABLE_DISPLAY) as
        | 'GRID'
        | 'LIST';

      if (display) {
        return display;
      }

      localStorage.setItem(LocalStorage.PREFERABLE_DISPLAY, 'GRID');
      return 'GRID';
    },
  );

  const [productPreferableDisplay, setProductPreferableDisplay] = useState<
    'LIST' | 'GRID'
  >(() => {
    const display = localStorage.getItem(
      LocalStorage.PRODUCT_PREFERABLE_DISPLAY,
    ) as 'LIST' | 'GRID';

    if (display) {
      return display;
    }

    localStorage.setItem(LocalStorage.PRODUCT_PREFERABLE_DISPLAY, 'LIST');
    return 'LIST';
  });

  const signIn = useCallback(
    async ({ email, password, token }: IUserLoginDTO) => {
      const response = await api.post<ILoginResponse>(
        '/accounts/login-company',
        {
          email,
          password,
          token,
        },
      );

      localStorage.setItem(
        LocalStorage.CUSTOMER as string,
        JSON.stringify({
          ...response.data.userToken,
          accessToken: response.data.accessToken,
          refreshToken: response.data.refreshToken,
        }),
      );

      setData({
        ...response.data.userToken,
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
      });
    },
    [],
  );

  const signOut = useCallback(() => {
    resetCompany();

    localStorage.removeItem(LocalStorage.CUSTOMER as string);
    setData(null);
  }, [resetCompany]);

  const refreshUser = useCallback(async () => {
    api.get<IUser>('/accounts/me').then(response => {
      setData(response.data);
    });
  }, []);

  const updatePhoneNumber = useCallback((phoneNumber: string) => {
    const customer = localStorage.getItem(LocalStorage.CUSTOMER as string);

    if (customer) {
      const parsedCustomer = JSON.parse(customer) as IUser;
      Object.assign(parsedCustomer, { phoneNumber });

      localStorage.setItem(
        LocalStorage.CUSTOMER as string,
        JSON.stringify(parsedCustomer),
      );

      setData(parsedCustomer);
    }
  }, []);

  const saveAddress = useCallback(async (address: ISaveAddressDTO) => {
    if (address.id > 0) {
      // const response = await api.put<IAddress>(`/addresses/${address.id}`, {
      //   ...address,
      //   streetNumber: address.streetNumber || 'S/N',
      // });
    }
  }, []);

  const changePreferableDisplay = useCallback((display: 'GRID' | 'LIST') => {
    localStorage.setItem(LocalStorage.PREFERABLE_DISPLAY, display);
    setPreferableDisplay(display);
  }, []);

  const changeProductPreferableDisplay = useCallback(
    (display: 'LIST' | 'GRID') => {
      localStorage.setItem(LocalStorage.PRODUCT_PREFERABLE_DISPLAY, display);
      setProductPreferableDisplay(display);
    },
    [],
  );

  const refreshToken = useCallback(async (response: IUser) => {
    setData({
      ...response,
      refreshToken: response.refreshToken,
      accessToken: response.accessToken,
      roles: response.roles,
    });
  }, []);

  const updateUser = useCallback(async (formData: ISaveUserDTO) => {
    const response = await api.put<ILoginResponse>(
      'restricted/accounts/change-password',
      formData,
    );

    localStorage.setItem(
      LocalStorage.CUSTOMER as string,
      JSON.stringify({
        ...response.data.userToken,
        accessToken: response.data.accessToken,
        refreshToken: response.data.refreshToken,
        roles: response.data.userToken.roles,
      }),
    );

    setData({
      ...response.data.userToken,
      refreshToken: response.data.refreshToken,
      accessToken: response.data.accessToken,
      roles: response.data.userToken.roles,
    });
  }, []);

  const forgotPassword = useCallback(async (email: string) => {
    await api.post('accounts/companies/forgot-password', {
      email,
    });
  }, []);

  const resetPassword = useCallback(async (formData: IResetPasswordDTO) => {
    await api.post('accounts/reset-password', formData);
  }, []);

  const checkForUpdates = useCallback(() => {
    const latestVersion = localStorage.getItem(LocalStorage.LATEST_VERSION);

    if (!latestVersion) {
      localStorage.setItem(
        LocalStorage.LATEST_VERSION,
        process.env.REACT_APP_BUILD_VERSION || '',
      );

      return;
    }

    if (latestVersion !== process.env.REACT_APP_BUILD_VERSION) {
      localStorage.removeItem(LocalStorage.COMPANY);
      localStorage.removeItem(LocalStorage.CUSTOMER);

      localStorage.setItem(
        LocalStorage.LATEST_VERSION,
        process.env.REACT_APP_BUILD_VERSION || '',
      );

      signOut();
    }
  }, [signOut]);

  useEffect(() => {
    if (data?.id && !data?.roles) {
      signOut();
    }
  }, [data, signOut]);

  return (
    <AuthContext.Provider
      value={{
        user: data,
        preferableDisplay,
        productPreferableDisplay,
        signIn,
        signOut,
        updateUser,
        refreshUser,
        saveAddress,
        refreshToken,
        resetPassword,
        forgotPassword,
        checkForUpdates,
        updatePhoneNumber,
        changePreferableDisplay,
        changeProductPreferableDisplay,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export function useAuth(): AuthContextData {
  const context = useContext(AuthContext);

  if (!context) {
    throw new Error('useAuth must be used within AuthProvider');
  }

  return context;
}

export function useAuthRoles({
  whiteList,
  blackList,
}: IUseAuthRolesProps): boolean {
  const { user } = useAuth();

  return useMemo(() => {
    if (!user) {
      return false;
    }

    return verifyUserRole(user, whiteList, blackList);
  }, [whiteList, blackList, user]);
}

export const AuthRole: React.FC<IUseAuthRolesProps> = ({
  children,
  whiteList,
  blackList,
  disableOnly,
}) => {
  const userCan = useAuthRoles({ whiteList, blackList });

  if (!userCan) {
    if (disableOnly) {
      return (
        <>
          {injectProps(children, props => {
            return {
              ...props,
              className: `${props?.className || ''} disabledElement`,
              disabled: true,
              isDisabled: true,
            };
          })}
        </>
      );
    }

    return null;
  }

  return <>{children}</>;
};

export const PagesAccessProfiles: PagesAccessProfilesType = {
  DASHBOARD: {
    whiteList: ['Company'],
  },

  RECENT_ORDERS: {
    blackList: ['Marketing'],
  },

  ORDERS: {
    blackList: ['Marketing'],
  },

  TABLES: {
    blackList: ['Marketing'],
  },

  CASHIER_REPORT: {
    whiteList: ['Company'],
  },
  CASHIER: {
    whiteList: ['Company'],
  },

  CATEGORIES: {
    blackList: [],
  },

  PRODUCTS: {
    blackList: [],
  },

  STOCK: {
    whiteList: ['Company', 'Manager'],
  },

  HIGHLIGHTS: {
    blackList: ['Employee'],
  },

  COMPLEMENTS: {
    blackList: [],
  },

  DISCOUNTS: {
    blackList: [],
  },

  UPDATE_SKU: {
    blackList: ['Employee'],
  },

  ADDRESSES: {
    whiteList: [],
  },

  SCHEDULED_PRODUCTS: {
    whiteList: [],
  },

  MESSAGES: {
    blackList: ['Employee'],
  },

  TEAM: {
    whiteList: [],
  },

  OPERATORS: {
    whiteList: ['Manager'],
  },

  CLIENTS: {
    whiteList: ['Manager', 'Company'],
  },

  DEVICES: {
    whiteList: ['Manager'],
  },

  SOCIAL: {
    blackList: [],
  },

  HISTORY: {
    whiteList: ['Marketing'],
  },

  THEME: {
    blackList: ['Employee'],
  },

  SETTINGS: {
    blackList: [],
  },
  REGISTER: {
    blackList: [],
  },
  WAITER: {
    whiteList: ['Manager', 'Company'],
  },
  CARD: {
    blackList: [],
  },

  WHATSAPP: {
    blackList: [],
  },

  EDIT_PRODUCT: {
    blackList: [],
  },
  SELL: {
    blackList: [],
  },
  FAST: {
    blackList: [],
  },
  PDV: {
    blackList: [],
  },
};
