import styled, { keyframes } from 'styled-components';

const shakeAnimation = keyframes`
  0% { transform: translateX(0); }
  20% { transform: translateX(-10px); }
  40% { transform: translateX(10px); }
  60% { transform: translateX(-10px); }
  80% { transform: translateX(10px); }
  100% { transform: translateX(0); }
`;

interface IProductCartWrapperProps {
  animate: boolean;
  transfer: boolean;
}

export const ProductCartWrapper = styled.div<IProductCartWrapperProps>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 79px;
  height: auto;
  border: ${({ theme, transfer }) =>
    transfer && `3px solid ${theme.palette.primary_light}`};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  background-color: ${({ theme }) => theme.palette.background_white};
  align-items: center;
  overflow: hidden;
  animation: ${({ animate }) => (animate ? shakeAnimation : 'none')} 0.5s
    ease-out;
  gap: 1rem;
`;

export const Image = styled.div`
  svg,
  img {
    display: flex;
    align-items: center;
    width: 80px;
    height: 80px;
    object-fit: cover;
  }

  .blue {
    .st0 {
      fill: #85c1e9 !important;
    }
  }

  .green {
    .st0 {
      fill: #94e7b7 !important;
    }
  }

  .orange {
    .st0 {
      fill: #f2be90 !important;
    }
  }

  .red {
    .st0 {
      fill: #f2a59d !important;
    }
  }

  .yellow {
    .st0 {
      fill: #f9e79f !important;
    }
  }
`;

export const Infos = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 80%;
  margin-top: 5px;
  /* gap: 1rem; */

  .price {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 5px;
  }
  p {
    font-size: 16px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limita o texto a 2 linhas */
    overflow: hidden;
    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }

  .complements {
    font-size: 12px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2; /* Limita o texto a 2 linhas */
    overflow: hidden;
    color: #aaa;
    @media (max-width: 1024px) {
      font-size: 10px;
    }
  }
`;

export const Actions = styled.div`
  display: flex;
  width: auto;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  border: 16px 0;

  .common-background {
    background-color: ${({ theme }) => theme.palette.primary};
    display: flex;
    width: auto;
    align-items: flex-end;
    :hover {
      cursor: pointer;
      background-color: ${({ theme }) => theme.palette.primary_light};
    }
  }
  .edit {
    border-radius: 0 8px;
    div {
      background-color: ${({ theme }) => theme.palette.primary};
      margin-right: 0;
      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.palette.primary_light};
      }
    }
  }
  .delete {
    border-radius: 8px 0;

    div {
      background-color: ${({ theme }) => theme.palette.primary};
      margin-right: 0;
      :hover {
        cursor: pointer;
        background-color: ${({ theme }) => theme.palette.primary_light};
      }
    }
  }
`;

export const EditInput = styled.div`
  max-width: 80px;

  div {
    display: flex;
    max-width: 70px;
    max-height: 30px;
  }
`;

export const TransferInput = styled.div`
  margin-bottom: 1.9px;
  max-width: 80px;

  div {
    display: flex;
    max-width: 70px;
    max-height: 30px;
  }
`;

export const ModalDelete = styled.div`
  width: 450px;
  height: 450px;
  background-color: ${({ theme }) => theme.palette.background_white};
  z-index: 999999;
`;
