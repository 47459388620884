import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useCartProducts } from '../../hooks/products_sell';
import {
  Complements,
  DetailWrapper,
  HalfPrice,
  Image,
  Price,
  Product,
} from './styles';
import { ReactComponent as ProductPlaceholderSVG } from '../../assets/icons/product_placeholder.svg';
import { randomInt } from '../../utils/numbers';
import ComplementsCartSell from '../ComplementsCartSell';
import ButtonFinishDetailProduct from '../ButtonFinishDetailProduct';
import TextAreaObservation from '../TextAreaObservation';

const DetailProductCart: React.FC = () => {
  const {
    selectedProduct,
    decrementProduct,
    incrementProduct,
    toggleHalfPrice,
    handleComment,
    handleSetNewProduct,
  } = useCartProducts();
  const containerRefs = useRef<(HTMLDivElement | null)[]>([]);
  const productRef = useRef<HTMLDivElement | null>(null);
  const [nextComplement, setNextComplement] = useState(0);

  const productIcon = useMemo(() => {
    if (selectedProduct?.imageUrl) {
      return (
        <img
          src={selectedProduct?.imageUrl ?? ''}
          alt={selectedProduct?.title ?? ''}
        />
      );
    }
    const classes = ['blue', 'red', 'orange', 'green', 'yellow'];
    const randomClass = classes[randomInt(0, classes.length - 1)];
    return <ProductPlaceholderSVG className={randomClass} />;
  }, [selectedProduct]);

  const hasComplements =
    selectedProduct && selectedProduct?.complementsGroups?.length > 0;

  const [displayNone, setDisplayNone] = useState(!selectedProduct);

  useEffect(() => {
    if (!selectedProduct) {
      setTimeout(() => setDisplayNone(true), 100);
    } else {
      setDisplayNone(false);
    }
  }, [selectedProduct]);

  useEffect(() => {
    if (nextComplement !== null && containerRefs.current[nextComplement]) {
      const element = containerRefs.current[nextComplement];
      if (element) {
        element.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }
  }, [nextComplement]);

  // Adiciono setNextComplement(-1) para que se o usuário scrolle a página e desmarcar o mesmo complemento e volte
  // marcá-lo o estado nextComplement seja alterado para que o useEffect que depende depe seja acionado novamente e seja feito o scroll
  useEffect(() => {
    const handleScroll = () => {
      if (productRef.current) {
        setNextComplement(-1);
      }
    };

    const productElement = productRef.current;
    if (productElement) {
      productElement.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (productElement) {
        productElement.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  return (
    <DetailWrapper close={!selectedProduct} displayNone={displayNone}>
      <Product
        ref={productRef}
        hasComplements={hasComplements ?? false}
        className="has-custom-scroll-bar-2"
      >
        <Image>{productIcon}</Image>
        <h2>{selectedProduct?.title}</h2>

        {Number(selectedProduct?.halfPrice) > 0 && (
          <HalfPrice>
            <h3>Selecione o Tamanho</h3>
            <div className="prices">
              <Price
                selected={!selectedProduct?.isHalfPrice}
                onClick={() => toggleHalfPrice(false)}
              >
                <p>Inteiro</p>
                R$
                {selectedProduct?.unitPrice.toFixed(2).replace('.', ',')}
              </Price>
              <Price
                selected={!!selectedProduct?.isHalfPrice}
                onClick={() => toggleHalfPrice(true)}
              >
                <p>Meio</p>
                R$
                {(selectedProduct?.halfPrice ?? 0).toFixed(2).replace('.', ',')}
              </Price>
            </div>
          </HalfPrice>
        )}
        <Complements>
          {hasComplements &&
            selectedProduct?.complementsGroups?.map(
              (complementGroup, index) => (
                <ComplementsCartSell
                  ref={el => {
                    containerRefs.current[index] = el;
                  }}
                  index={index}
                  key={complementGroup.id}
                  complementGroup={complementGroup}
                  setNextComplement={setNextComplement}
                />
              ),
            )}
        </Complements>
        <TextAreaObservation
          handleCommentsChange={handleComment}
          placeholder="Escreva as observações do produto aqui..."
          title="Observações"
        />
      </Product>

      <ButtonFinishDetailProduct
        subtotal={selectedProduct?.total ?? 0}
        qtd={selectedProduct?.qty ?? 0}
        onIncrease={() => incrementProduct(selectedProduct)}
        onDecrease={() => decrementProduct(selectedProduct)}
        handleFinish={() => handleSetNewProduct(selectedProduct)}
        title="Adicionar"
      />
    </DetailWrapper>
  );
};

export default DetailProductCart;
