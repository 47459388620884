import styled from 'styled-components';

export const ClientWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;
export const ClientPadding = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.palette.background};
  flex: 1;
  height: calc(100% - 100px);
  padding-bottom: 100px;
  padding: 24px 0px 0px 32px;
  overflow: hidden;
`;

export const Grid = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  overflow-x: hidden;
  background: ${({ theme }) => theme.palette.background};
  flex-wrap: wrap;
  max-height: 100%;
  overflow-y: auto;
  flex: 1;
  padding: 0 32px 150px 0;
`;

export const NotFound = styled.div`
  width: 100%;
  text-align: center;
  margin-top: 128px;
`;
