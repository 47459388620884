import React, { useState } from 'react';
import { BiRotateRight } from 'react-icons/bi';

import { Container, Input, Inputs, WrapperGenerator } from './styles';

import { useCards } from '../../../../../hooks/card';
import { useTable } from '../../../../../hooks/table';
import { useConfirmDialog } from '../../../../../hooks/confim_dialog';

import { ICard } from '../../../../../models/ICard';
import { ITable2 } from '../../../../../models/ITable2';

import Button from '../../../../Button';
import AmountInput from '../../../../AmountInput';

interface IInputGeneratorPops {
  type: 'card' | 'table';
  setCards: React.Dispatch<React.SetStateAction<ICard[]>>;
  setLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setTables: React.Dispatch<React.SetStateAction<ITable2[]>>;
}
const InputGenerator: React.FC<IInputGeneratorPops> = ({
  type,
  setCards,
  setTables,
  setLoading,
}) => {
  const { createCards } = useCards();
  const { createTables } = useTable();
  const { ThrowError } = useConfirmDialog();

  const [init, setInit] = useState(0);
  const [error, setError] = useState('');
  const [finish, setFinish] = useState(0);

  const postTables = async () => {
    try {
      setLoading(true);
      const response = await createTables(init, finish);
      setTables(response.data);
    } catch (error) {
      ThrowError(error, 'Erro ao gerar mesas');
    } finally {
      setLoading(false);
    }
  };
  const postCards = async () => {
    try {
      setLoading(true);
      const response = await createCards(init, finish);
      setCards(response.data);
    } catch (error) {
      ThrowError(error, 'Erro ao gerar comandas');
    } finally {
      setLoading(false);
    }
  };

  const handleGenerate = () => {
    if (init === 0) {
      setError(' O número inicial não pode ser 0');
      return;
    }
    if (init > finish) {
      setError(' O número inicial não pode ser maior que o final');
      return;
    }
    if (finish === 0 && init === 0) {
      setError(' Os números não podem ser 0');
      return;
    }
    if (finish === init) {
      setError('Caso queira criar somente uma clique no +');
      return;
    }
    setError('');

    type === 'table' && postTables();
    type === 'card' && postCards();
  };

  return (
    <WrapperGenerator>
      <Container>
        <Inputs>
          <Input>
            <p>
              Da
              {type === 'table' ? ' Mesa' : ' Comanda'}
            </p>

            <AmountInput
              allowsZero
              value={init}
              inputWidth="100%"
              setValue={setInit}
              onIncreaseAmount={() => setInit(prev => prev + 1)}
              onDecreaseAmount={() => setInit(prev => prev - 1)}
            />
          </Input>

          <Input>
            <p>até</p>

            <AmountInput
              allowsZero
              value={finish}
              inputWidth="100%"
              setValue={setFinish}
              onIncreaseAmount={() => setFinish(prev => prev + 1)}
              onDecreaseAmount={() => setFinish(prev => prev - 1)}
            />
          </Input>
        </Inputs>
        <Button
          className="btn"
          title="Gerar"
          width="auto"
          icon={<BiRotateRight size={22} />}
          onClick={handleGenerate}
        />
      </Container>
      {error.length > 0 && <p className="error">{error}</p>}
    </WrapperGenerator>
  );
};

export default InputGenerator;
