import React, { useEffect, useMemo } from 'react';

import { IPizzaSize } from '../../../../models/IPizza';
import IComplement from '../../../../models/IComplement';
import IComplementsGroup from '../../../../models/IComplementsGroup';

import ComplementsGroupsPizza from '../ComplementsGroupsPizza';
import { IProductSell } from '../../../../models/IFastSell';

interface IComplementsEdge {
  editPizza?: IProductSell;
  edges: IComplement[] | undefined;
  selectedSize: IPizzaSize | undefined;
  setEdges: React.Dispatch<React.SetStateAction<IComplement[]>>;
}

const ComplementsEdge: React.FC<IComplementsEdge> = ({
  edges,
  editPizza,
  selectedSize,
  setEdges,
}) => {
  useEffect(() => {
    if (editPizza) {
      const complementsEdges = editPizza.complementsGroups.filter(
        cp => cp.id === -10,
      );

      setEdges(prev =>
        (selectedSize?.pizzaEdges || []).map((edge, index) => {
          const existingEdge = complementsEdges[0]?.complements.find(
            comp => comp.title === edge.name,
          );

          const matchingComplement = prev?.find(
            complement => complement.title === edge.name,
          );

          return matchingComplement
            ? { ...matchingComplement, id: edge.id, unitPrice: edge.price }
            : existingEdge
            ? { ...existingEdge, id: edge.id, unitPrice: edge.price }
            : ({
                id: edge?.id,
                title: edge.name,
                isActive: true,
                position: index - 1,
                unitPrice: edge.price || 0,
                maxAmount: 1,
                deleted: false,
                error: false,
              } as IComplement);
        }),
      );
    } else {
      setEdges(prev =>
        (selectedSize?.pizzaEdges || []).map((edge, index) => {
          const matchingComplement = prev?.find(
            complement => complement.title === edge.name,
          );

          return {
            amount: matchingComplement?.amount,
            checked: matchingComplement?.checked,
            id: edge?.id,
            title: edge.name,
            isActive: true,
            position: index - 1,
            unitPrice: edge.price || 0,
            maxAmount: 1,
            deleted: false,
            error: false,
          } as IComplement;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSize]);

  const handleSelectEdges = (
    comple: IComplement,
    // type: 'increase' | 'decrease' | 'checkbox' | 'radio',
  ) => {
    setEdges(prev => {
      return (
        prev
          ?.map(comp => {
            if (comp.id === comple.id) {
              return { ...comp, amount: 1, checked: true };
            }
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { amount, ...rest } = comp;
            return { ...rest, checked: false };
          })
          ?.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)) ?? []
      );
    });
  };

  const defaultPizzaEdgesGroup: IComplementsGroup = useMemo(
    () => ({
      id: -10,
      modules: '',
      deleted: false,
      title: 'Borda',
      displayName: 'Borda',
      category: 'STANDARD',
      maxAmount: 1,
      minAmount: 1,
      isActive: true,
      position: 0,
      checked: false,
      complements: edges ?? [],
    }),
    [edges],
  );

  return (
    <ComplementsGroupsPizza
      index={0}
      key={0}
      complementGroup={defaultPizzaEdgesGroup}
      handleSelect={handleSelectEdges}
    />
  );
};

export default ComplementsEdge;
