import React, { useEffect, useState } from 'react';
import { v4 as uuid } from 'uuid';

import { Wrapper } from './styles';

import { formatToMoney } from '../../../../utils/format';
import { useProducts } from '../../../../hooks/products';

import { IProductSell } from '../../../../models/IFastSell';

import Drawer from '../../../../components/Drawer';
import DetailProductToGrid from '../DetailProductToGrid';
import AmountInput from '../../../../components/AmountInput';
import ProductIcon from '../../../../components/ProductIcon';

interface IProductToGridProps {
  openDrawer: boolean;
  product: IProductSell;
  productsCartModal: IProductSell[];
  searchRef: React.RefObject<HTMLInputElement>;
  setOpenDrawer: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsToGrid: (value: React.SetStateAction<IProductSell[]>) => void;
  setProductsCartModal: React.Dispatch<React.SetStateAction<IProductSell[]>>;
}
const ProductToGrid: React.FC<IProductToGridProps> = ({
  product,
  searchRef,
  openDrawer,
  productsCartModal,
  setOpenDrawer,
  setProductsToGrid,
  setProductsCartModal,
}) => {
  const { calcProductPrice } = useProducts();

  const qtyAlreadyAdd = productsCartModal.filter(
    prodCart => prodCart.title === product.title,
  )[0]?.qty;
  const [editQtyProduct, setEditQtyProduct] = useState<IProductSell>({
    ...product,
    hash: uuid(),
    qty: qtyAlreadyAdd > 1 ? qtyAlreadyAdd : product.qty,
  });

  useEffect(() => {
    setEditQtyProduct({
      ...product,
      hash: uuid(),
      qty: qtyAlreadyAdd > 1 ? qtyAlreadyAdd : product.qty,
    });
  }, [product, qtyAlreadyAdd]);

  const handleIncrease = (e?: number) => {
    setEditQtyProduct(prev => ({
      ...prev,
      qty: e !== undefined ? e : prev.qty + 1,
    }));
    setProductsCartModal(prev => {
      const increment = prev?.filter(
        prev => prev.title !== editQtyProduct.title,
      );
      if (increment !== undefined) {
        return [
          ...increment,
          {
            ...editQtyProduct,
            qty: e !== undefined ? e : editQtyProduct.qty + 1,
            total: calcProductPrice({
              ...editQtyProduct,
              qty: e !== undefined ? e : editQtyProduct.qty + 1,
            }),
          },
        ];
      }
      return prev;
    });
  };

  const handleDecrease = () => {
    searchRef?.current?.focus();
    setEditQtyProduct(prev => ({
      ...prev,
      qty: prev.qty === 1 ? 1 : prev.qty - 1,
    }));
    setProductsCartModal(prev => {
      const increment = prev?.filter(
        prev => prev.title !== editQtyProduct.title,
      );
      if (increment !== undefined) {
        if (editQtyProduct.qty === 1) {
          return increment;
        }

        return [
          ...increment,
          {
            ...editQtyProduct,
            qty: editQtyProduct.qty - 1,
            total: calcProductPrice({
              ...editQtyProduct,
              qty: editQtyProduct.qty - 1,
            }),
          },
        ];
      }
      return prev;
    });
  };

  const handleClickProduct = () => {
    searchRef?.current?.focus();
    const hasMandatoryComplement = product.complementsGroups?.some(
      complement => complement.minAmount > 0,
    );

    if (hasMandatoryComplement) {
      setOpenDrawer(true);
    } else {
      setProductsCartModal(prev => {
        const verifyProductExistInArray = prev?.some(
          (productPrev: any) => productPrev.title === editQtyProduct?.title,
        );
        if (verifyProductExistInArray) {
          handleIncrease();
        }
        if (prev !== undefined) {
          return [
            ...prev,
            { ...editQtyProduct, total: calcProductPrice(editQtyProduct) },
          ];
        }
        return [{ ...editQtyProduct, total: calcProductPrice(editQtyProduct) }];
      });
    }
  };

  const findProduct = productsCartModal.find(
    productCart => productCart.title === product.title,
  );

  return (
    <div>
      <Wrapper onClick={handleClickProduct} selected={!!findProduct}>
        <ProductIcon url={product.imageUrl} alt={product.title} />
        <div className="info">
          <div className="codeName">
            <p>{product.id}</p>
            <p className="name">{product.title}</p>
          </div>
          <div className="priceQty">
            <p>{formatToMoney(product.unitPrice)}</p>
            {findProduct && (
              <div className="input">
                <AmountInput
                  allowsZero
                  value={editQtyProduct.qty}
                  setValue={e => handleIncrease(e)}
                  onIncreaseAmount={handleIncrease}
                  onDecreaseAmount={handleDecrease}
                />
              </div>
            )}
          </div>
        </div>
      </Wrapper>
      {openDrawer && (
        <Drawer zindex={9} setOpen={setOpenDrawer} open={openDrawer}>
          <DetailProductToGrid
            product={product}
            setOpenDrawer={setOpenDrawer}
            setProductsToGrid={setProductsToGrid}
          />
        </Drawer>
      )}
    </div>
  );
};

export default React.memo(ProductToGrid);
