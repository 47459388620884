import styled from 'styled-components';

export const ContainerCancelationOrder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;

export const Tittle = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 0 32px 0;
  align-items: center;
  justify-content: center;
  text-align: center;
  gap: 15px;
  p {
    font-size: 20px;
  }
`;
export const SelectContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  .select {
    width: 100%;
  }
  p {
    font-size: 16px;
    color: ${({ theme }) => theme.palette.primary};
  }
`;
