import React, { TextareaHTMLAttributes, useState } from 'react';
import { TextArea } from './styles';
import { useDebounce } from '../../utils/debounce';

interface ITextAreaProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  handleCommentsChange: (value: string) => void;
  title: string;
  error?: boolean;
  labelError?: string;
}
const TextAreaObservation: React.FC<ITextAreaProps> = ({
  handleCommentsChange,
  title,
  error,
  labelError,
  ...rest
}) => {
  const handleDebouncedEvent = useDebounce(
    (value: React.SetStateAction<string>) => {
      handleCommentsChange(value.toString());
    },
    300,
  );

  const [comments, setComments] = useState(rest.value);

  return (
    <TextArea error={error ?? false}>
      <h4>{title}</h4>
      <textarea
        {...rest}
        id="comments"
        maxLength={255}
        value={comments}
        onChange={e => {
          handleDebouncedEvent(e.target.value);
          setComments(e.target.value);
        }}
      />
      {error && <span>{labelError && labelError}</span>}
      <div className="count">
        {!error && <span>{`${String(comments ?? '')?.length ?? 0}/255`}</span>}
      </div>
    </TextArea>
  );
};

export default TextAreaObservation;
