import React, { useCallback, useEffect, useMemo } from 'react';
import Select, {
  GroupedOptionsType,
  OptionsType,
  components,
  Props,
} from 'react-select';
import { NoticeProps } from 'react-select/src/components/Menu';

import { SelectContainer, SelectLabel, selectStyles } from './styles';
import { useDevices } from '../../../hooks/devices';
import { useCashierReport } from '../../../hooks/cashier_report';

const NoOptionsMessage = (props: NoticeProps<any, false>) => {
  return (
    <components.NoOptionsMessage {...props}>
      <span className="custom-css-class">Sem opções</span>
    </components.NoOptionsMessage>
  );
};

type SelectOptions = OptionsType<any> | GroupedOptionsType<any> | undefined;

interface CustomSelectProps extends Props {
  label: string;
  options: SelectOptions;
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  label,
  options,
  ...rest
}) => {
  return (
    <SelectContainer>
      <SelectLabel>{label}</SelectLabel>
      <Select
        isSearchable
        styles={selectStyles}
        placeholder="Selecione"
        options={options}
        menuPortalTarget={document.body}
        components={{ NoOptionsMessage }}
        menuPosition="fixed"
        {...rest}
      />
    </SelectContainer>
  );
};

const OperatorAndDevice: React.FC = () => {
  const { getDevices, devices } = useDevices();

  const { filtersStates } = useCashierReport();
  // const { getOperators, operators } = useOperators();

  const formattedDevices = useMemo(() => {
    return devices.map(device => ({
      label: device.name,
      value: device.deviceId,
    }));
  }, [devices]);

  // const formattedOperators = useMemo(() => {
  //   return operators.map(operator => ({
  //     label: operator.name,
  //     value: operator.id,
  //   }));
  // }, [operators]);

  const handleOnChangeDevice = useCallback(
    selectedDevice => {
      const device = devices.find(
        device => device.deviceId === selectedDevice.value,
      );

      if (device) {
        filtersStates.device.set(device?.deviceId);
      }
    },
    [filtersStates.device, devices],
  );

  // const handleOnChangeOperator = useCallback(
  //   selectedOperator => {
  //     const operator = operators.find(
  //       operator => operator.id === selectedOperator.value,
  //     );

  //     if (operator) {
  //       setOperator(operator?.id);
  //     }
  //   },
  //   [operators, setOperator],
  // );

  useEffect(() => {
    getDevices();
    // getOperators();
  }, [getDevices]);

  return (
    <CustomSelect
      label="Dispositivo"
      options={formattedDevices}
      onChange={handleOnChangeDevice}
    />
  );
};

export default OperatorAndDevice;
