import React, { useEffect, useMemo } from 'react';

import { IPizzaSize } from '../../../../models/IPizza';
import IComplement from '../../../../models/IComplement';
import IComplementsGroup from '../../../../models/IComplementsGroup';

import ComplementsGroupsPizza from '../ComplementsGroupsPizza';
import { IProductSell } from '../../../../models/IFastSell';

interface ICrust {
  editPizza?: IProductSell;
  crusts: IComplement[] | undefined;
  selectedSize: IPizzaSize | undefined;
  setCrusts: React.Dispatch<React.SetStateAction<IComplement[]>>;
}

const Crust: React.FC<ICrust> = ({
  crusts,
  editPizza,
  selectedSize,
  setCrusts,
}) => {
  useEffect(() => {
    if (editPizza) {
      const complementsCrusts = editPizza.complementsGroups.filter(
        cp => cp.id === -9,
      );
      setCrusts(prev =>
        (selectedSize?.pizzaCrusts || []).map((crust, index) => {
          // Verifica se já existe um complemento com o mesmo id em `complementsCrusts`
          const existingCrust = complementsCrusts[0]?.complements.find(
            comp => comp.title === crust.name,
          );

          const matchingComplement = prev?.find(
            complement => complement.title === crust.name,
          );

          // Retorna o complemento existente ou cria um novo
          return matchingComplement
            ? {
                ...matchingComplement,
                unitPrice: crust.price || 0,
                id: crust.id,
              }
            : existingCrust
            ? {
                ...existingCrust,
                unitPrice: crust.price || 0,
                id: crust.id,
              }
            : ({
                // Cria um novo complemento com os valores padrão
                id: crust?.id,
                title: crust.name,
                isActive: true,
                position: index - 1,
                unitPrice: crust.price || 0,
                maxAmount: 1,
                deleted: false,
                error: false,
                disableEdge: crust.disableEdges,
              } as IComplement);
        }),
      );
    } else {
      setCrusts(prev =>
        (selectedSize?.pizzaCrusts || []).map((crust, index) => {
          const matchingComplement = prev?.find(
            complement => complement.title === crust.name,
          );
          return {
            amount: matchingComplement?.amount ?? 0,
            checked: matchingComplement?.checked ?? false,
            id: crust?.id,
            title: crust.name,
            isActive: true,
            position: index - 1,
            unitPrice: crust.price || 0,
            maxAmount: 1,
            deleted: false,
            error: false,
            disableEdge: crust.disableEdges,
          } as IComplement;
        }),
      );
    }
  }, [editPizza, selectedSize, setCrusts]);

  const handleSelectCrusts = (
    comple: IComplement,
    type: 'increase' | 'decrease' | 'checkbox' | 'radio',
  ) => {
    setCrusts(prev => {
      return (
        prev
          ?.map(comp => {
            if (comp.id === comple.id) {
              return { ...comp, amount: 1, checked: true };
            }
            const { amount, ...rest } = comp;
            return { ...rest, checked: false };
          })
          ?.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)) ?? []
      );
    });
  };

  const defaultPizzaCrustsGroup: IComplementsGroup = useMemo(
    () => ({
      id: -9,
      modules: '',
      deleted: false,
      title: 'Massa',
      displayName: 'Massa',
      category: 'STANDARD',
      maxAmount: 1,
      minAmount: 1,
      isActive: true,
      position: 0,
      checked: false,
      complements: crusts ?? [],
    }),

    [crusts],
  );
  return (
    <ComplementsGroupsPizza
      index={1}
      key={1}
      complementGroup={defaultPizzaCrustsGroup}
      handleSelect={handleSelectCrusts}
    />
  );
};

export default Crust;
