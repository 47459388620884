import styled from 'styled-components';

export const BoxAdd = styled.div`
  display: flex;
  flex: 1 1 80px;
  height: auto;
  border-radius: 4px;
  background-color: ${({ theme }) => theme.palette.primary};
  place-content: center;
  align-items: center;
  cursor: pointer;
  transition: 600ms;

  :hover {
    transform: scale(1.05);
    box-shadow: rgba(0, 0, 0, 0.4) 0px 2px 4px,
      rgba(0, 0, 0, 0.3) 0px 7px 13px -3px,
      rgba(0, 0, 0, 0.2) 0px -3px 0px inset;
  }

  @media (max-width: 768px) {
    flex: 1 1 50px;
  }
`;

export const ModalWrapper = styled.div<{ error: boolean }>`
  margin: 32px 0;

  input {
    width: 100%;
    border: none;
    border-bottom: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.primary : theme.palette.outline};
    height: 40px;
  }
`;
