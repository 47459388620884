import styled from 'styled-components';

export const Tittle = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  padding: 32px 0;
  gap: 5px;
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  .table {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 15px;
    width: 100%;

    .input {
      div {
        display: flex;
        max-height: 30px;
      }
    }

    .first-child {
      flex: 2;
    }

    .last-child {
      flex: 7;
    }

    .icon {
      div {
        margin-right: 0;
      }
      display: flex;
      align-items: end;
    }
  }
  .card {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
`;

export const ContainerSelect = styled.div`
  display: flex;
  flex-direction: column;

  .error {
    color: ${({ theme }) => theme.palette.primary};
  }
`;
export const RegisterSelect = styled.div<{ error?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: 10px;

  .select {
    width: 100%;
  }
  .send {
    min-width: 70px;
    height: 46px;
    padding: 10px;
    align-items: center;
    justify-content: center;
  }
  .register {
    height: 38px;
    border-radius: 4px;
    border: 1px solid
      ${({ error, theme }) => (error ? theme.palette.primary : '#aaa')};
  }
  button {
    border: none;
    display: flex;
    text-align: center;
    background-color: ${({ theme }) => theme.palette.primary};
    padding: 5px;
    border-radius: 8px;
    color: ${({ theme }) => theme.palette.text_white};
    font-size: 12px;
    transition: 500ms;
    :hover {
      background-color: ${({ theme }) => theme.palette.primary_light};
    }
  }
`;

export const StyledSelect = styled.select`
  min-height: 38px;
  border-color: #aaa;
  border-radius: 4px;
  position: relative;

  option {
    position: relative;
    z-index: 1;
    bottom: 0;

    :focus {
      position: absolute;
      top: 100%; /* Coloca o dropdown abaixo do campo de seleção */
      left: 0;
      width: 100%;
      z-index: 2;
    }
  }
`;

export const OptionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 0;
`;
