import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { DateTime } from 'luxon';
import { Line } from 'react-chartjs-2';
import { useTheme } from 'styled-components';

import { useCompany } from '../../hooks/company';
import { EModule, useOverview } from '../../hooks/overview';

import Tyle from '../../components/Tyle';
import Tour from '../../components/Tour';
import HelpButton from '../../components/HelpButton';
import LoadingAnimation from '../../components/LoadingAnimation';

import tourData from '../../tour/dashboard';
import { formatToMoney } from '../../utils/format';
import { chooseBsFoodOrVarejo } from '../../utils/subdomain';
import { LocalStorage } from '../../enums/localstorage';
import IGraphData, { IDataset } from '../../models/IGraphData';

import logo from '../../assets/logo.png';
import users from '../../assets/icons/dashboard/users-color.svg';
import orders from '../../assets/icons/dashboard/orders-color.svg';
import average from '../../assets/icons/dashboard/average-color.svg';
import discount from '../../assets/icons/dashboard/discount-color.svg';
import delivery from '../../assets/icons/dashboard/delivery-color.svg';
import revenues from '../../assets/icons/dashboard/revenues-color.svg';

import {
  Info,
  Logo,
  Main,
  Badge,
  Title,
  Total,
  Value,
  Header,
  Content,
  Wrapper,
  PageInfo,
  PageName,
  Container,
  ModuleTitle,
  ProductName,
  ButtonModule,
  ProductPrice,
  MostSoldTitle,
  CardsContainer,
  ChartContainer,
  LoadMoreItems,
  MostSoldHeader,
  MostSoldProduct,
  FiltersContainer,
  MostSoldProducts,
  MostSoldContainer,
  ButtonModuleContainer,
} from './styles';
import DateRangePicker, {
  EFilterTag,
  IDateRangePickerResponse,
} from '../../components/DateRangePicker';
import { PageNames } from '../../enums/pages';

interface IFilter {
  endValue: string;
  startValue: string;
}

const options = {
  scales: {
    yAxes: [
      {
        ticks: {
          beginAtZero: true,
        },
      },
    ],
  },
  maintainAspectRatio: false,
};

const DashboardPage: React.FC = () => {
  const theme = useTheme();
  const { company } = useCompany();

  const {
    module,
    overview,
    isOverviewLoading,
    getOverview,
    // resetModule,
    chooseModule,
    getAllItems,
  } = useOverview();

  const [isTourVisible, setIsTourVisible] = useState(false);

  const [selectedFilterTag, setSelectedFilterTag] =
    useState<EFilterTag>('HOURS');

  const [loadMoreItemsVisible, setLoadMoreItemsVisible] = useState(false);

  const [filter, setFilter] = useState<IFilter>(() => {
    return {
      endValue: DateTime.fromISO(new Date().toISOString()).toISO(),
      startValue: DateTime.fromISO(new Date().toISOString())
        .minus({ hours: 24 })
        .toISO(),
    };
  });

  useEffect(() => {
    if (company?.id && module !== 'NONE') {
      getOverview(filter.startValue, filter.endValue);
      setLoadMoreItemsVisible(true);
    }
  }, [company, filter, module, getOverview]);

  const normalizedOverview: IGraphData = useMemo(() => {
    if (overview && overview?.items?.length < 10) {
      setLoadMoreItemsVisible(false);
    }
    const labels: string[] = [];

    const datasets: IDataset[] = [
      {
        borderWidth: 0,
        label: 'Evolução de vendas por hora',
        data: [],
        fill: false,
        backgroundColor: theme.palette.primary,
        borderColor: `${theme.palette.primary}50`,
      },
    ];

    overview?.orders.forEach(order => {
      labels.push(`${order.hour}h`);
      datasets[0].data.push(Number(order.count));
    });

    return {
      labels,
      datasets,
    };
  }, [overview, theme]);

  const handleOnConfirmDateRangePicker = useCallback(
    ({ selectedTag, dates }: IDateRangePickerResponse) => {
      setSelectedFilterTag(selectedTag);

      if (dates.startDate && dates.endDate) {
        setFilter({
          startValue: DateTime.fromISO(dates.startDate.toISOString()).toISO(),
          endValue: DateTime.fromISO(dates.endDate.toISOString()).toISO(),
        });
      }
    },
    [],
  );

  const handleSelectModule = useCallback(
    (selectedModule: EModule) => {
      chooseModule(selectedModule);
    },
    [chooseModule],
  );

  const handleLoadMoreItemsClick = useCallback(() => {
    getAllItems(filter.startValue, filter.endValue);
    setLoadMoreItemsVisible(false);
  }, [getAllItems, filter]);

  const handleHelpClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  useEffect(() => {
    if (!isOverviewLoading) {
      const willTriggerTour = !localStorage.getItem(LocalStorage.TOUR);
      const documentWidth = document.documentElement.clientWidth;

      if (willTriggerTour && documentWidth > 1271) {
        localStorage.setItem(LocalStorage.TOUR, 'true');
        setIsTourVisible(true);
      }
    }
  }, [isOverviewLoading]);

  // const handleOnModuleReseted = useCallback(() => {
  //   resetModule();
  // }, [resetModule]);

  return (
    <Container>
      <Content>
        <Header>
          <PageInfo>
            <PageName>
              {PageNames.DASHBOARD}
              <HelpButton onClick={handleHelpClick} />
            </PageName>

            {/* {module !== 'NONE' && (
              <ChooseModuleButton onClick={handleOnModuleReseted}>
              <FiChevronLeft size={26} />
              <span>Selecionar Módulo</span>
              </ChooseModuleButton>
            )} */}
          </PageInfo>
        </Header>
        {isOverviewLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            {module === 'NONE' ? (
              <Main className="has-custom-scroll-bar-2">
                <ButtonModuleContainer>
                  <ButtonModule onClick={() => handleSelectModule('MENU')}>
                    <Logo src={logo} />
                    <ModuleTitle>
                      {chooseBsFoodOrVarejo('BSFOOD', 'BSVAREJO')}
                    </ModuleTitle>
                  </ButtonModule>
                  <ButtonModule onClick={() => handleSelectModule('TICKET')}>
                    <Logo src={logo} />
                    <ModuleTitle>BSTICKET</ModuleTitle>
                  </ButtonModule>
                </ButtonModuleContainer>
              </Main>
            ) : (
              <Main className="has-custom-scroll-bar-2">
                <FiltersContainer>
                  <DateRangePicker
                    selectedTag={selectedFilterTag}
                    onConfirm={handleOnConfirmDateRangePicker}
                  />
                </FiltersContainer>
                <CardsContainer id="dashboard-cards">
                  <Tyle
                    source={`${revenues}`}
                    title="Faturamento"
                    value={formatToMoney(overview?.subTotal || 0)}
                  />
                  <Tyle
                    source={`${average}`}
                    title="Ticket Médio"
                    value={formatToMoney(overview?.average || 0)}
                  />
                  <Tyle
                    source={`${orders}`}
                    title="Pedidos"
                    value={String(overview?.ordersCount || 0)}
                  />
                  <Tyle
                    source={`${users}`}
                    title="Visitas"
                    value={String(overview?.visits || 0)}
                  />
                  <Tyle
                    source={`${discount}`}
                    title="Desconto"
                    value={formatToMoney(overview?.discount || 0)}
                  />
                  <Tyle
                    source={`${delivery}`}
                    title="Taxa de entrega"
                    value={formatToMoney(overview?.deliveryFee || 0)}
                  />
                </CardsContainer>
                <ChartContainer id="dashboard-line-chart">
                  <Line
                    height={400}
                    options={options}
                    data={normalizedOverview}
                  />
                </ChartContainer>
                <MostSoldContainer id="dashboard-most-sold">
                  <MostSoldHeader>
                    <MostSoldTitle>Produtos mais vendidos</MostSoldTitle>
                  </MostSoldHeader>
                  <MostSoldProducts>
                    {overview?.items && overview?.items.length > 0 ? (
                      <>
                        {overview?.items.map((product, index) => (
                          <MostSoldProduct
                            key={product.id}
                            last={index === overview.items.length - 1}
                          >
                            <Wrapper>
                              <Badge>{`#${index + 1}`}</Badge>
                              <Info>
                                <ProductName>{product.title}</ProductName>
                                <ProductPrice>
                                  {`${formatToMoney(product.price)} unid.`}
                                </ProductPrice>
                              </Info>
                            </Wrapper>
                            <Info>
                              <ProductName>qtd.</ProductName>
                              <ProductPrice>{product.quantity}</ProductPrice>
                            </Info>
                            <Total>
                              <Title>total</Title>
                              <Value>
                                {formatToMoney(
                                  product.price * product.quantity,
                                )}
                              </Value>
                            </Total>
                          </MostSoldProduct>
                        ))}
                        {loadMoreItemsVisible && (
                          <LoadMoreItems onClick={handleLoadMoreItemsClick}>
                            Carregar Todos
                          </LoadMoreItems>
                        )}
                      </>
                    ) : (
                      <span>Nenhum produto foi vendido neste período.</span>
                    )}
                  </MostSoldProducts>
                </MostSoldContainer>
                <MostSoldContainer id="dashboard-most-sold-complements">
                  <MostSoldHeader>
                    <MostSoldTitle>Complementos mais vendidos</MostSoldTitle>
                  </MostSoldHeader>
                  <MostSoldProducts>
                    {overview?.complements &&
                    overview?.complements.length > 0 ? (
                      <>
                        {overview?.complements.map((complement, index) => (
                          <MostSoldProduct
                            key={complement.id}
                            last={index === overview.items.length - 1}
                          >
                            <Wrapper>
                              <Badge>{`#${index + 1}`}</Badge>
                              <Info>
                                <ProductName>{complement.title}</ProductName>
                                <ProductPrice>
                                  {formatToMoney(complement.price)}
                                </ProductPrice>
                              </Info>
                            </Wrapper>
                            <Info>
                              <ProductName>qtd.</ProductName>
                              <ProductPrice>{complement.quantity}</ProductPrice>
                            </Info>
                            <Total>
                              <Title>total</Title>
                              <Value>
                                {formatToMoney(
                                  complement.price * complement.quantity,
                                )}
                              </Value>
                            </Total>
                          </MostSoldProduct>
                        ))}
                      </>
                    ) : (
                      <span>Nenhum complemento foi vendido neste período.</span>
                    )}
                  </MostSoldProducts>
                </MostSoldContainer>
              </Main>
            )}
          </>
        )}
      </Content>
      {isTourVisible && <Tour steps={tourData} onFinish={handleTourFinish} />}
    </Container>
  );
};

export default DashboardPage;
