import styled from 'styled-components';

import Tooltip from '../Tooltip';

interface IInputProps {
  hasError: boolean;
  alignCenter?: boolean;
  scale: 'sm' | 'md' | 'lg' | 'xl';
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  .error {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const Label = styled.label<{ scale: 'sm' | 'md' | 'lg' | 'xl' }>`
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: ${({ scale }) =>
    scale === 'sm'
      ? '10px'
      : scale === 'md'
      ? '14px'
      : scale === 'lg'
      ? '16px'
      : '18px'};
  @media (min-width: 940px) {
    font-size: ${({ scale }) =>
      scale === 'sm'
        ? '14px'
        : scale === 'md'
        ? '14px'
        : scale === 'lg'
        ? '16px'
        : '18px'};
  }
`;

export const WrapperInput = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  input[type='number'] {
    -moz-appearance: textfield; /* Remove as setas no Firefox */
  }

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    opacity: 1; /* Força a exibição dos controles no WebKit (Chrome, Safari, etc.) */
  }
`;

export const StyledInput = styled.input<IInputProps>`
  width: 100%;
  border: 0;
  padding: 2px;
  background: none;
  font-size: ${({ scale }) =>
    scale === 'sm'
      ? '12px'
      : scale === 'md'
      ? '16px'
      : scale === 'lg'
      ? '18px'
      : '20px'};
  border-bottom: ${({ theme }) => `1px solid ${theme.palette.outline}`};
  text-align: ${({ type, alignCenter }) =>
    (type === 'number' || alignCenter) && 'center'};
  border: ${({ type, theme }) =>
    type === 'number' && `1px solid ${theme.palette.outline}`};
  border-radius: ${({ type }) => type === 'number' && '8px'};
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    margin: 0;
    -webkit-appearance: auto;
  }
  ::placeholder {
    font-size: ${({ scale }) =>
      scale === 'sm'
        ? '12px'
        : scale === 'md'
        ? '14px'
        : scale === 'lg'
        ? '16px'
        : '28px'};
  }
  @media (min-width: 940px) {
    font-size: ${({ scale }) =>
      scale === 'sm'
        ? '14px'
        : scale === 'md'
        ? '14px'
        : scale === 'lg'
        ? '16px'
        : '18px'};
  }
`;

export const Error = styled(Tooltip)`
  svg {
    margin: 0;
  }
  span {
    background: #c53030;
    color: #fff;
    &::before {
      border-color: #c53030 transparent;
    }
  }
`;

export const Copy = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  svg {
    margin: 0;
  }
`;
