import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { v4 as uuid } from 'uuid';
import { GiPizzaSlice } from 'react-icons/gi';

import {
  Group,
  Wrapper,
  NotFound,
  PizzaCard,
  GridProducts,
  FavoriteGroup,
  WrapperGroups,
} from './styles';

import { useToast } from '../../../../hooks/toast';
import { useProducts } from '../../../../hooks/products';

import ICategory from '../../../../models/ICategory';
import { IProductSell } from '../../../../models/IFastSell';

import InputSell from '../InputSell';
import ProductToGrid from '../ProductToGrid';
import MountYourPizza from '../MountYourPizza';
import Modal from '../../../../components/Modal';
import Search from '../../../../components/Search';

type IdsSelectedType = {
  IdGroupSelected: number;
  IdSizeSelected: number;
  IdSubcategorieSelected: number;
};

interface IInputProductsProps {
  categories: ICategory[];
  products: IProductSell[];
  setProductsToGrid: React.Dispatch<React.SetStateAction<IProductSell[]>>;
}
const InputProducts: React.FC<IInputProductsProps> = ({
  products,
  categories,
  setProductsToGrid,
}) => {
  const searchRef = useRef<HTMLInputElement>(null);
  const { addToast } = useToast();
  const { calcProductPrice } = useProducts();

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [openDrawer, setOpenDrawer] = useState(false);
  const [multiplierPart, setMultiplierPart] = useState(0);
  const [openMountPizza, setOpenMountPizza] = useState(false);
  const [searchCriteria, setSearchCriteria] = useState<string>('');
  const [productsCartModal, setProductsCartModal] = useState<IProductSell[]>(
    [],
  );

  const [idsSelected, setIdsSelected] = useState<IdsSelectedType>({
    IdGroupSelected:
      (products?.filter(product => product.isFavorite)?.length ?? 0) > 0
        ? -1
        : 0, // inicia mostrando os favoritos
    IdSizeSelected: 0,
    IdSubcategorieSelected: 0,
  });

  const allProducts = products?.filter(product => product.active);
  const activeProducts = useMemo(() => {
    const expandedProducts = allProducts?.flatMap(product => {
      // Se o produto tiver pizzaSizes, cria um novo produto para cada tamanho de pizza
      if (product.pizzaSizes && product.pizzaSizes.length > 0) {
        return product.pizzaSizes.map(size => ({
          ...product,
          total: size.price,
          unitPrice: size.price,
          title: `${product.title} - ${size.name}`, // Adiciona o nome do tamanho ao título do produto
          pizzaSizes: [size], // Mantém apenas o tamanho de pizza atual
        }));
      }
      // Se não tiver pizzaSizes, retorna o produto original
      return product;
    });

    const idGroup = idsSelected?.IdGroupSelected;
    const idSize = idsSelected?.IdSizeSelected;
    const idSub = idsSelected?.IdSubcategorieSelected;

    // Verifica se o id do grupo é -1 (Padrão para favoritos) ou se algum grupo está selecionado
    const onlyGroup = expandedProducts?.filter(product =>
      product?.categories?.some(cat => cat.id === idGroup),
    );

    if (idGroup === -1) {
      const favoriteProducts = expandedProducts?.filter(
        product => product.isFavorite,
      );
      return favoriteProducts;
    }
    if (idGroup === -2) {
      return expandedProducts?.filter(product => (product?.salePrice ?? 0) > 0);
    }
    // Filtros para categorias, sucbategorias e tamanhos
    if (idGroup === 0 && idSize === 0 && idSub === 0) {
      return expandedProducts;
    }
    if (idGroup !== 0 && idSize === 0 && idSub === 0) {
      return onlyGroup;
    }
    if (idGroup !== 0 && idSize !== 0 && idSub === 0) {
      return onlyGroup?.filter(
        product => product?.pizzaSizes?.[0].pizzaSizeId === idSize,
      );
    }
    if (idGroup !== 0 && idSize === 0 && idSub !== 0) {
      return onlyGroup?.filter(product => product?.subcategory?.id === idSub);
    }
    return [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [idsSelected, products]);

  const handleGroupClick = useCallback(
    (groupId: number) => {
      setSearchCriteria('');
      searchRef.current?.focus();
      setInputValue('');
      if (groupId === idsSelected.IdGroupSelected) {
        setIdsSelected({
          IdGroupSelected: 0,
          IdSubcategorieSelected: 0,
          IdSizeSelected: 0,
        });
      } else {
        setIdsSelected({
          IdGroupSelected: groupId,
          IdSubcategorieSelected: 0,
          IdSizeSelected: 0,
        });
      }
    },
    [idsSelected.IdGroupSelected],
  );

  const handleOnClickSubcategorie = (sizeId: number) => {
    if (idsSelected.IdSubcategorieSelected === sizeId) {
      setIdsSelected(old => ({
        ...old,
        IdSubcategorieSelected: 0,
      }));
    } else {
      setIdsSelected(old => ({
        ...old,
        IdSubcategorieSelected: sizeId ?? 0,
      }));
    }
  };

  const handleOnClickSize = (sizeId: number) => {
    if (idsSelected.IdSizeSelected === sizeId) {
      setIdsSelected(old => ({
        ...old,
        IdSizeSelected: 0,
      }));
    } else {
      setIdsSelected(old => ({
        ...old,
        IdSizeSelected: sizeId ?? 0,
      }));
    }
  };

  const searchedProducts = useMemo(() => {
    const string_norm = searchCriteria
      .normalize('NFD')
      .replace(/[\u0300-\u036f]/g, '')
      .toLowerCase();
    const [number, searchPart] = string_norm.split('*');
    if (number && string_norm.includes('*')) setMultiplierPart(Number(number));
    const productsByName = activeProducts?.filter(product =>
      product.title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.includes('*') ? searchPart : string_norm),
    );
    // Se encontrar produtos pelo nome, retorna esses produtos
    if ((productsByName?.length ?? 0) > 0) {
      return productsByName;
    }

    // Caso contrário, busca pelo ID
    return activeProducts?.filter(
      product =>
        product.id.toString() ===
        (string_norm.includes('*') ? searchPart : string_norm),
    );
  }, [searchCriteria, activeProducts]);

  const onChange = (e: any) => {
    setSearchCriteria(e);
    setInputValue(e);
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      if (searchedProducts?.length === 1) {
        const hasMandatoryComplement =
          searchedProducts[0].complementsGroups?.some(
            complement => complement.minAmount > 0,
          );

        if (hasMandatoryComplement) {
          setOpen(true);
        } else {
          setProductsToGrid(prev => [
            ...prev,
            {
              ...searchedProducts[0],
              productId: Number(searchedProducts[0].id),
              hash: uuid(),
              qty: multiplierPart === 0 ? 1 : multiplierPart,
              unitPrice:
                (multiplierPart === 0 ? 1 : multiplierPart) *
                searchedProducts[0].unitPrice,
              total: calcProductPrice({
                ...searchedProducts[0],
                qty: multiplierPart === 0 ? 1 : multiplierPart,
              }),
            },
          ]);
          setInputValue('');
          setMultiplierPart(0);
          setSearchCriteria('');
        }
      } else {
        setOpen(true);
      }
    }
  };

  const groupSelected: ICategory = (categories ?? []).filter(
    group => group.id === idsSelected.IdGroupSelected,
  )[0];

  const handleCancel = () => {
    setProductsCartModal([]);
    setSearchCriteria('');
    setInputValue('');
    handleGroupClick(
      (products?.filter(product => product.isFavorite)?.length ?? 0) > 0
        ? -1
        : 0,
    );
    setOpen(false);
  };

  const handleSendProductsToGrid = useCallback(() => {
    if ((productsCartModal?.length ?? 0) === 0) {
      addToast({
        type: 'error',
        description: 'Nenhum produto selecionado para lançamento!',
      });
    } else {
      const cleanedProducts = productsCartModal.map(product => ({
        ...product,
        productId: product.id,
        complementsGroups:
          product.complementsGroups?.filter(group => group.checked) ?? [],
      }));

      setProductsToGrid(prev => [
        ...prev,
        ...(cleanedProducts as IProductSell[]),
      ]);
      setProductsCartModal([]);
      setOpen(false);
      setSearchCriteria('');
      setInputValue('');
      handleGroupClick(
        (products?.filter(product => product.isFavorite)?.length ?? 0) > 0
          ? -1
          : 0,
      );
      addToast({
        type: 'success',
        description: 'Produto adicionado ao grid com sucesso!',
      });
    }
  }, [
    addToast,
    products,
    handleGroupClick,
    productsCartModal,
    setProductsToGrid,
  ]);

  const existsPizza = categories?.some(category => category.type === 'PIZZA');
  const pizzaCategories = categories?.filter(
    cetegory => cetegory.type === 'PIZZA',
  );
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Enter' && !openDrawer && !openMountPizza) {
        handleSendProductsToGrid();
        setOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleSendProductsToGrid, openDrawer, openMountPizza, setOpen]);

  useEffect(() => {
    searchRef?.current?.focus();
  }, [open]);

  return (
    <>
      <InputSell
        value={inputValue}
        label="Produtos"
        onChange={onChange}
        onClick={() => setOpen(true)}
        onKeyPress={onKeyPress}
      />
      {open && (
        <Modal
          zindex={8}
          width="auto"
          open={open}
          setOpen={setOpen}
          titleConfirm="Enviar"
          onCancel={handleCancel}
          onConfirm={handleSendProductsToGrid}
        >
          <Wrapper>
            <div className="title">
              <h3>Produtos</h3>
            </div>
            <Search
              fullscreen
              onChange={onChange}
              value={searchCriteria}
              ref={searchRef}
            />
            <WrapperGroups>
              <FavoriteGroup
                selected={idsSelected.IdGroupSelected === -1}
                onClick={() => handleGroupClick(-1)}
              >
                Favoritos
              </FavoriteGroup>
              <Group
                selected={idsSelected.IdGroupSelected === 0}
                onClick={() => handleGroupClick(0)}
              >
                Todos
              </Group>
              {(categories ?? []).map(group => (
                <Group
                  selected={idsSelected.IdGroupSelected === group.id}
                  onClick={() => handleGroupClick(group.id)}
                  key={group.id}
                >
                  {group.name}
                </Group>
              ))}
            </WrapperGroups>
            <WrapperGroups>
              {((groupSelected?.subCategories ?? []).length > 0 ||
                groupSelected?.pizzaSizes?.length > 0) && <p>Subcategorias</p>}

              {groupSelected?.subCategories?.map((subcategorie: any) => (
                <Group
                  selected={
                    subcategorie.id === idsSelected.IdSubcategorieSelected
                  }
                  key={subcategorie.id}
                  onClick={() => handleOnClickSubcategorie(subcategorie.id)}
                >
                  {subcategorie.name}
                </Group>
              ))}
              {groupSelected?.pizzaSizes?.map((pizzaSize: any) => (
                <Group
                  selected={pizzaSize.id === idsSelected.IdSizeSelected}
                  key={pizzaSize.id}
                  onClick={() => handleOnClickSize(pizzaSize.id)}
                >
                  {pizzaSize.name}
                </Group>
              ))}
            </WrapperGroups>
            {(searchedProducts?.length ?? 0) > 0 ? (
              <GridProducts className="has-custom-scroll-bar-2">
                {/* Se houver algum grupo de pizza e estiver selecionado todos grupos ele mostra o monte sua pizza */}
                {!groupSelected && existsPizza ? (
                  <>
                    <PizzaCard onClick={() => setOpenMountPizza(true)}>
                      <GiPizzaSlice size={50} />
                      <div className="separate">
                        <p className="label">Monte sua Pizza</p>
                        <p className="click">clique aqui</p>
                      </div>
                    </PizzaCard>

                    <MountYourPizza
                      open={openMountPizza}
                      setOpen={setOpenMountPizza}
                      allProducts={allProducts ?? []}
                      categories={pizzaCategories ?? []}
                      setProductsToGrid={setProductsToGrid}
                    />
                  </>
                ) : (
                  // Se o grupo selecionado for do tipo pizza mostra o monte sua pizza
                  !!groupSelected &&
                  groupSelected?.type === 'PIZZA' && (
                    <>
                      <PizzaCard onClick={() => setOpenMountPizza(true)}>
                        <GiPizzaSlice size={50} />
                        <div className="separate">
                          <p className="label">Monte sua Pizza</p>
                          <p className="click">clique aqui</p>
                        </div>
                      </PizzaCard>

                      <MountYourPizza
                        open={openMountPizza}
                        setOpen={setOpenMountPizza}
                        allProducts={allProducts ?? []}
                        categories={[groupSelected]}
                        setProductsToGrid={setProductsToGrid}
                      />
                    </>
                  )
                )}

                {searchedProducts?.map((product, index) => (
                  <ProductToGrid
                    searchRef={searchRef}
                    openDrawer={openDrawer}
                    setOpenDrawer={setOpenDrawer}
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    product={product}
                    productsCartModal={productsCartModal}
                    setProductsCartModal={setProductsCartModal}
                    setProductsToGrid={setProductsToGrid}
                  />
                ))}
              </GridProducts>
            ) : (
              <NotFound>
                <p>Nenhum produto encontrado!</p>
              </NotFound>
            )}
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export default InputProducts;
