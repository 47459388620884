import { v4 as uuid } from 'uuid';
import { IProductCart } from './IProduct';

export interface ICart {
  id: string;
  type: string;
  peoples: number;
  total: number;
  products: IProductCart[];
  totalPriceCart?: number;
}

export const defaultCart = {
  id: uuid(),
  type: 'mesa',
  peoples: 5,
  total: 240,
  products: [],
};
