import React, { useCallback, useEffect, useState } from 'react';
import { FiPlusCircle, FiTrash } from 'react-icons/fi';
import Select from 'react-select';
import Spinner from 'react-spinner-material';
import { useTheme } from 'styled-components';
import { v4 as uuid } from 'uuid';
import { CustomPizzaChargeCheckForKeys, PizzaCharges } from '../../enums/pizza';
import {
  ICustomPizzaCharge,
  ICustomPizzaChargeRule,
} from '../../models/IPizza';
import { selectStyles } from '../../styles/select';
import {
  Column,
  Container,
  Label,
  Input,
  RuleContainer,
  RulesContainer,
  StyledModal,
  AddButton,
  RemoveButton,
  Footer,
  CancelButton,
  ConfirmButton,
} from './styles';

interface ICustomPizzaChargeModalProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (charge: ICustomPizzaCharge) => Promise<void>;
  charge?: ICustomPizzaCharge;
}

const chargeOptions: {
  label: string;
  value: ICustomPizzaCharge['defaultCharge'];
}[] = [
  { label: 'Valor Médio', value: PizzaCharges.AVERAGE },
  { label: 'Maior Valor', value: PizzaCharges.GREATER },
];

const checkForOptions = [
  { label: 'Sabores', value: CustomPizzaChargeCheckForKeys.FLAVORS },
];

const CustomPizzaChargeModal: React.FC<ICustomPizzaChargeModalProps> = ({
  isOpen,
  charge,
  onConfirm,
  onClose,
}) => {
  const theme = useTheme();

  const [defaultCharge, setDefaultCharge] = useState(chargeOptions[0]);
  const [rules, setRules] = useState<ICustomPizzaChargeRule[]>([]);
  const [loading, setLoading] = useState(false);

  const handleOnDefaultChargeChange = (value: any) => {
    setDefaultCharge(value as (typeof chargeOptions)[0]);
  };

  const handleOnAddRuleButtonClick = () => {
    setRules(old => [
      ...old,
      {
        id: uuid(),
        charge: PizzaCharges.AVERAGE,
        checkFor: CustomPizzaChargeCheckForKeys.FLAVORS,
        minAmount: 0,
      },
    ]);
  };

  const handleOnRemoveRuleButtonClick = (id: string | number) => {
    setRules(old => old.filter(rule => rule.id !== id));
  };

  function handleOnRuleParamChange<T extends keyof ICustomPizzaChargeRule>(
    id: string | number,
    key: T,
    value: ICustomPizzaChargeRule[T],
  ) {
    setRules(old =>
      old.map(rule =>
        rule.id === id
          ? {
              ...rule,
              [key]: value,
            }
          : rule,
      ),
    );
  }

  const handleOnConfirm = useCallback(async () => {
    setLoading(true);
    await onConfirm({
      defaultCharge: defaultCharge.value,
      rules,
    });
    setLoading(false);
    onClose();
  }, [defaultCharge.value, onClose, onConfirm, rules]);

  useEffect(() => {
    if (isOpen && charge) {
      setRules(charge?.rules || []);
      setDefaultCharge(
        chargeOptions.find(option => option.value === charge?.defaultCharge) ||
          chargeOptions[0],
      );
    }
  }, [charge, isOpen]);

  return (
    <StyledModal
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={isOpen}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
          background: 'rgba(0, 0, 0, .6)',
        },
      }}
    >
      <Container>
        <Label>Regras:</Label>
        <RulesContainer>
          {rules.map(rule => (
            <RuleContainer key={rule.id.toString()}>
              <Column>
                <Label>Quando o campo</Label>
                <Select
                  isSearchable={false}
                  value={checkForOptions.find(
                    option => option.value === rule.checkFor,
                  )}
                  styles={selectStyles}
                  options={checkForOptions}
                  defaultValue={checkForOptions[0]}
                  onChange={value =>
                    handleOnRuleParamChange(
                      rule.id,
                      'checkFor',
                      (value as (typeof checkForOptions)[0]).value,
                    )
                  }
                  menuPortalTarget={document.body}
                />
              </Column>
              <Column>
                <Label>possuir valor mínimo de:</Label>
                <Input
                  type="number"
                  value={rule.minAmount}
                  onChange={e =>
                    handleOnRuleParamChange(
                      rule.id,
                      'minAmount',
                      Number(e?.target?.value || 0),
                    )
                  }
                />
              </Column>
              <Column>
                <Label>será cobrado:</Label>
                <Select
                  isSearchable={false}
                  value={chargeOptions.find(
                    option => option.value === rule.charge,
                  )}
                  styles={selectStyles}
                  options={chargeOptions}
                  defaultValue={chargeOptions[0]}
                  onChange={value =>
                    handleOnRuleParamChange(
                      rule.id,
                      'charge',
                      (value as (typeof chargeOptions)[0]).value,
                    )
                  }
                  menuPortalTarget={document.body}
                />
              </Column>
              <RemoveButton
                onClick={() => handleOnRemoveRuleButtonClick(rule.id)}
              >
                <FiTrash size={16} />
              </RemoveButton>
            </RuleContainer>
          ))}
          <AddButton onClick={handleOnAddRuleButtonClick}>
            <FiPlusCircle size={32} />
          </AddButton>
        </RulesContainer>
        <Label>Valor Padrão:</Label>
        <Select
          isSearchable={false}
          value={defaultCharge}
          styles={selectStyles}
          options={chargeOptions}
          onChange={handleOnDefaultChargeChange}
          placeholder="Valor padrão"
          menuPortalTarget={document.body}
        />
        <Footer>
          <CancelButton onClick={onClose}>Cancelar</CancelButton>
          <ConfirmButton onClick={handleOnConfirm}>
            {loading ? (
              <Spinner
                visible
                radius={24}
                stroke={2}
                color={theme.palette.text_white}
              />
            ) : (
              'Salvar'
            )}
          </ConfirmButton>
        </Footer>
      </Container>
    </StyledModal>
  );
};

export default CustomPizzaChargeModal;
