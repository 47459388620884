import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100vh;

  @media (max-width: 700px) {
    height: 100%;
  }
`;
export const Header = styled.div`
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  width: 100%;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 10px 32px;
`;
export const Body = styled.div`
  display: flex;
  flex-direction: column;
  padding: 10px 32px;
  gap: 16px;
  height: 78vh;
  overflow: hidden;
  .title {
    display: flex;
    width: 100%;
    gap: 20px;
  }
  .first {
    flex: 3;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .second {
    flex: 2;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  @media (max-height: 1200px) {
    height: 75vh;
  }
  @media (max-height: 1200px) {
    height: 75vh;
  }
  @media (max-height: 960px) {
    height: 69vh;
  }
  @media (max-height: 900px) {
    height: 67vh;
  }
  @media (max-height: 850px) {
    height: 65vh;
  }
  @media (max-height: 825px) {
    height: 64vh;
  }
  @media (max-height: 800px) {
    height: 63vh;
  }
  @media (max-height: 750px) {
    height: 62vh;
  }
  @media (max-height: 720px) {
    height: 61vh;
  }
  @media (max-height: 690px) {
    height: 59vh;
  }
  @media (max-height: 660px) {
    height: 57vh;
  }
  @media (max-height: 630px) {
    height: 55vh;
  }
  @media (max-height: 600px) {
    height: 53vh;
  }
  @media (max-height: 570px) {
    height: 51vh;
  }
`;

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(85px, 1fr));
  width: 100%;
  gap: 5px;
  overflow-y: auto;
  padding-bottom: 2px;

  /* display: flex;
  flex-wrap: wrap;
  align-items: stretch; */
`;

export const Transact = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex: 1;
  max-width: 100%;

  .line {
    font-size: 14px;
    display: flex;
    gap: 20px;
    align-items: center;
    div {
      margin-right: 0;
      padding: 5px;
    }
  }
`;
export const GridTransact = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  bottom: 60px;
  width: 100%;
  background-color: white;
  padding: 20px 32px;
  gap: 5px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  background-color: ${({ theme }) => theme.palette.background};
`;
export const Buttons = styled.div`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: white;
  padding: 10px 32px;
  gap: 5px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
`;

export const GridCards = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
`;

export const ValueCard = styled.div<{ color?: string; cursor?: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  font-weight: 500;
  background-color: ${({ theme }) => theme.palette.background_white};
  border-radius: 4px;
  color: ${({ theme, color }) =>
    color === 'green'
      ? '#85D1A5'
      : color === 'red'
      ? theme.palette.primary
      : '#797979'};
  gap: 5px;
  font-size: 14px;
  padding: 5px;
  border: 1px solid
    ${({ theme, color }) =>
      color === 'green'
        ? '#85D1A5'
        : color === 'red'
        ? theme.palette.primary
        : theme.palette.outline};
  cursor: ${({ cursor = false }) => cursor && 'pointer'};

  @media (max-width: 1007px) {
    font-size: 12px;
  }
  @media (max-width: 450px) {
    font-size: 10px;
  }

  .value {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    width: 100%;
    justify-content: end;
    @media (max-width: 380px) {
      font-size: 14px;
    }
  }

  .edit {
    display: flex;
    width: 100%;
    justify-content: space-between;
    @media (max-width: 415px) {
      width: 57px;
      font-size: 10px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const TotalCard = styled.div<{ smallCash: boolean }>`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 600;
  background-color: ${({ smallCash }) =>
    smallCash ? '#675B5C' : 'rgba(219, 22, 34, 0.2)'};
  border-radius: 4px;
  color: ${({ theme, smallCash }) =>
    smallCash ? 'white' : theme.palette.primary};
  font-size: 18px;
  padding: 10px;
  @media (max-height: 767px) {
    font-size: 16px;
    padding: 5px;
  }
`;
export const TotalPay = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  font-weight: 500;
  background-color: rgba(133, 209, 165, 0.2);
  border-radius: 4px;
  color: '#43A76D';
  font-size: 16px;
  padding: 10px;
  @media (max-height: 767px) {
    padding: 5px;
  }
`;
