import React, {
  useMemo,
  useState,
  useEffect,
  useCallback,
  ReactElement,
} from 'react';
import { FiDownload, FiTrash } from 'react-icons/fi';

import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import QRCodeStyling from 'qr-code-styling';

import { useCards } from '../../../../../hooks/card';
import { useTable } from '../../../../../hooks/table';
import { useToast } from '../../../../../hooks/toast';
import { useConfirmDialog } from '../../../../../hooks/confim_dialog';

import { ICard } from '../../../../../models/ICard';
import { ITable2 } from '../../../../../models/ITable2';

import {
  Box,
  Wrapper,
  GridBox,
  SelectAll,
  ContainerBtn,
  GroupSubTitles,
  SearchContainer,
  WrapperContainer,
  ContainerButtons,
  DonwloadQrCode,
} from './styles';

import Search from '../../../../Search';
import Button from '../../../../Button';
import Loading from '../../../../Loading';
import InputGenerator from '../InputGenerator';
import ModalAddTableCard from '../ModalAddTableCard';

import logo from '../../../../../assets/logo.png';
import { useCompany } from '../../../../../hooks/company';

interface IGeneratorTableCardProps {
  type: 'card' | 'table';
  icon: ReactElement<any, any>;
}
const GeneratorTableCard: React.FC<IGeneratorTableCardProps> = ({
  icon,
  type,
}) => {
  const { company } = useCompany();
  const { addToast } = useToast();
  const { ThrowError } = useConfirmDialog();
  const { getCards, deactivateCards } = useCards();
  const { getTables, deactivateTables } = useTable();

  const [search, setSearch] = useState('');
  const [remove, setRemove] = useState(false);
  const [loading, setLoading] = useState(false);
  const [cards, setCards] = useState<ICard[]>([]);
  const [tables, setTables] = useState<ITable2[]>([]);
  const [arrayToDelete, setArrayToDelete] = useState<number[]>([]);

  useEffect(() => {
    const fetchTable = async () => {
      setLoading(true);
      try {
        if (tables.length === 0 && type === 'table') {
          const response = await getTables();

          setTables(response?.data);
        }
      } catch (error) {
        ThrowError(error, 'Erro ao buscar Mesas');
      } finally {
        setLoading(false);
      }
    };

    fetchTable();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ThrowError, getTables, type]);

  useEffect(() => {
    const fetchCards = async () => {
      setLoading(true);
      try {
        if (cards.length === 0 && type === 'card') {
          const response = await getCards();
          setCards(response.data);
        }
      } catch (error) {
        ThrowError(error, 'Erro ao buscar Comandas');
      } finally {
        setLoading(false);
      }
    };

    fetchCards();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ThrowError, getCards, type]);

  const handleSelectToDelete = (number: number) => {
    const exists = arrayToDelete.includes(number);
    if (!exists) {
      setArrayToDelete(prev => (prev ? [...prev, number] : [number]));
    } else {
      setArrayToDelete(arrayToDelete.filter(tableID => tableID !== number));
    }
  };

  const handleSelectAll = (e: boolean) => {
    if (e) {
      type === 'table' &&
        setArrayToDelete(tables.map(table => table.tableNumber));

      type === 'card' && setArrayToDelete(cards.map(table => table.cardNumber));
    } else {
      setArrayToDelete([]); // Limpa o array
    }
  };

  const patchTables = useCallback(
    async (arrayCards: { tableNumber: number }[]) => {
      try {
        setLoading(true);
        const response = await deactivateTables(arrayCards);
        setTables(response.data);
      } catch (error) {
        ThrowError(error, 'Erro ao remover comandas');
      } finally {
        setLoading(false);
      }
    },
    [ThrowError, deactivateTables],
  );
  const patchCards = useCallback(
    async (arrayCards: { cardNumber: number }[]) => {
      try {
        setLoading(true);
        const response = await deactivateCards(arrayCards);
        setCards(response.data);
      } catch (error) {
        ThrowError(error, 'Erro ao remover comandas');
      } finally {
        setLoading(false);
      }
    },
    [ThrowError, deactivateCards],
  );
  const handleDeactivate = () => {
    const cardObjects = arrayToDelete.map(number => ({
      cardNumber: number,
    }));
    const tableObjects = arrayToDelete.map(number => ({
      tableNumber: number,
    }));
    if (type === 'table') {
      if (tableObjects.length > 0) {
        patchTables(tableObjects);
        setArrayToDelete([]);
      } else {
        addToast({
          type: 'error',
          description: 'Selecione ao menos 1 mesa para deletar',
        });
      }
    }
    if (type === 'card') {
      if (cardObjects.length > 0) {
        patchCards(cardObjects);
        setArrayToDelete([]);
      } else {
        addToast({
          type: 'error',
          description: 'Selecione ao menos 1 comanda para deletar',
        });
      }
    }
  };

  const searchedTables = useMemo(() => {
    return tables.filter(table => {
      const string_norm = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      return String(table.tableNumber)
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [search, tables]);

  const searchedCards = useMemo(() => {
    return cards.filter(card => {
      const string_norm = search
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      return String(card.cardNumber)
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [cards, search]);

  const generateBlobFromCanvas = (
    canvas: HTMLCanvasElement,
  ): Promise<Blob | null> => {
    return new Promise(resolve => {
      canvas.toBlob(blob => {
        resolve(blob);
      }, 'image/png'); // Converte para PNG
    });
  };

  const handleDownload = async () => {
    const zip = new JSZip();

    const qrCodePromises = tables.map(async item => {
      const { hash, tableNumber } = item;

      // Criar a instância do QRCodeStyling
      const qrCode = new QRCodeStyling({
        width: 300,
        height: 300,
        type: 'canvas', // Usando canvas para depois converter para PNG
        data: `https://${company?.subdomain}.bsfood.com.br/hash?token=${hash}`,
        image: logo,
        dotsOptions: {
          color: '#000',
          type: 'rounded',
        },
        backgroundOptions: {
          color: '#ffffff',
        },
        imageOptions: {
          crossOrigin: 'anonymous',
          margin: 0,
        },
      });

      // Criar um div temporário para renderizar o QRCode
      const tempDiv = document.createElement('div');
      document.body.appendChild(tempDiv);

      // Renderizar o QR code no div temporário
      qrCode.append(tempDiv);

      // Aguardar um pequeno tempo para garantir que o QR Code foi renderizado
      await new Promise(resolve => setTimeout(resolve, 500));

      // Acessar o canvas gerado
      const canvas = tempDiv.querySelector('canvas');

      if (!canvas) {
        console.error('Canvas não encontrado');
        return;
      }

      // Gerar o blob do canvas em formato PNG
      const blob = await generateBlobFromCanvas(canvas);

      if (blob) {
        // Adicionar o blob ao arquivo ZIP
        zip.file(`Mesa${tableNumber}.png`, blob);
      } else {
        console.error(`Erro ao gerar o blob para a Mesa: ${item.tableNumber}`);
      }

      // Remover o div temporário após o uso
      document.body.removeChild(tempDiv);
    });

    // Esperar todas as promessas serem resolvidas
    await Promise.all(qrCodePromises);

    // Gerar o arquivo ZIP final e salvar
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, 'QrCode_MesasBSfood.zip');
  };
  return (
    <Wrapper>
      {loading ? (
        <Loading />
      ) : (
        <>
          <GroupSubTitles>
            {type === 'table' ? 'Mesa' : 'Comanda'}
          </GroupSubTitles>
          <InputGenerator
            type={type}
            setCards={setCards}
            setTables={setTables}
            setLoading={setLoading}
          />

          <WrapperContainer>
            <SearchContainer>
              <Search
                alwaysFullscren
                fullscreen
                value={search}
                onChange={e => setSearch(e)}
              />
            </SearchContainer>
            {remove && (
              <>
                <SelectAll>
                  <h3>Selecionar todos</h3>
                  <input
                    type="checkbox"
                    onChange={e => handleSelectAll(e.currentTarget.checked)}
                  />
                </SelectAll>
                <p>
                  Clique sobre as
                  {type === 'table' ? ' mesas ' : ' comandas '}
                  que deseja remover
                </p>
              </>
            )}
            <GridBox className="has-custom-scroll-bar-2">
              <ModalAddTableCard
                setLoading={setLoading}
                setTables={setTables}
                setCards={setCards}
                type={type}
              />

              {type === 'table' &&
                searchedTables?.map(table => (
                  <Box
                    onClick={() =>
                      remove && handleSelectToDelete(table.tableNumber)
                    }
                    animate={remove}
                    checked={arrayToDelete.includes(table.tableNumber)}
                    key={table.tableNumber}
                  >
                    <div className="identification">
                      <p>{table.tableNumber}</p>
                      {icon}
                    </div>
                  </Box>
                ))}

              {type === 'card' &&
                searchedCards?.map(table => (
                  <Box
                    onClick={() =>
                      remove && handleSelectToDelete(table.cardNumber)
                    }
                    animate={remove}
                    checked={arrayToDelete.includes(table.cardNumber)}
                    key={table.cardNumber}
                  >
                    <div className="identification">
                      <p>{table.cardNumber}</p>
                      {icon}
                    </div>
                  </Box>
                ))}
            </GridBox>

            <ContainerBtn type={type}>
              {type === 'table' && (
                <DonwloadQrCode onClick={handleDownload}>
                  <FiDownload />
                  Baixar QrCode
                </DonwloadQrCode>
              )}

              {!remove && (
                <Button
                  icon={<FiTrash size={22} />}
                  width="auto"
                  onClick={() => setRemove(true)}
                />
              )}
            </ContainerBtn>

            {remove && (
              <ContainerButtons>
                <Button
                  bg="#aaa"
                  title="Cancelar"
                  onClick={() => {
                    handleSelectAll(false);
                    setRemove(false);
                  }}
                  side="L"
                />

                <Button
                  title="Remover selecionados"
                  onClick={handleDeactivate}
                  side="R"
                />
              </ContainerButtons>
            )}
          </WrapperContainer>
        </>
      )}
    </Wrapper>
  );
};

export default GeneratorTableCard;
