import React from 'react';
import ReactDOM from 'react-dom';
import Loading from '../Loading';
import { Overlay, DrawerWrapper } from './styles';

interface IDrawerProps {
  open: boolean;
  zindex?: number;
  loading?: boolean;
  children: React.ReactNode;
  handleClose?: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Drawer: React.FC<IDrawerProps> = ({
  open,
  zindex,
  children,
  loading = false,
  setOpen,
  handleClose,
}) => {
  return ReactDOM.createPortal(
    <Overlay
      isVisible={open}
      onClick={() => (handleClose ? handleClose() : setOpen(false))}
      zindex={zindex}
    >
      {loading ? (
        <Loading />
      ) : (
        <DrawerWrapper isVisible={open} onClick={e => e.stopPropagation()}>
          {children}
        </DrawerWrapper>
      )}
    </Overlay>,
    document.body,
  );
};

export default Drawer;
