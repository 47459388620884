import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  height: 100vh;
  padding-bottom: 100px;
  @media (max-width: 700px) {
    padding-bottom: 65px;
  }
`;
export const Header = styled.div`
  display: flex;
  width: 100%;
  max-height: 60px;
  background-color: ${({ theme }) => theme.palette.background_white};
  z-index: 999;
  padding: 0 32px;
  padding-top: 20px;
  align-items: flex-start;
  @media (max-width: 700px) {
    padding: 0 16px;
  }

  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    @media (max-width: 1024px) {
      padding: 0 16px;
    }
    @media (max-width: 1129px) {
      h2 {
        font-size: 20px;
      }
    }
  }
`;

export const Close = styled.div`
  display: flex;
  width: auto;
  height: auto;
`;

interface IContainerProps {
  hasComplements: boolean;
}
export const Container = styled.div<IContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  align-items: center;
  text-align: center;
  padding: 0 32px;
`;

export const Img = styled.div`
  img,
  svg {
    display: flex;
    align-items: center;
    width: 200px;
    height: 200px;
    border-radius: 16px;
    object-fit: cover;
  }
`;

export const HalfPrice = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  .prices {
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
`;
type IPriceProps = {
  selected: boolean;
};
export const Price = styled.div<IPriceProps>`
  width: auto;
  height: auto;
  color: ${({ theme }) => theme.palette.text_white};
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary : theme.palette.background_dark};
  display: flex;
  flex-direction: column;
  place-content: center;
  align-items: center;
  padding: 16px 32px;
  border-radius: 16px;
  cursor: pointer;
  @media (max-width: 1024px) {
    padding: 8px 16px;
  }
`;
export const Complements = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px 32px;
  max-height: 100px;

  @media (max-width: 700px) {
    padding: 12px 16px;
    max-height: 65px;
  }
`;

export const ContainerAdditionals = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: end;
  margin-top: 0.5rem;
  gap: 1rem;
  .none {
    display: none;
  }
`;

export const GridAdditional = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ItemAdditional = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  div {
    display: flex;
    gap: 1rem;
    .delete {
      align-items: end;
      transition: 300ms;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }
`;
