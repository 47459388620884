import React, { useState } from 'react';
import { ContainerCard } from './styles';

import { ICard } from '../../models/ICard';
import { statusCardTableType } from '../../enums/cardAndTableType';

import DrawerCardTable from '../DrawerCardTable';
import ModalOpenCardTable from '../ModalOpenCardTable';
import DaysHoursMinutes from '../DaysHoursMinutes';

interface ICardProps extends React.HTMLAttributes<HTMLDivElement> {
  card: ICard;
}
const Card: React.FC<ICardProps> = ({ card, ...divProps }) => {
  const [open, setOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cardState, setCardState] = useState<ICard>(card);
  return (
    <>
      <ContainerCard
        status={cardState.status}
        {...divProps}
        onClick={() =>
          cardState.status === statusCardTableType.FREE
            ? setOpen(true)
            : setOpenDrawer(true)
        }
      >
        {cardState.description && (
          <p className="description">{cardState.description}</p>
        )}
        <div className="cardNumber">
          <p>{cardState.cardNumber}</p>
        </div>
        {cardState?.openedAt && (
          <DaysHoursMinutes opened={cardState.openedAt} />
        )}
      </ContainerCard>

      {open && (
        <ModalOpenCardTable
          open={open}
          card={card}
          setOpen={setOpen}
          setOpenDrawer={setOpenDrawer}
          setCardState={setCardState}
        />
      )}

      {openDrawer && (
        <DrawerCardTable
          card={cardState}
          open={openDrawer}
          setOpen={setOpenDrawer}
          setCardState={setCardState}
        />
      )}
    </>
  );
};

export default Card;
