import React from 'react';

import {
  Name,
  Price,
  Complement,
  InputContainer,
  ComplementsWrapper,
  ComplementContainer,
} from './styles';

import IComplement from '../../../../models/IComplement';
import IComplementsGroup from '../../../../models/IComplementsGroup';

import AmountInput from '../../../../components/AmountInput';
import CheckboxComplement from '../../../../components/CheckboxComplement';
import { formatToMoney } from '../../../../utils/format';

interface IComplementsGroupsPizza {
  index: number;
  complementGroup: IComplementsGroup;
  handleSelect: (
    comple: IComplement,
    type: 'increase' | 'decrease' | 'checkbox' | 'radio',
  ) => void;
  setNextComplement?: React.Dispatch<React.SetStateAction<number>>;
}

const ComplementsGroupsPizza: React.FC<IComplementsGroupsPizza> = ({
  index,
  handleSelect,
  complementGroup,
  setNextComplement,
}) => {
  const isRadioButton =
    complementGroup?.minAmount === 1 && complementGroup?.maxAmount === 1;

  const numberButton = complementGroup.complements.some(
    (complementGroup: IComplement) =>
      (complementGroup?.maxAmount || 0) > 1 && !isRadioButton,
  );
  const isCheckbox = !isRadioButton && !numberButton;

  return (
    <ComplementsWrapper>
      <Name>
        <h4>
          {complementGroup?.displayName
            ? complementGroup?.displayName
            : complementGroup?.title}
        </h4>
        {complementGroup?.maxAmount > 1 && (
          <p>{`(até ${complementGroup?.maxAmount} itens)`}</p>
        )}
        {complementGroup?.minAmount > 0 && (
          <p className="obg">
            {/* eslint-disable-next-line */}
            Obrigatório ({complementGroup?.minAmount})
          </p>
        )}
        {complementGroup?.minAmount === 0 && <p className="opc">Opcional</p>}
      </Name>

      {/* caso seja radiobox */}
      {isRadioButton && (
        <ComplementContainer>
          {complementGroup?.complements.map((comp: IComplement) => {
            const isDisabled = complementGroup.complements.some(
              (otherComp: IComplement) =>
                otherComp.checked && otherComp.id !== comp.id,
            );
            return (
              <Complement
                key={comp?.id}
                disabled={isDisabled}
                onClick={() => {
                  setNextComplement && setNextComplement(index + 1);
                  handleSelect(comp, 'radio');
                }}
              >
                <p>{comp?.title ? comp?.title : comp?.description}</p>
                <Price bg={comp?.unitPrice > 0}>
                  {comp?.unitPrice > 0 && (
                    <p>{formatToMoney(comp?.unitPrice)}</p>
                  )}
                </Price>

                <CheckboxComplement
                  id={`${complementGroup.id}-${comp.id}`}
                  checked={comp?.checked ? comp?.checked : false}
                  onChange={() => null}
                  roundedStyle={isRadioButton}
                />
              </Complement>
            );
          })}
        </ComplementContainer>
      )}
      {/* caso seja Checkbox */}
      {isCheckbox && (
        <ComplementContainer>
          {complementGroup?.complements.map((comp: IComplement) => {
            const isDisabled =
              complementGroup.maxAmount ===
                complementGroup.complements.filter(
                  (comp: IComplement) => comp.checked,
                ).length && !comp.checked;

            const beforeDisabled =
              complementGroup.maxAmount ===
                complementGroup.complements.filter(
                  (comp: IComplement) => comp.checked,
                ).length +
                  1 && !comp.checked;
            // O -1 ou +1 utilizado na verificação é para simular a ultima entrada do usuário,
            // pois se não a condição só será verdadeira no momento em que deveria scrollar a página
            const allSelected =
              complementGroup?.complements.length - 1 ===
              complementGroup.complements.filter(
                (comp: IComplement) => comp.checked,
              ).length;
            return (
              <Complement key={comp?.id} disabled={isDisabled}>
                <p>{comp?.title ? comp?.title : comp?.description}</p>
                <Price bg={comp?.unitPrice > 0}>
                  {comp?.unitPrice > 0 && (
                    <p>{formatToMoney(comp?.unitPrice)}</p>
                  )}
                </Price>

                <CheckboxComplement
                  id={`${complementGroup.id}-${comp.id}`}
                  checked={comp?.checked ? comp?.checked : false}
                  onChange={() => {
                    if (!isDisabled) {
                      handleSelect(comp, 'checkbox');
                      if (beforeDisabled) {
                        setNextComplement && setNextComplement(index + 1);
                      }
                      // Se todos checkbox forém selecionados
                      if (allSelected && !comp?.checked) {
                        setNextComplement && setNextComplement(index + 1);
                      }
                    }
                  }}
                  roundedStyle={isRadioButton}
                />
              </Complement>
            );
          })}
        </ComplementContainer>
      )}

      {/* caso seja numberButton */}
      {numberButton && (
        <ComplementContainer>
          {complementGroup?.complements.map((comp: IComplement) => {
            const groupMaxAmount = complementGroup.maxAmount;
            const totalAmount = complementGroup?.complements.reduce(
              (accumulator: number, item: IComplement) => {
                return accumulator + (item.amount || 0); // Adiciona o valor de `amount` ao acumulador
              },
              0,
            );

            const disabledAll = totalAmount >= groupMaxAmount;
            const beforeDisabledAll = totalAmount + 1 === groupMaxAmount;
            const maxAmount = (comp?.amount ?? 0) >= (comp?.maxAmount || 0);
            return (
              <Complement key={comp?.id} disabled={maxAmount || disabledAll}>
                <p>{comp?.title ? comp?.title : comp?.description}</p>
                <Price bg={comp?.unitPrice > 0}>
                  {comp?.unitPrice > 0 && (
                    <p>{formatToMoney(comp?.unitPrice)}</p>
                  )}
                </Price>
                <InputContainer>
                  <AmountInput
                    allowsZero
                    disabled={maxAmount || disabledAll}
                    value={comp?.amount ?? 0}
                    onIncreaseAmount={() => {
                      handleSelect(comp, 'increase');
                      beforeDisabledAll &&
                        setNextComplement &&
                        setNextComplement(index + 1);
                    }}
                    onDecreaseAmount={() => handleSelect(comp, 'decrease')}
                  />
                </InputContainer>
              </Complement>
            );
          })}
        </ComplementContainer>
      )}
    </ComplementsWrapper>
  );
};

export default ComplementsGroupsPizza;
