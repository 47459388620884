/* eslint-disable no-return-assign */
import React, {
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';
import { useField } from '@unform/core';

interface IOptionProps {
  value: string;
  defaultChecked?: boolean;
}

interface IGroupProps {
  name: string;
  children: ReactElement<IOptionProps> | Array<ReactElement<IOptionProps>>;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
}

const Option: React.FC<IOptionProps> = ({ children }) => {
  return <>{children}</>;
};

const Group: React.FC<IGroupProps> = ({
  name,
  style,
  children,
  className,
  disabled,
  onChange,
  ...rest
}) => {
  const inputRefs = useRef<Array<HTMLInputElement | null>>([]);

  const { error, fieldName, defaultValue, clearError, registerField } =
    useField(name);

  const options = useMemo(() => {
    const validChildren: typeof children = [];

    React.Children.forEach(children, child => {
      if (React.isValidElement(child)) {
        validChildren.push(child);
      }
    });

    return validChildren;
  }, [children]);

  const handleOptionClick = useCallback(
    (value: string) => {
      onChange && onChange(value);
      clearError();
    },
    [onChange, clearError],
  );

  useEffect(() => {
    registerField({
      name: fieldName,
      ref: inputRefs.current,
      getValue(refs: HTMLInputElement[]) {
        const checked = refs.find(ref => ref.checked);
        return checked ? checked.value : null;
      },
      setValue(refs: HTMLInputElement[], value) {
        const item = refs.find(ref => ref.value === value);

        if (item) {
          item.checked = true;
        }
      },
    });
  }, [fieldName, registerField]);

  return (
    <div style={style} className={className} {...rest}>
      {options.map((option, index) => (
        <>
          <input
            id={`${fieldName}-${index}`}
            ref={elRef => (inputRefs.current[index] = elRef)}
            type="radio"
            name={fieldName}
            value={option.props.value}
            defaultChecked={
              option.props.defaultChecked || defaultValue === option.props.value
            }
            onClick={() => handleOptionClick(option.props.value)}
            disabled={disabled}
          />
          {/* eslint-disable-next-line react/no-array-index-key */}
          <label key={index.toString()} htmlFor={`${fieldName}-${index}`}>
            {option}
          </label>
        </>
      ))}
      <div className="error">{error || '⠀'}</div>
    </div>
  );
};

export default { Group, Option };
