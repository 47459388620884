import styled, { css } from 'styled-components';

interface IContainerProps {
  rounded?: boolean;
  disabled?: boolean;
}

export const Container = styled.div<IContainerProps>`
  margin-right: 8px;
  position: relative;
  justify-self: end;
  label {
    width: 28px;
    height: 28px;

    cursor: pointer;

    border-radius: ${({ rounded }) => (rounded ? '50%' : '4px')};

    background-color: ${({ theme, disabled }) =>
      disabled ? theme.palette.unselected : theme.palette.text_white};
    border: 1px solid
      ${({ theme, disabled }) =>
        disabled ? theme.palette.unselected : theme.palette.control};

    top: 0;
    left: 0;
    position: absolute;

    transition: all 200ms ease-out;

    ${({ rounded }) =>
      !rounded &&
      css`
        transform: scale(0.9);
      `};
  }

  label:after {
    border: 2px solid ${({ theme }) => theme.palette.text_white};
    border-top: none;
    border-right: none;
    content: '';
    height: 6px;
    left: 7px;
    opacity: 0;
    position: absolute;
    top: 8px;
    transform: rotate(-45deg);
    width: 12px;
    transition: all 200ms ease-out;
  }

  input[type='checkbox'] {
    visibility: hidden;
  }

  input[type='checkbox']:checked + label {
    border-color: ${({ theme }) => theme.palette.primary};
    background-color: ${({ theme }) => theme.palette.primary};
  }

  input[type='checkbox']:checked + label:after {
    opacity: 1;
    border-color: ${({ theme }) => theme.palette.primary_light};
  }
`;
