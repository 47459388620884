import styled from 'styled-components';

export const Row = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 3fr 0.5fr 1fr 0.5fr;
  align-items: center;
  border-bottom: 1px solid #eaeaea;
`;

export const Cell = styled.div`
  padding: 5px;
`;

export const OptionButton = styled.div`
  display: flex;
  padding: 12px;
  gap: 15px;
  align-items: center;
  border-radius: 8px;
  color: ${({ theme }) => theme.palette.primary};
  cursor: pointer;
  transition: all 0.3s ease; /* Transição suave para todas as mudanças */

  /* Efeito de sombra e elevação no hover */
  &:hover {
    transform: translateX(-2px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }

  &:active {
    transform: translateY(0);
    box-shadow: none;
  }

  @media (max-width: 600px) {
    padding: 6px;
  }
`;

export const Image = styled.div`
  svg,
  img {
    display: flex;
    align-items: center;
    width: 50px;
    height: 50px;
    object-fit: cover;
  }

  .blue {
    .st0 {
      fill: #85c1e9 !important;
    }
  }

  .green {
    .st0 {
      fill: #94e7b7 !important;
    }
  }

  .orange {
    .st0 {
      fill: #f2be90 !important;
    }
  }

  .red {
    .st0 {
      fill: #f2a59d !important;
    }
  }

  .yellow {
    .st0 {
      fill: #f9e79f !important;
    }
  }
`;
