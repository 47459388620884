import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { FiX } from 'react-icons/fi';
import {
  Header,
  Overlay,
  ModalWrapper,
  ContainerModal,
  ContainerButtons,
} from './styles';

import Button from '../Button';
import Loading from '../Loading';
import IconButton from '../IconButton';

interface IModalProps {
  open: boolean;
  width?: string;
  zindex?: number;
  loading?: boolean;
  titleCancel?: string;
  titleConfirm?: string;
  children: React.ReactNode;
  onConfirm?: () => void;
  onCancel?: () => void;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

const Modal: React.FC<IModalProps> = ({
  open,
  width,
  zindex,
  children,
  loading = false,
  titleConfirm = 'Confirmar',
  titleCancel = 'Cancelar',
  setOpen,
  onCancel,
  onConfirm,
}) => {
  useEffect(() => {
    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        onCancel && onCancel();
        setOpen(false);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onCancel, setOpen]);

  const modalRoot = document.createElement('div');

  useEffect(() => {
    // Adiciona a div criada após o body
    document.documentElement.appendChild(modalRoot);

    return () => {
      // Remove a div quando o componente for desmontado
      document.documentElement.removeChild(modalRoot);
    };
  }, [modalRoot]);

  return ReactDOM.createPortal(
    <Overlay
      zindex={zindex}
      isVisible={open}
      onClick={() => (onCancel ? onCancel() : setOpen(false))}
    >
      {loading ? (
        <Loading />
      ) : (
        <ModalWrapper
          width={width}
          isVisible={open}
          onClick={e => e.stopPropagation()}
        >
          <ContainerModal>
            <Header>
              <IconButton
                onClick={() => (onCancel ? onCancel() : setOpen(false))}
                icon={<FiX size={22} />}
              />
            </Header>
            {children}

            <ContainerButtons>
              <Button
                bg="#aaa"
                title={titleCancel}
                onClick={() => (onCancel ? onCancel() : setOpen(false))}
                side="L"
              />

              <Button title={titleConfirm} onClick={onConfirm} side="R" />
            </ContainerButtons>
          </ContainerModal>
        </ModalWrapper>
      )}
    </Overlay>,
    document.body,
  );
};

export default Modal;
