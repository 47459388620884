import React from 'react';
import { FiPlus } from 'react-icons/fi';

import Search from '../Search';
import {
  AddOperatorButton,
  ButtonSearchContainer,
  HeaderWrapper,
  PageName,
} from './styles';

interface IHeaderProps {
  selectedPage: string;
  searchCriteria: string;
  handleOpenModal: () => void;
  handleOnSearchCriteriaChanged: (text: string) => void;
}

const Header: React.FC<IHeaderProps> = ({
  selectedPage,
  searchCriteria,
  handleOnSearchCriteriaChanged,
  handleOpenModal,
}) => {
  return (
    <HeaderWrapper>
      <PageName>{selectedPage}</PageName>
      <ButtonSearchContainer>
        <Search
          value={searchCriteria}
          onChange={handleOnSearchCriteriaChanged}
        />
        <AddOperatorButton onClick={handleOpenModal}>
          <FiPlus />
          <span />
        </AddOperatorButton>
      </ButtonSearchContainer>
    </HeaderWrapper>
  );
};

export default Header;
