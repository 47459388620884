import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import Select, {
  GroupedOptionsType,
  OptionsType,
  ValueType,
} from 'react-select';

import { selectStyles } from '../../styles/select';
import { Tittle } from '../DeleteOrder/styles';
import { ContainerSelect, Form, RegisterSelect, Wrapper } from './styles';

import { ICard } from '../../models/ICard';
import { ITable2 } from '../../models/ITable2';
import IClient from '../../models/IClient';
import IOrder from '../../models/IOrder';

import { useClient } from '../../hooks/clients';
import { IEditOrder, useInternalOrders } from '../../hooks/internal_orders';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import BasicInput from '../BasicInput';
import Modal from '../Modal';
import AmountInput from '../AmountInput';
import { useTable } from '../../hooks/table';

interface IModalEditInfosCardTableProps {
  open: boolean;
  card?: ICard;
  table?: ITable2;
  order?: IOrder;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setCardState?: React.Dispatch<React.SetStateAction<ICard>>;
  setTableState?: React.Dispatch<React.SetStateAction<ITable2>>;
}
export interface ISelect {
  label: string | undefined;
  value: number | string;
}

export const ModalEditInfosCardTable: React.FC<
  IModalEditInfosCardTableProps
> = ({ open, card, table, order, setOpen, setCardState, setTableState }) => {
  const defaultLocationSelect: ValueType<ISelect, false> =
    card && card?.location?.length > 0
      ? { label: card?.location, value: card?.location }
      : {
          label: 'Digite ou selecione uma localização...',
          value: 'Digite ou selecione uma localização...',
        };
  const defaultClientSelect: ValueType<ISelect, false> =
    card !== undefined
      ? {
          label: card?.customerName ?? 'Selecione um cliente...',
          value: `${card?.customerId} - ${card.phoneNumber}`,
        }
      : table !== undefined
      ? {
          label: table?.customerName ?? 'Selecione um cliente...',
          value: `${table?.customerId} - ${table?.phoneNumber}`,
        }
      : {
          label: 'Selecione um cliente...',
          value: 'Selecione um cliente...',
        };

  const defaultLocation = (card && card.location) ?? '';
  const { getTables } = useTable();
  const { getClients, saveClient } = useClient();
  const { editOrder } = useInternalOrders();
  const { ThrowError } = useConfirmDialog();
  const [loading, setLoading] = useState(false);
  const [qtdPeople, setQtdPeople] = useState(table ? table?.personQty : 1);
  const [location, setLocation] = useState(defaultLocation);
  const [clients, setClients] = useState<IClient[]>([]);
  const [tables, setTables] = useState<ITable2[]>([]);
  const [registerClient, setRegisterClient] = useState(false);
  const [nameRegisterClient, setNameRegisterClient] = useState('');
  const [clientSelected, setClientSelected] =
    useState<ISelect>(defaultClientSelect);
  const [errorClient, setErrorClient] = useState('');
  const [locationSelected, setLocationSelected] = useState<ISelect>(
    defaultLocationSelect,
  );
  const handleChangeLocation = (e: string) => {
    if (e.length > 0) {
      setLocationSelected({ label: e, value: e });
      setLocation(e);
    }
  };
  const defaultDescription = table
    ? table.description ?? ''
    : (card && card.description) ?? '';

  const [description, setDescription] = useState(defaultDescription);

  useEffect(() => {
    const fetchTable = async () => {
      setLoading(true);
      try {
        const response = await getTables();
        setTables(response?.data);
      } catch (error) {
        ThrowError(error, 'Erro ao buscar localizações!');
      } finally {
        setLoading(false);
      }
    };

    fetchTable();
  }, [ThrowError, getTables]);

  useEffect(() => {
    const fetchClients = async () => {
      setLoading(true);
      const clientData = await getClients();
      if (clientData.length > 0) {
        setClients(clientData);
      }
      setLoading(false);
    };

    fetchClients();
  }, [getClients]);

  type SelectOptions = OptionsType<any> | GroupedOptionsType<any> | undefined;
  const optionsClient: SelectOptions = useMemo(() => {
    return clients?.map(client => {
      return {
        label: client?.name ?? '',
        value: `${client?.id} - ${client.phoneNumber}`,
      };
    });
  }, [clients]);

  const optionsLocations: SelectOptions = useMemo(() => {
    return tables?.map(table => {
      return {
        label: `Mesa ${table.tableNumber}`,
        value: `${table?.hash}`,
      };
    });
  }, [tables]);

  const handleConfirm = async () => {
    setLoading(true);
    try {
      // se nada estiver preenchido fecha o modal e não faz a requisição
      if (
        description === defaultDescription &&
        location === defaultLocation &&
        clientSelected?.value === defaultClientSelect?.value
      ) {
        setOpen(false);
        return;
      }

      // se tiver cliente então ele faz esse objeto
      let body: IEditOrder;
      const client: any = !!clientSelected && clientSelected;

      if (client && client.label !== 'Selecione um cliente...') {
        const [id, phoneNumber, customerName] = client?.value.split(' - ');
        const { label } = client;

        body = {
          orderId: order?.id ?? 0,
          waiterId: 1,
          customerId: Number(id),
          customerName: label,
          phoneNumber,
          description,
          location,
          personQty: qtdPeople ?? 1,
        };
        phoneNumber === 'undefined' && delete body.phoneNumber;
        qtdPeople === undefined && delete body.personQty;
        id === null && delete body?.customerId;
        customerName === '' && delete body.customerName;
        !!location && delete body.personQty;
        await editOrder(order?.id ?? 0, body);
      } else {
        body = {
          orderId: order?.id ?? 0,
          waiterId: 1,
          description,
          location,
          personQty: qtdPeople ?? 1,
        };

        await editOrder(order?.id ?? 0, body);
      }
      setTableState &&
        setTableState(prev => ({
          ...prev,
          description: body.description,
          customerName: body.customerName,
          customarId: body.customerId ?? null,
          personQty: body?.personQty ?? prev.personQty,
        }));
      setCardState &&
        setCardState(prev => ({
          ...prev,
          description: body?.description ?? '',
          customerName: body?.customerName,
          customerId: body?.customerId ?? null,
          location: body?.location ?? '',
        }));
      setLoading(false);
      setOpen(false);
    } catch (error) {
      setLoading(false);
      ThrowError(error, 'Erro ao registrar!');
    }
  };

  const handleNewClient = async () => {
    setLoading(true);
    try {
      if (nameRegisterClient.length < 3) {
        setErrorClient('O campo Nome precisa ter entre 3 e 120 caracteres');
        setLoading(false);
        return;
      }

      await saveClient({ name: nameRegisterClient, isManualRegister: true });
      const clientData = await getClients();
      if (clientData.length > 0) {
        setClients(clientData);
      }

      setTimeout(() => {
        setRegisterClient(false);
      }, 100000);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      ThrowError(error, 'Erro ao registrar!');
    }
  };

  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={() => handleConfirm()}
      loading={loading}
    >
      <>
        <Tittle>
          <p>
            {`Editar ${
              card
                ? `Comanda ${card?.cardNumber}`
                : `Mesa ${table?.tableNumber}`
            }`}
          </p>
        </Tittle>
        <Wrapper>
          <Form>
            {table ? (
              <div className="table">
                <div className="first-child">
                  <p>QtdPessoas</p>
                  <div className="input">
                    <AmountInput
                      setValue={setQtdPeople}
                      value={
                        qtdPeople === 0 || qtdPeople === null
                          ? table.personQty
                          : qtdPeople
                      }
                      onIncreaseAmount={e =>
                        Number(e) > 0 && setQtdPeople(Number(e))
                      }
                      onDecreaseAmount={e =>
                        Number(e) > 0 && setQtdPeople(Number(e))
                      }
                    />
                  </div>
                </div>
                <div className="last-child">
                  <BasicInput
                    title="Identificação"
                    placeholder="Ex:.  José da Silva..."
                    value={description}
                    onChange={e => {
                      e.preventDefault();
                      setDescription(e.target.value);
                    }}
                    scale="md"
                  />
                </div>
              </div>
            ) : (
              <div className="card">
                <ContainerSelect>
                  <p>Localização</p>
                  <Select
                    isClearable
                    styles={selectStyles}
                    menuPortalTarget={document.body}
                    value={locationSelected}
                    options={optionsLocations}
                    placeholder="Digite ou selecione uma localização..."
                    onInputChange={e => handleChangeLocation(e)}
                    onChange={(param: any) => {
                      setLocation(param?.label);
                      setLocationSelected(param);
                    }}
                  />
                </ContainerSelect>

                <BasicInput
                  title="Identificação"
                  placeholder="Ex:.  José da Silva..."
                  value={description}
                  onChange={e => {
                    e.preventDefault();
                    setDescription(e.target.value);
                  }}
                  scale="lg"
                />
              </div>
            )}
            {registerClient ? (
              <ContainerSelect>
                <p>Cadastrar Cliente</p>
                <RegisterSelect error={errorClient.length > 0}>
                  <BasicInput
                    clearLable={() => setRegisterClient(false)}
                    placeholder="Nome do Cliente"
                    value={nameRegisterClient}
                    onChange={e => {
                      e.preventDefault();
                      e.target.value.length >= 3 && setErrorClient('');
                      setNameRegisterClient(e.target.value);
                    }}
                    scale="lg"
                    className="register"
                  />
                  <button
                    className="send"
                    type="button"
                    onClick={handleNewClient}
                  >
                    Cadastrar
                  </button>
                </RegisterSelect>
                <p className="error">{errorClient && errorClient}</p>
              </ContainerSelect>
            ) : (
              <ContainerSelect>
                <p>Selecione um Cliente</p>
                <RegisterSelect>
                  <Select
                    className="select"
                    isClearable
                    styles={selectStyles}
                    menuPortalTarget={document.body}
                    value={clientSelected}
                    options={optionsClient}
                    placeholder="Ex:. Cliente 1"
                    onChange={(param: any) => setClientSelected(param)}
                  />
                  <button
                    type="button"
                    onClick={() => {
                      setRegisterClient(true);
                    }}
                  >
                    Novo Cliente
                  </button>
                </RegisterSelect>
              </ContainerSelect>
            )}
          </Form>
        </Wrapper>
      </>
    </Modal>
  );
};
