import { IProductSell } from '../models/IFastSell';

export const calcPizzaValue = (pizza: IProductSell | any): number => {
  const calcPrice = pizza.complementsGroups.reduce(
    (acc: number, complementGroup: any) =>
      acc +
      complementGroup.complements.reduce(
        (subAcc: number, complement: any) =>
          subAcc + complement.unitPrice * (complement?.amount ?? 1),
        0,
      ),
    0,
  );
  return calcPrice * pizza.qty;
};
