export default interface IDevice {
  id: number;
  name: string;
  deviceId: string;
  activationCode: string;
  deviceType: 'KIOSK' | 'POS' | 'TABLET';
  status: 'ACTIVE' | 'DISABLED';
}

export interface IDeviceProduct {
  productId: number;
  isActive: boolean;
}

export enum TotemAppMode {
  DEFAULT = 'DEFAULT',
  EVENT = 'EVENT',
}

export interface ITotemImagePosition {
  id: number;
  position: number;
}

export interface ITotemSettings {
  appMode: TotemAppMode;
  enableSkipPayment: boolean;
  enableNfce: boolean;
  enableDriveThru: boolean;
  enableDemoMode: boolean;
  enableSubcategoriesLayout: boolean;
  enablesCustomerNameOnOrder: boolean;
  orderTypeConfig: {
    checkout: boolean;
    onTable: boolean;
  };
  images: Array<{
    id: number;
    deviceId: number;
    active: boolean;
    url: string;
    fileName: string;
    position: number;
  }>;
}
