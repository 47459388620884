import styled from 'styled-components';

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h3 {
    font-size: 20px;
  }
`;
export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  img {
    width: 200px;
    height: 200px;
  }
`;
export const TransactModal = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px;
`;
