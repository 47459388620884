import styled, { css } from 'styled-components';

export const Wrapper = styled.div<{ selected: boolean }>`
  display: flex;

  border-radius: 8px;
  max-width: 400px;
  max-height: 55px;
  cursor: pointer;
  ${({ selected }) =>
    selected
      ? css`
          box-shadow: ${({ theme }) => theme.palette.primary} 0px 1px 3px 0px,
            ${({ theme }) => theme.palette.primary} 0px 0px 0px 1px;
        `
      : css`
          box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px,
            rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
        `};

  img,
  svg {
    border-radius: 8px 0 0 8px;
    max-width: 55px;
    max-height: 55px;
  }

  .info {
    display: flex;
    padding: 2px;
    flex-direction: column;
    width: 73%;
    height: 100%;
  }

  .codeName {
    display: flex;
    gap: 5px;
    .name {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .priceQty {
    display: flex;
    justify-content: space-between;
  }

  .input {
    div {
      max-width: 75px;
      max-height: 23px;
      input {
        max-height: 21px;
      }
    }
  }
`;
