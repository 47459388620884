/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useRef,
  useState,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';
import { FiSearch, FiX } from 'react-icons/fi';
import Loading from '../Loading';

import { Container, Input, LoadingContainer } from './styles';

interface SearchProps {
  id?: string;
  value?: string;
  loading?: boolean;
  fullscreen?: boolean;
  alwaysFullscren?: boolean;
  onChange?: (value: string) => void;
}

const Search = forwardRef<HTMLInputElement, SearchProps>(
  (
    {
      value,
      onChange,
      id,
      loading,
      fullscreen = false,
      alwaysFullscren = false,
    },
    ref,
  ) => {
    const inputRef = useRef<HTMLInputElement>(null);
    const [visible, setVisible] = useState(fullscreen);

    // Expor o `inputRef` para o componente pai
    useImperativeHandle(ref, () => inputRef.current!);

    const handleChange = useCallback(
      (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(event.target.value);
      },
      [onChange],
    );

    const handleClose = useCallback(
      (event: any) => {
        event.stopPropagation();
        if (!inputRef.current?.value) {
          setVisible(false);
        } else {
          inputRef.current?.focus();
        }
        onChange && onChange('');
      },
      [onChange],
    );

    const handleOpen = useCallback(() => {
      setVisible(true);
      setTimeout(() => inputRef.current?.focus(), 250);
    }, []);

    return (
      <Container
        visible={visible}
        onClick={handleOpen}
        id={id}
        full={fullscreen}
        alwaysFullscren={alwaysFullscren}
      >
        <FiSearch size={24} className="icon" />
        <Input value={value} onChange={handleChange} ref={inputRef} />
        {loading && (
          <LoadingContainer className="loading">
            <Loading color="black" stroke={2} radius={18} />
          </LoadingContainer>
        )}
        <FiX size={24} onClick={handleClose} />
      </Container>
    );
  },
);

export default Search;
