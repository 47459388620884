import table from '../assets/icons/table.svg';
import orders from '../assets/icons/orders.svg';
import cashier from '../assets/icons/cashier.svg';
import devices from '../assets/icons/devices.svg';
import fastSell from '../assets/icons/fastsell.svg';
import products from '../assets/icons/products.svg';
import settings from '../assets/icons/settings.svg';
import dashboard from '../assets/icons/dashboard.svg';
import operators from '../assets/icons/operators.svg';
import categories from '../assets/icons/categories.svg';
import tableBlack from '../assets/icons/table-black.svg';
import registration from '../assets/icons/registration.svg';
import recentOrders from '../assets/icons/recent-orders.svg';

import milk from '../assets/icons/milk.svg';
import peanut from '../assets/icons/peanut.svg';
import gluten from '../assets/icons/gluten.svg';

import car from '../assets/icons/car.svg';
import tea from '../assets/icons/tea.svg';
import pie from '../assets/icons/pies.svg';
import beer from '../assets/icons/beer.svg';
import bowl from '../assets/icons/bowl.svg';
import crab from '../assets/icons/crab.svg';
import fish from '../assets/icons/fish.svg';
import kids from '../assets/icons/kids.svg';
import soda from '../assets/icons/soda.svg';
import wine from '../assets/icons/wine.svg';
import plate from '../assets/icons/plate.svg';
import salad from '../assets/icons/salad.svg';
import shake from '../assets/icons/shake.svg';
import sushi from '../assets/icons/sushi.svg';
import fries from '../assets/icons/fries.svg';
import money from '../assets/icons/money.svg';
import pizza from '../assets/icons/pizza.svg';
import steak from '../assets/icons/steak.svg';
import juice from '../assets/icons/juice.svg';
import water from '../assets/icons/water.svg';
import ame_black from '../assets/icons/ame.svg';
import bakery from '../assets/icons/bakery.svg';
import coffee from '../assets/icons/coffee.svg';
import pix_black from '../assets/icons/pix.svg';
import drinks from '../assets/icons/drinks.svg';
import hotdog from '../assets/icons/hotdog.svg';
import potato from '../assets/icons/potato.svg';
import shrimp from '../assets/icons/shrimp.svg';
import waiter from '../assets/icons/waiter.svg';
import candies from '../assets/icons/candies.svg';
import chinese from '../assets/icons/chinese.svg';
import dessert from '../assets/icons/dessert.svg';
import lobster from '../assets/icons/lobster.svg';
import seafood from '../assets/icons/seafood.svg';
import cocktail from '../assets/icons/cocktail.svg';
import delivery from '../assets/icons/delivery.svg';
import pancakes from '../assets/icons/pancakes.svg';
import sandwich from '../assets/icons/sandwich.svg';
import hotDrink from '../assets/icons/hot-drink.svg';
import picpay_black from '../assets/icons/picpay.svg';
import champagne from '../assets/icons/champagne.svg';
import ice_cream from '../assets/icons/ice-cream.svg';
import hamburguer from '../assets/icons/hamburger.svg';
import caipirinha from '../assets/icons/caipirinha.svg';
import credit_card from '../assets/icons/credit-card.svg';
import whatsapp_black from '../assets/icons/whatsapp.svg';
import check_white from '../assets/icons/check_white.svg';
import check_black from '../assets/icons/check_black.svg';
import packed_lunch from '../assets/icons/packed-lunch.svg';
import shopping_bag from '../assets/icons/shopping-bag.svg';
import voucher_black from '../assets/icons/voucher-black.svg';
import voucher_white from '../assets/icons/voucher-white.svg';
import natural_sandwich from '../assets/icons/natural-sandwich.svg';

import car_white from '../assets/icons/car-white.svg';
import pix_white from '../assets/icons/pix-white.svg';
import ame_white from '../assets/icons/ame-white.svg';
import money_white from '../assets/icons/money-white.svg';
import table_white from '../assets/icons/table-white.svg';
import picpay_white from '../assets/icons/picpay-white.svg';
import whatsapp_white from '../assets/icons/whatsapp-white.svg';
import delivery_white from '../assets/icons/delivery-white.svg';
import credit_card_white from '../assets/icons/credit-card-white.svg';
import shopping_bag_white from '../assets/icons/shopping-bag-white.svg';
import monthly_payer_black from '../assets/icons/monthly-payer-black.svg';
import monthly_payer_white from '../assets/icons/monthly-payer-white.svg';

import pix_flat from '../assets/icons/pix-flat.svg';
import ame_flat from '../assets/icons/ame-flat.svg';
import money_flat from '../assets/icons/money-flat.svg';
import picpay_flat from '../assets/icons/picpay-flat.svg';
import voucher_flat from '../assets/icons/voucher-flat.svg';
import check_flat from '../assets/icons/bank-check-flat.svg';
import whatsapp_flat from '../assets/icons/whatsapp-flat.svg';
import credit_card_flat from '../assets/icons/credit-card-flat.svg';
import monthly_payer_flat from '../assets/icons/monthly-payer-flat.svg';

import vr from '../assets/icons/brands/vr.svg';
import elo from '../assets/icons/brands/elo.svg';
import pix from '../assets/icons/brands/pix.svg';
import ame from '../assets/icons/brands/ame.svg';
import tck from '../assets/icons/brands/tck.svg';
import amex from '../assets/icons/brands/amex.svg';
import vale from '../assets/icons/brands/vale.svg';
import visa from '../assets/icons/brands/visa.svg';
import alelo from '../assets/icons/brands/alelo.svg';
import check from '../assets/icons/brands/check.svg';
import diners from '../assets/icons/brands/diners.svg';
import picpay from '../assets/icons/brands/picpay.svg';
import sodexo from '../assets/icons/brands/sodexo.svg';
import moneyBrand from '../assets/icons/brands/money.svg';
import whatsapp from '../assets/icons/brands/whatsapp.svg';
import whatsappSidebar from '../assets/icons/whatsapp-sidebar.svg';
import hipercard from '../assets/icons/brands/hipercard.svg';
import coopercard from '../assets/icons/brands/coopercard.svg';
import mastercard from '../assets/icons/brands/mastercard.svg';

import { PageNames } from '../enums/pages';
import { chooseBsFoodOrVarejo } from './subdomain';
import { PaymentTypes } from '../enums/paymentType';

export const chooseCategoryIcon = (name: string): string => {
  const lowerCaseName = name.toLowerCase();

  switch (true) {
    case lowerCaseName.includes('folha'):
    case lowerCaseName.includes('salada'):
    case lowerCaseName.includes('vegano'):
    case lowerCaseName.includes('vegetariano'):
      return salad;

    case lowerCaseName.includes('pizza'):
      return pizza;

    case lowerCaseName.includes('bebidas quentes'):
      return hotDrink;

    case lowerCaseName.includes('cação'):
    case lowerCaseName.includes('peixe'):
    case lowerCaseName.includes('salmão'):
    case lowerCaseName.includes('salmao'):
    case lowerCaseName.includes('salmon'):
    case lowerCaseName.includes('salmón'):
    case lowerCaseName.includes('tilápia'):
    case lowerCaseName.includes('tilapia'):
    case lowerCaseName.includes('abadejo'):
    case lowerCaseName.includes('pescada'):
    case lowerCaseName.includes('pescado'):
      return fish;

    case lowerCaseName.includes('camarão'):
    case lowerCaseName.includes('camarao'):
    case lowerCaseName.includes('camarões'):
    case lowerCaseName.includes('camaroes'):
      return shrimp;

    case lowerCaseName.includes('porção'):
    case lowerCaseName.includes('porcao'):
    case lowerCaseName.includes('porções'):
    case lowerCaseName.includes('porcoes'):
      return fries;

    case lowerCaseName.includes('carne'):
      return steak;

    case lowerCaseName.includes('marmita'):
    case lowerCaseName.includes('quentinha'):
      return packed_lunch;

    case lowerCaseName.includes('café'):
    case lowerCaseName.includes('manhã'):
    case lowerCaseName.includes('matinal'):
    case lowerCaseName.includes('matinais'):
    case lowerCaseName.includes('expresso'):
    case lowerCaseName.includes('espresso'):
    case lowerCaseName.includes('capuccino'):
    case lowerCaseName.includes('cappucino'):
    case lowerCaseName.includes('capucino'):
    case lowerCaseName.includes('cappuccino'):
      return coffee;

    case lowerCaseName.includes('doce'):
    case lowerCaseName.includes('chocolate'):
      return candies;

    case lowerCaseName.includes('lanche'):
    case lowerCaseName.includes('burguer'):
    case lowerCaseName.includes('búrguer'):
    case lowerCaseName.includes('hambúrguer'):
    case lowerCaseName.includes('hamburguer'):
    case lowerCaseName.includes('hambúrguers'):
    case lowerCaseName.includes('hamburguers'):
    case lowerCaseName.includes('hamburgueres'):
    case lowerCaseName.includes('hambúrgueres'):
      return hamburguer;

    case lowerCaseName.includes('sanduba'):
    case lowerCaseName.includes('sanduíche'):
    case lowerCaseName.includes('sanduiche'):
    case lowerCaseName.includes('lanche natural'):
    case lowerCaseName.includes('lanches naturais'):
    case lowerCaseName.includes('lanchinho natural'):
    case lowerCaseName.includes('lanchinhos naturais'):
      return natural_sandwich;

    case lowerCaseName.includes('kids'):
    case lowerCaseName.includes('junior'):
    case lowerCaseName.includes('júnior'):
    case lowerCaseName.includes('infantil'):
      return kids;

    case lowerCaseName.includes('sorvete'):
      return ice_cream;

    case lowerCaseName.includes('fruto do mar'):
    case lowerCaseName.includes('frutos do mar'):
      return seafood;

    case lowerCaseName.includes('lata'):
    case lowerCaseName.includes('refrigerante'):
      return soda;

    case lowerCaseName.includes('chop'):
    case lowerCaseName.includes('chopp'):
    case lowerCaseName.includes('cerveja'):
      return beer;

    case lowerCaseName.includes('dose'):
    case lowerCaseName.includes('drink'):
    case lowerCaseName.includes('vodka'):
    case lowerCaseName.includes('bebida'):
    case lowerCaseName.includes('whisky'):
    case lowerCaseName.includes('uísque'):
    case lowerCaseName.includes('uisque'):
    case lowerCaseName.includes('cachaça'):
    case lowerCaseName.includes('cachaca'):
    case lowerCaseName.includes('licores'):
    case lowerCaseName.includes('tequila'):
    case lowerCaseName.includes('conhaque'):
    case lowerCaseName.includes('whiskeys'):
    case lowerCaseName.includes('digestivo'):
    case lowerCaseName.includes('gin'):
      return drinks;

    case lowerCaseName.includes('suco'):
      return juice;

    case lowerCaseName.includes('coquetél'):
    case lowerCaseName.includes('coquetel'):
    case lowerCaseName.includes('coquetéis'):
    case lowerCaseName.includes('coqueteis'):
      return cocktail;

    case lowerCaseName.includes('caipirinha'):
    case lowerCaseName.includes('caipiroska'):
    case lowerCaseName.includes('caipirosca'):
      return caipirinha;

    case lowerCaseName.includes('sushi'):
    case lowerCaseName.includes('japonesa'):
    case lowerCaseName.includes('oriental'):
    case lowerCaseName.includes('orientais'):
      return sushi;

    case lowerCaseName.includes('chinês'):
    case lowerCaseName.includes('chines'):
    case lowerCaseName.includes('chinesa'):
    case lowerCaseName.includes('yakisoba'):
    case lowerCaseName.includes('yakissoba'):
      return chinese;

    case lowerCaseName.includes('água'):
    case lowerCaseName.includes('agua'):
      return water;

    case lowerCaseName.includes('vinho'):
      return wine;

    case lowerCaseName.includes('chá'):
      return tea;

    case lowerCaseName.includes('sobremesa'):
      return dessert;

    case lowerCaseName.includes('pão'):
    case lowerCaseName.includes('pao'):
    case lowerCaseName.includes('paes'):
    case lowerCaseName.includes('pães'):
    case lowerCaseName.includes('padaria'):
      return bakery;

    case lowerCaseName.includes('champagne'):
    case lowerCaseName.includes('champanhe'):
    case lowerCaseName.includes('espumante'):
      return champagne;

    case lowerCaseName.includes('lagosta'):
      return lobster;

    case lowerCaseName.includes('siri'):
    case lowerCaseName.includes('sirí'):
    case lowerCaseName.includes('carangueijo'):
      return crab;

    case lowerCaseName.includes('lanchonete'):
      return sandwich;

    case lowerCaseName.includes('panqueca'):
      return pancakes;

    case lowerCaseName.includes('torta'):
    case lowerCaseName.includes('tortinha'):
      return pie;

    case lowerCaseName.includes('bolo'):
      return dessert;

    case lowerCaseName.includes('prato'):
    case lowerCaseName.includes('almoço'):
    case lowerCaseName.includes('executivo'):
      return plate;

    case lowerCaseName.includes('hotdog'):
    case lowerCaseName.includes('hot dog'):
    case lowerCaseName.includes('hot-dog'):
    case lowerCaseName.includes('cachorro quente'):
    case lowerCaseName.includes('cachorro-quente'):
      return hotdog;

    case lowerCaseName.includes('batata'):
      return potato;

    case lowerCaseName.includes('batida'):
      return shake;

    case lowerCaseName.includes('sopa'):
    case lowerCaseName.includes('caldo'):
      return bowl;

    default:
      return chooseBsFoodOrVarejo(waiter, shopping_bag);
  }
};

export const chooseAllergicIcon = (ref: string): string => {
  switch (ref) {
    case 'PEANUT':
      return peanut;
    case 'MILK':
      return milk;
    case 'GLUTEN':
      return gluten;
    default:
      return gluten;
  }
};

export const choosePaymentTypeIcon = (ref: string, white: boolean): string => {
  switch (ref) {
    case 'CREDIT_CARD':
    case 'DEBIT_CARD':
      return white ? credit_card_white : credit_card;
    case 'PIX':
      return white ? pix_white : pix_black;
    case 'PIC_PAY':
      return white ? picpay_white : picpay_black;
    case 'AME':
      return white ? ame_white : ame_black;
    case 'FB_PAYMENT':
      return white ? whatsapp_white : whatsapp_black;
    case 'CHECK':
      return white ? check_white : check_black;
    case 'VOUCHER':
      return white ? voucher_white : voucher_black;
    case 'MONTHLY_PAYER':
      return white ? monthly_payer_white : monthly_payer_black;
    default:
      return white ? money_white : money;
  }
};

const colorPaymentTypeMap = new Map([
  [PaymentTypes.AME, ame_flat],
  [PaymentTypes.CASH, money_flat],
  [PaymentTypes.MONEY, money_flat],
  [PaymentTypes.CHECK, check_flat],
  [PaymentTypes.FB_PAYMENT, whatsapp_flat],
  [PaymentTypes.MONTHLY_PAYER, monthly_payer_flat],
  [PaymentTypes.PIC_PAY, picpay_flat],
  [PaymentTypes.PIX, pix_flat],
  [PaymentTypes.VOUCHER, voucher_flat],
]);

export const chooseColorPaymentTypeIcon = (ref: PaymentTypes): string => {
  const found = colorPaymentTypeMap.get(ref);
  return found || credit_card_flat;
};

export const chooseDeliveryTypeIcon = (ref: string, white: boolean): string => {
  switch (ref) {
    case 'DELIVERY':
      return white ? delivery_white : delivery;
    case 'CHECKOUT':
      return white ? shopping_bag_white : shopping_bag;
    case 'ON_TABLE':
      return white ? table_white : table;
    default:
      return white ? car_white : car;
  }
};

export const choosePageIcon = (page: PageNames): string => {
  switch (page) {
    case PageNames.DASHBOARD:
      return dashboard;
    case PageNames.SETTINGS:
      return settings;
    case PageNames.CATEGORIES:
      return categories;
    case PageNames.TABLES:
      return tableBlack;
    case PageNames.CASHIER_REPORT:
      return cashier;
    case PageNames.PRODUCTS:
    case PageNames.COMPLEMENTS:
      return products;
    case PageNames.OPERATORS:
      return operators;
    case PageNames.DEVICES:
      return devices;
    case PageNames.RECENT_ORDERS:
      return recentOrders;
    case PageNames.SELL:
      return fastSell;
    case PageNames.REGISTER:
      return registration;
    case PageNames.WHATSAPP:
      return whatsappSidebar;
    default:
      return orders;
  }
};

export const chooseBrandIcon = (brand: string): string => {
  const lowercaseBrand = brand.toLowerCase();

  switch (true) {
    case lowercaseBrand.includes('alelo'):
      return alelo;

    case lowercaseBrand.includes('amex'):
      return amex;

    case lowercaseBrand.includes('cheque'):
      return check;

    case lowercaseBrand.includes('cooper'):
      return coopercard;

    case lowercaseBrand.includes('diners'):
      return diners;

    case lowercaseBrand.includes('elo'):
      return elo;

    case lowercaseBrand.includes('hipercard'):
      return hipercard;

    case lowercaseBrand.includes('mastercard'):
      return mastercard;

    case lowercaseBrand.includes('sodexo'):
      return sodexo;

    case lowercaseBrand.includes('tck'):
    case lowercaseBrand.includes('ticket restaurante'):
      return tck;

    case lowercaseBrand.includes('visa'):
      return visa;

    case lowercaseBrand.includes('vr'):
      return vr;

    case lowercaseBrand.includes('vale'):
      return vale;

    case lowercaseBrand.includes('pix'):
      return pix;

    case lowercaseBrand.includes('picpay'):
      return picpay;

    case lowercaseBrand.includes('ame'):
      return ame;

    case lowercaseBrand.includes('whatsapp'):
      return whatsapp;

    default:
      return moneyBrand;
  }
};
