import styled from 'styled-components';

export const Wrapper = styled.div`
  /* * {
    font-family: 'Courier New', Courier, monospace;
  } */
  position: fixed;
  top: 0;
  left: 0;

  visibility: hidden;
  z-index: -1;

  font-size: 12px;
  color: #000;
  width: 100%;
  margin: 0 auto;

  background-color: #ffffff;

  @media print {
    visibility: visible;
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
  }
`;

export const Header = styled.div`
  text-align: center;
  margin-bottom: 20px;
`;

export const Section = styled.div`
  margin-bottom: 20px;
`;

export const SectionHeader = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  text-align: center;
  margin-top: 32px;
  margin-bottom: 10px;
`;

export const SectionTitle = styled.div`
  font-size: 14px;
  font-weight: 700;
  text-decoration: none;
  margin-top: 24px;
  margin-bottom: 10px;
`;

export const SectionSubtitle = styled.div`
  font-size: 12px;
  font-weight: bold;
  text-decoration: none;
  text-align: center;
`;

export const Item = styled.div``;

export const Right = styled.div`
  float: right;
`;

export const Clear = styled.div`
  clear: both;
`;

export const Line = styled.div`
  border-top: 1px solid #333;
  margin: 8px 0;
`;

export const Highlight = styled.span`
  font-weight: 800;
`;

export const Footer = styled.div`
  text-align: center;
  margin-top: 20px;
`;

export const ItemTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  tbody {
    width: 100%;
  }
`;

export const ItemTableRow = styled.tr`
  width: 100%;

  td {
    padding: 5px;
    font-size: 12px;

    &:nth-child(2),
    &:nth-child(3) {
      text-align: right;
    }
  }
`;

export const TableSectionTitle = styled(SectionTitle)`
  margin-top: 8px;
  font-size: 12px;
`;
