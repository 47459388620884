import React from 'react';

import { FiPrinter } from 'react-icons/fi';

import { IShift } from '../../models/ICashierReport';

import {
  Cashier,
  CashierInfoContainer,
  CashierNumber,
  Container,
  Header,
  InfoContainer,
  Label,
  PrintButton,
  RightContainer,
  Status,
  Title,
  Value,
} from './styles';
import { formatToFullLocalDate } from '../../utils/format';
import { stopPropagation } from '../../utils/html';

interface ICashierReportProps
  extends Pick<IShift, 'id' | 'start' | 'end' | 'openOperatorName'> {
  onClickCashierReport: () => void;
  onPrintClicked: () => void;
}

const CashierReportItem: React.FC<ICashierReportProps> = ({
  id,
  start,
  end,
  openOperatorName,
  onClickCashierReport,
  onPrintClicked,
}) => {
  return (
    <Container id={id?.toString()} onClick={onClickCashierReport}>
      <Header>
        <Cashier>
          <Title>Caixa</Title>
          <CashierNumber>{id}</CashierNumber>
        </Cashier>
        <RightContainer>
          <Status status={end ? 'CLOSED' : 'OPEN'}>
            {end ? 'Fechado' : 'Aberto'}
          </Status>
          <PrintButton type="button" onClick={stopPropagation(onPrintClicked)}>
            <FiPrinter size={22} />
          </PrintButton>
        </RightContainer>
      </Header>
      <CashierInfoContainer>
        <InfoContainer>
          <Label>Data de abertura</Label>
          <Value>{formatToFullLocalDate(start)}</Value>
        </InfoContainer>
        <InfoContainer>
          <Label>Data de fechamento</Label>
          <Value>{end ? formatToFullLocalDate(end) : '-'}</Value>
        </InfoContainer>
        <InfoContainer>
          <Label>Operador (Abertura)</Label>
          <Value>{openOperatorName}</Value>
        </InfoContainer>
      </CashierInfoContainer>
    </Container>
  );
};

export default CashierReportItem;
