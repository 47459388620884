import styled from 'styled-components';

interface IDisplayProps {
  onlyTable: boolean;
}

export const Display = styled.div<IDisplayProps>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;
  height: ${({ onlyTable }) => (onlyTable ? '100%' : '50%')};
`;

export const Grid = styled.div`
  display: grid;
  width: 100%;
  height: 100%;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 10px;
  overflow-y: auto;
  grid-auto-rows: 75px;
`;

export const Center = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  place-content: center;
  align-items: center;
`;
