import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
`;
const slideInSmall = keyframes`
  from {
    transform: translateX(10%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOutSmall = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(10%);
  }
`;
const slideInMedium = keyframes`
  from {
    transform: translateX(25%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOutMedium = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(25%);
  }
`;

export const Overlay = styled.div<{ isVisible: boolean; zindex?: number }>`
  position: fixed;
  display: flex;
  align-items: center;
  place-content: center;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: ${({ zindex }) => zindex || '10'};
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(2px) brightness(0.6);
  will-change: backdrop-filter;
  opacity: ${({ isVisible }) => (isVisible ? '1' : '0')};
  visibility: ${({ isVisible }) => (isVisible ? 'visible' : 'hidden')};
  transition: opacity 100ms ease, visibility 150ms linear;
  will-change: opacity, visibility;
`;
interface IModalWrapperProps {
  isVisible: boolean;
  width?: string;
}
export const ModalWrapper = styled.div<IModalWrapperProps>`
  width: ${({ width }) => width || '450px'};
  height: auto;
  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: -2px 2px 7px -1px rgba(0, 0, 0, 0.15);
  border-radius: 16px;
  overflow: hidden;
  padding: 16px;
  z-index: 99999; /* Garante que fique sobreposta aos outros elementos */
  ${({ isVisible }) =>
    isVisible
      ? css`
          animation: ${slideIn} 0.5s forwards;
        `
      : css`
          animation: ${slideOut} 0.5s forwards;
        `}
  @media (max-width: 1280px) {
    ${({ isVisible }) =>
      isVisible
        ? css`
            animation: ${slideInMedium} 0.5s forwards;
          `
        : css`
            animation: ${slideOutMedium} 0.5s forwards;
          `}
  }
  @media (max-width: 700px) {
    ${({ isVisible }) =>
      isVisible
        ? css`
            animation: ${slideInSmall} 0.5s forwards;
          `
        : css`
            animation: ${slideOutSmall} 0.5s forwards;
          `}
  }
`;

export const ContainerModal = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const Header = styled.div`
  position: fixed;
  max-width: 40px;
  max-height: 40px;
  top: 16px;
  display: flex;
  align-items: center;
`;

export const ContainerButtons = styled.div`
  display: flex;
  justify-content: space-between;
`;
