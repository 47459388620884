import React, { useEffect, useRef, useState } from 'react';
import { DropdownButton, DropdownContainer, DropdownContent } from './styles';

type IOptionsProps = {
  title: string;
  onClick: () => void;
};

interface IDropdownProps {
  button: React.ReactNode;
  options: IOptionsProps[];
}
const Dropdown: React.FC<IDropdownProps> = ({ button, options }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [openLeft, setOpenLeft] = useState(false);
  const toggleDropdown = () => setIsOpen(!isOpen);
  const dropdownRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsOpen(false);
    }
  };

  const checkDropdownPosition = () => {
    if (contentRef.current) {
      const { right } = contentRef.current.getBoundingClientRect();

      const viewportWidth = window.innerWidth;

      const distanceFromRight = viewportWidth - right;

      // Se o dropdown está muito perto da borda direita, abrir para a esquerda
      if (distanceFromRight <= 100) {
        setOpenLeft(true);
      } else {
        setOpenLeft(false);
      }
    }
  };

  useEffect(() => {
    if (isOpen) {
      checkDropdownPosition();
    }
  }, [isOpen]);

  useEffect(() => {
    // Adiciona o listener quando o componente é montado
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Remove o listener quando o componente é desmontado
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <DropdownContainer ref={dropdownRef} onClick={toggleDropdown}>
      {isOpen && (
        <DropdownContent
          ref={contentRef}
          itemCount={options?.length || 0}
          positionUpwards={openLeft}
        >
          {options?.map((option, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <button key={index} type="button" onClick={option.onClick}>
              {option.title}
            </button>
          ))}
        </DropdownContent>
      )}

      <DropdownButton onClick={toggleDropdown}>{button}</DropdownButton>
    </DropdownContainer>
  );
};

export default Dropdown;
