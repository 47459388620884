import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 1rem;
  margin-top: 3rem;

  .step {
    display: flex;
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Container = styled.div<{ selected: boolean }>`
  display: flex;
  padding: 1rem;
  border: 1px solid
    ${({ theme, selected }) =>
      selected ? theme.palette.primary : theme.palette.control_light};
  border-radius: 8px;
  cursor: pointer;
  transition: 300ms;
  color: ${({ theme, selected }) =>
    selected ? theme.palette.text : theme.palette.text_light};

  &:hover {
    transform: scale(1.05);
  }

  span {
    font-size: 32px;
  }

  .infos {
    display: flex;
    flex-direction: column;
  }
`;
interface IPreviewProps {
  applyBackground?: boolean;
  selected?: boolean;
}
export const PizzaPreview = styled.div<IPreviewProps>`
  width: 30vw;
  height: 30vw;
  max-width: 130px;
  max-height: 130px;
  background: ${({ theme, applyBackground }) =>
    applyBackground ? theme.palette.primary : theme.palette.background_white};
  border-radius: 50%;
  /* grid-column: 1 / 2;
  grid-row: 1 / 4; */
  align-self: center;
  justify-self: center;
  opacity: ${({ selected }) => (selected ? '1' : '0.4')};
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;
interface IPizzaPreviewLineProps {
  rotation: number;
}
export const PizzaPreviewLine = styled.span<IPizzaPreviewLineProps>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  margin: auto;
  height: 50%;
  width: 3px;
  transform-origin: bottom center;
  background: ${({ theme }) => theme.palette.background_white};
  transform: ${({ rotation }) => `rotateZ(${rotation}deg)`};
`;

export const Slice = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100px;
  height: 100px;
  margin: auto;
  border-radius: 50px;
  align-self: center;
  overflow: hidden;
  transform: translate(-15px, 15px) rotateZ(45deg);

  &:before {
    content: '';
    top: 0;
    right: 0;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 72px 38px 0 38px;
    border-color: ${({ theme }) => theme.palette.primary} transparent
      transparent transparent;
  }
`;

export const GridFlavours = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
`;
export const BtnFlavours = styled.div<{ selected: boolean }>`
  display: flex;
  justify-content: center;
  width: auto;
  border-radius: 8px;
  color: ${({ theme, selected }) =>
    selected ? theme.palette.text_white : theme.palette.text};
  background-color: ${({ theme, selected }) =>
    selected ? theme.palette.primary : 'trasnparent'};
  border: 1px solid ${({ theme }) => theme.palette.control_light};
  padding: 1rem;
  cursor: pointer;
  transition: 300ms;

  &:hover {
    color: ${({ theme, selected }) => !selected && theme.palette.text_white};
    background-color: ${({ theme, selected }) =>
      !selected && theme.palette.primary_light};
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  right: 0;
  justify-content: space-between;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  padding: 24px 32px;
  max-height: 100px;
  background-color: ${({ theme }) => theme.palette.background_white};

  @media (max-width: 700px) {
    padding: 12px 16px;
    max-height: 65px;
  }

  .btns {
    display: flex;
    gap: 1rem;
    align-items: center;
  }
`;

export const FlexColumn = styled.div`
  display: flex;
  flex-direction: column;

  h3 {
    font-weight: bold;
  }
`;

export const Resume = styled.div`
  display: flex;
  gap: 1rem;
  text-align: center;
  justify-content: center;

  .title {
    font-weight: bold;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 3rem;
  margin-bottom: 1rem;
  align-items: center;
  text-align: center;
`;

export const ContainerAdditionals = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: end;
  margin-top: 0.5rem;
  gap: 1rem;
  .none {
    display: none;
  }
`;

export const GridAdditional = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;
export const ItemAdditional = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  div {
    display: flex;
    gap: 1rem;
    .delete {
      align-items: end;
      transition: 300ms;
      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }
  }
`;
