import React from 'react';
import { FiEdit } from 'react-icons/fi';
import { Actions, Badge, Container, Infos, WrapperBadge } from './styles';
import IClient from '../../models/IClient';
import { maskPhoneNumber } from '../../utils/masks';
import IconButton from '../IconButton';

interface IClientCardProps {
  client: IClient;
  onClick: () => void;
}
const ClientCard: React.FC<IClientCardProps> = ({ client, onClick }) => {
  return (
    <Container onClick={onClick}>
      <Infos>
        <div>
          <p className="name">{client.name}</p>
          <p className="phone">{maskPhoneNumber(client?.phoneNumber ?? '')}</p>
        </div>
        <Actions>
          <IconButton icon={<FiEdit size={22} />} />
        </Actions>
      </Infos>
      <WrapperBadge>{!client.isManualRegister && <Badge />}</WrapperBadge>
    </Container>
  );
};

export default ClientCard;
