import React, { useState, useCallback, useMemo, useEffect } from 'react';

import { FiGrid, FiList, FiPlus } from 'react-icons/fi';

import ICategory from '../../models/ICategory';

import { AuthRole, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { useCompany } from '../../hooks/company';
import { useProducts } from '../../hooks/products';
import { useCategories } from '../../hooks/categories';
import { useComplements } from '../../hooks/complements';
import { useProductGroups } from '../../hooks/product_groups';

import Tour from '../../components/Tour';
import Search from '../../components/Search';
import Category from '../../components/Category';
import HelpButton from '../../components/HelpButton';
import OptionsDialog from '../../components/OptionsDialog';
import LoadingAnimation from '../../components/LoadingAnimation';
import CategorySummaryModal from '../../components/CategorySummaryModal';
import ConfirmActionDialog from '../../components/ConfirmActionDialog';

import OrderCategoriesModal, {
  IOrderable,
} from '../../components/OrderCategoriesModal';

import tourData from '../../tour/categories';

import {
  Main,
  Filter,
  Header,
  Content,
  Filters,
  PageInfo,
  PageName,
  Container,
  MainHeader,
  PizzaGroupsTab,
  InnerContent,
  DisplayButton,
  FlexPlaceholder,
  ButtonsContainer,
  DisplayContainer,
  NoCategoriesFound,
  AddCategoryButton,
  AddTutorial,
  CategoriesContainer,
} from './styles';
import AddCategoryModal from '../../components/AddCategoryModal';
import OrderButton from '../../components/OrderButton';
import PizzaGroup from '../../components/PizzaGroup';
import OrderGroupModal, { IGroupProps } from '../../components/OrderGroupModal';
import AddGroupModal from '../../components/AddGroupModal';
import { IProductGroup } from '../../models/IProductGroups';
import { useConfirmDialog } from '../../hooks/confim_dialog';
import { normalizePositions } from '../../utils/arrays';
import { search } from '../../utils/search';
import useMediaQuery from '../../hooks/media_query';
import { PageNames } from '../../enums/pages';

const CategoriesPage: React.FC = () => {
  const { addToast } = useToast();
  const { company } = useCompany();
  const { loadComplementsGroups } = useComplements();
  const { changePreferableDisplay, preferableDisplay } = useAuth();
  const { products, isProductsLoading, loadProducts } = useProducts();

  const {
    categories,
    isCategoriesLoading,
    changeStatus,
    loadCategories,
    deleteCategory,
    orderCategories,
  } = useCategories();

  const {
    productGroups,
    loadProductGroups,
    deleteProductGroup,
    saveProductGroups,
  } = useProductGroups();

  const { showConfirmDialog } = useConfirmDialog();

  const isMobile = useMediaQuery('(max-width: 700px)');

  const [isTourVisible, setIsTourVisible] = useState(false);

  const [selectedCategory, setSelectedCategory] = useState<ICategory | null>(
    null,
  );

  const [confirmationMessage, setConfirmationMessage] = useState('');

  const [categoryToBeDeletedId, setCategoryToBeDeletedId] = useState(0);

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [isEditCategoryModalOpen, setIsEditCategoryModalOpen] = useState(false);

  const [isCategorySummaryModalOpen, setIsCategorySummaryModalOpen] =
    useState(false);

  const [isOrderCategoriesModalOpen, setIsOrderCategoriesModalOpen] =
    useState(false);

  const [selectedFilter, setSelectedFilter] = useState<
    'ALL' | 'CATEGORIES' | 'SUBCATEGORIES' | null
  >('ALL');

  const [searchCriteria, setSearchCriteria] = useState('');

  const [isOptionsDialogOpen, setIsOptionsDialogOpen] = useState(false);

  const [isPizzaGroupsPage, setIsPizzaGroupsPage] = useState(false);

  const [isEditGroupModalOpen, setIsEditGroupModalOpen] = useState(false);

  const [isOrderGroupModalOpen, setIsOrderGroupModalOpen] = useState(false);

  const [selectedGroup, setSelectedGroup] = useState<IProductGroup | null>(
    null,
  );

  useEffect(() => {
    async function loadData() {
      await loadProducts();
      await loadCategories();
      await loadComplementsGroups();
      await loadProductGroups();
    }

    if (company) {
      loadData();
    }
  }, [
    company,
    loadProducts,
    loadCategories,
    loadComplementsGroups,
    loadProductGroups,
  ]);

  const handleOnCategorySummaryModalOpen = useCallback(() => {
    setIsCategorySummaryModalOpen(true);
  }, []);

  const handleOnCategorySummaryModalClosed = useCallback(() => {
    setSelectedCategory(null);
    setIsCategorySummaryModalOpen(false);
  }, []);

  const handleSelectCategory = useCallback(
    (category: ICategory) => {
      setSelectedCategory(category);
      handleOnCategorySummaryModalOpen();
    },
    [handleOnCategorySummaryModalOpen],
  );

  const handleOnEditModalClose = useCallback(() => {
    setSelectedCategory(null);
    setIsEditCategoryModalOpen(false);
  }, []);

  const handleOnEditGroupModalClose = useCallback(() => {
    setIsEditGroupModalOpen(false);
    setSelectedGroup(null);
  }, []);

  const handleOnOrderModalClose = useCallback(() => {
    setIsOrderCategoriesModalOpen(false);
  }, []);

  const handleAddNewCategoryClicked = useCallback(() => {
    setIsEditCategoryModalOpen(true);
  }, []);

  const handleOnOrderGroupModalClose = useCallback(() => {
    setIsOrderGroupModalOpen(false);
  }, []);

  const handleOnOrderGroupModalConfirm = useCallback(
    (groups: IGroupProps[]) => {
      const newGroups = groups.map(group => ({
        id: group.id,
        name: group.label,
        position: group.position,
      }));

      saveProductGroups(normalizePositions(newGroups as IProductGroup[]));

      setIsOrderGroupModalOpen(false);
    },
    [saveProductGroups],
  );

  const handleAddNewGroupClicked = useCallback(() => {
    setIsEditGroupModalOpen(true);
  }, []);

  const handleOrderCategoriesClicked = useCallback(() => {
    setIsOrderCategoriesModalOpen(true);
  }, []);

  const handleOnOrderGroupClicked = useCallback(() => {
    setIsOrderGroupModalOpen(true);
  }, []);

  const handleOnEditGroupClicked = useCallback((group: IProductGroup) => {
    setSelectedGroup(group);

    setIsEditGroupModalOpen(true);
  }, []);

  const handleOnDeleteGroupClicked = useCallback(
    (group: IProductGroup) => {
      showConfirmDialog({
        title: `Excluir grupo "${group.name}"?`,
        message: 'Esta ação é irreversível!',
        onConfirm: () => deleteProductGroup(group.id),
        onCancel: () => setSelectedGroup(null),
      });
    },
    [deleteProductGroup, showConfirmDialog],
  );

  const handleOnStatusClick = useCallback(
    async (id: number, resource: string) => {
      try {
        await changeStatus(id, resource);
      } catch {
        addToast({
          type: 'error',
          description: 'Verifique sua conexão e tente novamente.',
        });
      }
    },
    [addToast, changeStatus],
  );

  const handleOnDeleteCategory = useCallback((id: number) => {
    setCategoryToBeDeletedId(id);
    setConfirmationMessage('Deseja realmente excluir a categoria?');
    setIsConfirmationDialogOpen(true);
  }, []);

  const handleOnActionDialogClosed = useCallback(() => {
    setConfirmationMessage('');
    setIsConfirmationDialogOpen(false);
  }, []);

  const handleOnCategoryDeleted = useCallback(async () => {
    try {
      await deleteCategory(categoryToBeDeletedId);
      addToast({
        type: 'success',
        description: 'Categoria removida!',
      });
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (err as any)?.response?.status === 400
            ? 'A categoria contém produtos cadastrados'
            : (Array.isArray(errors) && errors[0]) ||
              'Ocorreu um erro inesperado.',
      });
    }
  }, [addToast, categoryToBeDeletedId, deleteCategory]);

  const handleOnActionDialogConfirmation = useCallback(() => {
    handleOnActionDialogClosed();
    handleOnCategoryDeleted();
  }, [handleOnActionDialogClosed, handleOnCategoryDeleted]);

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const handleOnFilterChanged = useCallback(
    (text: 'ALL' | 'CATEGORIES' | 'SUBCATEGORIES') => {
      setSelectedFilter(text);

      if (isPizzaGroupsPage) {
        setIsPizzaGroupsPage(false);
      }
    },
    [isPizzaGroupsPage],
  );

  const handleOnOptionsDialogClosed = useCallback(() => {
    setIsOptionsDialogOpen(false);
  }, []);

  const handleOnFilterDialogConfirmed = useCallback(
    (option: string) => {
      handleOnOptionsDialogClosed();

      setSelectedFilter(option as 'ALL' | 'CATEGORIES' | 'SUBCATEGORIES');
    },
    [handleOnOptionsDialogClosed],
  );

  const handleOnDisplayChanged = useCallback(
    (display: 'GRID' | 'LIST') => {
      changePreferableDisplay(display);
    },
    [changePreferableDisplay],
  );

  const handleOrderCategories = useCallback(
    async (order: IOrderable[]) => {
      try {
        await orderCategories(order);

        setIsOrderCategoriesModalOpen(false);

        addToast({
          type: 'success',
          description: 'Categorias ordernadas.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Verifique sua conexão e tente novamente.',
        });
      }
    },
    [addToast, orderCategories],
  );

  const handleHelpClick = useCallback(() => {
    setIsTourVisible(true);
  }, []);

  const handleTourFinish = useCallback(() => {
    setIsTourVisible(false);
  }, []);

  const handleOnEditCategory = useCallback((category: ICategory) => {
    setSelectedCategory(category);
    setIsEditCategoryModalOpen(true);
  }, []);

  const handleOnGroupTabClicked = useCallback(() => {
    setIsPizzaGroupsPage(true);
    setSelectedFilter(null);
  }, []);

  const formattedProductGroups = useMemo(() => {
    const group = productGroups?.map(group => ({
      ...group,
      label: group.name,
    }));

    return group;
  }, [productGroups]);

  const dialogOptions = useMemo(() => {
    return [
      {
        ref: 'ALL',
        title: 'Todos',
        selected: selectedFilter === 'ALL',
      },
      {
        ref: 'CATEGORIES',
        title: 'Categorias',
        selected: selectedFilter === 'CATEGORIES',
      },
      {
        ref: 'SUBCATEGORIES',
        title: 'Subcategorias',
        selected: selectedFilter === 'SUBCATEGORIES',
      },
    ];
  }, [selectedFilter]);

  const categoryProducts = useMemo(() => {
    if (selectedCategory?.isSubcategory && selectedCategory.isCategory) {
      return products.filter(
        product =>
          (product.subcategory &&
            product.subcategory.id === selectedCategory?.id) ||
          product.categories.some(c => c.id === selectedCategory.id),
      );
    }

    if (selectedCategory?.isSubcategory) {
      return products.filter(
        product =>
          product.subcategory &&
          product.subcategory.id === selectedCategory?.id,
      );
    }

    return products.filter(product =>
      product.categories.some(c => c.id === selectedCategory?.id),
    );
  }, [selectedCategory, products]);

  const filteredCategories = useMemo(() => {
    switch (selectedFilter) {
      case 'CATEGORIES':
        return categories.filter(category => category.isCategory);
      case 'SUBCATEGORIES':
        return categories.filter(category => category.isSubcategory);
      default:
        return categories;
    }
  }, [selectedFilter, categories]);

  const searchedCategories = useMemo(() => {
    return filteredCategories.filter(category => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return category.name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [searchCriteria, filteredCategories]);

  const searchedGroups = useMemo(() => {
    if (searchCriteria) {
      return search(productGroups || [], item => item.name, searchCriteria);
    }

    return productGroups;
  }, [searchCriteria, productGroups]);

  return (
    <Container>
      <Content>
        <InnerContent visible>
          <Header>
            <PageInfo>
              <PageName>
                {PageNames.CATEGORIES}
                <HelpButton onClick={handleHelpClick} />
              </PageName>
            </PageInfo>
            <ButtonsContainer>
              <Search
                id="categories-search"
                value={searchCriteria}
                onChange={handleOnSearchCriteriaChanged}
              />
              <AuthRole blackList={['Employee']}>
                <OrderButton
                  id="categories-order"
                  onClick={
                    isPizzaGroupsPage
                      ? handleOnOrderGroupClicked
                      : handleOrderCategoriesClicked
                  }
                />
              </AuthRole>
              <AuthRole blackList={['Employee']}>
                <AddCategoryButton
                  onClick={
                    isPizzaGroupsPage
                      ? handleAddNewGroupClicked
                      : handleAddNewCategoryClicked
                  }
                  id="categories-add"
                >
                  <FiPlus size={24} />
                  <span />
                </AddCategoryButton>
              </AuthRole>
            </ButtonsContainer>
          </Header>
          <Main>
            <MainHeader>
              <Filters id="categories-filters">
                <Filter
                  onClick={() => handleOnFilterChanged('ALL')}
                  selected={selectedFilter === 'ALL'}
                >
                  Todas
                </Filter>
                <Filter
                  onClick={() => handleOnFilterChanged('CATEGORIES')}
                  selected={selectedFilter === 'CATEGORIES'}
                >
                  Categorias
                </Filter>
                <Filter
                  onClick={() => handleOnFilterChanged('SUBCATEGORIES')}
                  selected={selectedFilter === 'SUBCATEGORIES'}
                >
                  Subcategorias
                </Filter>
                <PizzaGroupsTab
                  selected={isPizzaGroupsPage}
                  onClick={handleOnGroupTabClicked}
                >
                  Grupos de Pizzas
                </PizzaGroupsTab>
              </Filters>
              {!isMobile && (
                <DisplayContainer id="categories-display-preference">
                  <DisplayButton>
                    <FiGrid
                      size={24}
                      onClick={() => handleOnDisplayChanged('GRID')}
                      color={preferableDisplay === 'GRID' ? '#000' : '#0005'}
                    />
                  </DisplayButton>
                  <DisplayButton>
                    <FiList
                      size={24}
                      onClick={() => handleOnDisplayChanged('LIST')}
                      color={preferableDisplay === 'LIST' ? '#000' : '#0005'}
                    />
                  </DisplayButton>
                </DisplayContainer>
              )}
            </MainHeader>
            {isProductsLoading || isCategoriesLoading ? (
              <LoadingAnimation />
            ) : isPizzaGroupsPage ? (
              <CategoriesContainer
                id="categories-container"
                className="has-custom-scroll-bar-2"
              >
                {searchedGroups ? (
                  searchedGroups.map(group => (
                    <PizzaGroup
                      key={group.id}
                      group={group}
                      display={isMobile ? 'LIST' : preferableDisplay}
                      onEdit={() => handleOnEditGroupClicked(group)}
                      onDelete={() => handleOnDeleteGroupClicked(group)}
                    />
                  ))
                ) : (
                  <AddTutorial id="groups-container">
                    <span>
                      Ainda não há grupos de pizzas cadastrados. Para cadastrar
                      o primeiro.
                      <button type="button" onClick={handleAddNewGroupClicked}>
                        clique aqui
                      </button>
                      <span>.</span>
                    </span>
                  </AddTutorial>
                )}
                <FlexPlaceholder />
                <FlexPlaceholder />
                <FlexPlaceholder />
              </CategoriesContainer>
            ) : (
              <>
                {searchedCategories.length > 0 ? (
                  <CategoriesContainer
                    id="categories-container"
                    className="has-custom-scroll-bar-2"
                  >
                    {searchedCategories.map(category => (
                      <Category
                        key={category.id}
                        category={category}
                        onClick={() => {
                          handleSelectCategory(category);
                        }}
                        display={isMobile ? 'LIST' : preferableDisplay}
                        onStatusChange={handleOnStatusClick}
                        onDelete={id => handleOnDeleteCategory(id)}
                      />
                    ))}
                    <FlexPlaceholder />
                    <FlexPlaceholder />
                    <FlexPlaceholder />
                  </CategoriesContainer>
                ) : (
                  <>
                    {selectedFilter === 'ALL' && !searchCriteria ? (
                      <AddTutorial id="categories-container">
                        <span>
                          Ainda não há categorias cadastradas. Para cadastrar a
                          primeira.
                          <button
                            type="button"
                            onClick={handleAddNewCategoryClicked}
                          >
                            clique aqui
                          </button>
                          <span>.</span>
                        </span>
                      </AddTutorial>
                    ) : (
                      <NoCategoriesFound>
                        <span>Nenhuma categoria encontrada.</span>
                      </NoCategoriesFound>
                    )}
                  </>
                )}
              </>
            )}
          </Main>
        </InnerContent>
        <OrderCategoriesModal
          visible={isOrderCategoriesModalOpen}
          onClose={handleOnOrderModalClose}
          onConfirm={handleOrderCategories}
        />
        <ConfirmActionDialog
          title="Atenção"
          message={confirmationMessage}
          isOpen={isConfirmationDialogOpen}
          onClose={handleOnActionDialogClosed}
          onConfirm={handleOnActionDialogConfirmation}
        />
        <CategorySummaryModal
          products={categoryProducts}
          onEdit={handleOnEditCategory}
          selectedCategory={selectedCategory}
          visible={isCategorySummaryModalOpen}
          onClose={handleOnCategorySummaryModalClosed}
        />
      </Content>
      <OptionsDialog
        title="Filtros"
        options={dialogOptions}
        isOpen={isOptionsDialogOpen}
        onClose={handleOnOptionsDialogClosed}
        onConfirm={handleOnFilterDialogConfirmed}
      />
      <AddCategoryModal
        category={selectedCategory}
        onClose={handleOnEditModalClose}
        isOpen={isEditCategoryModalOpen}
      />

      <OrderGroupModal
        id="order-product-group"
        groups={formattedProductGroups || []}
        isOpen={isOrderGroupModalOpen}
        onConfirm={handleOnOrderGroupModalConfirm}
        onClose={handleOnOrderGroupModalClose}
      />

      <AddGroupModal
        group={selectedGroup}
        onClose={handleOnEditGroupModalClose}
        isOpen={isEditGroupModalOpen}
      />

      {isTourVisible && <Tour steps={tourData} onFinish={handleTourFinish} />}
    </Container>
  );
};

export default CategoriesPage;
