import styled from 'styled-components';

export const CarouselWrapper = styled.div`
  width: 100%;
  position: relative;
  overflow-x: auto;
`;

export const GroupsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  max-width: 100%;
  overflow-x: auto;
  box-sizing: border-box;
  white-space: nowrap;
  scrollbar-width: none;
  -ms-overflow-style: none;
  cursor: grab;
  padding: 20px;

  &::-webkit-scrollbar {
    display: none;
  }
`;

interface IGroupContainerProps {
  favorite: boolean;
  selected: boolean;
  promotion?: boolean;
}
export const GroupContainer = styled.div<IGroupContainerProps>`
  display: flex;
  place-content: center;
  justify-content: space-between;
  align-items: center;
  width: auto;
  height: auto;
  background-color: ${({ theme, favorite, selected }) =>
    favorite && selected
      ? '#fdd400'
      : selected
      ? theme.palette.primary
      : theme.palette.background_white};
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px,
    rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
  cursor: pointer;
  padding: 10px;
  border-radius: 16px;
  color: ${({ selected, theme, favorite, promotion }) =>
    selected
      ? theme.palette.text_white
      : favorite
      ? '#fdd400'
      : promotion
      ? theme.palette.primary
      : '#000'};
  gap: 10px;
  transition: 500ms;

  :hover {
    transform: scale(1.1);
  }

  svg,
  img {
    filter: ${({ selected }) =>
      selected &&
      `invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg)
      brightness(100%) contrast(100%)`};
    width: 30px;
    height: 30px;
    object-fit: cover;
  }
`;

export const NavButton = styled.button<{ left?: boolean }>`
  display: flex;
  place-content: center;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  ${({ left }) => (left ? 'left: 0;' : 'right: 0;')}
  background-color: rgba(0, 0, 0, 0.2);
  color: #ffffff;
  border: none;
  padding: 5px;
  cursor: pointer;
  z-index: 1;
  border-radius: 16px;

  &:hover {
    background-color: rgba(0, 0, 0, 0.6);
  }
`;
