import React, {
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from 'react';

import ICompany, {
  CompanyToggleOptions,
  ITimeCompany,
} from '../models/ICompany';
import ISaveCompanyDTO from '../dtos/ISaveCompanyDTO';

import api from '../services/api';
import IStandardCancelReason from '../models/IStandardCancelReason';
import IBusinessHours from '../models/IBusinessHours';
import { normalizeHHmmToHHmmss } from '../utils/date';
import { useToast } from './toast';
import { upperCaseFirstChar } from '../utils/string';
import { IFacebookPixelIntegrationDTO } from '../dtos/IIntegrationDTO';
import { ICustomPizzaCharge } from '../models/IPizza';

interface CompanyContextData {
  company: ICompany | null;
  acceptedOrderTypes: string[];
  acceptedPaymentTypes: string[];
  isLoadingCompany?: boolean;
  isSavingCompany?: boolean;
  standardCancelReasons: IStandardCancelReason[];
  resetCompany: () => void;
  changeLogo: (file: File) => Promise<void>;
  getStandardCancelReasons: () => Promise<void>;
  loadCompany(subdomain: string): Promise<void>;
  changeLogoPrinter: (file: File) => Promise<void>;
  deleteBusinessHour: (id: number) => Promise<void>;
  saveCompany(company: ISaveCompanyDTO): Promise<void>;
  saveFacebookPixelIntegration: (
    integration: IFacebookPixelIntegrationDTO,
  ) => Promise<void>;
  togglePaymentType(name: string, active: boolean): Promise<void>;
  toggleDeliveryType(name: string, active: boolean): Promise<void>;
  saveBusinessHours(businessHours: IBusinessHours): Promise<void>;
  handleChangeTime(time: ITimeCompany): Promise<void>;
  toggleOption(
    name: CompanyToggleOptions,
    companyField?: keyof ICompany,
  ): Promise<void>;
  togglePaymentCard(id: number, type: 'LOCAL' | 'DELIVERY'): Promise<void>;
  saveCustomPizzaCharges(customPizzaCharge: ICustomPizzaCharge): Promise<void>;
}

const CompanyContext = createContext<CompanyContextData>(
  {} as CompanyContextData,
);

export const CompanyProvider: React.FC = ({ children }) => {
  const [isLoadingCompany, setIsLoadingCompany] = useState(false);
  const [isSavingCompany, setisSavingCompany] = useState(false);
  const [data, setData] = useState<ICompany | null>(null);

  const [standardCancelReasons, setStandardCancelReasons] = useState<
    IStandardCancelReason[]
  >([]);

  const [acceptedPaymentTypes, setAcceptedPaymentTypes] = useState<string[]>(
    [],
  );

  const [acceptedOrderTypes, setAcceptedOrderTypes] = useState<string[]>([]);

  const { addToast } = useToast();

  useEffect(() => {
    if (data?.orderPaymentTypes) {
      setAcceptedPaymentTypes(data.orderPaymentTypes);
    }

    if (data?.orderTypes) {
      setAcceptedOrderTypes(data.orderTypes);
    }
  }, [data]);

  const loadCompany = useCallback(async (subdomain: string) => {
    setIsLoadingCompany(true);
    const response = await api.get<ICompany>('/restricted/companies', {
      headers: {
        'X-Tenant-Id': subdomain,
      },
    });

    api.defaults.headers.common['X-Tenant-Id'] = subdomain;

    setData(response.data);
    setIsLoadingCompany(false);
  }, []);

  const getStandardCancelReasons = useCallback(async () => {
    const response = await api.get('/standard-cancel-reasons');
    setStandardCancelReasons(response.data);
  }, []);

  const saveCompany = useCallback(
    async (company: ISaveCompanyDTO) => {
      setisSavingCompany(true);
      const response = await api.put<ICompany>(
        `restricted/companies/${data?.id}`,
        {
          id: data?.id,
          ...company,
          ...(company?.address2 &&
            company?.address2.length > 2 && { address2: company?.address2 }),
          ...(company?.reference &&
            company?.reference.length > 2 && { reference: company?.reference }),
          streetNumber: company?.streetNumber || 'S/N',
        },
      );

      setData(response.data);
      setisSavingCompany(false);
    },
    [data],
  );

  const saveFacebookPixelIntegration = useCallback(
    async (integration: IFacebookPixelIntegrationDTO) => {
      setisSavingCompany(true);
      try {
        const response = await api.put<IFacebookPixelIntegrationDTO>(
          '/restricted/settings/integrations/fb-pixel',
          integration,
        );
        setData(old => ({ ...(old as ICompany), ...response.data }));
      } catch (err) {
        setisSavingCompany(false);
        throw err;
      }
      setisSavingCompany(false);
    },
    [],
  );

  const togglePaymentType = useCallback(
    async (name: string, active: boolean) => {
      await api.patch(
        `restricted/companies/${data?.id}/payment-type/${
          active ? 'deactivate' : 'activate'
        }`,
        {
          companyId: data?.id,
          orderPaymentType: name,
        },
      );

      if (active) {
        setAcceptedPaymentTypes(
          acceptedPaymentTypes.filter(type => type !== name),
        );
      } else {
        setAcceptedPaymentTypes([...acceptedPaymentTypes, name]);
      }
    },
    [data, acceptedPaymentTypes],
  );

  const toggleDeliveryType = useCallback(
    async (name: string, active: boolean) => {
      await api.patch(
        `restricted/companies/${data?.id}/order-type/${
          active ? 'deactivate' : 'activate'
        }`,
        {
          orderType: name,
          companyId: data?.id,
        },
      );

      if (active) {
        setAcceptedOrderTypes(acceptedOrderTypes.filter(type => type !== name));
      } else {
        setAcceptedOrderTypes([...acceptedOrderTypes, name]);
      }
    },
    [data, acceptedOrderTypes],
  );

  const resetCompany = useCallback(() => {
    setData(null);
  }, []);

  const deleteBusinessHour = useCallback(
    async (id: number) => {
      await api.delete(`restricted/companies/${data?.id}/business-hours/${id}`);
    },
    [data],
  );

  const changeLogo = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('companyId', String(data?.id));
      formData.append('image', file);

      await api.patch(`restricted/companies/${data?.id}/image`, formData);
    },
    [data],
  );

  const changeLogoPrinter = useCallback(
    async (file: File) => {
      const formData = new FormData();
      formData.append('companyId', String(data?.id));
      formData.append('image', file);

      await api.patch(
        `restricted/companies/${data?.id}/image-printer`,
        formData,
      );
    },
    [data],
  );

  const saveBusinessHours = useCallback(
    async (businessHours: IBusinessHours) => {
      await api.post<IBusinessHours>(
        '/restricted/companies/business-hours',
        businessHours.map(day => {
          return {
            ...day,
            shifts: day.shifts.map(shift => ({
              ...shift,
              open: normalizeHHmmToHHmmss(shift.open),
              close: normalizeHHmmToHHmmss(shift.close),
            })),
          };
        }),
      );
    },
    [],
  );
  const handleToggleOption = useCallback(
    async (option: CompanyToggleOptions, companyField?: keyof ICompany) => {
      try {
        const { data } = await api.patch(
          `restricted/settings/companies/${upperCaseFirstChar(option)}/toggle`,
        );

        setData(old => {
          if (!old) return null;

          const newValue = data?.active;
          return { ...old, [companyField || option]: newValue };
        });

        addToast({
          type: 'success',
          description: 'Alteração feita com sucesso.',
        });
      } catch (err) {
        addToast({
          type: 'error',
          description: 'Erro inesperado.',
        });
      }
    },
    [addToast],
  );

  const handleTogglePaymentCard = useCallback(
    async (id: number, type: 'LOCAL' | 'DELIVERY') => {
      try {
        const { data } = await api.patch(
          `restricted/settings/companies/paymentCard/${id}/${type.toLowerCase()}/toggle`,
        );

        setData(old => {
          if (!old) return null;

          const foundBrandIndex = old.paymentCards.findIndex(
            card => card.paymentCardId === id,
          );
          if (foundBrandIndex === -1) return old;
          const { paymentCards } = old;
          const newPaymentCards = [...paymentCards];
          const foundPaymentCard = newPaymentCards[foundBrandIndex];
          const key = type === 'LOCAL' ? 'local' : 'delivery';
          newPaymentCards[foundBrandIndex] = {
            ...foundPaymentCard,
            [key]: data?.active,
          };
          return { ...old, paymentCards: newPaymentCards };
        });

        addToast({
          type: 'success',
          description: 'Alteração feita com sucesso.',
        });
      } catch (err) {
        addToast({
          type: 'error',
          description: 'Erro inesperado.',
        });
      }
    },
    [addToast],
  );

  const handleOnSaveCustomPizzaCharges = useCallback(
    async (customPizzaCharge: ICustomPizzaCharge) => {
      try {
        await api.post(
          '/restricted/settings/companies/pizza-charges',
          customPizzaCharge,
        );
        // setData(old => ({ ...old,  }))
        addToast({
          type: 'success',
          description: 'Alteração feita com sucesso.',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao salvar alteração.',
        });
      }
    },
    [addToast],
  );

  const handleChangeTime = useCallback(
    async (times: ITimeCompany) => {
      try {
        const response = await api.patch(
          '/restricted/companies/preparation-time',
          times,
        );
        setData(response.data);
      } catch (err) {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro ao alterar o tempo!',
        });
        console.error(err);
      }
    },
    [addToast],
  );

  return (
    <CompanyContext.Provider
      value={{
        company: data,
        isSavingCompany,
        isLoadingCompany,
        acceptedOrderTypes,
        acceptedPaymentTypes,
        standardCancelReasons,
        changeLogo,
        loadCompany,
        saveCompany,
        resetCompany,
        changeLogoPrinter,
        togglePaymentType,
        deleteBusinessHour,
        toggleDeliveryType,
        getStandardCancelReasons,
        saveBusinessHours,
        toggleOption: handleToggleOption,
        saveFacebookPixelIntegration,
        togglePaymentCard: handleTogglePaymentCard,
        saveCustomPizzaCharges: handleOnSaveCustomPizzaCharges,
        handleChangeTime,
      }}
    >
      {children}
    </CompanyContext.Provider>
  );
};

export function useCompany(): CompanyContextData {
  const context = useContext(CompanyContext);

  if (!context) {
    throw new Error('useCompany must be used within CompanyProvider');
  }

  return context;
}
