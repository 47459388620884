import React, { useEffect, useMemo } from 'react';

import { IPizzaSize } from '../../../../models/IPizza';
import IComplement from '../../../../models/IComplement';
import IComplementsGroup from '../../../../models/IComplementsGroup';

import ComplementsGroupsPizza from '../ComplementsGroupsPizza';
import { IProductSell } from '../../../../models/IFastSell';

interface IFlavours {
  flavoursQty: number;
  editPizza?: IProductSell;
  flavours: IComplement[];
  allProducts: IProductSell[];
  selectedSize: IPizzaSize | undefined;
  setFlavours: React.Dispatch<React.SetStateAction<IComplement[]>>;
}

const Flavours: React.FC<IFlavours> = ({
  flavours,
  editPizza,
  allProducts,
  flavoursQty,
  selectedSize,
  setFlavours,
}) => {
  const productsOfTheSelectedSize = allProducts.filter(currentProduct =>
    currentProduct?.pizzaSizes
      ?.filter(pz => pz.active)
      .some(pz => pz.pizzaSizeId === selectedSize?.id),
  );

  useEffect(() => {
    if (editPizza) {
      const complementFlavours = editPizza.complementsGroups.filter(
        cp => cp.id === -8,
      );

      setFlavours(prev =>
        productsOfTheSelectedSize?.map((flav, index) => {
          const currentPizzaSize = flav?.pizzaSizes?.find(
            pz => pz.pizzaSizeId === selectedSize?.id,
          );

          // Verifica se já existe um sabor com o mesmo id em `complementFlavours`
          const existingFlavour =
            prev.length > 0
              ? prev.find(prevC => prevC.title === flav.title)
              : complementFlavours[0]?.complements.find(
                  comp => comp.productId === flav.id,
                );

          // Retorna o sabor existente ou cria um novo
          return existingFlavour
            ? {
                ...existingFlavour,
                unitPrice: (currentPizzaSize?.price || 0) / flavoursQty,
                id: currentPizzaSize?.id,
                maxAmount: flavoursQty > 2 ? flavoursQty - 1 : 1,

                // Mantém os atributos do sabor existente
              }
            : {
                // Cria um novo sabor com os valores padrão
                id: currentPizzaSize?.id,
                title: flav.title,
                productId: flav?.id,
                isActive: true,
                position: index - 1,
                unitPrice: (currentPizzaSize?.price || 0) / flavoursQty,
                description: flav?.description,
                maxAmount: flavoursQty > 2 ? flavoursQty - 1 : 1,
                deleted: false,
                error: false,
                complementsPizza: flav.complementsGroups ?? [],
              };
        }),
      );
    } else {
      setFlavours(prev =>
        productsOfTheSelectedSize?.map((flav, index) => {
          const currentPizzaSize = flav?.pizzaSizes?.find(
            pz => pz.pizzaSizeId === selectedSize?.id,
          );

          const existingFlavour = prev?.find(p => p.title === flav?.title);
          return {
            id: currentPizzaSize?.id,
            title: flav.title,
            productId: flav?.id,
            isActive: true,
            position: index - 1,
            unitPrice: (currentPizzaSize?.price || 0) / flavoursQty,
            description: flav?.description,
            maxAmount: flavoursQty > 2 ? flavoursQty - 1 : 1,
            deleted: false,
            error: false,
            complementsPizza: flav.complementsGroups ?? [],
            amount: existingFlavour?.amount ?? 0,
            checked: existingFlavour?.checked,
          } as IComplement;
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSize, flavoursQty]);

  const handleSelectFlavour = (
    comple: IComplement,
    type: 'increase' | 'decrease' | 'checkbox' | 'radio',
  ) => {
    if (type === 'radio') {
      setFlavours(prev => {
        return (
          prev
            ?.map(comp => {
              if (comp.id === comple.id) {
                return { ...comp, amount: 1, checked: true };
              }
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { amount, ...rest } = comp;
              return { ...rest, checked: false };
            })
            ?.sort((a, b) => (a?.position ?? 0) - (b?.position ?? 0)) ?? []
        );
      });
    } else if (type === 'increase' || type === 'checkbox') {
      setFlavours(prev => {
        const filteredComplements = prev.filter(comp => comp.id !== comple.id);
        const amountComple = {
          ...comple,
          amount:
            type === 'increase'
              ? comple.amount
                ? comple.amount + 1
                : 1
              : comple.checked
              ? 0
              : 1,
          checked: type === 'increase' ? true : !comple.checked,
        };

        return [...filteredComplements, amountComple].sort(
          (a, b) => (a?.position ?? 0) - (b?.position ?? 0),
        );
      });
    } else if (type === 'decrease') {
      setFlavours(prev => {
        const filteredComplements = prev.filter(comp => comp.id !== comple.id);
        const amountComple = {
          ...comple,
          amount: comple.amount && comple.amount - 1,
          checked: (comple?.amount ?? 0) - 1 !== 0,
        };

        return [...filteredComplements, amountComple].sort(
          (a, b) => (a?.position ?? 0) - (b?.position ?? 0),
        );
      });
    }
  };

  const defaultPizzaFlavorsGroup: IComplementsGroup = useMemo(
    () => ({
      id: -8,
      modules: '',
      deleted: false,
      title: 'Sabores',
      displayName: 'Sabores',
      category: 'STANDARD',
      maxAmount: flavoursQty,
      minAmount: flavoursQty,
      isActive: true,
      position: 0,
      complements: flavours ?? [],
    }),
    [flavoursQty, flavours],
  );

  return (
    <ComplementsGroupsPizza
      key={0}
      index={0}
      handleSelect={handleSelectFlavour}
      complementGroup={defaultPizzaFlavorsGroup}
    />
  );
};

export default Flavours;
