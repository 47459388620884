import styled, { css } from 'styled-components';

interface IContainerProps {
  visible: boolean;
}

export const Container = styled.div<IContainerProps>`
  width: 100%;
  position: absolute;
  z-index: 20;
  bottom: 0;
  background: #f4f4f6;
  -webkit-transition: height 0.4s;
  transition: height 0.4s;
  overflow: auto;
  ${({ visible }) =>
    visible
      ? css`
          height: 100%;
        `
      : css`
          height: 0;
        `}
`;

export const Main = styled.div`
  display: flex;
  flex-direction: column;
`;
