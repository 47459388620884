import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from 'react';

import * as Yup from 'yup';
import { v4 as uuid } from 'uuid';
import Lottie from 'lottie-react-web';
import { FormHandles } from '@unform/core';
import Select, { ValueType } from 'react-select';
import { FiArrowLeft, FiCheck, FiPlus } from 'react-icons/fi';

import IComplement from '../../models/IComplement';
import IComplementsGroup from '../../models/IComplementsGroup';
import ISaveComplementsGroupDTO from '../../dtos/ISaveComplementsGroupDTO';

import { useToast } from '../../hooks/toast';
import { useComplements } from '../../hooks/complements';

import { formatToMoney } from '../../utils/format';
import { selectStyles } from '../../styles/select';
import { getValidationErrors } from '../../utils/errors';
import animation from '../../assets/animations/loading.json';

import Input from '../Input';
import Complement from '../Complement';

import {
  Title,
  Header,
  Button,
  Wrapper,
  Container,
  Complements,
  Information,
  SelectLabel,
  ButtonContainer,
  SelectContainer,
  LoadingContainer,
  ComplementsContainer,
  DispositionContainer,
} from './styles';
import Disposition, { EDisposition } from '../Disposition';

interface IEditComplementsGroupModal {
  visible: boolean;
  complementsGroup: IComplementsGroup | null;
  onClose: (bypass?: boolean) => void;
  onSave: (data: ISaveComplementsGroupDTO) => void;
}

interface IEditComplementsGroupFormData {
  title: string;
  minAmount: string;
  maxAmount: string;
  displayName: string;
}

export interface IGroupCategory {
  label: string;
  value: 'PIZZA' | 'STANDARD';
}

export interface IEditComplementsGroupRef {
  complementsLength: () => number;
}

const EditComplementsGroupModal: React.ForwardRefRenderFunction<
  IEditComplementsGroupRef,
  IEditComplementsGroupModal
> = ({ visible, complementsGroup, onSave, onClose }, ref) => {
  const { addToast } = useToast();

  const { complementsGroups, isComplementsLoading, saveComplementsGroup } =
    useComplements();

  const formRef = useRef<FormHandles | null>(null);

  const [saveComplements, setSaveComplements] = useState(false);
  const [complements, setComplements] = useState<IComplement[]>([]);

  const [category, setCategory] = useState<IGroupCategory>({
    label: 'Padrão',
    value: 'STANDARD',
  });

  const [dispositionTypes, setDispositionTypes] = useState<EDisposition[]>([
    'TABLE',
    'TICKET',
    'CHECKOUT',
    'DELIVERY',
    'SELFCHECKOUT',
  ]);

  const categories: IGroupCategory[] = useMemo(() => {
    return [
      {
        label: 'Padrão',
        value: 'STANDARD',
      },
      {
        label: 'Sabores de pizza',
        value: 'PIZZA',
      },
    ];
  }, []);

  useImperativeHandle(ref, () => ({
    complementsLength: () => complements.length,
  }));

  useEffect(() => {
    if (complementsGroups && visible && saveComplements) {
      setSaveComplements(false);
      onSave(complementsGroups[0]);
      onClose(true);
    }
  }, [complementsGroups, saveComplements, visible, onSave, onClose]);

  const getLabel = useCallback(() => {
    return complementsGroup?.category === 'PIZZA'
      ? 'Para pizza Customizada'
      : 'Padrão';
  }, [complementsGroup]);

  useEffect(() => {
    if (visible) {
      setCategory({
        label: getLabel(),
        value: complementsGroup?.category || 'STANDARD',
      });

      if (complementsGroup?.modules) {
        setDispositionTypes(
          complementsGroup.modules.split(', ') as EDisposition[],
        );
      }
    }
  }, [visible, complementsGroup, getLabel]);

  useEffect(() => {
    if (visible && complementsGroup && complementsGroup.complements) {
      const formattedComplements = complementsGroup.complements.map(
        complement => {
          return {
            ...complement,
            unitPriceAsString: formatToMoney(complement.unitPrice),
          };
        },
      );

      setComplements(formattedComplements);

      if (formRef.current) {
        formRef.current.setData(complementsGroup);
      }
    } else {
      setComplements([]);

      if (formRef.current) {
        formRef.current.reset();
      }
    }
  }, [visible, complementsGroup]);

  const handleOnBackPressed = useCallback(() => {
    onClose();
  }, [onClose]);

  const handleAddNewComplement = useCallback(() => {
    const element = document.getElementById('complementsContainer');

    if (element) {
      element.scrollTo(0, 0);
    }

    setComplements([
      {
        id: 0,
        title: '',
        position: 0,
        unitPrice: 0,
        error: false,
        maxAmount: 0,
        deleted: false,
        isActive: true,
        localId: uuid(),
        description: '',
        unitPriceAsString: 'R$ 0,00',
      },
      ...complements,
    ]);
  }, [complements]);

  const handleOnInputChange = useCallback(
    (value: string | number, name: string, id: string | number) => {
      const index = complements.findIndex(
        complement => complement.id === id || complement.localId === id,
      );

      const newComplements = [...complements];

      if (index > -1) {
        switch (name) {
          case 'description':
            newComplements[index].description = value.toString();
            break;
          case 'title':
            newComplements[index].title = value.toString();
            break;
          case 'maxAmount':
            newComplements[index].maxAmount = Number(value);
            break;
          default:
            newComplements[index].unitPrice = Number(value);
            newComplements[index].unitPriceAsString = value.toString();
        }
        setComplements(newComplements);
      }
    },
    [complements],
  );

  const handleOnRemoveComplementClick = useCallback(id => {
    if (typeof id === 'number' && id > 0) {
      setComplements(prevState => {
        return prevState.map(c => {
          if (c.id === id) {
            return {
              ...c,
              deleted: true,
            };
          }

          return c;
        });
      });
    } else {
      setComplements(prevState => {
        return prevState.filter(c => c.localId !== id);
      });
    }
  }, []);

  const handleOnSubmit = useCallback(
    async (data: IEditComplementsGroupFormData) => {
      const newComplements = [...complements];
      const invalidComplementIndex = complements.findIndex(c => !c.title);

      if (invalidComplementIndex > -1) {
        newComplements[invalidComplementIndex].error = true;
        setComplements(newComplements);

        if (formRef && formRef.current) {
          const inputName = `complement_name_${invalidComplementIndex}`;

          const element = document.querySelector(`label[for="${inputName}"]`);
          element?.scrollIntoView({ behavior: 'smooth' });

          formRef.current.setFieldError(inputName, 'O nome é obrigatório');
        }

        return;
      }

      if (complements.length === 0) {
        addToast({
          type: 'error',
          description: 'Adicione, pelo menos, um complemento.',
        });

        return;
      }

      formRef.current?.setErrors({});

      try {
        const schema = Yup.object().shape({
          title: Yup.string()
            .min(3, 'Mínimo de 3 caracteres.')
            .required('O nome é obrigatório.'),
        });

        await schema.validate(data, {
          abortEarly: false,
        });

        await saveComplementsGroup(
          {
            complements,
            position: 0,
            isActive: true,
            deleted: false,
            localId: uuid(),
            title: data.title,
            id: complementsGroup?.id,
            displayName: data.displayName,
            maxAmount: Number(data.maxAmount || 0),
            minAmount: Number(data.minAmount || 0),
            category: category.value,
            modules: dispositionTypes.join(','),
          },
          complementsGroup?.id || 0,
        );

        setSaveComplements(true);
      } catch (err) {
        if (err instanceof Yup.ValidationError) {
          formRef.current?.setErrors(getValidationErrors(err));
        }
      }
    },
    [
      complements,
      addToast,
      saveComplementsGroup,
      complementsGroup,
      category.value,
      dispositionTypes,
    ],
  );

  const filteredComplements = useMemo(() => {
    return complements.filter(c => !c.deleted);
  }, [complements]);

  const handleOnCategoryChanged = useCallback(
    (param: ValueType<IGroupCategory, false>) => {
      setCategory(param as IGroupCategory);
    },
    [],
  );

  const handleOnDispositionClicked = useCallback(
    (disposition: EDisposition) => {
      setDispositionTypes(prevState => {
        if (prevState.includes(disposition)) {
          if (prevState.length === 1) {
            return prevState;
          }

          return prevState.filter(d => d !== disposition);
        }

        return [...prevState, disposition];
      });
    },
    [],
  );

  return (
    <Container visible={visible}>
      <Header>
        <FiArrowLeft size={24} onClick={handleOnBackPressed} />
        <span>
          {complementsGroup
            ? 'Alteração de Complemento'
            : 'Cadastro de Complemento'}
        </span>
      </Header>
      <Information
        ref={formRef}
        onSubmit={handleOnSubmit}
        className="has-custom-scroll-bar-2"
        initialData={complementsGroup || {}}
      >
        <Input name="title" title="Nome" />
        <div className="has-margin-top" />
        <Input name="displayName" title="Nome de exibição" />
        <SelectContainer>
          <SelectLabel>Categoria</SelectLabel>
          <Select
            value={category}
            options={categories}
            styles={selectStyles}
            onChange={handleOnCategoryChanged}
            placeholder="Selecione a categoria"
          />
        </SelectContainer>
        <DispositionContainer>
          <Disposition
            dispositionTypes={dispositionTypes}
            onDispositionClicked={handleOnDispositionClicked}
          />
        </DispositionContainer>
        <Wrapper>
          <Input
            min={0}
            type="number"
            name="minAmount"
            title="Quantidade mínima"
          />
          <Input
            min={0}
            type="number"
            name="maxAmount"
            title="Quantidade máxima"
          />
        </Wrapper>
        <Complements>
          <Title>
            <span>Complementos</span>
            <button type="button" onClick={handleAddNewComplement}>
              <FiPlus />
              <span>Novo</span>
            </button>
          </Title>
          <ComplementsContainer
            className="has-custom-scroll-bar-2"
            id="complementsContainer"
          >
            {filteredComplements.map((complement, index) => (
              <Complement
                index={index}
                key={
                  complement.id && complement.id > 0
                    ? complement.id
                    : complement.localId
                }
                complement={complement}
                onChange={handleOnInputChange}
                onDelete={handleOnRemoveComplementClick}
              />
            ))}
          </ComplementsContainer>
        </Complements>
        <ButtonContainer>
          <Button>
            {isComplementsLoading ? (
              <LoadingContainer>
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  height={80}
                  width={80}
                />
              </LoadingContainer>
            ) : (
              <>
                <span>Salvar</span>
                <FiCheck size={24} />
              </>
            )}
          </Button>
        </ButtonContainer>
      </Information>
    </Container>
  );
};

export default forwardRef(EditComplementsGroupModal);
