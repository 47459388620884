import styled from 'styled-components';

export const ConfigContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: 1rem;
`;
export const GroupSubTitles = styled.span`
  width: fit-content;
  border-bottom: 4px solid #db1622;
  margin-top: 32px;
  font-size: 18px;
`;
