/* eslint-disable @typescript-eslint/no-empty-function */
import React, {
  useRef,
  useMemo,
  useState,
  useEffect,
  useCallback,
} from 'react';

import { FiPlus } from 'react-icons/fi';

import { AxiosError } from 'axios';
import { useToast } from '../../hooks/toast';
import { useComplements } from '../../hooks/complements';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import Search from '../../components/Search';
import LoadingAnimation from '../../components/LoadingAnimation';
import ComplementsGroupItem from '../../components/ComplementsGroupItem';
import ComplementsGroupProducts from '../../components/ComplementsGroupProducts';

import IComplementsGroup from '../../models/IComplementsGroup';

import EditComplementsGroupModal2, {
  IEditComplementsGroup2Ref,
} from '../../components/EditComplementsGroupModal2';

import {
  Main,
  Header,
  Content,
  PageInfo,
  PageName,
  Container,
  AddGroupButton,
  FlexPlaceholder,
  ComplementsGroupsContainer,
} from './styles';

import api from '../../services/api';
import IProduct from '../../models/IProduct';
import EmptyMessage from '../../components/EmptyMessage';
import { PageNames } from '../../enums/pages';

const ComplementsPage: React.FC = () => {
  const { addToast } = useToast();
  const { showConfirmDialog } = useConfirmDialog();

  const editComplementsGroupRef = useRef<IEditComplementsGroup2Ref>(null);

  const {
    complementsGroups,
    isComplementsLoading,
    saveComplementsGroup,
    loadComplementsGroups,
    deleteComplementsGroup,
  } = useComplements();

  const [isEditComplementsGroupModalOpen, setIsEditComplementsGroupModalOpen] =
    useState(false);

  const [loading, setLoading] = useState(false);
  const [loadingProducts, setLoadingProducts] = useState(false);
  const [productsOpen, setProductsOpen] = useState(false);
  const [products, setProducts] = useState<IProduct[]>([]);
  const [searchCriteria, setSearchCriteria] = useState('');

  const [selectedGroup, setSelectedGroup] = useState<IComplementsGroup | null>(
    null,
  );
  const [changed, setChanged] = useState(false);

  useEffect(() => {
    loadComplementsGroups();
  }, [loadComplementsGroups]);

  const handleHideComplementsGroupModal = useCallback(
    (force?: boolean) => {
      if (force) {
        setSelectedGroup(null);
        setIsEditComplementsGroupModalOpen(false);
        setChanged(false);
      } else {
        const hasChanges =
          editComplementsGroupRef.current?.complementsLength() !== 0 || changed;

        if (isEditComplementsGroupModalOpen && hasChanges) {
          showConfirmDialog({
            title: 'Deseja mesmo sair?',
            onCancel: () => {},
            onConfirm: () => {
              setChanged(false);
              setSelectedGroup(null);
              setIsEditComplementsGroupModalOpen(false);
            },
          });
        } else {
          setSelectedGroup(null);
          setIsEditComplementsGroupModalOpen(false);
        }
      }
    },
    [isEditComplementsGroupModalOpen, showConfirmDialog, changed],
  );

  const handleOnGroupSelected = useCallback((group: IComplementsGroup) => {
    setSelectedGroup(group);
    setIsEditComplementsGroupModalOpen(true);
  }, []);

  const handleOnGroupDelete = useCallback(
    async (group: IComplementsGroup) => {
      try {
        await deleteComplementsGroup(group.id);
        addToast({
          type: 'success',
          description: 'Grupo excluído com sucesso.',
        });
      } catch (err) {
        addToast({
          type: 'error',
          description: 'Erro ao excluir grupo.',
        });
      }
    },
    [deleteComplementsGroup, addToast],
  );

  const handleAddNewGroup = useCallback(() => {
    setIsEditComplementsGroupModalOpen(true);
  }, []);

  const handleOnGroupSaved = useCallback(
    async (group: IComplementsGroup) => {
      setLoading(true);

      try {
        await saveComplementsGroup(
          {
            ...group,
            maxAmount:
              group.category === 'PIZZA'
                ? Number(group.minAmount || 0)
                : Number(group.maxAmount || 0),
          },
          group.id || 0,
        );

        addToast({
          type: 'success',
          description: `Você ${group.id ? 'alterou' : 'cadastrou'} o grupo ${
            group.title
          }.`,
        });

        handleHideComplementsGroupModal(true);
      } catch (err) {
        let errorMessage = 'Verifique sua conexão e tente novamente.';

        if ((err as AxiosError)?.isAxiosError) {
          errorMessage =
            (Object.values(
              (err as any)?.response?.data?.errors,
            )[0] as string) || 'Erro inesperado.';
        }
        addToast({
          type: 'error',
          description: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    },
    [saveComplementsGroup, addToast, handleHideComplementsGroupModal],
  );

  const handleOnSearchCriteriaChanged = useCallback((text: string) => {
    setSearchCriteria(text);
  }, []);

  const searchedGroups = useMemo(() => {
    return complementsGroups.filter(group => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return group.title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [complementsGroups, searchCriteria]);

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        if (editComplementsGroupRef.current?.getIsComplementsOrderModalOpen()) {
          editComplementsGroupRef.current?.closeComplementsOrderModal();
        } else {
          handleHideComplementsGroupModal();
        }
      } else {
        setChanged(true);
      }
    },
    [handleHideComplementsGroupModal],
  );

  const handleProductsClose = useCallback(() => {
    setProductsOpen(false);
  }, []);

  const handleItemsClick = useCallback(async (group: IComplementsGroup) => {
    setLoadingProducts(true);
    setProductsOpen(true);
    const { data } = await api.get(`/products?ComplementGroupId=${group.id}`);
    setProducts(data || []);
    setLoadingProducts(false);
  }, []);

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  }, [handleOnEscPressed]);

  return (
    <Container>
      <ComplementsGroupProducts
        products={products}
        open={productsOpen}
        loading={loadingProducts}
        onClose={handleProductsClose}
      />
      <Content overlay={isEditComplementsGroupModalOpen}>
        <Header>
          <PageInfo>
            <PageName>{PageNames.COMPLEMENTS}</PageName>
          </PageInfo>
          <Search
            value={searchCriteria}
            onChange={handleOnSearchCriteriaChanged}
          />
          <AddGroupButton onClick={handleAddNewGroup}>
            <FiPlus />
            <span />
          </AddGroupButton>
        </Header>
        {isComplementsLoading ? (
          <LoadingAnimation />
        ) : (
          <>
            {searchedGroups.length > 0 ? (
              <Main>
                <ComplementsGroupsContainer className="has-custom-scroll-bar-2">
                  {searchedGroups.map(group => (
                    <ComplementsGroupItem
                      key={group.id}
                      complementsGroup={group}
                      onClick={handleOnGroupSelected}
                      onDelete={handleOnGroupDelete}
                      onItemsClick={handleItemsClick}
                    />
                  ))}
                  <FlexPlaceholder />
                  <FlexPlaceholder />
                  <FlexPlaceholder />
                </ComplementsGroupsContainer>
              </Main>
            ) : (
              <EmptyMessage
                searchCriteria={searchCriteria}
                plural="complementos"
                text="complemento"
                onClick={handleAddNewGroup}
              />
            )}
          </>
        )}
      </Content>
      <EditComplementsGroupModal2
        loading={loading}
        onSave={handleOnGroupSaved}
        ref={editComplementsGroupRef}
        complementsGroup={selectedGroup}
        visible={isEditComplementsGroupModalOpen}
        onClose={handleHideComplementsGroupModal}
      />
    </Container>
  );
};

export default ComplementsPage;
