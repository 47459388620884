import React, { useState, useContext, useCallback, createContext } from 'react';
import api from '../services/api';
import { IWaiter } from '../models/IWaiter';

interface WaiterContextData {
  isLoading: boolean;
  getWaiters(): Promise<[] | IWaiter[]>;
  saveWaiter(waiter: IWaiter): Promise<void>;
  ActiveDeactiveWaiter: (waiter: IWaiter) => Promise<void>;
  deleteWaiter: (waiterId: number) => Promise<void>;
}

const WaiterContext = createContext<WaiterContextData>({} as WaiterContextData);

export const WaiterProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);

  const getWaiters = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await api.get<IWaiter[]>('/restricted/waiters');

      setIsLoading(false);
      return response.data;
    } catch (error) {
      setIsLoading(false);
      console.error(error);
      return [];
    }
  }, []);

  const saveWaiter = useCallback(async (waiter: IWaiter) => {
    try {
      setIsLoading(true);
      if (waiter?.id) {
        await api.put<IWaiter>(
          `restricted/waiters/${Number(waiter?.id)}`,
          waiter,
        );
      } else {
        await api.post<IWaiter>(`restricted/waiters`, waiter);
      }
      setIsLoading(false);
    } catch (err) {
      console.error(err);
      setIsLoading(false);
    }
  }, []);

  const ActiveDeactiveWaiter = useCallback(async (waiter: IWaiter) => {
    try {
      if (waiter.active) {
        await api.patch(`restricted/waiters/${waiter.id}/deactivate`);
      } else {
        api.patch(`restricted/waiters/${waiter.id}/activate`);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const deleteWaiter = useCallback(async (waiterId: number) => {
    try {
      await api.delete(`/restricted/waiters/${waiterId}`);
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <WaiterContext.Provider
      value={{
        isLoading,
        getWaiters,
        saveWaiter,
        ActiveDeactiveWaiter,
        deleteWaiter,
      }}
    >
      {children}
    </WaiterContext.Provider>
  );
};

export function useWaiter(): WaiterContextData {
  const context = useContext(WaiterContext);

  if (!context) {
    throw new Error('useWaiter must be used within WaiterProvider');
  }

  return context;
}
