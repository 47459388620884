import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const Header = styled.header`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding: 32px;

  max-height: 100px;

  span {
    font-size: 24px;
  }

  @media (max-width: 600px) {
    max-height: 150px;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 16px;
  }
`;

export const Info = styled.div`
  display: flex;
  align-items: center;

  svg {
    cursor: pointer;
    margin-right: 16px;

    @media (max-width: 460px) {
      margin-right: 0;
    }
  }

  > div {
    display: flex;
    align-items: center;
    width: 100%;

    @media (max-width: 460px) {
      margin-top: 32px;
    }
  }

  > span {
    @media (max-width: 600px) {
      font-size: 20px;
    }
  }
`;

export const HeaderButtonsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 48px;
  .on-hover {
    transition: border-bottom 0.2s;

    &:hover {
      border-bottom: 1px solid #db1622;
    }
  }
`;

export const SaveOperatorButton = styled.button`
  display: flex;
  align-items: center;
  font-size: 32px;
  background: none;
  border: 0;
  padding-bottom: 8px;
  border-bottom: 2px solid #db1622;
  transition: border-bottom 0.2s;

  &:hover {
    border-bottom: 8px solid #db1622;
  }

  svg {
    margin-right: 8px;
  }

  @media (max-width: 650px) {
    display: none;
  }
`;
export const SaveOperatorFloatingButton = styled.button`
  display: none;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: #db1622;
  border: 0;
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 16px;
  bottom: 32px;

  @media (max-width: 650px) {
    display: flex;
  }

  @supports (-webkit-touch-callout: none) {
    bottom: 96px;
  }
`;
