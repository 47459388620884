import React, { useCallback, useState, useEffect } from 'react';

import { useTheme } from 'styled-components';
import { useHistory } from 'react-router-dom';

import {
  FiX,
  FiEdit,
  FiGrid,
  FiList,
  FiPlus,
  FiCheck,
  FiSquare,
} from 'react-icons/fi';

import IProduct from '../../models/IProduct';
import ICategory from '../../models/ICategory';

import { AuthRole, useAuth } from '../../hooks/auth';
import { useToast } from '../../hooks/toast';
import { useProducts } from '../../hooks/products';

import Product from '../Product';
import ConfirmActionDialog from '../ConfirmActionDialog';

import {
  Icon,
  Main,
  Header,
  Products,
  Container,
  IsCategory,
  CategoryInfo,
  CategoryName,
  EditCategory,
  DisplayButton,
  CategoryTypes,
  IsSubcategory,
  ProductsHeader,
  ButtonContainer,
  DisplayContainer,
  ProductsContainer,
  ProductPlaceholder,
} from './styles';
import useMediaQuery from '../../hooks/media_query';
import { IActionsProps } from '../../pages/ProductsPage';

interface ICategorySummaryModal {
  visible: boolean;
  products: IProduct[];
  selectedCategory: ICategory | null;
  onClose: () => void;
  onEdit: (category: ICategory) => void;
}

const CategorySummaryModal: React.FC<ICategorySummaryModal> = ({
  visible,
  products,
  selectedCategory,
  onEdit,
  onClose,
}) => {
  const theme = useTheme();
  const history = useHistory();

  const { addToast } = useToast();
  const { inactivateProduct, deleteProduct, selectProduct, favoriteProduct } =
    useProducts();

  const { productPreferableDisplay, changeProductPreferableDisplay } =
    useAuth();

  const isMobile = useMediaQuery('(max-width: 700px)');

  const [confirmationMessage, setConfirmationMessage] = useState('');
  const [procedure, setProcedure] = useState<IActionsProps>('');

  const [isConfirmationDialogOpen, setIsConfirmationDialogOpen] =
    useState(false);

  const [itemToBeAlteredId, setItemToBeAlteredId] = useState(0);

  const handleOnEscPressed = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        onClose();
      }
    },
    [onClose],
  );

  useEffect(() => {
    document.addEventListener('keydown', handleOnEscPressed, false);

    return () =>
      document.removeEventListener('keydown', handleOnEscPressed, false);
  });

  const handleOnSelectProduct = useCallback(
    (product: IProduct) => {
      selectProduct(product);
      history.push('/edit-product');
    },
    [history, selectProduct],
  );

  const handleAddNewProduct = useCallback(() => {
    history.push('/edit-product', { categoriesParams: [selectedCategory?.id] });
  }, [history, selectedCategory]);

  const handleOnShowConfirmationDialog = useCallback(
    (id: number, proc: IActionsProps) => {
      setProcedure(proc);
      setItemToBeAlteredId(id);

      switch (proc) {
        case 'DELETE':
          setConfirmationMessage('Deseja excluir o produto?');
          break;
        case 'INACTIVATE': {
          const product = products.find(p => p.id === id);
          setConfirmationMessage(
            `Deseja ${
              product?.active ? 'pausar' : 'retomar'
            } as vendas do produto?`,
          );
          break;
        }
        case 'FAVORITE': {
          const product = products.find(p => p.id === id);
          setConfirmationMessage(
            `Deseja ${
              product?.isFavorite ? 'desfavoritar' : 'favoritar'
            }  o produto ${product?.title}?`,
          );
          break;
        }
        default:
          setConfirmationMessage('Sem ação!');
          break;
      }

      setProcedure(proc);
      setIsConfirmationDialogOpen(true);
    },
    [products],
  );

  const handleOnActionDialogClosed = useCallback(() => {
    setProcedure('');
    setConfirmationMessage('');
    setIsConfirmationDialogOpen(false);
  }, []);
  const handleOnToggleFavorite = useCallback(async () => {
    try {
      await favoriteProduct(itemToBeAlteredId);
      addToast({ type: 'success', description: 'Produto alterado!' });
    } catch (err) {
      const errors = (err as any)?.response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  }, [addToast, favoriteProduct, itemToBeAlteredId]);

  const handleOnProductDeleted = useCallback(async () => {
    try {
      await deleteProduct(itemToBeAlteredId);

      addToast({
        type: 'success',
        description: 'Produto removido!',
      });
    } catch (err) {
      const errors = (err as any).response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  }, [deleteProduct, addToast, itemToBeAlteredId]);

  const handleOnProductInactivated = useCallback(async () => {
    try {
      await inactivateProduct(itemToBeAlteredId);

      addToast({
        type: 'success',
        description: 'Produto alterado!',
      });
    } catch (err) {
      const errors = (err as any).response?.data?.errors?.messages;

      addToast({
        type: 'error',
        description:
          (Array.isArray(errors) && errors[0]) || 'Ocorreu um erro inesperado.',
      });
    }
  }, [inactivateProduct, addToast, itemToBeAlteredId]);

  const handleOnActionDialogConfirmed = useCallback(() => {
    handleOnActionDialogClosed();

    switch (procedure) {
      case 'DELETE':
        handleOnProductDeleted();
        break;
      case 'INACTIVATE':
        handleOnProductInactivated();
        break;
      default: {
        handleOnToggleFavorite();
      }
    }
  }, [
    handleOnActionDialogClosed,
    procedure,
    handleOnProductDeleted,
    handleOnProductInactivated,
    handleOnToggleFavorite,
  ]);

  const handleOnDisplayChanged = useCallback(
    (display: 'GRID' | 'LIST') => {
      changeProductPreferableDisplay(display);
    },
    [changeProductPreferableDisplay],
  );

  const handleOnEditClicked = useCallback(() => {
    onClose();

    if (selectedCategory) onEdit(selectedCategory);
  }, [selectedCategory, onEdit, onClose]);

  return (
    <Container visible={visible}>
      <Header>
        <CategoryInfo>
          <FiX onClick={onClose} size={32} />
          <CategoryName>{selectedCategory?.name}</CategoryName>
        </CategoryInfo>
        <AuthRole whiteList={['Manager']}>
          <EditCategory onClick={handleOnEditClicked}>
            <FiEdit size={22} />
            <span>Editar</span>
          </EditCategory>
        </AuthRole>
      </Header>
      <AuthRole blackList={['Employee']}>
        <CategoryTypes>
          <IsCategory>
            <Icon>
              <FiSquare size={24} />
              {selectedCategory?.isCategory && (
                <FiCheck size={32} color={theme.palette.success} />
              )}
            </Icon>
            <span>Categoria</span>
          </IsCategory>
          <IsSubcategory>
            <Icon>
              <FiSquare size={24} />
              {selectedCategory?.isSubcategory && (
                <FiCheck size={32} color={theme.palette.success} />
              )}
            </Icon>
            <span>Subcategoria</span>
          </IsSubcategory>
        </CategoryTypes>
      </AuthRole>
      <Main>
        {selectedCategory && (
          <Products>
            <AuthRole whiteList={['Manager']}>
              <ProductsHeader>
                {!(
                  selectedCategory.isSubcategory && !selectedCategory.isCategory
                ) && (
                  <button type="button" onClick={handleAddNewProduct}>
                    <FiPlus size={24} />
                    <span />
                  </button>
                )}
              </ProductsHeader>
            </AuthRole>
            <DisplayContainer>
              <span>Produtos</span>
              {!isMobile && (
                <ButtonContainer>
                  <DisplayButton>
                    <FiList
                      size={24}
                      onClick={() => handleOnDisplayChanged('LIST')}
                      color={
                        productPreferableDisplay === 'LIST'
                          ? theme.palette.black
                          : '#0005'
                      }
                    />
                  </DisplayButton>
                  <DisplayButton>
                    <FiGrid
                      size={24}
                      onClick={() => handleOnDisplayChanged('GRID')}
                      color={
                        productPreferableDisplay === 'GRID'
                          ? theme.palette.black
                          : '#0005'
                      }
                    />
                  </DisplayButton>
                </ButtonContainer>
              )}
            </DisplayContainer>
            <ProductsContainer className="has-custom-scroll-bar-2">
              {products.map(product => (
                <Product
                  key={product.id}
                  product={product}
                  onClick={() => {
                    handleOnSelectProduct(product);
                  }}
                  onFavorite={id =>
                    handleOnShowConfirmationDialog(id, 'FAVORITE')
                  }
                  onDelete={id => handleOnShowConfirmationDialog(id, 'DELETE')}
                  onInactivate={id =>
                    handleOnShowConfirmationDialog(id, 'INACTIVATE')
                  }
                  display={isMobile ? 'LIST' : productPreferableDisplay}
                  actions
                />
              ))}

              <ProductPlaceholder />
              <ProductPlaceholder />
              <ProductPlaceholder />
            </ProductsContainer>
          </Products>
        )}
      </Main>
      <ConfirmActionDialog
        title="Atenção"
        message={confirmationMessage}
        isOpen={isConfirmationDialogOpen}
        onClose={handleOnActionDialogClosed}
        onConfirm={handleOnActionDialogConfirmed}
      />
    </Container>
  );
};

export default CategorySummaryModal;
