import React, { useEffect, useRef, useState } from 'react';
import { FiX } from 'react-icons/fi';
import { v4 as uuid } from 'uuid';
import { Badge, Close, Header, Container, SelectCategory } from './styles';

import ICategory from '../../../../models/ICategory';
import { IProductSell } from '../../../../models/IFastSell';

import PizzaSizes from '../PizzaSizes';
import Drawer from '../../../../components/Drawer';
import IconButton from '../../../../components/IconButton';
import IComplementsGroup from '../../../../models/IComplementsGroup';

interface IMountYourPizza {
  editPizza?: IProductSell;
  open: boolean;
  categories: ICategory[];
  allProducts: IProductSell[];
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setProductsToGrid: React.Dispatch<React.SetStateAction<IProductSell[]>>;
}
const MountYourPizza: React.FC<IMountYourPizza> = ({
  open,
  editPizza,
  categories,
  allProducts,
  setOpen,
  setProductsToGrid,
}) => {
  const oneCategory = categories.length === 1;
  const pizzaRef = useRef<HTMLDivElement | null>(null);
  const [category, setCategory] = useState<ICategory>(
    oneCategory ? categories[0] : ({} as ICategory),
  );

  const defaultMountPizza: IProductSell = {
    id: uuid(),
    qty: 1,
    total: 0,
    title: oneCategory ? category?.name : '',
    imageUrl: '',
    productId: 0,
    unitPrice: 0,
    complementsGroups: [] as IComplementsGroup[],
  };
  const [pizzaToCart, setPizzaToCart] =
    useState<IProductSell>(defaultMountPizza);

  useEffect(() => {
    setCategory(oneCategory ? categories[0] : ({} as ICategory));
  }, [categories, oneCategory, open]);

  useEffect(() => {
    if (editPizza) {
      setPizzaToCart(prev => ({
        ...prev,
        id: editPizza.id,
        title: editPizza.title,
        total: editPizza.total,
        pizzaSizeId: editPizza.pizzaSizeId,
        pizzaCategoryId: editPizza.pizzaCategoryId,
        complementsGroups: editPizza.complementsGroups.filter(
          compG => compG.id === undefined,
        ),
      }));
    }
  }, [editPizza]);

  const handleOnClose = () => {
    setPizzaToCart(defaultMountPizza);
    setCategory({} as ICategory);
    setOpen(false);
  };
  return (
    <Drawer
      zindex={9}
      setOpen={setOpen}
      open={open}
      handleClose={handleOnClose}
    >
      <Header>
        <Close>
          <IconButton onClick={handleOnClose} icon={<FiX size={22} />} />
        </Close>
      </Header>
      <Container ref={pizzaRef} className="has-custom-scroll-bar-2">
        {!category?.id ? (
          <SelectCategory>
            <h3>Selecione a categoria</h3>
            {categories.map(cat => (
              <Badge key={cat.id} onClick={() => setCategory(cat)}>
                <span>{cat.name}</span>
                <p>Tamanhos:</p>
                <ul>
                  {cat.pizzaSizes.map(pz => (
                    <li key={pz.id}>
                      {`${pz.name} - ${pz.flavours}  ${
                        pz.flavours > 1 ? 'sabores' : 'sabor'
                      }`}
                    </li>
                  ))}
                </ul>
              </Badge>
            ))}
          </SelectCategory>
        ) : (
          <PizzaSizes
            category={category}
            editPizza={editPizza}
            allProducts={allProducts}
            pizzaToCart={pizzaToCart}
            handleOnClose={handleOnClose}
            setPizzaToCart={setPizzaToCart}
            setProductsToGrid={setProductsToGrid}
          />
        )}
      </Container>
    </Drawer>
  );
};

export default MountYourPizza;
