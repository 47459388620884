import React, { useCallback, useEffect } from 'react';

import { Redirect, Switch } from 'react-router-dom';

import { PagesAccessProfiles, useAuth } from '../hooks/auth';
import { useCompany } from '../hooks/company';

import PrivateRoute from '../components/PrivateRoute';
import Sidebar from '../components/Sidebar';

import TeamPage from '../pages/TeamPage';
import StockPage from '../pages/StockPage';
import ThemePage from '../pages/ThemePage';
import SocialPage from '../pages/SocialPage';
import TablesPage from '../pages/TablesPage';
import OrdersPage from '../pages/OrdersPage';
import ClientPage from '../pages/ClientPage';
import DevicesPage from '../pages/DevicesPage';
import HistoryPage from '../pages/HistoryPage';
import WaitersPage from '../pages/WaitersPage';
import DiscountPage from '../pages/DiscountPage';
import MessagesPage from '../pages/MessagesPage';
import FastSellPage from '../pages/FastSellPage';
import ProductsPage from '../pages/ProductsPage';
import SettingsPage from '../pages/SettingsPage';
import DashboardPage from '../pages/DashboardPage';
import OperatorsPage from '../pages/OperatorsPage';
import AddressesPage from '../pages/AddressesPage';
import UpdateSkuPage from '../pages/UpdateSkuPage';
import HighlightsPage from '../pages/HighlightsPage';
import CategoriesPage from '../pages/CategoriesPage';
import ComplementsPage from '../pages/ComplementsPage';
import EditProductPage from '../pages/EditProductPage';
import RecentOrdersPage from '../pages/RecentOrdersPage';
import CashierReportPage from '../pages/CashierReportPage';
import CashierManagement from '../pages/CashierManagement';
import CardsAndTablesPage from '../pages/CardsAndTablesPage';
import { findRedirectRoute, verifyUserRole } from '../utils/auth';
import ScheduledProductsPage from '../pages/ScheduledProductsPage';

const AdminRoutes: React.FC = () => {
  const { user } = useAuth();

  const { loadCompany } = useCompany();

  useEffect(() => {
    if (user && user?.companies) {
      loadCompany(user?.companies[0]?.subdomain);
    }
  }, [user, loadCompany]);

  const getRouteProps = useCallback(
    (key: keyof typeof PagesAccessProfiles) => {
      if (!user) {
        return {
          authed: false,
          redirect: '',
        };
      }

      const { whiteList, blackList } = PagesAccessProfiles[key];

      const redirect = findRedirectRoute(user?.roles || []);

      return {
        authed: verifyUserRole(user, whiteList, blackList),
        redirect,
      };
    },
    [user],
  );

  return (
    <Sidebar>
      <Switch>
        <PrivateRoute
          path="/"
          component={DashboardPage}
          exact
          {...getRouteProps('DASHBOARD')}
        />
        <PrivateRoute
          path="/categories"
          component={CategoriesPage}
          {...getRouteProps('CATEGORIES')}
        />
        <PrivateRoute
          path="/updatesku"
          component={UpdateSkuPage}
          {...getRouteProps('UPDATE_SKU')}
        />
        <PrivateRoute
          path="/products"
          component={ProductsPage}
          {...getRouteProps('PRODUCTS')}
        />
        <PrivateRoute
          path="/edit-product"
          component={EditProductPage}
          {...getRouteProps('EDIT_PRODUCT')}
        />
        <PrivateRoute
          path="/stock"
          component={StockPage}
          {...getRouteProps('STOCK')}
        />
        <PrivateRoute
          path="/discounts"
          component={DiscountPage}
          {...getRouteProps('DISCOUNTS')}
        />
        <PrivateRoute
          path="/highlights"
          component={HighlightsPage}
          {...getRouteProps('HIGHLIGHTS')}
        />
        <PrivateRoute
          path="/complements"
          component={ComplementsPage}
          {...getRouteProps('COMPLEMENTS')}
        />
        <PrivateRoute
          path="/orders"
          component={OrdersPage}
          {...getRouteProps('ORDERS')}
        />
        <PrivateRoute
          path="/recent-orders"
          component={RecentOrdersPage}
          {...getRouteProps('RECENT_ORDERS')}
        />
        <PrivateRoute
          path="/sell"
          component={CardsAndTablesPage}
          {...getRouteProps('CARD')}
        />
        <PrivateRoute
          path="/tables"
          component={TablesPage}
          {...getRouteProps('TABLES')}
        />
        <PrivateRoute
          path="/cashier-report"
          component={CashierReportPage}
          {...getRouteProps('CASHIER_REPORT')}
        />

        <PrivateRoute
          path="/addresses"
          component={AddressesPage}
          {...getRouteProps('ADDRESSES')}
        />
        <PrivateRoute
          path="/scheduled-products"
          component={ScheduledProductsPage}
          {...getRouteProps('SCHEDULED_PRODUCTS')}
        />
        <PrivateRoute
          path="/cashier"
          component={CashierManagement}
          {...getRouteProps('CASHIER')}
        />

        <PrivateRoute
          path="/addresses"
          component={AddressesPage}
          {...getRouteProps('ADDRESSES')}
        />
        <PrivateRoute
          path="/scheduled-products"
          component={ScheduledProductsPage}
          {...getRouteProps('SCHEDULED_PRODUCTS')}
        />
        <PrivateRoute
          path="/messages"
          component={MessagesPage}
          {...getRouteProps('MESSAGES')}
        />
        <PrivateRoute
          path="/team"
          component={TeamPage}
          {...getRouteProps('TEAM')}
        />
        <PrivateRoute
          path="/operators"
          component={OperatorsPage}
          {...getRouteProps('OPERATORS')}
        />
        <PrivateRoute
          path="/clients"
          component={ClientPage}
          {...getRouteProps('CLIENTS')}
        />
        <PrivateRoute
          path="/operators"
          component={OperatorsPage}
          {...getRouteProps('OPERATORS')}
        />
        <PrivateRoute
          path="/clients"
          component={ClientPage}
          {...getRouteProps('CLIENTS')}
        />
        <PrivateRoute
          path="/devices"
          component={DevicesPage}
          {...getRouteProps('DEVICES')}
        />
        <PrivateRoute
          path="/social"
          component={SocialPage}
          {...getRouteProps('SOCIAL')}
        />
        <PrivateRoute
          path="/history"
          component={HistoryPage}
          {...getRouteProps('HISTORY')}
        />
        <PrivateRoute
          path="/theme"
          component={ThemePage}
          {...getRouteProps('THEME')}
        />
        <PrivateRoute
          path="/fast"
          component={FastSellPage}
          {...getRouteProps('FAST')}
        />
        <PrivateRoute
          path="/settings"
          component={SettingsPage}
          {...getRouteProps('SETTINGS')}
        />
        <PrivateRoute
          path="/waiters"
          component={WaitersPage}
          {...getRouteProps('WAITER')}
        />
        <Redirect to="/recent-orders" />
      </Switch>
    </Sidebar>
  );
};

export default AdminRoutes;
