import { MaskedCurrency } from 'react-easy-mask';
import React, { useEffect, useRef, useState } from 'react';

import { FiPlus } from 'react-icons/fi';
import { ModalWrapper } from './styles';

import IComplement from '../../../../models/IComplement';
import { IProductSell } from '../../../../models/IFastSell';
import IComplementsGroup from '../../../../models/IComplementsGroup';

import Modal from '../../../../components/Modal';
import Button from '../../../../components/Button';
import BasicInput from '../../../../components/BasicInput';

interface IAdditionalsProps {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  setEditProduct: React.Dispatch<React.SetStateAction<IProductSell>>;
}
const Additionals: React.FC<IAdditionalsProps> = ({
  open,
  setOpen,
  setEditProduct,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState(0);
  const [error, setError] = useState(false);
  const [additional, setAdditional] = useState('');

  useEffect(() => {
    if (open && inputRef.current) {
      inputRef.current.focus();
      setValue(0);
    }
  }, [open]);
  const handleConfirm = () => {
    if (additional.length <= 0) {
      setError(true);
      return;
    }
    setError(false);
    setEditProduct((prev: IProductSell) => {
      const product = { ...prev };
      const existingGroupIndex = product.complementsGroups?.findIndex(
        (group: IComplementsGroup) => group.id === undefined,
      );
      const newComplement: IComplement = {
        title: additional,
        unitPrice: value,
        isActive: true,
        maxAmount: 1,
        amount: 1,
        error: false,
        deleted: false,
        checked: true,
      };
      if (existingGroupIndex === -1) {
        const newGroup: IComplementsGroup = {
          id: undefined,
          title: 'Adicional Avulso',
          localId: 'add_avulso',
          minAmount: 0,
          maxAmount: 99,
          isActive: true,
          complements: [newComplement],
          position: 0,
          deleted: false,
          category: 'STANDARD',
          modules: '',
          checked: true,
        };
        product.complementsGroups = [
          ...(product.complementsGroups || []),
          newGroup,
        ];
      } else {
        // Se já existe o grupo, adicionamos o complemento ao grupo existente
        const existingGroup = (product?.complementsGroups ?? [])[
          existingGroupIndex ?? 0
        ];
        existingGroup.complements = [
          ...existingGroup.complements,
          newComplement,
        ];
      }
      setOpen(false);
      setAdditional('');
      return product;
    });
  };

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        outline
        title="Adicional"
        icon={<FiPlus size={22} />}
      />
      {open && (
        <Modal
          open={open}
          setOpen={setOpen}
          onConfirm={handleConfirm}
          onCancel={() => {
            setValue(0);
            setOpen(false);
            setError(false);
            setAdditional('');
          }}
        >
          <ModalWrapper error={error}>
            <div className="title">
              <h3>Adicional</h3>
            </div>
            <div className="container">
              <BasicInput
                ref={inputRef}
                title="Adicional"
                placeholder="gelo e limão..."
                value={additional}
                onChange={e => setAdditional(e.target.value)}
                scale="lg"
              />
              <div>
                <p>Valor</p>
                <MaskedCurrency
                  locale="pt-BR"
                  currency="BRL"
                  defaultValue={additional.length === 0 ? 0 : value ?? 0}
                  onChangeValue={value => setValue(value)}
                />
              </div>
            </div>
            {error && <p style={{ color: 'red' }}>O Adicional deve ter nome</p>}
          </ModalWrapper>
        </Modal>
      )}
    </>
  );
};

export default Additionals;
