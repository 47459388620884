import styled, { keyframes, css } from 'styled-components';

const slideIn = keyframes`
  from {
    transform: translateX(110%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOut = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(110%);
  }
`;
const slideInLeft = keyframes`
  from {
    transform: translateX(-110%);
  }
  to {
    transform: translateX(0);
  }
`;
const slideOutLeft = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-110%);
  }
`;
interface IWrapperProps {
  close: boolean;
}

export const Wrapper = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  z-index: 99999;
  background-color: ${({ theme }) => theme.palette.background_secondary};

  ${({ close }) =>
    close
      ? css`
          animation: ${slideOut} 0.5s ease forwards;
        `
      : css`
          animation: ${slideIn} 0.5s ease forwards;
        `}
`;

export const Close = styled.div`
  display: flex;
  min-width: 40px;
  min-height: 40px;
`;

export const Header = styled.div`
  display: flex;
  width: 100%;
  height: 60px;
  align-items: center;
  padding: 20px 32px 5px;
  gap: 30px;

  @media (max-width: 700px) {
    padding: 10px 16px 5px;
  }
`;
export const ButtonMobile = styled.div`
  display: none;
  width: 100%;
  max-height: 60px;
  align-items: center;
  padding: 0 16px;

  @media (max-width: 700px) {
    display: flex;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: calc(100vh - 60px);
  gap: 1rem;

  @media (max-width: 375px) {
    height: calc(100vh - 55px);
  }
`;

export const Display = styled.div<IWrapperProps>`
  display: flex;
  flex: 2;
  width: 100%;
  padding: 16px 32px 10px 32px;
  overflow: hidden;
  height: 100%;
  gap: 1rem;
  min-height: 0px;
  @media (max-width: 700px) {
    padding: 10px 16px;
    flex-direction: column;
  }
  @media (max-width: 1024px) {
    padding: 10px 24px;
  }

  @media (max-width: 700px) {
    ${({ close }) =>
      close
        ? css`
            ${Payments} {
              display: none;
            }
          `
        : css`
            ${Grid} {
              display: none;
            }
          `}
  }
`;

export const Controller = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  border-radius: 16px 16px 0 0;
  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  transition: max-height 0.3s ease;
  padding: 16px 32px;
  min-height: 0px;

  @media (max-width: 1024px) {
    padding: 16px 24px;
  }

  @media (max-width: 700px) {
    padding: 10px 16px;
  }

  max-height: ${({ close }) => (close ? '2px' : '500px')};
`;

export const ContainerController = styled.div`
  display: flex;
  width: 100%;

  gap: 1rem;
  flex-direction: column;

  .first {
    display: flex;
    width: 100%;
    gap: 1rem;

    @media (max-width: 700px) {
      height: 100%;
    }
  }

  @media (max-width: 700px) {
    height: 100%;
  }
`;

export const ContainerInputs = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 1;
  width: 100%;
  height: 100%;
  min-height: 0;
`;
export const ContainerTotalizer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  flex: 2;
  width: 100%;
  height: 100%;

  @media (max-width: 700px) {
    display: none;
  }
`;

export const ButtonClose = styled.div<{ isVisible: boolean }>`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    margin-top: -3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    color: ${({ theme }) => theme.palette.text_white};
    gap: 5px;
    padding: 0 15px;
    border-radius: 8px 8px 0 0;
    background-color: ${({ theme }) => theme.palette.control_light};
    transition: 400ms;
    border: none;
    :hover {
      background-color: ${({ theme }) => theme.palette.primary};
    }

    @media (max-width: 700px) {
      margin-top: -2.7rem;
    }
  }
`;

export const Payments = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  height: 100%;
  gap: 1rem;
  @media (max-width: 700px) {
    ${({ close }) =>
      close
        ? css`
            animation: ${slideOutLeft} 0.5s ease forwards;
          `
        : css`
            animation: ${slideInLeft} 0.5s ease forwards;
          `}
  }
`;

export const Grid = styled.div<IWrapperProps>`
  display: flex;
  flex-direction: column;
  flex: 2;
  width: 100%;
  height: 100%;

  @media (max-width: 700px) {
    ${({ close }) =>
      !close
        ? css`
            animation: ${slideOut} 0.5s ease forwards;
          `
        : css`
            animation: ${slideIn} 0.5s ease forwards;
          `}
  }
`;
export const DisplayPayments = styled.div`
  display: flex;
  height: auto;
  flex-shrink: 1;
  min-height: 0px;
`;
export const GridPayments = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(60px, 1fr));
  width: 100%;
  gap: 5px;
  overflow-y: auto;
`;
