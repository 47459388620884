import styled from 'styled-components';

export const FinishResponsive = styled.div`
  background-color: ${({ theme }) => theme.palette.background_white};
  margin-top: auto;
  width: 100%;
  height: 72px;
  position: sticky; /* Mantém o botão ao final do conteúdo sem escrolar */
  flex-shrink: 0; /* Impede o botão de ser redimensionado no flexbox */
  bottom: 0;
  border-radius: 16px 16px 0px 0px;
  display: flex;
  align-items: center;
  overflow: hidden;
  z-index: 10;
  padding: 32px;
  box-shadow: 0px -1px 3px rgba(0, 0, 0, 0.1);
  gap: 10px;
  @media (max-width: 1280px) {
    padding: 16px;
    width: 100%;
  }

  .subtotal {
    width: 100%;
    z-index: 10;
    gap: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: ${({ theme }) => theme.palette.black};
    span {
      font-size: 12px;
    }
    strong {
      font-size: 20px;

      @media (max-width: 900px) {
        font-size: 16px;
      }
    }
  }
  .button {
    display: flex;
    align-items: center;
    gap: 4px;
    padding: 16px;
    * {
      color: ${({ theme }) => theme.palette.text_white};
    }
    span {
      font-size: 14px;
    }
    svg {
      width: 24px;
      height: 24px;
      z-index: 10;
      display: block;
    }

    @media (max-width: 1280px) {
      padding: 12px;
    }
  }
`;

export const ContainerInput = styled.div`
  min-width: 100px;
`;
