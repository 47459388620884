import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  background-color: ${({ theme }) => theme.palette.background_white};

  .link {
    text-decoration: underline;
    color: ${({ theme }) => theme.palette.primary};
    background: none;
    border: none;
    padding: 15px 0;
    margin: 0;
    font: inherit;
    cursor: pointer;
  }
`;

export const Close = styled.div`
  display: flex;
  width: auto;
  height: auto;
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 20px 32px;
  padding-bottom: 100px;
  gap: 20px;
  box-sizing: border-box;
  overflow-y: auto;
  overflow-x: hidden;
  @media (max-width: 600px) {
    padding: 20px 16px;
  }
`;

export const Header = styled.div`
  position: sticky;
  top: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme }) => theme.palette.background_white};
  z-index: 999;
  align-items: flex-start;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1);
  padding: 10px 32px;
  gap: 10px;
  .name {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    /* @media (max-width: 1129px) { */
    h2 {
      transform: rotate3d(0);
      background-color: ${({ theme }) => theme.palette.primary};
      /* border-radius: 32px 4px 32px 4px; */
      border-radius: 50% 5px 50% 5px;
      font-size: 20px;
      padding: 5px 40px;
      color: ${({ theme }) => theme.palette.text_white};

      @media (max-width: 500px) {
        padding: 5px 20px;
      }
    }
    /* } */
  }
`;

export const Form = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  width: 100%;
  .icon {
    div {
      margin-right: 0;
    }
    display: flex;
    align-items: end;
  }
`;
export const Line = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;

  .people {
    width: 30%;
  }
`;

export const NotFound = styled.div`
  width: 100%;
  height: 100%;
  align-items: center;
  text-align: center;
  place-content: center;
  margin-bottom: 100px;
`;

export const SelectAll = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  input[type='checkbox'] {
    appearance: none; /* Remove o estilo padrão do checkbox */
    width: 30px;
    height: 30px;
    border: 2px solid ${({ theme }) => theme.palette.primary};
    border-radius: 4px;
    text-align: center;
    position: relative;
    cursor: pointer;

    &:checked {
      background-color: ${({ theme }) => theme.palette.primary};

      &::after {
        content: '✔'; /* Adiciona o checkmark */
        color: white;
        font-size: 18px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
`;

export const FalseInput = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;

  p {
    position: relative;
    padding: 2px 10px;
    font-size: 14px;
    display: flex;
    justify-content: start;
    min-height: 27px;
    width: 100%;
  }
  .first {
    font-size: 12px;
    z-index: 100;
    width: fit-content;
    padding: 2px 5px;
    margin-bottom: -7px;
    border-radius: 8px 0 0 8px;
    background-color: ${({ theme }) => theme.palette.background_white};
  }
  .second {
    max-width: 100%;
    border: 1px solid #aaa;
    border-radius: 8px;
    white-space: nowrap; /* Não quebra o texto em várias linhas */
    overflow: hidden; /* Oculta o texto que ultrapassa o contêiner */
    text-overflow: ellipsis; /* Adiciona '...' ao final do texto que não cabe */
  }
  .number {
    width: 100%;
    display: flex;
    justify-content: center;
    border: 1px solid #aaa;
    border-radius: 8px;
  }
`;
