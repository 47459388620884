import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Wrapper } from './styles';

import NavbarSellPage from '../NavbarSellPage';
import ListProductsSell from '../ListProductsSell';

import ICategory from '../../models/ICategory';
import { PageNames } from '../../enums/pages';

import { useProducts } from '../../hooks/products';
import { useSidebar } from '../../hooks/sidebar';
import { useCategories } from '../../hooks/categories';
import Loading from '../Loading';
import { IProductSell } from '../../models/IFastSell';

interface IProductsSellPageProps {
  setOpen: Dispatch<SetStateAction<boolean>>;
}

type ProductsAndGroupsType = {
  products: IProductSell[];
  groups: ICategory[];
};
export type IdsSelectedType = {
  IdGroupSelected: number;
  IdSizeSelected: number;
  IdSubcategorieSelected: number;
};

const ProductsSellPage: React.FC<IProductsSellPageProps> = ({ setOpen }) => {
  const { setSelectedPage, selectedPage } = useSidebar();
  const { loadOnlyCategories } = useCategories();
  const { loadOnlyProducts } = useProducts();
  const [loading, setLoading] = useState(true);

  const [productsAndGroups, setProductsAndGroups] =
    useState<ProductsAndGroupsType>({ products: [], groups: [] });

  const [idsSelected, setIdsSelected] = useState<IdsSelectedType>({
    IdGroupSelected: -1, // inicia mostrando os favoritos
    IdSizeSelected: 0,
    IdSubcategorieSelected: 0,
  });

  const [searchCriteria, setSearchCriteria] = useState('');

  useEffect(() => {
    let isMounted = true;
    if (PageNames.SELL !== selectedPage) {
      setSelectedPage(PageNames.SELL);
    }

    const fetchGroupsAndProducts = async () => {
      setLoading(true);

      // Verificar se os produtos e grupos já foram carregados
      if (
        productsAndGroups.products.length > 0 &&
        productsAndGroups.groups.length > 0
      ) {
        setLoading(false);
        return;
      }

      try {
        const categories = await loadOnlyCategories();
        const products = await loadOnlyProducts();

        if (isMounted) {
          setProductsAndGroups({ products, groups: categories });
        }
      } catch (error) {
        throw error;
      } finally {
        if (isMounted) {
          setLoading(false); // Finaliza o loading
        }
      }
    };

    const startFetching = () => {
      fetchGroupsAndProducts();
      const intervalId = setInterval(() => {
        fetchGroupsAndProducts();
      }, 300000); // 300000ms = 5 minutos

      return intervalId;
    };
    const intervalId = startFetching();
    return () => {
      isMounted = false;
      clearInterval(intervalId);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadOnlyCategories]);

  // Filtra todos produtos ativos
  const activeProducts = useMemo(() => {
    const allProducts = productsAndGroups.products.filter(
      product => product.active,
    );

    const expandedProducts = allProducts.flatMap(product => {
      // Se o produto tiver pizzaSizes, cria um novo produto para cada tamanho de pizza
      if (product.pizzaSizes && product.pizzaSizes.length > 0) {
        return product.pizzaSizes.map(size => ({
          ...product,
          title: `${product.title} - ${size.name}`, // Adiciona o nome do tamanho ao título do produto
          pizzaSizes: [size], // Mantém apenas o tamanho de pizza atual
        }));
      }
      // Se não tiver pizzaSizes, retorna o produto original
      return product;
    });

    const idGroup = idsSelected?.IdGroupSelected;
    const idSize = idsSelected?.IdSizeSelected;
    const idSub = idsSelected?.IdSubcategorieSelected;

    // Verifica se o id do grupo é -1 (Padrão para favoritos) ou se algum grupo está selecionado
    const onlyGroup = expandedProducts.filter(product =>
      product?.categories?.some(cat => cat.id === idGroup),
    );

    if (idGroup === -1) {
      const favoriteProducts = expandedProducts.filter(
        product => product.isFavorite,
      );
      if (favoriteProducts.length > 0) {
        return favoriteProducts;
      }
      setIdsSelected(prev => ({ ...prev, IdGroupSelected: 0 }));
    }
    if (idGroup === -2) {
      return expandedProducts.filter(product => (product?.salePrice ?? 0) > 0);
    }
    // Filtros para categorias, sucbategorias e tamanhos
    if (idGroup === 0 && idSize === 0 && idSub === 0) {
      return expandedProducts;
    }
    if (idGroup !== 0 && idSize === 0 && idSub === 0) {
      return onlyGroup;
    }
    if (idGroup !== 0 && idSize !== 0 && idSub === 0) {
      return onlyGroup.filter(
        product => product?.pizzaSizes?.[0].pizzaSizeId === idSize,
      );
    }
    if (idGroup !== 0 && idSize === 0 && idSub !== 0) {
      return onlyGroup.filter(product => product?.subcategory?.id === idSub);
    }
    return [];
  }, [idsSelected, productsAndGroups.products]);

  const searchedProducts = useMemo(() => {
    return activeProducts.filter(product => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      return product.title
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [activeProducts, searchCriteria]);
  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <Wrapper>
          <NavbarSellPage
            setOpen={setOpen}
            idsSelected={idsSelected}
            setIdsSelected={setIdsSelected}
            groups={productsAndGroups?.groups || []}
            setSearchCriteria={setSearchCriteria}
            searchCriteria={searchCriteria}
          />
          <ListProductsSell filteredProducts={searchedProducts ?? []} />
        </Wrapper>
      )}
    </>
  );
};

export default ProductsSellPage;
