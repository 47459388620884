import React, { useContext, createContext, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import api from '../services/api';
import { IProductCart } from '../models/IProduct';

export interface ITransferOrder {
  orderId: number;
  EOrderTransferTypeDestination: string;
  EOrderTransferTypeOrigin: string;
  destinationNumber?: number;
  originNumber?: number;
  items?: IProductCart[];
}

export interface IEditOrder {
  orderId: number;
  waiterId?: number;
  customerId?: number;
  customerName?: string;
  phoneNumber?: string;
  description?: string;
  location?: string;
  personQty?: number;
}

export interface IPayment {
  orderId: number;
  amount: number;
  paymentType: string;
}

interface InternalOrdersContextData {
  transferOrder: (orderId: number, body: ITransferOrder) => void;
  deleteOrder: (orderId: number, reason: string) => Promise<void>;
  editOrder: (orderId: number, body: IEditOrder) => Promise<void>;
  sendPayment: (orderID: number, body: IPayment[]) => Promise<void>;
  closingOrder: (orderId: number) => Promise<AxiosResponse<any>>;
  transferProduct: (orderId: number, body: IEditOrder) => Promise<void>;
  occupiedOrder: (orderId: number) => Promise<AxiosResponse<any>>;
  completeOrder: (
    orderId: number,
    body: {
      discount: number;
      serviceFee: number;
      orderId: number;
    },
  ) => Promise<AxiosResponse<any>>;
  deletePaymentOrder: (
    orderId: number,
    paymentId: number,
  ) => Promise<AxiosResponse<any>>;
  amountProduct: (
    itemId: number,
    product: IProductCart,
  ) => Promise<AxiosResponse<any>>;
}

const InternalOrdersContext = createContext<InternalOrdersContextData>(
  {} as InternalOrdersContextData,
);

export const InternalOrdersProvider: React.FC = ({ children }) => {
  const deleteOrder = useCallback(async (orderId: number, reason: string) => {
    try {
      await api.patch(`/restricted/internal-orders/${orderId}`, {
        reason,
      });
    } catch (error) {
      throw error;
    }
  }, []);

  const deletePaymentOrder = useCallback(
    async (orderId: number, paymentId: number) => {
      try {
        const response = await api.delete(
          `/restricted/internal-orders/${orderId}/payments/${paymentId}`,
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    [],
  );

  const amountProduct = useCallback(
    async (itemId: number, product: IProductCart) => {
      try {
        const response = await api.patch(
          `/restricted/internal-orders/${product.orderId}/items/${itemId}`,
          product,
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    [],
  );

  const completeOrder = useCallback(
    // Estou lançando o valor da porcentagem do desconto e servico
    async (
      orderId: number,
      body: { discount: number; serviceFee: number; orderId: number },
    ) => {
      try {
        const response = await api.patch(
          `/restricted/internal-orders/${orderId}/complete`,
          body,
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    [],
  );

  const closingOrder = useCallback(async (orderId: number) => {
    try {
      const response = await api.put(
        `/restricted/internal-orders/${orderId}/closing`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }, []);
  const occupiedOrder = useCallback(async (orderId: number) => {
    try {
      const response = await api.patch(
        `/restricted/internal-orders/${orderId}/change-status/occuppied`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }, []);

  const editOrder = async (orderId: number, body: IEditOrder) => {
    try {
      await api.put(`/restricted/internal-orders/${orderId}`, body);
    } catch (error) {
      console.error('Erro na API:', error);
      throw error;
    }
  };
  const transferOrder = async (orderId: number, body: ITransferOrder) => {
    try {
      await api.put(`/restricted/internal-orders/${orderId}/transfer`, body);
    } catch (error) {
      console.error('Erro na API:', error);
      throw error;
    }
  };
  const transferProduct = async (orderId: number, body: IEditOrder) => {
    try {
      await api.put(
        `/restricted/internal-orders/${orderId}/transfer/items`,
        body,
      );
    } catch (error) {
      console.error('Erro na API:', error);
      throw error;
    }
  };

  const sendPayment = useCallback(async (orderID: number, body: IPayment[]) => {
    try {
      await api.post(`/restricted/internal-orders/${orderID}/payments`, body);
    } catch (error) {
      console.error('Erro na API:', error);
      throw error;
    }
  }, []);

  return (
    <InternalOrdersContext.Provider
      value={{
        editOrder,
        sendPayment,
        deleteOrder,
        closingOrder,
        completeOrder,
        transferOrder,
        occupiedOrder,
        amountProduct,
        transferProduct,
        deletePaymentOrder,
      }}
    >
      {children}
    </InternalOrdersContext.Provider>
  );
};

export function useInternalOrders(): InternalOrdersContextData {
  const context = useContext(InternalOrdersContext);

  if (!context) {
    throw new Error(
      'useInternalOrders must be used within InternalOrdersProvider',
    );
  }

  return context;
}
