import React, {
  forwardRef,
  useCallback,
  useImperativeHandle,
  useRef,
} from 'react';

import { Container } from './styles';

interface IScrollProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  style?: any;
  loading: boolean;
  hasMore: boolean;
  endComponent: React.ReactNode;
  loadingComponent: React.ReactNode;
  loadMore: () => void;
  children: React.ReactNode;
}

export interface IScrollRef {
  scrollToTop: () => void;
}

const InfiniteScroll: React.ForwardRefRenderFunction<
  IScrollRef,
  IScrollProps
> = (
  {
    children,
    loading,
    loadingComponent,
    loadMore,
    style,
    hasMore,
    endComponent,
  },
  ref,
) => {
  const scrollRef = useRef<HTMLDivElement>(null);

  const scrollToTop = useCallback(() => {
    scrollRef.current?.scrollTo({ top: 0 });
  }, []);

  useImperativeHandle(ref, () => ({
    scrollToTop,
  }));

  const onScroll = useCallback(() => {
    const { scrollTop, offsetHeight, scrollHeight } =
      scrollRef.current as HTMLDivElement;

    if (offsetHeight + scrollTop >= scrollHeight - 100 && hasMore) {
      loadMore();
    }
  }, [loadMore, hasMore]);

  return (
    <Container
      ref={scrollRef}
      onScroll={onScroll}
      className="has-custom-scroll-bar-2"
      style={style || { overflowY: 'scroll', height: '100%' }}
    >
      {children}
      {loading && loadingComponent}
      {!hasMore && endComponent}
    </Container>
  );
};

export default forwardRef(InfiniteScroll);
