import styled from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  flex: 1;
  grid-template-rows: 30px 60px 1fr 60px;
  width: 100%;
  height: 50%;
  background-color: ${({ theme }) => theme.palette.background_white};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
`;

export const Labels = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 3fr 0.5fr 1fr 0.5fr;
  font-weight: bold;
  padding: 10px;
  border-bottom: 1px solid #ccc;
  height: 100%;
  max-height: 60px;
`;

export const ScrollContainer = styled.div`
  overflow-y: auto;
`;
export const Cell = styled.div`
  padding: 5px;
`;

export const Clear = styled.div`
  display: flex;
  width: 100%;
  justify-content: end;
  padding: 10px 10px 0 0;

  color: ${({ theme }) => theme.palette.primary};

  button {
    border: none;
    width: auto;
    height: 100%;
    background-color: transparent;
    max-height: 30px;
    display: flex;
    cursor: pointer;
    gap: 5px;
    cursor: pointer;
    align-items: center;
    p {
      color: ${({ theme }) => theme.palette.primary};
      font-size: 12px;
      transform: translateX(100%);
      opacity: 0;
      transition: 500ms ease;
    }

    &:hover {
      p {
        transform: translateX(0);
        opacity: 1;
      }

      svg {
        transition: 500ms ease;
        transform: scale(1.05);
        box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px,
          rgba(0, 0, 0, 0.23) 0px 6px 6px;
      }
    }
  }

  svg {
    border-radius: 100%;
    cursor: pointer;
  }
`;
