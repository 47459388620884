import React, { useState } from 'react';
import { FaMinus, FaPlus } from 'react-icons/fa';
import { BtnAdd, Container, Control, Wrapper } from './styles';
import { formatToMoney } from '../../utils/format';

interface IDivisionPerPersonProps {
  totalValue: number;
}
const DivisionPerPerson: React.FC<IDivisionPerPersonProps> = ({
  totalValue,
}) => {
  const [peopleNumber, setPeopleNumber] = useState(1);
  return (
    <Wrapper>
      <p>Divisão por pessoa</p>
      <Container>
        <Control>
          <BtnAdd
            onClick={() =>
              setPeopleNumber(prev => (prev > 1 ? prev - 1 : prev))
            }
          >
            <FaMinus size={18} color="#fff" />
          </BtnAdd>
          <p>{peopleNumber}</p>
          <BtnAdd onClick={() => setPeopleNumber(prev => prev + 1)}>
            <FaPlus size={18} color="#fff" />
          </BtnAdd>
        </Control>

        {formatToMoney(totalValue / peopleNumber)}
      </Container>
    </Wrapper>
  );
};

export default DivisionPerPerson;
