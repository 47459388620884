import styled, { css } from 'styled-components';

export const Wrapper = styled.div`
  display: grid;
  grid-template-rows: 40px 1fr 30px;
  width: 100%;
  flex-shrink: 1;
  flex-grow: 1;
  min-height: 0px;

  border-radius: 8px;
  background-color: ${({ theme }) => theme.palette.background_white};
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 4px;
  h3 {
    padding: 0 16px 10px 16px;
    align-items: center;
    color: ${({ theme }) => theme.palette.success};
  }
`;
export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  overflow-y: auto;
`;

export const Transaction = styled.div`
  display: grid;
  grid-template-columns: 0.5fr 0.5fr 3fr 0.5fr 0.5fr;
  padding: 10px;
  height: auto;
  max-height: 60px;
  padding: 0 16px;
`;
export const Cell = styled.div<{ deleteIcon?: boolean }>`
  align-items: center;
  ${({ deleteIcon = false }) =>
    deleteIcon &&
    css`
      display: flex;
      justify-content: end;
    `}

  color: ${({ theme }) => theme.palette.text_light};

  img {
    width: 22px;
    height: 22px;
  }

  .delete {
    align-items: end;
    transition: 300ms;
    &:hover {
      cursor: pointer;
      transform: scale(1.05);
    }
  }
`;
