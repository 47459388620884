import React, { useCallback, useMemo, useState } from 'react';

import { FiX } from 'react-icons/fi';

import IOrder from '../../models/IOrder';

import { useToast } from '../../hooks/toast';
import { useOrders } from '../../hooks/orders';
import { useTables } from '../../hooks/tables';

import Order from '../Order';
import PrintModal from '../PrintModal';
import OrderCancelationModal from '../OrderCancelationModal';

import {
  Scroll,
  Header,
  Container,
  ModalTitle,
  CloseButton,
  StyledModal,
  OrdersContainer,
} from './styles';
import LoadingAnimation from '../LoadingAnimation';

interface ITableSummaryModalProps {
  onOrderClicked: (order: IOrder) => void;
}

const TableSummaryModal: React.FC<ITableSummaryModalProps> = ({
  onOrderClicked,
}) => {
  const { addToast } = useToast();
  const { setSelectedOrder, cancelOrder } = useOrders();

  const { selectedTable, tableOrders, unselectTable, tableOrdersLoading } =
    useTables();

  const [orderToBeCanceled, setOrderToBeCanceled] = useState(0);
  const [printModalVisible, setPrintModalVisible] = useState(false);

  const isModalOpen = useMemo(() => {
    return !!selectedTable;
  }, [selectedTable]);

  const handleCloseModal = useCallback(() => {
    unselectTable();
  }, [unselectTable]);

  const handleOnPressClick = useCallback(
    (order: IOrder) => {
      setSelectedOrder(order);
      setPrintModalVisible(true);
    },
    [setSelectedOrder],
  );

  const handleOnOrderCancelClick = useCallback((orderId: number) => {
    setOrderToBeCanceled(orderId);
  }, []);

  const handleOnCancelOrderCancel = useCallback(() => {
    setOrderToBeCanceled(0);
  }, []);

  const handleOnCancelOrder = useCallback(
    async (reason: string) => {
      try {
        await cancelOrder(orderToBeCanceled, reason);

        addToast({
          type: 'success',
          description: 'Pedido cancelado com sucesso!',
        });
      } catch {
        addToast({
          type: 'error',
          description: 'Erro ao cancelar o pedido. Tente novamente.',
        });
      } finally {
        setOrderToBeCanceled(0);
      }
    },
    [orderToBeCanceled, cancelOrder, addToast],
  );

  const handleOnClosePrintModal = useCallback(() => {
    setPrintModalVisible(false);
  }, []);

  return (
    <>
      <StyledModal
        shouldCloseOnEsc
        isOpen={isModalOpen}
        id="table-summary-modal"
        shouldCloseOnOverlayClick
        onRequestClose={handleCloseModal}
        style={{
          overlay: {
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          },
        }}
      >
        <Container>
          <Scroll className="has-custom-scroll-bar-2">
            <Header>
              <ModalTitle>{`Pedidos da mesa ${selectedTable?.tableNumber}`}</ModalTitle>
              <CloseButton onClick={handleCloseModal}>
                <FiX size={20} />
              </CloseButton>
            </Header>
            {tableOrdersLoading ? (
              <LoadingAnimation />
            ) : (
              <>
                {tableOrders.length > 0 ? (
                  <OrdersContainer>
                    {tableOrders.map(order => (
                      <Order
                        type="ALL"
                        order={order}
                        key={order.id}
                        onClick={onOrderClicked}
                        onPressClick={handleOnPressClick}
                        onCancelClick={handleOnOrderCancelClick}
                      />
                    ))}
                  </OrdersContainer>
                ) : (
                  <div className="empty-message">
                    <span>Nenhum pedido encontrado.</span>
                  </div>
                )}
              </>
            )}
          </Scroll>
        </Container>
      </StyledModal>
      <PrintModal open={printModalVisible} onCancel={handleOnClosePrintModal} />
      {orderToBeCanceled > 0 && (
        <OrderCancelationModal
          open={orderToBeCanceled > 0}
          onConfirm={handleOnCancelOrder}
          onCancel={handleOnCancelOrderCancel}
        />
      )}
    </>
  );
};

export default TableSummaryModal;
