import React, { Dispatch, SetStateAction, useCallback, useEffect } from 'react';
import { FiArrowLeft, FiShoppingCart } from 'react-icons/fi';
import { useHistory } from 'react-router-dom';

// eslint-disable-next-line import/no-extraneous-dependencies
import { Location } from 'history';
import { useSidebar } from '../../hooks/sidebar';

import {
  Cart,
  Close,
  Container,
  NavbarWrapper,
  SubcategorieContainer,
  SubcategoriesMap,
  SubcategoriesWrapper,
} from './styles';

import { PageNames } from '../../enums/pages';
import ICategory from '../../models/ICategory';
import { IdsSelectedType } from '../ProductsSellPage';

import CarousellSellPage from '../CarousellSellPage';
import IconButton from '../IconButton';
import { IPizzaSize } from '../../models/IPizza';
import { useCartProducts } from '../../hooks/products_sell';
import Search from '../Search';

interface INavbarSellPageProps {
  setIdsSelected: React.Dispatch<React.SetStateAction<IdsSelectedType>>;
  setOpen: Dispatch<SetStateAction<boolean>>;
  setSearchCriteria: React.Dispatch<React.SetStateAction<string>>;
  searchCriteria: string;
  idsSelected: IdsSelectedType;
  groups: ICategory[];
}

const NavbarSellPage: React.FC<INavbarSellPageProps> = ({
  idsSelected,
  searchCriteria,
  groups,
  setIdsSelected,
  setOpen,
  setSearchCriteria,
}) => {
  const { setSelectedPage } = useSidebar();
  const { openCart, setOpenCart, setSelectedProduct } = useCartProducts();
  const history = useHistory();

  // useEffect criado para que quando clicado no botão (VOLTAR) do navegador a sidebar seja setada novamente
  useEffect(() => {
    const unblock = history.block((location: Location, action: string) => {
      if (action === 'POP') {
        history.push('/');
        setSelectedPage(PageNames.DASHBOARD);
      }
    });
    return () => {
      unblock();
    };
  }, [history, setSelectedPage]);

  useEffect(() => {
    setSelectedPage(PageNames.SELL);
  }, [setSelectedPage]);

  const groupSelected = groups.filter(
    group => group.id === idsSelected.IdGroupSelected,
  )[0];

  const handleOnClickSubcategorie = (size: ICategory) => {
    if (idsSelected.IdSubcategorieSelected === size.id) {
      setIdsSelected(old => ({
        ...old,
        IdSubcategorieSelected: 0,
      }));
    } else {
      setIdsSelected(old => ({
        ...old,
        IdSubcategorieSelected: size?.id ?? 0,
      }));
    }
  };
  const handleOnClickSize = (size: IPizzaSize) => {
    if (idsSelected.IdSizeSelected === size.id) {
      setIdsSelected(old => ({
        ...old,
        IdSizeSelected: 0,
      }));
    } else {
      setIdsSelected(old => ({
        ...old,
        IdSizeSelected: size?.id ?? 0,
      }));
    }
  };

  const handleDebouncedEvent = useCallback(
    (value: React.SetStateAction<string>) => {
      setSearchCriteria(value);
    },
    [setSearchCriteria],
  );
  return (
    <NavbarWrapper>
      <Container>
        <Close>
          <IconButton
            icon={<FiArrowLeft size={22} />}
            onClick={() => {
              setOpen(false);
              setTimeout(() => {
                history.push('/sell');
                setSelectedPage(PageNames.SELL);
              }, 500);
            }}
          />
        </Close>
        <Search
          value={searchCriteria}
          onChange={e => handleDebouncedEvent(e)}
          fullscreen
        />
        {!openCart && (
          <Cart>
            <IconButton
              icon={<FiShoppingCart size={22} />}
              onClick={() => {
                setSelectedProduct(null);
                setOpenCart(true);
              }}
            />
          </Cart>
        )}
      </Container>
      <CarousellSellPage
        setIdsSelected={setIdsSelected}
        idsSelected={idsSelected}
        groups={groups}
      />
      <SubcategoriesWrapper>
        {((groupSelected?.subCategories || []).length > 0 ||
          groupSelected?.pizzaSizes?.length > 0) && <p>Subcategorias</p>}

        <SubcategoriesMap>
          {groupSelected?.subCategories?.map(subcategorie => (
            <SubcategorieContainer
              selected={subcategorie.id === idsSelected.IdSubcategorieSelected}
              key={subcategorie.id}
              onClick={() => handleOnClickSubcategorie(subcategorie)}
            >
              {subcategorie.name}
            </SubcategorieContainer>
          ))}
          {groupSelected?.pizzaSizes?.map(size => (
            <SubcategorieContainer
              key={size.id}
              onClick={() => handleOnClickSize(size)}
              selected={size.id === idsSelected.IdSizeSelected}
            >
              {size.name}
            </SubcategorieContainer>
          ))}
        </SubcategoriesMap>
      </SubcategoriesWrapper>
    </NavbarWrapper>
  );
};

export default NavbarSellPage;
