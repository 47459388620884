import React, { createContext, useState, useContext } from 'react';
import IOrder from '../models/IOrder';

interface IPaymentDrawerContextProps {
  order: IOrder;
  isDrawerOpen: boolean;
  openDrawer: () => void;
  closeDrawer: () => void;
  setOrder: React.Dispatch<React.SetStateAction<IOrder>>;
}

const PaymentDrawerContext = createContext<IPaymentDrawerContextProps>(
  {} as IPaymentDrawerContextProps,
);

export const PaymentDrawerProvider: React.FC = ({ children }) => {
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [order, setOrder] = useState<IOrder>({} as IOrder);

  const openDrawer = () => {
    setDrawerOpen(true);
  };

  const closeDrawer = () => {
    setDrawerOpen(false);
  };

  return (
    <PaymentDrawerContext.Provider
      value={{ isDrawerOpen, order, setOrder, openDrawer, closeDrawer }}
    >
      {children}
    </PaymentDrawerContext.Provider>
  );
};

export function usePaymentDrawer(): IPaymentDrawerContextProps {
  const context = useContext(PaymentDrawerContext);
  if (!context) {
    throw new Error('useCards must be used within usePaymentDrawer');
  }

  return context;
}
