import React, {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { BiTransfer } from 'react-icons/bi';
import Select, { ValueType } from 'react-select';
import { selectStyles } from '../../styles/select';
import { ContainerCancelationOrder, SelectContainer, Tittle } from './styles';

import IOrder from '../../models/IOrder';
import { ICard } from '../../models/ICard';
import { ITable2 } from '../../models/ITable2';
import { statusCardTableType } from '../../enums/cardAndTableType';

import { ITransferOrder, useInternalOrders } from '../../hooks/internal_orders';
import { useTable } from '../../hooks/table';
import { useCards } from '../../hooks/card';
import { useConfirmDialog } from '../../hooks/confim_dialog';

import Modal from '../Modal';

export interface ISelect {
  label: string;
  value: number;
}
interface ITransferOrderProps {
  order?: IOrder;
  card?: ICard;
  table?: ITable2;
  open: boolean;
  setOpen: Dispatch<SetStateAction<boolean>>;
}
const TransferOrder: React.FC<ITransferOrderProps> = ({
  table,
  card,
  order,
  open,
  setOpen,
}) => {
  const { transferOrder } = useInternalOrders();
  const { ThrowError } = useConfirmDialog();
  const { getTables } = useTable();
  const { getCards } = useCards();
  const [optionSelected, setOptionSelected] = useState<ISelect | undefined>(
    undefined,
  );
  const [cards, setCards] = useState<ICard[]>([]);
  const [tables, setTables] = useState<ITable2[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchCards = async () => {
      setLoading(true);
      try {
        const response = await getCards();
        setCards(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };

    fetchCards();
  }, [getCards]);

  const tablesOptions = useMemo(() => {
    return tables
      .filter(tb => tb.status === statusCardTableType.FREE)
      ?.map(tb => {
        return {
          label: `Mesa ${tb.tableNumber}`,
          value: tb.tableNumber,
        };
      });
  }, [tables]);

  const cardsOptions = useMemo(() => {
    return cards
      .filter(cd => cd.status === statusCardTableType.FREE)
      ?.map(cd => {
        return {
          label: `Comanda ${cd.cardNumber}`,
          value: -cd.cardNumber,
        };
      });
  }, [cards]);

  useEffect(() => {
    const fetchTable = async () => {
      setLoading(true);
      try {
        const response = await getTables();
        setTables(response?.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        throw error;
      }
    };

    fetchTable();
  }, [getTables]);

  const handleOnGroupChanged = useCallback(
    (param: ValueType<ISelect, false>) => {
      setError(false);
      setOptionSelected(param as ISelect);
    },
    [],
  );

  const handleTransferOrder = async () => {
    try {
      setLoading(true);
      if (!optionSelected) {
        setLoading(false);
        setError(true);
        return;
      }
      const body: ITransferOrder = {
        orderId: order?.id ?? 0,
        EOrderTransferTypeDestination:
          optionSelected && optionSelected?.value < 0 ? 'CARD' : 'TABLE',
        EOrderTransferTypeOrigin: card ? 'CARD' : 'TABLE',
        destinationNumber:
          optionSelected && optionSelected?.value > 0
            ? optionSelected?.value
            : optionSelected && -optionSelected?.value,
        originNumber: card ? card.cardNumber : table?.tableNumber,
      };
      await transferOrder(order?.id ?? 0, body);
      setLoading(false);
    } catch (error) {
      ThrowError(error, 'Erro ao transferir!');
      setLoading(false);
    }
  };
  return (
    <Modal
      open={open}
      setOpen={setOpen}
      onConfirm={handleTransferOrder}
      loading={loading}
    >
      <ContainerCancelationOrder>
        <Tittle>
          <p>
            Transferir
            {card && ` Comanda ${card.cardNumber}`}
            {table && ` Mesa ${table.tableNumber}`}
          </p>
          <BiTransfer size={22} />
          <p>enviar para</p>
          <SelectContainer>
            <Select
              className="select"
              error={error}
              isClearable
              styles={selectStyles}
              menuPortalTarget={document.body}
              value={optionSelected}
              options={[...tablesOptions, ...cardsOptions]}
              placeholder="Selecione a mesa ou comanda de envio"
              onChange={handleOnGroupChanged}
            />

            {error && <p>Obrigatório selecionar um destino!</p>}
          </SelectContainer>
        </Tittle>
      </ContainerCancelationOrder>
    </Modal>
  );
};

export default TransferOrder;
