import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: end;

  @media (max-width: 700px) {
    height: auto;
  }
  @media (max-height: 800px) {
    height: calc(100% - 50px);
    margin-top: -10px;
  }
`;

export const ModalWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;

  h3 {
    font-size: 20px;
  }
`;
export const ModalBody = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 20px 0;
  justify-content: center;
  align-items: center;
  h4 {
    font-size: 18px;
  }
  h5 {
    font-size: 16px;
  }
  img {
    width: 200px;
    height: 200px;
  }
`;
