import styled from 'styled-components';

interface IButtonProps {
  hover: boolean;
}
export const Button = styled.div<IButtonProps>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin-right: 25px;
  background: ${({ theme }) => theme.palette.background_white};
  border: none;
  padding: 5px;
  border-radius: 100%;
  transition: filter 500ms ease;
  cursor: pointer;
  &:hover {
    filter: ${({ hover }) => hover && 'brightness(0.9)'};
  }
`;
