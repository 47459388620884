import styled from 'styled-components';
import { statusCardTableType } from '../../enums/cardAndTableType';

interface IContainerTableProps {
  status: statusCardTableType;
}

export const ContainerTable = styled.div<IContainerTableProps>`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme, status }) =>
    status === 'FREE'
      ? '#85D1A5'
      : status === 'CLOSING'
      ? '#FDD400'
      : theme.palette.primary_light};
  border-radius: 8px;
  box-shadow: rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  padding: 5px;
  color: ${({ theme }) => theme.palette.text_white};
  cursor: pointer;

  .name {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    p {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 15px;
      max-height: 30px;
    }

    svg {
      margin-top: -10px;
    }
  }

  font-size: 12px;
`;

export const Infos = styled.div`
  display: flex;
  width: 100%;
  gap: 5px;
  height: 100%;
  place-content: end;

  .subInfos {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .tableNumber {
    display: flex;
    place-content: end;
    width: 100%;
    height: 100%;
    font-size: 32px;

    p {
      align-items: end;
      place-content: end;
    }

    ${({ theme }) => theme.palette.primary}
  }
`;

export const Icon = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  gap: 2px;
`;
