import styled from 'styled-components';

export const Title = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 52px 0 22px 0;
  gap: 20px;
`;

export const Column = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
`;
export const Btn = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  div {
    width: 100%;
  }
`;

export const StyledInput = styled.div`
  width: 100%;
  font-size: 16px;
  span {
    display: flex;
    width: 100%;
    padding: 2px;
    font-size: 16px;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.outline};
  }
`;
export const StyledInputDiscount = styled.div`
  width: 100%;
  font-size: 16px;
  input {
    padding: 2px;
    font-size: 16px;
    width: 100%;
    border: none;
    border-bottom: 1px solid ${({ theme }) => theme.palette.outline};
  }
`;
