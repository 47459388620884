import React, { useContext, useCallback, createContext } from 'react';
import api from '../services/api';
import { useCompany } from './company';

interface StatusContextData {
  toggleIsOpen: (isOpen: boolean) => Promise<void>;
  toggleSendWhatsapp: (sendWhatsapp: boolean) => Promise<void>;
}

const StatusContext = createContext<StatusContextData>({} as StatusContextData);

export const StatusProvider: React.FC = ({ children }) => {
  const { company } = useCompany();

  const toggleIsOpen = useCallback(
    async (isOpen: boolean) => {
      await api.patch(
        `restricted/companies/${company?.id}/${isOpen ? 'close' : 'open'}`,
        {},
      );
    },
    [company],
  );

  const toggleSendWhatsapp = useCallback(
    async (sendWhatsapp: boolean) => {
      await api.patch(
        `restricted/companies/${company?.id}/send-whatsapp/${
          sendWhatsapp ? 'deactivate' : 'activate'
        }`,
        {},
      );
    },
    [company],
  );

  return (
    <StatusContext.Provider
      value={{
        toggleIsOpen,
        toggleSendWhatsapp,
      }}
    >
      {children}
    </StatusContext.Provider>
  );
};

export function useStatus(): StatusContextData {
  const context = useContext(StatusContext);

  if (!context) {
    throw new Error('useStatus must be used within StatusProvider');
  }

  return context;
}
