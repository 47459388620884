import React, { useState, useEffect, useCallback } from 'react';
import { FiPlus } from 'react-icons/fi';
import {
  Droppable,
  Draggable,
  DropResult,
  DragDropContext,
} from 'react-beautiful-dnd';
import isEqual from 'react-fast-compare';
import {
  Header,
  PageInfo,
  PageName,
  Messages,
  Container,
  InnerContent,
  AddMessageButton,
  DraggableContainer,
} from './styles';
import WarningBadge from '../../components/WarningBadge';
import AddMessageModal from '../../components/AddMessageModal';
import IMessage from '../../models/IMessage';
import { useCompany } from '../../hooks/company';
import { useToast } from '../../hooks/toast';
import { useMessage } from '../../hooks/messages';
import SaveButton from '../../components/SaveButton';
import { PageNames } from '../../enums/pages';

const MessagesPage: React.FC = () => {
  const { addToast } = useToast();
  const { company } = useCompany();
  const { messages, savePosition, loadMessages, deleteMessage, toggleMessage } =
    useMessage();

  const [selectedMessage, setSelectedMessage] = useState<IMessage | null>(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [localMessages, setLocalMessages] = useState<IMessage[]>([]);
  const [blockEdit, setBlockEdit] = useState(true);

  useEffect(() => {
    if (company?.id) {
      loadMessages();
    }
  }, [loadMessages, company]);

  useEffect(() => {
    setLocalMessages(messages);
  }, [messages]);

  const reOrderMessages = useCallback((list, startIndex: number, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  }, []);

  const handleDragEnd = useCallback(
    (result: DropResult) => {
      setBlockEdit(true);
      if (result.destination) {
        setLocalMessages(old => {
          const newMessages = reOrderMessages(
            old,
            result.source.index,
            result?.destination?.index,
          );

          return newMessages as IMessage[];
        });
      }
    },
    [reOrderMessages],
  );

  const handleDragStart = () => {
    setBlockEdit(false);
  };

  const handleEditModal = useCallback((message: IMessage | null) => {
    setSelectedMessage(message);
    setIsModalVisible(true);
  }, []);

  const handleModalVisible = useCallback(() => {
    setIsModalVisible(true);
  }, []);

  const handleCloseModal = useCallback(() => {
    setIsModalVisible(false);
    setSelectedMessage(null);
  }, []);

  const handleOnMessageDelete = useCallback(
    async (id: number) => {
      try {
        await deleteMessage(id);
      } catch (err) {
        addToast({
          type: 'success',
          description: 'Mensagem deletada com sucesso!',
        });
      }
    },
    [addToast, deleteMessage],
  );

  const handleOnMessageToggle = useCallback(
    async (id: number) => {
      try {
        await toggleMessage(id);
        const message = messages.find(message => message.id === id);
        addToast({
          type: 'success',
          description: `Mensagem ${
            message?.active ? 'ativada' : 'desativada'
          } com sucesso`,
        });
      } catch (err) {
        addToast({
          type: 'error',
          description: 'Ocorreu um erro inesperado.',
        });
      }
    },
    [messages, addToast, toggleMessage],
  );

  const saveMessages = useCallback(async () => {
    setBlockEdit(true);
    try {
      await savePosition(
        localMessages.map((message, index) => ({
          id: message.id,
          position: index,
        })),
      );
      addToast({
        type: 'success',
        description: 'Mensagens salvas com sucesso!',
      });
    } catch {
      addToast({
        type: 'error',
        description: 'Ocorreu um erro ao salvar mensagens.',
      });
    }
  }, [savePosition, localMessages, addToast]);

  return (
    <Container>
      <InnerContent>
        <Header>
          <PageInfo>
            <PageName>{PageNames.MESSAGES}</PageName>
          </PageInfo>
          <AddMessageButton onClick={handleModalVisible}>
            <FiPlus />
            <span />
          </AddMessageButton>
        </Header>
        <DraggableContainer className="has-custom-scroll-bar-2">
          <DragDropContext
            onDragEnd={handleDragEnd}
            onDragStart={handleDragStart}
          >
            <Droppable droppableId="messages" direction="vertical">
              {providedDroppable => (
                <div
                  {...providedDroppable.droppableProps}
                  ref={providedDroppable.innerRef}
                >
                  {localMessages.map((message, index) => (
                    <Draggable
                      key={message.id}
                      draggableId={message.id.toString()}
                      index={index}
                    >
                      {provided => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                        >
                          <Messages>
                            <WarningBadge
                              message={message}
                              color={message.hexColor}
                              onEdit={handleEditModal}
                              blockEditable={blockEdit}
                              onDelete={handleOnMessageDelete}
                              onToggle={handleOnMessageToggle}
                            />
                          </Messages>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {providedDroppable.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </DraggableContainer>
      </InnerContent>
      <SaveButton
        disabled={isEqual(localMessages, messages)}
        onClick={saveMessages}
      />
      <AddMessageModal
        message={selectedMessage}
        isOpen={isModalVisible}
        onClose={handleCloseModal}
      />
    </Container>
  );
};

export default MessagesPage;
