import styled from 'styled-components';

interface ITextArea {
  error: boolean;
}
export const TextArea = styled.div<ITextArea>`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  width: 100%;
  text-align: start;
  align-items: start;

  textarea {
    width: 100%;
    height: 100px;

    padding: 8px;
    resize: none;
    margin-top: 8px;

    box-shadow: ${({ theme }) => theme.shadow.box_shadow_small};
    border: 1px solid
      ${({ theme, error }) =>
        error ? theme.palette.primary : theme.palette.outline};
    background: ${({ theme }) => theme.palette.background_white};

    ::placeholder {
      color: ${({ theme, error }) => (error ? theme.palette.primary : '#aaa')};
    }
  }

  .count {
    display: flex;
    width: 100%;
    justify-content: end;
  }
  span {
    display: flex;
    justify-content: flex-end;
    color: ${({ theme, error }) => error && theme.palette.primary};
  }
`;
