import React, { useState, useMemo, useCallback } from 'react';

import { DateTime } from 'luxon';
import Lottie from 'lottie-react-web';
import { useTheme } from 'styled-components';
import { FiCheck, FiPrinter, FiX } from 'react-icons/fi';

import IOrder from '../../models/IOrder';

import { useToast } from '../../hooks/toast';
import { useOrders } from '../../hooks/orders';
import { useCompany } from '../../hooks/company';

import AccessDeniedDialog from '../AccessDeniedDialog';

import { formatToMoney } from '../../utils/format';
import { getDeliveryTypeName, getPaymentTypeName } from '../../utils/string';
import { getNextStatusColor, getNextStatusMessage } from '../../utils/orders';

import animation from '../../assets/animations/loading.json';

import {
  Top,
  Left,
  Time,
  Right,
  Footer,
  Content,
  Summary,
  Wrapper,
  Container,
  OrderInfo,
  OrderSummary,
  TopOrderInfo,
  OrderInfoItem,
  HorizontalItem,
  NextStatusButton,
  PrintOrderButton,
  CancelOrderButton,
  OrderPlacedStatus,
  SummaryHorizontal,
  ApproveOrderButton,
  OrderCanceledStatus,
  OrderCompletedStatus,
  OrderInPrepareStatus,
  OrderInTransitStatus,
} from './styles';
import { EOrderOrigin } from '../../enums/order';
import { removeDuplicateValues } from '../../utils/arrays';

interface IOrderProps {
  order: IOrder;
  type: 'RECENT' | 'ALL';
  onClick: (order: IOrder) => void;
  onPressClick: (order: IOrder) => void;
  onCancelClick: (orderId: number) => void;
}

const Order: React.FC<IOrderProps> = ({
  type,
  order,
  onClick,
  onPressClick,
  onCancelClick,
}) => {
  const theme = useTheme();

  const { addToast } = useToast();
  const { company } = useCompany();
  const { changeStatus, loadingStatus } = useOrders();

  const [accessDialog, setAccessDialog] = useState(false);
  const [loadingOperation, setLoadingOperation] = useState<'CHANGE' | 'CANCEL'>(
    'CHANGE',
  );

  const FormattedStatus = useMemo(
    () => () => {
      switch (order.status) {
        case 'IN_TRANSIT':
          return (
            <OrderInTransitStatus>
              <span>Em entrega</span>
            </OrderInTransitStatus>
          );
        case 'COMPLETED':
          return (
            <OrderCompletedStatus>
              <span>Concluído</span>
            </OrderCompletedStatus>
          );
        case 'PREPARING':
          return (
            <OrderInPrepareStatus>
              <span>Em preparo</span>
            </OrderInPrepareStatus>
          );
        case 'CANCELED':
          return (
            <OrderCanceledStatus>
              <span>Cancelado</span>
            </OrderCanceledStatus>
          );
        default:
          return (
            <OrderPlacedStatus>
              <span>Em aberto</span>
            </OrderPlacedStatus>
          );
      }
    },
    [order],
  );

  const orderNumber = useMemo(() => {
    return `#${order.number}`;
  }, [order]);

  const nextStatus = useMemo(() => {
    if (order.tableNo) {
      switch (order.status) {
        case 'PLACED':
          return 'PREPARING';
        case 'PREPARING':
          return 'COMPLETED';
        case 'CANCELED':
          return 'PREPARING';
        default:
          return 'COMPLETED';
      }
    }

    switch (order.status) {
      case 'PLACED':
        return 'PREPARING';
      case 'PREPARING':
        return 'IN_TRANSIT';
      case 'IN_TRANSIT':
        return 'COMPLETED';
      case 'CANCELED':
        return 'PREPARING';
      default:
        return 'COMPLETED';
    }
  }, [order]);

  const orderTitle = useMemo(() => {
    if (order.receiverName) {
      return order.receiverName;
    }

    if (!order.tableNo) {
      if (order.origin === EOrderOrigin.BSSELFCHECKOUT) {
        return 'BS Self Checkout';
      }

      return 'BS Ticket';
    }

    if (order.cardNo) {
      return `Mesa ${order.tableNo} - Comanda ${order.cardNo}`;
    }

    return `Mesa ${order.tableNo}`;
  }, [order]);

  const handleOnStatusChange = useCallback(
    (
      e: React.MouseEvent<HTMLButtonElement>,
      operation: 'CHANGE' | 'CANCEL',
    ) => {
      if (company?.pdvIntegration) {
        setAccessDialog(true);
        e.stopPropagation();
        return;
      }

      setLoadingOperation(operation);

      if (
        order.status === 'PLACED' &&
        operation === 'CHANGE' &&
        window.innerWidth > 700
      ) {
        e.stopPropagation();
        onPressClick(order);
        try {
          changeStatus('PREPARING', order.id);

          addToast({
            type: 'success',
            description: 'Você alterou o status do pedido.',
          });

          return;
        } catch {
          addToast({
            type: 'error',
            description: 'Verifique sua conexão e tente novamente',
          });
        }
      }

      e.preventDefault();
      e.stopPropagation();
      if (order.status === 'COMPLETED') return;

      try {
        if (operation === 'CANCEL') {
          changeStatus('CANCELED', order.id);
        } else {
          changeStatus(nextStatus, order.id);
        }

        addToast({
          type: 'success',
          description: 'Você alterou o status do pedido.',
        });
      } catch (err) {
        const errors = (err as any).response?.data?.errors?.messages;

        addToast({
          type: 'error',
          description:
            (Array.isArray(errors) && errors[0]) ||
            'Ocorreu um erro inesperado.',
        });
      }
    },
    [order, company, nextStatus, addToast, changeStatus, onPressClick],
  );

  const orderDate = useMemo(() => {
    if (type === 'ALL') {
      const { hours } = DateTime.fromISO(order.createdAt)
        .diffNow('hours')
        .toObject();

      if (hours && hours < -24) {
        return DateTime.fromISO(order.createdAt).toFormat(
          "dd/MM/yyyy 'às' HH:mm",
        );
      }

      return DateTime.fromISO(order.createdAt).toRelative();
    }

    return DateTime.fromISO(order.createdAt).toRelative();
  }, [order, type]);

  const orderDate2 = useMemo(() => {
    if (type === 'ALL') {
      const { hours } = DateTime.fromISO(order.createdAt)
        .diffNow('hours')
        .toObject();

      if (hours && hours < -24) {
        return DateTime.fromISO(order.createdAt).toFormat(
          "dd/MM/yyyy 'às' HH:mm",
        );
      }

      return DateTime.fromISO(order.createdAt).toFormat(
        "dd/MM/yyyy 'às' HH:mm",
      );
    }

    return DateTime.fromISO(order.createdAt).toFormat("dd/MM/yyyy 'às' HH:mm");
  }, [order, type]);

  const orderTime = useMemo(() => {
    if (['DELIVERY', 'CHECKOUT'].includes(order.orderType)) {
      const date = DateTime.fromISO(order.createdAt)
        .plus({
          minutes:
            order.orderType === 'DELIVERY'
              ? order.timeToDelivery
              : order.timeToPickup,
        })
        .diffNow('minutes')
        .toObject();

      const minutes = Math.floor(date?.minutes || 0);
      const maxLateMinutes = -120;

      if (minutes < 0 && minutes >= maxLateMinutes) {
        return `Atrasado ${Math.floor(Math.abs(minutes))} minutos`;
      }

      if (minutes > 0) {
        return `Faltam ${minutes} minutos`;
      }
    }

    return '-';
  }, [order]);

  const AccordingButton = useMemo(
    () => () => {
      if (order.status === 'PLACED') {
        return (
          <ApproveOrderButton onClick={e => handleOnStatusChange(e, 'CHANGE')}>
            {loadingStatus.includes(order.id) &&
            loadingOperation === 'CHANGE' ? (
              <Lottie
                options={{
                  animationData: animation,
                }}
                width={20}
                height={20}
                style={{ overflow: 'visible' }}
              />
            ) : (
              <FiCheck color={theme.palette.status_approved} size={22} />
            )}
          </ApproveOrderButton>
        );
      }

      return (
        <NextStatusButton
          onClick={e => handleOnStatusChange(e, 'CHANGE')}
          color={getNextStatusColor(order.status, order.tableNo)}
        >
          {loadingStatus.includes(order.id) && loadingOperation === 'CHANGE' ? (
            <Lottie
              options={{
                animationData: animation,
              }}
              width={20}
              height={20}
              style={{ overflow: 'visible' }}
            />
          ) : (
            <span>{getNextStatusMessage(order.status, order.tableNo)}</span>
          )}
        </NextStatusButton>
      );
    },
    [theme, loadingOperation, loadingStatus, order, handleOnStatusChange],
  );

  const handleOnPrintClicked = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();
      onPressClick(order);
    },
    [order, onPressClick],
  );

  const handleOnCloseDialog = useCallback(() => {
    setAccessDialog(false);
  }, []);

  const handleOnCancelOrder = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      e.stopPropagation();

      setLoadingOperation('CANCEL');

      onCancelClick(order.id);
    },
    [order, onCancelClick],
  );

  const paymentLabel = useMemo(() => {
    const paymentTypes = (
      (order?.payments || []).length > 0
        ? removeDuplicateValues(
            order?.payments,
            ({ paymentType }) => paymentType || '',
          )?.map(({ paymentType }) => getPaymentTypeName(String(paymentType)))
        : [getPaymentTypeName(order.paymentType)]
    ).filter(value => !!value);

    if (paymentTypes.length === 0) return '-';

    if (paymentTypes.length === 1) return paymentTypes[0];

    if (paymentTypes.length === 2) return paymentTypes.join(' e ');

    return `${paymentTypes.slice(0, -1).join(',')} e ${
      paymentTypes[paymentTypes.length - 1]
    }`;
  }, [order]);

  return (
    <Container onClick={() => onClick(order)} notAuthed={order.notAuthed}>
      <Content>
        <Left>
          <OrderInfo>
            <TopOrderInfo>
              <span>{orderTitle}</span>
              {order.scheduledProduct && (
                <Time>
                  <span>Horário da entrega</span>
                  <strong>
                    {`${order.scheduledProduct
                      .substring(0, 3)
                      .replace(':', 'h')}${order.scheduledProduct.substring(
                      3,
                      5,
                    )}`}
                  </strong>
                </Time>
              )}
            </TopOrderInfo>
            {order.notAuthed && (
              <span className="not-authed">usuário não cadastrado</span>
            )}
            <strong>{orderNumber}</strong>
          </OrderInfo>
          <OrderSummary>
            <SummaryHorizontal>
              <HorizontalItem>
                <span className="label">
                  <span>Total do</span>
                  <br />
                  <span>pedido</span>
                </span>
                <strong className="value">
                  {formatToMoney(order.total || 0)}
                </strong>
              </HorizontalItem>
              <HorizontalItem>
                <span className="label">
                  <span>Tipo de</span>
                  <br />
                  <span>pagamento</span>
                </span>
                <strong className="value">{paymentLabel}</strong>
                <strong className="value">{paymentLabel}</strong>
              </HorizontalItem>
            </SummaryHorizontal>
            <Wrapper>
              <Summary>
                <OrderInfoItem>
                  <span className="label">Data/hora</span>
                  <span className="value">
                    <span className="first">{orderDate}</span>
                    <span className="second">{orderDate2}</span>
                  </span>
                </OrderInfoItem>
                <OrderInfoItem>
                  <span className="label">Modo de entrega</span>
                  <span className="value">
                    {getDeliveryTypeName(order.orderType)}
                  </span>
                </OrderInfoItem>
              </Summary>
              {order.status !== 'COMPLETED' && (
                <Summary>
                  <OrderInfoItem>
                    <span className="label">Tempo restante</span>
                    <span className="value">{orderTime}</span>
                  </OrderInfoItem>
                  <OrderInfoItem>
                    <span className="label">
                      {`Tempo de ${
                        order.orderType === 'DELIVERY' ? 'entrega' : 'retirada'
                      }`}
                    </span>
                    <span className="value">
                      {order.orderType === 'DELIVERY'
                        ? order.timeToDelivery
                        : order.timeToPickup}
                      min
                    </span>
                  </OrderInfoItem>
                </Summary>
              )}
              {order.discountCoupon && (
                <Summary className="first-media">
                  <OrderInfoItem>
                    <span className="label">Cupom de desconto</span>
                    <span className="value">{order.discountCoupon.ref}</span>
                  </OrderInfoItem>
                  <OrderInfoItem>
                    <span className="label">Valor aplicado</span>
                    <span className="value">
                      {formatToMoney(order.discount)}
                    </span>
                  </OrderInfoItem>
                </Summary>
              )}
              {!!order.cancelReason && (
                <OrderInfoItem>
                  <span className="label">Motivo</span>
                  <span className="value">{order.cancelReason}</span>
                </OrderInfoItem>
              )}
            </Wrapper>
          </OrderSummary>
        </Left>
        <Right>
          <Top>
            <PrintOrderButton onClick={handleOnPrintClicked}>
              <FiPrinter color={theme.palette.status_placed} size={22} />
            </PrintOrderButton>
          </Top>
        </Right>
      </Content>
      <Footer>
        <FormattedStatus />
        <div>
          <AccordingButton />
          {order.status !== 'CANCELED' && order.status !== 'COMPLETED' && (
            <CancelOrderButton onClick={handleOnCancelOrder}>
              {loadingStatus.includes(order.id) &&
              loadingOperation === 'CANCEL' ? (
                <Lottie
                  options={{
                    animationData: animation,
                  }}
                  width={20}
                  height={20}
                  style={{ overflow: 'visible' }}
                />
              ) : (
                <FiX color={theme.palette.status_canceled} size={22} />
              )}
            </CancelOrderButton>
          )}
        </div>
      </Footer>
      <AccessDeniedDialog open={accessDialog} onCancel={handleOnCloseDialog} />
    </Container>
  );
};

export default Order;
