import React, { useEffect, useMemo, useState } from 'react';

import { Wrapper, Grid, Client, Head } from './styles';

import InputSell from '../InputSell';
import Modal from '../../../../components/Modal';
import Search from '../../../../components/Search';

import { useClient } from '../../../../hooks/clients';
import { useConfirmDialog } from '../../../../hooks/confim_dialog';

import IClient from '../../../../models/IClient';

interface IInputClientsProps {
  customer: IClient | undefined;
  setCustomer: React.Dispatch<React.SetStateAction<IClient>>;
}
const InputClients: React.FC<IInputClientsProps> = ({
  customer,
  setCustomer,
}) => {
  const { getClients } = useClient();
  const { ThrowError } = useConfirmDialog();

  const [open, setOpen] = useState(false);
  const [clients, setClients] = useState<IClient[]>([]);
  const [inputValue, setInputValue] = useState<string>(customer?.name ?? '');
  const [searchCriteria, setSearchCriteria] = useState<string>('');

  useEffect(() => {
    if (!customer?.name) {
      setInputValue('');
    }
  }, [customer]);

  useEffect(() => {
    const fetchClients = async () => {
      try {
        const response = await getClients();
        setClients(response);
      } catch (error) {
        ThrowError(error, 'Erro ao buscar clientes!');
      }
    };
    fetchClients();
  }, [ThrowError, getClients]);

  const searchedClients = useMemo(() => {
    return clients.filter(client => {
      const string_norm = searchCriteria
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');

      return String(client.name)
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(string_norm.toLowerCase());
    });
  }, [clients, searchCriteria]);

  const onChange = (e: any) => {
    setSearchCriteria(e);
    setInputValue(e);
  };

  const onKeyPress = (e: any) => {
    if (e.key === 'Enter') {
      if (searchedClients.length === 0) {
        setOpen(true);
        setSearchCriteria(inputValue);
      } else if (searchedClients.length > 1) {
        setSearchCriteria(inputValue);
        setOpen(true);
      } else {
        setCustomer(searchedClients[0]);
        setInputValue(searchedClients[0].name);
      }
    }
  };

  const handleOnClick = (client: IClient) => {
    setCustomer(client);
    setInputValue(client.name);
    setOpen(false);
  };
  return (
    <>
      <InputSell
        value={inputValue}
        label="Clientes"
        onChange={onChange}
        onClick={() => setOpen(true)}
        onKeyPress={onKeyPress}
      />
      {open && (
        <Modal width="auto" setOpen={setOpen} open={open}>
          <Wrapper>
            <div className="title">
              <h3>Clientes</h3>
            </div>
            <Search fullscreen onChange={onChange} value={searchCriteria} />
            <div>
              <Head>
                <p>ID</p>
                <p>Nome</p>
              </Head>
              <Grid className="has-custom-scroll-bar-2">
                {searchedClients.map(client => (
                  <Client key={client.id} onClick={() => handleOnClick(client)}>
                    <p>{client.id}</p>
                    <p className="name">{client.name}</p>
                  </Client>
                ))}
              </Grid>
            </div>
          </Wrapper>
        </Modal>
      )}
    </>
  );
};

export default InputClients;
