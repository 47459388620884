import React, { useContext, createContext, useCallback } from 'react';
import { AxiosResponse } from 'axios';
import api from '../services/api';
import ICashierReport, { IShift } from '../models/ICashierReport';

export interface ISupplyWithdrawal {
  description: string;
  amount: number;
}

export interface ICloseCashier {
  amount: number;
  date: string;
  id: number;
  cashWithdrawAmount: number;
}
interface CashDrawerContextData {
  closeCashier: (
    body: ICloseCashier,
    shiftId: number,
  ) => Promise<AxiosResponse<any>>;
  getCashier: () => Promise<AxiosResponse<any>>;
  getCashierAll: () => Promise<AxiosResponse<any>>;
  getSummary: (shiftId: number) => Promise<AxiosResponse<any>>;
  openCashier: (amount: number) => Promise<AxiosResponse<any>>;
  Supply: (body: ISupplyWithdrawal) => Promise<AxiosResponse<any>>;
  CashWithdrawal: (body: ISupplyWithdrawal) => Promise<AxiosResponse<any>>;
  getCashierReport: (shiftId: IShift['id']) => Promise<ICashierReport | null>;
}

const CashDrawerContext = createContext<CashDrawerContextData>(
  {} as CashDrawerContextData,
);

export const CashDrawerProvider: React.FC = ({ children }) => {
  const getCashierAll = useCallback(async () => {
    try {
      const response = await api.get(`/restricted/internal-shifts/all`);
      return response;
    } catch (error) {
      throw error;
    }
  }, []);
  const getCashier = useCallback(async () => {
    try {
      const response = await api.get(`/restricted/internal-shifts/open`);
      return response;
    } catch (error) {
      throw error;
    }
  }, []);
  const getSummary = useCallback(async (shiftId: number) => {
    try {
      const response = await api.get(
        `/restricted/internal-shifts/${shiftId}/summary`,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }, []);

  const Supply = useCallback(async (body: ISupplyWithdrawal) => {
    try {
      const post = { ...body, type: 1 };
      const response = await api.post(
        `/restricted/cash-drawers/internal`,
        post,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }, []);

  const CashWithdrawal = useCallback(async (body: ISupplyWithdrawal) => {
    try {
      const post = { ...body, type: 0 };
      const response = await api.post(
        `/restricted/cash-drawers/internal`,
        post,
      );
      return response;
    } catch (error) {
      throw error;
    }
  }, []);

  const openCashier = useCallback(async (amount: number) => {
    try {
      const currentDate = new Date().toISOString();
      const body = { amount, date: currentDate };

      const response = await api.post(`/restricted/internal-shifts/open`, body);
      return response;
    } catch (error) {
      throw error;
    }
  }, []);

  const closeCashier = useCallback(
    async (body: ICloseCashier, shiftId: number) => {
      try {
        const response = await api.patch(
          `/restricted/internal-shifts/${shiftId}/close`,
          body,
        );
        return response;
      } catch (error) {
        throw error;
      }
    },
    [],
  );

  const getCashierReport = useCallback(async (shiftId: IShift['id']) => {
    try {
      const response = await api.get<ICashierReport>(
        `/restricted/internal-shifts/${shiftId}/summary`,
      );

      return response.data;
    } catch (error) {
      return null;
    }
  }, []);

  return (
    <CashDrawerContext.Provider
      value={{
        Supply,
        getSummary,
        getCashier,
        openCashier,
        closeCashier,
        getCashierAll,
        CashWithdrawal,
        getCashierReport,
      }}
    >
      {children}
    </CashDrawerContext.Provider>
  );
};

export function useCashDrawer(): CashDrawerContextData {
  const context = useContext(CashDrawerContext);

  if (!context) {
    throw new Error('useCashDrawer must be used within CashDrawerProvider');
  }

  return context;
}
