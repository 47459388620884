import styled from 'styled-components';

export const ContainerCancelationOrder = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`;
export const Tittle = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  text-align: center;
  p {
    font-size: 20px;
  }
`;
