/* eslint-disable no-param-reassign */
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { MdSearch } from 'react-icons/md';
import { FiEye, FiEyeOff, FiX } from 'react-icons/fi';
import IProduct from '../../models/IProduct';
import ProductIcon from '../ProductIcon';

import {
  Container,
  ProductsContainer,
  Product,
  ProductImage,
  Search,
  StyledModal,
  Header,
  ClearSearchButton,
} from '../ProductsSelectorModal/styles';
import { normalizeString } from '../../utils/string';
import { Actions, EmptyProductsLabel, Label } from './styles';
import ButtonSm from '../ButtonSm';
import api from '../../services/api';
import IDevice, { IDeviceProduct } from '../../models/IDevice';
import Loading from '../Loading';
import { useToast } from '../../hooks/toast';

interface IDeviceMenuModalProps {
  device: IDevice;
  open: boolean;
  products: IProduct[];
  onConfirm: () => void;
  onClose?: () => void;
}

StyledModal.setAppElement('#root');

export interface IProductConfig {
  product: Pick<
    IProduct,
    'id' | 'title' | 'imageUrl' | 'categories' | 'tags' | 'subcategory'
  >;
  active?: boolean;
}

interface ISections {
  active: IProduct[];
  inactive: IProduct[];
}

interface IItemProps {
  product: IProduct;
  active?: boolean;
  onPress?: () => void;
}

function search(text: string, term: string) {
  return normalizeString(text || '')?.includes(normalizeString(term || ''));
}

const Item: React.FC<IItemProps> = ({ product, active, onPress }) => {
  return (
    <Product key={product.id} onClick={onPress} inactive={!active}>
      <ProductImage>
        <ProductIcon url={product.imageUrl} alt={product.title} />
      </ProductImage>
      <span>{product.title}</span>
      {active ? (
        <FiEye size={16} className="arrow" />
      ) : (
        <FiEyeOff size={16} className="arrow" />
      )}
    </Product>
  );
};

const DeviceMenuModal: React.FC<IDeviceMenuModalProps> = ({
  open,
  products,
  device,
  onClose,
  onConfirm,
}) => {
  const { addToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [productsConfig, setProductsConfig] = useState<IProductConfig[]>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const searchRef = useRef<HTMLInputElement | null>(null);

  const searchedProductsConfig = useMemo<ISections>(() => {
    if (!productsConfig) {
      return { active: [], inactive: [] };
    }
    return productsConfig.reduce(
      (acc, { product, active }): ISections => {
        let includeProduct = false;

        if (!searchTerm) {
          includeProduct = true;
        } else {
          let searchText = '';
          searchText += product.title;
          searchText += product.categories?.map(c => c.name)?.join('');
          searchText += product?.subcategory?.name;
          searchText += product?.tags?.join('');
          includeProduct = search(searchText, searchTerm);
        }

        if (!includeProduct) return acc;

        if (active)
          return { ...acc, active: [...acc.active, product] } as ISections;

        return { ...acc, inactive: [...acc.inactive, product] } as ISections;
      },
      { active: [], inactive: [] } as ISections,
    );
  }, [searchTerm, productsConfig]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearchTerm(event.target.value);
    },
    [],
  );

  const handleOnSearchClear = () => {
    setSearchTerm('');
    if (searchRef.current) {
      searchRef.current.value = '';
    }
  };

  const handleKeyDown = useCallback(() => {
    if (searchRef.current) {
      searchRef.current.focus();
    }
  }, []);

  const handleOnProductToggle = useCallback(
    (productId: IProduct['id'], newValue: boolean) => {
      setProductsConfig(old =>
        old.map(config =>
          config.product?.id === productId
            ? { ...config, active: newValue }
            : config,
        ),
      );
    },
    [],
  );

  const handleOnConfirm = async () => {
    setIsSaving(true);
    try {
      const deviceProducts: IDeviceProduct[] = productsConfig.reduce(
        (acc, { product, active }) => {
          if (active)
            return [...acc, { productId: product?.id }] as IDeviceProduct[];

          return acc;
        },
        [] as IDeviceProduct[],
      );

      await api.post(
        `restricted/devices/${device.deviceId}/products`,
        deviceProducts,
      );
      addToast({ type: 'success', description: 'Cardápio salvo com sucesso' });
    } catch (error) {
      console.error(error);
      addToast({
        type: 'error',
        description: 'Ocorreu um erro ao salvar o cardápio.',
      });
    }
    setIsSaving(false);
    onConfirm();
  };

  const loadDeviceProducts = useCallback(async () => {
    setLoading(true);
    try {
      const { data } = await api.get<IDeviceProduct[]>(
        `/restricted/devices/${device.deviceId}/products`,
      );

      setProductsConfig(
        products?.reduce((acc, product) => {
          const deviceProduct = data.find(
            dProduct => dProduct.productId === product.id,
          );

          return [
            ...acc,
            {
              active: !!deviceProduct,
              product: {
                id: product?.id,
                title: product?.title,
                categories: product?.categories,
                subcategory: product?.subcategory,
                tags: product?.tags,
                imageUrl: product?.imageUrl,
              },
            },
          ];
        }, [] as IProductConfig[]),
      );
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  }, [device.deviceId, products]);

  const handleOnActiveAll = useCallback(() => {
    setProductsConfig(old => old.map(config => ({ ...config, active: true })));
  }, []);

  const handleOnDisableAll = useCallback(() => {
    setProductsConfig(old => old.map(config => ({ ...config, active: false })));
  }, []);

  const handleOnInvert = useCallback(() => {
    setProductsConfig(old =>
      old.map(config => ({ ...config, active: !config.active })),
    );
  }, []);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);

    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    if (!open) {
      handleOnSearchClear();
    } else {
      loadDeviceProducts();
    }
  }, [open, loadDeviceProducts]);

  return (
    <StyledModal
      id="options-dialog"
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      onRequestClose={onClose}
      isOpen={open}
      style={{
        overlay: {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          height: '100%',
        },
      }}
    >
      <Container>
        {loading ? (
          <Loading />
        ) : (
          <>
            <Header>
              <FiX size={24} onClick={onClose} />
              <h2>Cardápio para este dispositivo:</h2>
              <ButtonSm
                buttonStyle="filled"
                style={{ fontSize: '1rem', marginLeft: 'auto' }}
                onClick={handleOnConfirm}
                disabled={isSaving}
              >
                Salvar
                {isSaving && (
                  <Loading color="text_white" radius={16} stroke={2} />
                )}
              </ButtonSm>
            </Header>
            <Actions>
              <ButtonSm
                buttonStyle="outline"
                className="active"
                onClick={handleOnActiveAll}
              >
                Ativar tudo
              </ButtonSm>
              <ButtonSm
                buttonStyle="outline"
                className="disable"
                onClick={handleOnDisableAll}
              >
                Desativar tudo
              </ButtonSm>
              <ButtonSm
                buttonStyle="outline"
                className="invert"
                onClick={handleOnInvert}
              >
                Inverter
              </ButtonSm>
            </Actions>

            <Search>
              <MdSearch size={24} />
              <input
                type="text"
                onChange={handleSearchChange}
                ref={searchRef}
              />
              <ClearSearchButton onClick={handleOnSearchClear}>
                <FiX size={18} />
              </ClearSearchButton>
            </Search>
            <ProductsContainer className="has-custom-scroll-bar">
              <Label>Ativos</Label>
              {searchedProductsConfig.active.length > 0 ? (
                searchedProductsConfig.active.map(product => (
                  <Item
                    product={product}
                    active
                    onPress={() => handleOnProductToggle(product?.id, false)}
                  />
                ))
              ) : (
                <EmptyProductsLabel>Nenhum produto ativo</EmptyProductsLabel>
              )}

              <Label>Inativos</Label>
              {searchedProductsConfig.inactive.length > 0 ? (
                searchedProductsConfig.inactive.map(product => (
                  <Item
                    product={product}
                    onPress={() => handleOnProductToggle(product?.id, true)}
                  />
                ))
              ) : (
                <EmptyProductsLabel>Nenhum produto inativo</EmptyProductsLabel>
              )}
            </ProductsContainer>
          </>
        )}
      </Container>
    </StyledModal>
  );
};

export default DeviceMenuModal;
